import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-chatapp',
  templateUrl: './chatapp.component.html',
  styleUrls: ['./chatapp.component.scss']
})
export class ChatappComponent implements OnInit {
  windowWidth: any;
  windowHeight: any;

  constructor() {
    this.windowWidth = window.innerWidth - window.innerWidth / 2;
    this.windowHeight = Number(window.innerHeight - window.innerHeight / 3);
  }

  ngOnInit(): void {
  }

  @HostListener('window:resize', ['$event']) onResize(event) {
    console.log(event.target.innerWidth, event.target.innerHeight);
    this.windowWidth = event.target.innerWidth - event.target.innerWidth / 2;
    this.windowHeight = Number(event.target.innerHeight - event.target.innerHeight / 3);
  }

}
