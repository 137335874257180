import { Injectable } from '@angular/core';
import { Http, RequestOptions, Headers } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class SocketdataService {
 
  constructor(private _http: Http) { 
  
  } 

  getCanvasData(prjName, jsonFileName) {
    return new Promise((resolve, reject) => {
      this.updateStatus(prjName, jsonFileName).then(
        res => {
          resolve(res);
        });
    });   
  }

  getAssetsData(apipath, jsonFileName) {
    return new Promise((resolve, reject) => {
      this.updateStatus(apipath, jsonFileName).then(
        res => {
          resolve(res);
        });
    });
  }

  getObjectData(apipath, jsonFileName) {
    return new Promise((resolve, reject) => {
      this.updateStatus(apipath, jsonFileName).then(
        res => {
          resolve(res);
        });
    });
  }

  getObjectProperties(apipath, jsonFileName) {
    return new Promise((resolve, reject) => {
      this.updateStatus(apipath, jsonFileName).then(
        res => {
          resolve(res);
        });
    });
  }

  getAnimationData(apipath, jsonFileName) {
    return new Promise((resolve, reject) => {
      this.updateStatus(apipath, jsonFileName).then(
        res => {
          resolve(res);
        });
    });
  }

  updateStatus(api,data) {
    return new Promise((resolve, reject) => {
        return this._http.post(api,data).subscribe(data => {
            resolve(data.json());
        }, (err) => {
            console.log('error:get-config:', err);
            reject(null);
        });
    });
  }

}
