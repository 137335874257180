import { Injectable } from '@angular/core';
declare var BABYLON;
@Injectable({
  providedIn: 'root'
})
export class RadhazVisService {
  datauxview;
  broadcastService;
  activityServ;
  broadcastSubs;
  dfx;
  isRadiating : boolean = false;
  constructor() {
    /**
     * comment out when pushing to live
     */
    window['rhscope'] = this;
  }
  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.broadcastService.getInstance().next(data);
  }
  /**
  * RADHAZ demo
  */
  ship_map = {}
  color_map = {};
  collision_ships = [];
  selectedActivity: any = "";
  radhaz_comps = {
    "01__NAV_RADAR-B": { "antenna":true, "rotating":true, "position": {}, "radius": 2, "height": null, "state": false, "name": "NAV RADAR B", "id": "NAV_radar_B", "index": 1 },
    "02__NAV_RADAR-A": { "antenna":true, "rotating":true, "position": {}, "radius": 3, "height": null, "state": false, "name": "NAV RADAR A", "id": "NAV_radar_A", "index": 2 },
    "11__SIRIUS": { "antenna":false, "rotating":true, "position": {}, "radius": 0.8, "height": null, "state": false, "name": "SIRIUS", "id": "SIRIUS_sensors", "index": 11 },
    "16__FBB-500": { "antenna":true, "rotating":false, "position": {}, "radius": 2, "height": null, "state": false, "name": "FBB 500", "id": "FBB_500", "index": 16 },
    "31__SG-180": { "antenna":true, "rotating":true, "position": {}, "radius": 17, "height": null, "state": false, "name": "SG-180", "id": "SG-180", "index": 31 },
    "43__RAMSES": { "antenna":true, "rotating":false, "position": {}, "radius": 35, "height": null, "state": false, "name": "RAMSES", "id": "RAMSES", "index": 43 },
    "44__NMT_PORT": { "antenna":true, "rotating":false, "position": {}, "radius": 45, "height": null, "state": false, "name": "NMT PORT", "id": "NMT_PORT", "index": 44 },
    "45__NMT_STBD": { "antenna":true, "rotating":false, "position": {}, "radius": 45, "height": null, "state": false, "name": "NMT STBD", "id": "NMT_STBD", "index": 45 },
    "46__CEROS_200_fwd": { "antenna":true, "rotating":true, "position": {}, "radius": 75, "height": null, "state": false, "name": "FWD CEROS", "id": "CEROS_200_fwd", "index": 46 },
    "47__CEROS_200_aft": { "antenna":true, "rotating":true, "position": {}, "radius": 75, "height": null, "state": false, "name": "AFT CEROS", "id": "CEROS_200_aft", "index": 47 },
    "48__SEOSS": { "antenna":true, "rotating":false, "position": {}, "radius": 66, "height": null, "state": false, "name": "SEOSS", "id": "SEOSS", "index": 48 },
    "49__Smart_S_radar": { "antenna":true, "rotating":true, "position": {}, "radius": 30.4, "height": null, "state": false, "name": "Smart-S", "id": "Smart_S_radar", "index": 49 },
    "60__white_Sailor_100_GX": { "antenna":true, "rotating":false, "position": {}, "radius": 55, "height": null, "state": false, "name": "Sailor 100 GX", "id": "Sailor_100_GX", "index": 60 },
    "54__MSCU": { "antenna":true, "rotating":false, "position": {}, "radius": 153, "height": null, "state": false, "name": "lMSCU", "id": "lMSCU", "index": 54 },
    "55__CIWS_sensors": { "antenna":true, "rotating":true, "position": {}, "radius": 189, "height": null, "state": false, "name": "CIWS RF ONLY", "id": "CIWS_sensors", "index": 55 },
    "56__HF_18ft_whip": { "antenna":true, "rotating":false, "position": {}, "radius": 18.5, "height": null, "state": false, "name": "HF 18' Whip", "id": "HF_18ft_whip", "index": 56 },
    "56__HF_35ft_whip": { "antenna":true, "rotating":false, "position": {}, "radius": 10, "height": null, "state": false, "name": "HF 35' Whip", "id": "HF_35ft_whip", "index": 56 },
    "57__HF_Emergency": { "antenna":true, "rotating":false, "position": {}, "radius": 7.3, "height": null, "state": false, "name": "HF Emergency", "id": "HF_Emergency", "index": 57 },
    "58__CEROS_200_fwd": { "antenna":true, "rotating":false, "position": {}, "radius": 245, "height": null, "state": false, "name": "FWD CWI", "id": "CEROS_200_fwd", "index": 58 },
    "59__CEROS_200_aft": { "antenna":true, "rotating":false, "position": {}, "radius": 245, "height": null, "state": false, "name": "AFT CWI", "id": "CEROS_200_aft", "index": 59 }

  }
  radhaz_components = Object.keys(this.radhaz_comps);
  aloft_comps = {
    "BRIDGE_TOP": { "position": {}, "radius": 1, "height": null, "state": false, "name": "Bridge Top", "id": "BRIDGE_TOP", "index": 0 }
    , "HANGAR_TOP": { "position": {}, "radius": 1, "height": null, "state": false, "name": "Hangar Top", "id": "HANGAR_TOP", "index": 0 }
    , "MAST": { "position": {}, "radius": 1, "height": null, "state": false, "name": "Mast", "id": "MAST", "index": 0 }
    , "FUNNEL_TOP": { "position": {}, "radius": 1, "height": null, "state": false, "name": "Funnel Top", "id": "FUNNEL_TOP", "index": 0 }
    , "HELO_TOP": { "position": {}, "radius": 1, "height": null, "state": false, "name": "Helo Top", "id": "HELO_TOP", "index": 0 }
    , "DIVER_DOWN": { "position": {}, "radius": 1, "height": null, "state": false, "name": "Diver Down", "id": "DIVER_DOWN", "index": 0 }
    , "INTAKES_TOP": { "position": {}, "radius": 1, "height": null, "state": false, "name": "Intakes Top", "id": "INTAKES_TOP", "index": 0 }
  }
  aloft_components = Object.keys(this.aloft_comps);

  initService(datauxview: any, broadcastService: any, activityServ: any) {
    this.dfx = datauxview.getDatascape();
    this.broadcastSubs = broadcastService.getInstance()
      .subscribe((data: any) => {
        if (data.src === "main") {
          if (data.event === 'updateshipdata') {

          }
        }
      });
    this.datauxview = datauxview;
    this.broadcastService = broadcastService;
    this.activityServ = activityServ;
    this.generateCompCols();
  }

  addCollisionShips(shipobj){
    let hfx = this.datauxview.getElementId(shipobj.name);
    let m = this.dfx.getElementMesh(hfx);
    this.processHFXForRHMAT(m);
    this.collision_ships.push(m);
  }

  addRadHazActivity(shipobj,process=true,ant="") {
    if(!ant){
      this.ship_map[shipobj.name] = { name: shipobj.name, colluded: false }
      this['radhaz_comps_' + shipobj.name] = this.clone(this.radhaz_comps);
      this['aloft_comps_' + shipobj.name] = this.clone(this.aloft_comps);
    }
    if(ant=="ARH"){
    this['ant_radhaz_comps_' + shipobj.name] = this.clone(this.radhaz_comps);
    }
    if(ant=="ROT"){
    this['ant_rot_comps_' + shipobj.name] = this.clone(this.radhaz_comps);
    }
    if(process){
      let hfx = this.datauxview.getElementId(shipobj.name);
      let m = this.dfx.getElementMesh(hfx);
      this.processHFXForRHMAT(m);
      this.collision_ships.push(m);
      let c = this.getChildMeshEx(shipobj.name, "WARNINGS_V1");
      c ? c.setEnabled(false) : null;
      this.processHFXForRH(shipobj.name, this.radhaz_comps);
      this.processHFXForRH(shipobj.name, this.aloft_comps, true);
    }
    this.activityServ.createCylinderModel_RADHAZ(this.radhaz_comps, shipobj.name,ant);
  }
  reloadRadHazActivity() {
    for (let shipId in this.ship_map) {
      this.ship_map[shipId] = { name: shipId, colluded: false }
      this['radhaz_comps_' + shipId] = this.clone(this.radhaz_comps);
      this['aloft_comps_' + shipId] = this.clone(this.aloft_comps);
      let hfx = this.datauxview.getElementId(shipId);
      let m = this.dfx.getElementMesh(hfx);
      this.processHFXForRHMAT(m);
      // this.collision_ships.push(m);
      let c = this.getChildMeshEx(shipId, "WARNINGS_V1");
      c ? c.setEnabled(false) : null;
      this.processHFXForRH(shipId, this.radhaz_comps);
      this.processHFXForRH(shipId, this.aloft_comps, true);
      this.activityServ.createCylinderModel_RADHAZ(this.radhaz_comps, shipId);
    }
  }
  setElemProfile(id, profile) {
    let el = this.datauxview.getElementId(id);
    this.dfx.modify(el, profile);
  }
  setMeshProfile(id, mat) {
    let el = this.getMesh(id);
    el.material = mat;
  }
  setShipProfile(ship, profile) {
    let el = ship.getChildren()[0];
    let c = el.getChildMeshes();
    let scene = this.dfx.getCamera().getScene();
    let mat = profile === 'regular' ? 'def' : scene.getMaterialByName(profile);
    c.forEach((m) => {
      let def = m.defMat;
      let _mat = mat === 'def' ? scene.getMaterialByName(def) : mat;
      m.material = _mat;
    })
  }

  resetCollisions(boo=false) {
    this.resetDemoShipProfile();
    this.resetCollisionStatus();
    for (let m in this.ship_map) {
      this['ant_radhaz_comps_' + m] = this.clone(this.radhaz_comps);
      this['ant_rot_comps_' + m] = this.clone(this.radhaz_comps);
      this['radhaz_comps_' + m] = this.clone(this.radhaz_comps);
      this['aloft_comps_' + m] = this.clone(this.aloft_comps);
      if(boo){
        this.processHFXForRH('ealoft',this['aloft_comps_ehfx'],true);
      }
    }
  }
  resetCollisionParams() {
    for (let m in this.ship_map) {
      this.resetCollisionParam(this['ant_radhaz_comps_' + m] );
      this.resetCollisionParam(this['ant_rot_comps_' + m]);
      this.resetCollisionParam(this['radhaz_comps_' + m]);
      this.resetCollisionParam(this['aloft_comps_' + m]);
    }
  }
  resetCollisionParam(compdef){
    for(let m in compdef){
      compdef[m].incollision=null;
      delete compdef[m].incollision
    }
  }

  checkCollisions(cshipId,dw=false) {
    this.resetDemoShipProfile();
    this.resetCollisionStatus();
    for (let m in this.ship_map) {
      let shipId = m;
      this.checkSelfCollision(shipId, this['radhaz_comps_' + shipId], this['aloft_comps_' + shipId]);
    }
    var shipData = Object.keys(this.ship_map).filter(obj => this.ship_map[obj]['colluded'] == true)
    if(!dw){
      this.applyProfiles();
    }
    const radhaz_colllision = Object.keys(this['radhaz_comps_' + cshipId]).some(key => this['radhaz_comps_' + cshipId][key]['incollision'].length > 0)
    var shipCollisionDetails = {radhaz_colllision : radhaz_colllision , shipData : shipData}
    return shipCollisionDetails;
  }
  applyProfiles() {
    for (let m in this.ship_map) {
      let shipId = m;
      this.reapplyProfiles(shipId, this['radhaz_comps_' + shipId], this['aloft_comps_' + shipId]);
    }
  }
  resetCollisionStatus() {
    for (let m in this.ship_map) {
      this.ship_map[m].colluded = false;
    }
  }
  collision_db = {}
  reapplyProfiles(ship, src, comp) {
    let scene = this.dfx.getCamera().getScene();
    // let p = ship === "hfex_radhaz" ? "" : "_2"
    let p = ship;
    if (this.ship_map[ship].colluded) {
      return;
    }
    for (let m in src) {
      let item = src[m];
      let id = item.id;
      let mesh = this.getChildMeshEx(ship, id);
      let istate = item.istate
      let estate = item.state
      let matid = mesh.defMat;
      if (estate) {
        matid = item.matid;
      }
      if (istate) {
        //matid = item.matid2;
      }
      if (item.incollision && item.incollision.length) {

      }
      let mat = scene.getMaterialByName(matid);
      mesh.material = mat;
    }
    for (let m in comp) {
      let item = comp[m];
      // let id = item.id + this['aloft_version' + p];
      let id = item.id + p;
      let mesh = this.getChildMeshEx(ship, id);
      if (mesh) {
        let istate = item.istate
        let estate = item.state
        let matid = mesh.defMat;
        let mat3 = this.isDiverEnvp(id) ? item.matid4 : item.matid3
        if (estate) {
          matid = item.matid5;
        }
        if (istate) {
          matid = mat3;
        }
        if (item.colluded) {
          matid = "envelope_red"
        }

        let mat = scene.getMaterialByName(matid);
        mesh.material = mat;
      }
    }
  }
  getRHCylinder(shipId,id, ant=""){
    let k= 'envelope-' + id + '-' + shipId;
    let cyl=this.activityServ.radhaz_cylinders
    if(ant){
      k=k+"-"+ant;
      cyl=ant=="ROT"? this.activityServ["ant_rot_cylinders"]:cyl;
      cyl=ant=="ARH"? this.activityServ["ant_radhaz_cylinders"]:cyl;
    }
              let mel = cyl[shipId][k];
              let mmel = this.dfx.getElementMesh(mel);
              return mmel;
  }
  emitters_activity=['radiating','antenna rotating','antenna radiating']
  isRHEmitter(act){
    return this.emitters_activity.includes(act)
  }
  getAntCode(act){
    let code = "";
    if(act==="antenna rotating"){
      code = "ROT";
    }
    if(act==="antenna radiating"){
      code = "ARH";
    }
    return code;
  }
  getCylinderStore(act){
    let cyl=this.activityServ.radhaz_cylinders;
    let ant = this.getAntCode(act);
    if(ant){
      cyl=ant=="ROT"? this.activityServ["ant_rot_cylinders"]:cyl;
      cyl=ant=="ARH"? this.activityServ["ant_radhaz_cylinders"]:cyl;
    }
    return cyl;
  }
  getARHCompStore(act,shipId){
    let comps=this['radhaz_comps_'+shipId];
    let ant = this.getAntCode(act);
    if(ant){
      comps=ant=="ROT"? this["ant_rot_comps_"+shipId]:comps;
      comps=ant=="ARH"? this["ant_radhaz_comps_"+shipId]:comps;
    }
    return comps;
  }
  checkSelfCollisionDW(shipId, actobj=null){
    this.resetCollisionStatus();
    this.clearOutlines(shipId);
    let arr=null;
    let act = actobj.activity.toLowerCase();
    let loc=null;
    let rh = this['radhaz_comps_' + shipId];
    let alf =  this['aloft_comps_' + shipId];
    let loop=this.emitters_activity;
    if(act==='man-aloft'){

      loc = actobj.manAloftLocation;
      rh = null;
      let _alf={};
      _alf[loc]=alf[loc];
      alf=_alf;
      this.checkSelfCollisionForDW(shipId, rh, alf,act);
    }else{
       rh = this.getARHCompStore(act,shipId)
       loc = this.isRHEmitter(act)?actobj.emitterLocation:actobj.manAloftLocation;
      if(this.isRHEmitter(act)){
        let _rh={}
        _rh[loc]=rh[loc]
        rh=_rh
      }/* else if(act==="man-aloft"){
        let _alf={}
        _alf[loc]=alf[loc]
        alf=_alf
      } */
      this.checkSelfCollisionForDW(shipId, rh, alf,act);
    }

    let _loc=loc.toLowerCase();
    let coll =null;
    loop.forEach((act)=>{
      let rh = this.getARHCompStore(act,shipId);
      let keys = Object.keys(rh);
      keys.forEach((s) => {
        let sobj = rh[s];
        let collision = sobj.incollision;
        if(collision&&collision.length){
          let mmel = this.getRHCylinder(shipId,s,this.getAntCode(act));
          this.showOutline(mmel);
          collision.forEach((c)=>{
            let _c=c.toLowerCase();
            if(_c.includes(_loc)||this.isRHEmitter(act)){
              if(!coll){
                coll=[];
              }
              if(!coll.includes(c)){
                let aid=c.split("/")[1];
                let str = aid.split("-");
                let ant=""
                if(str.length>1){
                  ant=str[str.length-1];
                  str.pop();
                  str[0]=str.join("-");
                }
                aid=str[0];
                coll.push(aid);
                let mesh = this.getChildMeshEx(shipId, aid)||this.getRHCylinder(shipId, aid,ant);
                if(mesh){
                  this.showOutline(mesh);
                }
              }
              // coll = true;
            }
          })
        }
      })
    })

    return coll;
  }
  showOutlinesForActivities(shipId,acts){
    let loc;
    let mmel;
    this.clearOutlines(shipId);
    acts.forEach((act)=>{
      if(this.isRHEmitter(act.activity)){
        loc=act.emitterLocation;
        mmel=this.getRHCylinder(shipId,loc,this.getAntCode(act.activity));
      }else if (act.activity=='man-aloft'){
        loc=act.manAloftLocation;
        mmel=this.getChildMeshEx(shipId,loc);
      }
      this.showOutline(mmel);
    })
  }
  clearOutlines(shipId,id=null,act=null){
    if(id&&act){
      let mmel=null
      if(this.isRHEmitter(act)){
        mmel=this.getRHCylinder(shipId,id,this.getAntCode(act));
      }else if(act==='man-aloft'){
        mmel = this.getChildMeshEx(shipId,id);
      }
      this.hideOutline(mmel);
      return
    }
    let rh = this['radhaz_comps_' + shipId];
    let alf =  this['aloft_comps_' + shipId];
    this.emitters_activity.forEach((_act)=>{
      for(var m in rh){
          let mmel = this.getRHCylinder(shipId,m,this.getAntCode(_act));
          this.hideOutline(mmel);
      }
    })
    for(var m in alf){
      let mmel = this.getChildMeshEx(shipId,m);
      this.hideOutline(mmel);
    }
  }
  /* checkSelfCollisionDW(shipId,actobj=null){
    this.resetCollisionStatus();
    let rh = this['radhaz_comps_' + shipId];
    let alf =  this['aloft_comps_' + shipId]
    let sobj=this.checkSelfCollisionForActivity(shipId, rh, alf,actobj);
    let arr = sobj?sobj.incollision:null;
    return arr;
  } */
  checkSelfCollisionForActivity(ship, rhcomp, comp,actobj) {
    let act = actobj.activity.toLowerCase();
    let s = this.isRHEmitter(act)?actobj.emitterLocation:actobj.manAloftLocation
    let sobj = this.isRHEmitter(act)?rhcomp[actobj.emitterLocation]:comp[actobj.manAloftLocation]
      let state = sobj.istate;
      let id = sobj.id;
      let k
      let mel
      let mmel
      if(act=='man-aloft'){
        mmel = this.getChildMeshEx(ship, sobj.id);
      }else{

         k = 'envelope-' + s + '-' + ship;
         let ant = this.getAntCode(act);
         if(ant){
          k=k+"-"+ant;
         }
         let cyl = this.getCylinderStore(act);
         mel = cyl[ship][k];
         mmel = this.dfx.getElementMesh(mel);
      }

      let colls = this.checkCollisionWithMA(ship, mmel, comp, sobj.radius);

      sobj.incollision = [].concat(colls);
      mmel.oncollision = sobj.incollision && sobj.incollision.length;
      return sobj;
  }
  checkSelfCollision(ship, src, comp,dw=false) {
    let keys = Object.keys(src);
    keys.forEach((s) => {
      let sobj = src[s];
      let state = sobj.istate;
      let id = sobj.id;
      let k = 'envelope-' + s + '-' + ship;
      let mel = this.activityServ.radhaz_cylinders[ship][k];
      let mmel = this.dfx.getElementMesh(mel);

      let colls = this.checkCollisionWithMA(ship, mmel, comp, sobj.radius);
      let colls2 = this.checkCollisionWithShips(ship, mmel, sobj.radius);
      sobj.incollision = [].concat(colls, colls2);
      mmel.oncollision = sobj.incollision && sobj.incollision.length;
      if (state&&!dw) {
        this.activityServ.showHideRHCylinder(s + '-' + ship, sobj.istate, ship);
      }
    })

  }
  checkSelfCollisionForDW(ship, src, comp,act) {
    let emitter=true
    let loop=[act];
    if(!this.isRHEmitter(act)){
      loop=this.emitters_activity;
      emitter = false;
    }
    loop.forEach((act)=>{
      if(!emitter){
      src=this.getARHCompStore(act,ship);
      }
      let keys = Object.keys(src);
      keys.forEach((s) => {
        let sobj = src[s];
        let state = sobj.istate;
        let id = sobj.id;
        let k = 'envelope-' + s + '-' + ship;
        let colls=[];
        let colls2=[];

        let ant = this.getAntCode(act);
        if(ant){
        k=k+"-"+ant;
        }
        let cyl = this.getCylinderStore(act);
        let mel = cyl[ship][k];
        let mmel = this.dfx.getElementMesh(mel);

        colls = this.checkCollisionWithMA(ship, mmel, comp, sobj.radius);
        if(emitter){
          this.emitters_activity.forEach((act)=>{
            let _colls=this.checkCollisionWithRH(ship, mmel, sobj.radius,act);
            colls2=colls2.concat(_colls);
          })
        }
       // let colls2 = act=='man-aloft'?[]:this.checkCollisionWithRH(ship, mmel, sobj.radius,act);
        sobj.incollision = [].concat(colls, colls2);
        mmel.oncollision = sobj.incollision && sobj.incollision.length;
      })
    })


  }
  checkCollisionWithMA(ship, mel, comp, rad) {
    let ckeys = Object.keys(comp);
    let colls = [];
    let p = "";
    let sid = this.getDemoShipName(ship);
    ckeys.forEach((s) => {
      let sobj = comp[s];
      let state = mel.material.name !== 'hidden_obj';//sobj.istate;
      if (state) {
        // let id = sobj.id + this['aloft_version' + p];
        let id = sobj.id + p;
        let mesh = this.getChildMeshEx(ship, id);
        let cstate = mesh.isVisible && mesh.material.name !== 'man_aloft';
        if (cstate && mel.intersectsMesh(mesh, !true) && this.verifyCollision(mel, mesh, rad)) {
          colls.push(sid + "/" + sobj.id);
          sobj.colluded = true;
        } else {
          //sobj.colluded=!true;
        }
      } else {
        //sobj.colluded=!true;
      }
    })
    return colls;
  }
  checkCollisionWithRH(ship, mel, rad,act="") {
    let comp = this.getARHCompStore(act,ship)//this['radhaz_comps_' + ship];
    let ckeys = Object.keys(comp);
    let colls = [];
    let p = "";
    let sid = this.getDemoShipName(ship);
    ckeys.forEach((s) => {

      let sobj = comp[s];
      let state = mel.material.name !== 'hidden_obj';//sobj.istate;
      if (state) {
        // let id = sobj.id + this['aloft_version' + p];
        let id = s;
        let ant = this.getAntCode(act);
        let mesh = this.getRHCylinder(ship,s,ant);

        let cstate = (mel.name!==mesh.name)&&mesh.isVisible;
        if (cstate && mel.intersectsMesh(mesh, !true) && this.verifyCollision(mel, mesh, rad)) {
          colls.push(sid + "/" + s+(ant?"-"+ant:ant));
          sobj.colluded = true;
        } else {
          //sobj.colluded=!true;
        }
      } else {
        //sobj.colluded=!true;
      }
    })
    return colls;
  }
  checkCollisionWithShips(cship, mel, rad) {
    let colls = []
    let sid;
    this.collision_ships.forEach((ship) => {
      if (cship != ship.name && ship.isEnabled()) {
        sid = ship.name.split("element-")[1];
        let act = false;
        let state = mel.material.name !== 'hidden_obj';//||mel.material.name==='transparent'
        if (state) {
          let comps = ship.getChildren()[0].getChildren();
          let boo = false;
          comps.every((c) => {
            let diver = this.isDiverEnvp(c.name);
            if (!c.isEnabled()) {
              return true
            }
            if (!c.isVisible || (diver && !this.isDiverDown(sid))) {
              return true
            }
            let cstate = c.isVisible && c.material.name !== 'hidden_obj';
            if (cstate && mel.intersectsMesh(c, !true) && this.verifyCollision(mel, c, rad)) {

              if (diver) {
                boo = false;
                this.diverCompColluded(sid);
                let _sid = this.getDemoShipName(sid);
                colls.push("DIVER DOWN (" + _sid + ")");
              } else {
                boo = true;
              }
              console.log("Inter", c.name);
              return false;
            }
            return true
          })
          if (boo) {
            let _sid = sid;
            if (this.ship_map[sid]) {
              _sid = this.ship_map[sid].name;
              this.ship_map[sid].colluded = true;
            }
            colls.push(_sid);

            act = true;
          } else {
            //this.setMeshProfile(sid,{profile:'regular'})
          }
        }
        if (act) {
          this.setShipProfile(ship, 'envelope_red')
        } else {
          // this.setShipProfile(ship,'regular')
        }
      }
    })
    return colls;
  }
  checkCollisionWithShipsMA(cship, mel, rad) {
    let colls = []
    let sid;
    this.collision_ships.forEach((ship) => {
      if (ship.isEnabled()) {
        sid = ship.name.split("element-")[1];
        let act = false;
        if (sid === cship) {
          return false;
        }
        let comp = this['aloft_comps_' + cship];
        if (mel.isVisible) {
          colls = colls.concat(this.checkCollisionWithMA(sid, mel, comp, rad))
        }
      }
    })
    return colls;
  }
  verifyCollision(m1, m2, dia) {
    let sphere = m1.getAbsolutePosition();
    let min = m2.getBoundingInfo().boundingBox.minimumWorld;
    let max = m2.getBoundingInfo().boundingBox.maximumWorld;
    let box = { minX: min.x, minY: min.y, minZ: min.z, maxX: max.x, maxY: max.y, maxZ: max.z }
    // get box closest point to sphere center by clamping
    var x = Math.max(box.minX, Math.min(sphere.x, box.maxX));
    var y = Math.max(box.minY, Math.min(sphere.y, box.maxY));
    var z = Math.max(box.minZ, Math.min(sphere.z, box.maxZ));

    // this is the same as isPointInsideSphere
    var distance = Math.sqrt((x - sphere.x) * (x - sphere.x) +
      (y - sphere.y) * (y - sphere.y) +
      (z - sphere.z) * (z - sphere.z));

    return distance < dia / 2;
  }
  getDemoShipName(sid) {
    let _sid = sid;
    if (this.ship_map[sid]) {
      _sid = this.ship_map[sid].name;

    }
    return _sid
  }
  isDiverEnvp(e) {
    return e.includes("DIVER");
  }
  isDiverDown(shipId) {
    let comp: any = this['aloft_comps_' + shipId];;
    return comp["DIVER_DOWN"].istate
  }
  diverCompColluded(shipId) {
    let comp: any = this['aloft_comps_' + shipId];
    comp["DIVER_DOWN"].colluded = true;
  }
  getMelName(str) {
    let arr = str.split("-")[2].split("__");
    return arr[1];
  }
  resetDemoShipProfile() {
    let sid
    this.collision_ships.forEach((ship) => {
      if (ship.isEnabled()) {
        sid = ship.name.split("element-")[1];
        this.setShipProfile(ship, 'regular')
      }
    })
    if (this.activityServ) {
      let cyls = this.activityServ.radhaz_cylinders;
      for (let m in cyls) {
        let acts = cyls[m];
        for (let n in acts) {
          let act = this.dfx.getElementMesh(acts[n]);
          act.isVisible = false;
          act.isPickable = false;
          // this.dfx.modify(act, { profile:'hidden' });
        }
      }
    }
  }
  processHFXForRHMAT(m,_mesh=null) {
    let mesh = _mesh||m.getChildren()[0];
    let meshes = mesh.getChildMeshes();
    meshes.forEach((m) => {
      let n = m.material.name;
      m.defMat = n;
    })
  }
  processHFXForRH(m, cp, hide = false) {
    let comps = Object.keys(cp);
    let scene = this.dfx.getCamera().getScene();
    comps.forEach((c) => {
      let id = cp[c].id
      let child = this.getChildMeshEx(m, id);
      let p = cp[c].color || "#FFA500";
      let p2 = cp[c].color2 || "#00ff00";
      let p3 = cp[c].color3 || "#ffff00";
      let p4 = cp[c].color4 || "#337e9f";
      let p5 = cp[c].color5 || "#aaEEaa";
      let matid = "dfx_" + p;
      let matid2 = "dfx_" + p3 + "_transparent";
      let matid3 = "dfx_" + p2 + "_transparent";
      let matid4 = "dfx_" + p4 + "_transparent";
      let matid5 = "dfx_" + p5 + "_transparent";
      cp[c].matid = matid;
      cp[c].matid2 = matid2;
      cp[c].matid3 = matid3;
      cp[c].matid4 = matid3;
      cp[c].matid5 = matid5;
      if (child) {
        cp[c].position = child.position;
        if (hide) {
          child.isPickable = false;
        }else{
          child.isPickable = true;
        }
      }

    });
  }
  clone(obj) {
    return JSON.parse(JSON.stringify(obj));
  }
  showHideRHCylinder(shipId, boo, id = "",act="",col=null) {
    if (!id) {
      let r_comps = Object.keys(this.radhaz_comps);
      id = r_comps[Math.floor(Math.random() * r_comps.length)]
    }
    let cp =  this.getARHCompStore(act,shipId);//this['radhaz_comps_' + shipId];
    let ant= this.getAntCode(act);
    if (cp) {
      let key = id + "-" + shipId;
      cp[id].istate = boo;
      cp[id].state = boo;
      let prof=null
      if(col){
        prof="dfx_"+col+"_transparent";
      }
      if(ant==="ROT"){
        this.activityServ.showHideROTCylinder(key, boo, shipId,prof);
      }else if(ant==="ARH"){
        this.activityServ.showHideARHCylinder(key, boo, shipId,prof);
      }else{
        this.activityServ.showHideRHCylinder(key, boo, shipId,prof);
      }

    }
  }
  showHideRHCylinderAll( shipId, boo) {
    let cp = this['radhaz_comps_' + shipId];
    let comps = Object.keys(cp);
    comps.forEach((c) => {
      this.showHideRHCylinder(shipId,boo,c);
    });
  }

  showHideRHIndicator(shipId, boo, id = "") {
    if (!id) {
      let r_comps = Object.keys(this.radhaz_comps);
      id = r_comps[Math.floor(Math.random() * r_comps.length)]
    }
    let cp = this['radhaz_comps_' + shipId];
    let key = id + "-" + shipId;
    this.activityServ.showHideRHCylinder(key, boo, shipId);
    cp[id].istate = boo;
    if (boo && !cp[id].state) {
      this.showHideRHComp(shipId, boo, id);
    }
    if (!boo && cp[id].state) {
      this.showHideRHComp(shipId, true, id);
    }
    // this.checkCollisions(shipId);
  }
  showHideRHIndicator_wf(shipId, boo, id = "") {
    if (!id) {
      let r_comps = Object.keys(this.radhaz_comps);
      id = r_comps[Math.floor(Math.random() * r_comps.length)]
    }
    let key = id + "-" + shipId;
    this.activityServ.showHideRHCylinder_wf(key, boo, shipId);
  }
  showHideRHComp(shipId, boo, id = "") {
    if (!id) {
      let r_comps = Object.keys(this.radhaz_comps);
      id = r_comps[Math.floor(Math.random() * r_comps.length)]
    }
    let cp = this['radhaz_comps_' + shipId];
    let c = cp[id].id;
    let scene = this.dfx.getCamera().getScene();
    // let m = this.getChildMeshEx("hfx_radhaz" + p, c);
    let m = this.getChildMeshEx(shipId, c);
    let matid = /* cp[id].istate ? cp[id].matid2 : */ cp[id].matid;
    let mat1 = scene.getMaterialByName(matid);
    let mat2 = scene.getMaterialByName(m.defMat);
    let mat = boo ? mat1 : mat2;
    m.material = mat;
    cp[id].state = boo;
  }
  showHideRHCompAll(boo, shipId) {
    let cp = this['radhaz_comps_' + shipId];
    let comps = Object.keys(cp);
    comps.forEach((c) => {
      this.showHideRHComp(c, boo, shipId);
    });
  }
  /**man-aloft */
  showHideMAIndicator(shipId, boo, id = "",col=null) {
    if (!id) {
      let a_comps = Object.keys(this.aloft_comps);
      id = a_comps[Math.floor(Math.random() * a_comps.length)]
    }
    let cp = this['aloft_comps_' + shipId];
    if(cp != undefined){
      cp[id].istate = cp[id].state = boo;
    }
    if(col){
      let prof="dfx_"+col+"_transparent";
      this.isDiverEnvp(cp[id].id) ? cp[id].matid4=prof : cp[id].matid3=prof;
    }
    // if (boo && !cp[id].state) {
    // }
    if (!boo && cp[id].state) {
      this.showHideMAComp(id, true, shipId);
    } else {
      this.showHideMAComp(id, boo, shipId);
    }
    // this.checkCollisions(shipId)
  }
  showHideMAComp(id, boo, shipId) {
    let cp = this['aloft_comps_' + shipId];
    if (cp&&cp[id]&&cp[id].id != undefined) {
      let c = cp[id].id;
      let scene = this.dfx.getCamera().getScene();
      // let m = this.getChildMeshEx("hfx_radhaz" + p, c);
      let m = this.getChildMeshEx(shipId, c);
      if (!m) {
        return
      }
      if (c != undefined) {
        let mat3 = this.isDiverEnvp(c) ? cp[id].matid4 : cp[id].matid3
        let matid = cp[id].istate ? mat3 : cp[id].matid5;
        let mat1 = scene.getMaterialByName(matid);
        let mat2 = scene.getMaterialByName(m.defMat);
        let mat = boo ? mat1 : mat2;
        m.material = null;
        m.material = mat;
        m.hilited = boo;
        cp[id].state = boo;
        m.isPickable = boo;
      }
    }
  }
  showHideMACompAll(boo, shipId) {
    let cp = this['aloft_comps_' + shipId];
    let comps = Object.keys(cp);
    comps.forEach((c) => {
      this.showHideMAComp(c, boo, shipId);
    });
  }
  createContext(width, height) {
    var canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    return canvas;
  }
  texCAN
  getTexture(w = 8, h = 8, col = "#ffffff", tex = null) {
    if (!this.texCAN) {
      this.texCAN = this.createContext(w, h)
    }
    let ctx = this.texCAN.getContext("2d");
    ctx.clearRect(0, 0, w, h);
    ctx.beginPath();
    ctx.rect(0, 0, w, h);
    ctx.fillStyle = col;
    ctx.fill();
    if (tex) {
      ctx.font = "10px Arial";
      ctx.textAlign = "center";
      ctx.fillText(tex, w / 2, h / 2);
    }
    let png = this.texCAN.toDataURL();
    return png;
  }
  getHexToRGB(hex) {
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [parseInt(result[1], 16) / 255, parseInt(result[2], 16) / 255, parseInt(result[3], 16) / 255] : [0, 0, 0];
  }
  addNewProfileColor(col, addTex = true, prefix = "", model = null, trans = 0.3, bfc = true) {
    if (!col) {
      return;
    }
    let pcol = this.color_map[col];
    if (!pcol) {
      let dfx = this.dfx;
      let obj = { "id": "dfx_col1", "diffuse": [1, 1, 1], "specular": [0, 0, 0], "specularPower": 25, "ambient": [1, 1, 1], "emissive": [0, 0, 0], "backFaceCulling": bfc }
      let obj_transp = { "id": "dfx_col2_transparent", "diffuse": [1, 1, 1], "alpha": trans, "specular": [0, 0, 0], "specularPower": 25, "ambient": [1, 1, 1], "emissive": [0, 0, 0], "backFaceCulling": bfc }
      obj.id = prefix + "dfx_" + col;
      obj.diffuse = this.getHexToRGB(col);
      obj.ambient = [obj.diffuse[0] / 4, obj.diffuse[1] / 4, obj.diffuse[2] / 4]
      //obj.emissive = [obj.diffuse[0] / 6, obj.diffuse[1] / 6, obj.diffuse[2] / 6]
      if (addTex) {
        obj['diffuseTexture'] = { "name": this.getTexture(8, 8, col) }
      } else {

      }
      //obj.ambient = obj.diffuse;
      let mat = [obj];
      dfx.addNewMaterials(mat);
      obj_transp.id = prefix + "dfx_" + col + "_transparent";
      obj_transp.diffuse = obj.diffuse;
      obj_transp.ambient = obj.ambient;
      obj_transp.emissive = obj.emissive;
      if (addTex) {
        obj_transp['diffuseTexture'] = obj['diffuseTexture']
      } else {
      }
      //obj_transp.ambient = obj.diffuse;
      obj_transp.alpha = trans;
      let mat2 = [obj_transp];
      dfx.addNewMaterials(mat2);
      this.color_map[col] = prefix + "dfx_" + col
    }
    return this.color_map[col];
  }
  getActiveComp() {
    const hit = this.dfx.getHitPosition();
    if (!hit.hit) {
      return null;
    }
    const hpt = hit.pickedPoint;
    return [hit.pickedMesh, hpt];
  }
  checkAndToggleRHState(id, comps, p) {
    let items = this.getItems(id, comps);
    items.forEach((i) => {
      let item = comps[i];
      item.istate = !item.istate;
      this.showHideRHIndicator(i, item.istate, p)
    })
  }
  getItems(id, comps) {
    let items = []
    for (let m in comps) {
      if (comps[m].id === id) {
        items.push(m);
      }
    }
    return items;
  }

  getShipName(id) {
    if (!id) {
      return "";
    }
    return id.split(".")[0].split("element-")[1];
  }
  /**
   * Utility
   */
  getMeshName(id, mesh = null) {
    let _mesh
    if (mesh) {
      _mesh = mesh
    } else {
      let el = this.datauxview.getElementId(id);
      _mesh = this.dfx.getElementMesh(el);
    }
    let name = null;
    if (_mesh) {
      let str = _mesh.id
      let arr = str.split(".");
      name = arr[arr.length - 1];
    }
    return name;
  }
  getMesh(id) {
    let el = this.datauxview.getElementId(id);
    let mesh = this.dfx.getElementMesh(el);
    return mesh;
  }
  getChildMesh(id, cid) {
    let el = this.datauxview.getElementId(id);
    let mesh = this.dfx.getElementMesh(el);
    let meshes = mesh.getChildMeshes();
    let idx = meshes.findIndex(e => e.name.includes("." + cid));
    if (idx > -1) {
      return meshes[idx];
    }
    return null;
  }
  getChildMeshEx(id, cid) {
    let el = this.datauxview.getElementId(id);
    let mesh = this.dfx.getElementMesh(el);
    let meshes = mesh.getChildMeshes();
    let idx = meshes.findIndex(e => this.getMeshName(null, e) === cid);
    if (idx > -1) {
      return meshes[idx];
    }
    return null;
  }
  moveCameraTo(el, view = null) {
    let dfx = this.datauxview.getDatascape();
    let camera = dfx.getCamera();
    let _view = view;
    if (!view) {
      let dr = this.getElemRadius(el);
      let target = this.getElemCenter(el);
      let ds = dr / Math.tan(camera.fov / 2);
      ds = Math.max(20, ds);
      _view = { distance: ds, target }
    }
    dfx.moveCamera(_view);
  }
  getElemCenter(el) {
    let dfx = this.datauxview.getDatascape();
    let id = dfx.props(el).id;
    let target;
    let mesh = dfx.getElementMesh(el);
    // if(this.extras.includes(id)){
    // target=this.getAverageCenter(mesh);
    // }else{
    let c = mesh.getBoundingInfo().boundingSphere.centerWorld;
    target = { x: c.x, y: c.y, z: c.z }
    // }
    return target;
  }

  getElemRadius(el) {
    let dfx = this.datauxview.getDatascape();
    let id = dfx.props(el).id;
    let mesh = dfx.getElementMesh(el);
    let c = id ? this.getMaxRadius(mesh) : mesh.getBoundingInfo().boundingSphere.radiusWorld;
    return c;
  }

  getMaxRadius(m) {
    let meshes = m.getChildMeshes();
    let l = meshes.length;
    let c = Number.MIN_VALUE
    meshes.forEach((mesh) => {
      if (mesh.isEnabled() && mesh.isVisible && mesh.material.alpha) {
        let pt = mesh.getBoundingInfo().boundingSphere.radiusWorld;
        c = Math.max(c, pt)
      }
    });
    return c;
  }
  distance(p1, p2) {
    let dx0 = p1.x - p2.x;
    let dz0 = p1.z - p2.z;
    let ds0 = Math.sqrt(dx0 * dx0 + dz0 * dz0)
    return ds0;
  }
  distance3(p1, p2) {
    let dx0 = p1.x - p2.x;
    let dy0 = p1.y - p2.y;
    let dz0 = p1.z - p2.z;
    let ds0 = Math.sqrt(dx0 * dx0 + dy0 * dy0 + dz0 * dz0)
    return ds0;
  }
  /**
   * create custom profiles
   */
   createBasicShapes() {
    let dfx = this.datauxview.getDatascape();
    dfx.addShape('cylinder', [{ name: 'cylinder', height: 1, diaTop: 1, diaBottom: 1, tesselation: 32 }]);
  }
  generateCompCols() {
    for (let m in this.radhaz_comps) {
      let cdata = this.radhaz_comps[m];
      let col = cdata.color;
      if (!col) {
        col = "#FFA500"
      }
      this.addNewProfileColor(col);
    }
    //#ff0000
    this.addNewProfileColor('#bb0000', true, "", null, 0.5);
    this.addNewProfileColor('#00ff00');
    this.addNewProfileColor('#ff0000');
    this.addNewProfileColor('#337e9f');
    this.addNewProfileColor('#aaEEaa');
  }
  showActivityEnvelope(activity,emitterlocation,boo){
    let _shipId='ehfx';
    let act = activity.toLowerCase()
    if(this.isRHEmitter(activity.toLowerCase())){
      this.showHideRHCylinder(_shipId, boo, emitterlocation,act)
    }else if(activity.toLowerCase()==='man-aloft'){
      this.showHideMAIndicator(_shipId, boo, emitterlocation)
    }else{
      //
      this.showHideRHCylinder(_shipId, boo, emitterlocation)
    }
  }
  /**
   * For Duty watch
   */
  temp_acts=[];
  activity_overlap=null;
   showOutline(mesh, size = null, color = null) {
    mesh.enableEdgesRendering();
    mesh.edgesWidth = size || 10.0;
    let matColor = this.getColor(color);
    mesh.edgesColor = matColor || new BABYLON.Color4(1.0, 1.0, 0.0, 1);
  }
  hideOutline(mesh) {
    /* mesh.renderOutline = false; */
    mesh.disableEdgesRendering();
  }
  getColor(hex) {
    if (!hex) return null;
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    let color = result ? [parseInt(result[1], 16) / 255, parseInt(result[2], 16) / 255, parseInt(result[3], 16) / 255] : [0, 0, 0];
    var r = color[0];
    var g = color[1];
    var b = color[2];
    return new BABYLON.Color4(r, g, b, 1);
  }
  hideTempActivities(){
    this.temp_acts.forEach((act)=>{
      this.showActivityInView(act,false);
    })
    /* this.temp_activities_inview=!true;
    this.activity_show_status=""; */
  }
  showTempActivities(d=null){
    this.temp_acts.forEach((act)=>{
      this.showActivityInView(act,true);
    })
    /* this.temp_activities_inview=!true;
    this.activity_show_status=""; */
  }
  rerenderTempActivities(){
    this.hideTempActivities();
    this.showTempActivities();
  }
  showActivityInView(act,boo=true,col=null){
    this.showHideEmitterEnvelope(act,boo,col||act.groupColor);
  }
  showHideEmitterEnvelope(act,boo=true,col=null) {
      let state = boo;
      let shipId = 'ehfx';
      let ma = act.manAloftLocation?act.manAloftLocation.length>1?act.manAloftLocation:"":"";
      let em = act.emitterLocation?act.emitterLocation.length>1?act.emitterLocation:"":"";
      let activity = act.activity?act.activity.toLowerCase():"";
      if(this.isRHEmitter(activity)){
        //this.radhazVisServ
        this.showHideRHCylinder(shipId, state, em,activity,col);
        //this.broadcastInfo({ src: 'chitStepper', event: 'radhaz_rhIndi', data: { shipId, state, id: act.emitterLocation } });
      }else if(activity==='man-aloft'){
        this.showHideMAIndicator(shipId, state, ma,col);
        //this.broadcastInfo({ src: 'chitStepper', event: 'radhaz_maIndi', data: { shipId, state, id: ma } });
      }
     /*  else if(act.activity.toLowerCase()==='antenna rotating'){
        this.showHideMAIndicator(shipId, state, ma);
        //this.broadcastInfo({ src: 'chitStepper', event: 'radhaz_maIndi', data: { shipId, state, id: ma } });
      } */
  }
  getCylindersOfActs(data){
    return Object.keys(data).map(key => data[key]);
  }
  addProfileToActCylinders(prf){
    let cyls=[]
    let cyls_1=this.getCylindersOfActs(this.activityServ.radhaz_cylinders['ehfx']);
    let cyls_2=this.getCylindersOfActs(this.activityServ.ant_radhaz_cylinders['ehfx']);
    let cyls_3=this.getCylindersOfActs(this.activityServ.ant_rot_cylinders['ehfx']);
    cyls=cyls.concat(cyls_1,cyls_2,cyls_3);
    this.addNewProfileToModels(prf,cyls);
  }
  addNewProfileToModels(prf, models = null) {
    let dfx = this.dfx;
    let prfs = { profiles: {} }
    let _p = {
      material: "",
      visible: true,
      selectable: true,
      transition: 0
    };
    let p = this.clone(_p);
    p.material = prf;
    prfs.profiles[prf] = p;
    p = this.clone(_p);
    p.material = prf + "_transparent";
    prfs.profiles[prf + "_transparent"] = p;
    if (models) {
      models.forEach((model)=>{
        dfx.addProfiles(model, prfs.profiles);
      })
    }
  }
  /* getActivityOverlap(chit){
    this.activity_overlap=null
    this.temp_acts.forEach((activity)=>{
      if(chit.activity===activity.activity){
        if(chit.activity==='man-aloft'&&(chit.manAloftLocation===activity.manAloftLocation)){
          if(!this.activity_overlap){
            this.activity_overlap=[]
          }
          this.activity_overlap.push(activity);
        }
      }
      if(chit.activity==='radiating'&&(chit.emitterLocation===activity.emitterLocation)){
        if(!this.activity_overlap){
          this.activity_overlap=[]
        }
        this.activity_overlap.push(activity);
      }

    })
    return this.activity_overlap
    //this.broadcastInfo({ src: 'main', event: 'on-activities-overlap', data: this.activity_overlap.slice(), key: "" });
  } */
  
  // checkCollisionWithOA(ship, act, comp, rad) {
  //   let cyl = this.getCylinderStore(act);
  //   let k = 'envelope-' + s + '-' + ship;
  //   let mel = cyl[ship][k];
  //   let mmel = this.dfx.getElementMesh(mel);
  //   let sobj = this.getARHCompStore(act, ship);
  //   let colls = [];
  //   let sid = this.getDemoShipName(ship);
  //   comp.forEach((s) => {
  //     let mesh = this.getMesh(s.id);
  //     console.log(mesh)
  //     let cstate = mesh.isVisible && mesh.material.name !== 'man_aloft';
  //     if (cstate && mel.intersectsMesh(mesh, !true) && this.verifyCollision(mel, mesh, rad)) {
  //       colls.push(sid + "/" + sobj[0].id);
  //       sobj.colluded = true;
  //     } else {
  //       //sobj.colluded=!true;
  //     }
  //   })
  //   return colls;
  // }
radiateCollision = [];
  checkSelfCollisionForActivities(ship, comp, act, actobj=null) {
    this.resetCollisionStatus();
    let src = {};
    let rh = this.getARHCompStore(act, ship)
    let loc = this.isRHEmitter(act)?actobj.emitterLocation:actobj.manAloftLocation;
    if (this.isRHEmitter(act)) {
      let _rh = {}
      _rh[loc] = rh[loc]
      src = _rh
    }
    let keys = Object.keys(src);
    let colls = [];
    keys.forEach((s) => {
      let sobj = src[s];
      let k = 'envelope-' + s + '-' + ship;
      let colls2 = [];
      let ant = this.getAntCode(act);
      if (ant) {
        k = k + "-" + ant;
      }
      let cyl = this.getCylinderStore(act);
      let mel = cyl[ship][k];
      let mmel = this.dfx.getElementMesh(mel);
      colls = this.checkCollisionWithOA(ship, mmel, comp, sobj.radius);
      sobj.incollision = [].concat(colls, colls2);
      mmel.oncollision = sobj.incollision && sobj.incollision.length;
      console.log("collision data", colls);
    })
    this.radiateCollision = colls;
    return colls.length > 0 ? true : false;
  }
  checkCollisionWithOA(ship, mel, comp, rad) {
    let colls = [];
    let p = "";
    let sid = this.getDemoShipName(ship);
    comp.forEach((s) => {
      let mesh = this.getMesh(s.id);
      let cstate = mesh.isVisible && mesh.material.name!==null && mesh.material.name !== 'man_aloft';
      if (cstate && mel.intersectsMesh(mesh, !true) && this.verifyCollision(mel, mesh, rad)) {
        colls.push(sid + "/" + s.id);
        s.colluded = true;
      } else {
        //sobj.colluded=!true;
      }
    })
    return colls;
  }
  getAloft(shipId){
    return  this['aloft_comps_' + 'ehfx'][shipId];
  }
}
