import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CommunicationService } from "../services/communication.service";
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { ConfigService } from '../services/config.service';
import { ControllerService } from "../services/controller.service";

@Component({
  selector: 'app-admin-state-board',
  templateUrl: './admin-state-board.component.html',
  styleUrls: ['./admin-state-board.component.scss']
})
export class AdminStateBoardComponent implements OnInit, AfterViewInit {
  // layoutList = [];
  stateBoardForm: any = false;
  dynamicForm: FormGroup;
  orderList: any = [1, 2, 3, 4, 5, 6, 7, 8];
  stateBoardName: any = "";
  stateBoardDataList: any = [];
  stateBoardList: any = [];
  userRoleFilter: any;
  @Input() cfbport: any;
  @Input() set userRole(v: any) {
    if (v) {
      this.adminPanel = false;
      this.adminHandleToggleOpen = false;
    } else {
      this.adminPanel = false;
      this.broadcastInfo({ src: 'dynamic-layout', event: 'closeStateboard', data: "", key: true });
      this.broadcastInfo({ src: 'dynamic-layout', event: 'closeTurnover', data: "", key: true });
    }
    this.userRoleFilter = v;
    this.getStateBoardData();
  }

  selectedStateBoard: any;
  constructor(private ctrlServ: ControllerService, private configService: ConfigService, private communicationServ: CommunicationService, private fb: FormBuilder) {
    // this.broadcastInfo({ src: 'loader', event: 'loaderState', data: { state: true, process_txt: "Processing...", selectedDemo:"", event: 'showBlockLoading' } });
    this.formCreate();
    // this.selectedStateBoard=this.stateBoardList[6];
    // this.layoutList = this.selectedStateBoard['layoutList'];
    // console.log("this.layoutList1",this.layoutList);
    // this.assignOrder()
  }
  formCreate() {
    this.dynamicForm = this.fb.group({
      stateBoardName: ["", [Validators.required]],
      role: ["", [Validators.required]],
      // layoutList: this.fb.array([this.createLayoutGroup()])
    });
  }
  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }

  filterRole() {
    var filterRole = [];
    let filterId = this.stateBoardDataList.filter(e => e['role'] == this.userRoleFilter);
    if (filterId.length > 0) {
      // filterRole=this.stateBoardDataList.filter((o1,idx) => .some(o2 => o1['_id'] === o2));
      console.log("filterRole", filterId);
      this.stateBoardList = filterId;
    }
  }
  /* * *
  * get State board
  * * */
  getStateBoardData() {
    this.configService.getStateBoardListData().then((response: any) => {
      this.intervalState = false;
      console.log("getStateBoard ", response);
      this.stateBoardDataList = response.filter(e => e.port == this.cfbport);
      this.stateBoardList = JSON.parse(JSON.stringify(this.stateBoardDataList));
      console.log("getStateBoard-port", this.stateBoardList );
      if (this.userRoleFilter) {
        this.filterRole();
        this.orderListMode = "view";
        if (this.stateBoardList.length > 0) {
          // this.activeState(this.stateBoardList[0]);
          if(!this.formStateBoardData){
          this.viewStateBoardData = this.stateBoardList[0];
          this.viewStateBoard(this.viewStateBoardData, this.orderListMode);
        }
          this.broadcastInfo({ src: 'admin-SB', event: 'stateBoardList', data: this.stateBoardList, userRoleFilter: this.userRoleFilter });
          return
        }
      } else {
        this.broadcastInfo({ src: 'admin-SB', event: 'stateBoardList', data: this.stateBoardList, userRoleFilter: this.userRoleFilter });
      }
      if(this.selectedStateBoard){
        if (this.stateBoardList.length > 0) {
          this.selectedStateBoard = this.stateBoardList[0];
        }        
        this.broadcastInfo({ src: 'admin-SB', event: 'orderLayout', data: this.selectedStateBoard, mode: this.orderListMode });
      }
     
      if (!this.selectedStateBoard && this.stateBoardList.length > 0) {
        // this.activeState(this.stateBoardList[0]);
      } else {
        if (this.deleteDataStateBoard){
          if(this.selectedStateBoard['_id'] == this.deleteDataStateBoard['_id']) {
            this.broadcastInfo({ src: 'admin-SB', event: 'stateBoardView', data: false });
            delete this.selectedStateBoard;
            delete this.viewStateBoardData;
            delete this.formStateBoardData;
            this.layoutData = [];
            this.orderListMode = 'view';
       
            return
          }
          if(this.formStateBoardData['_id'] == this.deleteDataStateBoard['_id']) {
            this.broadcastInfo({ src: 'admin-SB', event: 'stateBoardForm', data: false });
            delete this.selectedStateBoard;
            delete this.viewStateBoardData;
            delete this.formStateBoardData;
            this.layoutData = [];
            this.orderListMode = 'view';
            // if(this.stateBoardList.length>0){
            return
          }
        }
          if(this.selectedStateBoard['_id'] == this.deleteDataStateBoard['_id']) {
          this.broadcastInfo({ src: 'admin-SB', event: 'stateBoardView', data: false });
          delete this.selectedStateBoard;
          delete this.viewStateBoardData;
          delete this.formStateBoardData;
          this.layoutData = [];
          this.orderListMode = 'view';
          // if(this.stateBoardList.length>0){
          //   // this.activeState(this.stateBoardList[0]);
          //   this
          // }       
          return
        }

      }
            if (this.formStateBoardData) {
              if(this.formStateBoardData['_id'] == this.deleteDataStateBoard['_id']) {
        
               this.broadcastInfo({ src: 'dynamic-layout', event: 'closeStateboard', data: "", key: true });
                return
              }
        let stateBoardData = this.stateBoardList.filter(e => e['_id'] == this.formStateBoardData['_id']);
        this.formStateBoard(stateBoardData[0], this.orderListMode);
        return;
      }
      delete this.deleteDataStateBoard;
      if (this.viewStateBoardData) {
        let stateBoardData = this.stateBoardList.filter(e => e['_id'] == this.viewStateBoardData['_id']);
        this.viewStateBoard(stateBoardData[0], this.orderListMode);
        return;
      }

    }).catch((error) => {
      // console.log("error ", error);
      // cbck(error)
    });
  }

  /* * *
  * Create State board
  * * */
  createStateBoard(data) {
    this.configService.createStateBoard(data).then(data => {
      if (data) {
        console.log("Created StateBoard", data);
        this.getStateBoardData();
        // cbck(data)
      }
    }).catch((error) => {
      // console.log("error ", error);
      // cbck(error)
    });
  }

  /* * *
  * Update State board
  * * */
  updateStateBoard(data) {
    console.log("update", data);
    if (data) {
      this.configService.updateStateBoard(data._id, data).then(data => {
        if (data) {
          console.log("stateborad updated ", data);
          this.getStateBoardData();
          // cbck(data)
        }
      }).catch((error) => {
        // console.log("error ", error);
        // cbck(error)
      });
    }

  }

  /* * *
  * Delete State board
  * * */
  deleteStateBoard(_id) {
    this.configService.deleteStateBoard(_id).then(data => {
      if (data) {
        console.log("group deleted ", data);
        this.getStateBoardData();
      }
    }).catch((error) => {
      // console.log("error ", error);
    });
  }

  // deleteStateBoardConfirm(layoutid ,id, tag){
  //   // this.selectedLayoutList
  //   this.markerName = tag.name;
  //    this.selectedNotesId = id;
  //    this.layoutId  = layoutid;
  //   //  return;
  //   this.broadcastInfo({ src: 'material', event: 'openDialogConfirmStateBoardDelete', data: this.layoutId, 'notesId': this.selectedNotesId, sub: null });
  //  }
  deleteDataStateBoard: any;
  deleteStateBoardConfirm(item) {
    console.log(item)
    this.deleteDataStateBoard = item;
    this.broadcastInfo({ src: 'admin-SB', event: 'openDialogConfirmStateBoardDelete', data: "", key: true });
  }
  deleteStateBoardData() {
    this.deleteStateBoard(this.deleteDataStateBoard['_id']);
    // delete this.deleteDataStateBoard;

    // this.broadcastInfo({ src: 'sidepanel', event: 'delete-notes-data', data: this.tagNotesDelete });


    // delete this.activeLayoutId
    // delete this.activeLayoutIndex
  }

  assignOrder() {
    //  if(this.layoutList.length>0){
    //   this.layoutList.forEach((element,idx) => {


    //   });
    //  }

  }

  //  arrayMove(fromIndex, toIndex) {
  // var element = this.layoutList[fromIndex];
  // // this.layoutList.splice(fromIndex, 1);
  // this.layoutList.splice(toIndex, 0, element);
  // moveArrayItemToNewIndex(arr, old_index, new_index) {
  // if (toIndex >= this.layoutList.length) {
  //     var k = toIndex - this.layoutList.length + 1;
  //     while (k--) {
  //       this.layoutList.push(undefined);
  //     }
  // }
  // this.layoutList.splice(toIndex, 0, this.layoutList.splice(fromIndex, 1)[0]);
  // console.log("arraymove",fromIndex,this.layoutList);
  // return arr; 
  // };
  // }
  /*****
   * drag and drop value
   */
  // ondrop(event:any) {
  //   moveItemInArray(this.layoutList, event.previousIndex, event.currentIndex);
  //   console.log("this.layoutList2",this.layoutList)
  //   // this.orderChange();
  // }
  // drop(event:any) {
  //   moveItemInArray(this.layoutList, event.previousIndex, event.currentIndex);
  //   console.log("this.layoutList3",this.layoutList);

  // }

  // orderChange(){
  //   if(this.layoutList.length>0){
  //     this.layoutList.forEach((element,idx) => {
  //       this.layoutList[idx]['order']=(idx+1);
  //     });
  //    }
  // }

  activeState(item) {
    return;
    this.selectedStateBoard = item;
    this.sortByStateboardOrder();
    // this.layoutList = item['layoutList'];
    // if( this.editWindow){
    //   this.stateBoardForm = true;
    //   this.formCreate();
    //   this.seedFiltersFormArray();
    // }
    delete this.editWindow;
    this.stateBoardForm = false;
    this.formCreate();
    delete this.userError;


  }
  sortByStateboardOrder() {
    let key = 'order';
    let direction = 1;
    this.selectedStateBoard['layoutList'].sort((a, b) => {
      if (a[key] < b[key]) {
        return -1 * direction;
      }
      else if (a[key] > b[key]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  }

  updateStateBoardForm(data){
    let stateBoard = data.selectedStateBoard;
    if(stateBoard&&stateBoard.formList&&stateBoard.formList.length>0){
      let idx = stateBoard.formList.findIndex(e=> e['name']==data.form.name);
      if(idx>-1){
        stateBoard['formList'][idx]=data.form;
      }else{
        stateBoard['formList'].push(data.form);
      }
    }else{
      stateBoard['formList']=[];
      stateBoard['formList'].push(data.form);
    }
    console.log("updateStateBoardForm",stateBoard);
    this.updateStateBoard(stateBoard);
  }
  broadcastSubs
  sidepanelLayoutData: any = [];
  layoutData: any = [];
  smartDrawer:boolean=false;
  ngOnInit(): void {
    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src == "turnoverSheet") {
          if (data.event === 'updatedStateboard') {
            let formData={form:data['data'],selectedStateBoard:this.formStateBoardData}
            console.log("updatedStateboard",formData)
            this.updateStateBoardForm(formData);
          }
        }
        if (data.src == "dynamic-layout") {
          if (data.event === 'closeStateboardViewIcon') {
            this.stateBoardViewIcon = true;
            this.stateBoardCloseIcon = false;
          }
        }
        if (data.src == "navyApp") {
          if (data.event === 'layoutData') {
            this.layoutData = data['data'];
            console.log("layoutData-admin", this.layoutData);
          }
          if (data.event == "deleteLayoutUpdate") {
            let id = data['data'];
            this.stateboradLayoutRemove(id);
            if (this.viewStateBoardData) {
              let stateBoardData = this.stateBoardList.filter(e => e['_id'] == this.viewStateBoardData['_id']);
              this.viewStateBoard(stateBoardData[0], this.orderListMode)
            }
            // let idx = this.adminLayoutOrderList.findIndex(e=> e['_id']==id);
          }
        }
        if (data.src == "main") {
          if (data.event == "deleteStateBoardData") {
            this.deleteStateBoardData();
          }
        }
        if (data.src == "dynamic-layout") {
          if(data.event == "formStateboardView"){
            this.formStateBoard(data['data'],'change');
          }
          if (data.event == "addLayout") {
            let stateBoardData = data['data'];
            this.addLayoutfromCard(stateBoardData);
          }
          if (data.event == "closeStateboard") {
            delete this.viewStateBoardData;
            delete this.formStateBoardData;
          }
          if (data.event == "editStateboardName") {
            this.editStateBoard(data['data']);
          }
          if (data.event == "changeView") {
            this.viewStateBoard(data['data'], 'view');
          }
        }
        if (data.src === "sidepanel") {
          if (data.event == "stateBoardClose") {
            this.stateBoardForm = false;
            this.adminPanel = data['data'];
            this.adminHandleToggleOpen = false;
            if (data['key']) {
              this.adminPanel = false;
              this.adminHandleToggleOpen = false;
            } else {
              this.adminPanel = false;
              this.broadcastInfo({ src: 'dynamic-layout', event: 'closeStateboard', data: "", key: true });
            }
            this.userRoleFilter = data['key'];
            this.getStateBoardData();
          }
          if (data.event == "stateBoardEnable") {
            this.stateBoardForm = false;
            this.adminPanel = data['data'];
            this.adminHandleToggleOpen = false;
            delete this.viewStateBoardData;
          }
          if(data.event=="stateBoardToggle"){
            this.smartDrawer=true
            this.stateBoardForm = false;
            this.adminHandleToggleOpen =data['data'];
          }
          if (data.event === 'reload-state-borard') {
            this.reloadStateBoard();
          }
        }        
      })

    // this.addlayoutToLayoutsFormArray();
    // Uncomment the line below If you want to seed the Form with some data

  }
  stateboradLayoutRemove(id) {
    if (this.stateBoardList.length > 0) {
      this.stateBoardList.forEach(ele => {
        let idx = ele['layoutList'].findIndex(e => e['_id'] == id)
        if (idx > -1) {
          ele['layoutList'].splice(idx, 1);
          this.updateStateBoard(ele);
        }
      })
    }
  }
  interval: any;
  intervalState: boolean = true;
  userList: any;
  roles = ["CO", "OOD", "DPO", "QM", "MSEO", "CSEO", "CCFL", "CCFP", "WFM", "QHM", "FLEETWAY"];
  ngAfterViewInit(): void {
    // this.broadcastInfo({ src: 'loader', event: 'loaderState', data: { state: false, process_txt: "Processing...", selectedDemo:"", event: 'showBlockLoading' } });
    this.interval = setInterval(() => {
      if (this.intervalState) {
        this.getStateBoardData();
      } else {
        if (this.interval) {
          clearInterval(this.interval);
        }
      }
      //  this.userList= this.configService.userList;
    }, 3000);

  }

  seedFiltersFormArray() {
    if (this.selectedStateBoard) {
      this.dynamicForm.controls['stateBoardName'].setValue(this.selectedStateBoard.name);
      this.dynamicForm.controls['role'].setValue(this.selectedStateBoard.role);
      if (this.selectedStateBoard['layoutList'].length > 0) {

        // this.selectedStateBoard['layoutList'].forEach((layout,idx) => {
        //   // const formGroup = this.createLayoutGroup();
        //   // if (seedDatum.apiType) {
        //   //   formGroup.addControl('value', this.getFormControl());
        //   // }
        //   // formGroup.patchValue(seedDatum);
        //   // this.layoutFormArray.push(formGroup);
        //   if(idx>0){
        //     this.addlayoutToLayoutsFormArray();
        //   }
        //   let filterLayout = this.layoutData.filter(e=> e['_id']==layout['_id']);
        //   let filterlayoutData = filterLayout.length>0?filterLayout[0]:"";
        //   this.dynamicForm.controls['layoutList']['controls'][idx]['controls']['layout'].setValue(filterlayoutData);
        //   this.dynamicForm.controls['layoutList']['controls'][idx]['controls']['order'].setValue(layout.order);
        // });
        this.dynamicForm.controls['stateBoardName'].setValue(this.selectedStateBoard.name);
        console.log("dynamicForm", this.dynamicForm)
      }
    }
  }

  createLayoutGroup() {
    return this.fb.group({
      layout: ["", [Validators.required]],
      role: ["", [Validators.required]]
    });
  }

  addlayoutToLayoutsFormArray() {
    this.layoutFormArray.push(this.createLayoutGroup());
  }

  removelayoutToLayoutsFormArray(index) {
    this.layoutFormArray.removeAt(index);
  }

  getFormControl() {
    return this.fb.control(null);
  }
  checkOrderList(order) {
    let layoutList = this.dynamicForm.value['layoutList'];
    var status = false;
    layoutList.forEach(e => {
      if (e.order == order) {
        status = true;
      }
    })
    return status;
  }

  checkLayoutList(layout) {
    let layoutList = this.dynamicForm.value['layoutList'];
    var status = false;
    layoutList.forEach(e => {
      if (e.layout != "" && layout['_id'] != "") {
        if (e.layout['_id'] == layout['_id']) {
          status = true;
        }
      }

    })
    return status;
  }
  addLayoutfromCard(e) {
    if (this.viewStateBoardData) {
      let stateBoardData = this.stateBoardList.filter(e => e['_id'] == this.viewStateBoardData['_id']);
      if (stateBoardData.length > 0) {
        if (stateBoardData[0]['layoutList'] && stateBoardData[0]['layoutList'].length > 0) {
          let orderIdx = stateBoardData[0]['layoutList'].findIndex(ele => ele['order'] == e['order']);
          if (e['layout'] == 'none') {
            if (orderIdx > -1) {
              stateBoardData[0]['layoutList'].splice(orderIdx, 1);
              this.updateStateBoard(stateBoardData[0]);
            }
          } else {
            if (orderIdx > -1) {
              stateBoardData[0]['layoutList'].splice(orderIdx, 1);
              stateBoardData[0]['layoutList'].push({ _id: e['layout']['id'], name: e['layout']['layoutName'], order: e.order });
              this.updateStateBoard(stateBoardData[0]);
            } else {
              stateBoardData[0]['layoutList'].push({ _id: e['layout']['id'], name: e['layout']['layoutName'], order: e.order });
              this.updateStateBoard(stateBoardData[0]);
            }
          }
        } else {
          stateBoardData[0]['layoutList'].push({ _id: e['layout']['id'], name: e['layout']['layoutName'], order: e.order });
          this.updateStateBoard(stateBoardData[0]);
        }

      }
    }
  }

  adminPanel: boolean = false;
  adminHandleToggleOpen: boolean = false;
  toggleAdminHanddle() {
    this.adminHandleToggleOpen = !this.adminHandleToggleOpen;
  }
  getPanelwidth(e) {
    let elem = document.getElementById(e);
    var width = elem.offsetWidth;
    return 'translateX(' + width + 'px)';

  }
  save() {
    if (this.userError) {
      return;
    }
    console.log(this.dynamicForm.value);
    var stateBoardData = { name: this.dynamicForm.value['stateBoardName'], port: this.cfbport, layoutList: [], role: this.dynamicForm.value['role'] }
    // let layoutList = this.dynamicForm.value['layoutList'];
    // if(this)
    // if(layoutList&&layoutList.length>0){
    //   layoutList.forEach(e=>{
    //     stateBoardData['layoutList'].push({_id:e.layout['_id'],name:e.layout['layoutName'],order:e.order});
    //   });
    // }

    console.log("stateBoardData", stateBoardData);
    if (this.editWindow) {
      // delete this.selectedStateBoard;
      // this.layoutList=[];
      stateBoardData['_id'] = this.selectedStateBoard['_id'];
      stateBoardData['layoutList'] = this.selectedStateBoard['layoutList'];
      this.updateStateBoard(stateBoardData);
    } else {
      this.createStateBoard(stateBoardData);
    }
    this.closeFormWindow();
  }

  get layoutFormArray() {
    return (<FormArray>this.dynamicForm.get('layoutList'));
  }

  createStateBoardForm() {
    this.stateBoardForm = true;
    delete this.editWindow;
    this.formCreate();
    delete this.userError;
  }
  editStateBoard(item) {
    // if(this.selectedStateBoard){
    // this.activeState(item);
    // }
    this.selectedStateBoard = item;
    this.stateBoardForm = true;
    this.formCreate();
    setTimeout(() => {
      this.seedFiltersFormArray();
    }, 300);

    this.editWindow = item;
    delete this.userError;
  }
  editWindow
  closeFormWindow() {
    delete this.editWindow;
    this.stateBoardForm = false;
    // delete this.selectedStateBoard;
    // this.layoutList=[];
    this.formCreate();
    delete this.userError;
  }
  viewStateBoardData: any;
  orderListMode;
  stateBoardViewIcon: boolean = true;
  stateBoardCloseIcon: boolean = false;
  viewStateBoard(stateBoard, mode) {
    // this.closeWindow();
    if (mode == 'close') {
      this.stateBoardViewIcon = true;
      this.stateBoardCloseIcon = false;
      this.broadcastInfo({ src: 'dynamic-layout', event: 'closeStateboard', data: "", key: false });
      this.orderListMode = mode;
      this.stateBoardForm = false;
      this.viewStateBoardData = stateBoard;
      this.selectedStateBoard = stateBoard;
      setTimeout(() => {
        this.broadcastInfo({ src: 'admin-SB', event: 'stateBoardView', data: false });
        this.broadcastInfo({ src: 'admin-SB', event: 'orderLayout', data: stateBoard, mode: mode });
      }, 1000);
    } else {
      this.stateBoardViewIcon = false;
      this.stateBoardCloseIcon = true;
      this.broadcastInfo({ src: 'dynamic-layout', event: 'closeStateboard', data: "", key: true });
      this.orderListMode = mode;
      this.stateBoardForm = false;
      this.viewStateBoardData = stateBoard;
      this.selectedStateBoard = stateBoard;
      setTimeout(() => {
        this.broadcastInfo({ src: 'admin-SB', event: 'stateBoardView', data: true });
        this.broadcastInfo({ src: 'admin-SB', event: 'orderLayout', data: stateBoard, mode: mode });
      }, 1000);
    }

   
  }
  
  formStateBoardData:any;
  formStateBoard(stateBoard, mode){

    this.broadcastInfo({ src: 'dynamic-layout', event: 'closeStateboard', data: "", key: true });
    this.formStateBoardData=stateBoard;
    this.broadcastInfo({ src: 'admin-SB', event: 'stateBoardForm', data: true });
    this.broadcastInfo({ src: 'admin-SB', event: 'selectedStateBoardForm', data: stateBoard, mode: 'view' });
    // if(mode=='change'){
    //   this.broadcastInfo({ src: 'admin-SB', event: 'orderLayout', data: stateBoard, mode: mode });
    // }
  }
  closeWindow() {
    delete this.userError;
    this.stateBoardForm = false;
    delete this.editWindow;
    this.getStateBoardData();
    this.broadcastInfo({ src: 'admin-SB', event: 'adminView', data: false });
  }
  userError: any;
  userValidate(e) {
    var value = e.srcElement.value.toLowerCase();
    if (value == "") {
      delete this.userError;
    } else {
      let idx = this.stateBoardList.findIndex(e => e['name'].toLowerCase() == value);
      if (idx > -1) {
        if (this.editWindow) {
          if (this.editWindow['_id'] == this.selectedStateBoard['_id']) {
            if (this.selectedStateBoard['name'].toLowerCase() == value) {
              delete this.userError;
              return;
            }
          }
        }
        this.userError = "Name Already exists, try another name";
      } else {
        delete this.userError;
      }
    }
  }

  /**
   * reload stateboard data
   */
  reloadStateBoard() {
    if (this.ctrlServ.stateBoardView) {
      this.broadcastInfo({ src: 'admin-SB', event: 'orderLayout', data: this.viewStateBoardData, mode: this.orderListMode });
    }
  }
}