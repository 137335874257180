import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  loggedState: boolean = false;
  accessToken: any;
  refershToken: any;
  // url: any = 'http://localhost:3000/';
  url: any = 'https://nodesdms.azurewebsites.net/';
  constructor(private http: HttpClient) { }
  getAccessToken(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.url + 'sign/', body, { 'headers': headers })
  }
}
