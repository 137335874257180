import { Injectable } from '@angular/core';
import { Http, RequestOptions, Headers } from '@angular/http';


const objects = {};
@Injectable({
  providedIn: 'root'
})


export class XldataService {

  constructor(private _http: Http) { }

  getCanvasData(apipath, jsonFileName, proj) {
    return new Promise((resolve, reject) => {
      this.getData(apipath, jsonFileName, proj).then(
        res => {
          resolve(res);
        });
    });
  }

  getAssetsData(apipath, jsonFileName, proj) {
    return new Promise((resolve, reject) => {
      this.getData(apipath, jsonFileName, proj).then(
        res => {
          resolve(res);
        });
    });
  }

  getObjectData(apipath, jsonFileName, proj) {
    return new Promise((resolve, reject) => {
      this.getData(apipath, jsonFileName, proj).then(
        res => {
          resolve(res);
        });
    });
  }

  getObjectProperties(apipath, jsonFileName, proj) {
    return new Promise((resolve, reject) => {
      this.getData(apipath, jsonFileName, proj).then(
        res => {
          resolve(res);
        });
    });
  }

  getAnimationData(apipath, jsonFileName, proj) {
    return new Promise((resolve, reject) => {
      this.getData(apipath, jsonFileName, proj).then(
        res => {
          resolve(res);
        });
    });
  }

  getData(api,xlFileName, proj) {
    return new Promise((resolve, reject) => {
      return this._http.post(api+xlFileName, {'filename' : proj })
          .subscribe(data => {
              resolve(this.modifyStructure(data.json()));
          }, (err) => {
              reject(null);
          });
    });
  }

  modifyStructure(data) {
    let modelDefn = data;
    console.log("modelDefn :",modelDefn);
    Object.entries(modelDefn).forEach(([key, value]) => {
      objects[key] =  { 
        "type": value['type'],  
        "parent": value['parent'],
        "name"  : value['name'],
        "model" : value['model'],
        "place" :{
          "pos":{x: value['pos_x'], y: value['pos_y'], z: value['pos_z']},
          "rot":{x: value['rot_x'], y: value['rot_y'], z: value['rot_z']},
          "size":value['size']
        },
        "label": value['label']
      };
    });
    return {"objects": objects};
  }

}
