import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CommunicationService } from "../services/communication.service";
import { ChitService } from '../services/chit.service';
import { MatStepper } from '@angular/material/stepper';
import { Output } from '@angular/core';
import moment from 'moment';
import { ControllerService } from '../services/controller.service';

// import {MatStepper} from '@angular/material';
import { RadhazVisService } from "../services/radhaz.service";
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { ConfigService } from '../services/config.service';
import { TagService } from '../services/tag.service';

import { MatSnackBar } from '@angular/material/snack-bar';
import { DrawService } from '../services/draw.service';

const NO_INDEX = -1;
@Component({
  selector: 'app-stepper-form',
  templateUrl: './stepper-form.component.html',
  styleUrls: ['./stepper-form.component.scss'],
  providers: [DatePipe,
    {
      provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
    }]
})
export class StepperFormComponent implements OnInit {
  @Output() openPublishDialog: EventEmitter<any> = new EventEmitter<any>();
  @Input() loadedPort: any = 'cfbhalifax';
  @Input() shipLocations: any = [];
  @Input() shipVesselName: any = [];
  @Input() profileInfo: any = [];
  @Input() currentShipList: any = [];
  @Input() navyFilterToggle: any;
  @Input() currentShipListInfo: any = [];
  @Input() selectedLayoutList: any = []
  @Input() createForm: any
  // @Input() set navyFilterState(v: any) {
  //   if (v) {
  //     this.ctrlServ.commandingOfficer = v;
  //   }
  // }
  // @Input() set shipConfig(v: any) {
  //   if (v) {
  //     this.chitDataListsdas = v;
  //   }
  // }
  // chitDataListsdas: any = [];
  collision = false;
  isLinear = true;
  disableSelect = new FormControl(true);
  createFormGroup!: FormGroup;
  evolutionFormGroup!: FormGroup;
  locationFormGroup!: FormGroup;
  safeFormGroup!: FormGroup;
  vesselsFormGroup!: FormGroup;
  informFormGroup!: FormGroup;
  flagFormGroup!: FormGroup;
  signFormGroup!: FormGroup;
  proximityFormGroup!: FormGroup;
  completeFormGroup!: FormGroup;
  createActivityFormGroup!: FormGroup;
  createActivityCompleteFormGroup!: FormGroup;

  firstFormGroup!: FormGroup;
  secondFormGroup!: FormGroup;
  isOptional = false;
  chitDataList: any = [];
  chitStaffSup: any = [];
  chitTimePeriod: any = [];
  chitOnsiteContent: any = [];
  chitOod: any = [];
  chitEvolution: any = [];
  chitLocation: any = [];
  chitSafeExhaust: any = [];
  chitSafeExhaustAlongside: any = [];
  chitSafeExhaustAtsea: any = [];
  chitVesselProximity: any = [];
  chitQHM: any = [];
  chitAlongside: any = [];
  chitFlags: any = [];
  chitSign: any = [];
  chitSpecialCircumstances: any = [];
  chitCompleted: any = [];
  chitId: any;
  chitVeriVessel: any;
  chitVeriOOD: any;
  chitVeriTelephone: any;
  chitVeriDate: any;
  isReadonly: boolean = false;
  isAddUser: boolean = true;
  chitFormHeading: false;
  ReadOnlyData: boolean = false;
  ReadOnlyInput: boolean = true;
  editChitIcon: boolean = false;
  cancelEditChitIcon: boolean = false;
  chitDeleteIcon: boolean = false;
  chitHeaderTitle: boolean = true;
  chitHeaderEditTitle: boolean = false;
  chitHeader: boolean = false;
  chitOnEditState: boolean = false;
  createDataChitForm: boolean;
  createDataChitFormStepp1: boolean = false;
  vesselRequestApproval: boolean = false;
  chitDetailForm: any;
  azureArray: any = ['001', '002', '003', '004', '005'];
  evolutionArray: any = ['ammunitioning', 'bollard pulls', 'man-aloft', 'antenna rotating', 'ceremonial', 'cold move', 'crane work', 'diving operations', 'engine exhuasting', 'fueling operations', 'jetty service', 'radiating', 'antenna radiating', 'truck fuelling', 'man aloft/radiate', 'man aloft/rotating', 'other activities'];
  shipDetails: any = ['measurement', 'radiation efficiency', 'temperature', 'change ford ranger'];
  shipEngine: any = ['Stern Drive Engine', 'Inboard Engine', 'Outboard Engine', 'Jet Drive Engine'];
  // evolutionArray: any = ['Ammunitioning', 'Bollard Pulls', 'Man-Aloft', 'Antenna Rotating', 'Ceremonial', 'Cold Move', 'Crane Work', 'Diving Operations', 'Engine Exhuasting', 'Fueling Operations', 'Jetty Service', 'Radiating', 'Antenna Radiating', 'Truck Fuelling', 'Man Aloft/Radiate', 'Man Aloft/Rotating', 'Other Activities'];
  // evolutionArray: any = ['Man-Aloft', 'Man Aloft/Radiate', 'Diving Operations'];
  alongsideList: string[] = ['Hunter', 'Jackson', 'Noah', 'Liam', 'Benjamin', 'Oliver', 'Ethan'];
  // VesselName: any =['Ammunitioning', 'Bollard Pulls', 'Man-Aloft','Antenna Rotating','Ceremonial','Cold Move','Crane Work','Diving Operations','Engine Exhuasting','Fueling Operations','Jetty Service','Radiating','Antenna Radiating','Truck Fuelling','Man Aloft/Radiate','Man Aloft/Rotating','Other Activities']
  VesselName: any = []
  userInfo: any;
  usersInfo: any = [];
  contractorInfo: any = [];
  assignContactInfo: any = [];
  reciverInfo: any = [];
  usersDetails: any;
  activeShip: any;
  userInfoData: any = [
    { 'id': '001', 'oodName': 'john Doe', 'rank': '001', 'telephone': '6139952534' }
  ];
  shipLocation: any;
  shipStaff: any = [
    { 'id': '001', 'supName': 'Hunter', 'supRank': '001', 'supTelephone': '6139952534' },
    { 'id': '002', 'supName': 'Jackson', 'supRank': '002', 'supTelephone': '6139952534' },
    { 'id': '003', 'supName': 'Noah', 'supRank': '003', 'supTelephone': '6139952534' },
    { 'id': '004', 'supName': 'Liam', 'supRank': '004', 'supTelephone': '6139952534' },
    { 'id': '005', 'supName': 'Benjamin', 'supRank': '005', 'supTelephone': '6139952534' },
    { 'id': '006', 'supName': 'Oliver', 'supRank': '006', 'supTelephone': '6139952534' },
    { 'id': '007', 'supName': 'Ethan', 'supRank': '007', 'supTelephone': '6139952534' }
  ];
  shipStaffInfo: any = []
  OnsiteInfo: any = [
    { 'id': '001', 'onsiteName': 'john DoMateo', 'onsiteUnit': 'OC-001', 'onsiteTelephone': '6139952534', 'shop': 'department 1' },
    { 'id': '002', 'onsiteName': 'Hudson', 'onsiteUnit': 'OC-002', 'onsiteTelephone': '6139952534', 'shop': 'department 2' },
    { 'id': '003', 'onsiteName': 'Gabriel', 'onsiteUnit': 'OC-003', 'onsiteTelephone': '6139952534', 'shop': 'department 3' },
    { 'id': '004', 'onsiteName': 'Mason', 'onsiteUnit': 'OC-004', 'onsiteTelephone': '6139952534', 'shop': 'department 4' },
    { 'id': '005', 'onsiteName': 'Luca', 'onsiteUnit': 'OC-005', 'onsiteTelephone': '6139952534', 'shop': 'department 5' },
    { 'id': '006', 'onsiteName': 'Jayden', 'onsiteUnit': 'OC-006', 'onsiteTelephone': '6139952534', 'shop': 'department 6' },
    { 'id': '007', 'onsiteName': 'Nathan', 'onsiteUnit': 'OC-007', 'onsiteTelephone': '6139952534', 'shop': 'department 7' }
  ];
  OnsiteInfoData: any = [];
  azureID = new FormControl();
  options: string[] = ['001', '002', '003', '004', '005'];
  filteredOptions: Observable<string[]> | undefined;
  azureId = this.chitDataList._id;
  completed: boolean = false;
  verticalStepper = true;
  horizontalStepper = false;
  horizontalButton = true;
  verticalButton = false;
  safeAlongSideSection = false;
  safeAtSeaSection = false;
  chitWindowState: any = true;
  state!: string;
  disabled: any;
  broadcastSubs: any;
  errorDetails = false;
  startDate: '';
  loginUserID: any;
  chitFormIndex: any;
  radiatesList: any = [];
  chitActivityList: any = [];
  shipList: any = [];
  shipName: any;
  submitted = false;
  kiloHoisted = false;
  keysSecured = false;
  keyboardTaggedOut = false;
  shipsProfileBoard = false;
  startTime = '08:00';
  endTime = '16:00';
  chitCreateEvolution = 'ammunitioning';
  chitCreateLocation: any;
  chitCreateVessel: any;
  chitCreateDate: any;
  chitUserDate: any;
  onSiteContactName: any;
  chitSupervisor: any;
  chitCreateVesselName: any;
  supervisorListData: any;
  chitReload: any;
  collisionDetection: any;
  selectedShipElem: any;
  emitterLocation_src = {}
  emitterLocation: any = [];
  purposeData: any;
  manAloftLocation: any = [];
  officerName: any = '';
  @ViewChild('stepper') stepper: MatStepper;
  displayedColumns: string[];
  dataSource: any;
  chitKey: any;
  @Input() selectedTagItem: any = [];
  loginUserdata: { fName: any; lName: any; email: any; };
  currentEmailList: any;
  collisionShip: any;
  collisionShipInfo: any;
  userInfoDetails: any = [];
  @Input() cd_in_iframe: boolean = false;
  @Input() collisionMode: boolean = false;
  @Input() dockyardView: boolean = false;
  @Input() iframeInView: boolean = false;
  cancelStatus: boolean = false;
  tagLocation: any;
  selfCollisionData: any;
  contractorFormGroup!: FormGroup;
  contractorForm: boolean = false;
  visitorIds:any = [];
  contractorDetails: any;
  visitorDet: any = [];
  visitorInformation:any;
  visitorList:any;
  postFilterData: any = {
    "date": [],
    "Vessel": [],
    "type": [],
    "venue":this.loadedPort
  }
  visitorIdx = 0;
  filterVisitior = [];
  visitor:any;
  filterData = [];
  DataIdx = 0;
  filterCheck = [];
  objData = [];
  assignContact: any;
  prefixActivity: any;
  prefixLocation: any;
  constructor(private drawService: DrawService, public tagServ : TagService,private radhazVisServ: RadhazVisService, private datePipe: DatePipe, private _formBuilder: FormBuilder, private communicationServ: CommunicationService, private chitService: ChitService, private ctrlServ: ControllerService, private controllerService : ControllerService, private configService : ConfigService, private snackbar: MatSnackBar) {
    this.emitterLocation = this.radhazVisServ.radhaz_components;
    this.manAloftLocation = this.radhazVisServ.aloft_components;
    //this.manAloftLocation = this.manAloftLocation.map(loaction=>loaction.replace('_',' '));
    this.emitterLocation_src['radiating'] = this.filterLocation(this.emitterLocation, 'radiating')
    this.emitterLocation_src['antenna rotating'] = this.filterLocation(this.emitterLocation, 'antenna rotating')
    this.emitterLocation_src['antenna radiating'] = this.filterLocation(this.emitterLocation, 'antenna radiating')
    // this.dataSource = new MatTableDataSource(this.usersDetails);
    // this.displayedColumns = Object.keys(this.usersDetails)
    console.log("stepper-port",this.loadedPort);
    this.broadcastIframeSubs = this.communicationServ.getIframeInstance()
      .subscribe((data: any) => {
        if (data.iframeConnection === "dockyard") {
          if (data.event == 'dockyard-loaded-iframe') {
            this.cd_in_iframe = true;
          }
          if (data.event == 'collisionDetection') {
            if (data.data != undefined) {
              this.vesselRequestApproval = true;
              this.vesselSelfColliedRequestApproval = false;
            this.selfCollied =false;
            this.collisionShipInfo = data.data;
              console.log(this.collisionShipInfo)
              this.userAdded()
              this.createDataChitForm = false
              this.createDataChitFormStepp1 = false
              this.showMessage("Collision Detected! Show Dockyard!")
            }
          }
          if (data.event === 'chitNotification') {
            let index = data['data'];
            if (index == 'yes') {
              this.CreateChitActivity();
            }
          }
        }

      })
  }
  showMessage(msg) {
    const snack = this.snackbar.open(msg, 'Show', { duration: 30000 });
    snack
      .onAction()
      .subscribe(() => {
        this.broadcastInfo({ src: 'chit', event: 'show-dockyard', data: true, key: "" });
      });
  }
  ngOnDestroy() {
    this.broadcastSubs.complete();
    this.broadcastIframeSubs.complete();
    /**
     * Clear all listeners, data storages from this component;
     */
  }
  chitViewData: any;

  ngOnInit() {
    console.log("stepper-form-init");
    //*** CONTRACTOR FORM ***/
    this.contractorFormGroup = this._formBuilder.group({
      _id: '',
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      emailID: ['', Validators.email],
      mobileNo: ['', Validators.required],
      role: [''],
      rank: [''],
      companyName:['']
    });
    this.visitorDet = [];
    this.visitorIds = [];

    window['sform']=this;
    this.getUserData();
    this.radiateList();
    this.getContractorList();
    this.getVisitorList();
    this.getLayoutList();
    // this.getChitActivityData();
    this.supervisorList();
    this.officerName = this.profileInfo.userName;
    if (!this.createForm) {
      this.createDataChitForm = true;
    } else {
      this.createDataChitForm = false;
    }
    // setTimeout(() => {
    //   this.supervisorList();
    // }, 1000);
    // this.supervisorList();

    // this.createDataChitForm = true;
    // this.createDataChitFormStepp1 = false;
    // console.log("location details", this.shipLocations)
    if (this.selectedLayoutList && this.selectedLayoutList.length != 0) {
      var idx = this.selectedLayoutList.tagPoints.findIndex(obj => obj.tagShapeId == this.selectedTagItem.tagShapeId)
      this.selectedTagItem = this.selectedLayoutList.tagPoints[idx]
      console.log(this.selectedTagItem, this.selectedLayoutList)
    }
    // console.log("datattaa", this.shipVesselName)
    // console.log("VesselName", this.shipVesselName)
    // console.log('VesselName key', Object.keys(this.shipVesselName));

    this.VesselName = ["HAL", "VAN", "VDQ", "TOR", "REG", "CAL", "MON", "FRE", "WIN", "CHA", "STJ", "OTT", "VSR", "VIS", "ORI", "AST", "HDW", "MAR", "VIC", "WSR", "COR", "CHI", "KIN", "GLA", "NAN", "EDM", "SHA", "WHI", "YEL", "GBY", "MCT", "SAS", "BRN", "SUM", "SAC", "TUG", "ORC", "RHP", "RHB"]
    this.shipLocations = [{ "key": "A1", "value": "A1" }, { "key": "A1,2", "value": "A1,2" }, { "key": "A2", "value": "A2" }, { "key": "A2,2", "value": "A2,2" }, { "key": "B1", "value": "B1" }, { "key": "B1,2", "value": "B1,2" }, { "key": "B2", "value": "B2" }, { "key": "B2,2", "value": "B2,2" }, { "key": "C1", "value": "C1" }, { "key": "C1,2", "value": "C1,2" }, { "key": "C2", "value": "C2" }, { "key": "C2,2", "value": "C2,2" }, { "key": "C3", "value": "C3" }, { "key": "C3,2", "value": "C3,2" }, { "key": "C4", "value": "C4" }, { "key": "C4,2", "value": "C4,2" }, { "key": "C4,3", "value": "C4,3" }, { "key": "C4,4", "value": "C4,4" }, { "key": "C5", "value": "C5" }, { "key": "C5,2", "value": "C5,2" }, { "key": "YN", "value": "YN" }, { "key": "YN,2", "value": "YN,2" }, { "key": "Y1", "value": "Y1" }, { "key": "Y1,2", "value": "Y1,2" }, { "key": "YS", "value": "YS" }, { "key": "YS,2", "value": "YS,2" }, { "key": "Y2", "value": "Y2" }, { "key": "Y2,2", "value": "Y2,2" }, { "key": "Y3", "value": "Y3" }, { "key": "Y3,2", "value": "Y3,2" }]
    // shipLocations.forEach(element => {
    //   let key = this.shipLocations.findIndex(e => e.key.trim() == element.trim())
    //   if (key > -1) {
    //     this.shipLocations.splice(key, 1);
    //   }
    // });

    // console.log("LOcation...... ", this.shipLocations)

    this.userInfo = this.userInfoData[0];
    this.broadcastSubs = this.communicationServ.getInstance()

      .subscribe((data: any) => {
        // if (data.src === "main") {
        //   if (data.event === 'VesselName') {
        //     var VesselName = data['data'];
        //     console.log("123456", VesselName);
        //   }
        // }
        if (data.src == "tagPoints") {
          if (data.event == "chitNotify") {
            this.radiateCheckShip();
          }
        }
        if (data.src === "sidepanel") {
          if (data.event === "chitWindowClose") {
            this.chitWindow();
          }
          if (data.event === 'loadStepper') {
            let chitid = data['data'];
            let chitDate = data['date'];
            this.selectedShipElem = data['data'];
            // console.log("-----------------", chitDate)

            if (chitid) {
              // console.log("-----------------", chitid)
              this.collisionDetection = chitid;
              this.chitCreateEvolution = chitid.activity;
              // if (chitid.activity) {
              //   setTimeout(() => {
              this.chitDetailForm = chitid.activity ? chitid.activity : "";
              //   }, 100);
              // }
              this.createActivityFormGroup.value.Evolution = chitid.activity;
              // this.createActivityFormGroup.value.Date = chitDate;
              this.chitCreateLocation = chitid.location;

              this.chitCreateVessel = chitid.ship;
              this.chitCreateDate = new Date(this.ctrlServ.formatUTCTZ(chitDate, "DD-MMM-YYYY"));
              this.chitUserDate = this.ctrlServ.formatUTCTZ(this.chitCreateDate, 'YYYY-MM-DD');
              // var chitDate = this.datePipe.transform(data, 'yyy-MM-dd');
              // var chitCreateDate = new Date(chitDate);
              // ((new Date()).toISOString().substring(0, 10))
              this.chitCreateVesselName = chitid.shipname;
              this.supervisorList();

              // this.chitId = chitid;
              // this.isReadonly = true;
              // this.ReadOnlyData = true;
              this.chitHeaderTitle = true;
              this.chitOnEditState = true;
              this.chitHeaderEditTitle = false;
              this.getChitDataList();
              this.activeShip = data['key'];
              setTimeout(() => {
                if (this.supervisorListData?.length > 0) {
                  // var shipLocationMatch = this.supervisorListData.filter(t => t.vessel == this.chitCreateVessel);
                  this.onSiteContactName = this.supervisorListData[0].onSiteContact;
                  this.chitSupervisor = this.supervisorListData[0].supervisor_name;
                  this.onSiteContactInfo.push(this.onSiteContactName);
                  this.staffContactInfo.push(this.chitSupervisor);
                }
              }, 1000);

            } else {
              // this.createFormGroup.reset();
              // this.evolutionFormGroup.reset();
              // this.locationFormGroup.reset();
              // this.safeFormGroup.reset();
              // this.vesselsFormGroup.reset();
              // this.informFormGroup.reset();
              // this.flagFormGroup.reset();
              // this.signFormGroup.reset();
              // this.proximityFormGroup.reset();
              // this.completeFormGroup.reset();

              this.isReadonly = false;
              this.ReadOnlyData = false;
              // this.editChitIcon = false;
              this.cancelEditChitIcon = false;
              this.chitHeader = false;
              this.chitHeaderTitle = true;
              this.chitHeaderEditTitle = false;

            }
          }
          if (data.event === 'collision') {
            //this.userdataSocket()
          }
          if (data.event === 'updateSelectedShip') {
            let ship = data['data'];
            if (ship) {
              this.selectedShipElem = ship;
            }
          }
          if (data.event === 'chitViewData') {
            let chitView = data['data'];
            this.chitKey = data['key'];
            console.log("edit chit", chitView);
            if (chitView) {

              this.isReadonly = false;
              this.formView = true;
              this.chitId = chitView._id
              this.editChitIcon = true;
              this.ReadOnlyData = true;
              this.cancelEditChitIcon=false;
              this.getChitActivityDataView();
              this.chitHeaderTitle = false;
              this.chitHeaderEditTitle = false;
              // this.showEmitterEnvelope(chitView);
            } else {
              this.isReadonly = false;
              this.editChitIcon = false;
              this.ReadOnlyData = false;
            }
          }
          if (data.event === 'chitViewForm') {
            let chitView = data['data'];
            this.chitKey = data['key'];
            console.log("edit chit", chitView);
            if (chitView) {
              this.chitViewData = chitView;
              this.isReadonly = false;
              this.formView = true;
              this.chitId = chitView.typeCollectionId;
              this.editChitIcon = true;
              this.cancelEditChitIcon=false;
              this.ReadOnlyData = true;
              this.createDataChitForm = true;
              this.getChitActivityDataView();

              this.chitHeaderTitle = false;
              this.chitHeaderEditTitle = false;
              //this.showEmitterEnvelope(chitView);
            } else {
              this.isReadonly = false;
              this.editChitIcon = false;
              this.ReadOnlyData = false;
            }
          }
          // if (data.event === 'VesselName') {
          //   var VesselName = data['data'];
          //   console.log("123456", VesselName);
          // }
          if (data.event === 'chitDeleteStatus') {
            let status = data['data'];
            this.chitDeleteIcon = status;
          }
          if (data.event === 'shipDetails') {
            let shipDetail = data['data'];
            this.collisionDetection = shipDetail;
          }
          if (data.event === 'dashboardChit') {
            this.chitKey = data['key'];
            var formData = data.value;
            var filterDate = data.filterDate != undefined ? data.filterDate : '';
            if (formData) {
              this.createActivityFormGroup.patchValue({
                OnsiteName: (formData.email ? formData.email : ""),
                Start: (formData.starttime ? formData.starttime : ""),
                End: (formData.endtime ? formData.endtime : ""),
                Date: filterDate != "" ? new Date(this.ctrlServ.formatUTCTZ(filterDate, "DD-MMM-YYYY")) : "",
                // Date: (new Date(formData.date) ? new Date(this.ctrlServ.formatUTCTZ(filterDate, "DD-MMM-YYYY")) : ""),
              });
            }
            if (data.selectedTag) {
              this.chitCreateEvolution = data.selectedTag.layoutName;
              this.selectedLayoutList = data.selectedLayoutList
              this.selectedTagItem = data.selectedTag
              this.createActivityFormGroup.patchValue({
                Date: new Date(this.ctrlServ.formatUTCTZ(filterDate, "DD-MMM-YYYY"))                
              });
              this.chitUserDate = this.ctrlServ.formatUTCTZ(filterDate);
            }
          }
        }

        if (data.src === "main") {
          if (data.event == "show-activities-done") {
            /**
             * store activities show event and notify user when needed
             */
            this.radiateCheckShipReady(data['data']);
            data.event = "";
          }
          if(data.event=="show-activities-done-data"){
             this.selfCollisionData = data['data'][0];
           }
          if (data.event == "on-activities-overlap") {
            this.radiateCheckShipReady(data['data']);
          }
          if (data.event === 'chitNotification') {
            let index = data['data'];
            if (index == 'yes') {
              // this.stepper.selectedIndex = 2;
              this.CreateChitActivity();
            }
          }
          if (data.event === 'chitNotificationYesRequest') {
            let index = data['data'];
            if (index == 'yes') {
              console.log(data.collisionShip)
              this.collisionShip = data.collisionShip
              this.userdataSocket()
              // this.stepper.selectedIndex = 2;
              //this.CreateChitActivity();
            }
          }
          if (data.event === 'chitNotificationNo') {
            let index = data['data'];
            if (index == 'no') {
              // this.ChitFormComplete();
              if (this.chitId) {
                this.chitDeleteActivity();
              } else {
                if (this.ctrlServ.commandingOfficer == 'commanding') {
                  this.broadcastInfo({ src: 'chit', event: 'commandingOfficer', data: 'commanding', key: this.createActivityFormGroup.get('Date').value });
                }
                this.chitWindowState = false;
                this.broadcastInfo({ src: 'sidepanel', event: 'stepper', data: this.chitWindowState, key: "" });
              }
              // this.chitWindow();
            }
          }
          if (data.event === 'collisionNotificationYes') {
            let index = data['data'];
            if (index == 'yes') {
              // this.CreateChit();
              this.CreateChitActivity();
            }
          }
          if (data.event === 'collisionNotificationNo') {
            let index = data['data'];
            if (index == 'no') {
              // this.ChitFormComplete();
              // this.chitWindow();
              if (this.ctrlServ.commandingOfficer == 'commanding') {
                this.broadcastInfo({ src: 'chit', event: 'commandingOfficer', data: 'commanding', key: this.createActivityFormGroup.get('Date').value });
              }
              this.submitted = false
              //this.chitWindowState = false;
              this.broadcastInfo({ src: 'sidepanel', event: 'stepper', data: this.chitWindowState, key: "" });
            }

          }
          if (data.event === 'shipDataList') {
            var shipList = data['data'];
            this.shipList = shipList.shipData
            // console.log("ship List", this.shipList);
            // console.log("ship List");
          }
        }
        if (data.src === "navyApp") {
          if (data.event === 'createCollectionChit') {
            this.selectedLayoutList = data.selectedLayoutList;
            this.selectedTagItem = data.saveNotesData;
            if (this.selectedLayoutList && this.selectedLayoutList.Notes && this.selectedLayoutList.Notes.length > 0) {
              var idx = this.selectedLayoutList.Notes.findIndex(obj => obj.tagShapeId == this.selectedTagItem.tagShapeId);
              this.selectedTagItem = this.selectedLayoutList.Notes[idx]
              console.log(this.selectedTagItem, this.selectedLayoutList)

            }
            this.radiateCheckShipDetails()
          }
          if (data.event == 'collisionDetection') {
            if (data.data != undefined) {
              this.onCollisionDetection(data.data);
            } else {
              this.collision = false;
              this.userAdded();
            }
          }
        }
        if (data.src === "slider") {
          if (data.event === 'updateShipDetails') {
            let ship = data['data'];
            if (ship == 'updateShip') {
              this.radiateCheckShipDetails();
            }
          }
        }

        if (data.src === "slms-slider") {
          if (data.event === 'filterSlms') {
            this.chitUserDate = this.datePipe.transform(data.sliderDate, 'yyy-MM-dd');
            this.createActivityFormGroup.patchValue({
              Date: this.chitUserDate != "" ? new Date(this.ctrlServ.formatUTCTZ(this.chitUserDate, "DD-MMM-YYYY")) : "",
            });
          } else if (data.event === 'filterScrubber') {
            console.log('event', data);
            this.chitUserDate = this.datePipe.transform(data.endDate, 'yyy-MM-dd');
            this.createActivityFormGroup.patchValue({
              Date: this.chitUserDate != "" ? new Date(this.ctrlServ.formatUTCTZ(this.chitUserDate, "DD-MMM-YYYY")) : "",
            });
          }
        }
      })


    // var shipLocationMatch = this.supervisorListData.filter(t => t.vessel == this.createActivityFormGroup.get('Vessel').value);
    // this.onSiteContact = shipLocationMatch.onSiteContact;
    // this.chitSupervisor= shipLocationMatch.supervisor_name;

    this.filteredOptions = this.azureID.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );


    // this.createFormGroup = this._formBuilder.group({
    //   firstCtrl: ['', Validators.required]
    // });
    // this.evolutionFormGroup.patchValue( this.chitEvolution);
    // console.log("form test", this.evolutionFormGroup.patchValue( this.chitEvolution));
    // console.log("this.chitEvolution", this.chitEvolution)


    // "SHIP STAFF SUPERVISOR": this._formBuilder.array([{

    // }])

    this.createFormGroup = this._formBuilder.group({

      // azureIdCtrl: [this.profileInfo.userId],
      azureIdCtrl: [''],
      // azureIdCtrl: new FormControl({ value: '', disabled: this.disabled }),
      Serail_no: [''],
      // Date: ['', Validators.required, Validators.pattern(`this.errorDetails = !this.errorDetails`)],
      Date: ['', Validators.required],
      OOD_name: ['', Validators.required],
      OOD_Rank: ['', Validators.required],
      OOD_Telephone: ['', Validators.required],
      Vessel: ['', Validators.required],
      SUP_name: ['', Validators.required],
      SUP_Rank: ['', Validators.required],
      SUP_Telephone: ['', Validators.required],
      ONSITE_name: ['', Validators.required],
      Unit: ['', Validators.required],
      Telephone: ['', Validators.required],
      Department_no: ['', Validators.required],
      Start: ['', Validators.required],
      End: ['', Validators.required],
    });
    var data = "60cb5f73ab98e62cb075b9a3"
    this.evolutionFormGroup = this._formBuilder.group({
      azureIdCtrl: [this.profileInfo.userId],
      EVOLUTION: ['', Validators.required],
      Location: ['', Validators.required],
      Purpose: ['', Validators.required],
      // mySingleCheckbox: ['', Validators.required],
      // mySingleCheckbox: [false, Validators.requiredTrue]
      // mySingleCheckbox: new FormControl(false, [Validators.requiredTrue]),
    });
    this.locationFormGroup = this._formBuilder.group({
      azureIdCtrl: [this.profileInfo.userId],
      Location: ['', Validators.required],
      Purpose: ['', Validators.required],
    });
    this.safeFormGroup = this._formBuilder.group({
      azureIdCtrl: [this.profileInfo.userId],
      'alongside': ['true'],
      'atsea': [''],
      'Duty_tech_names': [''],
      'generators': '',
      'WK_name': [''],
      'onsite_contact': ''
      // safeNameCtrl: ['', Validators.required],
      // safeSnrEngNameCtrl: ['', Validators.required],
    });
    this.vesselsFormGroup = this._formBuilder.group({
      azureIdCtrl: [this.profileInfo.userId],
      OOD: ['', Validators.required],
      Vessels: ['', Validators.required],
      'permissions': ''
    });
    this.informFormGroup = this._formBuilder.group({
      azureIdCtrl: [this.profileInfo.userId],
      // informCtrl: ['', Validators.required]
      'ATSEA': '',
      'alongside': '',
      'passed_to_qhm': '',
      'no_qhm_contacted': '',
    });
    this.flagFormGroup = this._formBuilder.group({
      azureIdCtrl: [this.profileInfo.userId],
      OOD: ['', Validators.required],
      'Kilo_hoisted': [false, Validators.required],
      'Keys_secured': [false, Validators.required],
      'Keyboard_tagged_out': [false, Validators.required],
      'ships_profile_board': [false, Validators.required],
    });
    this.signFormGroup = this._formBuilder.group({
      azureIdCtrl: [this.profileInfo.userId],
      OOD: ['', Validators.required],
      ON_SITE_CONTACT: ['', Validators.required],
    });
    this.proximityFormGroup = this._formBuilder.group({
      azureIdCtrl: [this.profileInfo.userId],
      OOD: ['', Validators.required],
      Vessel: ['', Validators.required],
      URFSO_Name: ['', Validators.required],
      Special_instructions: ['', Validators.required],
    });
    this.completeFormGroup = this._formBuilder.group({
      azureIdCtrl: [this.profileInfo.userId],
      Date: ['', Validators.required],
      Time: ['', Validators.required],
      'removed_padlocks': '',
      'removed_Key_Ta': '',
    });
    this.createActivityFormGroup = this._formBuilder.group({
      UserID: [this.profileInfo.userId],
      // ShipName: [this.chitCreateVesselName],
      activity: ['', Validators.required],
      Location: ['',],
      emitterLocation: [' '],
      manAloftLocation: [' '],
      Vessel: [''],
      OnsiteName: [''],
      // SupName: ['', Validators.required],
      Start: ['', Validators.required],
      End: ['', Validators.required],
      Date: [new Date(), Validators.required],
      notification: [''],
      purpose: ['', Validators.required],
      contractor: [''],
      assignContact: [''],
      visitorIds:['']
    });
    this.createActivityCompleteFormGroup = this._formBuilder.group({
      azureIdCtrl: [this.profileInfo.userId],
      'alongside': [' ']
    });
    // this.createActivityFormGroup.controls['Vessel'].disable();
    // this.createActivityFormGroup.controls['Date'].disable();
    // this.createActivityFormGroup.controls['Start'].disable();
    // this.createActivityFormGroup.controls['End'].disable();
    // this.getChitData();

    // this.createFormGroup.get('azureIdCtrl').disable();
    // this.createFormGroup.get('Serail_no').disable();
    // this.evolutionFormGroup.get('azureIdCtrl').disable();
    // this.locationFormGroup.get('azureIdCtrl').disable();
    // this.safeFormGroup.get('azureIdCtrl').disable();
    // this.vesselsFormGroup.get('azureIdCtrl').disable();
    // this.informFormGroup.get('azureIdCtrl').disable();
    // this.flagFormGroup.get('azureIdCtrl').disable();
    // this.signFormGroup.get('azureIdCtrl').disable();
    // this.proximityFormGroup.get('azureIdCtrl').disable();
    // this.completeFormGroup.get('azureIdCtrl').disable();
    // this.createFormGroup.get('Date').disable();

    // this.getChitDataList();
    // this.safeFormGroup.value.alongside == 'true'



  }
  broadcastIframeSubs: any;
  broadcastIframeInfo(data: any) {
    this.communicationServ.createIframeInstance(data);
  }
  // get f() { return this.evolutionFormGroup.controls; }

  // ngAfterViewInit(): void {
  //   this.broadcastIframeSubs = this.communicationServ.getIframeInstance()
  //     .subscribe((data: any) => {
  //       if (data.iframeConnection === "navyApp") {
  //         if(data.event == 'collisionDetection'){
  //           if(data.data != undefined){
  //             this.vesselRequestApproval = true;
  //             this.collisionShipInfo = data.data
  //             console.log(this.collisionShipInfo)
  //             this.userAdded()
  //             this.createDataChitForm = false
  //             this.createDataChitFormStepp1 = false
  //           }
  //         }
  //         if (data.event === 'chitNotification') {
  //           let index = data['data'];
  //           if (index == 'yes') {
  //             this.CreateChitActivity();
  //           }
  //         }
  //       }

  //     })
  // }


  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }
  onCollisionDetection(data) {
    this.radiateShipSelected
    this.vesselRequestApproval = true;
    this.vesselSelfColliedRequestApproval = false;
    this.selfCollied = false;
    this.collisionShipInfo = data
    console.log(this.collisionShipInfo)
    this.userAdded()
    this.createDataChitForm = false
    this.createDataChitFormStepp1 = false
  }
  clearBeforeCollisionDetection(clearSel = true) {
    if (clearSel) {
      this.clearLastShownEmittersMA("ehfx");/**TODO make shipId dynamic and better clear logic*/
      this.radhazVisServ.rerenderTempActivities();
    }

    // this.broadcastInfo({src:"chitStepper", event:"show-current-activities",data:this.createActivityFormGroup.value,key:false})
  }
  chitWindow() {
    this.chitWindowState = !this.chitWindowState;
    if (this.collisionMode) {
      this.radhazVisServ.clearOutlines('ehfx');
      this.clearBeforeCollisionDetection()
      this.collisionMode = false;
    }
    if (this.selectedShipElem && this.radhazVisServ.selectedActivity) {
      let emitter = this.radhazVisServ.isRHEmitter(this.radhazVisServ.selectedActivity.toLowerCase());
      if (this.selectedShipElem['model'] === "Halifax" && (emitter || this.radhazVisServ.selectedActivity.toLowerCase() === 'man-aloft')) {
        this.radhazVisServ.resetCollisions(this.collisionMode);
      }
    }
    this.broadcastInfo({ src: 'sidepanel', event: 'stepper', data: this.chitWindowState, key: "" });
    this.broadcastInfo({ src: 'chitStepper', event: 'stepperActive', data: 'null', key: "" });
    /* if (this.createActivityFormGroup.get('emitterLocation').value) {
      this.radhazVisServ.showActivityInView(this.createActivityFormGroup.value,false);
    }
    if (this.createActivityFormGroup.get('manAloftLocation').value) {
      this.radhazVisServ.showActivityInView(this.createActivityFormGroup.value,false);
    } */
    this.createActivityFormGroup.reset();
    this.formView = false;
  }
  done() {
    this.completed = true;
    this.state = 'done';
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }
  horizontalStepperShow() {
    this.verticalStepper = false;
    this.horizontalStepper = true;
    this.horizontalButton = false;
    this.verticalButton = true;
  }
  verticalStepperShow() {
    this.horizontalStepper = false;
    this.verticalStepper = true;
    this.horizontalButton = true;
    this.verticalButton = false;
  }
  safeAlongSide() {
    this.safeAlongSideSection = true;
    this.safeAtSeaSection = false;
    this.safeFormGroup.get('WK_name').clearValidators();
    this.safeFormGroup.get('WK_name').updateValueAndValidity();
  }
  safeAtSea() {
    this.safeAlongSideSection = false;
    this.safeAtSeaSection = true;
    this.safeFormGroup.get('Duty_tech_names').clearValidators();
    this.safeFormGroup.get('Duty_tech_names').updateValueAndValidity();
    this.safeFormGroup.get('generators').clearValidators();
    this.safeFormGroup.get('generators').updateValueAndValidity();
  }
  levelForm(e) {
    // console.log(this.createFormGroup);
  }

  getChitData() {
    this.chitService.getChitData()
      .subscribe(
        (response) => {
          // console.log('response received')
          var repos = response;
          // console.log("datat2222 : ", repos);
        },
        error => {
          console.error('Request failed with error')
          // console.log(error)
        })
  }
  getChitDataList() {
    // this.chitId = '60ed81d8b3ebe8003559a4ce'
    // this.chitId = '60ec127ab3ebe8003559a4b5'
    this.chitService.getChitDataList(this.chitId)
      .subscribe(
        (response) => {
          var repos = response;
          console.log("chit data1 : ", repos[0]);

          if (repos.length !== 0) {
            this.chitHeader = !this.chitHeader;
            this.chitDataList = repos[0];
            // console.log("chitDataList", this.chitDataList);
            // console.log("chit date", this.chitDataList.Date);
            // console.log("chit id", this.chitDataList._id);
            // console.log("chit azureIdCtrl", this.chitDataList.azureIdCtrl);
            this.shipName = this.chitDataList.Vessel;
            this.chitStaffSup = this.chitDataList.SHIP_STAFF_SUPERVISOR;
            this.chitTimePeriod = this.chitDataList.TIME_PERIOD;
            this.chitOnsiteContent = this.chitDataList.ONSITE_CONTACT;
            this.chitOod = this.chitDataList.OOD;
            this.chitEvolution = this.chitDataList.Evolution;
            this.chitLocation = this.chitDataList.LOCATION;
            this.chitSafeExhaust = this.chitDataList.Safe_Exhaust;
            this.chitSafeExhaustAlongside = (this.chitSafeExhaust ? this.chitSafeExhaust.ALONGSIDE : "");
            this.chitSafeExhaustAtsea = (this.chitSafeExhaust ? this.chitSafeExhaust.ATSEA : "");
            this.chitVesselProximity = this.chitDataList.Vessel_Proximity;
            this.chitQHM = this.chitDataList.QHM;
            this.chitAlongside = (this.chitQHM ? this.chitQHM.ALONGSIDE : "");
            this.chitFlags = this.chitDataList.Flags;
            this.chitSign = this.chitDataList.Sign;
            this.chitSpecialCircumstances = this.chitDataList.Special_Circumstances
            this.chitCompleted = this.chitDataList.Completed
            // console.log("chit data1", this.chitDataList.Date);
            // console.log("chit data2", this.chitOod);
            // console.log("chit data3", this.chitOod.OOD_Telephone);

            this.chitVeriVessel = this.chitDataList.Vessel;
            this.chitVeriOOD = this.chitOod.OOD_name;
            this.chitVeriTelephone = this.chitOod.OOD_Telephone;
            this.chitVeriDate = this.chitDataList.Date;

            // const chitNotification = { Vessel: this.chitVeriVessel, OOD: this.chitVeriOOD, Telephone: this.chitVeriTelephone , Date:this.chitVeriDate};
            // this.broadcastInfo({ src: 'chitform', event: 'chitNotification', data: chitNotification, key: "" });
            // var startDate = this.datePipe.transform(this.chitDataList.Date, 'yyyy-MM-dd');
            let date = this.chitDataList.Date;
            let startDate = moment(date);
            // console.log("Start Date", startDate)
            this.createFormGroup.setValue({
              azureIdCtrl: (this.chitDataList.azureIdCtrl ? this.chitDataList.azureIdCtrl : ""),
              Serail_no: (this.chitDataList._id ? this.chitDataList._id : ""),
              Date: this.chitDataList.Date,
              OOD_Rank: (this.chitOod ? this.chitOod.OOD_Rank : ""),
              OOD_Telephone: (this.chitOod ? this.chitOod.OOD_Telephone : ""),
              OOD_name: (this.chitOod ? this.chitOod.OOD_name : ""),
              Vessel: this.chitDataList.Vessel,
              SUP_name: (this.chitStaffSup ? this.chitStaffSup.SUP_name : ""),
              SUP_Rank: (this.chitStaffSup ? this.chitStaffSup.SUP_Rank : ""),
              SUP_Telephone: (this.chitStaffSup ? this.chitStaffSup.SUP_Telephone : ""),
              ONSITE_name: (this.chitOnsiteContent ? this.chitOnsiteContent.ONSITE_name : ""),
              Unit: (this.chitOnsiteContent ? this.chitOnsiteContent.Unit : ""),
              Telephone: (this.chitOnsiteContent ? this.chitOnsiteContent.Telephone : ""),
              Department_no: (this.chitOnsiteContent ? this.chitOnsiteContent.Department_no : ""),
              Start: (this.chitTimePeriod ? this.chitTimePeriod.Start : ""),
              End: (this.chitTimePeriod ? this.chitTimePeriod.End : ""),
            });
            this.createActivityFormGroup.setValue({
              azureIdCtrl: (this.chitDataList.azureIdCtrl ? this.chitDataList.azureIdCtrl : ""),
              Date: this.chitDataList.Date,
              Vessel: this.chitDataList.Vessel,
              SUP_name: (this.chitStaffSup ? this.chitStaffSup.SUP_name : ""),
              ONSITE_name: (this.chitOnsiteContent ? this.chitOnsiteContent.ONSITE_name : ""),
              Start: (this.chitTimePeriod ? this.chitTimePeriod.Start : ""),
              End: (this.chitTimePeriod ? this.chitTimePeriod.End : ""),
              EVOLUTION: (this.chitEvolution ? this.chitEvolution.EVOLUTION : ""),
              Location: (this.chitEvolution ? this.chitEvolution.Location : ""),
              assignContact: (this.chitEvolution ? this.chitEvolution.assignContact : ""),
              contractor: (this.chitEvolution ? this.chitEvolution.contractor : ""),
            });
            this.createActivityCompleteFormGroup.setValue({
              azureIdCtrl: (this.chitDataList.azureIdCtrl ? this.chitDataList.azureIdCtrl : ""),
              alongside: (this.chitSafeExhaustAlongside ? this.chitSafeExhaustAlongside.alongside : ""),
              Duty_tech_names: (this.chitSafeExhaustAlongside ? this.chitSafeExhaustAlongside.Duty_tech_names : ""),
              generators: (this.chitSafeExhaustAlongside ? this.chitSafeExhaustAlongside.generators : ""),
              atsea: (this.chitSafeExhaustAtsea ? this.chitSafeExhaustAtsea.atsea : ""),
              WK_name: (this.chitSafeExhaustAtsea ? this.chitSafeExhaustAtsea.WK_name : ""),
              onsite_contact: (this.chitSafeExhaustAtsea ? this.chitSafeExhaustAtsea.onsite_contact : "")
            });
            // this.createActivityFormGroup.patchValue(this.chitEvolution);
            this.evolutionFormGroup.patchValue(this.chitEvolution);
            this.locationFormGroup.patchValue(this.chitLocation);

            this.safeFormGroup.setValue({
              azureIdCtrl: (this.chitDataList.azureIdCtrl ? this.chitDataList.azureIdCtrl : ""),
              alongside: (this.chitSafeExhaustAlongside ? this.chitSafeExhaustAlongside.alongside : ""),
              Duty_tech_names: (this.chitSafeExhaustAlongside ? this.chitSafeExhaustAlongside.Duty_tech_names : ""),
              generators: (this.chitSafeExhaustAlongside ? this.chitSafeExhaustAlongside.generators : ""),
              atsea: (this.chitSafeExhaustAtsea ? this.chitSafeExhaustAtsea.atsea : ""),
              WK_name: (this.chitSafeExhaustAtsea ? this.chitSafeExhaustAtsea.WK_name : ""),
              onsite_contact: (this.chitSafeExhaustAtsea ? this.chitSafeExhaustAtsea.onsite_contact : "")
            });
            this.vesselsFormGroup.patchValue(this.chitVesselProximity);
            this.informFormGroup.setValue({
              azureIdCtrl: (this.chitDataList.azureIdCtrl ? this.chitDataList.azureIdCtrl : ""),
              ATSEA: (this.chitQHM ? this.chitQHM.ATSEA : ""),
              alongside: (this.chitAlongside ? this.chitAlongside.alongside : ""),
              passed_to_qhm: (this.chitAlongside ? this.chitAlongside.passed_to_qhm : ""),
              no_qhm_contacted: (this.chitAlongside ? this.chitAlongside.no_qhm_contacted : ""),
            });
            this.flagFormGroup.patchValue(this.chitFlags);
            this.signFormGroup.patchValue(this.chitSign);
            this.proximityFormGroup.patchValue(this.chitSpecialCircumstances);
            this.completeFormGroup.patchValue(this.chitCompleted);
            // console.log("this.chitEvolution", this.chitEvolution)
            // this.chitCloseForm();
            // this.createFormGroup.disable();
            this.evolutionFormGroup.disable();
            this.locationFormGroup.disable();
            this.safeFormGroup.disable();
            this.vesselsFormGroup.disable();
            this.informFormGroup.disable();
            this.flagFormGroup.disable();
            this.signFormGroup.disable();
            this.proximityFormGroup.disable();
            this.completeFormGroup.disable();
          }
          else {
            // console.log("chit data", this.chitDataList);
          }
        },
        error => {
          console.error('Request failed with error')
          // console.log(error)
        })
  }

  getChitDataListVerfy() {
    // this.chitId = '60d27dfc24d303477c62f7e1'
    // this.chitId = '60ec127ab3ebe8003559a4b5'
    this.chitService.getChitDataList(this.chitId)
      .subscribe(
        (response) => {
          var repos = response;
          // console.log("chit data : ", repos[0]);
          if (repos.length !== 0) {
            var data1 = repos[0];
            var data2 = data1.OOD;
            this.chitVeriVessel = data1.Vessel;
            this.chitVeriOOD = data2.OOD_name;
            this.chitVeriTelephone = data2.OOD_Telephone;
            this.chitVeriDate = data1.Date;
            // const chitNotification = { Vessel: this.chitVeriVessel, OOD: this.chitVeriOOD, Telephone: this.chitVeriTelephone , Date:this.chitVeriDate};
            // this.broadcastInfo({ src: 'chitform', event: 'chitNotification', data: chitNotification, key: "" });
          }
          else {
            // console.log("chit data", this.chitDataList);
          }
        },
        error => {
          console.error('Request failed with error')
          // console.log(error)
        })
  }

  createActivity() {
    let obj =
    {
      "ship": this.createActivityFormGroup.value.Vessel || '',
      "workperiodtype": this.safeFormGroup.value.WK_name || '',
      "startdate": (this.createActivityFormGroup.value.Date ? this.datePipe.transform(this.createActivityFormGroup.value.Date, 'dd-MMM-yy') : this.datePipe.transform(new Date(), 'dd-MMM-yy')),
      "enddate": (this.completeFormGroup.value.Date ? this.datePipe.transform(this.completeFormGroup.value.Date, 'dd-MMM-yy') : this.datePipe.transform(this.createActivityFormGroup.value.Date, 'dd-MMM-yy')),
      "activity": this.createActivityFormGroup.value.EVOLUTION || '',
      "starttime": this.createActivityFormGroup.value.Start || '',
      "endtime": this.createActivityFormGroup.value.End || '',
      "notes": this.createActivityFormGroup.value.Purpose || '',
      "contactname": this.createActivityFormGroup.value.ONSITE_name || '',
      "location": this.createActivityFormGroup.value.Location || '',
      "assignContact": this.createActivityFormGroup.value.assignContact || '',
      "contractor": this.createActivityFormGroup.value.contractor || '',
      "chit_id": this.chitId
    }
    this.chitService.createActivity({ data: obj, port: this.loadedPort }).subscribe(response => {
      if (response['result'] === 'success') {
        // this.CreateChitEvolution();
        let shipData = response['shipData'];
        if (shipData['location']) {
          let activity = this.createActivityFormGroup.value ? this.createActivityFormGroup.value.EVOLUTION.toLowerCase() : '';
          let chitNotification = { 'Vessel': this.createActivityFormGroup.value.Vessel, Activity: activity, 'OOD': this.createActivityFormGroup.value.OOD_name, 'Telephone': this.createActivityFormGroup.value.OOD_Telephone, "Date": this.createActivityFormGroup.value.Date, "editStatus": this.chitOnEditState }
          // console.log(" Activity Created ", shipData);
          this.broadcastInfo({ src: 'chit', event: 'reloadshipdata', data: shipData, notify: chitNotification });
        }
      }
    })
  }

  CreateChit() {
    if (this.isReadonly == true) {
      // console.log("view data");
      this.stepper.selectedIndex = 2;

    } else {
      if (this.chitId) {
        // console.log("Update Form Data", this.createActivityFormGroup.value);
        this.chitService.updateChit(this.chitId, this.createActivityFormGroup.value)
          .subscribe((res) => {
            // console.log("updateChit ", res);
            this.createActivity();
            this.CreateChitEvolution();
            // this.CreateChitCompleteDate();
            this.stepper.selectedIndex = 2;
            if (res['status'] === '200') {
              // console.log('Chit Updated')
              this.CreateChitEvolution();
            }
          }, error => {
            console.error('There was an error!');
          })
      } else {
        // console.log("Current Form Data 1", this.createActivityFormGroup.value);
        // console.log(this.createFormGroup.value.azureIdCtrl)
        // console.log(this.createFormGroup.value.Serail_no)
        // console.log(this.createFormGroup.value.Date)
        // console.log(this.createFormGroup.value.Vessel)
        this.chitService.addChit(this.createActivityFormGroup.value)
          .subscribe((res) => {
            this.chitId = res.insertedId;
            console.log("chitId ", this.chitId);
            this.CreateChitEvolution();
            this.createActivity();

            // this.CreateChitCompleteDate();
            if (res['status'] === '200') {
              // console.log('Chit Created ')
            }
          }, error => {
            console.error('There was an error!');
          })
      }
    }

  }

  // CreateChitCompleteDate() {
  //   if (this.isReadonly == false) {
  //     console.log("Current Form Data2", this.completeFormGroup.value);
  //     var date = { "Date": this.createFormGroup.get('Date').value, "Time": "", "removed_padlocks": '', "removed_Key_Ta": '' };
  //     this.chitService.addChitCompleted(this.chitId, date)
  //       .subscribe((res) => {
  //         console.log("zxcv", res)
  //         var status = res.status
  //         if (status === '200') {
  //           console.log('test121312')
  //         }
  //       }, error => {
  //         console.error('There was an error!');
  //       })
  //   } else {
  //     console.log('view data');
  //   }

  // }

  updateChitData() {

    this.chitService.updateChit(this.chitId, this.createFormGroup.value)
      .subscribe((res) => {
        // console.log("zxcv", res)
        var status = res.status;
        if (status === '200') {
          // console.log('test121312')
        }
      }, error => {
        console.error('There was an error!');
      })
  }

  CreateChitEvolution() {
    if (this.isReadonly == false) {
      if (this.createActivityFormGroup.invalid) {
        return;
      }
      // console.log("Current Form Data2", this.createActivityFormGroup.value);
      this.getChitDataListVerfy()
      this.chitService.addChitEvolution(this.chitId, this.createActivityFormGroup.value)
        .subscribe((res) => {
          // console.log("zxcv", res)
          var status = res.status
          if (status === '200') {
            // console.log('test121312')
          }
        }, error => {
          console.error('There was an error!');
        })
    } else {
      // console.log('view data');
      this.evolutionFormGroup.disable();
    }

  }
  CreateChitLocation() {

    if (this.isReadonly == false) {
      // console.log("Current Form Data2", this.locationFormGroup.value);
      this.chitService.addChitLocation(this.chitId, this.locationFormGroup.value)
        .subscribe((res) => {
          // console.log("zxcv", res)
          var status = res.status
          if (status === '200') {
            // console.log('test121312'
          }
        }, error => {
          console.error('There was an error!');
        })
    } else {
      // console.log('view data');
      this.locationFormGroup.disable();
    }


  }
  CreateChitSafeExhaust() {

    if (this.isReadonly == false) {
      // console.log("Current Form Data2", this.safeFormGroup.value);
      this.chitService.addChitSafeExhaust(this.chitId, this.safeFormGroup.value)
        .subscribe((res) => {
          // console.log("zxcv", res)
          var status = res.status
          if (status === '200') {
            // console.log('test121312')
          }
        }, error => {
          console.error('There was an error!');
        })
    } else {
      // console.log('view data');
      this.safeFormGroup.disable();
    }

  }
  CreateChitvessels() {
    if (this.isReadonly == false) {
      // console.log("Current Form Data2", this.vesselsFormGroup.value);
      this.chitService.addChitProximity(this.chitId, this.vesselsFormGroup.value)
        .subscribe((res) => {
          // console.log("zxcv", res)
          var status = res.status
          if (status === '200') {
            // console.log('test121312')
          }
        }, error => {
          console.error('There was an error!');
        })
    } else {
      // console.log('view data');
      this.vesselsFormGroup.disable();

    }

  }
  CreateChitinform() {
    if (this.isReadonly == false) {
      // console.log("Current Form Data2", this.informFormGroup.value);
      this.chitService.addChitQhm(this.chitId, this.informFormGroup.value)
        .subscribe((res) => {
          // console.log("zxcv", res)
          var status = res.status
          if (status === '200') {
            // console.log('test121312')
          }
        }, error => {
          console.error('There was an error!');
        })
    } else {
      // console.log('view data');
      this.informFormGroup.disable();

    }

  }
  CreateChitflag() {
    if (this.isReadonly == false) {
      // if (!this.flagFormGroup.value.Kilo_hoisted || !this.flagFormGroup.value.Keys_secured || !this.flagFormGroup.value.Keyboard_tagged_out || !this.flagFormGroup.value.ships_profile_board) {
      //   this.kiloHoisted = true;
      //   this.keysSecured = true;
      //   this.keyboardTaggedOut = true;
      //   this.shipsProfileBoard = true;
      //   return;
      // }
      if (!this.flagFormGroup.value.Kilo_hoisted) {
        this.kiloHoisted = true;
        return;
      }
      if (!this.flagFormGroup.value.Keys_secured) {
        this.keysSecured = true;
        return;
      }
      if (!this.flagFormGroup.value.Keyboard_tagged_out) {
        this.keyboardTaggedOut = true;
        return;
      }
      if (!this.flagFormGroup.value.ships_profile_board) {
        this.shipsProfileBoard = true;
        return;
      }
      // console.log("Current Form Data2", this.flagFormGroup.value);
      this.chitService.addChitFlags(this.chitId, this.flagFormGroup.value)
        .subscribe((res) => {
          // console.log("zxcv", res)
          var status = res.status
          if (status === '200') {
            // console.log('test121312')
          }
        }, error => {
          console.error('There was an error!');
        })
    } else {
      // console.log('view data');
      this.flagFormGroup.disable();
      // this.flagFormGroup.get('Kilo_hoisted').clearValidators();
      // this.flagFormGroup.get('Kilo_hoisted').updateValueAndValidity();
      // this.flagFormGroup.get('Keys_secured').clearValidators();
      // this.flagFormGroup.get('Keys_secured').updateValueAndValidity();
      // this.kiloHoisted = false;
      // this.keysSecured = false;
      // this.keyboardTaggedOut = false;
      // this.shipsProfileBoard = false;

    }

  }
  CreateChitSign() {
    if (this.isReadonly == false) {
      // console.log("Current Form Data2", this.signFormGroup.value);
      this.chitService.addChitSign(this.chitId, this.signFormGroup.value)
        .subscribe((res) => {
          // console.log("zxcv", res)
          var status = res.status
          if (status === '200') {
            // console.log('test121312')
          }
        }, error => {
          console.error('There was an error!');
        })
    } else {
      // console.log('view data');
      this.signFormGroup.disable();

    }

  }
  CreateChitProximity() {
    if (this.isReadonly == false) {
      // console.log("Current Form Data2", this.proximityFormGroup.value);
      this.chitService.addChitSpecialCircum(this.chitId, this.proximityFormGroup.value)
        .subscribe((res) => {
          // console.log("zxcv", res)
          var status = res.status
          if (status === '200') {
            // console.log('test121312')
          }
        }, error => {
          console.error('There was an error!');
        })
    } else {
      // console.log('view data');
      this.proximityFormGroup.disable();

    }

  }
  CreateChitComplete() {
    if (this.isReadonly == false) {
      // console.log("Current Form Data2", this.completeFormGroup.value);
      this.createActivity();
      this.chitService.addChitCompleted(this.chitId, this.completeFormGroup.value)
        .subscribe((res) => {
          // console.log("zxcv", res)
          this.broadcastInfo({ src: 'chit', event: 'reloadshipList', data: "reload" });
          var status = res.status
          if (status === '200') {
            // console.log('test121312')
          }
        }, error => {
          console.error('There was an error!');
        })


    } else {
      // console.log('view data');
      this.completeFormGroup.disable();
      // this.completeFormGroup.get('Date').clearValidators();
      // this.completeFormGroup.get('Date').updateValueAndValidity();
      // this.completeFormGroup.get('Time').clearValidators();
      // this.completeFormGroup.get('Time').updateValueAndValidity();
    }

  }
  ChitFormComplete() {
    this.chitWindowState = !this.chitWindowState;
    if (!this.chitWindowState) {
      if (this.collisionMode) {
        this.radhazVisServ.clearOutlines('ehfx');
        this.clearLastShownEmittersMA("ehfx");/**TODO make shipId dynamic and better clear logic*/
        this.radhazVisServ.rerenderTempActivities();
        //this.broadcastInfo({src:"chitStepper", event:"show-current-activities",data:this.createActivityFormGroup.value,key:false})
      }
    }
    this.broadcastInfo({ src: 'sidepanel', event: 'stepper', data: this.chitWindowState, key: "" });
    this.formView = false;
  }
  chitEditForm() {
    this.isReadonly = false;
    this.ReadOnlyData = false;
    this.isAddUser = true;
    this.chitHeaderEditTitle = true;
    this.editChitIcon = !this.editChitIcon;
    this.cancelEditChitIcon = true;
    this.createFormGroup.enable();
    this.evolutionFormGroup.enable();
    this.locationFormGroup.enable();
    this.safeFormGroup.enable();
    this.vesselsFormGroup.enable();
    this.informFormGroup.enable();
    this.flagFormGroup.enable();
    this.signFormGroup.enable();
    this.proximityFormGroup.enable();
    this.completeFormGroup.enable();
    this.createActivityFormGroup.enable();
    this.createActivityCompleteFormGroup.enable();
    // setTimeout(() => {
    //   this.createActivityCompleteFormGroup.controls['Vessel'].disable();
    // }, 1000);
    this.ReadOnlyInput = true;
    this.chitService.typeCollectionForm={name:'createCollectionFormActive',type:this.chitViewData['typeCollection']}
  }
  chitCloseForm() {
    this.isReadonly = true;
    this.ReadOnlyData = true;
    this.isAddUser = false;
    this.editChitIcon = !this.editChitIcon;
    this.cancelEditChitIcon = false;
    this.chitHeaderEditTitle = false;
    this.flagFormGroup.get('Kilo_hoisted').clearValidators();
    this.flagFormGroup.get('Kilo_hoisted').updateValueAndValidity();
    this.flagFormGroup.get('Keys_secured').clearValidators();
    this.flagFormGroup.get('Keys_secured').updateValueAndValidity();
    this.kiloHoisted = false;
    this.keysSecured = false;
    this.keyboardTaggedOut = false;
    this.shipsProfileBoard = false;
    this.createFormGroup.disable();
    this.evolutionFormGroup.disable();
    this.locationFormGroup.disable();
    this.safeFormGroup.disable();
    this.vesselsFormGroup.disable();
    this.informFormGroup.disable();
    this.flagFormGroup.disable();
    this.signFormGroup.disable();
    this.proximityFormGroup.disable();
    this.completeFormGroup.disable();
    this.createActivityFormGroup.disable();
    this.createActivityCompleteFormGroup.disable();
    delete this.chitService.typeCollectionForm;
  }
  shipStaffDetails(value) {
    // console.log("shipStaffDetails ID", value);
    // let shipStaffId: any = ''
    let shipData = this.shipStaff.find(el => el.id === value);
    if (shipData) {
      // shipStaffId = shipData;
      this.shipStaffInfo = shipData;
      // console.log("sndkajsd", this.shipStaffInfo)
    }
  }
  OnsiteInfoDetails(value) {
    // console.log("OnsiteInfoDetails ID", value);
    // let shipStaffId: any = ''
    let OnsiteInfo = this.OnsiteInfo.find(el => el.id === value);
    if (OnsiteInfo) {
      // shipStaffId =  OnsiteInfo;
      this.OnsiteInfoData = OnsiteInfo;
      // console.log("sndkajsd", this.shipStaffInfo)
    }
  }
  shipNameDetails(value) {
    console.log("shipNameDetails ID", value);
    this.shipName = value;
  }
  shipEvolutionDetails(value) {
    // console.log("shipEvolutionDetails ID", value);
    if (value == 'Man-Aloft') {
      this.evolutionFormGroup.get('Location').clearValidators();
      this.evolutionFormGroup.get('Location').updateValueAndValidity();
    }
    else if (value == 'Man Aloft/Radiate') {
      this.evolutionFormGroup.get('Location').clearValidators();
      this.evolutionFormGroup.get('Location').updateValueAndValidity();
    }
    else if (value == 'Diving Operations') {
      this.evolutionFormGroup.get('Location').clearValidators();
      this.evolutionFormGroup.get('Location').updateValueAndValidity();
    }
    if (value == 'antenna rotating') {
      this.evolutionFormGroup.get('Location').clearValidators();
      this.evolutionFormGroup.get('Location').updateValueAndValidity();
    }
    else {
      this.evolutionFormGroup.get('Location').updateValueAndValidity();
    }

  }

  getUserData() {
    this.chitService.getUserData().subscribe(
      (response) => {
        // console.log('response received')
        // this.loginUserID = "gokul.r@datifex.com";
        this.loginUserID = (this.profileInfo.accountEmail ? this.profileInfo.accountEmail : "");
        this.usersDetails = response;
        this.dataSource = new MatTableDataSource(this.usersDetails);
        this.displayedColumns = ['select', 'First Name', 'teamsid', 'Role'];
        console.log("ship user details", this.usersDetails);
        var userData = this.usersDetails.filter(obj => obj.email == this.configService.accountEmail)
        if (userData.length > 0) {
          this.loginUserdata = {
            "fName": userData[0]['First Name'],
            "lName": userData[0]['Last Name'],
            "email": userData[0].email
          }
          // this.userList.push(this.loginUserdata);
        }
        this.userInfoDetails.push(this.loginUserdata)
        // var repos = response;
        // var logingUserInfo = repos.filter(t => t.email === this.loginUserID);
        // console.log("datat2222 : this.loginUserID", logingUserInfo);
        this.shipUserDetails(this.loginUserID)
      },
      error => {
        console.error('Request failed with error')
        // console.log(error)
      })
  }


  shipUserDetails(value) {
    // console.log("shipUserDetails", value);
    let userInfo = this.usersDetails.find(el => el.email === value);
    // console.log("OOD User Details", userInfo);
    // this.createFormGroup.controls.azureIdCtrl.setValue(this.usersInfo._id);
    if (userInfo) {
      this.usersInfo = userInfo;
    }
  }
  move(index: number) {
    this.chitFormIndex = index;
    this.stepper.selectedIndex = this.chitFormIndex;
    this.submitted = false;
  }
  back(index: number) {
    this.stepper.selectedIndex = 1;
    this.submitted = false;
  }
  radiateList() {
    this.chitService.radiateList({ port: this.loadedPort }).subscribe(response => {
      if (response['result'] === 'success') {
        // this.CreateChitEvolution();
        var radiates = response['radiatesData']
        this.radiatesList = radiates.radiates;
        // console.log('data radiatesList', this.radiatesList);
      }
    })
  }
  radiateCheck() {
    if (this.createActivityFormGroup.valid) {
      // ship check
      var shipList = this.currentShipList.ships;
      // console.log("shipList Data", shipList)
      var shipName = this.radiatesList;
      var startDate = this.datePipe.transform(this.createActivityFormGroup.get('Date').value, 'dd-MMM-yy');
      var enddate = this.datePipe.transform(this.createActivityFormGroup.get('Date').value, 'dd-MMM-yy' || this.completeFormGroup.get('Date').value, 'dd-MMM-yy');
      var shipLocationMatch = shipList.filter(t => t.location == this.createActivityFormGroup.get('Location').value);

      let selectedLocation = shipLocationMatch.filter(
        m => new Date(m.startdate) >= new Date(startDate) && new Date(m.enddate) <= new Date(enddate)
      );
      // console.log("selectedLocation", selectedLocation);


      // console.log("shipName List", shipName, this.createActivityFormGroup.get('Vessel').value);
      // var shipNameMatch = shipName.filter(t => t.ship == this.createActivityFormGroup.get('Vessel').value || t.location == this.createActivityFormGroup.get('Location').value || t.startdate == startDate);
      var shipRadiatingData = this.createActivityFormGroup.get('EVOLUTION').value.toLowerCase();
      this.radhazVisServ.isRHEmitter(shipRadiatingData)
      if (this.radhazVisServ.isRHEmitter(shipRadiatingData)) {
        var shipRadiatingMatch = shipName.filter(t => t.ship == this.createActivityFormGroup.get('Vessel').value && t.location == this.createActivityFormGroup.get('Location').value && t.startdate == startDate);
      }

      if (this.isReadonly == true) {
        // console.log("view data");
        this.stepper.selectedIndex = 2;
      } else {
        this.submitted = true;
        if (shipRadiatingMatch?.length > 0) {
          // console.log("ship Radiating Match", shipRadiatingMatch)
          this.broadcastInfo({ src: 'chit', event: 'collision', data: "collision-confirm-box", key: "" });
        } else {
          if (shipLocationMatch?.length > 0) {
            if (selectedLocation?.length > 0) {
              var shipNameMatch = selectedLocation.filter(t => t.ship == this.createActivityFormGroup.get('Vessel').value);
              if (shipNameMatch?.length > 0) {
                this.CreateChit();
              } else {
                this.openPublishDialog.emit("There is a Vessel already in the same location.");
                this.chitWindow();
              }
              // this.openPublishDialog.emit("This Activity Already Created this Location!!");
              // this.chitWindow();
            } else {
              this.CreateChit();
            }
          } else {
            this.CreateChit();
          }

        }
      }
    }


  }
  disabledForm() {
    if (this.isReadonly == true) {
      this.createFormGroup.disable();
      this.evolutionFormGroup.disable();
      this.locationFormGroup.disable();
      this.safeFormGroup.disable();
      this.vesselsFormGroup.disable();
      this.informFormGroup.disable();
      this.flagFormGroup.disable();
      this.signFormGroup.disable();
      this.proximityFormGroup.disable();
      this.completeFormGroup.disable();
    }
  }
  chitDeleteForm() {
    // this.chitDeleteIcon = true;
    let data = {
      chitId: this.chitId,
      port: this.loadedPort,
      chit_id: this.chitId
    }
    this.broadcastInfo({ src: 'chit', event: 'chitDeleteshipList', data: data });
    this.chitWindow();
    this.broadcastInfo({ src: 'chitStepper', event: 'chitDeleteshipStatus', data: "false" });
  }



  // chit create

  backChit() {
    this.createDataChitForm = true;
    this.createDataChitFormStepp1 = false;
    this.submitted = false;
    // delete this.userEmailData;
    // delete this.userList;
    this.userList = [];
    this.userEmailData = [];
    if (this.collisionMode) {
      this.clearBeforeCollisionDetection(false)
      this.collisionMode = false;
    }
  }
  getChitActivityData() {
    this.configService.getProjectConfig().then((project_config: any) => {
      this.chitService.getChitActivityDataList().subscribe(response => {
        // console.log('data getChitActivityData', response);
        this.chitActivityList = response;
      })
    });
  }
  formView = false;
  chitActivity: any;
  listofvisitor:any = [];
  userEmailDataList:any = [];
  assignContacts:any = "";
  getChitActivityDataView() {
    this.chitService.getChitActivityData(this.chitId).subscribe(
      (response) => {
        var repos = response;
      
        if (repos.length !== 0) {
          var activity = repos[0];
          this.chitReload = repos[0];
          this.isReadonly = false;
          this.isAddUser = false;
          // this.createActivityFormGroup.patchValue(this.chitFlags);
          this.chitCreateLocation = activity.Location ? activity.Location : "";
          // if (activity.activity) {
          //   setTimeout(() => {
          this.chitDetailForm = activity.activity ? activity.activity : "";
          //   }, 100);
          // }
          this.visitorIds = activity.visitorIds ? activity.visitorIds:[];
          this.userEmailData = JSON.parse(activity.notification);
          this.userEmailDataList =this.userEmailData;
          this.prefixActivity = (activity.activity ? activity.activity : "");
          if (activity.emitterLocation) {
            this.prefixLocation = (activity.emitterLocation ? activity.emitterLocation : "")
          }
          if (activity.manAloftLocation) {
            this.prefixLocation = (activity.manAloftLocation ? activity.manAloftLocation : "")
          }
          this.chitCreateDate = (new Date(activity.Date) ? new Date(this.ctrlServ.formatUTCTZ(activity.Date, "DD-MMM-YYYY")) : "");
          // this.assignContacts = this.usersDetails.filter(item=> item.email == activity.assignContact)[0]['First Name']
          // if data is empty its throwing error handled Exception @prabha
          // this.assignContacts = activity.assignContact!=""&&this.usersDetails&&this.usersDetails.length>0?this.usersDetails.filter(item=> item.email == activity.assignContact)[0]['First Name']:"";
          let assignContacts = activity.assignContact != "" && this.usersDetails && this.usersDetails.length > 0 ? this.usersDetails.filter(item => item.email == activity.assignContact) : [];
          this.assignContacts = assignContacts.length > 0 ? assignContacts[0]['First Name'] : "";
          
          this.createActivityFormGroup.setValue({
            UserID: (activity.UserID ? activity.UserID : ""),
            activity: (activity.activity ? activity.activity : ""),
            Location: (activity.Location ? activity.Location : ""),
            Vessel: (activity.Vessel ? activity.Vessel : ""),
            OnsiteName: (activity.OnsiteName ? activity.OnsiteName : ""),
            // SupName: (activity.SupName ? activity.SupName : ""),
            Start: (activity.Start ? activity.Start : ""),
            End: (activity.End ? activity.End : ""),
            Date: (new Date(activity.Date) ? new Date(this.ctrlServ.formatUTCTZ(activity.Date, "DD-MMM-YYYY")) : ""),
            emitterLocation: (activity.emitterLocation ? activity.emitterLocation : ""),
            manAloftLocation: (activity.manAloftLocation ? activity.manAloftLocation : ""),
            purpose: (activity.purpose ? activity.purpose : ""),
            notification: (this.userEmailData.length > 0 ? this.userEmailData : []),
            assignContact: (activity.assignContact ? activity.assignContact : ""),
            contractor: (activity.contractor ? activity.contractor : []),
            visitorIds: (activity.visitorIds ? activity.visitorIds : []),
          });
          this.chitActivity = activity.activity;
          this.listofvisitor = activity.visitorIds ? JSON.parse(JSON.stringify(activity.visitorIds)) : [];
          // this.selection = this.userEmailData;
          // this.userEmailDataList = this.userEmailData;
          // this.selection[this.userEmailData];
          // this.selection['_selected'];
          // this.selection.select(...this.dataSource.data);
          // this.selection.isSelected(this.userEmailData);
          // this.selection['_selected'].push(this.userEmailData)
          // this.selection.toggle(this.userEmailData);
          // this.selection.select(this.userEmailData)
          var evolution = activity.Evolution
          this.officerName = activity.officerName;
          this.createActivityCompleteFormGroup.patchValue(evolution);
          this.createActivityFormGroup.patchValue(activity.activity);
          this.createActivityFormGroup.disable();
          this.createActivityCompleteFormGroup.disable();
          this.showEmitterEnvelope(activity);
          this.chitCreateEvolution=activity.activity;
          console.log("chit data--- : ", repos[0],this.createActivityFormGroup);
        }
      },
      error => {
        console.error('Request failed with error')
      })
  }
  activity_overlap = null;
  selfCollied: boolean = false;
  radiateCheckShipReady(data = null) {
    console.log("radiateCheckShipReady", data);
    if (data && data.length) {
      this.activity_overlap = data;
    }
    if (!this.collision_detection_state) {
      return;
    }

    this.selfCollisionCheck();

    if (!this.collision) {
      if(this.chitViewData){
        this.chitId = this.chitViewData['typeCollectionId'];
        this.CreateChitActivity(true);
      }else{
        var chitData = [{ 'typeCollectionId': '', 'typeCollection': 'Chits', 'purpose': this.createActivityFormGroup.get('purpose').value, "tagLocation": this.tagLocation }]
      this.broadcastInfo({ src: 'chit', event: 'chitCreate', data: chitData, seletedCollection: this.selectedTagItem, chit: this.createActivityFormGroup.value, filterDate: this.createActivityFormGroup.get('Date').value });
      }
    } else {
      this.selfCollied = true;
    }
    this.collision_detection_state = false;
  }

  selfCollisionContinue(){
    this.selfColliedRequestApprovalStatus = true;
    var chitData =[{'typeCollectionId': '', 'typeCollection': 'Chits','purpose':this.createActivityFormGroup.get('purpose').value ,"tagLocation": this.tagLocation}]
    this.broadcastInfo({ src: 'chit', event: 'chitCreate', data: chitData, seletedCollection:this.selectedTagItem, chit: this.createActivityFormGroup.value,filterDate:this.createActivityFormGroup.get('Date').value  });
    delete this.collideMsg;
    this.selfCollied = false;
    // this.userAdded()
    // this.selfCollied=false;
    // this.vesselSelfColliedRequestApproval = true;
    // this.createDataChitForm = false;
    // this.createDataChitFormStepp1 = false;
  }
  vesselSelfColliedRequestApproval: boolean = false;
  selfColliedRequestApprovalStatus:boolean = false;
  // selfColliedRequestApproval(){
  //   this.selfCollisionRequestForm();
  // }
  selfCollisionRequestForm(){
    delete this.collideMsg;
    this.userdataSocket()
    this.userAdded();
    this.CreateChitActivity();
    this.vesselRequestApproval = false;
    this.createDataChitForm = false
    this.vesselSelfColliedRequestApproval = false;
    this.createDataChitFormStepp1 = true;
  }
  selfCollisionCancel(){
    this.chitWindow();
    delete this.collideMsg;
    this.selfCollied = false;
  }
  selfCollisionRequestCancel(){
    this.chitWindow();
    delete this.collideMsg;
    this.selfCollied=false;
  }
  collision_detection_state=false;
  radiateCheckShip() {
    this.collision_detection_state = true;
    this.activity_overlap = null;
    if (this.dockyardView && !this.cd_in_iframe) {
      const snack = this.snackbar.open('Wait! Dockyard data not loaded!', "", { duration: 3000 });
      return;
    }
    if (this.chitService.typeCollectionForm && this.chitService.typeCollectionForm['name'] == "createCollectionFormActive") {
      if (this.createActivityFormGroup.valid) {
        this.createActivityFormGroup.value.manAloftLocation = this.createActivityFormGroup.value.manAloftLocation
        //this.broadcastInfo({src:"chitStepper", event:"get-activities-overlap",data:this.createActivityFormGroup.value,key:true})
        this.broadcastInfo({ src: "chitStepper", event: "show-current-activities", data: this.createActivityFormGroup.value, key: true })
      }
    } else {
      if (this.chitKey == 'dashboard') {
        if (this.createActivityFormGroup.valid) {
          if (!this.collisionMode) {
            this.radhazVisServ.resetCollisions();
            this.radhazVisServ.reloadRadHazActivity();
            this.enableEmitterEvelope();
            setTimeout(() => {
              this.radiateCheckShipDetails();
            }, 500)
          } else {
            // this.broadcastInfo({src:"chitStepper", event:"show-current-activities",data:this.createActivityFormGroup.value,key:true})
          }

        }
      } else {
        if (this.createActivityFormGroup.valid) {
          this.radhazVisServ.selectedActivity = this.createActivityFormGroup['value']['activity'];

          if (this.navyFilterToggle === 'commanding') {
            this.broadcastInfo({ src: 'chit', event: 'chitRadiateCheck', data: '' });
            let animation = (timer) => {
              if (this.ctrlServ.isDayShipsLoaded) {
                this.ctrlServ.isDayShipsLoaded = false;
                this.enableEmitterEvelope();
                setTimeout(() => {
                  this.radiateCheckShipDetails();
                }, 500)
                cancelAnimationFrame(AnimationTimer);
              } else {
                AnimationTimer = window.requestAnimationFrame(animation);
              }
            }
            var AnimationTimer = window.requestAnimationFrame(animation)
          } else {
            this.radhazVisServ.resetCollisions();
            this.radhazVisServ.reloadRadHazActivity();
            this.enableEmitterEvelope();
            setTimeout(() => {
              this.radiateCheckShipDetails();
            }, 500)
          }

        }
      }
    }
    if (this.isReadonly == true) {
      this.CreateChitActivity();
    }


  }
  collideMsg: any;
  selfCollisionCheck() {
    delete this.collideMsg;
    this.radhazVisServ.resetCollisionParams();
    if (this.createActivityFormGroup.valid) {
      this.collision = false;
      let act = this.createActivityFormGroup.get('activity').value
      if (this.activity_overlap && this.activity_overlap.length) {
        this.radhazVisServ.showOutlinesForActivities('ehfx', this.activity_overlap);
        this.collision = !false;
        let msg = "Collides with another activity at same location!";
        // const snack = this.snackbar.open(msg,"",{duration:3000});
        this.collideMsg = msg;
        return;
      }
      if (this.radhazVisServ.isRHEmitter(act) || act == 'man-aloft') {
        let self_coll = this.radhazVisServ.checkSelfCollisionDW('ehfx', this.createActivityFormGroup.value);
        if (self_coll) {
          this.collision = !false;
          let msg = "Collides with another activity!"
          // const snack = this.snackbar.open(msg,"",{duration:3000});
          this.collideMsg = msg;
        }
      }
      if (this.radhazVisServ.isRHEmitter(act) || act == 'radiating') {
        let self_other_collection = this.radhazVisServ.checkSelfCollisionForActivities('ehfx', this.tagServ.messIdDetails, act, this.createActivityFormGroup.value);
        if (self_other_collection) {
          this.collision = !false;
          let msg = "Collides with another activity!"
          this.collideMsg = msg;
          // var data = [];
          var data  =this.radhazVisServ.radiateCollision[0].split('_');
          var props;

          // this.filterNotesDataDetails.filter()
          var filterNotesData = this.filterNotesDataDetails.filter(t => t.tagShapeId == data[1]);
          if (filterNotesData.length > 0) {
            props = filterNotesData[0].props
            var collisionShipInfo = {
              activity: props.activity?props.activity:"",
              location: props.Location?props.Location:"",
              ship: props.Vessel?props.Vessel:"",
              startdate: filterNotesData[0].startDate,
              starttime: filterNotesData[0].startTime
            }
          }
          this.selfCollisionData = collisionShipInfo;

        }
      }
    }
  }
  radiateShipSelected: any;
  radiateCheckShipDetails() {
    // if (this.isReadonly == true) {
    //   this.CreateChitActivity();
    // }
    if (this.createActivityFormGroup.valid) {
      this.submitted = true;
      // ship check
      var shipList = this.currentShipListInfo;
      // this.userdataSocket()
      // var shipList = this.currentShipList.ships;
      var shipName = this.radiatesList;
      var startDate = this.datePipe.transform(this.createActivityFormGroup.get('Date').value, 'yyy-MM-dd');
      var enddate = this.datePipe.transform(this.createActivityFormGroup.get('Date').value, 'yyy-MM-dd' || this.completeFormGroup.get('Date').value, 'yyy-MM-dd');
      var shipLocationMatch = shipList.filter(t => t.location == this.createActivityFormGroup.get('Location').value);
      var startTime = this.createActivityFormGroup.get('Start').value;
      var endTime = this.createActivityFormGroup.get('End').value;

      let s = new Date(new Date(startDate).toDateString() +" "+startTime);
      let e = new Date(new Date(enddate).toDateString() +" "+endTime);
      // let cs = new Date(new Date(m.startdate).toDateString() +" "+m.starttime);
      // let ce = new Date(new Date(m.endtime).toDateString() +" "+m.endtime);
      // let selectedLocation = shipLocationMatch.filter(m=> (new Date(m.startdate)<=  new Date(startDate) && new Date(m.enddate) >= new Date(enddate))&&
      // ((m.starttime)<=startTime&&startTime<=(m.endtime)||(m.starttime)<=endTime&&endTime<=(m.endtime)));
      // 
      let selectedLocation = shipLocationMatch.filter(
          m => (s>=new Date(new Date(m.startdate).toDateString() +" "+m.starttime)&&s<=new Date(new Date(m.enddate).toDateString() +" "+m.endtime))||(e>=new Date(new Date(m.startdate).toDateString() +" "+m.starttime)&&e<=new Date(new Date(m.enddate).toDateString() +" "+m.endtime))
        );
      this.radiateShipSelected = selectedLocation[0];
      if (shipLocationMatch?.length > 0) {
        if (selectedLocation?.length > 0) {
          let act = this.createActivityFormGroup.get('activity').value

          if (this.radhazVisServ.isRHEmitter(act) || act == 'man-aloft') {
            var shipNameMatch = selectedLocation.filter(t => t.ship == this.createActivityFormGroup.get('Vessel').value);
            if (shipNameMatch?.length > 0) {
              this.collision = true;
              this.collisionDetection = shipNameMatch[0];
              this.selectedShipElem = shipNameMatch[0];
              // this.userAdded();
              if (this.dockyardView) {
                this.getCollisionDetection();
              }else if(this.selfColliedRequestApprovalStatus){
                this.selfCollied=false;
                this.vesselSelfColliedRequestApproval = true;
                this.createDataChitForm = false;
                this.createDataChitFormStepp1 = false;
              }else{
                this.collision = false;
                this.userAdded();
                this.CreateChitActivity();
              }
              console.log(this.userInfoDetails)

            } else {
              this.collision = true;
              this.userAdded();
              //this.openPublishDialog.emit("There is a Vessel already in the same location.");
              // this.CreateChitActivity()
              //this.userdataSocket()
              //this.chitWindow();
            }
          } else {
            this.collision = false;
            this.userAdded();
            //this.userdataSocket()
            // this.CreateChitActivity()
          }
        } else {
          this.collision = false;
          this.userAdded();
          //this.userdataSocket()
          this.createDataChitForm = false;
          // this.CreateChitActivity()
          //this.CreateChitActivity()
          //this.getCollisionDetection();
        }
      }
      else {
        this.collision = false;
        this.userAdded();
        this.createDataChitForm = false;
        //this.userdataSocket()
        // this.CreateChitActivity()
        // this.getCollisionDetection();
      }
    }
  }

  getCollisionDetection() {
    var radiate = this.createActivityFormGroup.get('activity').value;
    if (radiate?.length > 0) {
      this.collisionShipInfo = this.collisionDetection;
      var chitData = this.collisionDetection;
      //console.log(" Activity Created ", shipData);
      if (this.radhazVisServ.isRHEmitter(radiate)) {
        this.chitOnEditState = true;
        let chitNotification = { 'Vessel': this.createActivityFormGroup.value.Vessel, Activity: this.collisionDetection.activity, 'OOD': this.createActivityFormGroup.value.OnsiteName, "Date": this.createActivityFormGroup.value.Date, "editStatus": this.chitOnEditState }
        this.broadcastInfo({ src: 'chit', event: 'shipChitNotification', notify: chitNotification });
        // this.broadcastIframeInfo({ iframeConnection: 'dockyard', event: 'shipChitNotification',notify: chitNotification});
      }
      this.broadcastInfo({ src: 'chit', event: 'chitAcitivity', data: chitData, chitForm: this.createActivityFormGroup.value, currentShipList: this.currentShipListInfo });
      if (this.dockyardView) {
        this.broadcastIframeInfo({ iframeConnection: 'dockyard', event: 'chitAcitivity', data: chitData, chitForm: this.createActivityFormGroup.value, currentShipList: this.currentShipListInfo });
      }
    }
    // this.userAdded();
  }

  selectUser:any
  viewChitsStatus(user){
    this.userPinState=true;
    console.log("viewChitsStatus",user,this.chitViewData,this.chitReload,this.userEmailData);
    this.selectUser =user;
    // this.broadcastInfo({ src: 'sidepanel', event: 'chitsApproveStatus', data: "" });
  }

  numberPad(val){
    if(val=='backspace'){
      this.userPin =this.userPin.slice(0,-1);
    }else{
      this.userPin =this.userPin?this.userPin+val:val;
    }
  }

  closeUserPin(){
    delete this.userPinValid;
    delete this.userPin;
    this.userPinState=false;
  }
  userPin:any;
  userPinValid:any;
  userPinState:boolean=false;
  validateUserPin(){
    if(this.userPin){
      this.chitService.getUserData()
      .subscribe(
        (response) => {
          var repos = response;
          console.log("getUserData",repos);
          let userData = repos.filter(e=> e['email']==this.selectUser['email'])
          if(userData.length>0){
            if(userData[0].Pin==this.userPin){
              delete this.userPinValid;
              this.userPinState=false;
              delete this.userPin;
              this.broadcastInfo({ src: 'sidepanel', event: 'chitsApproveStatus', data: this.selectUser,chit:this.chitViewData, chitEmailList:this.userEmailData });
            }else{
              this.userPinValid = "Invalid Pin Number";
            }
          }
          // this.configService['userList'] = repos;
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
    }else{
      this.userPinValid = "Please Enter Pin Number";
    }
  }


  CreateChitActivity(booflagStatus = false) {
    if (this.isReadonly == true) {
      this.createDataChitForm = false;
      if (!this.vesselRequestApproval && !this.cancelStatus) {
        this.createDataChitFormStepp1 = true;
      }
    } else {
      this.createActivityFormGroup['value']['notification'] = JSON.stringify(this.userEmailData);
      console.log("updateChitActivity", this.createActivityFormGroup.value);
      if (this.chitId) {
        this.createActivityFormGroup.value['visitorIds'] = this.visitorIds;
        let pushParams = this.createActivityFormGroup.value;
        pushParams['officerName'] = this.officerName;
        this.chitService.updateChitActivity(this.chitId, pushParams)
          .subscribe((res) => {
            var status = res.status
            var chitId = res.value;
            this.chitId = chitId._id;
            // console.log("chitId ", res);
            this.chitDetailForm = this.chitCreateEvolution || this.createActivityFormGroup.get('activity').value;
            this.createDataChitForm = false;
            if (!this.vesselRequestApproval && !this.cancelStatus) {
              this.createDataChitFormStepp1 = true;
            }
            
           var notesData = {description:pushParams['purpose'],startDate:pushParams['Date'],startTime:pushParams['Start'],EndDate:pushParams['Date'],endTime:pushParams['End'],props:{activity:pushParams['activity']},parentMeshId:pushParams['emitterLocation']?pushParams['emitterLocation']:'',tagName:pushParams['emitterLocation']?pushParams['emitterLocation']:pushParams['activity']}
           if(booflagStatus){
            this.CreateVisitor();
            var logBookData = {
              'typeCollection': 'Chits',
              "tagName": notesData['tagName'],
              "description": notesData['description'],
              "startDate": this.datePipe.transform(notesData['startDate'], 'yyyy-MM-dd'),
              "startTime": notesData['startTime'],
              "endTime": notesData['endTime']
            };
            this.chitService.createLogBook(logBookData);
          }
           this.chitViewData = this.chitViewData === undefined ? { props: {} } : this.chitViewData;
           this.chitViewData['description']= (notesData['description'] ? notesData['description'] : "");
           this.chitViewData['startDate']=notesData['startDate'];
           this.chitViewData['startTime']=notesData['startTime'];
           this.chitViewData['EndDate']=notesData['EndDate'];
           this.chitViewData['endTime']=notesData['endTime'];
           this.chitViewData['props']['activity']=notesData['props']['activity'];
           this.chitViewData['parentMeshId']=notesData['parentMeshId'];
           this.chitViewData['tagName']=notesData['tagName'];
            this.broadcastInfo({ src: 'chit', event: 'chitUpdate', data: this.chitViewData });
            this.broadcastInfo({ src: 'chitApp', event: 'chitDataModified', data: 'true' });
            if (this.ctrlServ.commandingOfficer == 'commanding') {
              this.broadcastInfo({ src: 'chit', event: 'commandingOfficer', data: 'commanding', key: this.createActivityFormGroup.get('Date').value });
            }
            if (status === '200') {
            }
          }, error => {
            console.error('There was an error!');
          })
      } else {
        this.createActivityFormGroup.value['visitorIds'] = this.visitorIds;
        // this.datePipe.transform(this.createActivityFormGroup.get('Date').value, 'yyyy-MM-dd')
        let pushParams = this.createActivityFormGroup.value;
        pushParams['officerName'] = this.officerName
        this.chitService.addChitActivity(pushParams)
          .subscribe((res) => {
            if(booflagStatus){
              this.CreateVisitor();
              var logBookData = {
                'typeCollection': 'Chits',
                "tagName": this.createActivityFormGroup.value.activity,
                "description": this.createActivityFormGroup.value.purpose,
                "startDate": this.datePipe.transform(this.createActivityFormGroup.value.Date, 'yyyy-MM-dd'),
                "startTime": this.createActivityFormGroup.value.Start,
                "endTime": this.createActivityFormGroup.value.End
              };
              this.chitService.createLogBook(logBookData);
            }
            var status = res.status
            this.chitId = res.insertedId;
            this.formView = false;
            // console.log("chitId ", res);
            var chitData = [{ 'typeCollectionId': this.chitId, 'typeCollection': 'Chits', 'purpose': this.createActivityFormGroup.get('purpose').value }]
            this.broadcastInfo({ src: 'chit', event: 'chitCreate', data: chitData, filterDate: this.chitCreateDate, "tagLocation": this.tagLocation });
            this.chitDetailForm = this.chitCreateEvolution || this.createActivityFormGroup.get('activity').value;
            this.createDataChitForm = false;
            if (!this.vesselRequestApproval && !this.cancelStatus) {
              this.createDataChitFormStepp1 = true;
            }
            if (this.ctrlServ.commandingOfficer == 'commanding') {
              this.broadcastInfo({ src: 'chit', event: 'commandingOfficer', data: 'commanding', key: this.createActivityFormGroup.get('Date').value });
            }
          }, error => {
            console.error('There was an error!');
          })
      }
    }

  }

  chitCompletedForm() {
    this.collision = false
    this.userdataSocket()
    if (this.isReadonly == false) {
      if (this.createActivityCompleteFormGroup.valid) {
        this.chitService.addChitEvolutionActivity(this.chitId, this.createActivityCompleteFormGroup.value)
          .subscribe((res) => {
            var status = res.status
            this.formView = false;
            let data = {
              chitId: this.chitId,
              message: this.createActivityFormGroup.get('Vessel').value + " ship created " + this.createActivityFormGroup.get('activity').value + " activity @" + this.createActivityFormGroup.get('Location').value,
              receiver: this.getReceiverInfo()
            }
            let vessel = this.createActivityFormGroup.get('Vessel').value;
            // let date = this.createActivityFormGroup.get('Date').value;
            this.ctrlServ.animationDate = this.createActivityFormGroup.get('Date').value;
            this.broadcastInfo({ src: 'chitApp', event: 'chitDataModified', data: 'true' });
            this.broadcastInfo({ src: 'chitStepper', event: 'chitActivity', data: data });
            this.ChitFormComplete();
            var reloadUpdate = {
              vessel: this.createActivityFormGroup.get('Vessel').value || this.chitReload.Vessel,
              date: this.createActivityFormGroup.get('Date').value || this.chitReload.Date,
            }
            this.broadcastInfo({ src: 'chitStepper', event: 'updateChitData', data: "reload", key: reloadUpdate });
            if (status === '200') {
            }
          }, error => {
            console.error('There was an error!');
          })
      }
    } else {
      this.createActivityCompleteFormGroup.disable();
      this.ChitFormComplete();
    }


  }


  chitDeleteActivity() {
    this.chitService.deleteChitActivity(this.chitId)
      .subscribe((res) => {
        // console.log("Chit Id Delete Status", res)
        this.chitWindowState = false;
        // let vessel = this.createActivityFormGroup.get('Vessel').value;
        // let date = this.createActivityFormGroup.get('Vessel').value;
        var reloadUpdate = {
          vessel: this.createActivityFormGroup.get('Vessel').value || this.chitReload.Vessel,
          date: this.createActivityFormGroup.get('Date').value || this.chitReload.Date,
        }
        this.broadcastInfo({ src: 'sidepanel', event: 'stepper', data: this.chitWindowState, key: "" });
        // this.broadcastInfo({ src: 'chit', event: 'chitDelete', data: this.chitId, key: "Chits" });
        // this.broadcastInfo({ src: 'chitStepper', event: 'updateChitData', data: "reload", key: reloadUpdate });
        this.broadcastInfo({ src: 'chitApp', event: 'chitDataModified', data: 'true' });
      }, error => {
        console.error('There was an error!');
      })
  }
  onSiteContactInfo: any = [];
  staffContactInfo: any = [];
  selectOnSite(value) {
    this.onSiteContactInfo = []
    this.onSiteContactInfo.push(value);
    // console.log("selectOnSite ", this.onSiteContactInfo)
  }
  selectStaff(value) {
    this.staffContactInfo = []
    this.staffContactInfo.push(value);
    // console.log("selectStaff ", this.staffContactInfo)
  }
  getReceiverInfo() {
    let userInfo = [];
    let reciverEmail = [...this.onSiteContactInfo, ...this.staffContactInfo, ...this.getUserEmailData]
    this.usersDetails.forEach(el => {
      if (reciverEmail.includes(el.email)) {
        let obj = { id: el['_id'], teamsid: el.teamsid, email: el.email };
        userInfo.push(obj)
      }
    })
    // console.log("user ----", userInfo);
    return userInfo;
  }
  last_sel_evolution = null;
  selectEvolution(value) {
    this.clearLastShownEmittersMA('ehfx');
    if (value != 'man-aloft' || !this.radhazVisServ.isRHEmitter(value)) {
      // this.createActivityFormGroup.value.manAloftLocation = '';
      this.createActivityFormGroup.controls.manAloftLocation.setValue('');
      this.createActivityFormGroup.controls.emitterLocation.setValue('');
      if (this.createActivityFormGroup.get('emitterLocation').value) {
        this.radhazVisServ.showActivityInView(this.createActivityFormGroup.value, false);
      }
      if (this.createActivityFormGroup.get('manAloftLocation').value) {
        this.radhazVisServ.showActivityInView(this.createActivityFormGroup.value, false);
      }
    }
    this.chitDetailForm = value || this.chitCreateEvolution || this.createActivityFormGroup.get('Vessel').value;
    this.radhazVisServ.selectedActivity = value;
    this.last_sel_evolution = value;
  }
  selectVessel(value) {
    var shipLocationMatch = this.supervisorListData.filter(t => t.vessel == value);
    this.onSiteContactName = shipLocationMatch[0].onSiteContactEmail;
    this.chitSupervisor = shipLocationMatch[0].supervisor_name_email;
    this.onSiteContactInfo.push(this.onSiteContactName);
    this.staffContactInfo.push(this.chitSupervisor);
  }
  // Supervisor

  supervisorList() {
    this.configService.getProjectConfig().then((project_config: any) => {
      // this.chitService.getOodData()
      this.chitService.getScheduleData()
        .subscribe(
          (response) => {
            var repos = response;
            this.supervisorListData = repos;
            console.log('this.supervi', this.supervisorListData)
            var filterData = this.supervisorListData.filter(obj => obj.userId == this.configService.accountEmail)
            if (filterData.length != 0) {
              this.chitFormBinded(filterData[0])
            }
            // this.loginUserID = (this.profileInfo.accountEmail ? this.profileInfo.accountEmail : "");
            // var userMatch = repos.filter(t => t.ood_email == this.loginUserID);
            // var vesselMatch = userMatch.filter(t => t.vessel.toLowerCase() == this.chitCreateVessel.toLowerCase());
            // var dateFilter = vesselMatch.filter(t => this.datePipe.transform(t.start_date, 'yyy-MM-dd') == this.chitUserDate);
            // // console.log('response received', dateFilter)
            // this.supervisorListData = dateFilter;
          },
          error => {
            console.error('Request failed with error')
            console.log(error)
          })
    });
  }

  /**
   * TODO
   * move this to common service
   */
  lastSelected_radhaz_comp: any;
  lastSelected_aloft_comp: any;
  onSelectEmitterLocation(id) {

    if (this.collisionMode) {
      let state = true;
      let shipId = 'ehfx';
      this.radhazVisServ.clearOutlines(shipId);
      // if previosly select man-aloft means clear that
      if (this.lastSelected_aloft_comp) {
        let state = false;
        let _id = this.lastSelected_aloft_comp;
        // this.broadcastInfo({ src: 'chitStepper', event: 'radhaz_maIndi', data: { shipId, state, id: _id } });
        this.radhazVisServ.showHideMAIndicator(shipId, state, _id)
        delete this.lastSelected_aloft_comp;
      }

      if (this.lastSelected_radhaz_comp) {
        let state = false;
        let _id = this.lastSelected_radhaz_comp;
        //this.broadcastInfo({ src: 'chitStepper', event: 'radhaz_rhIndi', data: { shipId, state, id: _id } });
        this.radhazVisServ.showHideRHCylinder(shipId, state, _id, this.last_sel_evolution);
      }
      this.radhazVisServ.rerenderTempActivities();
      this.radhazVisServ.showHideRHCylinder(shipId, state, id, this.chitCreateEvolution);
      let mmel = this.radhazVisServ.getRHCylinder(shipId, id, this.radhazVisServ.getAntCode(this.chitCreateEvolution));
      this.radhazVisServ.showOutline(mmel, 20, '#FF4433');
      //this.broadcastInfo({ src: 'chitStepper', event: 'radhaz_rhIndi', data: { shipId, state, id } });
      //"rerender-current-activities"
      this.lastSelected_radhaz_comp = id;
      if (mmel) {
        this.tagLocation = mmel.position;
      }

      return;
    }
    if (!this.selectedShipElem) return;
    if (this.selectedShipElem['model'] === "Halifax") {
      let state = true;
      let shipId = this.selectedShipElem['name'];

      // if previosly select man-aloft means clear that
      if (this.lastSelected_aloft_comp) {
        let state = false;
        let _id = this.lastSelected_aloft_comp;
        this.radhazVisServ.showHideMAIndicator(shipId, state, _id);
        //this.broadcastInfo({ src: 'chitStepper', event: 'radhaz_maIndi', data: { shipId, state, id: _id } });
        delete this.lastSelected_aloft_comp;
      }

      if (this.lastSelected_radhaz_comp) {
        let state = false;
        let _id = this.lastSelected_radhaz_comp;
        this.radhazVisServ.showHideRHCylinder(shipId, state, _id);
        //this.broadcastInfo({ src: 'chitStepper', event: 'radhaz_rhIndi', data: { shipId, state, id: _id } });
      }
      //this.broadcastInfo({ src: 'chitStepper', event: 'rerender-current-activities', data:{ shipId, state, id } });
      //this.broadcastInfo({ src: 'chitStepper', event: 'radhaz_rhIndi', data: { shipId, state, id } });
      this.radhazVisServ.showHideRHCylinder(shipId, state, id);
      this.lastSelected_radhaz_comp = id;
    }
  }
  clearLastShownEmittersMA(shipId, rerender = false) {
    this.radhazVisServ.clearOutlines(shipId);
    if (this.lastSelected_aloft_comp) {
      let state = false;
      let _id = this.lastSelected_aloft_comp;
      this.radhazVisServ.showHideMAIndicator(shipId, state, _id);
      //this.broadcastInfo({ src: 'chitStepper', event: 'radhaz_maIndi', data: { shipId, state, id: _id } });
      delete this.lastSelected_aloft_comp;
    }

    if (this.lastSelected_radhaz_comp) {
      let state = false;
      let _id = this.lastSelected_radhaz_comp;
      // this.broadcastInfo({ src: 'chitStepper', event: 'radhaz_rhIndi', data: { shipId, state, id: _id } });
      this.radhazVisServ.showHideRHCylinder(shipId, state, _id, this.last_sel_evolution);
      this.lastSelected_radhaz_comp = null;
    }
    if (rerender) {
      this.broadcastInfo({ src: 'chitStepper', event: 'rerender-current-activities', data: true });

    }
  }

  onSelectMALocation(id) {
    id = id.replace(' ', '_')
    if (this.collisionMode) {
      let state = true;
      let shipId = 'ehfx';
      this.radhazVisServ.clearOutlines(shipId);
      // if previosly select rediate means clear that
      if (this.lastSelected_radhaz_comp) {
        let state = false;
        let _id = this.lastSelected_radhaz_comp;
        this.radhazVisServ.showHideRHCylinder(shipId, state, _id, this.last_sel_evolution);
        //this.broadcastInfo({ src: 'chitStepper', event: 'radhaz_rhIndi', data: { shipId, state, id: _id } });
        delete this.lastSelected_radhaz_comp
      }

      if (this.lastSelected_aloft_comp) {
        let state = false;
        let _id = this.lastSelected_aloft_comp;
        this.radhazVisServ.showHideMAIndicator(shipId, state, _id);
        //this.broadcastInfo({ src: 'chitStepper', event: 'radhaz_maIndi', data: { shipId, state, id: _id } });
      }
      this.radhazVisServ.rerenderTempActivities();
      this.radhazVisServ.showHideMAIndicator(shipId, state, id);
      let mmel = this.radhazVisServ.getChildMeshEx(shipId, id)
      this.radhazVisServ.showOutline(mmel, 20, '#FF4433');
      //this.broadcastInfo({ src: 'chitStepper', event: 'rerender-current-activities', data:true });
      //this.broadcastInfo({ src: 'chitStepper', event: 'radhaz_maIndi', data: { shipId, state, id } });
      this.lastSelected_aloft_comp = id;
      if (mmel) {
        this.tagLocation = mmel.absolutePosition;
      }
      return
    }
    if (!this.selectedShipElem) return;
    if (this.selectedShipElem['model'] === "Halifax") {
      let state = true;
      let shipId = this.selectedShipElem['name'];

      // if previosly select rediate means clear that
      if (this.lastSelected_radhaz_comp) {
        let state = false;
        let _id = this.lastSelected_radhaz_comp;
        this.radhazVisServ.showHideRHCylinder(shipId, state, _id);
        //this.broadcastInfo({ src: 'chitStepper', event: 'radhaz_rhIndi', data: { shipId, state, id: _id } });
        delete this.lastSelected_radhaz_comp
      }

      if (this.lastSelected_aloft_comp) {
        let state = false;
        let _id = this.lastSelected_aloft_comp;
        this.radhazVisServ.showHideMAIndicator(shipId, state, _id);
        //this.broadcastInfo({ src: 'chitStepper', event: 'radhaz_maIndi', data: { shipId, state, id: _id } });
      }
      //this.broadcastInfo({ src: 'chitStepper', event: 'radhaz_maIndi', data: { shipId, state, id } });
      this.radhazVisServ.showHideMAIndicator(shipId, state, id);
      this.lastSelected_aloft_comp = id;
    }
  }

  enableEmitterEvelope() {
    if (!this.selectedShipElem) return;
    if (this.selectedShipElem['model'] === "Halifax") {
      let state = true;
      let shipId = this.selectedShipElem['name'];
      if (this.lastSelected_radhaz_comp) {
        let id = this.lastSelected_radhaz_comp;
        this.broadcastInfo({ src: 'chitStepper', event: 'radhaz_rhIndi', data: { shipId, state, id } });
      } else if (this.lastSelected_aloft_comp) {
        let id = this.lastSelected_aloft_comp;
        this.broadcastInfo({ src: 'chitStepper', event: 'radhaz_maIndi', data: { shipId, state, id } });
      }
    }
  }

  showEmitterEnvelope(chit) {
    if (!chit) return;
    this.radhazVisServ.selectedActivity = chit['activity'];
    let comp_id = chit['emitterLocation'] ? chit['emitterLocation'].trim() : "";
    let ma_comp_id = chit['manAloftLocation'] ? chit['manAloftLocation'].trim() : "";
    if (chit['activity']) {
      if (chit['activity'].toLowerCase() === 'radiating' && comp_id) {
        const found = this.radhazVisServ.radhaz_components.some(el => el == comp_id);
        if (found) {
          this.lastSelected_radhaz_comp = comp_id;
          this.onSelectEmitterLocation(comp_id);
        }
      } else if (chit['activity'].toLowerCase() === 'man-aloft' || chit['activity'].toLowerCase() === 'antenna rotating' && ma_comp_id) {
        const found = this.radhazVisServ.aloft_components.some(el => el == ma_comp_id);
        if (found) {
          this.lastSelected_aloft_comp = ma_comp_id;
          this.onSelectMALocation(ma_comp_id);
        }
      }
    }
  }
  addUserCard: any = false;
  addUser() {
    this.addUserCard = !this.addUserCard;
    this.createDataChitFormStepp1 = !this.addUserCard;
  }
  addUserSelected() {
    if (this.selection['_selected']?.length > 0) {
      // this.userList = [];
      this.selection['_selected'].forEach(ele => {
        var loginUserdata = {
          "fName": ele['First Name'],
          "lName": ele['Last Name'],
          "email": ele.email,
          'status': 'inProgress',
          'Role': ele.Role,
          'First Name': ele['First Name'],
          'defalutDelete': true
        }
        this.userList.push(loginUserdata);
      })
    }
    // this.userList.push(this.userEmailDataList)
    console.log("this.selection['_selected'];", this.selection['_selected']);
    this.createActivityFormGroup['value']['notification'] = JSON.stringify(this.userList);
    console.log("select", this.selection, this.userList, this.createActivityFormGroup['value']);
    this.addUser();
    this.userDataDetails()
  }
  /****
   * *** chip list for email multi user
   */
  userList: any = [];
  userEmailData: any = [];
  getUserEmailData: any = [];
  selection = new SelectionModel<any>(true, []);
  // add(event: any): void {
  //   const value = (event.value || '').trim();
  //   // Add our user
  //   if (value) {
  //     this.userList.push({ name: value });
  //   }
  //   // Clear the input value
  //   event.chipInput!.clear();
  // }

  remove(user: any): void {
    const index = this.userList.indexOf(user);
    if (index >= 0) {
      this.checkboxLabel(this.userList[index]);
      this.userList.splice(index, 1);
      this.userEmailData.splice(index, 1);
      this.selection.deselect(user)
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }


  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;

  }

  userdataSocket() {
    this.currentEmailList = this.configService.accountEmail
    let userListData = [];
    if (this.selectedTagItem) {
      this.selectedTagItem['typeCollectionId'] = this.chitId;
      this.selectedTagItem['typeCollection'] = "Chit";
    }

    if (!this.collision) {
      chitForm = true
      userListData = this.userList;//.filter(obj => obj.Role != 'OOD')
      userListData.forEach((ele, idx) => {
        if ( this.userInfoDetails[0]&&ele.email == this.userInfoDetails[0].email) {
          userListData.splice(idx, 1)
        }
      })
      
      var obj = {
        "userList": userListData,
        "tagShapeId": this.selectedTagItem && this.selectedTagItem.tagShapeId ? this.selectedTagItem.tagShapeId : " ",
        "layoutId": this.selectedTagItem && this.selectedTagItem.layoutId ? this.selectedTagItem.layoutId : "",
        "markerId": this.selectedTagItem && this.selectedTagItem.markerId ? this.selectedTagItem.markerId : " ",
        "AppName": "SDMS",
        'chitForm': chitForm,
        "messageData": this.getMessageData(),
        "CommentScreendata": this.selectedTagItem && this.selectedTagItem ? this.selectedTagItem : " ",
        'shipDetails': this.createActivityFormGroup.value,
        "purpose": this.createActivityFormGroup.value.purpose,
        "assignContact": this.createActivityFormGroup.value.assignContact,
        "contractor": this.createActivityFormGroup.value.contractor,
        'loginUser': this.userInfoDetails[0],
        'collisionStatus': this.collision,
        'collisionShipInfo': this.collisionShipInfo,
        "chitId": this.chitId
      }
      console.log("obj", obj)
      this.broadcastInfo({ src: 'comment', event: 'userSocketSendInfo', data: obj });
      this.saveMessageData();
    } else if(this.selfColliedRequestApprovalStatus){
      // userListData = this.userList.filter(obj => obj.Role == 'OOD')
      var chitForm = false;
      this.chitService.getScheduleData().subscribe(response => {
        if (response && response.length > 0) {
          let filterUser = response.filter(ele => ele.role == 'URFSO' && ele.vessel == this.createActivityFormGroup.value.Vessel && ((this.datePipe.transform(ele.start_date, 'dd-MMM-YY') >= this.datePipe.transform(this.createActivityFormGroup.value.Date, 'dd-MMM-YY') && this.datePipe.transform(ele.start_date, 'dd-MMM-YY') <= this.datePipe.transform(this.createActivityFormGroup.value.Date, 'dd-MMM-YY')) || (this.datePipe.transform(ele.end_date, 'dd-MMM-YY') >= this.datePipe.transform(this.createActivityFormGroup.value.Date, 'dd-MMM-YY') && this.datePipe.transform(ele.end_date, 'dd-MMM-YY') <= this.datePipe.transform(this.createActivityFormGroup.value.Date, 'dd-MMM-YY'))));
          if (filterUser.length > 0) {
            filterUser.forEach(ele => {
              var emailData = this.usersDetails.filter(element => element.email == ele.userId);
              if (emailData && filterUser.length != 0) {
                var loginUserdata = {
                  "fName": emailData[0]['First Name'],
                  "lName": emailData[0]['Last Name'],
                  "email": emailData[0].email,
                  'status': 'inProgress',
                  'Role': "URFSO",
                  'First Name': emailData[0]['First Name'],
                }
              }
              userListData.push(loginUserdata)
            })
            userListData.forEach((ele, idx) => {
              if (ele.email == this.userInfoDetails[0].email) {
                userListData.splice(idx, 1)
              }
            })
            // if(this.selfCollisionData){
              var collisionShipInfo = {
                activity: this.selfCollisionData?this.selfCollisionData.activity:"",
                location: this.selfCollisionData?this.selfCollisionData.Location:"",
                ship: this.selfCollisionData?this.selfCollisionData.Vessel:"",
                startdate: this.selfCollisionData?this.selfCollisionData.Date:"",
                starttime: this.selfCollisionData?this.selfCollisionData.Start:"",
              }
            // }

            var obj = {
              "userList": userListData,
              "tagShapeId": this.selectedTagItem && this.selectedTagItem.tagShapeId ? this.selectedTagItem.tagShapeId : " ",
              "layoutId": this.selectedTagItem && this.selectedTagItem.layoutId ? this.selectedTagItem.layoutId : "",
              "markerId": this.selectedTagItem && this.selectedTagItem.markerId ? this.selectedTagItem.markerId : " ",
              "AppName": "SDMS",
              'chitForm': chitForm,
              "messageData": this.getMessageData(),
              "CommentScreendata": this.selectedTagItem && this.selectedTagItem ? this.selectedTagItem : " ",
              'shipDetails': this.createActivityFormGroup.value,
              "purpose": this.createActivityFormGroup.value.purpose,
              "assignContact": this.createActivityFormGroup.value.assignContact,
              "contractor": this.createActivityFormGroup.value.contractor,
              'loginUser': this.userInfoDetails[0],
              'collisionStatus': this.collision,
              'collisionShipInfo': collisionShipInfo,
              "chitId": this.chitId
            }
            console.log("obj", obj)
            this.broadcastInfo({ src: 'comment', event: 'userSocketSendInfo', data: obj });
            this.saveMessageData();

          }

        }

      })
      //   var data = this.supervisorListData.filter(obj => obj.shedule == 'OOD' && obj.vessel == this.createActivityFormGroup.value.Vessel && this.datePipe.transform(obj.start_date, 'dd-MMM-yy') == this.datePipe.transform(this.createActivityFormGroup.value.Date, 'dd-MMM-yy'));
      //  var  emailData = this.usersDetails.filter(element => element.email == data[0].ood_email)
      //   var loginUserdata = {
      //     "fName": emailData[0]['First Name'],
      //     "lName": emailData[0]['Last Name'],
      //     "email": emailData[0].email,
      //     'status':'inProgress',
      //     'Role': emailData[0].Role,
      //     'First Name': emailData[0]['First Name']
      //   }
      //   this.userList.push(loginUserdata)
      // var chitForm = false
    } else {
      // userListData = this.userList.filter(obj => obj.Role == 'OOD')
      var chitForm = false;
      this.chitService.getScheduleData().subscribe(response => {
        if (response && response.length > 0) {
          let filterUser = response.filter(ele => ele.role == 'OOD' && ele.vessel == this.collisionShipInfo.ship && ((this.datePipe.transform(ele.start_date, 'dd-MMM-YY') >= this.collisionShipInfo.startdate && this.datePipe.transform(ele.start_date, 'dd-MMM-YY') <= this.collisionShipInfo.enddate) || (this.datePipe.transform(ele.end_date, 'dd-MMM-YY') >= this.collisionShipInfo.startdate && this.datePipe.transform(ele.end_date, 'dd-MMM-YY') <= this.collisionShipInfo.enddate)));
          if (filterUser.length > 0) {
            filterUser.forEach(ele => {
              var emailData = this.usersDetails.filter(element => element.email == ele.userId);
              if (emailData && filterUser.length != 0) {
                var loginUserdata = {
                  "fName": emailData[0]['First Name'],
                  "lName": emailData[0]['Last Name'],
                  "email": emailData[0].email,
                  'status': 'inProgress',
                  'Role': "OOD",
                  'First Name': emailData[0]['First Name'],
                }
              }
              userListData.push(loginUserdata)
            })
            userListData.forEach((ele, idx) => {
              if (ele.email == this.userInfoDetails[0].email) {
                userListData.splice(idx, 1)
              }
            })
            var obj = {
              "userList": userListData,
              "tagShapeId": this.selectedTagItem && this.selectedTagItem.tagShapeId ? this.selectedTagItem.tagShapeId : " ",
              "layoutId": this.selectedTagItem && this.selectedTagItem.layoutId ? this.selectedTagItem.layoutId : "",
              "markerId": this.selectedTagItem && this.selectedTagItem.markerId ? this.selectedTagItem.markerId : " ",
              "AppName": "SDMS",
              'chitForm': chitForm,
              "messageData": this.getMessageData(),
              "CommentScreendata": this.selectedTagItem && this.selectedTagItem ? this.selectedTagItem : " ",
              'shipDetails': this.createActivityFormGroup.value,
              "purpose": this.createActivityFormGroup.value.purpose,
              "assignContact": this.createActivityFormGroup.value.assignContact,
              "contractor": this.createActivityFormGroup.value.contractor,
              'loginUser': this.userInfoDetails[0],
              'collisionStatus': this.collision,
              'collisionShipInfo': this.collisionShipInfo,
              "chitId": this.chitId
            }
            console.log("obj", obj)
            this.broadcastInfo({ src: 'comment', event: 'userSocketSendInfo', data: obj });
            this.saveMessageData();

          }

        }

      })
      //   var data = this.supervisorListData.filter(obj => obj.shedule == 'OOD' && obj.vessel == this.createActivityFormGroup.value.Vessel && this.datePipe.transform(obj.start_date, 'dd-MMM-yy') == this.datePipe.transform(this.createActivityFormGroup.value.Date, 'dd-MMM-yy'));
      //  var  emailData = this.usersDetails.filter(element => element.email == data[0].ood_email)
      //   var loginUserdata = {
      //     "fName": emailData[0]['First Name'],
      //     "lName": emailData[0]['Last Name'],
      //     "email": emailData[0].email,
      //     'status':'inProgress',
      //     'Role': emailData[0].Role,
      //     'First Name': emailData[0]['First Name']
      //   }
      //   this.userList.push(loginUserdata)
      // var chitForm = false
    }
    // userListData.forEach((ele, idx) => {
    //   if(ele.email == this.userInfoDetails[0].email){
    //     userListData.splice(idx,1)
    //   }
    // })
    // var obj = {
    //   "userList" : userListData,
    //   "tagShapeId": this.selectedTagItem.tagShapeId,
    //   "layoutId" : this.selectedTagItem.layoutId,
    //   "markerId" : this.selectedTagItem.markerId,
    //   "AppName" : "SDMS",
    //   'chitForm': chitForm,
    //   "messageData": this.getMessageData(),
    //   "CommentScreendata" :this.selectedTagItem.tagComments,
    //   'shipDetails': this.createActivityFormGroup.value,
    //   "purpose": this.createActivityFormGroup.value.purpose,
    //   'loginUser': this.userInfoDetails[0],
    //   'collisionStatus': this.collision,
    //   'collisionShipInfo':this.collisionShipInfo,
    //   "chitId": this.chitId
    // }
    // console.log("obj", obj)
    // this.broadcastInfo({ src: 'comment', event: 'userSocketSendInfo', data: obj });
    // this.saveMessageData();
  }
  getMessageData() {
    var messageData = {
      "notesId": this.selectedTagItem && this.selectedTagItem._id ? this.selectedTagItem._id : " ",
      "layoutId": this.selectedTagItem && this.selectedLayoutList._id ? this.selectedLayoutList._id : " ",
      "Messagedate": new Date(),
      "email": this.loginUserdata?this.loginUserdata.email:"",
      "name": this.loginUserdata?this.loginUserdata.fName + '' + this.loginUserdata.lName : "",
      "comments": "Request For Approval" + '@' + this.currentEmailList,
    }
    return messageData
  }

  saveMessageData() {
    var messagedata = this.getMessageData()
    this.configService.postData(this.configService.project_config.activityMessage, messagedata).subscribe(result => {
      console.log("ade", result)
    })
  }

  chitFormBinded(chitFormData) {
    this.createActivityFormGroup.patchValue({
      Location: (chitFormData.location ? chitFormData.location : ""),
      // Date: (new Date('01-Mar-2021')),//(new Date(chitFormData.start_date) ? new Date(chitFormData.start_date) : "" ),
      Start: (chitFormData.start_time ? chitFormData.start_time : "08:00"),
      End: (chitFormData.end_time ? chitFormData.end_time : "20:00"),
      activity: (chitFormData.activity ? chitFormData.activity : ""),
      Vessel: (chitFormData.vessel ? chitFormData.vessel : ""),
    });
    this.shipLocation = chitFormData.location;
    this.shipName = chitFormData.vessel;
  }

  userDataDetails() {
    var userListFilter = [];
    for (const key in this.userList) {
      const element = this.userList[key];
      for (const [key, value] of Object.entries(element)) {
        element["status"] = 'inprogress'
        if (key == "First Name" || key == "Role" || key == "status" || key == "email" || key == "teamsid" || key == "_id" || key == 'defalutDelete') {
        } else {
          delete element[key]
        }
      }
      userListFilter.push(element);
      this.getUserEmailData.push(element.email);
      this.userEmailData = userListFilter;
      this.selection.isSelected(this.userEmailData)
      // if(this.collision){

      // }
    }

    this.userEmailData.forEach(current => {
      this.userEmailDataList.forEach(privacy => {
        if (current.email == privacy.email) {
          current.status = privacy.status;
          if (privacy.status == 'yes' || privacy.status == 'no') {
            current.message = privacy.message;
          }
        }
      });
    });

    var emailUserData = [];
    this.userEmailDataList.forEach(element => {
      this.userEmailData.push(element);
    });
    


    emailUserData = this.removeDuplicatesUser(this.userEmailData, "email")
    this.userEmailData =emailUserData;
    this.CreateChitActivity(true)
  }
  removeDuplicatesUser(myArray, Prop) {
    return myArray.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[Prop]).indexOf(obj[Prop]) === pos;
    });
  }

  cancel() {
    this.vesselRequestApproval = false;
    this.createDataChitForm = true;
    this.submitted = false;
    this.cancelStatus = true;
    this.createDataChitFormStepp1 = false;
    if (this.collisionMode) {
      this.clearBeforeCollisionDetection(false)
      this.collisionMode = false;
    }
    this.formView = false;
  }
  requestForm() {
    this.userdataSocket()
    this.vesselRequestApproval = false;
    this.createDataChitForm = false
    this.createDataChitFormStepp1 = true;
  }
  userAdded() {
    this.cancelStatus = false;
    var userData = []
    if (this.collision) {
      console.log("formdata", this.createActivityFormGroup.value.Date)
      // console.log("collision", this.collisionShipInfo.startdate)
      var data = this.supervisorListData.filter(obj => obj.shedule == 'OOD' && (obj.vessel == this.collisionShipInfo.ship || obj.vessel == this.createActivityFormGroup.value.Vessel) && (this.datePipe.transform(new Date(obj.start_date), 'dd-MM-YYY') == this.collisionShipInfo.startdate || this.datePipe.transform(new Date(obj.start_date), 'dd-MM-YYY') == this.datePipe.transform(this.createActivityFormGroup.value.Date, 'dd-MM-YYY')));
      // console.log("filter",data);
      var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let newActivityDate = this.convertTZ(this.datePipe.transform(new Date(this.createActivityFormGroup.value.Date), 'dd-MM-YYY'), 'Asia/Calcutta');

      let filterData = { vessel: this.createActivityFormGroup.value.Vessel, date: this.createActivityFormGroup.value.Date }
      this.chitService.shedulerFilter(filterData).subscribe(response => {
        console.log("response", response);
        let filterData = response//.filter(obj => obj.role != 'OOD');
        if (filterData.length > 0) {
          var data = filterData
          if (data && data.length != 0) {
            data.forEach(ele => {
              var emailData = this.usersDetails.filter(element => element.email == ele.userId);
              if (emailData && data.length != 0) {
                var loginUserdata = {
                  "fName": emailData[0]['First Name'],
                  "lName": emailData[0]['Last Name'],
                  "email": emailData[0].email,
                  'status': 'inProgress',
                  'Role': ele.role,
                  'First Name': emailData[0]['First Name'],
                  'defalutDelete': false
                }
              }
              this.userList.push(loginUserdata)
            })
          }
        }
        this.userList.forEach((ele, idx) => {
          if (ele.email == this.userInfoDetails[0].email) {
            this.userList.splice(idx, 1)
          }
        })
      this.userDataDetails()
    })
    } else if(this.selfColliedRequestApprovalStatus){
      console.log("formdata", this.createActivityFormGroup.value.Date)
      // console.log("collision", this.collisionShipInfo.startdate)
      // var data = this.supervisorListData.filter(obj => obj.shedule == 'OOD' && (obj.vessel == this.collisionShipInfo.ship || obj.vessel == this.createActivityFormGroup.value.Vessel) && (this.datePipe.transform(new Date(obj.start_date), 'dd-MM-YYY') == this.collisionShipInfo.startdate || this.datePipe.transform(new Date(obj.start_date), 'dd-MM-YYY') == this.datePipe.transform(this.createActivityFormGroup.value.Date, 'dd-MM-YYY')));
      // console.log("filter",data);
      var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let newActivityDate = this.convertTZ(this.datePipe.transform(new Date(this.createActivityFormGroup.value.Date), 'dd-MM-YYY'), 'Asia/Calcutta');
      let filterData = {vessel:this.createActivityFormGroup.value.Vessel,date:this.createActivityFormGroup.value.Date}
      this.chitService.shedulerFilter(filterData).subscribe(response => {
        console.log("response",response);
        let filterData = response//.filter(obj => obj.role != 'OOD');
        if(filterData.length>0){
          var data = filterData
          if (data && data.length != 0) {
            data.forEach(ele => {
              var emailData = this.usersDetails.filter(element => element.email == ele.userId);
              if (emailData && data.length != 0) {
                var loginUserdata = {
                  "fName": emailData[0]['First Name'],
                  "lName": emailData[0]['Last Name'],
                  "email": emailData[0].email,
                  'status': 'inProgress',
                  'Role': ele.role,
                  'First Name': emailData[0]['First Name'],
                  'defalutDelete': false
                }
              }
              this.userList.push(loginUserdata)
            })
          }
        }
        this.userList.forEach((ele, idx) => {
          if(ele.email == this.userInfoDetails[0].email){
            this.userList.splice(idx,1)
          }
        })
      this.userDataDetails()
    })
    } else{
      console.log(this.supervisorListData)
      // var data = this.supervisorListData.filter(obj => obj.ood_email == this.configService.accountEmail &&  obj.vessel == this.createActivityFormGroup.value.Vessel && this.datePipe.transform(obj.start_date, 'dd-MMM-yy') == this.datePipe.transform(this.createActivityFormGroup.value.Date, 'dd-MMM-yy'));
      // console.log(data);
      var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let newActivityDate = this.convertTZ(this.datePipe.transform(new Date(this.createActivityFormGroup.value.Date), 'dd-MM-YYY'), 'Asia/Calcutta');
      let filterData = { vessel: this.createActivityFormGroup.value.Vessel, date: this.createActivityFormGroup.value.Date }
      this.chitService.shedulerFilter(filterData).subscribe(response => {
        console.log("response", response);
        var data = response;
        if (data && data.length != 0) {
          // var emailData = this.usersDetails.filter(element => element.email == data[0].qhm || element.email == data[0].ursfo || element.email == data[0].onSiteContact || element.email == data[0].ood_email)
          // if (emailData.length > 0) {
          //   emailData.forEach(elem => {
          //     userData.push(elem)
          //   })
          // }
          userData = data;
          //  })
          console.log(userData)
          if (userData.length > 0) {
            userData.forEach(ele => {
              var filterUserData = this.usersDetails.filter(element => element.email == ele.userId);
              var loginUserdata = {
                "fName": filterUserData[0]['First Name'],
                "lName": filterUserData[0]['Last Name'],
                "email": filterUserData[0].email,
                'status': 'inProgress',
                'Role': ele.role,
                'First Name': filterUserData[0]['First Name']
              }
              this.userList.push(loginUserdata);
            })
          }
        }
        this.userList.forEach((ele, idx) => {
          if (ele.email == this.userInfoDetails[0].email) {
            this.userList.splice(idx, 1)
          }
        })
        this.userDataDetails()
      })
    }
  }
  // onSelectActivity(e){
  //   if (e.value != 'man-aloft') {
  //     this.createActivityFormGroup.value.manAloftLocation = '';
  //   }
  // }
  filterLocation(locs, f) {
    if (f === 'antenna rotating') {
      return locs.filter(loc => this.radhazVisServ.radhaz_comps[loc].rotating)
    } else if (f === 'antenna radiating') {
      return locs.filter(loc => this.radhazVisServ.radhaz_comps[loc].antenna)
    }
    return locs
  }

  
  // *** CONTRACTOR FORM***

  // ***GET CONTRACTOR LIST***/
  getVisitorList(){
    this.chitService.getVisitorData().subscribe((res) => {
      console.log('visitorList', res);
      this.visitorList = res;
    }, error => {
      console.error('There was an error!');
    });
  }

  getLayoutList() {
    let date = this.chitCreateDate || this.ctrlServ.currentActivityDate;
    this.postFilterData['date'].push(this.datePipe.transform(date, 'yyyy-MM-dd'));
    this.configService.getCollectonlayoutData(this.postFilterData).then((response: any) => {
      console.log("getLayoutDatas ", response);
        this.tagServ.layoutData = response['collectionData'];
    });
  }

  getContractorList() {
    this.chitService.getContractorDet().subscribe((res) => {
      console.log('contractorDet', res);
      this.contractorDetails = res;
    }, error => {
      console.error('There was an error!');
    });
  }

  getContractDet(e,contractor) {
    let selectedValue = contractor._id;
    console.log('selectedValue',e);
    let contractorIdx = this.contractorDetails.findIndex(detail => detail._id == selectedValue);
    if (contractorIdx != NO_INDEX && !this.visitorDet.includes(this.contractorDetails[contractorIdx])) {
      this.visitorDet.push(this.contractorDetails[contractorIdx]);
      this.visitorIds.push(selectedValue);
    }else{
      if(!e.source.selected){
        this.visitorDet.pop(this.contractorDetails[contractorIdx]);
        this.visitorIds.pop(selectedValue);
      }
    }
  }

 

  openContractorForm(topping){
    this.contractorForm = true;
    topping.close();
  }

  createContractor() {
    console.log('contractor', this.contractorFormGroup);
    if (this.contractorFormGroup.status == 'VALID') {
      delete this.contractorFormGroup.value['_id'];
      this.chitService.addContractor(this.contractorFormGroup.value).subscribe((res) => {
        this.contractorForm = false;
        this.getContractorList();
      }, error => {
        console.error('There was an error!');
      });
    }
  }

  CreateVisitor() {
    if (this.listofvisitor.length != 0) {
      var difference = this.visitorIds.filter(visitorId => this.listofvisitor.indexOf(visitorId) === -1);
      let visitorDet = this.visitorDet.filter(det => difference.includes(det._id));
      visitorDet.forEach(visitor => {
        visitor['Start'] = this.createActivityFormGroup.value['Start'];
        visitor['End'] = this.createActivityFormGroup.value['End'];
        visitor["tagShapeId"] = this.getUIDData();
        visitor['Date'] = this.datePipe.transform(this.createActivityFormGroup.value['Date'], 'MMM,dd,YYY, h:mm a');
        visitor['description'] = this.createActivityFormGroup.value['purpose'] || ""
      })
      this.pushVisitor(visitorDet);
    } else {
      this.visitorDet.forEach(visitor => {
        visitor['Start'] = this.createActivityFormGroup.value['Start'];
        visitor['End'] = this.createActivityFormGroup.value['End'];
        visitor["tagShapeId"] = this.getUIDData();
        visitor['Date'] = this.datePipe.transform(this.createActivityFormGroup.value['Date'], 'MMM,dd,YYY');
        visitor['description'] = this.createActivityFormGroup.value['purpose'] || ""
      })
      this.pushVisitor(this.visitorDet);
    }
  }

  pushVisitor(visitorDet) {
    this.filterVisitior = visitorDet;
    this.startPushFilterData();
  }

  startPushFilterData(){
    if(this.filterVisitior.length > this.visitorIdx){
      this.visitor = this.filterVisitior[this.visitorIdx];
      let visitor = this.visitor;
      var filterdataDate = this.visitorList.filter(ele => ele.Date == this.datePipe.transform(this.chitCreateDate, 'MMM,dd,YYY'))
      if(filterdataDate.length != 0){
        this.filterData = filterdataDate.filter(elem => elem.email == visitor.emailID || elem.mobile == visitor.mobileNo);
        if(this.filterData.length != 0){
          this.DataIdx = 0;
          this.startFilterCheck();
        }else{
          this.objData.push(this.visitor);
          this.visitorIdx = this.visitorIdx + 1;
          this.startPushFilterData();
        }
      }else{
        this.objData.push(this.visitor);
        this.visitorIdx = this.visitorIdx + 1;
        this.startPushFilterData();
      }
    }else{
      this.visitorIdx = 0;
      this.pushVisitorManyData();
    }

  }

  startFilterCheck(){
    if (this.filterData.length > this.DataIdx) {
      let filterdata = this.filterData[this.DataIdx];
      if ((filterdata.Start >  this.visitor.Start && filterdata.Start >  this.visitor.End) ||
        (filterdata.End <  this.visitor.Start && filterdata.End <  this.visitor.End)
      ) {
        this.filterCheck.push(true);
        this.DataIdx = this.DataIdx + 1;
        this.startFilterCheck();
      } else {
        this.filterCheck.push(false)
        this.DataIdx = this.DataIdx + 1;
        this.startFilterCheck();
      }
    }else{
      this.DataIdx = 0;
      if(!this.filterCheck.includes(false)){
        this.objData.push(this.visitor);
      }
      this.visitorIdx = this.visitorIdx + 1;
      this.startPushFilterData();
    }
  }

  pushVisitorManyData(){
    if(this.objData.length != 0){
      let pushData = {
        data: this.objData
      }
      console.log('visitorDet',pushData)
      this.chitService.addVistiorManyData(pushData).subscribe(result => {
        console.log('resultMany', result);
        this.visitorInformation = result.ops;
        let selectedTagItem = this.tagServ.layoutData.find(layout => layout.layoutName == "Visitor");
        if (selectedTagItem) {
          this.broadcastInfo({ src: 'stepper-form', event: 'createMultipleVisitor', visitorInformation: this.visitorInformation, "chitCreateDate": this.datePipe.transform(this.createActivityFormGroup.value['Date'], 'MMM,dd,YYY'), formValue: this.createActivityFormGroup.value, selectedTagItem: selectedTagItem });
        }
      })
    }
  }

  getUIDData() {
    return Math.random().toString(36).substr(2, 9);
  }


  cancelForm(){
    this.contractorForm = false;
  }

  convertTZ(date, tzString) { 
    let d = new Date((typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', { timeZone: tzString, }));
    d.setHours(0, 0, 0);
    return d;
  }
  anotherActivityCollied = false;
  anotherActivityColliedMsg: any;
  filterNotesDataDetails = [];
  radiateCheckCollection(){
    if (this.createActivityFormGroup.value.activity == 'radiating') {
      this.radhazVisServ.isRadiating = true;
      let newActivityDate = this.convertTZ(this.createActivityFormGroup.value.Date, 'Asia/Calcutta');
      this.postFilterData.venue = this.loadedPort;
      this.postFilterData['date'] = [];
      this.postFilterData['date'].push(this.datePipe.transform(newActivityDate, 'yyyy-MM-dd'));   
      this.configService.getCollectonlayoutData(this.postFilterData).then((response: any) => {
        console.log("getLayoutDatas ", response.notesData);
        var layoutMasterData =[];
        var notesData = response.notesData;
        layoutMasterData = response['collectionData'];
        var filterNotesData = []
        
        var props;
        var activity = this.createActivityFormGroup.value;
        // typeCollection
        notesData.forEach(el => {
          if (el.typeCollection == 'Lockout/Tagout' || el.typeCollection == 'Flex' || el.typeCollection == 'Chits' || el.typeCollection == 'Rounds' || el.typeCollection == 'Visitor') {
            let newChitDate = this.convertTZ(el.startDate, 'Asia/Calcutta');

            let activityDate = this.datePipe.transform(newActivityDate, 'yyyy-MM-dd');
            let chitDate = this.datePipe.transform(newChitDate, 'yyyy-MM-dd');

            let s = activity.Start;
            let e =  activity.End;
            let cs =  el.startTime;
            let ce =  el.endTime;
            if (((s > cs && s > ce) || (e < cs && e < ce))) {
              filterNotesData.push(el);
            }
          }
        });
        this.filterNotesDataDetails = filterNotesData;
        if (filterNotesData.length > 0) {
          this.sidepanelvirtualList = [];
          this.layoutJson = layoutMasterData;
          this.sidepanelLayoutData = layoutMasterData;
          var notesDataList =[];
          notesDataList = response.notesData
          this.virtualOrderList(notesDataList);
          this.defalutLoadcollectionObj();
        }
        else{
          this.radiateCheckShip();
        }
      }).catch((error) => {
        console.log("error ", error)
      });
    }else 
    if(this.prefixActivity == this.createActivityFormGroup.value.activity && this.prefixLocation == this.createActivityFormGroup.value.emitterLocation || this.prefixActivity == this.createActivityFormGroup.value.activity && this.prefixLocation == this.createActivityFormGroup.value.manAloftLocation){
      this.CreateChitActivity()
    }else{
      this.radiateCheckShip();
    }

  }
  anotherActivityColliedCancel(){
    this.chitWindow();
    delete this.anotherActivityColliedMsg;
    this.anotherActivityCollied = false;
  }
  anotherActivityColliedContinue(){
    this.selfColliedRequestApprovalStatus = true;
    var chitData =[{'typeCollectionId': '', 'typeCollection': 'Chits','purpose':this.createActivityFormGroup.get('purpose').value ,"tagLocation": this.tagLocation}]
    this.broadcastInfo({ src: 'chit', event: 'chitCreate', data: chitData, seletedCollection:this.selectedTagItem, chit: this.createActivityFormGroup.value,filterDate:this.chitCreateDate  });
    delete this.anotherActivityColliedMsg;
    this.anotherActivityCollied = false;
  }
  layoutJson: any = [];
  sidepanelLayoutData: any = [];
  sidepanelvirtualList: any = [];
  defalutLoadcollectionObj(){
    this.tagServ.messIdDetails = [];
    if(this.layoutJson.length>0){
      this.layoutJson.forEach(e=>{
        if(this.sidepanelvirtualList[e._id]){
          this.sidepanelvirtualList[e._id].forEach(element => {
            const OBJLEN = 0;
            let idx = this.sidepanelLayoutData.findIndex(e => e['_id'] == element.layoutId);
            if (idx > -1) {
              var sidepanelData = this.sidepanelLayoutData[idx];
              let tagPoint = sidepanelData['tagPoints'].find(el => el.tagShapeId === element.tagShapeId);
              if (tagPoint) {
                let markerView = sidepanelData.Marker.filter(marker => marker.markerId == tagPoint.markerId);
                if (this.configService.appMode == 'mepm' || this.configService.appMode == 'babcock' && markerView.length != OBJLEN) {
                  this.drawService.applyCutMarkersFromData(markerView);
                }
                if ((tagPoint.position && (tagPoint.position['x'] !== 0 || tagPoint.position['y'] !== 0 || tagPoint.position['z'] !== 0))) {
                  this.tagServ.createMatTagObjectPoint(tagPoint, undefined);
                  this.tagServ.showHideTag(tagPoint, false) 
                } 
              }
            }
            
          });
        }
      })
      this.radiateCheckShip();
    }
  }
  selectedGroup = 'layoutName';
  collectionCategory: any;
  virtualOrderList(tagList) {
    this.sidepanelvirtualList = [];
    if (this.selectedGroup == 'layoutName') {
      tagList.forEach(ele => {
        if (this.collectionCategory) {
          if (ele.typeCollection == this.collectionCategory) {
            if (this.sidepanelvirtualList[ele.layoutId]) {
              this.sidepanelvirtualList[ele.layoutId].push(ele);
            } else {
              this.sidepanelvirtualList[ele.layoutId] = [];
              this.sidepanelvirtualList[ele.layoutId].push(ele);
            }
          } else {
            if (this.sidepanelvirtualList[ele.layoutId]) {
              this.sidepanelvirtualList[ele.layoutId].push(ele);
            } else {
              this.sidepanelvirtualList[ele.layoutId] = [];
              this.sidepanelvirtualList[ele.layoutId].push(ele);
            }
          }
        } else {
          if (this.sidepanelvirtualList[ele.layoutId]) {
            this.sidepanelvirtualList[ele.layoutId].push(ele);
          } else {
            this.sidepanelvirtualList[ele.layoutId] = [];
            this.sidepanelvirtualList[ele.layoutId].push(ele);
          }
        }
      })
    }

    console.log("virtualOrderList", this.sidepanelvirtualList);
  }
}

