import { NgModule } from '@angular/core';
import { DatauxviewComponent } from './datauxview.component';
import { JsonService } from './json.service';


@NgModule({
  declarations: [DatauxviewComponent],
  imports: [
 
  ],
  providers: [JsonService],
  exports: [DatauxviewComponent]
})
export class DatauxviewModule { }
