import { Component, OnInit, Output, EventEmitter, Input, AfterViewInit, HostListener } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CommunicationService } from "../services/communication.service";
import { ControllerService } from '../services/controller.service';
import { TagService } from '../services/tag.service';
import { DateHighlightService } from "../services/date-highlight.service";
import { ReportService } from '../services/report.service';
import moment from 'moment';
import { of } from 'rxjs';
import { LanguageService } from '../services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { ChitService } from '../services/chit.service';
import { ConfigService } from '../services/config.service';
import { RadhazVisService } from "../services/radhaz.service";

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  providers: [DatePipe]
})

export class SliderComponent implements OnInit {
  @Input() planType: any;
  @Input() loadedPort: any = 'cfbhalifax';
  @Input() timeProgress: any = false;
  navyFilterToggle: any;
  @Input() set navyFilterState(v: any) {
    if (v) {
      this.navyFilterToggle = v;
      this.ctrlServ.commandingOfficer = v;
    }
  }
  navyFilterShipData: any;
  @Input() set navyFilterShip(v: any) {
    this.navyFilterShipData = v;
  }
  @Input() set hideUnselectedState(v: any) {
    this.hideUnselected = v;
    this.showSelectedShip(this.currentDayShipList);
  }
  @Output() updateObjectPosition: EventEmitter<any> = new EventEmitter<any>();
  @Output() hideAllShips: EventEmitter<any> = new EventEmitter<any>();
  @Output() showAllShips: EventEmitter<any> = new EventEmitter<any>();
  @Output() openPublishDialog: EventEmitter<any> = new EventEmitter<any>();
  @Output() maintainErrorToggle: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateCurrentDayShip: EventEmitter<any> = new EventEmitter<any>();

  shipDate: any = 1;
  shipTime: any = 0;
  timeMaxvalue: any = 24;
  numberOfMinutes: any = 1440;
  hideUnselected: any = false;
  currentDayShipList: any = [];
  range: any = 1;
  monthRange = 27;
  shipObjects: any = [];
  broadcastSubs: any;
  date = new Date();
  sliderDate = new Date();
  startDate: any;
  endDate: any;
  activityEndDate: any;
  AnimationTimer: any;
  currentShipData: any = [];
  currentRangeShipData: any = []
  sliderSeconds: any = 0;
  sliderHours: any = 0;
  timerSpeed: any = '300';
  animationDate: any = '';
  animationState: boolean = false;
  activeShipList: any = '';
  jettyMissingShipObjects: any = [];
  jettyMissingShipList: any = [];
  profileID: any;
  loginUserShip: any;
  loginUserStartDate: any;

  constructor(private radhazVisServ:RadhazVisService, private configService: ConfigService, private chitService: ChitService, private reportServ: ReportService, private tagServ: TagService, private ctrlServ: ControllerService, private dateService: DateHighlightService, private communicationServ: CommunicationService, private datePipe: DatePipe, private translate: TranslateService, private language: LanguageService) {
    this.language.languageChange.subscribe(props => {
      // console.log("language", props);
      this.translate.use(props);
    })
  }

  ngOnInit() {
    this.getUserActivityInfo();
    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
        if(data.src==="dockyard"){
          if(data.event==="dockyard-profile-iframe"){
            this.profileID=data['data']['email']
          }
        }
        if (data.src === "main") {
          if (data.event === 'updateshipmetadata') {
            console.log("setDefultDate",this.sliderDate);
            this.shipObjects = data['data']['shipObjects'];
            this.jettyMissingShipObjects = data['data']['jettyMissingShipObjects'];
            if (!data['key']) {
              if (this.navyFilterToggle === 'commanding') {
                if (this.loginUserStartDate) {
                  this.sliderDate = new Date(this.loginUserStartDate);
                }
              } else {
                this.setDefultDate();
              }
            }
            this.dateHighlight();
            this.filterDateRange();
          }
          if (data.event === 'update_time_view') {
            this.filterTimeRange();
          }
          if (data.event === 'reset_preview') {
            this.hideAllShips.emit();
            this.filterDateRange();
          }
          if (data.event == 'navyfilterState') {
            this.navyFilterToggle = data.key;
            this.navyFilterShipData = data.data;
          }
          if (data.event === 'changeDateRange') {
            let range = data['data'];
            if (range) {
              this.monthRange = range;
              this.filterDateRange();
            }
          }
          if (data.event === 'updateSliderDate') {
            let startDate = data['data'];
            if (startDate) {
              startDate = new Date(startDate);
              if (startDate.getTime() !== this.sliderDate.getTime()) {
                this.sliderDate = startDate;
              }
            }
          }
          if (data.event == 'navyfilterDate') {
            // var startDate = data['data'];
            var startDate = this.loginUserStartDate;
            this.startDate = this.loginUserStartDate;
            if (startDate) {
              startDate = new Date(startDate);
              if (startDate.getTime() !== this.sliderDate.getTime()) {
                this.sliderDate = startDate;
              }
              this.dateHighlight();
              this.filterDateRange();
            }
          }
          if (data.event === 'navyProfileInfo') {
            var profile = data['data'];
            this.profileID = profile.email;
          }
        }
        if (data.src === "sidepanel") {
          if (data.event === 'selectedship') {
            this.activeShipList = data['data'];
            this.changeSlider(this.activeShipList);
          }
          if (data.event === 'filtered_data') {
            let obj = data['data'];
            if (data.key === 'clear_filter') {
              this.currentRangeShipData = this.currentShipData;
            } else {
              if (obj) {
                // let startDate = obj['startDate'];
                this.currentRangeShipData = obj['data'];
                // this.showFilteredShips(startDate);
              }
            }
          }
          if (data.event === 'updateDateRange') {
            let range = data['data'];
            if (range) {
              this.shipDate = 1;
              this.monthRange = range;
              this.formatLabelDate(range);
              this.filterDateRange();
            }
          }
        }
        if (data.src === "chit") {
          if (data.event === 'chitRadiateCheck') {
            this.navyFilterToggle = '';
            this.hideAllShips.emit();
            this.filterDateRange();
          }
          if (data.event === 'commandingOfficer') {
            let navy = data['data'];
            let date = data['key'];
            if (navy =='commanding') {
              // this.getUserActivityInfo();
              this.sliderDate = date;
              this.hideAllShips.emit();
              this.shipDate = 1;
              this.range = Number(this.shipDate);

              this.animationDate =  this.datePipe.transform(date, 'dd-MMM-yy, HH:mm');
              this.loginUserStartDate = date;
              this.setCommentingOfficerData();
              this.navyFilterToggle = navy;
              this.filterDateRange();
            }

          }
        }
      });
  }

  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }

  /* * * * *
  * method for get all day activity for hightlight Date
  * * * * * */
  dateHighlight() {
    let highlightDateList = [];
    this.shipObjects.forEach(ship => {
      if (ship['activity'] !== '') {
        let startDate = new Date(ship.startdate);
        let endDate = new Date(ship.enddate);
        var diffInMs = endDate.getTime() - startDate.getTime();
        var diffInDays = diffInMs / (1000 * 3600 * 24);
        if (diffInDays > 0) {
          for (let i = 0; i < diffInDays + 1; i++) {
            let date = new Date(startDate);
            date.setDate(startDate.getDate() + i);
            highlightDateList.push(date);
          }
        } else {
          highlightDateList.push(startDate);
        }
      }
    });
    this.dateService.setHighlightDate(highlightDateList);
  }

  commentingOfficerShips: any = [];
  userdata: any;
  getUserActivityInfo() {
    this.configService.getProjectConfig().then((project_config: any) => {
      this.chitService.getOodData().subscribe(response => {
        // console.log("userActivityInfo", response);
        // this.userdata = response;
        this.commentingOfficerShips = response.filter(t => t.ood_email == this.profileID);
        this.userdata = this.commentingOfficerShips;
        this.sortByShiplistdata('start_date')
        if (this.commentingOfficerShips.length > 0) {
          this.loginUserShip = this.commentingOfficerShips[0].vessel;
          console.log("user ship detais : ", this.commentingOfficerShips[0])
          this.loginUserStartDate = this.commentingOfficerShips[0].start_date;
          if (this.navyFilterToggle === 'commanding') {
            this.sliderDate = new Date(this.loginUserStartDate);
          }
          if (this.loginUserShip) {
            this.broadcastInfo({ src: 'slider', event: 'commandingShip', data: this.loginUserShip, date: this.loginUserStartDate, key: "" });
          }
        }
      })
    })
  }
  sortItem: any = [];
  sortByShiplistdata(e) {
    this.sortItem = e;
    let direction = 1;
    this.commentingOfficerShips.sort((a, b) => {
      if (e == 'startdate' || e == 'enddate') {
        let aDate = new Date(a[e]);
        let bDate = new Date(b[e])
        if (aDate < bDate) {
          return -1 * direction;
        }
        else if (aDate > bDate) {
          return 1 * direction;
        }
        else {
          return 0;
        }
      } else {
        if (a[e] < b[e]) {
          return -1 * direction;
        }
        else if (a[e] > b[e]) {
          return 1 * direction;
        }
        else {
          return 0;
        }
      }
    });
  }

  setCommentingOfficerData() {
    if (this.commentingOfficerShips.length > 0) {
      const ship = this.commentingOfficerShips.find(el => new Date(el.start_date).getTime() === new Date(this.animationDate).getTime());
      if (ship) {
        this.loginUserShip = ship.vessel;
        this.loginUserStartDate = ship.start_date;
        this.broadcastInfo({ src: 'slider', event: 'commandingShip', data: this.loginUserShip, date: this.loginUserStartDate, key: "" });
      } else {
        this.loginUserShip = 'no-ship';
        this.loginUserStartDate = new Date(this.animationDate);
        this.broadcastInfo({ src: 'slider', event: 'commandingShip', data: this.loginUserShip, date: this.loginUserStartDate, key: "" });
      }
    } else {
      this.loginUserShip = 'no-ship';
      this.loginUserStartDate = new Date(this.animationDate);
      this.broadcastInfo({ src: 'slider', event: 'commandingShip', data: this.loginUserShip, date: this.loginUserStartDate, key: "" });
    }
  }

  /* * * * *
  * method for set first date in metadata in slider
  * * * * * */
  setDefultDate() {
    if (this.planType.toLowerCase() === 'demo' || this.loadedPort.toLowerCase() === 'cfbesquimalt') {
      if (this.loadedPort.toLowerCase() === 'cfbesquimalt') {
        if (this.planType.toLowerCase() === 'demo') {
          for (let ship of this.shipObjects) {
            if (ship.startdate) {
              this.sliderDate = new Date(ship.startdate);
              break;
            }
          }
        } else {
          this.sliderDate = new Date('01-Mar-21');
        }
      } else {
        this.sliderDate = new Date('01-May-21');
      }
      // for (let ship of this.shipObjects) {
      //   if (ship.startdate) {
      //     this.sliderDate = new Date(ship.startdate);
      //     break;
      //   }a
      // }
    } else {
      if(this.loadedPort.toLowerCase() === 'cfbhalifax'){
        this.sliderDate = new Date('08-SEP-2020');
      }else{
        this.sliderDate = new Date();
      }

    }
  }

  /* * * * *
  * method for set selected ship time to slider
  * * * * * */
  changeSlider(ship) {
    if (ship) {
      // console.log("ship", ship);
      if (ship.startdate) {
        if (new Date(this.startDate) <= new Date(ship.startdate)) {
          if (ship['status'] === 'scheduled') {
            // calculate hours based on date difference
            let startDate = new Date(this.startDate);
            let endDate = new Date(ship.startdate);
            var diffInMins = endDate.getTime() - startDate.getTime();
            var diffInDays = diffInMins / (1000 * 3600 * 24);
            this.onChangeDateRange(diffInDays + 1);
          } else if (ship['status'] === 'completed') {
            // calculate hours based on date difference
            let startDate = new Date(this.startDate);
            let endDate = new Date(ship.enddate);
            var diffInMins = endDate.getTime() - startDate.getTime();
            var diffInDays = diffInMins / (1000 * 3600 * 24);
            this.onChangeDateRange(diffInDays + 1);
          } else {
            this.filterTimeRange();
          }
        }
        if (new Date(this.startDate) > new Date(ship.startdate)) {
          if (ship['status'] === 'completed') {
            // calculate hours based on date difference
            let startDate = new Date(this.startDate);
            let endDate = new Date(ship.enddate);
            var diffInMins = endDate.getTime() - startDate.getTime();
            var diffInDays = diffInMins / (1000 * 3600 * 24);
            this.onChangeDateRange(diffInDays + 1);
          } else {
            this.filterTimeRange();
          }
        }
      } else {
        this.filterTimeRange();
      }
    } else {
      this.filterTimeRange();
    }
  }


  /* * * * *
  * get start date
  * * * * * */
  getRangeDate(range) {
    let date = new Date(this.startDate);
    date.setDate(this.sliderDate.getDate() + range);
    let rangeDate = this.datePipe.transform(date, 'MM/dd/yyyy');
    return rangeDate;
  }

  /* * * * *
  * formate date
  * * * * * */
  formateDate(date) {
    let startDate = this.datePipe.transform(date, 'MM/dd/yyyy');
    return startDate;
  }

  /* * * * *
  * get start and end date dased on slider
  * * * * * */
  getStartEndDate() {
    // let range = ((0 < this.range) ? this.range - 1 : this.range);
    this.startDate = this.formateDate(this.sliderDate);
    this.endDate = this.getRangeDate(this.monthRange);
  }

  getStartEndDates() {
    // let range = ((0 < this.range) ? this.range - 1 : this.range);
    this.startDate = this.formateDate(this.loginUserStartDate);
    this.endDate = this.getRangeDate(this.monthRange);
  }

  /* * * * *
  * method for selected range datetime shown
  * * * * * */
  setEndDate() {
    this.activityEndDate = this.datePipe.transform(this.endDate + ', ' + '23:59', 'dd-MMM-yy, HH:mm');
  }

  /* * * * *
  * method for set Activity status
  * * * * * */
  setActivityStatus() {
    this.currentShipData.forEach((ship, idx) => {
      if (new Date(this.endDate) >= new Date(ship.startdate) && new Date(this.endDate) <= new Date(ship.enddate)) {
        if (new Date(this.endDate) > new Date(ship.startdate)) {
          this.currentShipData[idx]['status'] = 'inprogress';
        } else {
          this.currentShipData[idx]['status'] = 'scheduled';
        }
      }
    });
    return this.currentShipData;
  }

  /* * * * *
  * method for get range data between start and end date
  * * * * * */
  getRangeData() {
    let objConfig;
    let shipObj = [];
    let jettyLocation = [];
    this.currentShipData = [];
    this.jettyMissingShipList = [];
    let startDate = moment(this.startDate);
    // let range = ((0 < this.range) ? this.range - 1 : this.range);
    let range = this.monthRange;

    for (let i = 0; i < range + 1; i++) {
      let shipDate = moment(startDate).add(i, 'days');
      this.shipObjects.forEach(ship => {
        if (shipDate['_d'] >= moment(ship.startdate)['_d'] && shipDate['_d'] <= moment(ship.enddate)['_d']) {
          objConfig = this.ctrlServ.getObjectConfig();
          objConfig['name'] = ship['name'];
          objConfig['model'] = ship['model'];
          objConfig['place'] = ship['place'];
          objConfig['location'] = ship['location'];
          objConfig['activity'] = ship['activity'];
          objConfig['class'] = ship['class'];
          ship['status'] = 'scheduled';
          const found = this.currentShipData.some(el => el.name === ship.name);
          if (!found) {
            this.currentShipData.push(ship);
            if (ship['ship_status'] === 'show') {
              shipObj.push(objConfig);
            }
          }
        }
      });

      // for missing jetty ship objects
      this.jettyMissingShipObjects.forEach(obj => {
        if ((obj['startdate'] != '') && (obj['enddate'] != '')) {
          if (shipDate['_d'] >= moment(obj.startdate)['_d'] && shipDate['_d'] <= moment(obj.enddate)['_d']) {
            const found = jettyLocation.some(el => el === obj.location);
            if (!found) {
              jettyLocation.push(obj.location);
              this.jettyMissingShipList.push(obj);
            }
          }
        }
      });
    }
    return shipObj;
  }

  chitActivityList = [];
  curUserChitList = []
  getChitData(shipName, shipObj) {
    var filterCurrShip = [];
    var filterShipObj = [];

    if (shipName && shipName != 'no-ship') {
      this.currentShipData.forEach(ship => {
        if (ship.ship) {
          // console.log('chit', ship.ship, shipName)
          if (ship.ship == shipName)
            if (ship['activity']) {
              {
                filterCurrShip.push(ship);
              }
            }
        }
      });

      shipObj.forEach(element => {
        let idx = filterCurrShip.findIndex(e => e.name == element.name)
        if (idx > -1) {
          if (element['activity']) {
            filterShipObj.push(element);
          }
        }
      });

      // this.currentShipData = filterCurrShip;
      // this.currentRangeShipData = filterCurrShip;
      var data = { "currentShipData": filterCurrShip, "shipObj": filterShipObj, 'jettyMissingShipList': this.jettyMissingShipList, "isReport": false, 'date': this.loginUserStartDate };

      if (filterCurrShip.length == 0) {
        this.broadcastInfo({ src: 'slider', event: 'unkonwn_activity', data: this.navyFilterShipData, key: "" });
      }
    } else {
      data = { "currentShipData": filterCurrShip, "shipObj": filterShipObj, 'jettyMissingShipList': this.jettyMissingShipList, "isReport": false, 'date': this.loginUserStartDate };
    }
    this.updateObjectPosition.emit(data);
  }

  /* * * * *
  * filter ships based on date range
  * //RAJDEC08: filterObjects should be moved to a separate service and component named assetHistory
  * * * * * */
  _currentShipObj: any = [];
  filterDateRange() {
    this.reportServ.setSliderDate(this.sliderDate);
    this.getStartEndDate();
    this.setEndDate();
    let shipObj = this.getRangeData();
    this._currentShipObj = shipObj;
    this.currentShipData = this.setActivityStatus();
    let obj = { "startDate": new Date(this.startDate), "endDate": new Date(this.endDate) };
    // let obj = { "startDate": new Date('03/01/2021'), "endDate": new Date('03/28/2021') };
    this.broadcastInfo({ src: 'slider', event: 'update_date_range', data: obj, key: "" });
    var data = { "currentShipData": this.currentShipData, "shipObj": shipObj, 'jettyMissingShipList': this.jettyMissingShipList, "isReport": false };
    this.currentRangeShipData = this.currentShipData;
    this.broadcastInfo({ src: 'slider', event: 'commandingShip', data: '', date: '', key: "" });
    if (this.navyFilterToggle == 'commanding') {
      var filterCurrShip = [];
      var filterShipObj = [];

      this.getStartEndDates();
      // let obj = { "startDate": new Date(this.loginUserStartDate), "endDate": new Date(this.endDate) };
      // this.broadcastInfo({ src: 'slider', event: 'update_date_range', data: obj, key: "" });

      // this.sliderDate = new Date(this.loginUserStartDate);
      this.hideAllShips.emit();
      this.shipDate = 1;
      this.range = Number(this.shipDate);
      this.broadcastInfo({ src: 'slider', event: 'hidetag', data: "", key: "" });

      let shipName = this.loginUserShip;
      this.broadcastInfo({ src: 'slider', event: 'commandingShip', data: this.loginUserShip, date: this.loginUserStartDate, key: "" });

      this.getChitData(shipName, shipObj);
      return;
    }

    if (this.navyFilterToggle == 'ship') {
      var filterCurrShip = [];
      var filterShipObj = [];

      this.currentShipData.forEach(element => {
        if (element.shipname.toLowerCase() == this.navyFilterShipData) {
          filterCurrShip.push(element);
        }
      });

      shipObj.forEach(element => {
        let idx = filterCurrShip.findIndex(e => e.name == element.name)
        if (idx > -1) {
          filterShipObj.push(element);
        }
      });

      this.currentShipData = filterCurrShip;
      this.currentRangeShipData = filterCurrShip;
      data = { "currentShipData": this.currentShipData, "shipObj": filterShipObj, 'jettyMissingShipList': this.jettyMissingShipList, "isReport": false };
      if (filterCurrShip.length == 0) {
        this.broadcastInfo({ src: 'slider', event: 'unkonwn_activity', data: this.navyFilterShipData, key: "" });
      }
    }
    this.updateObjectPosition.emit(data);

    // setTimeout(() => {
    //   this.broadcastInfo({ src: 'slider', event: 'updateShipDetails', data: 'updateShip' });
    // }, 5000);

  }

  /* * * * *
  * date range value
  * * * * * */
  onChangeDateRange(value) {
    this.shipDate = value;
    this.range = Number(this.shipDate);
    if (this.range === 0) {
      this.timeMaxvalue = 24;
      this.numberOfMinutes = this.timeMaxvalue * 60;
    } else {
      this.timeMaxvalue = this.range * 24;
      this.numberOfMinutes = this.timeMaxvalue * 60;
    }
    // this.hideAllShips.emit();
    this.filterTimeRange();
    if (this.navyFilterToggle === 'commanding') {
      this.hideAllShips.emit();
      this.setCommentingOfficerData();
      this.getChitData(this.loginUserShip, this._currentShipObj);
    }
    // this.broadcastInfo({ src: 'slider', event: 'hidetag', data: "", key: "" });
  }

  /* * * * *
  * load to current day ship
  * * * * * */
  loadCurrentDayShip() {
    let range = 1;
    // this.sliderDate = new Date();
    this.onChangeDateRange(range);
  }

  /* * * * *
  * on date change
  * * * * * */
  dateChange(e) {
    this.sliderDate = e.value;
    this.hideAllShips.emit();
    this.shipDate = 1;
    this.range = Number(this.shipDate);
    if (this.navyFilterToggle === 'commanding') {
      this.animationDate = this.sliderDate;
      this.setCommentingOfficerData();
    }
    this.filterDateRange();
    this.broadcastInfo({ src: 'slider', event: 'hidetag', data: "", key: "" });
  }

  /* * * * *
  * Label range
  * * * * * */
  formatLabelDate(value: number) {
    if (value > this.monthRange) {
      return Math.round(value / this.monthRange) + '';
    }
    if (value > 0) {
      this.timeMaxvalue = this.timeMaxvalue * value;
    }
    if (value < 0) {
      this.timeMaxvalue = this.timeMaxvalue * (-1 * value);
    }
    if (value == 0) {
      this.timeMaxvalue = 24;
    }
    return value;
  }

  formatLabelTime(minutes: number | null) {
    if (!minutes) {
      return 0;
    }
    if (minutes < 0) {
      return minutes;
    }
    var hours = Math.floor(minutes / 60);
    var m = minutes % 60;
    var mins = m.toFixed(0);
    return hours + '.' + mins;
  }

  /* * * * *
  * time range value
  * * * * * */
  onTimeChange(event) {
    this.shipTime = event.value;
    if (this.shipTime > -1) {
      var hours = Math.floor(this.shipTime / 60);
      var seconds = this.shipTime * 60;
      this.sliderHours = hours;
      this.sliderSeconds = seconds;
      if (this.timeMaxvalue > this.sliderHours) {
        if (!this.animationState) {
          this.animationState = !this.animationState;
          this.broadcastInfo({ src: 'slider', event: 'hidetag', data: "", key: "" });
          this.hideAllShips.emit();
        }
        this.filterTimeRange();
      } else {
        setTimeout(() => {
          this.resetSliderAnimation();
        });
      }
    } else {
      this.showAllShips.emit();
    }
  }

  timerStart: any;
  timeAction(e) {
    if (e == 'stop') {
      this.resetSliderAnimation();
      return
    }
    this.timerStart = !this.timerStart;
    if (this.timerStart) {
      if (!this.animationState) {
        this.animationState = !this.animationState;
        this.broadcastInfo({ src: 'slider', event: 'hidetag', data: "", key: "" });
        this.hideAllShips.emit();
      }
      this.startTimerAnimation();
    } else {
      cancelAnimationFrame(this.AnimationTimer);
    }
  }

  /* * *
  * reset slider animation
  * * */
  resetSliderAnimation() {
    this.timerStart = !true;
    this.shipTime = 0;
    this.sliderSeconds = 0;
    this.sliderHours = 0;
    this.animationState = false;
    this.filterTimeRange();
    cancelAnimationFrame(this.AnimationTimer);
  }

  /* * *
   * time slider animation start
   * * */
  startTimerAnimation() {
    let animation = (timer) => {
      if (this.timeMaxvalue > this.sliderHours) {
        this.sliderSeconds += Number(this.timerSpeed);
        this.sliderHours = Math.floor(this.sliderSeconds / 60 / 60);
        var Minutes = Math.floor(this.sliderSeconds / 60);
        this.shipTime = Minutes;
        this.filterTimeRange();
        this.AnimationTimer = window.requestAnimationFrame(animation);
      } else {
        this.resetSliderAnimation();
      }
    }
    this.AnimationTimer = window.requestAnimationFrame(animation);
  }

  /* * *
  * set time slider
  * * */
  setAnimationDateTime(date) {
    let minutes = Math.floor(this.sliderSeconds / 60) - (this.sliderHours * 60);
    let Hour = Math.floor((this.sliderSeconds % (60 * 60 * 24)) / (60 * 60));
    let startTime = Hour + ':' + minutes;
    let startDateTime = new Date(date + ', ' + startTime);
    if (this.timeProgress) {
      this.animationDate = this.datePipe.transform(startDateTime, 'dd-MMM-yy, HH:mm');
    } else {
      this.animationDate = this.datePipe.transform(startDateTime, 'dd-MMM-yy');
    }
  }

  /* * *
  * time slider animation
  * * */
  filterTimeRange() {
    // let days = Math.floor(this.sliderSeconds / (60 * 60 * 24))
    // let date = this.getRangeDate(days);
    // let minutes = Math.floor(this.sliderSeconds / 60) - (this.sliderHours * 60);
    // let Hour = Math.floor((this.sliderSeconds % (60 * 60 * 24)) / (60 * 60));
    // let startTime = Hour + ':' + minutes;
    // let startDateTime = new Date(date + ', ' + startTime);
    // this.setAnimationDateTime(date);

    this.hideAllShips.emit();
    let range = ((0 < this.range) ? this.range - 1 : this.range);
    let date = this.getRangeDate(range);
    let startDateTime = new Date(date);
    this.reportServ.setCurrentViewDate(startDateTime);
    this.showFilteredShips(startDateTime);
  }

  /* * *
  * method for filtered ships to show
  * * * */
  dayFilterShips: any = []
  showFilteredShips(startDateTime) {
    this.dayFilterShips = [];
    this.currentDayShipList = [];
    this.animationDate = this.datePipe.transform(startDateTime, 'dd-MMM-yy');
    this.ctrlServ.animationDate = new Date(this.animationDate);
    this.ctrlServ.currentActivityDate = startDateTime;

    this.currentRangeShipData.forEach((ship, idx) => {
      if (ship['ship_status'] === 'show') {
        let allow;
        let shipid = ship['name'];
        let shipStartDateTime = new Date(ship.startdate + ", 00:00:00");
        let shipEndDateTime = new Date(ship.enddate + ", 23:59:00");

        // checking if ship current jetty means allowing
        let location = (ship.location ? ship.location.replace(" ", "").replace("/", "_") : "");
        if (this.tagServ.cfb_externalport) {
          if (this.tagServ.cfb_externalport === 'cfad') {
            if (location === 'NN') {
              allow = true;
            }
          } if (this.tagServ.cfb_externalport === 'shearwater') {
            if (location === 'NA') {
              allow = true;
            }
          }
        } else {
          const found = this.tagServ.cfbexternal_location.some(el => el === location);
          allow = !found;
        }

        if (allow) {
          if ((startDateTime >= shipStartDateTime) && (startDateTime <= shipEndDateTime)) {
            // current day view ship List
            const foundShip = this.currentDayShipList.some(el => el.name === ship.name);
            if (!foundShip) {
              this.currentDayShipList.push(ship);
              // this.ctrlServ.showShip(ship);
            }

            this.currentRangeShipData[idx]['status'] = "inprogress";
            // if activity inprogress checking if already assigned same location means hiding the ship
            const found = this.dayFilterShips.some(el => el.location === ship.location);
            if (!found) {
              this.dayFilterShips.push(ship);
              this.ctrlServ.showShip(ship);
            }
            else {
              let index = this.dayFilterShips.findIndex(el => el.location === ship.location);
              if (index > -1) {
                if (this.dayFilterShips[index]['activity']) {
                  this.ctrlServ.hideShip(ship);
                  this.ctrlServ.showShip(this.dayFilterShips[index]);
                } else {
                  this.ctrlServ.hideShip(this.dayFilterShips[index]);
                  this.dayFilterShips.splice(index, 1);
                  this.dayFilterShips.push(ship);
                  this.ctrlServ.showShip(ship);
                }
              } else {
                this.ctrlServ.hideShip(ship);
              }
            }
          }

          // if activity scheduled
          else if (startDateTime < shipStartDateTime) {
            this.ctrlServ.hideShip(ship);
            this.currentRangeShipData[idx]['status'] = "scheduled";
            if (this.tagServ.selTagElement === shipid) {
              this.broadcastInfo({ src: 'slider', event: 'hidetag', data: "", key: "" });
            }
          }

          // if activity compeleted
          else {
            this.ctrlServ.hideShip(ship);
            this.currentRangeShipData[idx]['status'] = "completed";
            if (this.tagServ.selTagElement === shipid) {
              this.broadcastInfo({ src: 'slider', event: 'hidetag', data: "", key: "" });
            }
          }
        }
        // if not in this jetty ships
        else {
          this.ctrlServ.hideShip(ship);
        }
      }
    });
    this.showSelectedShip(this.currentDayShipList);
    this.maintainErrorToggle.emit();
    this.updateCurrentDayShip.emit(this.currentDayShipList);
    console.log("shipData", this.dayFilterShips)
    console.log("currentShipData", this.currentDayShipList)
    this.broadcastInfo({ src: 'slider', event: 'updatelist', data: this.currentRangeShipData, key: "" });
    this.broadcastInfo({ src: 'slider', event: 'updateListDate', data: this.animationDate, key: "" });
  }

  /* * *
   * method for selected ship show other transparent
   * * * */
  showSelectedShip(currentDayShipList) {
    let shipList = [];
    currentDayShipList.forEach(ship => {
      let shipid = ship.name;
      // this.ctrlServ.showShip(ship);
      if (this.tagServ.selTagElement) {
        if (this.tagServ.selTagElement === shipid) {
          this.ctrlServ.showShip(ship);
        } else {
          const found = shipList.some(el => el.location === ship.location);
          if (!found) {
            shipList.push(ship);
            if (this.hideUnselected) {
              this.ctrlServ.transparentShip(ship);
              // this.ctrlServ.showShip(ship);
            }

            else {
              // if initially loaded without activity ship in location check if same location have acitivty
              let index = this.dayFilterShips.findIndex(el => el.location === ship.location);
              if (index > -1) {
                if (this.dayFilterShips[index]['activity']) {
                  this.ctrlServ.hideShip(ship);
                  this.ctrlServ.showShip(this.dayFilterShips[index]);
                } else {
                  if (ship['activity']) {
                    this.ctrlServ.hideShip(this.dayFilterShips[index]);
                    this.dayFilterShips.splice(index, 1);
                    this.dayFilterShips.push(ship);
                    this.ctrlServ.showShip(ship);
                  } else {
                    this.ctrlServ.hideShip(ship);
                    this.ctrlServ.showShip(this.dayFilterShips[index]);
                  }
                }
              }
            }
          }

          else {
            // if initially loaded without activity ship in location check if same location have acitivty
            let index = shipList.findIndex(el => el.location === ship.location);
            if (index > -1) {
              if (shipList[index]['activity']) {
                this.ctrlServ.hideShip(ship);
                this.ctrlServ.showShip(shipList[index]);
              } else {
                this.ctrlServ.hideShip(shipList[index]);
                shipList.splice(index, 1);
                shipList.push(ship);
                this.ctrlServ.showShip(ship);
              }
            }
          }

        }
      }
    });
  }

  DayChange(e) {
    if (e == 'pre') {
      if (this.shipDate == 1) {
        // script for timeline dynamic expandaple
        // this.monthRange = this.monthRange + 1;
        // this.ctrlServ.selectedDateRange = this.monthRange;
        // this.ctrlServ.defaultDateRange = this.monthRange;

        var d = new Date(this.sliderDate);
        d.setDate(d.getDate() - 1);
        this.sliderDate = new Date(d);
        this.filterDateRange();
        this.onChangeDateRange(this.shipDate);
        this.broadcastInfo({ src: 'slider', event: 'changeDayRange', data: '', key: true });
      } else {
        this.onChangeDateRange(this.shipDate - 1);
      }
    } else if (e == 'next') {
      if (this.monthRange >= this.shipDate) {
        this.onChangeDateRange(this.shipDate + 1);
      } else {
        // script for timeline dynamic expandaple
        // this.monthRange = this.monthRange + 1;
        // this.ctrlServ.selectedDateRange = this.monthRange;
        // this.ctrlServ.defaultDateRange = this.monthRange;
        // this.filterDateRange();
        // this.onChangeDateRange(this.shipDate + 1);
        // this.broadcastInfo({ src: 'slider', event: 'changeDayRange', data: '', key: true });

        var d = new Date(this.sliderDate);
        d.setDate(d.getDate() + 1);
        this.sliderDate = new Date(d);
        this.filterDateRange();
        this.onChangeDateRange(this.shipDate);
        this.broadcastInfo({ src: 'slider', event: 'changeDayRange', data: '', key: true });
      }
    }
  }
  @HostListener('window:mouseup', ['$event'])
  mouseUp(event) {
    if (event.target.tagName == 'MAT-SLIDER') {
      event = event.target
    } else if (event.target.className == "mat-slider-thumb" || event.target.className == "mat-slider-thumb-label" || event.target.className == "mat-slider-ticks") {
      event = event.target.parentNode.parentNode.parentNode;
    } else {
      event = document.getElementById('sliderDay')
    }
    // if (event.tagName == 'MAT-SLIDER') {
    //   var classname1 = 'cdk-focused';
    //   var classname2 = 'cdk-mouse-focused';
    // var reg = new RegExp('(\\s|^)' + classname1 + '(\\s|$)');
    // var reg1 = new RegExp('(\\s|^)' + classname2 + '(\\s|$)');
    // event.className = event.className.replace(reg, ' ');
    // event.className = event.className.replace(reg1, ' ');
    // }
    if (event.className == "mat-slider-thumb" || event.tagName == 'MAT-SLIDER') {
      // if (!event.matches('.cdk-focused')) {
      event.classList.add('cdk-focused');
      event.classList.add('cdk-mouse-focused');
      // }
    }
  }

  @HostListener('window:mousedown', ['$event'])
  mouseDown(event) {
    if (event.target.tagName == 'MAT-SLIDER') {
      event = event.target
    } else if (event.target.className == "mat-slider-thumb" || event.target.className == "mat-slider-thumb-label" || event.target.className == "mat-slider-ticks") {
      event = event.target.parentNode.parentNode.parentNode;
    } else {
      event = document.getElementById('sliderDay')
    }
    if (event.className == "mat-slider-thumb" || event.tagName == 'MAT-SLIDER') {
      if (!event.matches('.cdk-focused')) {
        event.classList.add('cdk-focused');
        event.classList.add('cdk-mouse-focused');
      }
    }
  }
}
