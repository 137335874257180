import { HostListener, Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { CommunicationService } from "../services/communication.service";
import { ControllerService } from '../services/controller.service';
import { TagService } from '../services/tag.service';
import moment from 'moment';

declare var window;
@Injectable({
  providedIn: 'root'
})
export class ReportService {
  dfx: any;
  datauxview: any;
  shipObjects: any = [];
  sliderDate = new Date();
  startDate: any;
  endDate: any;
  selectedDate: any;
  currentShipData: any = [];
  currentMonthShipData: any = [];
  dayShipList: any = [];
  range: any = 7;
  reportRange: any = 1;
  dayIdx: any = 1;
  reportPageData: any = [];
  pageIdx: any = 0;
  pdf: any;
  jettyMissingShipObjects: any = [];
  jettyMissingShipList: any = [];
  lastRefershedDate: any = '';
  reportDate: any;
  currentViewDate = new Date();
  screenWidth: any = window.innerWidth;

  constructor(private tagServ: TagService,private communicationServ: CommunicationService, private ctrlServ: ControllerService) {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    console.log(event.target.innerWidth);
    this.screenWidth = event.target.innerWidth;
  }

  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }

  /* * * *
  * init dataux instance
  * * * */
  init(_datauxview) {
    this.datauxview = _datauxview;
    this.dfx = _datauxview.getDatascape();
  }

  /* * * * *
  * config for last uploaded timestamp
  * * * * * */
  setConfigLastUploadedFile(file) {
    if (file) {
      let timestamp = file.split('.')[0];
      this.lastRefershedDate = new Date(Number(timestamp));
    }
  }

  /* * * * *
  * config missing jetty ship objects
  * * * * * */
  setMissingJettyShipList(shipList) {
    this.jettyMissingShipObjects = shipList;
  }

  /* * * * *
  * config shipobjects
  * * * * * */
  setShipObjects(objects) {
    this.shipObjects = objects;
  }

  /* * * * *
  * config slider date
  * * * * * */
  setSliderDate(date) {
    this.sliderDate = date;
  }

  /* * * * *
  * config slider date
  * * * * * */
  setCurrentViewDate(date) {
    this.currentViewDate = date;
  }

  /* * * * *
  * get start date
  * * * * * */
  getRangeDate(range) {
    let date = new Date(this.startDate);
    date.setDate(this.sliderDate.getDate() + range);
    return date;
  }

  /* * * * *
  * get start and end date dased on slider
  * * * * * */
  getStartEndDate() {
    let range = ((0 < this.range) ? this.range - 1 : this.range);
    this.startDate = this.sliderDate;
    this.endDate = this.getRangeDate(range);
  }

  /* * * * *
  * method for get range data between start and end date
  * * * * * */
  getRangeData(month = false) {
    let objConfig;
    let shipObj = [];
    let startDate = moment(this.startDate);
    let range = ((0 < this.range) ? this.range - 1 : this.range);

    if (!month) {
      this.currentShipData = [];
    } else {
      this.currentMonthShipData = [];
    }

    for (let i = 0; i < range + 1; i++) {
      let shipDate = moment(startDate).add(i, 'days');
      this.shipObjects.forEach(ship => {
        if (shipDate['_d'] >= moment(ship.startdate)['_d'] && shipDate['_d'] <= moment(ship.enddate)['_d']) {
          objConfig = this.ctrlServ.getObjectConfig();
          objConfig['name'] = ship['name'];
          objConfig['model'] = ship['model'];
          objConfig['place'] = ship['place'];
          objConfig['location'] = ship['location'];
          objConfig['activity'] = ship['activity'];
          objConfig['class'] = ship['class'];
          ship['status'] = 'scheduled';
          if (!month) {
            const found = this.currentShipData.some(el => el.name === ship.name);
            if (!found) {
              this.currentShipData.push(ship);
              if (ship['ship_status'] === 'show') {
                shipObj.push(objConfig);
              }
            }
          } else {
            const found = this.currentMonthShipData.some(el => el.name === ship.name);
            if (!found) {
              if (ship['activity']) {
                this.currentMonthShipData.push(ship);
              }
            }
          }
        }
      });
    }
    return shipObj;
  }

  /* * * * *
  * filter ships based on date range
  * * * * * */
  getReportRangeData() {
    this.getStartEndDate();
    let shipObj = this.getRangeData();
    let data = { "currentShipData": this.currentShipData, "shipObj": shipObj, 'jettyMissingShipList': [], "isReport": true };
    this.broadcastInfo({ src: 'report', event: 'load_report_view', data: data, key: "" });
  }

  /* * * * *
  * method for get 28 days report data
  * * * * * */
  getCurrentMonthShip() {
    this.getStartEndDate();
    let shipObj = this.getRangeData(true);
    let obj = {
      'monthReport': true,
      'canvasImage': '',
      'shipObjects': this.currentMonthShipData,
      'jettyMissingShipList': [],
      'lastRefershed': (this.lastRefershedDate ? this.lastRefershedDate : this.sliderDate),
      'dateSelected': this.sliderDate
    };
    this.reportPageData.push(obj);
    console.log("reportPageData : ", this.reportPageData);
    this.broadcastInfo({ src: 'report', event: 'update_preview', data: this.reportPageData, key: '' });
  }

  /* * * * *
  * method for call respected report
  * * * * * */
  showDayReportView(reportRange = '1') {
    this.reportRange = Number(reportRange);
    this.dayIdx = 1;
    this.reportPageData = [];
    if (reportRange === '28') {
      this.range = 28;
      this.getCurrentMonthShip();
    } else {
      this.showCurrentDayShip();
    }
  }

  /* * * * *
  * method for process Next day report
  * * * * * */
  processNextDay() {
    if (this.reportRange >= this.dayIdx) {
      this.showCurrentDayShip();
    } else {
      this.dayIdx = 1;
      console.log("reportPageData : ", this.reportPageData);
      console.log("process compeletd !!");
      this.broadcastInfo({ src: 'report', event: 'update_preview', data: this.reportPageData, key: '' });
    }
  }

  sortArr(obj, key) {
    return obj.sort(function (a, b) {
      return (a[key] > b[key]) ? 1 : ((a[key] < b[key]) ? -1 : 0);
    })
  }

  /* * * * *
  * method for get snapshot from venue
  * * * * * */
  getVenueSnapshot() {
    let obj = {};
    this.dfx.getSnapshot({
      callback: (canvasURL) => {
        var img1 = new Image(); // width, height values are optional params
        img1.src = canvasURL;
        img1.onload = () => {
          obj['monthReport'] = false;
          obj['canvasImage'] = this.resizeImgToPDF(img1);
          obj['shipObjects'] = this.sortArr(this.dayShipList, 'location');
          obj['jettyMissingShipList'] = this.jettyMissingShipList;
          obj['lastRefershed'] = (this.lastRefershedDate ? this.lastRefershedDate : this.selectedDate);
          obj['dateSelected'] = this.selectedDate;
          this.reportPageData.push(obj);
          this.processNextDay();
        }
      }
    });
  }

  /* * * * *
  * method for get last refershed date
  * * * * * */
  getRefershedDate(shipList) {
    let refershedDate = this.selectedDate;
    for (let ship of shipList) {
      if ((ship.startdate != '') && (ship.starttime != '')) {
        refershedDate = new Date(ship.startdate + ', ' + ship.starttime);
        break;
      }
    }
    return refershedDate;
  }

  /* * * * *
  * method for resize umage to pdf
  * * * * * */
  preview_pdf_width = 1120;
  preview_pdf_height = 350;
  resizeImgToPDF(img) {
    let w = this.preview_pdf_width;
    let h = this.preview_pdf_height;
    // Create canvas
    let canvas = document.createElement('canvas');
    let ctx = canvas.getContext('2d');
    // Set width and height
    canvas.width = w;
    canvas.height = h;
    // Draw the image
    let containerRatio = h / w;
    let width = img.naturalWidth;
    let height = img.naturalHeight;
    let imgRatio = height / width;

    if (imgRatio > containerRatio) { // image's height too big
      height = width * containerRatio;
    } else { // image's width too big
      width = height / containerRatio;
    }

    let s = {
      width: width,
      height: height,
      offsetX: (img.naturalWidth - width) * .5,
      offsetY: (img.naturalHeight - height) * .5
    };
    ctx.drawImage(img, s.offsetX, s.offsetY, s.width, s.height, 0, 0, w, h);
    return canvas.toDataURL('image/jpeg', 1.0);
  }

  /* * * * *
  * method for show based on day will show ship
  * * * * * */
  showCurrentDayShip() {
    this.dayShipList = [];
    let dayFilterShips = [];
    let location = [];
    let jettyLocation = [];
    this.jettyMissingShipList = [];
    let date = new Date(this.startDate);
    let startDate = new Date(this.startDate);
    let dayIdx = ((0 < this.dayIdx) ? this.dayIdx - 1 : this.dayIdx);
    let shipDate = date.setDate(startDate.getDate() + dayIdx);
    this.selectedDate = new Date(new Date(shipDate).setHours(0, 0, 0, 0));

    this.currentShipData.forEach(ship => {
      if (ship['ship_status'] === 'show') {
        if (new Date(shipDate) >= new Date(ship.startdate) && new Date(shipDate) <= new Date(ship.enddate)) {
          let shipLocation = (ship.location ? ship.location.replace(" ", "").replace("/", "_") : "");
          const foundExternalJettyShip = this.tagServ.cfbexternal_location.some(el => el === shipLocation);
          if (!foundExternalJettyShip) {
            const found = dayFilterShips.some(el => el.name === ship.name);
            if (!found) {
              if (ship['activity']) {
                this.dayShipList.push(ship);
              }
              dayFilterShips.push(ship);
              const found = location.some(el => el === ship.location);
              if (!found) {
                location.push(ship.location);               
                this.ctrlServ.showShip(ship, true);
              } else {
                if (ship['activity']) {
                  let index = dayFilterShips.findIndex(el => el.location === ship.location);
                  if (index > -1) {
                    if (dayFilterShips[index]['activity']) {
                      this.ctrlServ.hideShip(ship);                    
                      this.ctrlServ.showShip(dayFilterShips[index], true);                     
                    } else {
                      this.ctrlServ.hideShip(dayFilterShips[index]);                     
                      dayFilterShips.splice(index, 1);
                      dayFilterShips.push(ship);
                      this.ctrlServ.showShip(ship, true);                      
                    }
                  }
                } else {
                  this.ctrlServ.hideShip(ship);                  
                }
              }
            } else {
              this.ctrlServ.hideShip(ship);             
            }
          } else {
            this.ctrlServ.hideShip(ship);            
          }
        } else {
          this.ctrlServ.hideShip(ship);          
        }
      }
    });

    // for missing jetty ship objects
    this.jettyMissingShipObjects.forEach(obj => {
      if ((obj['startdate'] != '') && (obj['enddate'] != '')) {
        if (new Date(shipDate) >= new Date(obj.startdate) && new Date(shipDate) <= new Date(obj.enddate)) {
          const found = jettyLocation.some(el => el === obj.location);
          if (!found) {
            jettyLocation.push(obj.location);
            this.jettyMissingShipList.push(obj);
          }
        }
      }
    });

    this.dayIdx++;
    setTimeout(() => {
      this.getVenueSnapshot();
    }, 100);
  }

  /* * *
  * method for create pdf
  * * */
  generatePDF(e) {
    this.pdf = new jsPDF('l', 'mm', [280, 280]);
    this.startPDFGenerate(e);
  }

  /* * *
  * start creating page for PDF
  * * */
  startPDFGenerate(e) {
    if(e=='wfmreport'){
      if(this.reportPageData.length==0){
        this.reportPageData.push(e);
      }
    }
    if (this.pageIdx < this.reportPageData.length) {
      var printContiner = document.getElementById('printContiner_' + this.pageIdx);
      var width = this.pdf.internal.pageSize.getWidth();
      var height = this.pdf.internal.pageSize.getHeight();

      if (this.pageIdx > 0) {
        this.pdf.addPage();
      }

      html2canvas(printContiner, { scale: 1, width: 1066, backgroundColor: '#ffffff' }).then(canvas => {
        const imgDataURL = canvas.toDataURL('image/jpeg', 1.0);
        console.log(canvas,",",imgDataURL)
        this.pdf.setPage(this.pageIdx + 1);
        this.pdf.addImage(imgDataURL, 'JPEG', 0, 0, 0, 0);
        ///////////////canvas
        var width = this.pdf.internal.pageSize.getWidth() + 3;
        var height = this.pdf.internal.pageSize.getHeight() + 1;
        var croppingYPosition = 1054.9653543;
        var count = (canvas.height) / 1060.9653543;
        var idx = 2
        for (var i = 1; i < count; i++) {
          // this.pdf.addPage();
          var sourceX = 0;
          var sourceY = croppingYPosition;
          var sourceWidth = canvas.width;
          var sourceHeight = 1054.9653543;
          var destWidth = sourceWidth;
          var destHeight = sourceHeight;
          var destX = 0;
          var destY = 0;
          // var canvas1 = document.createElement('canvas');
          // canvas1.setAttribute('height', destHeight.toString());
          // canvas1.setAttribute('width', destWidth.toString());                         
          // var ctx = canvas1.getContext("2d");
          window.onePageCanvas = document.createElement("canvas");
          window.onePageCanvas.setAttribute('width', destWidth.toString());
          window.onePageCanvas.setAttribute('height', destHeight.toString());
          var ctx = window.onePageCanvas.getContext('2d');

          // ctx.scale(2, 2);
          ctx.fillStyle = 'white';
          ctx.fillRect(0, 0, destWidth.toString(), destHeight.toString());
          ctx.drawImage(canvas, sourceX, sourceY, sourceWidth, sourceHeight, destX, destY, destWidth, destHeight);
          var canvasDataURL = window.onePageCanvas.toDataURL("image/jpeg", 1.0);
          // i++;
          this.pdf.addPage();
          this.pdf.setPage(idx);
          this.pdf.addImage(canvasDataURL, "JPEG", 0, 0, width, height);
          if (sourceY > canvas.height) {
            break;
          }
          idx++;
          this.pageIdx++;
          croppingYPosition += destHeight;
        }
        // var d = new Date().toISOString().slice(0, 19).replace(/-/g, "");
        // filename = 'report_' + d + '.pdf';
        // doc.save(filename);
        //////////////
        this.pageIdx++;
        this.startPDFGenerate(e);
      });

    } else {
      this.pageIdx = 0;
      this.pdf.save('Report_' + Date.now() + '.pdf');
    }
  }

  /* * *
  * method for create pdf
  * * */
  generatePDF_old() {
    console.log(window.innerWidth, ",", window.devicePixelRatio);
    var header = document.getElementById('headerContainer');
    if (window.innerWidth <= 2800) {
      var table = document.getElementById('tableContainer');
      var legend = document.getElementById('legendContainer');
      var tableHeader = document.getElementById("tableContainer").getElementsByTagName("thead")[0];
    } else {
      var table = document.getElementById('tableContainer_big');
      var legend = document.getElementById('legendContainer_big');
      var tableHeader = document.getElementById("tableContainer_big").getElementsByTagName("thead")[0];
    }

    html2canvas(table, { scale: 1, width: 907.09, backgroundColor: '#ffffff' }).then(canvas => {
      html2canvas(legend, { scale: 1, backgroundColor: '#ffffff' }).then(legendCanvas => {
        html2canvas(header, { scale: 1, width: 907.09, }).then(headerCanvas => {
          html2canvas(tableHeader, { scale: 1, width: 907.09, backgroundColor: '#ffffff' }).then(tableHeaderCanvas => {
            const tableURL = canvas.toDataURL('image/jpeg', 0.5);
            const legendURL = legendCanvas.toDataURL('image/jpeg', 1.0);
            const headerURL = headerCanvas.toDataURL('image/jpeg', 0.5);
            const tableHeaderURL = tableHeaderCanvas.toDataURL('image/jpeg', 1.0);
            // window.open(tableURL);
            // window.open(legendURL);
            // window.open(headerURL);
            // window.open(tableHeaderURL);

            this.dfx.getSnapshot({
              callback: (canvasURL) => {
                let pdf = new jsPDF('l', 'mm', [300, 230]);
                var width = pdf.internal.pageSize.getWidth();
                var height = pdf.internal.pageSize.getHeight();

                var height1 = height - 100;
                var legendwidth = width - 60;
                var legendheight = height - 150;
                var width1 = width - 60;
                if (window.innerWidth >= 760 && window.innerWidth < 1200) {
                  legendheight = height - 110;
                  width1 = width - 15;
                } else if (window.innerWidth >= 1200 && window.innerWidth <= 1920) {
                  if (window.innerWidth >= 1680 && window.innerWidth < 2000 && window.innerHeight <= 520) {
                    height1 = height - 102;
                  }
                  legendheight = height - 100;
                } else if (window.innerWidth <= 2800 && window.innerWidth >= 1920) {
                  if (window.innerWidth >= 1680 && window.innerWidth < 2000 && window.innerHeight <= 520) {
                    height1 = height - 102;
                  }
                  // legendheight = height - 130;
                  //  width1 = width -10;
                  //  height1 = height - 40;
                }
                pdf.addImage(headerURL, 'JPEG', 0, 0, width, 20);
                pdf.addImage(canvasURL, 'JPEG', 0, 21, width, height1 - 10);
                pdf.addImage(tableURL, "JPEG", 0, height1, width1, (height1 - height));
                pdf.addImage(legendURL, "JPEG", legendwidth, height1, 60, (legendheight - height));
                let pageHeight = 815;
                let pageWidth = 907.09;
                // let heightRange = 7;
                // let idx = 1;
                // let tableElem = table['rows'].length;
                if (table['rows'].length > 8) {
                  //   for (var i = 0; i <= tableElem / heightRange; i++) {
                  //     var srcImg = canvas;
                  //     var sX = 0;
                  //     var sY = (pageHeight * i + 393);
                  //     if (sY > canvas.height) {
                  //       break
                  //     }
                  //     var sWidth = pageWidth;
                  //     var sHeight = pageHeight;
                  //     var dX = 0;
                  //     var dY = 0;
                  //     var dWidth = pageWidth;
                  //     var dHeight = pageHeight;
                  //     sY = sY + idx;

                  //     window.onePageCanvas = document.createElement("canvas");
                  //     window.onePageCanvas.setAttribute('width', pageWidth);
                  //     window.onePageCanvas.setAttribute('height', pageHeight);
                  //     var ctx = window.onePageCanvas.getContext('2d');

                  //     // ctx.scale(2, 2);
                  //     ctx.fillStyle = 'white';
                  //     ctx.fillRect(0, 0, pageWidth, pageHeight);
                  //     ctx.drawImage(srcImg, sX, sY, sWidth, sHeight, dX, dY, dWidth, dHeight);
                  //     var canvasDataURL = window.onePageCanvas.toDataURL("image/jpeg", 1.0);
                  //     // ctx.scale(1,1);
                  //     pdf.addPage();
                  //     pdf.setPage(idx);
                  //     // idx++;
                  //     pdf.addImage(tableHeaderURL, "JPEG", 0, height1, width1, 14.5);
                  //     pdf.addImage(canvasDataURL, "JPEG", 0, height1, width1, (height1 - height));
                  // pdf.addImage(legendURL, "JPEG", legendwidth, height1, 60, (legendheight - height));

                  //     // pdf.addImage(canvasDataURL, 'JPEG', 0, 15, width1, (height1 - height));
                  //     // pdf.addImage(legendURL, "JPEG", legendwidth, 0, 60, (legendheight - height));
                  //     if (sY > canvas.height) {
                  //       break;
                  //     }
                  //   }
                  let heightRange = 17;
                  let idx = 2;
                  let tableElem = table['rows'].length - 8;
                  for (var i = 0; i <= tableElem / heightRange; i++) {
                    var srcImg = canvas;
                    var sX = 0;
                    var sY = (pageHeight * i + 393);
                    if (window.innerWidth >= 1680 && window.innerWidth < 2000 && window.innerHeight <= 520) {
                      sY = (pageHeight * i + 410);
                    }

                    if (sY > canvas.height) {
                      break
                    }
                    var sWidth = pageWidth;
                    var sHeight = pageHeight;
                    var dX = 0;
                    var dY = 0;
                    var dWidth = pageWidth;
                    var dHeight = pageHeight;
                    sY = sY + idx;

                    window.onePageCanvas = document.createElement("canvas");
                    window.onePageCanvas.setAttribute('width', pageWidth);
                    window.onePageCanvas.setAttribute('height', pageHeight);
                    var ctx = window.onePageCanvas.getContext('2d');

                    // ctx.scale(2, 2);
                    ctx.fillStyle = 'white';
                    ctx.fillRect(0, 0, pageWidth, pageHeight);
                    ctx.drawImage(srcImg, sX, sY, sWidth, sHeight, dX, dY, dWidth, dHeight);
                    var canvasDataURL = window.onePageCanvas.toDataURL("image/jpeg", 1.0);
                    // ctx.scale(1,1);
                    pdf.addPage();
                    pdf.setPage(idx);
                    idx++;
                    pdf.addImage(tableHeaderURL, "JPEG", 0, 0, width1, 14.5);
                    pdf.addImage(canvasDataURL, 'JPEG', 0, 15, width1, (height1 - height));
                    pdf.addImage(legendURL, "JPEG", legendwidth, 0, 60, (legendheight - height));
                    if (sY > canvas.height) {
                      break;
                    }
                  }
                }
                // pdf.output('dataurlnewwindow');
                // this.broadcastInfo({ src: 'main', event: 'showtable', key: '' });
                pdf.save('Report_' + Date.now() + '.pdf');
              }
            });
          });
        });
      });
    });

  }


}
