import { Injectable } from '@angular/core';
import { CutOffEffect } from '../services/effects/cutoff-effect.service';
import { CommunicationService } from './communication.service';
declare var BABYLON;
@Injectable({
  providedIn: 'root'
})
export class DrawService {
    broadcastSubs: any;
    cutouts: any =[];
    isDrawState: boolean = false;
    layoutData: any =[];
    cfbport: any;
    constructor(private cutoutServ: CutOffEffect, private CommunicationServ : CommunicationService){
        this.broadcastSubs = this.CommunicationServ.getInstance()
        .subscribe((data) => {
          if (data.src === "sidepanel") {
            if (data.event === 'cut-mode') {
              this.cut_mode = data.key;
              if (!this.cut_mode) {
                this.clearCutOuts();
              } else {
                if (this.draw_select_mode && this.last_cselect) {
                  this.cutFromPath();
                }
              }
              /* this.draw_select_mode=this.cut_mode;
              if(!this.draw_select_mode){
                this.clearSections();
              } */
            }
            if (data.event === 'draw-mode') {
              this.draw_select_mode = data.key;
              this.cut_mode=this.draw_select_mode;
              /* if(!this.draw_select_mode){
                this.clearSections();
              } */
            }
            if (data.event === 'pause-cut') {
              this.draw_select_mode = data.key;
              this.cut_mode = !!this.draw_select_mode;
              /* if(!this.draw_select_mode){
                this.clearSections();
              } */
            }
            if (data.event === 'apply-cut') {
              if (this.selectedMarker) {
               this.cutFromPath(this.selectedMarker)
              }
            }
            if (data.event === 'remove-cut') {
              if (this.selectedMarker) {
                this.removeCutOut(this.selectedMarker)
              }
            }
            if (data.event === 'clear-cuts') {
              let markers = this.layoutData['markers'] || [];
              this.isDrawState = markers.length > 0 ? true : false;
              this.clearCutOuts();
              this.clearSections(true);
            }
            if (data.event === 'remove-marker') {
              if (this.selectedMarker) {
               this.deleteMarker(this.selectedMarker)
              }
            }
            if (data.event === 'clear-markers') {
              let markers = this.layoutData['markers'] || [];
              this.isDrawState = markers.length > 0 ? true : false;
               this.clearCutOuts();
               this.clearSections();
            }
            if (data.event === 'apply-marker-color') {
              if (this.selectedMarker) {
                this.applyMarkerColor(this.selectedMarker, data.data);
              } else {
                this.markerColor = data.data;
              }
            }
            if (data.event === 'apply-marker-width') {
  
              this.marker_width = data.data;
  
            }
            if (data.event === 'apply-marker-shape') {
              this.draw_select_type = data.data;
              this.marker_select_mode = !true;
              if (data.data === 'select') {
                this.marker_select_mode = true;
              }
              this.cselect = null;
            }
            if (data.event === 'draw-mode-fill') {
              this.draw_select_fill = (data.key);
              if (this.selectedMarker) {
                //this.toggleMarkerFill(this.selectedMarker, this.draw_select_fill);
              }
            }
            if (data.event === 'undo-marker') {
              this.undoMarker();
            }
            if (data.event === 'marker-roundoff') {
              this.roundOffMarker();
            }
           
        }
        })
    }
  cut_mode = false;
  marker_width = 2;
  circle_center
  current_fill
  scene: any;
  datauxview: any;
  dfx: any;
  marker_history=[]

  getHitPosition(x=null,y=null,skipfill=false){
    return this.scene.pick(x||this.scene.pointerX, y||this.scene.pointerY, (mesh) => {
       if(this.isMarker(mesh)){
         if(skipfill&&mesh.name.includes("_fill")&&mesh.name!=this.current_fill){
           return true;
         }
         return false
       }
       return mesh.isEnabled()&&mesh.isVisible&&mesh.isPickable;
     });
   }
  doDraw(){
    let pos;
    let pick = this.getHitPosition(null,null,true);
    // console.log(pick)
    if (!pick.hit) {
      return false;
    }
    if (this.isMarker(pick.pickedMesh,true)) {
      return false;
    }
    if (this.getChildMeshEx(this.cfbport,this.getMeshName(pick.pickedMesh.name)) == null) {
      return false;
    }
    let n = pick.pickedMesh.getFacetNormal(pick.faceId);
    this.draw_plane = this.getNormPlane(n);
    this.inorm = n;
    pos = pick.pickedPoint.clone();
    console.log("pointer Data", pos)
    if (this.cselect) {
        console.log("DO-DRAW update")
        this.ipick[1] = [this.scene.pointerX, this.scene.pointerY]
        //console.log("DO-DRAW update",pick.pickedMesh.name);       
        this.updateSection(pos);
      } else {
        console.log("DO-DRAW start")
        //console.log("DO-DRAW start",pick.pickedMesh.name)
        this.ipick = [[this.scene.pointerX, this.scene.pointerY]]
        this.draw_wall = pick.pickedMesh;
        let canv = this.datauxview.rendererCanvas.nativeElement;
        this.dfx.getCamera().detachControl(canv)
        this.drawSection(pos);
      }
      console.log('pos', pos)
      return true;
  }

isMarker(mesh,excludefill=false) {
  let boo = false;
  if (mesh && mesh.name) {
    boo= (mesh.name.includes("marker_"))
    if(boo && excludefill){
      boo= !(mesh.name.includes("_fill"))
    }
  }
  return boo;
}
  /**
   * draw selection
   */
   cselects = {};
   cselects_length = 0;
   cselect = null
   draw_select_mode = null;
   draw_select_type = 'free';
   draw_select_fill = false;
   last_cselect = null
   draw_wall = null;
   draw_plane = 'z';
   ipick = null;
   inorm = null;
   selectedMarker = null;
   marker_select_mode = false;
   markerColor = "#ff0000"
   
getUID() {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return Math.random().toString(36).substr(2, 9);
  }
drawSection(pos, opts = null, assignUID = true, color = null) {
  let myPath = [pos, pos];
  let uid = 'marker_' + this.getUID();
  let hascut = false;
  let cutplane = this.draw_plane;
  let r = this.marker_width / 40;
  let fill =this.draw_select_fill;
  let inorm=this.inorm;
  let _pos = [pos];
  if (opts) {
    myPath = this.objToVect(opts.path);
    _pos = myPath
    uid = opts.uid
    hascut = opts.cutoff;
    cutplane = opts.cutplane;
    r = opts.mwidth;
    fill = opts.fill;
    inorm = opts.inorm
  }

  let options = {
    path: myPath, //vec3 array,
    updatable: false,
    radius: r
  }
  console.log("CreateTube", uid, options, this.scene);
  let tube = BABYLON.MeshBuilder.CreateTube(uid, options, this.scene);
  //this.cselects_length++
  let col = color || this.markerColor || "#ff0000";
  tube.material = this.getMarkerMaterial(col);
  if (assignUID) {
    this.cselect = uid;
  }
  this.last_cselect = this.selectedMarker = uid;
  if (!this.draw_wall) {
    return
  }
  this.circle_center = pos;
  this.cselects[uid] = [_pos, tube, hascut, cutplane, this.getMeshName(this.draw_wall.name), null, null, col, r, fill, { x: inorm.x, y: inorm.y, z: inorm.z }]
  tube.isPickable = false;
  // console.log("drawSection",uid,this.cselects);
  this.marker_history.push(uid);
  if (opts) {
    //fill for shape from data
    let _pos=tube.getBoundingInfo().boundingSphere.centerWorld;
    let fillshape=this.cutoutServ.createFillShape(_pos,myPath,cutplane,this.scene,uid);
    fillshape.isPickable=false;
    fillshape.material=tube.material;
    //this.current_fill=fillshape.name
    //this.filledMarker(this.cselect,fpath,tube.material);
    fillshape.parent = tube;
    let _d = inorm[opts.cutplane] || inorm['_' + opts.cutplane]
    fillshape.position[opts.cutplane] += -0.03 * Math.sign(_d);
    if (!fill) {
      fillshape.setEnabled(false);
    }
  }
  console.log("this.cselects[uid] ", this.cselects[uid]);
}
updateSection(pos) {
  let instance = this.cselects[this.cselect][1];
  let finstance = this.scene.getMeshByName(this.cselect + "_fill")
  let path = this.cselects[this.cselect][0];
  let r = this.cselects[this.cselect][8];
  let fpath = [];
  if (this.cut_mode) {
    let p0 = path[0]
    path = this.getRectCoords(p0, pos);
  } else {
    if (this.draw_select_type === 'circle') {
      let p0 = this.circle_center;
      path = this.getCircleData(p0, pos, this.draw_plane);
    }
    if (this.draw_select_type === 'rect') {
      let p0 = path[0]
      path = this.getRectCoords(p0, pos);
    }
    if (this.draw_select_type === 'free' || this.draw_select_type === 'polygon') {
      path.push(pos);
    }
  }
  fpath = path.slice();
  //console.log(path)
  instance.dispose()
  if (finstance) {
    finstance.dispose()
  }
  let options = {
    path: path, //vec3 array,
    updatable: false,
    radius: r
  }
  let tube = BABYLON.MeshBuilder.CreateTube(this.cselect, options, this.scene);

  this.cselects[this.cselect][1] = tube
  this.cselects[this.cselect][0] = path
  this.cselects[this.cselect][2] = false;
  let col = this.cselects[this.cselect][7]
  tube.material = this.getMarkerMaterial(col);
  tube.isPickable = false;
  let _pos=tube.getBoundingInfo().boundingSphere.centerWorld;
  let fill=this.cutoutServ.createFillShape(_pos,fpath,this.draw_plane,this.scene,this.cselect);
  //fill.isPickable=false;
  fill.material=tube.material;
  //this.filledMarker(this.cselect,fpath,tube.material);
  fill.parent = tube;
  fill.position[this.draw_plane] += -0.03 * Math.sign(this.inorm[this.draw_plane]);
  if (!this.draw_select_fill) {
    fill.setEnabled(false);
  }
  this.current_fill=fill.name;
  //console.log('Fill',fill.name);
  
  if(this.draw_select_type==='circle'){
    tube.position[this.draw_plane]=-0.1*Math.sign(this.inorm[this.draw_plane]);
    //console.log(tube.name,tube.position);
  }

}

getMarkerMaterial(_color) {
    let matid = 'mark_mat_' + _color;
    let mat = this.scene.getMaterialByName(matid);
    if (mat) {
      return mat;
    }
    let color = this.getHexToRGB(_color);
    let cmat = new BABYLON.PBRCustomMaterial(matid, this.scene)
    cmat.metallic = 0;
    cmat.roughness = 0.4;
    cmat.specularColor = this.dfx.color3([0, 0, 0]);
    cmat.albedoColor = this.dfx.color3(color);
    return cmat;
  }
  getHexToRGB(hex) {
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [parseInt(result[1], 16) / 255, parseInt(result[2], 16) / 255, parseInt(result[3], 16) / 255] : [0, 0, 0];
  }
  objToVect(arr) {
    let nv = []
    arr.forEach((a, i) => {
      nv[i] = this.dfx.vector3(a);
    })
    return nv;
  }
  getMeshName(n) {
    if (!n) {
      return 'unknown'
    }
    let arr = n.split(".");
    if (arr.length === 1) {
      return arr[0]
    }
    if (arr.length === 0) {
      return "unknown"
    }
    return arr[arr.length - 1];
  }

  getNormPlane(n) {
    let ort = 'z';
    if (Math.abs(n.x) > 0.5) {
      ort = 'x'
    }
    if (Math.abs(n.y) > 0.5) {
      ort = 'y'
    }
    return ort;
  }

  getRectCoords(v1, v2) {
    let p0 = v1;
    let p2 = v2;
    let pick1 =this.getHitPosition(this.ipick[1][0], this.ipick[0][1],true);// this.scene.pick(this.ipick[1][0], this.ipick[0][1])
    let pick3 =this.getHitPosition(this.ipick[0][0], this.ipick[1][1],true); // this.scene.pick(this.ipick[0][0], this.ipick[1][1])
    console.log('getRectCoords')
    console.log(pick1);
    console.log(pick3);
    let p1, p3;
    if (!pick1.hit) {
      p1 = this.dfx.vector3([p2.x, p0.y, p0.z])
    } else {
      if (this.draw_plane === 'x') {
        p1 = this.dfx.vector3([pick1.pickedPoint.x, p0.y, p2.z])
      }
      if (this.draw_plane === 'z') {
        p1 = this.dfx.vector3([p2.x, p0.y, pick1.pickedPoint.z])
      }
      if (this.draw_plane === 'y') {
        p1 = this.dfx.vector3([p2.x, pick1.pickedPoint.y, p0.z])
      }
      //p1=pick1.pickedPoint;
    }
    if (!pick3.hit) {
      p3 = this.dfx.vector3([p0.x, p2.y, p0.z])
    } else {
      //p3=pick3.pickedPoint;
      if (this.draw_plane === 'x') {
        p3 = this.dfx.vector3([pick3.pickedPoint.x, p2.y, p0.z])
      }
      if (this.draw_plane === 'z') {
        p3 = this.dfx.vector3([p0.x, p2.y, pick3.pickedPoint.z])
      }
      if (this.draw_plane === 'y') {
        p3 = this.dfx.vector3([p0.x, pick3.pickedPoint.y, p2.z])
      }
    }

    return [p0, p1, p2, p3, p0]
  }

  getCircleData(cent, cp, plane) {
    let mySinus = [];
    let r = cp.subtract(cent).length();
    let radius = r;
    let x, y, z;
    for (let i = -Math.PI; i <= Math.PI; i += Math.PI / 32) {
      if (plane === 'z') {
        x = cent.x + radius * Math.cos(i)
        y = cent.y + radius * Math.sin(i)
        z = cent.z;
      }
      if (plane === 'x') {
        x = cent.x;
        y = cent.y + radius * Math.cos(i);
        z = cent.z + radius * Math.sin(i);
      }
      if (plane === 'y') {
        x = cent.x + radius * Math.cos(i);
        y = cent.y;
        z = cent.z + radius * Math.sin(i);
      }
      mySinus.push(new BABYLON.Vector3(x, y, z));
    }
    return mySinus;
  }
  applyMarkerColor(pid, color = '#ff0000') {
    let sect = this.cselects[pid];
    this.markerColor = color
    if (sect && sect[1]) {
      let mat = this.getMarkerMaterial(color)
      sect[1].material = mat;
      if (sect[1].getChildren()[0]) {
        sect[1].getChildren()[0].material = mat;
      }
      sect[7] = color;
    }
  }

  clearCutOuts() {
    /* let lights=this.scene.lights
    lights.forEach((light)=>{
      if(light.getClassName()=='SpotLight'){
        light.dispose();//setEnabled(false);
      }
    }) */
    //
    this.cutouts.forEach((wall) => {
      this.resetWallMats(wall);
      //  this.removeCutOut()
    })
    //this.cutoutServ.spots=[]    
  }
  resetWallMats(wall) {
    //this.cutoutServ.resetMat(this.scene,wall);
    let ihull = this.getChildContains(wall.parent, 'interior');
    if (ihull) {
      ihull.isVisible = true;
    }
    ihull = this.getChildContains(wall.parent, 'inside');
    if (ihull) {
      ihull.isVisible = true;
    }
    let csg = this.cutoutServ.csgArr[wall.name];
    if (csg) {
      wall.sliced=null;
      wall.setEnabled(true);
      csg.dispose();
      this.cutoutServ.csgArr[wall.name] = undefined;
      delete this.cutoutServ.csgArr[wall.name];

    }
  }

    getChildContains(mesh, c) {
    if (!mesh) return null;
    let arr = mesh.getChildren();
    let a = null;
    arr.some((m) => {
      let name = this.getMeshName(m.name);
      if (name.includes(c)) {
        a = m;
        return
      }
    })
    return a
  }

  clearSections(cuts = false) {
    for (const [key, value] of Object.entries(this.cselects)) {
      this.removeCutOut(key);
      let del = !value[2]
      if (cuts) {
        del = value[2]
      }
      if (del) {
        let tube = value[1];
        tube.dispose();
        let slicer = value[5];
        if (slicer) {
          slicer.dispose();
        }
        this.cselects[key] = null
        delete this.cselects[key];
      }
    }
    //this.cselects={};

    this.last_cselect = null;
    this.selectedMarker = null;
    //this.showMatObjectTag(null, false);
  }
  removeSection(pid) {
    let sect = this.cselects[pid];
    if (sect) {
      if (sect[2]) {
        this.removeCutOut(pid);
        return;
      }
      let tube = sect[1];
      tube.dispose();
      let slicer = sect[5];
      if (slicer) {
        slicer.dispose();
      }
      this.cselects[pid] = null;
      delete this.cselects[pid];
      //this.showMatObjectTag(null, false);
    }
  }

  addShapeFromPath(pid = null, onlydraw = false) {
    if (!this.last_cselect) {
      if (!pid) {
        return
      }
    }
    if (!this.draw_wall) {
      return
    }
    let p
    let key = this.last_cselect
    if (pid) {
      p = this.cselects[pid][1];
      key = pid;
    } else {
      p = this.cselects[this.last_cselect][1];
    }
    if (p) {
      let pos = p.getBoundingInfo().boundingSphere.centerWorld;
      let d = this.getCutDim(false, pid, onlydraw);
      let slicer = this.cutoutServ.createSlicer(pos, d, this.scene, key + "_slicer");
      this.cselects[pid][5] = slicer;
    }
  }
  getCutDim(def = false, pid = null, onlydraw = false) {
    let scale = 1;//1/15.39;
    if (!this.last_cselect || def) {
      if (!pid) {
        return { width: scale, height: scale, depth: scale }
      }
    }
    let path = this.cselects[pid || this.last_cselect][0];
    let p0 = path[0];
    let p1 = path[2];
    if (this.draw_select_mode || onlydraw) {
      let mesh = this.cselects[pid || this.last_cselect][1];
      let bb = mesh.getBoundingInfo().boundingBox;
      p0 = bb.minimum;
      p1 = bb.maximum;
    }
    let dp = p1.subtract(p0);
    let w, h, d;
    w = 1 * scale; h = 1 * scale; d = 1 * scale;
    if (this.draw_plane === 'x') {
      d = Math.abs(dp.z) * scale;
      h = Math.abs(dp.y) * scale;
      w = 1 * scale;
    }
    if (this.draw_plane === 'z') {
      w = Math.abs(dp.x) * scale;
      h = Math.abs(dp.y) * scale;
      d = 1 * scale;
    }
    if (this.draw_plane === 'y') {
      w = Math.abs(dp.x) * scale;
      d = Math.abs(dp.z) * scale;
      h = 1 * scale;
    }
    return { width: w, height: h, depth: d }
  }
  stopDraw() {
    let pos;
    let pick = this.getHitPosition(null,null,true);
    let _pos=null;
    console.log("DO-DRAW stop");
    if(this.draw_select_type==='polygon'&&pick.hit){
      pos = pick.pickedPoint.clone();
      let d = this.circle_center.subtract(pos).length();
      if (d < 0.2 && d > 0) {
        _pos = this.circle_center;
      } else {
        // this.updateSection(pos);
        return true;
      }

    }
    
    this.isDrawState = true;
    let canv = this.datauxview.rendererCanvas.nativeElement;
    this.dfx.getCamera().attachControl(canv);
    
    if (!pick.hit||!this.ipick[1]) {
      this.current_fill=null;
      this.deleteMarker(this.cselect);
      this.cselect = null;
      return false;
    }
    pos = _pos || pick.pickedPoint.clone();
    if (this.cselect) {
      console.log("DO-DRAW update")
      this.updateSection(pos)
    }
    this.addShapeFromPath(this.cselect)
    this.cselect = null;
    if (this.cut_mode) {
        this.cutFromPath();
    }
    this.current_fill=null;
    return false
  }

  deleteMarker(pid) {
    let sect = this.cselects[pid];
    if (sect) {
      this.removeSection(pid)
      //this.removeCutOut(pid);
      this.updateMarkerHistory(pid);
    }
    this.selectedMarker = null;
    this.last_cselect = null;
    if(this.draw_select_type==='polygon'){
      this.cselect = null;
    }
   let markers = this.layoutData['markers'] || [];
    if (markers.length > 0) {
      const found = markers.some(el => el.uid == pid);
      this.isDrawState = Object.keys(this.cselects).length === 0 ? true : found;
    } else {
      if (Object.keys(this.cselects).length === 0) this.isDrawState = false;
    }
  }

  cutFromPath(pid = null, onlydraw = false) {
    if (!this.last_cselect) {
      if (!pid) {
        return
      }
    }
    if (!this.draw_wall) {
      return
    }
    let p
    let key = this.last_cselect
    if (pid) {
      p = this.cselects[pid][1];
      key = pid;
    } else {
      p = this.cselects[this.last_cselect][1];
    }
    if (p) {
      let pos = p.getBoundingInfo().boundingSphere.centerWorld;
      let d = this.getCutDim(false, pid, onlydraw);
      //this.cutoutServ.cutoff_angle=1; 
      this.cselects[key][2] = true;
      this.applyCutoutAt(key, pos, this.draw_wall, d);

      let markers = this.layoutData['markers'] || [];
      if (markers.length > 0) {
        const found = markers.some(el => el.uid == pid && el.cutoff == this.cselects[key][2]);
        this.isDrawState = !found;
      }
    }
  }

  applyCutoutAt(key, pos, hull, d = null, fid = null) {
    if (!hull) {
      return
    }
    let name = this.getMeshName(hull.name);
    /* if(!name.includes('exterior')){
     return
    } */
    this.cutouts.push(hull);
    let ihull = this.getChildContains(hull.parent, 'interior');
    if (ihull) {
      ihull.isVisible = false;
    }
    ihull = this.getChildContains(hull.parent, 'inside');
    if (ihull) {
      ihull.isVisible = false;
    }
    if (fid !== null) {
      this.inorm = hull.getFacetNormal(fid);
      this.draw_plane = this.getNormPlane(this.inorm)
    }
    if (!d) {
      d = this.getCutDim(true);
    }
    /* if(this.cutoutServ.updateMode&&this.cutoutServ.spots.length){
      this.cutoutServ.updateCutPos(pos.clone(),null,d);
    }else{
     this.cutoutServ.cutTarget(pos.clone(),hull,this.scene,this.draw_plane,d,this.inorm)
    } */
    let slicer = this.cselects[key][5]
    this.cutoutServ.cutTargetAt(hull, pos, this.scene, d, slicer || null);
  }
  removeCutOut(pid) {
    let select = this.cselects[pid]
    if (select) {
      if (!select[2]) {
        this.removeSection(pid);
        return;
      }
      select[2] = false;
    }
    this.removeSection(pid);
    this.clearCutOuts();
    let drawState = this.isDrawState;
    for (const [key, value] of Object.entries(this.cselects)) {
      let render = value[2];
      if (render) {
        this.draw_wall = this.getChildMeshEx(this.cfbport, value[4]);
        if (!this.draw_wall) {
          value[4] = value[4].split("_sliced")[0]
          this.draw_wall = this.getChildMeshEx(this.cfbport, value[4]);
        }
        this.draw_plane = value[3]
        this.cutFromPath(key);
      }

      let markers = this.layoutData['markers'] || [];
      if (markers.length > 0) {
        const found = markers.some(el => el.uid == pid && el.cutoff == this.cselects[key][2]);
        if (!drawState) {
          drawState = !found;
        }
      }
    }
    this.isDrawState = drawState;
  }
  getChildMeshEx(id, cid) {
    let el = this.datauxview.getElementId(id);
    let mesh = this.dfx.getElementMesh(el);
    let meshes = mesh.getChildMeshes();
    // let childMeshes = meshes.find(e => this.getMeshName(e.name) === 'esq_land');
    // if(childMeshes){
    //   console.log(childMeshes.getChildMeshes())
    // }
    let idx = meshes.findIndex(e => this.getMeshName(e.name) === cid);
    if (idx > -1) {
      return meshes[idx];
    }
    return null;
  }

  getSelectedCutMarkersData() {
    let arr = this.cselects;
    let markers = []
    for (const [key, value] of Object.entries(this.cselects)) {
      if (this.selectedMarker === key) {
        let obj = {
          "uid": key, "path": this.VectToObj(value[0]), "cutoff": value[2], "cutplane": value[3], "pid": value[4], "note": value[6] || "",
          "color": value[7], "mwidth": value[8], "fill": value[9], "inorm": value[10]
        }
        markers.push(obj);
      }
    }
    return markers;
  }

  getCutMarkersData() {
    let arr = this.cselects;
    let markers = []
    for (const [key, value] of Object.entries(this.cselects)) {
      let obj = {
        "uid": key, "path": this.VectToObj(value[0]), "cutoff": value[2], "cutplane": value[3], "pid": value[4], "note": value[6] || "",
        "color": value[7], "mwidth": value[8], "fill": value[9], "inorm": value[10]
      }
      markers.push(obj);
    }
    return markers;
  }

  VectToObj(arr) {
    let nv = []
    arr.forEach((a, i) => {
      nv[i] = { x: a.x, y: a.y, z: a.z };
    })
    return nv;
  }
  applyCutMarkersFromData(data) {
    console.log("applyCutMarkersFromData",data)
    data.forEach(obj => {
      var  objData = {
        "uid": obj.markerId, "path": obj.poistion, "cutoff": obj.cutoff, "cutplane": obj.cutplane, "pid": obj.parentMeshId,
        "color": obj.color, "mwidth": obj.mwidth, "fill": obj.fill, "inorm": obj.inorm
      }
      obj = objData
      console.log("applyCutMarkersloop",obj)
      if(this.cfbport){
        this.draw_wall = this.getChildMeshEx(this.cfbport, obj.pid);
        this.draw_plane = obj.cutplane;
        this.drawSection(null, obj, false, obj.color);
        this.addShapeFromPath(obj.uid, !obj.cutoff);
        if (obj.cutoff) {
          this.cutFromPath(obj.uid)
        }
      }
    })
  }
  updateMarkerHistory(pid){
    let idx = this.marker_history.indexOf(pid);
    this.marker_history.splice(idx,1);
  }
  undoMarker(){
    if(this.marker_history.length){
      let pid=this.marker_history[this.marker_history.length-1];
      if(this.draw_select_mode){
        this.deleteMarker(pid);
      }else if(this.cut_mode){
        this.removeCutOut(pid)
      } 
    }
  }

  roundOffMarker(pid=null){
    let mid=pid||this.selectedMarker;
    let p=[];
    if(mid){
      p=(this.cselects[mid][0]||[]).slice();
      let l=p.length;
      if(l>=3){        
        let ctrl=p.slice(l-3)
        let bz = BABYLON.Curve3.CreateQuadraticBezier(ctrl[0], ctrl[1], ctrl[2], 10);
        let pbz=bz.getPoints();
        if(pbz.length){
        p.splice(l-2,2,...pbz)
        this.cselects[mid][0]=p;
        this.updateSection(ctrl[2]);
        }
      }
    }
    //return p;
  }
  
}