import { Component, OnInit, AfterViewInit, HostListener, ChangeDetectorRef } from '@angular/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { DatePipe, formatDate } from '@angular/common';
import { CommunicationService } from "../services/communication.service";
import { ChitService } from '../services/chit.service';
import { ConfigService } from '../services/config.service';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment } from 'moment';
import { element } from 'protractor';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-calender-schedule',
  templateUrl: './calender-schedule.component.html',
  styleUrls: ['./calender-schedule.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    DatePipe
  ],
})
export class CalenderScheduleComponent implements OnInit, AfterViewInit {
  selectedDate: any = new Date('01-Mar-2021');
  dateList: any = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  monthDayList: any = Array.from({ length: 35 }, (_, i) => i + 1);
  timeInterval: any = Array.from({ length: 24 }, (_, i) => i + 1);
  projectConfig: any;
  scheduleCardStatus: any = false;
  currDayList: any = 30;
  scheduleData: any = {
    "id": "",
    "name": "",
    "email": "",
    "date": "",
    "starttime": "",
    "endtime": ""
  };
  timelineData: any = [];
  // timelineData:any =[
  //   {
  //   "_id": "61dd97f111e46b40a0c39713",
  //   "officerid": "61dd97d411e46b40a0c39712",
  //   "rounds": "MCR Technical Rounds",
  //   "details": "check data",
  //   "starttime": "11:11",
  //   "notes": null,
  //   "status": "true",
  //   "email": "raj.t@datifex.com",
  //   "date": "6-March-2021",
  //   "role": "Duty Petty Officer(DPO)",
  //   "vessel": "VDQ",
  //   "number": null,
  //   "endtime": "22:02"
  //   },
  //   {
  //   "_id": "61dd981711e46b40a0c39717",
  //   "officerid": "61dd981411e46b40a0c39715",
  //   "rounds": " Habitability",
  //   "details": "test",
  //   "starttime": "01:02",
  //   "notes": null,
  //   "status": "false",
  //   "email": "raj.t@datifex.com",
  //   "date": "6-March-2021",
  //   "role": "Duty Petty Officer(DPO)",
  //   "vessel": "VDQ",
  //   "number": null,
  //   "endtime": "08:02"
  //   },
  //   {
  //   "_id": "61df1f036e8ee4003662353c",
  //   "officerid": "61df1f026e8ee4003662353b",
  //   "rounds": "Small Arms",
  //   "details": "small arms\ntest",
  //   "starttime": "03:00",
  //   "notes": null,
  //   "status": "false",
  //   "email": "raj.t@datifex.com",
  //   "date": "12-January-2022",
  //   "role": "Duty Cook",
  //   "vessel": "HAL",
  //   "number": null,
  //   "endtime": "08:00"
  //   },
  //   {
  //   "_id": "61df1f546e8ee4003662353e",
  //   "officerid": "61df1f536e8ee4003662353d",
  //   "rounds": "Small Arms",
  //   "details": null,
  //   "starttime": "03:00",
  //   "notes": null,
  //   "status": "false",
  //   "email": "raj.t@datifex.com",
  //   "date": "1-March-2021",
  //   "role": "Officer of the Day(OOD)",
  //   "vessel": "HAL",
  //   "number": null,
  //   "endtime": "05:00"
  //   },
  //   {
  //   "_id": "61e04f5b8e94cf0036e3be3d",
  //   "officerid": "61dd20ba5535800bc0f1bcfc",
  //   "rounds": "Small Arms",
  //   "details": "small arms audit",
  //   "starttime": "08:00",
  //   "notes": null,
  //   "status": "false",
  //   "email": "diane.b@datifex.com",
  //   "date": "1-March-2021",
  //   "role": "Brow Watchkeeper/Quartermaster",
  //   "vessel": "VAN",
  //   "number": null,
  //   "endtime": "22:22"
  //   },
  //   {
  //   "_id": "61e659b68e94cf0036e3be41",
  //   "officerid": "61dd4af340d4b741a8e36621",
  //   "rounds": "MCR Technical Rounds",
  //   "details": "check radiate",
  //   "starttime": "11:11",
  //   "notes": null,
  //   "status": "false",
  //   "email": "vengad.s@datifex.com",
  //   "date": "1-March-2021",
  //   "role": "Duty Petty Officer(DPO)",
  //   "vessel": "TOR",
  //   "number": null,
  //   "endtime": "22:22"
  //   },
  //   {
  //   "_id": "61ee335c3e57a20036337849",
  //   "officerid": "61dd20ba5535800bc0f1bcfc",
  //   "rounds": "Safety & Security",
  //   "details": "check alaram",
  //   "starttime": "11:11",
  //   "notes": null,
  //   "status": "false",
  //   "email": "gokul.r@datifex.com",
  //   "date": "1-March-2021",
  //   "role": "Brow Watchkeeper/Quartermaster",
  //   "vessel": "VAN",
  //   "number": null,
  //   "endtime": "22:22"
  //   }
  //   ]

  // timelineData: any = {
  //   "timeline": [
  //     {
  //       "data": [
  //         {
  //           "id":1,
  //           "name": "Round1",
  //           "scheduleDate":"01-24-2022",
  //           "start": "01:30",
  //           "end": "03:00"
  //         },
  //         {
  //           "id":2,
  //           "name": "Round1",
  //           "scheduleDate":"01-25-2022",
  //           "start": "02:30",
  //           "end": "03:00"
  //         },
  //         {
  //           "id":3,
  //           "name": "Round2",
  //           "scheduleDate":"01-21-2022",
  //           "start": "04:00",
  //           "end": "04:00"
  //         },
  //         {
  //           "id":4,
  //           "name": "Round2",
  //           "scheduleDate":"01-22-2022",
  //           "start": "02:00",
  //           "end": "04:00"
  //         }
  //       ],
  //       "id": 1,
  //       "name": "My Round"
  //     },
  //     {
  //       "data": [{
  //         "id":5,
  //         "name": "Round3",
  //         "scheduleDate":"01-21-2022",
  //         "start": "01:30",
  //         "end": "03:00"

  //       }
  //       ],
  //       "id": 2,
  //       "name": "Dependecy1"
  //     },
  //     {
  //       "data": [{
  //         "id":6,
  //         "name": "Round4",
  //         "scheduleDate":"01-25-2022",
  //         "start": "04:30",
  //         "end": "07:00"

  //       }],
  //       "id": 3,
  //       "name": "Dependecy2"
  //     }
  //   ]
  // };
  // timelinePeriod: any = Array.from({ length: 24 * this.sideHeaderList.length }, (_, i) => i + 1);
  sideHeaderList: any = [];
  timelinePeriod: any = Array.from({ length: 24 * this.sideHeaderList.length }, (_, i) => i + 1);
  constructor(private configService: ConfigService, private chitService: ChitService, private cdr: ChangeDetectorRef, private datePipe: DatePipe, private communicationServ: CommunicationService) { }
  broadcastSubs: any;
  chitDataList: any;
  viewDataCalender: any;
  usersDetails: any;
  ngOnInit(): void {
    this.getUserData();
    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src === "dashboard") {
          if (data.event == "getRoundsData") {
            this.sideHeaderList = [];
            // this.timelineData = data.data;
            this.viewDataCalender = data.key;
            this.getSideHeader();
            this.dateChange({ value: this.selectedDate });
          }
          if (data.event == "getChitsData") {
            // var data = data.data;
            this.sideHeaderList = [];
            var chitDataList = data.data;
            this.chitDataList = chitDataList.map(item => ({ _id: item._id, UserID: item.UserID, activity: item.activity, Location: item.Location, Vessel: item.Vessel, OnsiteName: item.OnsiteName, SupName: item.SupName, Start: item.Start, End: item.End, Date: item.Date, status: item.status, comments: item.comments, verified: item.verified, emitterLocation: item.emitterLocation, manAloftLocation: item.manAloftLocation, notification: item.notification }));
            this.viewDataCalender = data.key;
            this.chitDataView();
          }
        }
      });
    // this.broadcastInfo({ src: 'calendar', event: 'selectedDate', data: this.selectedDate });
  }
  getUserData() {
    this.configService.getProjectConfig().then((project_config: any) => {
      this.projectConfig = project_config;
      this.chitService.getUserData().subscribe(
        (response) => {
          this.usersDetails = response;
        },
        error => {
          console.error('Request failed with error')
        })
    })
  }
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }
  ngAfterViewInit(): void {
    this.getSideHeader();
    this.dateChange({ value: this.selectedDate });

  }
  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }
  loadedTimeLabel: any = [];
  getSchedulecard() {
    this.broadcastInfo({ src: 'calendar', event: 'selectedDate', data: this.selectedDate });
    if (this.timelineData) {
      var elem = document.getElementById('calendar');
      this.removeOLdSchedule();
      var el;
      this.timelineData.forEach((ele, idx) => {

        if (this.getDateFormat(new Date(ele.date)) == this.getDateFormat(this.selectedDate)) {
          // console.log("timelineData",ele)
          var timePos1 = parseInt(ele.starttime.split(':')[0], 10);
          var timePos2 = parseInt(ele.endtime.split(':')[0], 10);
          var timeLength = timePos2 - timePos1;
          var i;
          if (this.sideHeaderList.length > 0) {
            idx = this.sideHeaderList.findIndex(e => e.email == ele.email);
            if (idx > -1) {
              i = idx;
            }
          }

          var row = i + 2;
          var leftCursor = "height: 100%;width: 10px;right: auto; left: -8px; cursor: ew-resize; background: #fff;direction: ltr; display: block; position: absolute;border-radius: 50px;top: 0px;";
          var rightCursor = "height: 100%;width: 10px;left: auto; right: -8px; cursor: ew-resize; background: #fff;direction: rtl; display: block; position: absolute;border-radius: 50px;top: 0px;";
          var commonStyle = 'grid-column:' + timePos1 + '/ span ' + Number(timeLength + 1) + ';grid-row:' + row + ';border-left-width: 3px; color: #fff;padding: 8px 12px; font-size: 14px; position: relative;white-space: nowrap;overflow: hidden; text-overflow: ellipsis;';
          var infoStyle = 'border-left-color: #4786ff;background: #4786ffad; align-self: end;  top:-5px;';
          var warnStyle = 'border-left-color: #fdb44d;background: #fdb44d9e;align-self: center; top:-1px;';
          var dangerStyle = 'border-left-color: #fa607e; margin-top: 15px; background: #fa607ea3; align-self: end; top: -5px;';
          var primaryStyle = 'background: #df00afbf; border: 0;  align-self: end;  box-shadow: 0 10px 14px rgba(#4786ff, 0.4);top: -5px;';
          var finalStyle = 2 == row ? commonStyle + infoStyle : 3 == row ? commonStyle + warnStyle : 4 == row ? commonStyle + dangerStyle : 5 == row ? commonStyle + primaryStyle : commonStyle + primaryStyle;
          if (el) {
            el += '<section style="' + finalStyle + '"><span style="' + leftCursor + '"  id="timeLeftArrow_' + ele._id + '"></span><span style="z-index:9;cursor:pointer;display:block" id="timeLine_' + ele._id + '">' + ele.rounds + '</span><span style="' + rightCursor + '" id="timeRightArrow_' + ele._id + '"></span></section>';
          } else {
            el = '<section style="' + finalStyle + '"><span style="' + leftCursor + '" id="timeLeftArrow_' + ele._id + '"></span><span style="z-index:9;cursor:pointer;display:block" id="timeLine_' + ele._id + '">' + ele.rounds + '</span><span style="' + rightCursor + '" id="timeRightArrow_' + ele._id + '"></span></section>';
          }
        }
      });
      if (el) {
        elem.insertAdjacentHTML('beforeend', el);
      }
    }
  }

  dragDir: any;
  dragId: any;
  dragState: any = false;
  startPosition: any;
  @HostListener('document:mousedown', ['$event'])
  onMouseDown(event) {
    if (event.srcElement.id && event.srcElement.id.includes('timeLeftArrow_') || event.srcElement.id.includes('timeRightArrow_')) {
      this.startPosition = event.screenX;
      if (event.srcElement.id.includes('timeLeftArrow_')) {
        this.dragId = event.srcElement.id.split('_')[1];
        this.dragDir = 'left';
        this.dragState = true;
        // console.log("timeLeftArrow_",event,this.dragId,this.dragDir);
      }
      if (event.srcElement.id.includes('timeRightArrow_')) {
        this.dragId = event.srcElement.id.split('_')[1];
        this.dragDir = 'right';
        this.dragState = true;
        // console.log("timeRightArrow_",event,this.dragId,this.dragDir);
      }
    }
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(event) {

    let idx = this.timelineData.findIndex(e => e['_id'] == this.dragId);
    if (idx > -1) {
      this.updateTimelineData(this.timelineData[idx]);
    }
    delete this.dragId;
    delete this.dragDir;
    this.dragState = false;
    delete this.startPosition;
    // console.log("mouseup",this.dragId,this.dragDir);
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event) {
    if (this.dragState) {
      this.dragCardUpdate(event);
    }
  }
  dragCardUpdate(event) {
    let idx = this.timelineData.findIndex(e => e['_id'] == this.dragId);
    if (idx > -1) {
      // console.log("mousemove",event,this.timelineData[idx]);
      if (this.dragDir == 'left') {
        if (event.screenX > this.startPosition) {
          let value = event.screenX - this.startPosition;
          if (value > 50) {
            this.startPosition = this.startPosition + 50;
            if (this.timelineData[idx]['starttime']) {
              let time = (Number(this.timelineData[idx]['starttime'].split(':')[0]) + 1) <= this.timelineData[idx]['endtime'].split(':')[0] ? (Number(this.timelineData[idx]['starttime'].split(':')[0]) + 1) : Number(this.timelineData[idx]['starttime'].split(':')[0]) + 1 - 1;
              let timeValue = time > 9 ? time : '0' + time;
              this.timelineData[idx]['starttime'] = timeValue + ':' + this.timelineData[idx]['starttime'].split(':')[1];
              // this.updateTimelineData(this.timelineData[idx]);
              this.getSchedulecard();
            }
            // if(this.timelineData[idx]['endtime']){
            //   let time =Number(this.timelineData[idx]['endtime'].split(':')[0])-1;
            // }
          }
        }
        if (event.screenX < this.startPosition) {
          let value = this.startPosition - event.screenX;
          if (value > 50) {
            this.startPosition = this.startPosition - 50;
            if (this.timelineData[idx]['starttime']) {
              let time = (Number(this.timelineData[idx]['starttime'].split(':')[0]) - 1) == 0 ? 1 : (Number(this.timelineData[idx]['starttime'].split(':')[0]) - 1);
              let timeValue = time > 9 ? time : '0' + time;
              this.timelineData[idx]['starttime'] = timeValue + ':' + this.timelineData[idx]['starttime'].split(':')[1];
              // this.updateTimelineData(this.timelineData[idx]);
              this.getSchedulecard();
              // this.updateTimelineData();
            }
            // this.timelineData[idx]['starttime']
          }
        }
      }
      if (this.dragDir == 'right') {
        if (event.screenX > this.startPosition) {
          let value = event.screenX - this.startPosition;
          if (value > 50) {
            this.startPosition = this.startPosition + 50;
            if (this.timelineData[idx]['endtime']) {
              let time = Number(this.timelineData[idx]['endtime'].split(':')[0]) + 1 <= 25 ? Number(this.timelineData[idx]['endtime'].split(':')[0]) + 1 : (Number(this.timelineData[idx]['endtime'].split(':')[0]) + 1) - 1;
              let timeValue = time > 9 ? time : '0' + time;
              this.timelineData[idx]['endtime'] = timeValue + ':' + this.timelineData[idx]['endtime'].split(':')[1];
              // this.updateTimelineData(this.timelineData[idx]);
              this.getSchedulecard();
            }
            // if(this.timelineData[idx]['endtime']){
            //   let time =Number(this.timelineData[idx]['endtime'].split(':')[0])-1;
            // }
          }
        }
        if (event.screenX < this.startPosition) {
          let value = this.startPosition - event.screenX;
          if (value > 50) {
            this.startPosition = this.startPosition - 50;
            if (this.timelineData[idx]['endtime']) {
              let time = Number(this.timelineData[idx]['endtime'].split(':')[0]) - 1 >= this.timelineData[idx]['starttime'].split(':')[0] ? Number(this.timelineData[idx]['endtime'].split(':')[0]) - 1 : (Number(this.timelineData[idx]['endtime'].split(':')[0]) - 1) + 1;
              let timeValue = time > 9 ? time : '0' + time;
              this.timelineData[idx]['endtime'] = timeValue + ':' + this.timelineData[idx]['endtime'].split(':')[1];
              // this.updateTimelineData(this.timelineData[idx]);
              this.getSchedulecard();
            }
            // this.timelineData[idx]['starttime']
          }
        }
      }

    }
  }

  updateTimelineData(data) {
    this.broadcastInfo({ src: 'calendar', event: 'updateTimeline', data: data, key: this.viewDataCalender });
  }
  removeOLdSchedule() {
    var el = document.querySelectorAll('#calendar')[0];
    if (el) {
      if (el.children.length > 0) {
        Array.from(el.children).forEach((element, i) => {
          if (element.tagName == 'SECTION') {
            element.remove();
          }
        });
      }
    }
  }
  getMonth() {

  }
  getDayList(year, month) {
    var monthIndex = month - 1; // 0..11 instead of 1..12
    var names = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    var date = new Date(year, monthIndex, 1);
    var result = [];
    while (date.getMonth() == monthIndex) {
      result.push(date.getDate() + '-' + names[date.getDay()]);
      date.setDate(date.getDate() + 1);
    }
    return result;
  }
  onDateChange(e) {
    var dt = e.value._d;
    var month = dt.getMonth() + 1;
    var year = dt.getFullYear();
  }
  getDaycheck(date, day) {
    if (date.toLowerCase().indexof(day.toLowerCase()) > -1) {
      return date;
    } else {
      return "";
    }
  }
  // slider comp section
  /* * * * *
* load to current day ship
* * * * * */
  loadCurrentDayShip() {
    this.selectedDate = new Date();
    this.dateChange({ value: this.selectedDate });
  }

  timeLineSection: any = 1;
  /* * * * *
* date range value
* * * * * */
  onChangeDateRange(value) {
    this.timeLineSection = value;
    var d = new Date(this.selectedDate);
    d.setDate(value);
    this.selectedDate = new Date(d);
    var dt = this.selectedDate;
    var month = dt.getMonth() + 1;
    var year = dt.getFullYear();
    this.currDayList = new Date(year, month, 0).getDate();
    this.getSchedulecard();
  }

  DayChange(e) {
    if (e == 'pre') {
      if (this.timeLineSection >= 1) {
        this.onChangeDateRange(this.timeLineSection - 1);
        if (1 > this.timeLineSection) {
          this.timeLineSection = this.currDayList;
        }
      }
    } else if (e == 'next') {
      if (this.currDayList >= this.timeLineSection) {
        var curDayLimit = this.currDayList;
        this.onChangeDateRange(this.timeLineSection + 1);
        if (curDayLimit < this.timeLineSection) {
          this.timeLineSection = 1;
        }
      }
    }
  }

  /* * * * *
 * Label range 
 * * * * * */
  formatLabelDate(value: number) {
    if (value > this.currDayList) {
      return Math.round(value / this.currDayList) + '';
    }
    return value;
  }
  getDateFormat(date) {
    if (date) {
      var dt = new Date(date);
      return this.datePipe.transform(dt, 'MMMM d, y');
    } else {
      return "";
    }

  }
  /* * * * *
* on date change
* * * * * */
  dateChange(e) {
    this.selectedDate = e.value;
    var dt = this.selectedDate;
    var month = dt.getMonth() + 1;
    var year = dt.getFullYear();
    this.currDayList = new Date(year, month, 0).getDate();
    this.timeLineSection = this.selectedDate.getDate();
    this.getSchedulecard();
  }
  getDataIndex: any;
  scheduleAdd(time) {
    this.scheduleCardStatus = true;
    this.getDataIndex = Math.floor(time / 25);
    var convertTime = 24 * this.getDataIndex;
    var timeInterval = time - convertTime;
    timeInterval = timeInterval > 0 ? timeInterval : timeInterval * -1;
    this.scheduleData['starttime'] = timeInterval.toString().length == 1 ? '0' + timeInterval + ':00' : timeInterval + ':00';
    this.scheduleData['endtime'] = timeInterval.toString().length == 1 ? '0' + timeInterval + ':00' : timeInterval + ':00';
    this.scheduleData['date'] = this.selectedDate;
    this.scheduleData['email'] = this.sideHeaderList[this.getDataIndex]['email'];
    if (this.viewDataCalender == "rounds") {
      this.broadcastInfo({ src: 'calendar', event: 'createSchedule', data: this.scheduleData });
    }
    if (this.viewDataCalender == "chits") {
      this.broadcastInfo({ src: 'calendar', event: 'createChits', data: this.scheduleData });
    }
  }
  getSideHeader() {
    if (this.timelineData.length > 0) {
      this.timelineData.forEach(element => {
        if (this.sideHeaderList.length > 0) {
          let idx = this.sideHeaderList.findIndex(e => e.email == element.email);
          if (idx == -1) {
            this.sideHeaderList.push(element);
          }
        } else {
          this.sideHeaderList.push(element);
        }
      });

      var role = []
      var sideHeader = ['Raj', 'Gokul', 'Sathesh', 'Harris', 'Chris', 'Chris.Erickson@datifex.com', 'demodfx@outlook.com']
      var id = ['616a9de8804f4411b8bcad3b']

      sideHeader.forEach(element => {
        let key = this.sideHeaderList.findIndex(e => e.email == element)
        if (key > -1) {
          this.sideHeaderList.splice(key, 1);
        }
      });

      setTimeout(() => {
        var removeNull = []
        removeNull = this.sideHeaderList.filter(x => x.email !== null)
        this.sideHeaderList = removeNull;

        for (let index = 0; index < this.usersDetails.length; index++) {
          for (let index2 = 0; index2 < this.sideHeaderList.length; index2++) {
            if (this.usersDetails[index].email == this.sideHeaderList[index2].email) {
              this.sideHeaderList[index2]['Role'] = this.usersDetails[index].Role.trim();
              this.sideHeaderList[index2]['First Name'] = this.usersDetails[index]['First Name'].trim();
            }
          }
        }
      }, 500);

      this.timelinePeriod = Array.from({ length: 24 * this.sideHeaderList.length }, (_, i) => i + 1);
    }
  }
  @HostListener('window:click', ['$event'])
  onmessage(e) {
    if (e) {
      if (e.target.id) {
        if (e.target.id.indexOf('timeLine') > -1) {
          var idx = e.target.id.split('_')[1];
          this.viewTimeLine(idx);
        }
      }
    }


  }
  viewTimeLine(ele) {
    let idx = this.timelineData.findIndex(e => e._id == ele);
    if (idx > -1) {
      if (this.viewDataCalender == "rounds") {
        this.broadcastInfo({ src: 'calendar', event: 'viewTimeline', data: this.timelineData[idx] });
      }
      if (this.viewDataCalender == "chits") {
        this.configService.getProjectConfig().then((project_config: any) => {
          this.projectConfig = project_config;
          this.chitService.getChitActivityData(ele).subscribe(response => {
            this.broadcastInfo({ src: 'calendar', event: 'viewChits', data: response[0] });
          })
        })
      }
    }
  }
  chitDataView() {
    this.chitDataList.forEach((obj) => {
      for (const k in obj) {
        if (k === "Date") {
          obj["date"] = obj[k];
          delete obj[k]
        }
        if (k === "Start") {
          obj["starttime"] = obj[k];
          delete obj[k]
        }
        if (k === "End") {
          obj["endtime"] = obj[k];
          delete obj[k]
        }
        if (k === "activity") {
          obj["rounds"] = obj[k];
          delete obj[k]
        }
        if (k === "OnsiteName") {
          obj["email"] = obj[k];
          delete obj[k]
        }
      }
    })
    this.timelineData = this.chitDataList;
    this.getSideHeader();
    this.dateChange({ value: this.selectedDate });
  }
}
