import { Component, OnInit, Input, Output, AfterViewChecked, EventEmitter, HostListener, ElementRef, ViewChild } from '@angular/core';
import { CommunicationService } from "../services/communication.service";
import { formatDate } from '@angular/common';
import { DatePipe } from '@angular/common';
import { TagService } from '../services/tag.service';
import { ActivityVisualizationService } from '../services/activity-visualization.service';
import { LanguageService } from '../services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ConfigService } from '../services/config.service';
import { ControllerService } from '../services/controller.service';
import { DomSanitizer } from "@angular/platform-browser";
import { KHASystemService } from '../services/systems/kha-systems.service';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  providers: [DatePipe]
})
export class TagComponent implements OnInit {
  @ViewChild('scrollComment') private myScrollContainer: ElementRef;
  selectedConeElem: any = null;
  @Output() shipDetailTag: EventEmitter<any> = new EventEmitter<any>();
  @Output() tagCurrentClose: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeKioskStatus: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateConeData: EventEmitter<any> = new EventEmitter<any>();
  @Output() cautionPopupClose: EventEmitter<any> = new EventEmitter<any>();
  @Output() openPublishDialog: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeCone: EventEmitter<any> = new EventEmitter<any>();
  @Input() tagMaxList: any = [];
  @Input() unknownLocationShipList: any = [];
  @Input() sso_cfbhalifax: any = [];
  @Input() cfbstatic_ships: any = [];
  @Input() _disableTrans: any;
  @Input() magTagStatus: boolean = false;
  @Input() kioskList: any;
  @Input() kioskStatus: any;
  @Input() selectedLaydown: any;
  @Input() statusColorCode: any;
  @Input() coneList: any = [];
  @Input() set selectedCone(cone: any) {
    this.selectedConeElem = cone;
    this.setConeData();
  }
  @Input() set receivedMessage(msg: any) {
    if (msg != undefined) {
      this._receivedMessage = msg;
      this._receivedMessageTime = this.datePipe.transform(new Date, 'HH:mm');
      this.broadcastStatus = true;
    }
  }
  @Input() set commsConsoleState(v: any) {
    if (v) {
      this.commsConsole = v;
    } else {
      this.commsConsole = v;
    }
  }
  activityChangedShipList: any = [];
  activityChangedShipTime: any = {};
  _receivedMessage: any = '';
  _receivedMessageTime: any = '';
  tagMiniList: any = [];
  popupZindex: any = 0;
  broadcastSubs: any;
  activityList: any;
  broadcastStatus: boolean = false;
  chitBroadcastStatus: boolean = false;
  currentAssignment: boolean = true;
  nextAssignment: boolean = true;
  firstAidEdit: boolean = false;
  // selectedLocationObj: boolean = false;
  selectedLocationObj: any = {};
  // scsViewModel: boolean = false;
  // scsViewModelMaximizeTag: boolean = false;
  scsNscHeading: any;
  scsMaterialHeading: any;
  scsBaseUrl: any;
  scsResUrl: any;
  scsSubNscHeading: any;
  scsSubMaterialHeading: any;
  allFirstAidElements: any = [];
  padexpDateValues: any;
  sparepadDateValues: any;
  childpadDateValues: any;
  batteryexpDateValues: any;
  sparebatteryDateValues: any;
  allStaticElements: any = [];
  activeKioskStatus: any = '';
  laydownAreadata: any;
  ReadOnlyData: boolean = true;
  broadcastData: any;
  loadNscUrl: any;
  loadMaterialUrl: any;
  chitNotification: any = [];
  handleShipDetailToggleOpen: any = false;
  coneColors: any = [
    { "name": "orange", "code": "#df5900", 'material': 'cone_orange' },
    { "name": "red", "code": "#ff0000", 'material': 'cone_red' },
    // { "name": "green", "code": "#008000", 'material': 'cone_green' }
  ]
  hazardTypeList: any = [
    { "key": "emergency", "name": "Emergency", "label": "red" },
    { "key": "spill", "name": "Spill", "label": "red" },
    { "key": "notice", "name": "Notice", "label": "orange" },
    { "key": "maintenance", "name": "Maintenance", "label": "orange" },
    { "key": "disabledequipment", "name": "Disabled Equipment", "label": "orange" },
    { "key": "pavementrepair", "name": "Pavement repair", "label": "orange" },
    { "key": "temporarystructure", "name": "Temporary structure", "label": "orange" },
    { "key": "other", "name": "Other", "label": "orange" }
  ];
  laydownForm: FormGroup;
  laydownNextForm: FormGroup;
  aidForm: FormGroup;
  laydownData: any = [];
  laydownDataShowId: any;
  isReadonly = true;
  padexpDateValue: any;
  sparepadDateValue: any;
  commentDatas: any;
  childpadDateValue: any;
  batteryexpDateValue: any;
  sparebatteryDateValue: any;
  windowWidth: any;
  windowHeight: any;
  unknownActivity: any;
  unknowActivityState: boolean = false;
  riskMangState: any = false;
  selectedKiosk: any;
  selectedJetty: any;
  roofConfirm: any = false;
  roofConfirmData: any;
  constructor(private khaConfig: KHASystemService, private ctrlServ: ControllerService, private domSanitizer: DomSanitizer, private formBuilder: FormBuilder, private tagServ: TagService, private communicationServ: CommunicationService, private activityServ: ActivityVisualizationService, private datePipe: DatePipe, private translate: TranslateService, private language: LanguageService, private configService: ConfigService) {
    this.windowWidth = window.innerWidth - window.innerWidth / 2;
    this.windowHeight = Number(window.innerHeight - window.innerHeight / 3);
    this.activityList = this.activityServ.activityConfig;
    this.language.languageChange.subscribe(props => {
      console.log("language", props);
      this.translate.use(props);
    })
  }

  //   ngOnInit() { 
  //     this.scrollToBottom();
  // }

  ngAfterViewChecked() {
    // this.scrollToBottom();        
  }

  scrollToBottom(): void {
    try {
      document.getElementById("scrollComment").scrollTop = document.getElementById("scrollComment").scrollHeight;
    } catch (err) { console.log('err', err) }
  }
  @HostListener('window:resize', ['$event']) onResize(event) {
    console.log(event.target.innerWidth, event.target.innerHeight);
    this.windowWidth = event.target.innerWidth - event.target.innerWidth / 2;
    this.windowHeight = Number(event.target.innerHeight - event.target.innerHeight / 3);
  }
  getStaticObjects() {
    this.allStaticElements = this.sso_cfbhalifax.map(el => { return el })
    this.cfbstatic_ships.forEach(element => {
      this.allStaticElements.push(element)
    });
  }
  objClick: any = false
  objHover: any = false;
  commsConsole: any = false;

  @Input() set disabled(condition: boolean) {
    const action = condition ? 'disable' : 'enable';
    // this.ngControl.control[action]();
  }

  firstAidState: boolean = false;
  aedAccState: boolean = true;
  harzardAreaCreate: boolean = false;
  aidCreate: boolean = false;
  shipObjects: any;
  userList: any = [];
  updateUserList: any = [];
  ngOnInit() {
    this.getStaticObjects();
    console.log("fsakf ", this.laydownData)
    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src === "main") {
          if (data.event === 'showmsgtag') {
            this.activityChangedShipList = data.data;
            this.activityChangedShipList.forEach(ship => {
              this.activityChangedShipTime[ship.name] = this.getCurrentTime();
            });
          }
          if (data.event === 'showKioskId') {
            if (data['data']) {
              this.kioskPanelClose();
              this.objClick = true;
              this.selectedKioskData(data['data']);
              this.ctrlServ.focusElement({ name: data['data'] }, true);
              this.tagServ.showKioskTag(data['data'], true)
            }
          }
          if (data.event === 'onHoverKioskId') {
            if (data['data']) {
              // this.kioskPanelClose()
              this.objClick = false;
              this.selectedKioskData(data['data']);

            }
          }
          if (data.event === 'offHoverKioskId') {
            if (data['data']) {
              // this.kioskPanelClose()
              this.selectedKioskData(data['data']);
            }
          }
          if (data.event === 'showLaydownPointer') {
            if (data.state) {

              this.selectedLaydownData(data);
              this.laydownAreadata = data;
              this.isReadonly = true;
              this.ReadOnlyData = true;
              this.currentAssignment = true;
              this.laydownForm.disable();
            } else {
              this.laydownTagClose()
            }

          }
          if (data.event === 'updatekioskstatus') {
            let id = data['data'];
            if (id) {
              this.selectedKStatus = data['status'];
              this.kioskStatusList = {};
              this.kioskStatusList['service'] = data['service'];
              this.updateKioskStatus(this.laydownAreadata);
            }
          }
          if (data.event === 'showconepopup') {
            if (data['data']) {
              this.selectedConeElem = data['data'];
              this.setConeData();
              this.hazardEditState = false;
              this.deleteConfirmPopup = false;
              this.positionConfirmHazardPopup = false;
            }
          }
          if (data.src === "main") {
            if (data.event === 'updateshipmetadata') {
              this.shipObjects = data['data']['shipObjects'];
              console.log("updateshipmetadata", this.shipObjects);
              // this.commentTagShowHide();
            }
            if (data.event == "commentTag") {
              this.commentTagShowHide(data['data']);
            }
            if (data.event === 'first_Aid_data') {
              console.log("First Aid Data ", data.data)
              this.allFirstAidElements = data.data;
              this.firstAidState = (this.allFirstAidElements.first_aid == 'Yes' ? true : false);
              this.aedAccState = (this.allFirstAidElements.aed_acc == 'Yes' ? true : false);
              // this.selectedLocationObj = !this.selectedLocationObj;
              this.selectedLocationObj = null;
              this.padexpDateValues = new Date(this.allFirstAidElements.pad_exp);
              this.sparepadDateValues = new Date(this.allFirstAidElements.spare_exp);
              this.childpadDateValues = new Date(this.allFirstAidElements.child_pad);
              this.batteryexpDateValues = new Date(this.allFirstAidElements.battery_exp);
              this.sparebatteryDateValues = new Date(this.allFirstAidElements.spare_batt);
              this.monthDiff();
            }
            if (data.event === 'comms') {
              console.log("comms")
              this.commsConsole = true;
            }
          }
          if (data.event == 'broadCastMsg') {
            console.log("broadCastMsg", data.data);
            this.broadcastData = data.data
            this.broadCastPopup();
          }
          if (data.event === 'hazardPosition') {
            console.log("hazardPosition: ", data.data)
            this.selectedConeElem = data['data'];
            this.hazardEditState = false;
            this.deleteConfirmPopup = false;
            this.positionConfirmHazardPopup = true;
            // setTimeout(() => {
            // this.deleteHazard()
            // }, 1000);

          }
          if (data.event === 'hazardPositionFirstAid') {
            console.log("hazardPositionFirstAid: ", data.data)
            this.selectedLocationObj = data['data'];
            this.positionConfirmFirstAidPopup = true;
          }
          if (data.event === 'hazardAreas') {
            this.harzardAreaCreate = data['data'];
          }
          if (data.event === 'aidAreas') {
            this.aidCreate = data['data'];
          }
        }
        if (data.src === "khaservice") {
          if (data.event == "commentTag") {
            this.tagServ.showCommentTag(data['data'], data['key']);
          }
        }
        if (data.src === "pipe") {
          if (data.event === 'filterData') {
            console.log("filterData", data['data'])
          }
        }
        if (data.src == 'navyApp') {
          if (data.event == 'userDetails') {
            console.log("userDetails", data['data']);
            this.userList = data['data'];
            this.updateUserList = JSON.parse(JSON.stringify(this.userList));
          }
        }
        if (data.src === "sidepanel") {
          if (data.event === 'riskMangState') {
            this.riskMangState = data.data;
          }
          if (data.event === 'comms') {
            this.commsConsole = data['data'];
          }
          if (data.event === 'kioskStatus') {
            this.objClick = true;
            this.selectedKioskData(data['data']['data']['uid']);
            this.kioskPopupWindow(data.data.data, data.data.index);
            this.objClick = false;
          }
          if (data.event === 'commentData') {
            let val = data['data'];
            if (val) {
              // this.kioskStatusData = data;
              this.commentDatas = val.comment
              this.kioskStatusList = val.data.service[val.index];
              this.selectedKStatus = val.data.service[val.index].status;
              this.updateKioskComment(val.data.uid)
            }
          }
          if (data.event === 'selectedLaydown') {
            console.log("data bind laydown id : ", data.data)
            this.laydownDataShowId = data.data._id
            console.log("id data", this.laydownDataShowId)
            // this.getLaydownData();
          }
          if (data.event === 'hazardEdit') {
            console.log("hazardEdit: ", data.data)
            this.selectedConeElem = data['data'];
            this.hazardEditState = true;
            this.deleteConfirmPopup = false;
            this.positionConfirmHazardPopup = false;
            setTimeout(() => {
              this.editHazard();
            });
          }
          if (data.event === 'hazardDelete') {
            console.log("hazardDelete: ", data.data)
            this.selectedConeElem = data['data'];
            this.hazardEditState = false;
            this.deleteConfirmPopup = false;
            this.positionConfirmHazardPopup = false;
            // setTimeout(() => {
            this.deleteHazard()
            // }, 1000);

          }
          if (data.event === 'updateDetailPanel') {
            this.handleShipDetailToggleOpen = data['key'];
          }
          if (data.event === 'scsViewModelWindow') {
            console.log("gsg", data['data'])
            console.log("gsg", data['sub'])
            var subHeading = data['data'];
            var heading = data['sub'];
            if (heading == 'ern') {
              this.scsNscViewModel = true;
              this.scsNscHeading = 'ERN';
              this.loadNscUrl = this.domSanitizer.bypassSecurityTrustResourceUrl("https://navyscs.azurewebsites.net/?screenType=ERN")
              return
            }
            this.scsResUrl = subHeading.area.toLowerCase(); //subHeading.area.split(" ").join("").toLowerCase();
            if (heading == 'nsc') {
              this.scsNscViewModel = data['key'];
              this.scsSubMaterialHeading = subHeading.area;
              var topHeading = "NSSC"
              var baseLink = "nsc"
              this.scsNscHeading = topHeading;
              this.scsBaseUrl = baseLink;
              this.loadNscUrl = this.domSanitizer.bypassSecurityTrustResourceUrl("https://navyscs.azurewebsites.net/?screenType=" + this.scsBaseUrl + "&KHA=" + this.scsResUrl)
            }
            if (heading == 'ship') {
              this.scsMaterialViewModel = data['key'];
              this.scsSubMaterialHeading = subHeading.area;
              var topHeading = "Material"
              var baseLink = "materiel_kha"
              this.scsMaterialHeading = topHeading;
              this.scsBaseUrl = baseLink;
              this.loadMaterialUrl = this.domSanitizer.bypassSecurityTrustResourceUrl("https://navyscs.azurewebsites.net/?screenType=" + this.scsBaseUrl + "&KHA=" + this.scsResUrl)
              console.log(this.loadMaterialUrl)
            }
            // console.log("url ", this.loadUrl)
          }
          if (data.event == 'aedEdit') {
            this.editFirstAid();
          }
          if (data.event == 'roofConfirm') {
            this.roofConfirm = true;
            this.roofConfirmData = data;
          }
        }
        if (data.src == 'slider') {
          if (data.event == 'unkonwn_activity') {
            this.unknownActivity = data['data'];
            this.unknowActivityState = true;
          }
        }
        if (data.src == 'chitform') {
          if (data.event == 'chitNotification') {
            if (data['data']) {
              this.chitNotification = data['data'];
              this.chitBroadcastStatus = true;
            }
          }
        }
      });

    this.laydownForm = this.formBuilder.group({
      'AssignCat_Curr': '',
      'CurrAssign_Start': '',
      'CurrAssign_End': '',
      'Notes': '',
      'Indefinite': '',
      'CurrAssign_POC': '',
      'CurrAssign_Phone': '',
      'AssignCat_Upcom': '',
      'UpcomAssign_Start': '',
      'UpcomAssign_End': '',
      'UpcomAssign_Details': '',
      'UpcomAssign_POC': '',
      'UpcomAssign_Phone': '',
    });

    // set isReadonly(value: boolean) {
    //   this._isReadonly = value;
    //   if(value) {
    //     this.laydownForm.disable();
    //   } else {
    //     this.laydownForm.enable();
    //    }
    //  }

    if (this.isReadonly == true) {
      this.laydownForm.disable();
      // this.laydownForm.controls['AssignCat_Curr'].disable();
      // this.laydownForm.controls['CurrAssign_Start'].disable();
      // this.laydownForm.controls['CurrAssign_End'].disable();
      // this.laydownForm.controls['Notes'].disable();
      // // this.laydownForm.controls['Indefinite'].disable();
      // this.laydownForm.controls['CurrAssign_POC'].disable();
      // this.laydownForm.controls['CurrAssign_Phone'].disable();
      // this.laydownForm.controls['AssignCat_Upcom'].disable();
      // this.laydownForm.controls['UpcomAssign_End'].disable();
      // this.laydownForm.controls['UpcomAssign_Details'].disable();
      // this.laydownForm.controls['UpcomAssign_POC'].disable();
      // this.laydownForm.controls['UpcomAssign_Phone'].disable();
      // this.laydownForm.controls['UpcomAssign_Start'].disable();
    }
    //  } else {
    //   this.laydownForm.enable();
    //   // this.laydownForm.controls['AssignCat_Curr'].enable();
    //   // this.laydownForm.controls['CurrAssign_Start'].enable();
    //   // this.laydownForm.controls['CurrAssign_End'].enable();
    //   // this.laydownForm.controls['Notes'].enable();
    //   // // this.laydownForm.controls['Indefinite'].enable();
    //   // this.laydownForm.controls['CurrAssign_POC'].enable();
    //   // this.laydownForm.controls['CurrAssign_Phone'].enable();
    //   // this.laydownForm.controls['AssignCat_Upcom'].enable();
    //   // this.laydownForm.controls['UpcomAssign_End'].enable();
    //   // this.laydownForm.controls['UpcomAssign_Details'].enable();
    //   // this.laydownForm.controls['UpcomAssign_POC'].enable();
    //   // this.laydownForm.controls['UpcomAssign_Phone'].enable();
    //   // this.laydownForm.controls['UpcomAssign_Start'].enable();
    //   }

    // this.laydownNextForm = this.formBuilder.group({
    //   'AssignCat_Upcom': '',
    //   'UpcomAssign_Start': '',
    //   'UpcomAssign_End': '',
    //   'UpcomAssign_Details': '',
    //   'UpcomAssign_POC': '',
    //   'UpcomAssign_Phone': '',
    // });
    this.aidForm = this.formBuilder.group({
      pad_exp: new FormControl(),
      spare_exp: new FormControl(),
      child_pad: new FormControl(),
      battery_exp: new FormControl(),
      spare_batt: new FormControl(),
      notes: new FormControl(),
      aed_acc: new FormControl(),
      first_aid: new FormControl(),
      // id: this.allFirstAidElements.id,
      // location: this.allFirstAidElements.location,
      // position: this.allFirstAidElements.position,
    });
  }

  broadcastInfo(data) {
    this.communicationServ.getInstance().next(data)
  }

  tagMini(elem) {
    let idx = this.tagMaxList.findIndex(e => e.ship == elem.ship);
    if (idx > -1) {
      this.tagMaxList.splice(idx, 1);
      this.tagMiniList.push(elem);
    }
  }

  tagMax(elem) {
    let idx = this.tagMiniList.findIndex(e => e.ship == elem.ship);
    if (idx > -1) {
      this.tagMiniList.splice(idx, 1);
      this.popupZindex += 10;
      this.tagMaxList.push(elem);
      setTimeout(() => {
        document.getElementById('tooltip_' + elem.ship).style.zIndex = this.popupZindex;
      }, 500);
    }
  }

  tagClose(elem, type = '') {
    let obj = { ship: elem, type: type };
    this.tagCurrentClose.emit(obj);
    // this.sensorPanel = false;
    // this.cameraPanel = false;
    // let idx = this.tagMaxList.findIndex(e => e.ship == elem.ship);
    // if (idx > -1) {
    //   // this.popupZindex -= this.popupZindex;
    //   this.tagMaxList.splice(idx, 1);
    // }
    // let index = this.tagMiniList.findIndex(e => e.ship == elem.ship);
    // if (index > -1) {

    //   this.tagMiniList.splice(index, 1);
    // }
    // this.broadcastInfo({ src: 'tag', event: 'closealllevel', key: elem.ship, state: null, sub: null });
  }

  iframeList = [];
  regLink(link) {
    let idx = this.iframeList.findIndex(e => e.ship == link.ship);
    if (idx > -1) {
      this.iframeList.splice(idx, 1);
      this.popupZindex += 10;
      this.iframeList.push(link);
      setTimeout(() => {
        document.getElementById('ifamepopup_' + link.ship).style.zIndex = this.popupZindex;
        document.getElementById('ifameObjTag_' + link.ship).innerHTML = '<iframe width="100%" height="100%" src="' + link.register + '"></iframe>';
      }, 500);
    } else {
      this.iframeList.push(link);
      setTimeout(() => {
        document.getElementById('ifamepopup_' + link.ship).style.zIndex = this.popupZindex;
        document.getElementById('ifameObjTag_' + link.ship).innerHTML = '<iframe width="100%" height="100%" src="' + link.register + '"></iframe>';
      }, 500);
    }
  }

  objectTagClose(elem) {
    let idx = this.iframeList.findIndex(e => e.ship == elem.ship);
    if (idx > -1) {
      // this.popupZindex -= this.popupZindex;
      this.iframeList.splice(idx, 1);
    }
    let index = this.iframeList.findIndex(e => e.ship == elem.ship);
    if (index > -1) {

      this.iframeList.splice(index, 1);
    }
  }

  renderLink(link) {
    setTimeout(() => {
      return link;
    }, 100);
  }

  expand(elem) {
    let idx = this.tagMaxList.findIndex(e => e.ship == elem.ship);
    if (idx > -1) {
      // this.popupZindex -= this.popupZindex;
      this.tagMaxList[idx].expand = !this.tagMaxList[idx].expand;
    }
  }

  triggerLevel(elem, n = null) {
    this.tagMini(elem);
    this.broadcastInfo({ src: 'tag', event: 'nextlevel', key: elem.ship, state: null, sub: null });
  }

  regEx(location) {
    // console.log(location,location.replace(/_/g, " /"))
    return location.replace(/_/g, " /").replace(/[^0-9](?=[0-9])/g, '$& ');
  }

  dateFormat(date) {
    const format = 'dd MMM, yyyy';
    // const myDate = '2019-06-29';
    const locale = 'en-US';
    const formattedDate = formatDate(date, format, locale);
    // console.log(date,formattedDate);
    return formattedDate;
  }

  getActivityColor(activity) {
    if (activity) {
      activity = activity.split(" ").join("").toLowerCase();
      let activityConfig = this.activityServ.activityConfig;
      if (activityConfig[activity]) {
        return activityConfig[activity].color
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  shipActivityStatusClose(ship, i) {
    this.activityChangedShipList.splice(i, 1);
    this.tagServ.hideMessageTag(ship);
  }

  broadcastClose() {
    this.broadcastStatus = false
  }

  getCurrentTime() {
    return this.datePipe.transform(new Date, 'HH:mm');
  }
  kioskStatusData: any;
  kioskStatusList: any;
  kioskStatusWindowState: any = false;
  selectedKStatus: any;
  selectedServiceIdx: any;
  kioskPopupWindow(data, i) {
    let idx = this.selectedId.findIndex(e => e.servicetype.uid == data.uid);
    if (idx > -1) {
      this.selectedServiceIdx = idx;
    } else {
      this.selectedServiceIdx = 0;
    }
    this.kioskStatusData = data;
    this.kioskStatusList = data.service[i];
    this.selectedKStatus = data.service[i].status;
    this.kioskStatusWindowState = true;
  }
  kioskConfirmStatus() {

  }
  broadCastPopup() {
    this.broadcastData
  }
  updateKioskStatus(id, def = false) {

    this.kioskList.jettyList.forEach((data1, idx1) => {
      // this.selectedJetty=data1.jetty;
      data1.location1.forEach((data2, idx2) => {
        data2.kiosk.forEach((data3, idx3) => {
          data3.servicetype.forEach((data4, idx4) => {
            if (data4.uid == id) {
              data4.service.forEach((data5, idx5) => {
                if (this.kioskStatusList.service == data5.service) {
                  this.kioskList.jettyList[idx1].location1[idx2].kiosk[idx3].servicetype[idx4].service[idx5].status = this.selectedKStatus;
                  this.selectedId[this.selectedServiceIdx]['servicetype'] = this.kioskList.jettyList[idx1].location1[idx2].kiosk[idx3].servicetype[idx4];
                  this.selectedId[this.selectedServiceIdx]['kiosk'] = this.kioskList.jettyList[idx1].location1[idx2].kiosk[idx3].kiosk;
                  this.selectedId[this.selectedServiceIdx]['jetty'] = this.kioskList.jettyList[idx1].jetty;
                  this.broadcastInfo({ src: 'tagUpdate', event: 'kioskData', data: this.kioskList, service: data4, state: null, sub: null });
                  this.kioskStatusWindowState = false;
                  if (def) {
                    this.onKioskStatusChange();
                  }
                  return;
                }
              });
            }
          });
        })
      })
    })
  }

  // updateKioskComment(id, comment) {
  //   this.kioskList.jettyList.forEach((data1, idx1) => {
  //     // this.selectedJetty=data1.jetty;
  //     data1.location1.forEach((data2, idx2) => {
  //       data2.kiosk.forEach((data3, idx3) => {
  //         data3.servicetype.forEach((data4, idx4) => {
  //           if (data4.uid == id) {
  //             data4.service.forEach((data5, idx5) => {
  //               if (this.kioskStatusList.service == data5.service) {
  //                 this.kioskList.jettyList[idx1].location1[idx2].kiosk[idx3].servicetype[idx4].service[idx5].comment = comment;
  //                 this.broadcastInfo({ src: 'tagUpdate', event: 'kioskData', data: this.kioskList, state: null, sub: null });
  //                 return;
  //               }
  //             });
  //           }
  //         });
  //       })
  //     })
  //   })
  // }
  getColor(status) {
    if (status) {
      var colorData = this.statusColorCode.find(element => element.status == status);
      return colorData.color;
    }
  }

  kioskPanelClose() {
    this.kioskStatusWindowState = false;
    this.showCommentPanel = false;
    this.commentReadonly = false;
    this.showCommentEditIcon = true;
  }

  tagKioskClose(ele) {
    this.tagServ.showKioskTag(ele.uid, false)
  }

  /* *
  * on change kiosk status
  * * */
  onKioskStatusChange() {
    let obj = { "kiosk": this.selectedId[this.selectedServiceIdx].kiosk, uid: this.selectedId[this.selectedServiceIdx]['servicetype']['uid'], service: this.kioskStatusList.service, servicetype: this.selectedId[this.selectedServiceIdx]['servicetype'].servicetype, jetty: this.selectedId[this.selectedServiceIdx].jetty, status: this.selectedKStatus };
    this.changeKioskStatus.emit(obj);
  }

  // selectedJetty: any;
  selectedId: any = [];
  // selectedKiosk: any;
  hoverElem: any;
  hoverSelectedJetty: any;
  hoverKiosk: any;
  selectedKioskData(data) {
    this.kioskList.jettyList.forEach(data1 => {

      data1.location1.forEach(data2 => {
        data2.kiosk.forEach(data3 => {

          data3.servicetype.forEach(key => {
            if (key.uid == data) {
              if (this.objClick) {
                // this.selectedJetty = data1.jetty;
                // this.selectedKiosk = data3.kiosk;
                if (this.selectedId.length > 0) {
                  let idx = this.selectedId.findIndex(e => e.servicetype.uid == data);
                  if (idx == -1) {
                    this.selectedId.push({ servicetype: key, kiosk: data3.kiosk, jetty: data1.jetty })
                  }
                } else {
                  this.selectedId.push({ servicetype: key, kiosk: data3.kiosk, jetty: data1.jetty })
                }


              } else {
                this.hoverSelectedJetty = data1.jetty;
                this.hoverKiosk = data3.kiosk;
                this.hoverElem = key;
              }
              return;
            }
          })
        })
      })
    })
  }

  /**
   * change cone color
   */
  changeColor(color) {
    this.hazardColor = color;
  }
  hazardStateChange(e) {
    console.log(e);
    let idx = this.hazardTypeList.findIndex(ele => ele.key == e.source._value);
    var selectedCone = "cone_" + this.hazardTypeList[idx]['label'];
    this.changeColor(selectedCone);
  }

  hazardStartDate: any = '';
  hazardendDate: any = '';
  hazardActivity: any = '';
  hazardColor: any = '';
  hazardNotes: any = '';
  setConeData() {
    if (this.selectedConeElem) {
      this.hazardEditState = (this.selectedConeElem['status'] == 'new' ? true : false);
      this.hazardActivity = this.selectedConeElem['activity'];
      this.hazardColor = this.selectedConeElem['color'];
      this.hazardNotes = this.selectedConeElem['notes'];
      this.hazardStartDate = (this.selectedConeElem.startdate == "" ? new Date() : new Date(this.selectedConeElem.startdate));
      this.hazardendDate = (this.selectedConeElem.enddate == "" ? new Date() : new Date(this.selectedConeElem.enddate))
    } else {
      this.hazardStartDate = new Date();
      this.hazardendDate = new Date()
    }
  }


  /**
   * get popup data
   */
  getFormData() {
    if (this.selectedConeElem) {
      if (this.hazardActivity == '') {
        this.openPublishDialog.emit("Please Select Activity !!");
      } else if (this.hazardStartDate == 'Invalid Date') {
        this.openPublishDialog.emit("Please Enter StartDate !!")
      } else if (this.hazardendDate == 'Invalid Date') {
        this.openPublishDialog.emit("Please Enter EndDate !!")
      } else if (this.hazardColor == '') {
        this.openPublishDialog.emit("Please Select Color !!")
      } else {
        this.hazardEditState = false;
        this.selectedConeElem['startdate'] = this.datePipe.transform(this.hazardStartDate, 'dd-MMM-yy');
        this.selectedConeElem['enddate'] = this.datePipe.transform(this.hazardendDate, 'dd-MMM-yy');
        this.selectedConeElem['activity'] = this.hazardActivity;
        this.selectedConeElem['color'] = this.hazardColor;
        this.selectedConeElem['notes'] = this.hazardNotes;
        this.selectedConeElem['status'] = "edit";
        console.log("getFormData", this.selectedConeElem);
        this.updateConeData.emit(this.selectedConeElem);
      }
    }
  }
  laydownTagClose() {
    this.tagServ.laydownPointerTag('laydownPointer', false)
    this.currentAssignment = !this.currentAssignment;
    this.isReadonly = true;
    this.ReadOnlyData = true;
    this.laydownForm.reset();
    this.broadcastInfo({ src: 'tag', event: 'activeLaydown', data: '0', kay: "" });
  }
  laydownId: any
  selectedLaydownData(e) {
    this.laydownId = e.sub.split('.')[e.sub.split('.').length - 1]
    this.tagServ.laydownPointerTag(e['data'], true)
    // this.getLaydownData();
    this.laydownDataShowId = this.laydownId;
    this.broadcastInfo({ src: 'tag', event: 'activeLaydown', data: this.laydownId, kay: "" });
    console.log("activeLaydown--", this.broadcastInfo)
  }
  laydownEditToggle() {
    this.currentAssignment = !this.currentAssignment;
    this.isReadonly = false;
    this.ReadOnlyData = false;
    this.laydownForm.enable();
  }
  laydownCancelToggle() {
    this.currentAssignment = !this.currentAssignment;
    this.isReadonly = true;
    this.ReadOnlyData = true;
    this.laydownForm.disable();
  }
  laydownNextEditToggle() {
    this.nextAssignment = !this.nextAssignment;
    this.isReadonly = false;
    this.ReadOnlyData = false;
  }
  laydownNextCancelToggle() {
    this.nextAssignment = !this.nextAssignment;
    this.isReadonly = true;
    this.ReadOnlyData = true;
  }
  onSubmit() {
    console.log("Current Form Data1", this.laydownForm.value);
    // console.log("Current Form Data", this.laydownNextForm.value);
    this.currentAssignment = !this.currentAssignment;
    this.isReadonly = true;
    this.ReadOnlyData = true;
    var laydownId = 'C1-001'


    var formData: any = new FormData();
    formData.append("AssignCat_Curr", this.laydownForm.get('AssignCat_Curr').value);
    formData.append("CurrAssign_Start", this.laydownForm.get('CurrAssign_Start').value);
    formData.append("CurrAssign_End", this.laydownForm.get('CurrAssign_End').value);
    formData.append("Notes", this.laydownForm.get('Notes').value);
    formData.append("Indefinite", this.laydownForm.get('Indefinite').value);
    formData.append("CurrAssign_POC", this.laydownForm.get('CurrAssign_POC').value);
    formData.append("CurrAssign_Phone", this.laydownForm.get('CurrAssign_Phone').value);
    formData.append("AssignCat_Upcom", this.laydownForm.get('AssignCat_Upcom').value);
    formData.append("UpcomAssign_Start", this.laydownForm.get('UpcomAssign_Start').value);
    formData.append("UpcomAssign_End", this.laydownForm.get('UpcomAssign_End').value);
    formData.append("UpcomAssign_Details", this.laydownForm.get('UpcomAssign_Details').value);
    formData.append("UpcomAssign_POC", this.laydownForm.get('UpcomAssign_POC').value);
    formData.append("UpcomAssign_Phone", this.laydownForm.get('UpcomAssign_Phone').value);

    //   });
    // this.configService.getLaydownDatas(laydownId)
    // .subscribe(
    //   (response) => {
    //     console.log('response received')
    //     var repos = response;
    //     console.log("datat2222 : ", repos);
    //     this.laydownData = repos[0];
    //   },
    //   (error) => {
    //     console.error('Request failed with error')
    //     // this.errorMessage = error;
    //     // this.loading = false;
    //   })
    var laydownId = 'C1-001'
    this.configService.addLaydownDatas(this.laydownDataShowId, this.laydownForm.value)
      .subscribe(data => {
        // console.log("retruen data ", data)
        // this.laydownTagClose()
        this.tagServ.laydownPointerTag('laydownPointer', false)
        // return data.status
        var status = data.status
        if (status === '200') {
          console.log('test121312')
          this.broadcastInfo({ src: 'tag', event: 'updateLaydown', data: 'success', kay: "" });
        }
      }, error => {
        if (error.status === '200') {
          this.broadcastInfo({ src: 'tag', event: 'updateLaydown', data: 'success', kay: "" });
        }
        // console.error('There was an error!');

      }
      )
    this.broadcastInfo({ src: 'tag', event: 'updateLaydown', data: 'success', kay: "" });
    // console.log("123", )
    this.laydownForm.disable();
  }
  onSubmitNextLaydown() {
    console.log("Current Form Data", this.laydownNextForm.value);
  }


  // getLaydownData() {
  //   // var LaydownData = [{"_id":"C1-001","AssignCat_Curr":"test","CurrAssign_POC":"CPO1 Bisal - Ship Coxn","CurrAssign_Phone":"3-5959","CurrAssign_Start":"2018-06-05T01:04:00.000Z","CurrAssign_End":"2020-01-24T05:09:00.000Z","CurrAssign_Details":"HMCS REGINA","AssignCat_Upcom":"assign","UpcomAssign_POC":"","UpcomAssign_Phone":"","UpcomAssign_Start":"1970-01-01T00:00:00.000Z","UpcomAssign_End":"1970-01-01T00:00:00.000Z","UpcomAssign_Details":"upcom details","C-JettyArea":"C1","Notes":"notes","DateModified":"2021-06-02T10:17:30.839Z","TimeModified":"10:17","Indefinite":"on"}];
  //   // console.log("datas ---- ", LaydownData)
  //   // this.laydownData = LaydownData[0];

  //   // this.ConfigService.getCustomers().subscribe((res)=>{
  //   //   this.ConfigService.getCustomers(this.ConfigService.nextPage).subscribe((res)=>{
  //   //     console.log(res.body);
  //   var laydownId = 'C1-001'
  //   console.log("jlkasjdl", this.laydownDataShowId)
  //   this.configService.getLaydownDatas(this.laydownDataShowId)
  //     .subscribe(
  //       (response) => {
  //         var repos = response;
  //         console.log("Laydown data : ", repos);
  //         if (repos.length !== 0) {
  //           this.laydownData = repos[0];
  //           console.log("laydownData condition---", this.laydownData);
  //         }
  //         else {
  //           console.log("laydownData condition***", this.laydownData);
  //         }

  //       },
  //       error => {
  //         console.error('Request failed with error')
  //         console.log(error)
  //         // this.errorMessage = error;
  //         // this.loading = false;
  //       })


  // }


  kisokServiceDatas: boolean = false;
  showCommentPanel: boolean = false;
  showCommentStatus: boolean = false;
  commentReadonly = true;
  showCommentEditIcon = true;
  showCommentPanelOpenHandel() {
    this.commentReadonly = true;
    this.showCommentPanel = !this.showCommentPanel;
  }

  updateKioskComment(id, def = false) {
    // this.commentDatas = x;
    console.log("uid : ", id)
    this.kioskList.jettyList.forEach((data1, idx1) => {
      // this.selectedJetty=data1.jetty;
      data1.location1.forEach((data2, idx2) => {
        data2.kiosk.forEach((data3, idx3) => {
          data3.servicetype.forEach((data4, idx4) => {
            if (data4.uid == id) {
              data4.service.forEach((data5, idx5) => {
                if (this.kioskStatusList.service == data5.service) {
                  this.kioskList.jettyList[idx1].location1[idx2].kiosk[idx3].servicetype[idx4].service[idx5].comment = this.commentDatas;
                  this.broadcastInfo({ src: 'tagUpdate', event: 'kioskData', data: this.kioskList, state: null, sub: null });
                  this.commentDatas = ''
                  return;
                }
              });
            }
          });
        })
      })
    })


    this.showCommentPanel = false;
    this.showCommentStatus = true;

    this.showCommentEditIcon = true;
    setInterval(() => {
      this.showCommentStatus = false;
    }, 1000);
  }
  commentDataCancel() {
    this.commentDatas = ''
    this.showCommentPanel = false;
    this.showCommentEditIcon = true;
  }
  commentDataEdit(data) {
    this.commentDatas = data;
    this.showCommentEditIcon = false;
    this.commentReadonly = false;
  }
  brodcastShow(id) {
    delete this.broadcastData;
    this.broadcastInfo({ src: 'main', event: 'showKioskId', data: id, state: null, sub: null });
  }
  brosdcastPanelClose() {
    delete this.broadcastData;
  }

  hazardEditState: any = false;
  deleteConfirmPopup: any = false;
  positionConfirmHazardPopup: any = false;
  positionConfirmFirstAidPopup: any = false;
  editHazard() {
    this.hazardEditState = true
  }
  deleteHazard() {
    this.deleteConfirmPopup = true;
  }
  deleteHazardClose() {
    this.deleteConfirmPopup = false;
  }
  deleteHazardData(data) {
    this.deleteConfirmPopup = false;
    this.broadcastInfo({ src: 'tagHazard', event: 'deleteHazard', data: data, state: null, sub: null });
  }
  /**
 * close cone popup
 */
  conePopupClose() {
    this.hazardEditState = false;
    this.tagServ.showHazardTag(this.selectedConeElem['_id'], false);
    delete this.selectedConeElem;
    this.broadcastInfo({ src: 'tagHazard', event: 'unSelectHazard', data: null, state: null, sub: null });
  }
  editHazardPopupClose() {
    this.hazardEditState = false;
    if (this.selectedConeElem) {
      if (this.selectedConeElem['status'] == 'new') {
        this.removeCone.emit();
      }
      if (!this.tagServ.selectedHazardTag) {
        delete this.selectedConeElem;
        this.broadcastInfo({ src: 'tagHazard', event: 'unSelectHazard', data: null, state: null, sub: null });
      }
    }
  }
  getHazardName(activity) {
    let item = this.hazardTypeList.find(el => el.key === activity);
    if (item) {
      return item['name'];
    } else {
      return '-';
    }
  }

  handleClear(reset) {
    if (reset === 'padexp') {
      this.aidForm.get('pad_exp').reset();
    }
    if (reset === 'sparepad') {
      this.aidForm.get('spare_exp').reset();
    }
    if (reset === 'childpad') {
      this.aidForm.get('child_pad').reset();
    }
    if (reset === 'batteryexp') {
      this.aidForm.get('battery_exp').reset();
    }
    if (reset === 'sparebattery') {
      this.aidForm.get('spare_batt').reset();
    }
  }
  editFirstAid() {
    this.firstAidEdit = !this.firstAidEdit;
  }
  firstAidEditTagClose() {
    this.firstAidEdit = !this.firstAidEdit;
  }
  firastAidDate() {
    this.monthDiff();
  }
  padexpDate(date: any) {
    this.padexpDateValues = date.value;
    this.monthDiff();
  }
  sparepadDate(date: any) {
    this.sparepadDateValues = date.value;
    this.monthDiff();
  }
  childpadDate(date: any) {
    this.childpadDateValues = date.value;
    this.monthDiff();
  }
  batteryexpDate(date: any) {
    this.batteryexpDateValues = date.value;
    this.monthDiff();
  }
  sparebatteryDate(date: any) {
    this.sparebatteryDateValues = date.value;
    this.monthDiff();
  }
  monthDiff() {
    var currentDate = new Date();
    var padexp = this.padexpDateValues;
    var sparepad = this.sparepadDateValues;
    var childpad = this.childpadDateValues;
    var batteryexp = this.batteryexpDateValues;;
    var sparebattery = this.sparebatteryDateValues;
    // var padexp = new Date(this.aidForm.get('pad_exp').value);
    // var sparepad = new Date(this.aidForm.get('spare_exp').value);
    // var childpad = new Date(this.aidForm.get('child_pad').value);
    // var batteryexp = new Date(this.aidForm.get('battery_exp').value);
    // var sparebattery = new Date(this.aidForm.get('spare_batt').value);
    // var padexpAid = this.padexpDateValues;
    // var sparepadAid = new Date("this.allFirstAidElements.spare_exp");
    // var childpadAid = new Date("this.allFirstAidElements.child_pad");
    // var batteryexpAid = new Date("this.allFirstAidElements.battery_exp");
    // var sparebatteryAid = new Date("this.allFirstAidElements.spare_batt");
    var padexpDate;
    var sparepadDate;
    var childpadDate;
    var batteryexpDate;
    var sparebatteryDate;
    padexpDate = (padexp.getFullYear() - currentDate.getFullYear()) * 12;
    padexpDate -= currentDate.getMonth();
    padexpDate += padexp.getMonth();
    sparepadDate = (sparepad.getFullYear() - currentDate.getFullYear()) * 12;
    sparepadDate -= currentDate.getMonth();
    sparepadDate += sparepad.getMonth();
    childpadDate = (childpad.getFullYear() - currentDate.getFullYear()) * 12;
    childpadDate -= currentDate.getMonth();
    childpadDate += childpad.getMonth();
    batteryexpDate = (batteryexp.getFullYear() - currentDate.getFullYear()) * 12;
    batteryexpDate -= currentDate.getMonth();
    batteryexpDate += batteryexp.getMonth();
    sparebatteryDate = (sparebattery.getFullYear() - currentDate.getFullYear()) * 12;
    sparebatteryDate -= currentDate.getMonth();
    sparebatteryDate += sparebattery.getMonth();
    this.padexpDateValue = padexpDate <= 0 ? 0 : padexpDate;
    this.sparepadDateValue = sparepadDate <= 0 ? 0 : sparepadDate;
    this.childpadDateValue = childpadDate <= 0 ? 0 : childpadDate;
    this.batteryexpDateValue = batteryexpDate <= 0 ? 0 : batteryexpDate;
    this.sparebatteryDateValue = sparebatteryDate <= 0 ? 0 : sparebatteryDate;
    console.log("months1", this.padexpDateValue)
    console.log("months2", this.sparepadDateValue)
    console.log("months3", this.childpadDateValue)
    console.log("months4", this.batteryexpDateValue)
    console.log("months5", this.sparebatteryDateValue)
  }
  firstAidTagClose() {
    // this.selectedLocationObj = !this.selectedLocationObj;
    this.tagServ.locationPointerTag(null, false);
    this.broadcastInfo({ src: 'tag', event: 'unSelectedFirstAid', data: '', key: "" });
  }
  onSubmitAid() {
    console.log("Current Form Aid Data1");
    console.log("Current Form Aid Data1", this.aidForm.value);
    // this.allFirstAidElements.aed_acc = ( this.aidForm.value['aed_acc'] ? "Yes" : "No");
    // this.allFirstAidElements.first_aid =  (this.aidForm.value['first_aid'] ? "Yes" : "No");
    var data = this.aidForm.value;
    var date1 = this.aidForm.get('battery_exp').value;
    var date2 = this.aidForm.get('child_pad').value;
    var date3 = this.aidForm.get('pad_exp').value;
    var date4 = this.aidForm.get('spare_batt').value;
    var date5 = this.aidForm.get('spare_exp').value;
    console.log("erord", this.aidForm.get('child_pad').value)
    if (date1 == 'Invalid Date') {
      date1 = '';
    } else {
      date1 = this.datePipe.transform(this.aidForm.get('battery_exp').value, 'dd-MMM-yy');
    }
    if (date2 == 'Invalid Date') {
      date2 = '';
    } else {
      date2 = this.datePipe.transform(this.aidForm.get('child_pad').value, 'dd-MMM-yy');
    }
    if (date3 == 'Invalid Date') {
      date3 = '';
    } else {
      date3 = this.datePipe.transform(this.aidForm.get('pad_exp').value, 'dd-MMM-yy');
    }
    if (date4 == 'Invalid Date') {
      date4 = '';
    } else {
      date4 = this.datePipe.transform(this.aidForm.get('spare_batt').value, 'dd-MMM-yy');
    }
    if (date5 == 'Invalid Date') {
      date5 = '';
    } else {
      date5 = this.datePipe.transform(this.aidForm.get('spare_exp').value, 'dd-MMM-yy');
    }
    let updateFirstAid = {
      _id: this.allFirstAidElements._id,
      aed_acc: this.allFirstAidElements.aed_acc,
      first_aid: this.allFirstAidElements.first_aid,
      barcode: this.allFirstAidElements.barcode,
      battery_exp: date1,
      child_pad: date2,
      group: this.allFirstAidElements.group,
      id: this.allFirstAidElements.id,
      location: this.allFirstAidElements.location,
      notes: this.aidForm.get('notes').value,
      pad_exp: date3,
      position: {
        x: this.allFirstAidElements.position.x,
        y: this.allFirstAidElements.position.y,
        z: this.allFirstAidElements.position.z
      },
      serial: this.allFirstAidElements.serial,
      spare_batt: date4,
      spare_exp: date5,
    };
    this.firstAidEdit = !this.firstAidEdit;
    this.broadcastInfo({ src: 'tag', event: 'updateFirstAidData', data: updateFirstAid, key: '', state: null, sub: null });
  }
  CommsPopupClose() {
    this.broadcastInfo({ src: 'tag', event: 'comms', data: false });
    this.commsConsole = false;
  }
  getDateFormat(date) {
    if (date) {
      return this.datePipe.transform(date, 'MMMM d, y');
    } else {
      return "";
    }

  }
  cancelHazardPosition() {
    this.positionConfirmHazardPopup = false;
    this.broadcastInfo({ src: 'tag', event: 'updatePositionHazaed', data: this.selectedConeElem, key: false, state: null, sub: null });
  }
  confirmHazardPosition() {
    this.positionConfirmHazardPopup = false;
    this.broadcastInfo({ src: 'tag', event: 'updatePositionHazaed', data: this.selectedConeElem, key: true, state: null, sub: null });
  }
  unknowActivityPanelClose() {
    delete this.unknownActivity;
    this.unknowActivityState = false;
  }
  detailPanel(ship) {
    this.handleShipDetailToggleOpen = !this.handleShipDetailToggleOpen;
    this.broadcastInfo({ src: 'tag', event: 'updateDetailPanel', data: ship, key: this.handleShipDetailToggleOpen });
  }
  scsMaterialViewModel: boolean = false;
  scsNscViewModel: boolean = false;
  scsMaterialViewModelMaximizeTag: boolean = false;
  scsNscViewModelMaximizeTag: boolean = false;
  scsViewModelTagClose(e) {

    if (e == 'nsc') {
      this.scsNscViewModel = false;
    }
    if (e == 'material') {
      this.scsMaterialViewModel = false;
    }
    // this.broadcastInfo({ src: 'tag', event: 'updateScsPanel', data: this.scsViewModel, key: true, state: null, sub: null });
  }
  scsViewModelMinimize(e) {
    if (e == 'nsc') {
      this.scsNscViewModelMaximizeTag = true;
      this.scsNscViewModel = false;
    }
    if (e == 'material') {
      this.scsMaterialViewModelMaximizeTag = true;
      this.scsMaterialViewModel = false;
    }
    // this.scsViewModelMaximizeTag = !this.scsViewModelMaximizeTag;
    // this.scsViewModel = !this.scsViewModel;
  }
  scsViewModelMaximize(e) {
    if (e == 'nsc') {
      this.scsNscViewModelMaximizeTag = !this.scsNscViewModelMaximizeTag;
      this.scsNscViewModel = !this.scsNscViewModel;
    }
    if (e == 'material') {
      this.scsMaterialViewModelMaximizeTag = !this.scsMaterialViewModelMaximizeTag;
      this.scsMaterialViewModel = !this.scsMaterialViewModel;
    }
  }
  scsViewModelMaximizeTagClose(e) {
    if (e == 'nsc') {
      this.scsNscViewModelMaximizeTag = false;
      this.scsNscViewModel = false;
    }
    if (e == 'material') {
      this.scsMaterialViewModelMaximizeTag = false;
      this.scsMaterialViewModel = false;
    }
  }
  cancelFirstAidPosition() {
    this.positionConfirmFirstAidPopup = false;
    this.broadcastInfo({ src: 'tag', event: 'updatePositionFirstAid', data: this.selectedLocationObj, key: false, state: null, sub: null });
  }
  confirmFirstAidPosition() {
    this.positionConfirmFirstAidPopup = false;
    this.broadcastInfo({ src: 'tag', event: 'updatePositionFirstAid', data: this.selectedLocationObj, key: true, state: null, sub: null });
  }
  riskManagePanelClose() {
    this.riskMangState = false;
  }
  chitBroadcastClose() {
    this.chitBroadcastStatus = !this.chitBroadcastStatus;
  }
  fmfRoofCancel() {
    this.roofConfirm = false;
  }
  fmfRoofOn() {
    this.roofConfirm = false;
    this.broadcastInfo({ src: 'tag', event: 'fmfRoofState', data: this.roofConfirmData });
  }
  hazardPopupClose() {
    this.harzardAreaCreate = false;
  }
  aidClose() {
    this.aidCreate = false;
  }

  /***
   * Get Date format like "23 OCT, 12:30"
   */
  getDateTime(date) {
    return this.datePipe.transform(date, 'dd MMM, hh:mm');
  }

  /***
   * Get user Avatar 
   */
  getAvatar(name) {
    let FLname = name.split(' ');
    let avatar = FLname.length > 1 ? FLname[0].substring(0, 1).toUpperCase() + FLname[1].substring(0, 1).toUpperCase() : name.substring(0, 1).toUpperCase();
    return avatar;
  }

  commentTagShowHide(state) {
    if (this.khaConfig.dragVesselComment.length > 0) {
      this.khaConfig.dragVesselComment.forEach(e => {
        console.log('commentTagShowHide', e)
        this.tagServ.showCommentTag(e, state);
      })
    }
  }

  /***
   * Get user Avatar 
   */
  getUserAvatar(user) {
    let avatar = user['First Name'].substring(0, 1).toUpperCase() + user['Last Name'].substring(0, 1).toUpperCase()
    return avatar;
  }

  /***
   * Get user name 
   */
  getUserName(user) {
    let name = user['First Name'] + ' ' + user['Last Name'];
    return name;
  }

  /***
   * User Select
   */
  userSelect(user) {
    if (this.commentValue.search('@') > -1) {
      if (this.mentionedUserList.length == 0) {
        let pos = this.commentValue.indexOf('@');
        let rmMsg = this.commentValue.substring(0, pos - 1);
        let username = '@' + user['First Name'] + '_' + user['Last Name'] + ' ';
        // let userElem = '<span>'+user['First Name']+' '+user['Last Name']+'</span>';
        this.commentValue = rmMsg + ' ' + username;
        // this.commentMsgSend = rmMsg+' '+userElem;
        this.userListState = false;
        this.mentionedUserList.push(this.commentValue);
      } else {
        let countValue = 0;
        let pos = this.commentValue.indexOf('@');
        var lastIndex = this.getLastString(pos, countValue);
        // var lastIndex = this.getLastString(pos,countValue);
        let rmMsg = this.commentValue.substring(0, lastIndex - 1);
        // let rmElmMsg = this.commentValue.substring(0,lastIndex-1);
        let username = '@' + user['First Name'] + '_' + user['Last Name'] + ' ';
        // let userElem = '<span>'+user['First Name']+' '+user['Last Name'];
        this.commentValue = rmMsg + ' ' + username;
        // this.commentMsgSend = rmMsg+' '+userElem;
        this.userListState = false;
        this.mentionedUserList.push(this.commentValue);
      }
      document.getElementById("text-message").focus();
    }
  }

  /**
   * 
   * User @ get user list
   */
  userListState: any = false;
  mentionedUserList: any = [];
  keyupData(e) {
    console.log(e, this.commentValue);
    if (this.commentValue == "") {
      this.userListState = false;
    } else {
      if (this.mentionedUserList.length == 0) {
        if (this.commentValue.search('@') > -1) {
          console.log(this.commentValue.search('@'), this.commentValue.length, this.commentValue.substring(this.commentValue.search('@') + 1, this.commentValue.length));
          let searchKey = this.commentValue.substring(this.commentValue.search('@') + 1, this.commentValue.length);
          this.userListState = true;
          this.updateUserList = this.userList;
          if (searchKey !== '') {
            this.userFilter(searchKey);
          }
        }
      } else {
        var count = (this.commentValue.match(/@/g) || []).length;
        if (this.mentionedUserList.length < count) {
          let countValue = 0;
          let pos = this.commentValue.indexOf('@');
          var lastIndex = this.getLastString(pos, countValue);
          let searchKey = this.commentValue.substring(lastIndex + 1, this.commentValue.length);
          this.userListState = true;
          this.updateUserList = this.userList;
          if (searchKey !== '') {
            this.userFilter(searchKey);
          }
        } else {
          // if (this.commentValue.search('@') > -1) {
          //   console.log(this.commentValue.search('@'),this.commentValue.length,this.commentValue.substring(this.commentValue.search('@')+1,this.commentValue.length));
          //   let searchKey = this.commentValue.substring(this.commentValue.search('@')+1,this.commentValue.length);
          //   this.userListState = true;
          //   this.updateUserList = this.userList;
          //   if(searchKey!==''){
          //     this.userFilter(searchKey);
          //   }
          // }
          let countValue = 0;
          let pos = this.commentValue.indexOf('@');
          var lastIndex = this.getLastString(pos, countValue);
          let searchKey = this.commentValue.substring(lastIndex + 1, this.commentValue.length);
          this.userListState = true;
          this.updateUserList = this.userList;
          if (searchKey !== '') {
            this.userFilter(searchKey);
          }
        }
      }
    }

  }

  getLastString(pos, count) {
    while (pos > -1) {
      ++count;
      let posData = this.commentValue.indexOf('@', ++pos);
      var posEnable = posData == -1 ? pos : posData;
      pos = posData;
    }
    return posEnable;
  }
  getElemLastString(pos, count) {
    while (pos > -1) {
      ++count;
      let posData = this.commentMsgSend.indexOf('@', ++pos);
      var posEnable = posData == -1 ? pos : posData;
      pos = posData;
    }
    return posEnable;
  }
  userFilter(key) {
    let filterData = this.userList.filter(e => (((e['First Name'] + ' ' + e['Last Name']).toLocaleLowerCase()).includes(key.toLocaleLowerCase())));
    this.updateUserList = filterData;
  }
  /**
   * 
   * Comments input show
   */
  // commentId:any;
  groupList:any=[{id:"e-123", name:'CSC',color:"red"},
  {id:"e-124", name:'AOPS',color:"green"},
  {id:"e-125", name:'Halifax',color:"blue"},
  {id:"e-126", name:'IceBreaker',color:"yellow"},
  {id:"e-127", name:'AOPS CCG',color:"black"}]
  commentVessel(item) {
    this.scrollToBottom();
    this.tagServ.commentId = item.name;
    this.khaConfig.commentData = item;
    this.tagServ.commentData = item;
    this.khaConfig.urls = [];
  }
  getElemMsg() {
    let textData = this.commentValue;
    let pos = this.commentValue.indexOf('@');
    if (pos > -1) {
      while (pos > -1) {
        let spacePos = this.commentValue.indexOf(' ');
        while (spacePos > -1) {
          if (pos < spacePos) {
            console.log(this.commentValue.substring(pos, spacePos), ',', pos, ',', spacePos);
            let rmStartMsg = this.commentValue.substring(0, pos);
            let rmEndMsg = this.commentValue.substring(spacePos + 1, this.commentValue.length)
            let userName = this.commentValue.substring(pos + 1, spacePos);
            let userFirst = userName.split('_')[0];
            let idx = this.userList.findIndex(e => e['First Name'].toLowerCase() == userFirst.toLowerCase());
            if (idx > -1) {
              let user = this.userList[idx];
              let userElem = rmStartMsg + ` ` + `<span style="'color:blue;font-weight:500;font-size:14px;'" class="highlight">` + user['First Name'] + ` ` + user['Last Name'] + `</span>` + ` ` + rmEndMsg;
              this.commentValue = userElem;
              console.log(this.commentValue);
              this.getElemMsg();
            }

          }
          spacePos = this.commentValue.indexOf(' ', ++spacePos);
        }
        pos = this.commentValue.indexOf('@', ++pos);
      }
    }
  }
  /***
   * Comment send
   */
  commentValue: any;
  commentMsgSend: any;
  userCommentSave() {
    if(this.commentValue){
      var elemMsg = this.getElemMsg();
    }else{
      this.commentValue='';
    }
    
    this.commentValue = `<p>` + this.commentValue + `</p>`;
    let idx = this.userList.findIndex(e => e.email == this.configService.accountEmail);
    var commentData = { name: "", email: this.configService.accountEmail, date: new Date(), comment: this.commentValue, attachmentList:[] }
    if (idx > -1) {
      commentData['name'] = this.userList[idx]['First Name'] + ' ' + this.userList[idx]['Last Name'];
    }
    commentData['name'] = commentData['name'] ? commentData['name'] : 'Unknow User';
    commentData['attachmentList'] = this.khaConfig.urls;
    this.khaConfig.commentData.commentList.push(commentData);
    delete this.commentValue;
    delete this.commentMsgSend;
    this.scrollToBottom();
    this.mentionedUserList = [];
    this.khaConfig.urls = [];
  }
  closeComments() {
    if (this.khaConfig.commentData) {
      this.tagServ.showCommentTag(this.khaConfig.commentData, true);
      delete this.khaConfig.commentData;
      delete this.tagServ.commentData;
      delete this.tagServ.commentId;

    }
  }

  attachmentFile:any=[];
  openAttachment(){
    document.getElementById('getFile').click();
  }
  // urls = [];
  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
        var filesAmount = event.target.files.length;
        for (let i = 0; i < filesAmount; i++) {
                var reader = new FileReader();

                reader.onload = (event:any) => {
                  console.log(event.target.result);
                   this.khaConfig.urls.push(event.target.result); 
                }

                reader.readAsDataURL(event.target.files[i]);
        }
    }
  }
  removeAttach(idx){
    this.khaConfig.urls.splice(idx,1);
  }

  selectedAttach(url){
    this.selectedUrl = url;
  }

  selectedUrl:any;
  closePreview(){
    delete this.selectedUrl;
  }

  selectGroup(group,name){
    let idx = this.khaConfig.dragVesselComment.findIndex(e=> e.name == name);
    if(idx>-1){
      this.khaConfig.dragVesselComment[idx]['group']=group;
      this.khaConfig.commentData = this.khaConfig.dragVesselComment[idx];
      this.tagServ.commentData = this.khaConfig.dragVesselComment[idx];
    }
  }
  resolveComplete(e,name){
    console.log(e);
    let idx = this.khaConfig.dragVesselComment.findIndex(e=> e.name == name);
    if(idx>-1){
      this.khaConfig.dragVesselComment[idx]['status']=e.checked;
      this.khaConfig.commentData = this.khaConfig.dragVesselComment[idx];
      this.tagServ.commentData = this.khaConfig.dragVesselComment[idx];
    }
  }
}