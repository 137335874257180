import { Injectable } from '@angular/core';
import { CommunicationService } from "../services/communication.service";
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
// import { KHASystemService } from '../services/systems/kha-systems.service';

const constTagWidth = 0;//-25; // 1555 - window.innerWidth;
const constTagHeight = 0;//25; // 815 - window.innerHeight;
const constPopupWidth = -145; // 1555 - window.innerWidth;
const constPopupHeight = 95; // 815 - window.innerHeight;
const constMessageWidth = -145; // 1555 - window.innerWidth;
const constMessageHeight = 40; // 815 - window.innerHeight;
// const constKisokTagWidth = -145;//-25; // 1555 - window.innerWidth;
// const constKisokTagHeight = -700;//25; // 815 - window.innerHeight;
const constKisokTagWidth = -285 / 2;
const constKisokTagHeight = 60;
const constKisokToolWidth = -250 / 2;
const constKisokToolHeight = 40;
// const constKisokTagWidth = 800 - window.innerWidth;
// const constKisokTagHeight = 339 - window.innerHeight;
const constLaydownTagWidth = -535 / 2;
const constLaydownTagHeight = 310;
const constLocationTagWidth = -290 / 2;
const constLocationTagHeight = 340;

const constHazardTagWidth = -214 / 2;
const constHazardTagHeight = 185;
const constLabelWidth = 142; // 1555 - window.innerWidth;
const constLabelHeight = 75; // 815 - window.innerHeight;
const constDfxLabelWidth = 90; // 1555 - window.innerWidth;
const constDfxLabelHeight = 75; // 815 - window.innerHeight;

const constChatLabelWidth = 36; // 1555 - window.innerWidth;
const constChatLabelHeight = 33;

const constCommentWidth = 10; // 1555 - window.innerWidth;
const constCommentHeight = -20; // 815 - window.innerHeight;

@Injectable({
  providedIn: 'root'
})

export class TagService {

  kioskElement: any = {}
  dfx: any;
  datauxview: any;
  pickElement: boolean = true;
  selTagElement: any = '';
  selStaticObjTag: any = '';
  hoverTagElement: any = "";
  multipleTagStatus: boolean = false;
  tagStatus: any; // showAll, showClicked
  isMessageTag: boolean = false;
  isDragmove: boolean = false;
  activeFalgList: any = [];
  activePop: any = null;
  activeTagList: any = [];
  tagintervalId: any;
  popintervalId: any;
  cfbport: any = 'cfbhalifax';
  cfb_externalport: any = '';
  cfbstatic_obj: any;
  cfbstatic_ships: any = [
    { name: "ship_vis3", "c_name": "VIS2" },
    { name: "ship_vis2", "c_name": "VIS" },
    { name: "ship_csc3", "c_name": "CSC" }
  ];
  layoutData = [];
  // cfbstatic_obj: any = ['sackville_demo', 'sackville2', 'tug', 'tug2', 'vcsub', 'kingston', 'orca', 'rhib', 'rhib_p', 'rhib_q', 'rhib_p_1']
  cfbstatic_obj_hfx: any = ['sackville_demo', 'tug', 'tug2', 'halifax_demo']
  cfbstatic_obj_esq: any = ['tug', 'tug2', 'halifax_demo2', , 'ship_csc3']
  cfbexternal_location: any = ['NN', 'NA'];
  cfbstatic_pos_hfx: any = {
    'tug': {
      position: {
        "x": -1703.35,
        "y": 0,
        "z": 1378
      },
      orientation: {
        "x": 0,
        "y": -95,
        "z": 0
      }
    },
    'tug2': {
      position: {
        "x": -1725.589,
        "y": 0,
        "z": 1418.5
      },
      orientation: {
        "x": 0,
        "y": -120,
        "z": 0
      }
    },

  }
  cfbstatic_pos_esq: any = {
    'tug': {
      position: {
        "x": -180,
        "y": 1,
        "z": -138
      },
      orientation: {
        "x": 0,
        "y": -67,
        "z": 0
      }
    },
    'tug2': {
      position: {
        "x": -187,
        "y": 1,
        "z": -152
      },
      orientation: {
        "x": 0,
        "y": -67,
        "z": 0
      }
    },

  }
  showHideTagPointer: any = [];
  showHideStaticTagPointer: any = [];
  layoutTag: any;

  selectedTooltipTag: any = '';
  selectedPopupTag: any = '';
  selectedStaticPopupTag: any = '';
  locationList: any;
  constructor(private communicationServ: CommunicationService,
    // private khaConfig:KHASystemService
  ) { }

  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }

  /* * * *
  * init dataux instance
  * * * */
  init(_datauxview) {
    this.datauxview = _datauxview;
    this.dfx = _datauxview.getDatascape();
  }

  /* * * *
  * set port config
  * * * */
  setPortConfig(port) {
    this.cfbport = port;
  }

  /* * * *
  * set external port config
  * * * */
  setExternalPortConfig(port) {
    this.cfb_externalport = port;
  }

  /* * * *
  * show/hide static ship objects
  * * * */
  toggleStaticObj(show) {
    this.cfbstatic_obj = this.cfbport === 'cfbhalifax' ? this.cfbstatic_obj_hfx : this.cfbstatic_obj_esq
    this.cfbstatic_obj.forEach(element => {
      let el = this.datauxview.getElementId(element);
      if (el) {
        if (show) {
          this.dfx.modify(el, { profile: 'regular' });
        } else {
          this.unselectStaticObjTagElement();
          this.dfx.modify(el, { profile: 'invisible' });
        }
      }
    });
  }

  /* * * *
  * config multiple tage properties
  * * * */
  configMutiTagProperties(show) {
    if (show) {
      this.tagintervalId = setInterval(() => { this.hideActiveTag(); }, 500);
    } else {
      clearInterval(this.tagintervalId);
    }
  }

  setStaticShipMultiTag(flag) {
    this.cfbstatic_ships.forEach(ship => {
      let el = this.datauxview.getElementId(ship.name);
      if (flag) {
        this.addObserver(el);
      } else {
        // existing code display block
        // let dom = document.getElementById('tooltip_' + ship.name);
        // if (dom) {
        //   this.activeTagList = [];
        //   dom.style.display = "none";
        //   this.removeObserver(el);
        // }

        // new code ngIf
        this.activeTagList = [];
        this.selectedTooltipTag = '';
        this.removeObserver(el);
      }
    });
  }

  /* * * *
  * set properties for show multiple tags if lable control is on
  * * * */
  // RAJ-COMMENT: reorganize as discused
  setMultiTagProperties(flag, element) {
    this.multipleTagStatus = flag;
    this.setStaticShipMultiTag(flag)
    let el = this.datauxview.getElementId(element);
    if (this.multipleTagStatus) {
      const found = this.activeTagList.some(el => el === element);
      if (!found) {
        this.activeTagList.push(element);
      }
      this.addObserver(el);
    } else {
      // existing code display block
      // let dom = document.getElementById('tooltip_' + element);
      // if (dom) {
      //   this.activeTagList = [];
      //   dom.style.display = "none";
      //   this.removeObserver(el);
      // }

      // new code ngIf
      this.activeTagList = [];
      this.selectedTooltipTag = '';
      this.removeObserver(el);
    }
  }

  /* * * *
  * show only selected tag if lable control is on
  * * * */
  setTagProperties(element, flag) {
    let el = this.datauxview.getElementId(element);
    if (flag) {
      this.toggleStaticObj(false);
      this.selTagElement = element;
      this.selectedPopupTag = element;
      this.hideTag(element);
      this.addObserver(el);
      this.popintervalId = setInterval(() => { this.hideActivePop(); }, 500);
    } else {
      // let dom = document.getElementById('popup_' + element);
      // if (dom) {
      //   dom.style.display = "none";
      //   this.removeObserver(el);
      // }

      // this.selectedPopupTag = '';
      this.removeObserver(el);
    }
  }

  /* * * *
  * Hide the current active popup
  * * * */
  hideActiveTag() {
    // this.activeTagList.forEach(el => {
    //   let dom = document.getElementById('tooltip_' + el);
    //   if (dom) {
    //     if (dom.style.display === "block") {
    //       dom.style.display = "none";
    //     }
    //   }
    // });
    this.selectedTooltipTag = '';
  }

  /* * * *
  * Hide the current active popup
  * * * */
  hideActivePop() {
    if (this.activePop && this.activePop.style.display === "block") {
      this.activePop.style.display = "none";
    }
  }

  /* * * *
  * hide simple tag element
  * * * */
  hideTag(element) {
    // existing code display block
    // let tooltip = document.getElementById('tooltip_' + element);
    // if (tooltip) {
    //   tooltip.style.display = "none";
    // }

    // new code ngIf
    this.selectedTooltipTag = '';
  }
  /* * * *
  * hide simple tag element
  * * * */
  showTag(element, x, y) {
    // let pop = document.getElementById('tooltip_' + element);
    // if (pop) {
    //   pop.style.left = (Number(x) + constTagWidth) + 'px';
    //   pop.style.bottom = (Number(y) + constTagHeight) + 'px';
    //   pop.style.display = 'block';
    //   pop.style.position = 'absolute';
    // }

    // new code ngIf
    this.selectedTooltipTag = element;
    setTimeout(() => {
      let pop = document.getElementById('tooltip_' + element);
      if (pop) {
        pop.style.left = (Number(x) + constTagWidth) + 'px';
        pop.style.bottom = (Number(y) + constTagHeight) + 'px';
        pop.style.position = 'absolute';
      }
    }, 100);
  }
  /* * * *
  * unselect tag element
  * * * */
  unselectTagElement() {
    if (this.selTagElement) {
      let el = this.datauxview.getElementId(this.selTagElement);
      let dom = document.getElementById('popup_' + this.selTagElement);
      if (dom) {
        if (!this.cfb_externalport) {
          this.toggleStaticObj(true);
        }
        // dom.style.display = "none";
        // this.removeObserver(el);
        // clearInterval(this.popintervalId);
        // this.selTagElement = '';

        this.selTagElement = '';
        this.selectedPopupTag = '';
        this.removeObserver(el);
        clearInterval(this.popintervalId);
      }
    }
  }

  /* * * *
  * show Message tag
  * * * */
  showMessageTag(ship) {
    if (ship) {
      this.isMessageTag = true;
      let shipid = ship['name'];
      let el = this.datauxview.getElementId(shipid);
      this.addObserver(el);
    }
  }

  /* * * *
  * hide Message tag
  * * * */
  hideMessageTag(ship) {
    if (ship) {
      let shipid = ship['name'];
      let el = this.datauxview.getElementId(shipid);
      let mTag = document.getElementById('activity_' + shipid);
      if (mTag) {
        mTag.style.display = "none";
        this.removeObserver(el);
      }
    }
  }

  /* * * *
  * unselect tag element
  * * * */
  unselectStaticObjTagElement() {
    if (this.selStaticObjTag) {
      let el = this.datauxview.getElementId(this.selStaticObjTag);
      let dom = document.getElementById('staticPopupScreen_' + this.selStaticObjTag);
      if (dom) {
        // dom.style.display = "none";
        // this.removeObserver(el);
        // this.selStaticObjTag = '';

        this.removeObserver(el);
        this.selStaticObjTag = '';
        this.selectedStaticPopupTag = '';
      }
    }
  }

  /* * * *
  * hide static objects tag
  * * * */
  hideStaticObjTag(element) {
    // let tooltip = document.getElementById('staticPopupScreen_' + element);
    // if (tooltip) {
    //   tooltip.style.display = "none";
    // }
    this.selectedStaticPopupTag = '';
  }

  /* * * *
  * show only selected tag if lable control is on
  * * * */
  showStaticObjTag(element, flag) {
    let el = this.datauxview.getElementId(element);
    if (flag) {
      this.selStaticObjTag = element;
      this.hideStaticObjTag(element);
      this.selectedStaticPopupTag = element;
      this.addObserver(el);
    } else {
      // let dom = document.getElementById('staticPopupScreen_' + element);
      // if (dom) {
      //   dom.style.display = "none";
      //   this.removeObserver(el);
      // }

      // this.selectedStaticPopupTag = '';
      this.removeObserver(el);
    }
  }
  /* * * *
  * show only selected Hazard tag
  * * * */
  selectedHazardTag: any;
  showHazardTag(element, flag) {
    let el = this.datauxview.getElementId(element);
    if (flag) {
      this.selectedHazardTag = element;
      this.addObserver(el);
    } else {
      // let dom = document.getElementById('hazardPointer');
      // if (dom) {
      //   dom.style.display = "none";
      //   delete this.selectedHazardTag;
      //   if (el) {
      //     this.removeObserver(el);
      //   }
      // }

      this.selectedHazardTag = '';
      this.removeObserver(el);
    }
  }
  /* * * *
  * show only selected laydown tag
  * * * */
  selectedLaydownTag: any;
  laydownPointerTag(element, flag) {
    let el = this.datauxview.getElementId(element);
    console.log("el----- : ", el);
    if (el) {

    }
    if (flag) {
      this.selectedLaydownTag = element;
      // this.hideStaticObjTag(element)
      this.addObserver(el);
    } else {
      // let dom = document.getElementById('laydownPointer');
      // if (dom) {
      //   dom.style.display = "none";
      //   delete this.selectedLaydownTag;
      //   this.removeObserver(el);
      // }
      this.selectedLaydownTag = '';
      this.removeObserver(el);
    }
  }
  /* * * *
* show only selected Location tag
* * * */
  selectedLocationTag: any;
  locationPointerTag(element, flag) {
    let el = this.datauxview.getElementId(element);
    if (flag) {
      this.selectedLocationTag = element;
      // this.hideStaticObjTag(element)
      this.addObserver(el);
    } else {
      // let dom = document.getElementById('locationPointer');
      // if (dom) {
      //   dom.style.display = "none";
      //   delete this.selectedLocationTag;
      //   this.removeObserver(el);
      // }

      this.selectedLocationTag = '';
      this.removeObserver(el);
    }
  }

  /* * * * *
  * attch event for dynamic created ship objects
  * * * * * */
  attchDynamicEvent(element) {
    let el = this.datauxview.getElementId(element);
    this.datauxview.getDatascape().attach(el, {
      actions: {
        hoverOn: [(evt, elem) => {
          this.onHover(elem)
        }],
        hoverOff: [(evt, elem) => {
          this.offHover(elem);
        }],
      }
    });
  }

  /* * * * *
  * ship onhover tag will show if lable control is off
  * * * * * */
  onHover(elem) {
    console.log("hover", this.isDragmove);
    if (!this.multipleTagStatus && !this.isDragmove) {
      let props = this.datauxview.getDatascape().props(elem);
      this.hoverTagElement = props;
      if (props['profile']['material'] !== 'transparent') {
        if (this.selTagElement !== this.hoverTagElement.id) {
          this.addObserver(elem);
        }
      }
    }
  }

  /* * * * *
  * ship offhover shown tag will hide
  * * * * * */
  offHover(elem) {
    if (!this.multipleTagStatus) {
      let el = this.datauxview.getElementId(elem);
      // existing code display block
      // let dom = document.getElementById('tooltip_' + this.hoverTagElement.id);
      // if (dom) {
      //   dom.style.display = "none";
      //   this.hoverTagElement = '';
      //   this.removeObserver(el);
      // }

      // new code ngIf
      this.hoverTagElement = '';
      this.selectedTooltipTag = '';
      this.removeObserver(el);
    }
  }


  /* * * * *
* ship onhover tag will show if lable control is off
* * * * * */
  onKioskHover(elem) {
    console.log("hover", this.isDragmove);
    if (!this.isDragmove) {
      let el = this.datauxview.getElementId(elem);
      this.hoverKioskTagElement = elem;
      let idx = this.kioskSelectedElem.findIndex(e => e == this.hoverKioskTagElement)
      if (idx == -1) {
        this.addObserver(el);
      }
    }
  }

  /* * * * *
  * ship offhover shown tag will hide
  * * * * * */
  offKioskHover(elem) {
    let el = this.datauxview.getElementId(elem);
    let dom = document.getElementById('tooltipKiosk_' + this.hoverKioskTagElement);
    if (dom) {
      dom.style.display = "none";
      this.hoverKioskTagElement = '';
      this.removeObserver(el);
    }
  }
  /*****
 * kiosk tag show/hide on hover
 */
  hoverKioskTagElement: any = '';
  onHoverHideShow(element, flag) {
    if (flag) {
      this.onKioskHover(element);
    } else {
      this.offKioskHover(element);
    }
  }

  searchKioskTag(data) {
    if (data) {
      data.forEach(element => {
        let idx = this.kioskSelectedElem.findIndex(e => e == element.uid);
        if (idx > -1) {
          this.showKioskTag(element, true)
        }
      });
    }

  }
  /*****
   * kiosk tag show/hide
   */
  kioskSelectedError: any = [];
  kioskSelectedElem: any = [];
  showKioskTag(element, flag) {
    // let el = this.kioskElement[element];
    let el = this.datauxview.getElementId(element);
    if (el) {
      if (flag) {
        let dom = document.getElementById('kioskPopupErrorScreen');
        if (dom) {
          dom.style.display = "none";
        }
        if (this.kioskSelectedElem.length > 0) {
          if (this.hoverKioskTagElement != "") {
            if (element == this.hoverKioskTagElement) {
              this.offKioskHover(element);
            }
          }
          el = this.datauxview.getElementId(element);
          let idx = this.kioskSelectedElem.findIndex(e => e == element)
          if (idx == -1) {
            this.kioskSelectedElem.push(element);
          }
          this.addObserver(el);
        } else {
          if (this.hoverKioskTagElement != "") {
            if (element == this.hoverKioskTagElement) {
              this.offKioskHover(element);
            }
          }
          el = this.datauxview.getElementId(element);
          this.kioskSelectedElem.push(element);
          this.addObserver(el);
        }

        // el = this.kioskElement[element];

      } else {
        let dom = document.getElementById('kioskPopupScreen_' + element);
        if (dom) {
          dom.style.display = "none";
          let idx = this.kioskSelectedElem.findIndex(e => e == element);
          if (idx > -1) {
            this.kioskSelectedElem.splice(idx, 1);
          }
          this.removeObserver(el);
        }
      }
    } else {
      let dom = document.getElementById('kioskPopupErrorScreen_' + element);
      if (dom) {
        if (flag) {
          // if (this.kioskSelectedElem) {
          //   el = this.datauxview.getElementId(this.kioskSelectedElem);
          //   let dom = document.getElementById('kioskPopupScreen_' + this.kioskSelectedElem);
          //   if (dom) {
          //     dom.style.display = "none";
          //     delete this.kioskSelectedElem;
          //     this.removeObserver(el);
          //   }
          // }

          dom.style.display = "block";
          if (this.kioskSelectedError.length > 0) {

            let dom = document.getElementById('kioskPopupErrorScreen_' + this.kioskSelectedError[0]);
            if (dom) {
              dom.style.display = "none";
            }
            this.kioskSelectedError = [];

          }
          this.kioskSelectedError.push(element);
        } else {

          dom.style.display = "none";
        }
      }
    }

  }
  /* * * * *
  * add an Observer attach to 3d object
  * * * */
  addObserver(elem) {
    if (!elem) {
      return;
    }
    this.datauxview.getDatascape().attach(elem, {
      observer: {
        callback: this.assignTagProperties.bind(this),
        options: { no2D: false }
      }
    });
  }

  /* * * *
  * Remove an Observer detach to 3d object
  * * * */
  removeObserver(elem) {
    if (!elem) {
      return;
    }
    this.datauxview.getDatascape().detach(elem, {
      observer: {
        callback: this.assignTagProperties.bind(this),
        options: { no2D: false }
      }
    });
  }

  /* * * * *
  * tag observer properties
  * * * */
  assignTagProperties(options) {
    const { id, position, position2D, uuid, tag, visibility } = Object.assign({}, options);
    let elem = this.datauxview.getElementId(id);

    // let layout = this.datauxview.getElementId("layout_"+id);
    // if(elem){
    //   var props = this.datauxview.getDatascape().props(elem);
    // }

    // this.selectedTooltipTag = id;
    // this.selectedPopupTag = id;
    // this.selectedStaticPopupTag = id;

    var tooltip = document.getElementById('tooltip_' + id);
    var popup = document.getElementById('popup_' + id);
    var pop = document.getElementsByClassName("mat_nm_label")[0];

    if (elem) {
      let props = this.datauxview.getDatascape().props(elem);
      if (this.showHideTagPointer.length > 0) {
        let idx = this.showHideTagPointer.findIndex(e => e == id);
        if (idx > -1) {
          let props = this.datauxview.getDatascape().props(elem);
          pop = document.getElementById(id);
          this.objObserverLayout(pop, position2D, props);
        }
      }
      if (this.showChatNotificationTag.length > 0) {
        let chatData = this.showChatNotificationTag.find(e => id.includes(e.id));
        if (chatData) {
          let props = this.datauxview.getDatascape().props(elem);
          let cTag = document.getElementById('chat_' + chatData.id);
          this.objObserverChatTag(cTag, position2D, props);
        }
      }
      if (this.dfxTagList.length > 0) {
        let idx = this.dfxTagList.findIndex(e => e == id);
        if (idx > -1) {
          let props = this.datauxview.getDatascape().props(elem);
          pop = document.getElementById("dfxTag_" + id);
          this.objObserverDfxTag(pop, position2D, props);
        }
      }
      if (this.commentTagList.length > 0) {
        let idx = this.commentTagList.findIndex(e => e.name == id);
        if (idx > -1) {
          let props = this.datauxview.getDatascape().props(elem);
          pop = document.getElementById("comment_" + id);
          this.commentObserverTag(pop, position2D, props);
        }
      }
      if (this.showHideLocationTagPointer.length > 0) {
        let idx = this.showHideLocationTagPointer.findIndex(e => e == id);
        if (idx > -1) {
          let props = this.datauxview.getDatascape().props(elem);
          pop = document.getElementById(id);
          this.objObserverLayout(pop, position2D, props);
        }
      }
      if (this.selectedMatObjTag) {
        if (props.id == "materialPointer") {
          this.objObserverLayout(pop, position2D, props);
        }
      }
      if (this.multipleTagStatus) {
        if (this.selTagElement) {
          if (this.selTagElement === id) {
            this.popupObserver(popup, position2D, props);
          } else {
            if (props['profile']['material'] !== 'transparent') {
              this.objObserver(tooltip, position2D, props);
            }
          }
        } else {
          if (props['profile']['material'] !== 'transparent') {
            this.objObserver(tooltip, position2D, props);
          }
        }
      } else {
        if (this.hoverTagElement.id === id) {
          if (this.selTagElement !== id) {
            if (props['profile']['material'] !== 'transparent') {
              this.objObserver(tooltip, position2D, props);
            }
          }
        }
        if (this.selTagElement === id) {
          this.popupObserver(popup, position2D, props);
        }
      }
      if (this.isMessageTag) {
        var mTag = document.getElementById('activity_' + id);
        if (mTag) {
          this.messageTagObserver(mTag, position2D, props);
        }
      }
      if (this.selStaticObjTag) {
        var staticTag = document.getElementById('staticPopupScreen_' + id);
        if (staticTag) {
          if (this.selStaticObjTag === id) {
            if (props['profile']['visible']) {
              this.popupObserver(staticTag, position2D, props);
            }
          }
        }
      }
      // console.log("obs :", this.hoverKioskTagElement, this.kioskSelectedElem, id);
      if (this.hoverKioskTagElement) {
        if (this.hoverKioskTagElement === id) {
          let idx = this.kioskSelectedElem.findIndex(e => e == id);
          if (idx == -1) {
            var staticTooltip = document.getElementById('tooltipKiosk_' + id);
            if (staticTooltip) {
              this.kioskTooltipObserver(staticTooltip, position2D, props);
            }
          }
        }
      }
      if (this.kioskSelectedElem.length > 0) {
        let idx = this.kioskSelectedElem.findIndex(e => e == id);
        if (idx > -1) {
          var staticTag = document.getElementById('kioskPopupScreen_' + id);
          if (staticTag) {
            this.kioskPopupObserver(staticTag, position2D, props);
          }
        }
      }
      if (this.selectedLaydownTag) {
        var staticTag = document.getElementById('laydownPointer');
        if (staticTag) {
          if (this.selectedLaydownTag === id) {
            this.laydownPopupObserver(staticTag, position2D, props);
          }
        }
      }
      if (this.selectedLocationTag) {
        var staticTag = document.getElementById('locationPointer');
        if (staticTag) {
          if (this.selectedLocationTag === id) {
            this.locationPopupObserver(staticTag, position2D, props);
          }
        }
      }
      if (this.selectedHazardTag) {
        var staticTag = document.getElementById('hazardPointer');
        if (staticTag) {
          if (this.selectedHazardTag === id) {
            this.hazardPopupObserver(staticTag, position2D, props);
          }
        }
      }
    }

  }
  /* * * *
  * observable popup will show respected position
  * * * */
  kioskTooltipObserver(pop, position, element) {
    if (element != "") {
      // console.log("position :", position, pop, element);

      // const constKisokTagWidth = 800 - window.innerWidth;
      // const constKisokTagHeight = 339 - window.innerHeight;
      // console.log( constKisokTagWidth,constKisokTagHeight);
      const x = position[0];
      const y = position[1];
      const dpr = window.devicePixelRatio;
      var canvas = this.datauxview.rendererCanvas.nativeElement;
      const within_window = (x > 130 && x < canvas.width - 130) && (y > 130 && y <= canvas.height);
      if (pop) {
        if (within_window) {
          pop.style.left = (Number(x) + constKisokToolWidth) + 'px';
          pop.style.bottom = (Number(y) + constKisokToolHeight) + 'px';
          let color = 'black';
          var material = 'lightgrey';
          pop.children[0].children[0].children[0].children[0].style.color = color;
          pop.children[0].children[0].children[0].children[0].style.backgroundColor = material;
          pop.style.display = 'block';
          pop.style.position = 'absolute';
          pop.dataset.lastx = Number(x);
          pop.dataset.lasty = Number(y);
        } else {
          if (pop != null) {
            pop.style.display = 'none';
          }
        }
      } else {
        if (pop != null) {
          pop.style.display = 'none';
        }
      }
    }
  }
  /* * * *
* observable popup will show respected position
* * * */
  hazardPopupObserver(pop, position, element) {
    if (element != "") {
      // console.log("position :", position, pop, element);
      // const constKisokTagWidth = 800 - window.innerWidth;
      // const constKisokTagHeight = 339 - window.innerHeight;
      // console.log( constKisokTagWidth,constKisokTagHeight);
      var widthPos, heightPos;
      widthPos = constHazardTagWidth;
      heightPos = constHazardTagHeight;
      const x = position[0];
      const y = position[1];
      const dpr = window.devicePixelRatio;
      var canvas = this.datauxview.rendererCanvas.nativeElement;
      const within_window = (x > 130 && x < canvas.width - 130) && (y > 130 && y <= canvas.height);
      if (pop) {
        if (within_window) {
          pop.style.left = (Number(x) + widthPos) + 'px';
          pop.style.bottom = (Number(y) + heightPos) + 'px';
          pop.style.display = 'block';
          pop.style.position = 'absolute';
          pop.dataset.lastx = Number(x);
          pop.dataset.lasty = Number(y);
        } else {
          if (pop != null) {
            this.selectedHazardTag = '';
            pop.style.display = 'none';
          }
        }
      } else {
        if (pop != null) {
          this.selectedHazardTag = '';
          pop.style.display = 'none';
        }
      }
    }
  }
  /* * * *
* observable popup will show respected position
* * * */
  laydownPopupObserver(pop, position, element) {
    if (element != "") {
      // console.log("position :", position, pop, element);
      // const constKisokTagWidth = 800 - window.innerWidth;
      // const constKisokTagHeight = 339 - window.innerHeight;
      // console.log( constKisokTagWidth,constKisokTagHeight);
      var widthPos, heightPos;

      widthPos = constLaydownTagWidth;
      heightPos = constLaydownTagHeight;


      const x = position[0];
      const y = position[1];
      const dpr = window.devicePixelRatio;
      var canvas = this.datauxview.rendererCanvas.nativeElement;
      const within_window = (x > 130 && x < canvas.width - 130) && (y > 130 && y <= canvas.height);
      if (pop) {
        if (within_window) {
          pop.style.left = (Number(x) + widthPos) + 'px';
          pop.style.bottom = (Number(y) + heightPos) + 'px';
          pop.style.display = 'block';
          pop.style.position = 'absolute';
          pop.dataset.lastx = Number(x);
          pop.dataset.lasty = Number(y);
        } else {
          if (pop != null) {
            this.selectedLaydownTag = '';
            pop.style.display = 'none';
          }
        }
      } else {
        if (pop != null) {
          this.selectedLaydownTag = '';
          pop.style.display = 'none';
        }
      }
    }
  }
  /* * * *
  * observable popup will show respected position
  * * * */
  // kioskPopupObserver(pop, position, element) {
  //   if (element != "") {
  //     // console.log("position :", position, pop, element);

  //     // const constKisokTagWidth = 800 - window.innerWidth;
  //     // const constKisokTagHeight = 339 - window.innerHeight;
  //     // console.log( constKisokTagWidth,constKisokTagHeight);
  //     var widthPos,heightPos;

  //       widthPos = constKisokTagWidth;
  //       heightPos = constKisokTagHeight;
  //     const x = position[0];
  //     const y = position[1];
  //     const dpr = window.devicePixelRatio;
  //     var canvas = this.datauxview.rendererCanvas.nativeElement;
  //     const within_window = (x > 130 && x < canvas.width - 130) && (y > 130 && y <= canvas.height);
  //     if (pop) {
  //       if (within_window) {
  //         pop.style.left = (Number(x) + widthPos) + 'px';
  //         pop.style.bottom = (Number(y) + heightPos) + 'px';
  //         pop.style.display = 'block';
  //         pop.style.position = 'absolute';
  //         pop.dataset.lastx = Number(x);
  //         pop.dataset.lasty = Number(y);
  //       } else {
  //         if (pop != null) {
  //           pop.style.display = 'none';
  //         }
  //       }
  //     } else {
  //       if (pop != null) {
  //         pop.style.display = 'none';
  //       }
  //     }
  //   }
  // }
  locationPopupObserver(pop, position, element) {
    if (element != "") {
      // console.log("position :", position, pop, element);

      // const constKisokTagWidth = 800 - window.innerWidth;
      // const constKisokTagHeight = 339 - window.innerHeight;
      // console.log( constKisokTagWidth,constKisokTagHeight);
      var widthPos, heightPos;

      widthPos = constLocationTagWidth;
      heightPos = constLocationTagHeight;

      const x = position[0];
      const y = position[1];
      const dpr = window.devicePixelRatio;
      var canvas = this.datauxview.rendererCanvas.nativeElement;
      const within_window = (x > 130 && x < canvas.width - 130) && (y > 130 && y <= canvas.height);
      if (pop) {
        if (within_window) {
          pop.style.left = (Number(x) + widthPos) + 'px';
          pop.style.bottom = (Number(y) + heightPos) + 'px';
          pop.style.display = 'block';
          pop.style.position = 'absolute';
          pop.dataset.lastx = Number(x);
          pop.dataset.lasty = Number(y);
        } else {
          if (pop != null) {
            this.selectedLocationTag = '';
            pop.style.display = 'none';
          }
        }
      } else {
        if (pop != null) {
          this.selectedLocationTag = '';
          pop.style.display = 'none';
        }
      }
    }
  }

  kioskPopupObserver(pop, position, element) {
    if (element != "") {
      // console.log("position :", position, pop, element);

      // const constKisokTagWidth = 800 - window.innerWidth;
      // const constKisokTagHeight = 339 - window.innerHeight;
      // console.log( constKisokTagWidth,constKisokTagHeight);
      var widthPos, heightPos;

      widthPos = constKisokTagWidth;
      heightPos = constKisokTagHeight;
      const x = position[0];
      const y = position[1];
      const dpr = window.devicePixelRatio;
      var canvas = this.datauxview.rendererCanvas.nativeElement;
      const within_window = (x > 130 && x < canvas.width - 130) && (y > 130 && y <= canvas.height);
      if (pop) {
        if (within_window) {
          pop.style.left = (Number(x) + widthPos) + 'px';
          pop.style.bottom = (Number(y) + heightPos) + 'px';
          pop.style.display = 'block';
          pop.style.position = 'absolute';
          pop.dataset.lastx = Number(x);
          pop.dataset.lasty = Number(y);
        } else {
          if (pop != null) {
            pop.style.display = 'none';
          }
        }
      } else {
        if (pop != null) {
          pop.style.display = 'none';
        }
      }
    }
  }

  commentObserverTag(pop, position, element) {
    if (element != "") {
      const x = position[0];
      const y = position[1];
      var canvas = this.datauxview.rendererCanvas.nativeElement;
      const within_window = (x > 50 && x < canvas.width - 50) && (y > 50 && y <= canvas.height - 50);
      if (pop) {
        if (within_window) {
          if (this.commentData && this.commentData.name == element.id) {
            pop.style.left = (Number(x) - 160) + 'px';
            pop.style.bottom = (Number(y) + 145) + 'px';
          } else {
            pop.style.left = (Number(x) - constCommentWidth) + 'px';
            pop.style.bottom = (Number(y) + constCommentHeight) + 'px';
          }

          // pop.innerHTML = this.selectedMatObjTag;
          pop.style.display = 'block';
          pop.style.position = 'absolute';
          pop.dataset.lastx = Number(x);
          pop.dataset.lasty = Number(y);
        } else {
          // if (pop != null) {
          pop.style.display = 'none';
          // }
        }
      } else {
        if (pop != null) {
          pop.style.display = 'none';
        }
      }
    } else {
      if (pop != null) {
        pop.style.display = 'none';
      }
    }
  }
  objObserverDfxTag(pop, position, element) {
    if (element != "") {
      const x = position[0];
      const y = position[1];
      var canvas = this.datauxview.rendererCanvas.nativeElement;
      const within_window = (x > 50 && x < canvas.width - 50) && (y > 50 && y <= canvas.height - 50);
      if (pop) {
        if (within_window) {
          pop.style.left = (Number(x) - constDfxLabelWidth) + 'px';
          pop.style.bottom = (Number(y) + constDfxLabelHeight) + 'px';
          // pop.innerHTML = this.selectedMatObjTag;
          pop.style.display = 'block';
          pop.style.position = 'absolute';
          pop.dataset.lastx = Number(x);
          pop.dataset.lasty = Number(y);
        } else {
          // if (pop != null) {
          pop.style.display = 'none';
          // }
        }
      } else {
        if (pop != null) {
          pop.style.display = 'none';
        }
      }
    }
  }
  /****
   * observable popup Tag
   */
  objObserverLayout(pop, position, element) {
    if (element != "") {
      const x = position[0];
      const y = position[1];
      var canvas = this.datauxview.rendererCanvas.nativeElement;
      const within_window = (x > 50 && x < canvas.width - 50) && (y > 50 && y <= canvas.height - 50);
      if (pop) {
        if (within_window) {
          pop.style.left = (Number(x) - constLabelWidth) + 'px';
          pop.style.bottom = (Number(y) + constLabelHeight) + 'px';
          // pop.innerHTML = this.selectedMatObjTag;
          pop.style.display = 'block';
          pop.style.position = 'absolute';
          pop.dataset.lastx = Number(x);
          pop.dataset.lasty = Number(y);
        } else {
          // if (pop != null) {
          pop.style.display = 'none';
          // }
        }
      } else {
        if (pop != null) {
          pop.style.display = 'none';
        }
      }
    }
  }

  /****
   * observable chat Tag
   */
  objObserverChatTag(pop, position, element) {
    if (element != "") {
      const x = position[0];
      const y = position[1];
      var canvas = this.datauxview.rendererCanvas.nativeElement;
      const within_window = (x > 50 && x < canvas.width - 50) && (y > 50 && y <= canvas.height - 50);
      if (pop) {
        if (within_window) {
          pop.style.left = (Number(x) - constChatLabelWidth) + 'px';
          pop.style.bottom = (Number(y) + constChatLabelHeight) + 'px';
          pop.style.display = 'block';
          pop.style.position = 'absolute';
          pop.dataset.lastx = Number(x);
          pop.dataset.lasty = Number(y);
        } else {
          if (pop != null) {
            pop.style.display = 'none';
          }
        }
      } else {
        if (pop != null) {
          pop.style.display = 'none';
        }
      }
    }
  }

  /* * * *
  * observable Tag will show respected position
  * * * */
  objObserver(pop, position, element) {
    if (element != "") {
      // console.log("position :", position, pop, element);
      const x = position[0];
      const y = position[1];
      const dpr = window.devicePixelRatio;
      var canvas = this.datauxview.rendererCanvas.nativeElement;
      const within_window = (x > 50 && x < canvas.width - 50) && (y > 50 && y <= canvas.height - 50);
      if (pop) {
        if (within_window) {
          pop.style.left = (Number(x) + constTagWidth) + 'px';
          pop.style.bottom = (Number(y) + constTagHeight) + 'px';
          let color = 'black';
          var material = 'lightgrey';
          pop.children[0].children[0].children[0].children[0].style.color = color;
          pop.children[0].children[0].children[0].children[0].style.backgroundColor = material;
          pop.style.display = 'block';
          pop.style.position = 'absolute';
          pop.dataset.lastx = Number(x);
          pop.dataset.lasty = Number(y);
        } else {
          if (pop != null) {
            this.selectedTooltipTag = '';
            pop.style.display = 'none';
          }
        }
      } else {
        if (pop != null) {
          this.selectedTooltipTag = '';
          pop.style.display = 'none';
        }
      }
    }
  }

  /* * * *
  * observable popup will show respected position
  * * * */
  popupObserver(pop, position, element) {
    if (element != "") {
      // console.log("position :", position, pop, element);
      const x = position[0];
      const y = position[1];
      const dpr = window.devicePixelRatio;
      var canvas = this.datauxview.rendererCanvas.nativeElement;
      this.activePop = pop;
      const within_window = (x > 130 && x < canvas.width - 130) && (y > 130 && y <= canvas.height);
      if (pop) {
        if (within_window) {
          pop.style.left = (Number(x) + constPopupWidth) + 'px';
          pop.style.bottom = (Number(y) + constPopupHeight) + 'px';
          pop.style.display = 'block';
          pop.style.position = 'absolute';
          pop.dataset.lastx = Number(x);
          pop.dataset.lasty = Number(y);
        } else {
          if (pop != null) {
            this.selectedPopupTag = '';
            pop.style.display = 'none';
          }
        }
      } else {
        if (pop != null) {
          this.selectedPopupTag = '';
          pop.style.display = 'none';
        }
      }
    }
  }

  /* * * *
  * observable message tag will show respected position
  * * * */
  messageTagObserver(mTag, position, element) {
    if (element != "") {
      // console.log("position :", position, pop, element);
      const x = position[0];
      const y = position[1];
      const dpr = window.devicePixelRatio;
      var canvas = this.datauxview.rendererCanvas.nativeElement;
      const within_window = (x > 10 && x < canvas.width - 50) && (y > 10 && y <= canvas.height);
      if (mTag) {
        if (within_window) {
          mTag.style.left = (Number(x) + constMessageWidth) + 'px';
          mTag.style.bottom = (Number(y) + constMessageHeight) + 'px';
          mTag.style.display = 'block';
          mTag.style.position = 'absolute';
          mTag.dataset.lastx = Number(x);
          mTag.dataset.lasty = Number(y);
        } else {
          if (mTag != null) {
            mTag.style.display = 'none';
          }
        }
      } else {
        if (mTag != null) {
          mTag.style.display = 'none';
        }
      }
    }
  }

  /**
   * attach label to ship
   */
  attachLabel(shipid, ship, data, y, size) {
    let shapescale = { "aops-model": 1.5, "Halifax": 1.5, "sackville-model": 1.5, "vcsub-model": 1, "kingston-model": 1.35 };
    let shapeoff = {};//{"aops-model":0.5,"Halifax":0.65,"sackville-model":0,"vcsub-model":0,"kingston-model":-0.35};
    let yoff = data.model === "vcsub-model" ? y / 2.0 : y / 2.0
    let shape = data.ship;
    let id = shipid + "-label";
    let profiles = {
      regular: { material: 'ship_lab' },
      invisible: { material: 'ship_lab', visible: false },
      hidden: { material: 'hidden_obj', visible: false }
    };
    let scale = shapescale[data.model] || 1;
    let off = shapeoff[data.model] || 0
    let settings = { 'geometry': { 'orientation': { x: 0, y: 0, z: 0 }, 'position': { x: 0, y: yoff, z: y * off }, 'size': 1 } };
    this.datauxview.addElement(shape, id, profiles, settings);
    let label = this.datauxview.getElementId(id);
    this.dfx.setElementScale(label, [scale, 1, scale]);
    this.dfx.setAsParent(ship, [label]);
    this.attachBaseToLabel(id, data.model, y, scale);
    this.attachFlagBaseToShip(shipid, data.activity, data.model);
    this.showShipLabel(false, shipid);
  }
  attachBaseToLabel(lid, mod, size, sc) {
    let pobj = { "aops-model": "dewolf_mat", "Halifax": "halifax_mat", "sackville-model": "sackville_mat", "vcsub-model": "victoria_mat", "kingston-model": "kingston_mat", "asterix-model": "Asterix_mat", "vis-model": "vis_mat" };
    let prop = { "aops-model": 112 / 446, "Halifax": 74 / 581, "sackville-model": 45 / 269, "vcsub-model": 54 / 294, "kingston-model": 58 / 265, "asterix-model": 104 / 747, "vis-model": 69 / 535 }
    let mat = pobj[mod];
    let p = prop[mod];
    let o = mod === "vcsub-model" ? -1 : 1
    let shape = 'label_base';
    let id = lid + "-base";

    let profiles = {
      regular: { material: mat },
      invisible: { material: mat, visible: false },
      hidden: { material: 'hidden_obj', visible: false }
    };
    let settings = { 'geometry': { 'orientation': { x: 0, y: 90 * o, z: 0 }, 'position': { x: 0, y: -1, z: 0 }, 'size': 1 } };
    this.datauxview.addElement(shape, id, profiles, settings);
    let label = this.datauxview.getElementId(lid);
    let base = this.datauxview.getElementId(id);
    this.dfx.setAsParent(label, [base]);
    this.dfx.setElementScale(base, [size * 2 * p * (1 / sc), 1, size * 2 * (1 / sc)]);
    //let m = this.dfx.getElementMesh(base);
    //m.rotationQuaternion=null;
  }
  flag_base_model
  createFBModel() {
    let marr = ["antrot_mat", "ammun_mat", "aloft_mat", "cmove_mat", "crwork_mat", "diver_mat", "trial_mat", "emmis_mat", "rad_mat", "fuel_mat", "boll_mat", "other_mat", "engine_mat"]
    let profiles = {
      regular: { material: marr[0], visible: false },
      invisible: { material: marr[0], visible: false }
    };
    marr.forEach((m) => {
      profiles[m] = { material: m, visible: false }
    })
    let opt = {
      shape: 'label_base',
      id: 'e_label_flagbase',
      profiles
    }
    this.flag_base_model = this.datauxview.addModel(this.dfx, opt);
  }
  attachFlagBaseToShip(sid, act, mod = null, update = false) {
    let marr = ["antrot_mat", "ammun_mat", "aloft_mat", "cmove_mat", "crwork_mat", "diver_mat", "trial_mat", "emmis_mat", "rad_mat", "fuel_mat", "boll_mat", "other_mat", "engine_mat"]
    let _act = act.toLowerCase();
    let mat = "";
    if (_act.includes('antenna')) {
      mat = marr[0]
    } else if (_act.includes('ammuni')) {
      mat = marr[1]
    } else if (_act.includes('aloft')) {
      mat = marr[2]
    } else if (_act.includes('cold')) {
      mat = marr[3]
    } else if (_act.includes('crane')) {
      mat = marr[4]
    } else if (_act.includes('diver') || _act.includes('diving')) {
      mat = marr[5]
    } else if (_act.includes('basin')) {
      mat = marr[6]
    } else if (_act.includes('emiss')) {
      mat = marr[7]
    } else if (_act.includes('radiat')) {
      mat = marr[8]
    } else if (_act.includes('fuel')) {
      mat = marr[9]
    } else if (_act.includes('bollard')) {
      mat = marr[10]
    } else if (_act.includes('other')) {
      mat = marr[11]
    } else if (_act.includes('engine')) {
      mat = marr[12]
    }
    if (!mat) {
      return;
    }
    let lid = sid + "-label";
    let label = this.datauxview.getElementId(lid);
    let lab_mesh = this.dfx.getElementMesh(label);
    let lr = lab_mesh.getBoundingInfo().boundingSphere.radiusWorld;
    //
    let ship = this.datauxview.getElementId(sid);
    let ship_mesh = this.dfx.getElementMesh(ship);
    let sc = ship_mesh.scaling.x;
    let o = mod === "vcsub-model" ? -1 : 1
    let shape = 'label_base';
    let id = lid + "-flagbase";
    if (!this.flag_base_model) {
      this.createFBModel();
    }
    let exist = this.datauxview.getElementId(id);
    if (update && exist) {
      this.dfx.modify(exist, { profile: mat, tag: { mat: mat } });
      return
    }
    if (exist) {
      this.dfx.remove(exist);
      this.datauxview._allElements[id] = null;
    }
    let settings = { 'geometry': { 'orientation': { x: 0, y: 90 * o, z: 0 }, 'position': { x: o * (lr + (10 * (1 / sc))), y: 0, z: 0 }, 'size': 1 } };
    this.addElement(this.flag_base_model, id, mat, settings, { mat });

    let base = this.datauxview.getElementId(id);
    this.dfx.setAsParent(label, [base]);
    // this.dfx.setElementScale(base, [18 * (1 / sc), 1, 18 * (1 / sc)]);
    let scaleF = this.cfbport.includes('esq') ? 18 / 1.4 : 18;
    this.dfx.setElementScale(base, [scaleF * (1 / sc), 1, scaleF * (1 / sc)]);
  }
  addElement(m, id, profile, settings, tag = null) {
    let dfx = this.dfx;
    let el = dfx.add({
      id: id,
      model: m,
      geometry: settings.geometry,
      profile,
      tag: tag || null
    });
    this.datauxview._allElements[id] = el;
  }
  /**
   * show/hide ship Label
   */
  showShipLabel(show, id) {
    let el = this.datauxview.getElementId(id + "-label");
    let base = this.datauxview.getElementId(id + "-label-base");
    let fbase = this.datauxview.getElementId(id + "-label-flagbase");
    let fbmesh = this.dfx.getElementMesh(fbase);
    if (show) {
      this.dfx.modify(el, { profile: 'invisible' });
      this.dfx.modify(el, { profile: 'regular' });
      this.dfx.modify(base, { profile: 'invisible' });
      this.dfx.modify(base, { profile: 'regular' });
      if (fbmesh) {
        let props = this.dfx.props(fbase);
        let mat = props.tag.mat;
        this.dfx.modify(fbase, { profile: 'regular' });
        this.dfx.modify(fbase, { profile: mat });
        fbmesh.isVisible = true;
      }
    } else {
      this.dfx.modify(el, { profile: 'invisible' });
      this.dfx.modify(el, { profile: 'hidden' });
      this.dfx.modify(base, { profile: 'invisible' });
      this.dfx.modify(base, { profile: 'hidden' });
    }
  }

  /**
  * creating flag object for unknown ship
  */
  attachFlag(objects) {
    let flagObj = [];
    let config = { "vcsub-model": 2, "sackville-model": -12, "kingston-model": -15 };
    objects.forEach(ship => {
      let shipid = ship.name;
      let el = this.datauxview.getElementId(shipid)
      let props = this.dfx.props(el);
      let sy = (config[ship.model] ? config[ship.model] : -18);
      let objConfig = {
        "type": "static",
        "parent": props.id,
        "name": props.id + '_red_flag',
        "model": "flag-model",
        "profile": "invisible",
        "place": {
          "pos": { x: 0, y: sy, z: 0 },
          "rot": { x: 0, y: 0, z: 180 }
        }
      };
      flagObj.push(objConfig);
    });
    this.createFlagObject(flagObj)
  }

  /* * * *
  * creating flag object instance
  * * * */
  createFlagObject(obj) {
    this.datauxview.addElements(obj, (flagObjects) => {
      flagObjects.forEach(flag => {
        let el = this.datauxview.getElementId(flag.name);
        this.dfx.modify(el, { profile: 'invisible' });
      });
    }, (id, pointer) => { this.attchFlagObjectEvents(id, pointer) });
  }

  /* * * * *
  * attchFlagObjectEvents
  * * * * * */
  attchFlagObjectEvents(id, pointer) {
    if (pointer == 'pickLeft') {
      let shipid = id.split('_')[0];
      // console.log("attchFlagObjectEvents ", id, pointer);
      this.broadcastInfo({ src: 'tagServ', event: 'show_selected_flag', data: '', key: shipid });
    }
  }

  /* * * *
  * check if object is visible or not
  * * * */
  isObjVisible(c) {
    let comp = this.datauxview.getElementId(c);
    if (comp) {
      let props = this.dfx.props(comp);
      let vis = props.profile.visible;
      return vis;
    } else {
      return null;
    }
  }

  /* * * *
  * show flag for location error ship
  * * * */
  showFlag(shipid) {
    let show = this.isObjVisible(shipid);
    if (show) {
      let flag_el = this.datauxview.getElementId(shipid + '_red_flag');
      if (flag_el) {
        let found = this.activeFalgList.some(el => el === shipid);
        if (!found) {
          this.activeFalgList.push(shipid);
        }
        this.dfx.modify(flag_el, { profile: 'regular' });
      }
    }
  }

  /* * * *
  * show flag for location error ship
  * * * */
  hideFlag(shipid, hideAll = false) {
    let el = this.datauxview.getElementId(shipid);
    if (el) {
      let flag_el = this.datauxview.getElementId(shipid + '_red_flag');
      if (flag_el) {
        if (hideAll) {
          this.activeFalgList = [];
          this.dfx.modify(flag_el, { profile: 'regular' });
          this.dfx.modify(flag_el, { profile: 'invisible' });
        } else {
          let found = this.activeFalgList.some(el => el === shipid);
          if (!found) {
            this.dfx.modify(flag_el, { profile: 'regular' });
            this.dfx.modify(flag_el, { profile: 'invisible' });
          }
        }
      }
    }
  }

  configUrl = 'assets/config.json';

  // getConfig() {
  //   return this.http.get<Config>(this.configUrl);
  // }
  selectedTagMesh: any;
  sysTagPickedPoint: any = {};
  sysDefTagPickedPoint: any = {};
  shipTagMesh: any;
  profiles: any = {
    "regular": { material: 'custom-included' },
    "transparent": { material: 'transparent' },
    "red": { material: 'cone_red' },
    "orange": { material: 'orange' },
    "brown": { material: 'envelope_blue' },
    "location_green": { material: 'location_green' },
    "hidden": { material: 'hidden_obj', visible: false }
  }
  createMatObject(shape, e, props) {
    if (this.datauxview) {
      var elem = this.datauxview.getElementId('materialPointer');
      var pos = e.pickedPoint;
      this.sysTagPickedPoint = pos;
      this.sysDefTagPickedPoint = JSON.parse(JSON.stringify(pos));
      var size = 0.5;
      this.shipTagMesh = e.pickedMesh;
      if (!elem) {
        let profiles = this.profiles;
        let settings = { 'geometry': { 'orientation': { x: 0, y: 0, z: 0 }, position: { x: pos.x, y: pos.y, z: pos.z }, size: size } };
        this.datauxview.addElement(shape, 'materialPointer', profiles, settings);
        let buildModel = this.datauxview.getElementId('materialPointer');
        this.dfx.modify(buildModel, { profile: 'regular' });
      } else {
        if (this.selectedMatObjTag) {
          // if (this.selectedMatObjTag.toLowerCase().indexOf(props.toLowerCase()) > -1) {
          let dom: any = document.getElementsByClassName("mat_nm_label")[0];
          if (dom) {
            dom.style.display = "none";
            delete this.selectedMatObjTag;
            // delete this.shipTagMesh;
            this.removeObserver(elem);
          }
          // }
        }
        this.dfx.modify(elem, { 'geometry': { 'orientation': { x: 0, y: 0, z: 0 }, position: { x: pos.x, y: pos.y, z: pos.z }, size: size } });
      }
      // setTimeout(() => {
      this.showMatObjectTag(props, true);
      // }, 1000);
    }
  }
  commentsObjList: any = [];
  createCommentsObject(ele) {
    if (this.datauxview) {
      var pos = ele.position;
      var size = 0.01;
      // if (!elem) {
      let profiles = this.profiles;
      let settings = { 'geometry': { 'orientation': { x: 0, y: 0, z: 0 }, position: { x: pos.x, y: pos.y, z: pos.z }, size: size } };
      this.datauxview.addElement(ele.shapeId, ele.name, profiles, settings);
      let buildModel = this.datauxview.getElementId(ele.name);
      this.dfx.modify(buildModel, { profile: 'regular' });
      this.commentsObjList.push(ele);
      this.showCommentTag(ele, true);
      // } 
      // setTimeout(() => {
      // this.showMatObjectTag(props, true);
      // }, 1000);
    }
  }
  /* * * *
* show only selected laydown tag
* * * */
  selectedMatObjTag: any;
  showMatObjectTag(element, flag) {
    if (!this.datauxview) {
      return;
    }
    let el = this.datauxview.getElementId('materialPointer');
    if (!el) {
      return;
    }
    if (flag) {
      if (this.selectedMatObjTag) {
        if (this.selectedMatObjTag.toLowerCase().indexOf(element.toLowerCase()) > -1) {
          let dom: any = document.getElementsByClassName("mat_nm_label")[0];
          if (dom) {
            dom.style.display = "none";
            delete this.selectedMatObjTag;
            delete this.shipTagMesh;
            this.removeObserver(el);
          }
        } else {
          this.selectedMatObjTag = element;
          this.addObserver(el);
        }
      } else {
        this.selectedMatObjTag = element;
        this.addObserver(el);
      }
    } else {
      let dom: any = document.getElementsByClassName("mat_nm_label")[0];
      if (dom) {
        dom.style.display = "none";
        delete this.selectedMatObjTag;
        delete this.shipTagMesh;
        this.removeObserver(el);
      }
    }
  }
  matTagClear() {
    if (this.datauxview) {
      var elem = this.datauxview.getElementId('materialPointer');
      let dom: any = document.getElementsByClassName("mat_nm_label")[0];
      if (dom) {
        dom.style.display = "none";
        delete this.selectedMatObjTag;
        // delete this.shipTagMesh;
        this.removeObserver(elem);
        // this.hideHighlightSelection();
      }
    }
  }

  invertColor(col) {
    col = col.toLowerCase();
    const colors = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f']
    let inverseColor = '#'
    col.replace('#', '').split('').forEach(i => {
      const index = colors.indexOf(i)
      inverseColor += colors.reverse()[index]
    })
    return inverseColor
  }
  clearLayoutTagObjectList(layoutTagList) {
    let tagList = layoutTagList ? layoutTagList : [];
    if (tagList.length > 0) {
      tagList.forEach((ele, i) => {
        this.clearLayoutTagObject(ele);
        // this.layoutTagList.splice(i, 1);
      });
      this.layoutTagList = [];
    }
  }
  clearLayoutTagObject(ele) {
    let id = "layout_" + ele.tagShapeId;
    var elem = this.datauxview.getElementId(id);
    if (elem) {
      this.dfx.modify(elem, { profile: 'hidden' });
      this.dfx.remove(elem);
      this.removeObserver(elem);
      delete this.datauxview._allElements[id];
    }
  }
  shipPointerList: any = [];
  createMatObjectPoint(ele) {
    let id = "layout_" + ele.tagShapeId;
    if (this.datauxview) {
      if (ele.tagShapeId == "cylinder") {

      }
      var elem = this.datauxview.getElementId(id);
      var pos = ele.position;
      var size = 5;
      let prof = "red";
      if (!elem) {
        let profiles = this.profiles;
        let settings = { 'geometry': { 'orientation': { x: 0, y: 0, z: 0 }, position: { x: pos.x, y: pos.y, z: pos.z }, size: size } };

        this.datauxview.addElement("sphere", id, profiles, settings);
        let buildModel = this.datauxview.getElementId(id);
        // let matColor = this.getColor("#FFFFFF");

        // if (color === material.toLowerCase()) {
        //   material = this.invertColor(material)
        //   // cmesh.outlineWidth = 0.1;
        //   // cmesh.outlineColor = matColor;
        //   // cmesh.renderOutline = true;
        // }
        //cmesh.outlineColor = "#FFFFFF";
        this.dfx.modify(buildModel, { profile: prof });


        // if (ele.code != "--") {
        //   if (ele['name'].includes("marker_")) {
        //     // let arrSplit=e['name'].split("_");
        //     // let id=arrSplit.length==3?arrSplit[0]+"_"+arrSplit[1]:'';
        //     // if(id){
        //     //   if(this.cselects&&this.cselects[id]){

        //     //   }
        //     // }
        //     this.shipPointerList.push(ele);
        //   }
        // }

        // this.datauxview.getDatascape().attach(buildModel, {
        //   actions: {
        //     pidkUp: [(evt, elem) => {
        //       if (evt.button === 0) {
        //         let props = this.dfx.props(elem);
        //       console.log('elem',elem,props,this.layoutData['tagPoints'])
        //       }
        //     }],

        //   }
        // });
        // this.dfx.setAsParent(m, [cmesh]);
        // cmesh.parent=m
        this.showLayoutTag(ele, true);
        // if(this.layoutTagList.length>0){
        //   let idx = this.layoutTagList.findIndex(e=> e.id==ele.id);
        //   if(idx==-1){
        //     this.layoutTagList.push(ele);
        //     this.showLayoutTag(ele, true);
        //   }
        // }else{
        //   this.layoutTagList.push(ele);
        //   this.showLayoutTag(ele, true);
        // }
      } else {
        this.dfx.modify(elem, { profile: prof });
        this.showLayoutTag(ele, true);
      }
    }
  }
  layoutTagList: any = [];
  showLayoutTag(ele, flag) {
    if (!this.datauxview) {
      return;
    }
    let el = this.datauxview.getElementId("layout_" + ele.tagShapeId);
    if (!el) {
      return;
    }
    flag = false;
    if (flag) {
      // let dom: any = document.getElementById(ele.parentId+'_'+ele.id);
      if (this.layoutTagList.length > 0) {
        let idx = this.layoutTagList.findIndex(e => e.tagShapeId == ele.tagShapeId);
        if (idx == -1) {
          this.layoutTagList.push(ele);
          this.addObserver(el);
        }
      } else {
        this.layoutTagList.push(ele);
        this.addObserver(el);
      }
    } else {
      let dom: any = document.getElementById("layout_" + ele.tagShapeId);
      if (dom) {
        dom.style.display = "none";
        let idx = this.layoutTagList.findIndex(e => e.tagShapeId == ele.tagShapeId);
        if (idx > -1) {
          let layout = this.layoutTagList[idx]
          // this.clearLayoutTagObject(layout)
          this.layoutTagList.splice(idx, 1);
          this.removeObserver(el);
          this.dfx.remove(el);
          this.dfx.modify(dom, { profile: 'hidden' });
          //delete this.datauxview._allElements[el];
        }

      }
    }
  }
  commentData: any;
  commentTagList: any = [];
  commentId: any;
  showCommentTag(ele, flag) {
    if (!this.datauxview) {
      return;
    }
    let el = this.datauxview.getElementId(ele.name);
    if (!el) {
      return;
    }
    // flag=false;
    if (flag) {
      if (this.commentTagList.length > 0) {
        let idx = this.commentTagList.findIndex(e => e.name == ele.name);
        if (idx == -1) {
          this.commentTagList.push(ele);
          this.addObserver(el);
        }
      } else {
        this.commentTagList.push(ele);
        this.addObserver(el);
      }
    } else {
      let dom: any = document.getElementById("comment_" + ele.name);
      if (dom) {
        dom.style.display = "none";
        let idx = this.commentTagList.findIndex(e => e.name == ele.name);
        if (idx > -1) {
          this.commentTagList.splice(idx, 1);
          this.removeObserver(el);
        }

      }
    }
  }

  dfxTagList: any = [];
  dfxTagShowHide(id, flag) {
    if (!this.datauxview) {
      return;
    }
    let el = this.datauxview.getElementId(id);
    if (!el) {
      return;
    }
    if (flag) {
      // let dom: any = document.getElementById(ele.parentId+'_'+ele.id);
      if (this.dfxTagList.length > 0) {
        let idx = this.dfxTagList.findIndex(e => e == id);
        if (idx == -1) {
          this.dfxTagList.push(id);
          this.addObserver(el);
        }
      } else {
        this.dfxTagList.push(id);
        this.addObserver(el);
      }
    } else {
      let dom: any = document.getElementById("dfxTag_" + id);
      if (dom) {
        dom.style.display = "none";
        let idx = this.dfxTagList.findIndex(e => e == id);
        if (idx > -1) {
          let layout = this.dfxTagList[idx]
          // this.clearLayoutTagObject(layout)
          this.dfxTagList.splice(idx, 1);
          this.removeObserver(el);
          //delete this.datauxview._allElements[el];
        }

      }
    }
  }

  hideTagObjects(tag) {
    let id = "layout_" + tag.tagShapeId;
    if (this.datauxview) {
      if (this.port == 'slms') {
        var idx = this.showHideTagPointer.findIndex(obj => obj == id)
        this.showHideTagPointer.splice(idx, 1)
        // var elem = this.datauxview.getElementId(id);
        // this.dfx.modify(elem, { profile: 'transparent' });
        // if(elem){
        //   this.dfx.modify(elem, { profile: 'transparent' });
        let mesh = this.getMesh(id);
        if (mesh) {
          mesh.setEnabled(false);
          //   // let matid = 'location_green';
          //   if(this.scene){
          //     let mat = this.scene.getMaterialByName('transparent');
          //     if (mat) {
          //       mesh['material'] = mat;
          //     }
          //   }
        }
        // }
      }
    }
  }
  scene;
  port: any;
  messIdDetails: any = [];
  createMatTagObjectPoint(tag: any, activeTag = undefined) {
    let id = "layout_" + tag.tagShapeId;
    let shapeId = tag.shapeId || '';
    var messIdDetailsData = {
      "id": id,
      "typeCollection": '',
      "colluded": false,
    }
    this.messIdDetails.push(messIdDetailsData);
    // console.log("this.messIdDetails", this.messIdDetails,tag);
    if (this.datauxview) {
      if (this.port == 'slms' || this.port == 'babcock') {
        var elem = this.datauxview.getElementId(id);
        var size = 0.5;
        if (tag.shapeId == 'cone_m') {
          tag.position.x = -tag.position.x;
          tag.position.y = 3.3;
          size = 0.2
        }
        if (tag.shapeId == 'sphere') {
          size = 0.01
        }
        if (tag.shapeId.includes("avatar")) {
          tag.position.x = -tag.position.x;
          size = 1.5
        }
        if (tag.shapeId.includes("teams")) {
          tag.shapeId = 'avatar2';
          tag.position.x = -tag.position.x;
          size = 1.5
        }
        tag.position = tag.position;
        var pos = tag.position;
        let prof = "location_green";
        if (activeTag) {
          this.showHideTag(activeTag, false)
        }
        if (!elem) {
          let profiles = this.profiles;
          let settings = { 'geometry': { 'orientation': { x: 0, y: 0, z: 0 }, position: pos, size: size } };
          this.datauxview.addElement(tag.shapeId, id, profiles, settings);
          let buildModel = this.datauxview.getElementId(id);
          if (tag.shapeId == "cylinder") {
            this.dfx.setElementScale(buildModel, [2, 0.1, 2]);
          }
          // this.dfx.modify(buildModel, { profile: prof });
          let mesh = this.getMesh(id);
          if (mesh) {
            if (this.scene) {
              if (tag.shapeId.includes("avatar")) {
                if (shapeId.includes('teams')) {
                  this.dfx.modify(buildModel, { profile: 'brown' })
                } else {
                  this.dfx.modify(buildModel, { profile: 'regular' })
                }
              } else {
                let prof = tag.shapeId == "cylinder" ? "orange" : "red";
                let mat = this.scene.getMaterialByName(prof);
                if (mat) {
                  mesh['material'] = mat;
                }
              }
            }
          }
        } else {
          let mesh = this.getMesh(id);
          let buildModel = this.datauxview.getElementId(id);
          if (mesh) {
            mesh.setEnabled(true);
            if (this.scene) {
              if (tag.shapeId.includes("avatar")) {
                if (shapeId.includes('teams')) {
                  this.dfx.modify(buildModel, { profile: 'brown' })
                } else {
                  this.dfx.modify(buildModel, { profile: 'regular' })
                }
              }
            }
          }
        }
      } else {
        var elem = this.datauxview.getElementId(id);
        var size = 5;
        if (tag.shapeId == 'cone_m') {
          tag.position.x = -tag.position.x;
          tag.position.y = 3.3;
          size = 2
        }
        if (tag.shapeId.includes("avatar")) {
          tag.position.x = -tag.position.x;
          size = 1.5
        }
        if (tag.shapeId.includes("teams")) {
          tag.shapeId = 'avatar2';
          tag.position.x = -tag.position.x;
          size = 1.5
        }
        var pos = tag.position;
        let prof = tag.shapeId == "cylinder" ? "orange" : "red";
        if (activeTag) {
          this.showHideTag(activeTag, false)
        }
        if (!elem) {
          let profiles = this.profiles;
          let settings = { 'geometry': { 'orientation': { x: 0, y: 0, z: 0 }, position: pos, size: size } };
          this.datauxview.addElement(tag.shapeId, id, profiles, settings);
          let buildModel = this.datauxview.getElementId(id);
          if (tag.shapeId == "cylinder") {
            this.dfx.setElementScale(buildModel, [2, 0.1, 2]);
          }
          if (tag.shapeId.includes("avatar")) {
            if (shapeId.includes('teams')) {
              this.dfx.modify(buildModel, { profile: 'orange' })
            } else {
              this.dfx.modify(buildModel, { profile: 'regular' })
            }
          } else {
            this.dfx.modify(buildModel, { profile: prof });
            var idx = this.showHideTagPointer.findIndex(obj => obj == id);
            if (idx == -1) {
            }
            this.showHideTagPointer.push(id)
          }
          // this.addObserver(buildModel)
        } else {
          // this.showHideTag(tag, true)
        }
      }
    }
  }
  getMesh(id) {
    let el = this.datauxview.getElementId(id);
    let mesh = this.dfx.getElementMesh(el);
    return mesh;
  }
  showHideTag(tag, bShowTag) {
    if (this.datauxview && tag && tag.tagShapeId) {
      let dom: any = document.getElementById("layout_" + tag.tagShapeId);
      let el = this.datauxview.getElementId("layout_" + tag.tagShapeId);
      // bShowTag=false
      if (dom) {
        if (dom.style.display == "none" && bShowTag) {
          var idx = this.showHideTagPointer.findIndex(obj => obj == "layout_" + tag.tagShapeId);
          if (idx == -1) {
            this.showHideTagPointer.push("layout_" + tag.tagShapeId)
          }

          this.addObserver(el);
          let mesh = this.getMesh("layout_" + tag.tagShapeId);
          if (mesh) {
            mesh.setEnabled(true);
            //   let matid = 'location_green';
            //   if(this.scene){
            //     let mat = this.scene.getMaterialByName('red');
            //     if (mat) {
            //       mesh['material'] = mat;
            //     }
            //   }
          }
          // this.dfx.modify(el, { profile: 'red' });
          dom.style.display = "block";
        } else {
          var idx = this.showHideTagPointer.findIndex(obj => obj == "layout_" + tag.tagShapeId)
          this.showHideTagPointer.splice(idx, 1)
          this.removeObserver(el);
          // this.dfx.modify(el, { profile: 'hidden' });
          dom.style.display = "none";
        }

      }
    }
  }

  /* *
   * show/hide static layout popup
   */
  showHideStaticTag(tag, bShowTag) {
    if (this.datauxview && tag && tag.tagShapeId) {
      let id = "staticLayout_" + tag.tagShapeId;
      if (bShowTag) {
        const found = this.showHideStaticTagPointer.some(el => el === id);
        if (!found) {
          this.showHideStaticTagPointer.push(id);
        }
      } else {
        let idx = this.showHideStaticTagPointer.findIndex(obj => obj == id);
        if (idx > -1) {
          this.showHideStaticTagPointer.splice(idx, 1);
        }
      }
    }
  }

  hideStaticTag(tag) {
    if (this.datauxview && tag && tag.tagShapeId) {
      let id = "staticLayout_" + tag.tagShapeId;
      let idx = this.showHideStaticTagPointer.findIndex(obj => obj == id);
      if (idx > -1) {
        this.showHideStaticTagPointer.splice(idx, 1);
      }
      this.broadcastInfo({ src: 'navyApp', event: 'layoutTagClose', data: tag });
    }
  }

  /* * *
   * method for location show tag
   */
  showHideLocationTagPointer = [];
  showLocationTag(tag) {
    let tagShapeId = tag["locationShape"] || "sphere";
    let id = "location_" + tag["_id"];

    if (this.datauxview) {
      if (this.port == 'slms') {
        var elem = this.datauxview.getElementId(id);
        var size = 0.5;

        if (tagShapeId == 'cone_m') {
          tag.location.x = -tag.location.x;
          tag.location.y = 3.3;
          size = 0.2
        }

        if (tagShapeId.includes("avatar")) {
          tag.location.x = -tag.location.x;
          size = 1.5
        }

        var pos = tag.location || { x: 0, y: 0, z: 0 };

        if (!elem) {
          let profiles = this.profiles;
          let settings = { 'geometry': { 'orientation': { x: 0, y: 0, z: 0 }, position: pos, size: size } };
          this.datauxview.addElement(tagShapeId, id, profiles, settings);
          let buildModel = this.datauxview.getElementId(id);
          let mesh = this.getMesh(id);
          if (mesh && !tagShapeId.includes("avatar")) {
            if (this.scene) {
              let mat = this.scene.getMaterialByName('red');
              if (mat) {
                mesh['material'] = mat;
              }
            }
          }
          this.showHideLocationTagPointer.push(id);
          this.addObserver(buildModel);
        }

        else {
          let mesh = this.getMesh(id);
          if (mesh && !tagShapeId.includes("avatar")) {
            if (this.scene) {
              let mat = this.scene.getMaterialByName('red');
              if (mat) {
                mesh['material'] = mat;
              }
            }
          }
          let buildModel = this.datauxview.getElementId(id);
          this.dfx.modify(buildModel, { profile: 'red' });
          this.showHideLocationTagPointer.push(id);
          this.addObserver(buildModel);
        }

      }
    }
  }

  /* * *
   * show/hide location show tag
   */
  showHideLocationTag(tag, status = null) {
    if (this.datauxview && tag && tag["_id"]) {
      let id = "location_" + tag["_id"];
      let el = this.datauxview.getElementId(id);
      let idx = this.showHideLocationTagPointer.findIndex(obj => obj == id);
      if (idx > -1) {
        this.showHideLocationTagPointer.splice(idx, 1);
      }
      this.removeObserver(el);
      this.dfx.modify(el, { profile: 'hidden' });
      if (status != null) {
        this.broadcastInfo({ src: 'navyApp', event: 'locationTagClose', tag: tag });
      }
    }
  }

  /* * *
  * modify elemtn
  */
  modifyElement(id, shapeId, position) {
    if (shapeId == 'cone_m') {
      position.x = -position.x;
    }
    if (shapeId.includes("avatar")) {
      position.x = -position.x;
    }
    const obj = {
      "name": id,
      "place": {
        "pos": position,
        "rot": { "x": null, "y": null, "z": null }
      }
    };
    const fund = this.datauxview._allElements[id];
    if (fund) {
      this.datauxview.modifyElement(obj);
    }
  }

  /**
   * show tag notification message
   */
  showChatNotificationTag = [];
  showChatNotification(tag) {
    if (this.datauxview && tag && tag["tagShapeId"]) {
      let id = "layout_" + tag["tagShapeId"];
      let el = this.datauxview.getElementId(id);
      if (!el) return;
      let obj = { id: tag["tagShapeId"], message: tag['notification'] || "", name: tag['senderName'] };
      const idx = this.showChatNotificationTag.findIndex(obj => obj.id == tag["tagShapeId"]);
      if (idx > -1) {
        this.showChatNotificationTag[idx]['message'] = obj['message'];
      } else {
        this.showChatNotificationTag.push(obj);
      }
      this.addObserver(el);
    }
  }

  /**
   * hide tag notification message
   */
  hideChatNotification(tag) {
    if (this.datauxview && tag && tag["id"]) {
      let id = "layout_" + tag["id"];
      let el = this.datauxview.getElementId(id);
      if (!el) return;
      let idx = this.showChatNotificationTag.findIndex(obj => obj.id == tag["id"]);
      if (idx > -1) {
        this.showChatNotificationTag.splice(idx, 1);
      }
      this.removeObserver(el);
    }
  }


}
