import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpModule,JsonpModule } from '@angular/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from "@angular/forms";
import { DatauxviewModule } from 'datauxview';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatStepperModule } from '@angular/material/stepper';
import { MsalModule, MsalInterceptor } from '@azure/msal-angular';
// import { MaterialModule } from './material.module';
// import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
// import { MatProgressBarModule } from '@angular/material/progress-bar';
// import { MatSelectModule } from '@angular/material/select';
import { from } from 'rxjs';
import { ColorPickerModule } from 'ngx-color-picker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxFileDragDropModule } from "ngx-file-drag-drop";
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import {ScrollingModule} from '@angular/cdk/scrolling';
// import { MatTimepickerModule } from 'mat-timepicker';

//components
import { NavyappComponent, PublishDialog,PopupModelComponent } from './navyapp/navyapp.component'
import { HeaderComponent } from './header/header.component';
import { ControltoolsComponent } from './controltools/controltools.component';
import { SidepanelComponent } from './sidepanel/sidepanel.component';
import { SliderComponent } from './slider/slider.component';
import { TagComponent } from './tag/tag.component';
import { TableComponent } from './table/table.component';
import { MessageScreenComponent } from './message-screen/message-screen.component';
import { ExcelUploadComponent } from './excel-upload/excel-upload.component';
import { CommentsComponent } from './comments/comments.component';
import { ShipDetailComponent, PopupDialog } from './ship-detail/ship-detail.component';

//providers
import { ConfigService } from './services/config.service';
import { ControllerService } from './services/controller.service';
import { CommunicationService } from "./services/communication.service";
import { TagService } from './services/tag.service';
import { ReportService } from './services/report.service';
import { ActivityVisualizationService } from './services/activity-visualization.service';
import { DateHightlightComponent, CustomDateComponent } from './date-hightlight/date-hightlight.component';
import { StepperFormComponent } from './stepper-form/stepper-form.component';
import { FilterPipe } from './pipe/filter.pipe';
import { customInterceptor } from './services/http.interceptor';
import { ChitappComponent } from './chitapp/chitapp.component';
import { RadhazVisService } from './services/radhaz.service';
import {DrawService} from './services/draw.service';
import { CutOffEffect } from './services/effects/cutoff-effect.service';
import { ScheduleComponent } from './schedule/schedule.component';
import { ScheduleViewComponent } from './schedule-view/schedule-view.component';
import { RoundsappComponent, DialogStatus } from './roundsapp/roundsapp.component';
import { DashboardComponent, DialogStatusUpdate } from './dashboard/dashboard.component';
import { TagoutComponent } from './tagout/tagout.component';
import { KHASystemService } from './services/systems/kha-systems.service';
import { CommonModule } from '@angular/common';
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, '/assets/i18n/', '-lang.json');
}
import { CalenderScheduleComponent } from './calender-schedule/calender-schedule.component';
import { LayoutComponent } from './layout/layout.component';
import { ShipSidepanelComponent } from './ship-sidepanel/ship-sidepanel.component';
import { ShipCommentsComponent } from './ship-comments/ship-comments.component';
import { AdminStateBoardComponent } from './admin-state-board/admin-state-board.component';
import { LayoutCardComponent } from './layout-card/layout-card.component';
import { MobilePanelComponent } from './mobile-panel/mobile-panel.component';
import { ConfirmationPopupComponent,PopupComponent } from './confirmation-popup/confirmation-popup.component';
import { TagPointComponent } from './tag-point/tag-point.component';
import { SocketService } from './services/socketService';
import { MarkerComponent } from './marker/marker.component';
import { TurnoverSheetCardComponent } from './turnover-sheet-card/turnover-sheet-card.component';
import { SidepanelToggleComponent } from './sidepanel-toggle/sidepanel-toggle.component';
import { SlmsSliderComponent } from './slms-slider/slms-slider.component';
import { ScheduleAppComponent } from './schedule-app/schedule-app.component';
import { VisitorComponent } from './visitor/visitor.component';
import { TimeSliderComponent } from './time-slider/time-slider.component';
import { ChatappComponent } from './chatapp/chatapp.component';
import { HotWorkingsComponent } from './hot-workings/hot-workings.component';
import { DfxModelListComponent } from './dfx-model-list/dfx-model-list.component';
import { TeamsPanelComponent } from './teams-panel/teams-panel.component';
import * as fs from 'fs';
import { LoginScreenComponent } from './login-screen/login-screen.component';
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
var config = {
  clientId: "a3cf3fb4-11fb-491a-aabd-5e9e00548968",
  authority: 'https://login.microsoftonline.com/c201b39a-972b-4dfc-ae05-0d66ce8adfb0',
  redirectUri: window.origin + '/',
};

let hostname = window.location.hostname;
if (hostname === 'localhost') {
  config['clientId'] = 'eeb166ab-6393-4557-b8d3-9a688a604754';
} else if (hostname === 'navyapp.azurewebsites.net') {
  config['clientId'] = 'a3cf3fb4-11fb-491a-aabd-5e9e00548968';
} else if (hostname === 'navy.datifex.net') {
  config['clientId'] = 'a3cf3fb4-11fb-491a-aabd-5e9e00548968';
} else if (hostname === 'demo.datifex.com') {
  config['clientId'] = 'a3cf3fb4-11fb-491a-aabd-5e9e00548968';
} else if (hostname === 'navy.datifex.com') {
  config['clientId'] = 'a3cf3fb4-11fb-491a-aabd-5e9e00548968';
}
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ControltoolsComponent,
    SidepanelComponent,
    SliderComponent,
    NavyappComponent,
    PublishDialog,
    PopupDialog,
    PopupModelComponent,
    TagComponent,
    TableComponent,
    MessageScreenComponent,
    ExcelUploadComponent,
    DateHightlightComponent,
    CustomDateComponent,
    StepperFormComponent,
    FilterPipe,
    ChitappComponent,
    ScheduleComponent,
    ScheduleViewComponent,
    RoundsappComponent,
    DialogStatus,
    DashboardComponent,
    DialogStatusUpdate,
    TagoutComponent,
    CalenderScheduleComponent,
    LayoutComponent,
    CommentsComponent,
    PopupComponent,
    ShipDetailComponent,
    ShipDetailComponent,
    ShipSidepanelComponent,
    ShipCommentsComponent,
    AdminStateBoardComponent,
    LayoutCardComponent,
    MobilePanelComponent,
    ConfirmationPopupComponent,
    TagPointComponent,
    MarkerComponent,
    TurnoverSheetCardComponent,
    SidepanelToggleComponent,
    SlmsSliderComponent,
    ScheduleAppComponent,
    VisitorComponent,
    TimeSliderComponent,
    ChatappComponent,
    HotWorkingsComponent,
    DfxModelListComponent,
    TeamsPanelComponent,
    LoginScreenComponent
  ],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserModule,
    FormsModule,
    HttpModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DatauxviewModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatDividerModule,
    MatCardModule,
    MatListModule,
    MatMenuModule,
    MatChipsModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatExpansionModule,
    MatSnackBarModule,
    MatDialogModule,
    MatFormFieldModule,
    MatDividerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatRadioModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatGridListModule,
    MatTooltipModule,
    MatSliderModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatSelectModule,
    MatDatepickerModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    NgxFileDragDropModule,
    DragDropModule,
    // MatStepperModule,
    MatAutocompleteModule,
    Ng2SearchPipeModule,
    CommonModule,  BrowserModule,
    ColorPickerModule,
    ScrollingModule,
    MsalModule.forRoot({
      auth: config,

      // auth: {
      //   clientId: "a3cf3fb4-11fb-491a-aabd-5e9e00548968",
      //   authority: 'https://login.microsoftonline.com/c201b39a-972b-4dfc-ae05-0d66ce8adfb0',
      //   redirectUri: "https://navyapp.azurewebsites.net/",

      //   // clientId: "eeb166ab-6393-4557-b8d3-9a688a604754",
      //   // authority: 'https://login.microsoftonline.com/c201b39a-972b-4dfc-ae05-0d66ce8adfb0',
      //   // redirectUri: "http://localhost:4200",
      // },

      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE, // set to true for IE 11
      },
    },
      {
        popUp: !isIE,
        consentScopes: [
          'user.read',
          'openid',
          'profile',
          'directory.read'
        ],
        protectedResourceMap: [
          ['https://graph.microsoft.com/v1.0/me', ['user.read']],
          ['https://graph.microsoft.com/v1.0/users', ['directory.read']],
          // ["https://navyapp.azurewebsites.net/", ["api://854fcfb1-81ec-4e33-911f-9041e2b3fdd9/access_api"]]
        ],
        extraQueryParameters: {}
      })
  ],
  entryComponents: [
    DateHightlightComponent,
    CustomDateComponent,
    PublishDialog,
    PopupDialog,
    PopupModelComponent,
    DialogStatus,
    DialogStatusUpdate,
    PopupComponent
  ],
  exports: [
    BrowserModule,
    HttpModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatCardModule,
    MatListModule,
    MatMenuModule,
    MatChipsModule,
    MatCheckboxModule,
    MatDividerModule,
    MatButtonToggleModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatDialogModule,
    MatFormFieldModule,
    MatDividerModule,
    MatNativeDateModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatGridListModule,
    MatTooltipModule,
    MatSliderModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatSelectModule,
    DragDropModule,
    MatAutocompleteModule,
    MatStepperModule,
    ScrollingModule
  ],
  providers: [
    ConfigService,
    ControllerService,
    CommunicationService,
    RadhazVisService,
    TagService,
    ReportService,
    KHASystemService,
    ActivityVisualizationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: customInterceptor,
      multi: true
    },
    DrawService,
    CutOffEffect,
    SocketService
  ],
  schemas:[
    CUSTOM_ELEMENTS_SCHEMA,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
