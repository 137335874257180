import { Component, OnInit } from '@angular/core';
import { CommunicationService } from "../services/communication.service";
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-mobile-panel',
  templateUrl: './mobile-panel.component.html',
  styleUrls: ['./mobile-panel.component.scss'],
  providers: [DatePipe]
})
export class MobilePanelComponent implements OnInit {

  constructor(private datePipe: DatePipe,private communicationServ: CommunicationService) { }

  broadcastSubs
  layoutData:any=[];
  sidepanelLayoutData:any=[];
  sidepanelNotesData:any=[];
  sidepanelStatusObject:any=[];
  ngOnInit(): void {
    this.broadcastSubs = this.communicationServ.getInstance()
    .subscribe((data: any) => {
      if(data.src=="material"){
        if (data.event === 'updateLayoutData') {
          this.layoutData = data['data'];
        }
      }
      if(data.src=="sidepanel"){
        if(data.event == "reconstruct"){
            this.sidepanelLayoutData = data['layout'].length > 0 ? data['layout'].filter((arr, index, self) =>
            index === self.findIndex((t) => (t.layoutId == arr.layoutId))) : [];    
          this.sidepanelNotesData = data['notes'];
          console.log("reconstruct", this.sidepanelLayoutData,this.sidepanelNotesData );
        }
      }  
      // if(data.src=="sidepanel"){
      //   if(data.event == "reconstruct"){
      //       this.sidepanelLayoutData = data['layout'].length > 0 ? data['layout'].filter((arr, index, self) =>
      //       index === self.findIndex((t) => (t.layoutId == arr.layoutId))) : [];  
      //       // this.orderList.forEach((ele,i) => {
      //       //   this.sidepanelLayoutData[i]['order']=ele;
      //       // });
      //       // this.arrangeOrder();
      //     console.log("reconstruct", this.sidepanelLayoutData );
      //     this.sidepanelNotesData = data['notes']
      //   }
      if(data.src=="navyApp")   {
        if (data.event === 'updateStatus') {
          this.sidepanelStatusObject = data.data;
          console.log("updateStatus", data.data);
        }
      }
    })
  }

  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }

filteredNotesList:any=[];
selectedLayout:any;
notesPanel:any=false;
  activeLayout(layout,i){
    this.selectedLayout = layout;
    this.notesPanel = true;
    console.log(layout,this.sidepanelNotesData );
    this.filteredNotesList = this.sidepanelNotesData.filter(e=>e.layoutId==layout.layoutId)
    console.log("layout",this.filteredNotesList );
  }

  closeLayout(){
    if(this.notesPanel){
      this.notesPanel=false;
      delete  this.selectedLayout; 
      return
    }
    // if(this.selectedLayout){
      this.broadcastInfo({src:"mobilePanel",event:"closeLayout"})
    //   return
    // }
  }
  getDate(date) {
    return this.datePipe.transform(date, "MMM d, y, h:mm a")
  }
  statusChange(){

  }
}
