import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, HostListener } from '@angular/core';
import { ConfigService } from '../services/config.service';
import { ControllerService } from '../services/controller.service';
import { CommunicationService } from "../services/communication.service";
import { KHASystemService } from '../services/systems/kha-systems.service';
import { TagService } from '../services/tag.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

declare var BABYLON;
@Component({
  selector: 'app-dfx-model-list',
  templateUrl: './dfx-model-list.component.html',
  styleUrls: ['./dfx-model-list.component.scss']
})

export class DfxModelListComponent implements OnInit {
  @ViewChild('datauxview', { static: true }) public datauxview: any;
  project_config: any;
  dfx: any;
  lightSettings;
  scene;
  vesselLoading: any = false;
  @Input() selectedModel: any;
  @Input() cfbport: any;
  arrControlBtnProps = [
    { name: 'home', icon: 'home', state: true },
    { name: '2D', icon: 'dimension', state: false },
    { name: 'zoomin', icon: 'add', state: true },
    { name: 'zoomout', icon: 'remove', state: true },
    { name: 'drag', icon: 'swipe', state: false, ticon: 'do_not_touch' },
    { name: 'reset', icon: 'refresh', state: false, ticon: 'refresh' },
    { name: 'animation', icon: 'pause', state: false, ticon: 'play_arrow' },
    { name: 'explodeparts', icon: 'grain', state: false }
  ];

  viewMode = 'solid';
  viewObjectMode = 'solid';

  camviews = {
    "idg": {
      "home": { "target": { "x": 0, "y": 0, "z": 0 }, "distance": 30, "yaw": 300, "pitch": 27.85 },
      "top": { "target": { "x": 0, "y": 0, "z": 0 }, "distance": 30, "yaw": 270, "pitch": 89.9 },
      "left": { "target": { "x": 0, "y": 0, "z": 0 }, "distance": 30, "yaw": 269.9, "pitch": 27.85 },
      "right": { "target": { "x": 0, "y": 0, "z": 0 }, "distance": 30, "yaw": 89.9, "pitch": 27.85 },
      "back": { "target": { "x": 0, "y": 0, "z": 0 }, "distance": 30, "yaw": 179.9, "pitch": 27.85 },
      "front": { "target": { "x": 0, "y": 0, "z": 0 }, "distance": 30, "yaw": 0.09, "pitch": 27.85 },
      "ocean": { "target": { "x": 0, "y": 1.5, "z": 0 }, "distance": 30, "yaw": 300, "pitch": 27.85 },
      "animation": { "target": { "x": 0, "y": 0, "z": 0 }, "distance": 120, "yaw": 0.09, "pitch": 27.85 },
      "animation2": { "target": { "x": 0, "y": 0, "z": 0 }, "distance": 300, "yaw": 0.09, "pitch": 27.85 },
    }
  }

  cam_mode = '3D';
  isMouseDown
  mousestate = "up";
  khaConfig
  userRole: any;
  doubleClick = 0;
  disableTransCtrl = false;
  styleLength: any = '';
  excludeShipmodel = "AOPS,CSC";
  excludedrag: boolean = false;
  excldueexplode: boolean = false;
  dragsel: boolean = false;

  @Input() set _disableTrans(v: any) {
    this.disableTransCtrl = v;
  }
  @Input() focusMode: boolean = false;

  constructor(private communicationServ: CommunicationService, private tagServ: TagService, private configService: ConfigService, private ctrlServ: ControllerService, private cdr: ChangeDetectorRef, private CommunicationServ: CommunicationService,
    private _khaConfig: KHASystemService,
    private http: HttpClient) {
    this.khaConfig = this._khaConfig;
  }

  broadcastSubs: any;
  ngOnInit(): void {
    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src == "sidepanel") {
          if (data.event == "f35Anim") {
            let f35AnimData = data['data'];
            let pid = f35AnimData['animId'];
            this.startF35AnimModel(pid, f35AnimData['state']);
          }
          if (data.event == 'f35AnimAll') {
            this.f35AnimList = data['data'];
            if (data.key) {
              this.startF35Anim();
            } else {
              this.stopF35Anim();
            }
          }
        }
      })
  }

  /* * * * *
  * init objects
  * loading all the static objects from objects.json
  * * * * * */
  initObjects(cbck) {
    let so = this.selectedModel;
    let objectjson = "";
    if (this.selectedModel == "Barge-01") {
      objectjson = "objects-barge-models";
    }
    else if (this.selectedModel == "Bulk_Carrier") {
      objectjson = "objects-bulkcarrier-models";
    }
    else if (this.selectedModel == "Tanker") {
      objectjson = "objects-tanker-models";
    }
    else if (this.selectedModel == "Halifax") {
      objectjson = "objects-halifax-models";
    }
    else if (this.selectedModel == "Asterix") {
      objectjson = "objects-asterix-models";
    }
    else if (this.selectedModel == "CSC") {
      objectjson = "objects-csc-models";
    }
    else if (this.selectedModel == "AOPS") {
      objectjson = "objects-aops-models";
    }
    else if (this.selectedModel == "Submarine") {
      objectjson = "objects-submarine-models";
    }
    else {
      objectjson = "objects-all-models";
      this.excludedrag = true;
      this.excldueexplode = true;
    }
    if (objectjson != "") {
      this.datauxview.loadAssets(objectjson, 'json', (objectstatus) => {
        if (objectstatus) {
          this.datauxview.renderAssets(objectjson, 'json', (objectstatus) => {
            if (objectstatus) {
              this.dfx = this.datauxview.getDatascape();
              if (window.innerWidth < 1024) {
                this.datauxview.getDatascape().moveCamera({ distance: 200 });
              }
              this.configService.loadFile('../../assets/sdms/data/ship-comps.json').then((comp: any) => {
                let selectedShipComps = comp.filter((c) => { return c.shipmodel == this.selectedModel; });
                if (selectedShipComps.length > 0) {
                  this.shipCompsCntls["Components"] = selectedShipComps[0].controls;
                  this.bckupShip_comp_ctl_list = JSON.stringify(this.shipCompsCntls);
                }
                if (this.selectedModel == "Submarine") {
                  this.excludedrag = true;
                  this.excldueexplode = true;
                }
                else if (this.selectedModel == "Asterix" ||
                  this.selectedModel == "Halifax" ||
                  this.selectedModel == "CSC" ||
                  this.selectedModel == "AOPS"||this.selectedModel == "Ice_Breaker") {
                  this.excludedrag = true;
                }

                this.dfx = this.datauxview.getDatascape();
                this.scene = this.dfx.getCamera().getScene();
                this.ctrlServ.init(this.datauxview);
                this.tagServ.init(this.datauxview);
                this.tagServ.datauxview = this.datauxview;
                this.khaConfig.datauxview = this.datauxview;

                let mesh = this.ctrlServ.getMesh(this.selectedModel);
                mesh.rotationQuaternion = null;
                this.excludeRHIBLighting(mesh);

                let ship = this.datauxview.getElementId(this.selectedModel);

                // filter ship components 
                this.filterComps = this.selectedModel == "Halifax" ? this.filterCompsList.split(',') : [];

                this.processModel();
                if (!this.excludedrag) {
                  this.deconstructShip(ship, mesh);
                }
                if (!this.excldueexplode) {
                  this.newinitexplodemesh();
                }
                cbck();
              });
            }
          }, (id, pointer) => this.animate(id, pointer));
        }
      }, (id, pointer) => this.animate(id, pointer));
    }
  }

  posX = 0;
  posY = 0;
  posZ = 0;

  @Input() activeModel: any = 'idg';
  @Input() activeMode: any = 'idg';

  focus_title: string = "";
  shipComps = []
  cameraGroup = []
  sensors = []
  cameras = [];
  children = {};
  dbobj = {};
  compDefPos = {};
  shipElems;
  dbchildobj = {};
  mesh_explode = false;
  // used for filtering components for explode/drag
  filterCompsList = "WARNINGS,WARNINGS_V1";
  filterComps = []

  shipCompsCntls = {};
  bckupShip_comp_ctl_list: any = [];

  newinitexplodemesh() {
    let meshes = [];
    if (!this.explosion) {
      let cmesh = this.ctrlServ.getMesh(this.selectedModel);
      cmesh.getChildren().forEach((c) => {
        meshes.push(c);
      })
      let cm = null;
      let centerMesh = this.ctrlServ.getMesh(cm);
      this.explosion_cm = centerMesh;
      this.explosion = new BABYLON.MeshExploder(meshes)
    }
  }

  toggleComponents() {
    let cmesh = this.ctrlServ.getMesh(this.selectedModel);
    let mlen = cmesh.getChildren().length;
    cmesh.getChildren().forEach(element => {
      let idlen = element.id.split(".").length;
      let shipc = "";
      if (element.id.split(".")[idlen - 2] != element.id.split(".")[idlen - 1]) {
        shipc = element.id.split(".")[idlen - 2] + "." + element.id.split(".")[idlen - 1];
      }
      else {
        shipc = element.id.split(".")[idlen - 1];
      }
      if (this.selectedModel == "CSC") {
        if (element.isVisible && shipc != "decks") {
          element.isVisible = false;
          element.setEnabled(false);
        }
        else {
          element.isVisible = true;
          element.setEnabled(true);
        }
      }
      else {
        if (element.isVisible) {
          element.isVisible = false;
          element.setEnabled(false);
        }
        else {
          element.isVisible = true;
          element.setEnabled(true);
        }
      }
      let flg = element.isVisible;
      this.shipComps.forEach(c => {
        if (c == shipc) {
          let m = this.ctrlServ.getMesh(c);
          if (shipc == "decks" && this.selectedModel == "CSC") {
            m.isVisible = !flg;
          }
        }
      });
    });
  }

  synccomponents() {
    let dfx = this.datauxview.getDatascape();
    let cmesh = this.ctrlServ.getMesh(this.selectedModel);
    cmesh.getChildren().forEach(element => {
      let idlen = element.id.split(".").length;
      let shipc = "";
      if (element.id.split(".")[idlen - 2] != element.id.split(".")[idlen - 1]) {
        shipc = element.id.split(".")[idlen - 2] + "." + element.id.split(".")[idlen - 1];
      }
      else {
        shipc = element.id.split(".")[idlen - 1];
      }
      let id = this.datauxview.getElementId(shipc);
      let m = this.ctrlServ.getMesh(shipc);
      element._position = m._position;
    });
  }

  explosion: any;
  explosion_cm
  initExplodeMesh() {
    let meshes = [];
    if (!this.explosion) {
      this.shipComps.forEach((c) => {
        let comp = this.ctrlServ.getMesh(c);
        meshes.push(comp);
        /* if(comp.getChildren().length){
          meshes.push(...comp.getChildMeshes());
        }else{
          meshes.push(comp);
        } */
      })
      let cm = null;
      let centerMesh = this.ctrlServ.getMesh(cm);
      this.explosion_cm = centerMesh;
      this.explosion = new BABYLON.MeshExploder(meshes)
    }
  }
  getMeshID(m) {
    let aname = m.id.split(".");
    let name = aname[aname.length - 1];
    return name;
  }
  getParentElemBound(mesh) {
    let meshes = mesh.getChildMeshes();
    let c = this.getElemCenter(null, mesh);
    let r = this.getElemRadius(null, mesh);
    let l = 0
    return [c, r];
  }
  getElemCenter(el, _mesh = null) {
    let dfx = this.datauxview.getDatascape();
    let mesh = _mesh || dfx.getElementMesh(el);
    let c = mesh.getBoundingInfo().boundingSphere.centerWorld;
    let target = { x: c.x, y: c.y, z: c.z }
    return target;
  }
  getElemRadius(el, _mesh = null) {
    let dfx = this.datauxview.getDatascape();
    let mesh = _mesh || dfx.getElementMesh(el);
    let c = mesh.getBoundingInfo().boundingSphere.radius;
    return c;
  }

  /* Deconnstruct the ship components
     1. Parameter ship - element of Ship
     2. Parameter mesh - Mesh of the Ship
  */
  deconstructShip(ship, mesh) {
    let dfx = this.datauxview.getDatascape();
    console.log(mesh);
    let clen = mesh.getChildren();
    console.log("mech children: " + clen);
    mesh.getChildren().forEach((m) => {
      if (!m.isPickable) {
        m.isPickable = true;
      }
      let mid = this.getMeshID(m);
      let idlen = m.id.split(".").length;
      let shipc = "";
      if (m.id.split(".")[idlen - 2] != m.id.split(".")[idlen - 1]) {
        shipc = m.id.split(".")[idlen - 2] + "." + m.id.split(".")[idlen - 1];
      }
      else {
        shipc = m.id.split(".")[idlen - 1];
      }
      if (this.filterComps.length > 0 &&
        this.filterComps.includes(shipc)) {
      }
      else {
        this.shipComps.push(shipc);
        let _rot = m.rotationQuaternion ? m.rotationQuaternion.toEulerAngles() : m.rotation;
        let _ce = this.getParentElemBound(m);
        this.compDefPos[shipc] = { bound: _ce, color: m.material.albedoColor, position: { x: m.position.x, y: m.position.y, z: m.position.z }, rot: { x: _rot.x, y: _rot.y, z: _rot.z }, qrot: m.rotationQuaternion, scale: m.scaling }
        m.isVisible = false;
        m.setEnabled(false);
      }
    })

    let children = [];
    this.shipComps.forEach((c) => {
      let comp = this.datauxview.getElementId(c);
      let geometry = this.compDefPos[c];
      dfx.modify(comp, { geometry: { position: geometry.position } })
      children.push(comp);
      let pos = { x: geometry.position.x, y: geometry.position.y, z: geometry.position.z };
      this.children[c] = { pos }
      let hmesh = dfx.getElementMesh(comp);

      //hmesh.isVisible = false;
      //hmesh.setEnabled(false);

      if (!this.dbobj[c]) {
        this.dbobj[c] = [, hmesh];
      }
      hmesh.rotationQuaternion = null;
      hmesh.rotation.x = geometry.rot.x;
      hmesh.rotation.y = geometry.rot.y;
      hmesh.rotation.z = geometry.rot.z;
      hmesh.scaling = geometry.scale;
      dfx.setElementTransition(comp, 0);
    })
    dfx.setAsParent(ship, children);
    this.shipElems = [].concat(this.sensors, this.cameras, this.shipComps);
    mesh.isVisible = false;

    let scnt = this.shipComps.length;
    this.deconstructChildShip(mesh);
    this.applyParentChild();
  }
  applyParentChild() {
    let dfx = this.datauxview.getDatascape();
    this.shipComps.forEach((key) => {
      let parent = this.dbobj[key][1];
      let info = this.getCtrlData(key);
      if (info.isSub && info.children.length) {
        let c = info.children;
        c.forEach((cinfo) => {
          let id = cinfo['id'];
          if (id !== key) {
            let _child = this.dbchildobj[id][1];
            _child.setParent(parent);

            if (cinfo.isSub && cinfo.children.length) {
              let cc = cinfo.children;
              cc.forEach((ccinfo) => {
                let id = ccinfo['id'];
                if (id !== key) {
                  let _cchild = this.dbchildobj[id][1];
                  _cchild.setParent(_child);
                }
              })
            }
          }
        })
      }
    })
  }
  getCtrlData(val, prop = 'id', action = 'eq') {
    let data = null;
    for (let m in this.shipCompsCntls) {
      let catg = this.shipCompsCntls[m];
      catg.forEach((c) => {
        if (action === 'eq') {
          if (c[prop] == val) {
            data = c;
          }
        }
        if (action === 'contains') {
          if (c[prop].includes(val)) {
            data = c;
          }
        }
      })
    }
    return data;
  }
  deconstructChildShip(pmesh) {
    pmesh.getChildren().forEach((m) => {
      let dfx = this.datauxview.getDatascape();
      let shipChildComps = [];
      let childlen = m.getChildren().length;
      m.getChildren().forEach((cmesh) => {
        if (!cmesh.isPickable) {
          cmesh.isPickable = true;
        }
        //let cid = this.getMeshID(cmesh);
        let cid = this.getMeshID(cmesh);
        let idlen = cmesh.id.split(".").length;
        let shipc = "";
        if (cmesh.id.split(".")[idlen - 2] != cmesh.id.split(".")[idlen - 1]) {
          shipc = cmesh.id.split(".")[idlen - 2] + "." + cmesh.id.split(".")[idlen - 1];
        }
        else {
          shipc = cmesh.id.split(".")[idlen - 1];
        }
        if (this.filterComps.length > 0 &&
          this.filterComps.includes(shipc)) {
        }
        else {
          cmesh.isVisible = false;
          cmesh.setEnabled(false);

          let comp = this.datauxview.getElementId(shipc);

          if (!comp) return;
          shipChildComps.push(cid);
          let _rot = cmesh.rotationQuaternion ? cmesh.rotationQuaternion.toEulerAngles() : cmesh.rotation;
          let _ce = this.getParentElemBound(cmesh);
          this.compDefPos[cid] = { bound: _ce, color: cmesh.material.albedoColor, position: { x: cmesh.position.x, y: cmesh.position.y, z: cmesh.position.z }, rot: { x: _rot.x, y: _rot.y, z: _rot.z }, qrot: cmesh.rotationQuaternion, scale: cmesh.scaling }
        }
      })

      let children = [];
      shipChildComps.forEach((c) => {
        let comp = this.datauxview.getElementId(c);
        if (!comp) return;
        let geometry = this.compDefPos[c];
        dfx.modify(comp, { geometry: { position: geometry.position } })
        children.push(comp);
        let pos = { x: geometry.position.x, y: geometry.position.y, z: geometry.position.z };
        this.children[c] = { pos }
        let hmesh = dfx.getElementMesh(comp);
        if (!this.dbchildobj[c]) {
          this.dbchildobj[c] = [, hmesh];
        }
        hmesh.rotationQuaternion = null;
        hmesh.rotation.x = geometry.rot.x;
        hmesh.rotation.y = geometry.rot.y;
        hmesh.rotation.z = geometry.rot.z;
        hmesh.scaling = geometry.scale;
        dfx.setElementTransition(comp, 0);
      });
    });
    this.deconstructThirdLevelShip(pmesh);
  }
  deconstructThirdLevelShip(pmesh) {
    let shipChildComps = [];
    let dfx = this.datauxview.getDatascape();
    pmesh.getChildren().forEach((cmesh) => {
      cmesh.getChildren().forEach((mesh) => {
        mesh.getChildren().forEach((m) => {
          if (!m.isPickable) {
            m.isPickable = true;
          }
          m.isVisible = false;
          m.setEnabled(false);
          let cid = this.getMeshID(m);
          let comp = this.datauxview.getElementId(cid);
          if (!comp) return;
          shipChildComps.push(cid);
          let _rot = m.rotationQuaternion ? m.rotationQuaternion.toEulerAngles() : m.rotation;
          let _ce = this.getParentElemBound(m);

          this.compDefPos[cid] = { bound: _ce, color: m.material.albedoColor, position: { x: m.position.x, y: m.position.y, z: m.position.z }, rot: { x: _rot.x, y: _rot.y, z: _rot.z }, qrot: m.rotationQuaternion, scale: m.scaling }
        })

        let children = [];
        shipChildComps.forEach((c) => {
          let comp = this.datauxview.getElementId(c);
          if (!comp) return;
          let geometry = this.compDefPos[c];
          dfx.modify(comp, { geometry: { position: geometry.position } })
          children.push(comp);
          let pos = { x: geometry.position.x, y: geometry.position.y, z: geometry.position.z };
          this.children[c] = { pos }
          let hmesh = dfx.getElementMesh(comp);
          if (!this.dbchildobj[c]) {
            this.dbchildobj[c] = [, hmesh];
          }
          hmesh.rotationQuaternion = null;
          hmesh.rotation.x = geometry.rot.x;
          hmesh.rotation.y = geometry.rot.y;
          hmesh.rotation.z = geometry.rot.z;
          hmesh.scaling = geometry.scale;
          dfx.setElementTransition(comp, 0);
        });
      });
    });
  }


  allElements: any = [];
  animate(id, pointer) {
    this.datauxview.getElementId(id);
    if (pointer == 'pickDown') {
    }
    if (pointer == 'pickUp') {
      if (!this.isDrag) {
        if (id.toLowerCase() != 'land') {
          if (this.tagServ.dfxTagList.length > 0) {
            let idx = this.tagServ.dfxTagList.findIndex(e => e == id);
            if (idx > -1) {
              this.tagServ.dfxTagShowHide(id, false);
            } else {
              this.tagServ.dfxTagShowHide(id, true);
            }
          } else {
            this.tagServ.dfxTagShowHide(id, true);
          }
        }
      }
    }
  }

  modelsPosition: any = {
    'Halifax-Crane': { position: { 'position': { 'x': 0, 'y': 0, 'z': 0 } }, cameraPosition: { "target": { x: 0, y: 0, z: 0 }, "distance": 728, "yaw": 0, "pitch": 12.85 } },
    'Kone-Crane': { position: { 'position': { 'x': 0, 'y': 0, 'z': 0 } }, cameraPosition: { "target": { x: 0, y: 0, z: 0 }, "distance": 728, "yaw": 0, "pitch": 12.85 } },
    'Ebco-Crane': { position: { 'position': { 'x': 0, 'y': 0, 'z': 0 } }, cameraPosition: { "target": { x: 0, y: 0, z: 0 }, "distance": 728, "yaw": 0, "pitch": 12.85 } },
    'Thor-Crane': { position: { 'position': { 'x': 0, 'y': 0, 'z': 0 } }, cameraPosition: { "target": { x: 0, y: 0, z: 0 }, "distance": 728, "yaw": 0, "pitch": 12.85 } },
    'S2S-Crane': { position: { 'position': { 'x': 0, 'y': 0, 'z': 0 } }, cameraPosition: { "target": { x: 0, y: 0, z: 0 }, "distance": 728, "yaw": 0, "pitch": 12.85 } },
    'Esquimalt-Crane': { position: { 'position': { 'x': 0, 'y': 0, 'z': 0 } }, cameraPosition: { "target": { x: 0, y: 0, z: 0 }, "distance": 728, "yaw": 0, "pitch": 12.85 } },
    'RHIB': { position: { 'position': { 'x': 0, 'y': 0, 'z': 0 } }, cameraPosition: { "target": { x: 0, y: 0, z: 0 }, "distance": 28, "yaw": 0, "pitch": 12.85 } },
    'CSC': { position: { 'position': { 'x': 0, 'y': 0, 'z': 0 } }, cameraPosition: { "target": { x: 0, y: 0, z: 0 }, "distance": 728, "yaw": 0, "pitch": 12.85 } },
    'AOPS': { position: { 'position': { 'x': 0, 'y': 0, 'z': 0 } }, cameraPosition: { "target": { x: 0, y: 0, z: 0 }, "distance": 728, "yaw": 0, "pitch": 12.85 } },
    'Asterix': { position: { 'position': { 'x': 0, 'y': 0, 'z': 0 } }, cameraPosition: { "target": { x: 0, y: 0, z: 0 }, "distance": 728, "yaw": 0, "pitch": 12.85 } },
    'TUG': { position: { 'position': { 'x': 0, 'y': 0, 'z': 0 } }, cameraPosition: { "target": { x: 0, y: 0, z: 0 }, "distance": 728, "yaw": 0, "pitch": 12.85 } },
    'Barge-01': { position: { 'position': { 'x': 0, 'y': 0, 'z': 0 } }, cameraPosition: { "target": { x: 0, y: 0, z: 0 }, "distance": 728, "yaw": 0, "pitch": 12.85 } },
    'Bulk_Carrier': { position: { 'position': { 'x': 0, 'y': 0, 'z': 0 } }, cameraPosition: { "target": { x: 0, y: 0, z: 0 }, "distance": 728, "yaw": 0, "pitch": 12.85 } },
    'Tanker': { position: { 'position': { 'x': 0, 'y': 0, 'z': 0 } }, cameraPosition: { "target": { x: 0, y: 0, z: 0 }, "distance": 728, "yaw": 0, "pitch": 12.85 } },
    'Submarine': { position: { 'position': { 'x': 0, 'y': 6, 'z': 0 } }, cameraPosition: { "target": { x: 0, y: 0, z: 0 }, "distance": 728, "yaw": 0, "pitch": 12.85 } },
    'Halifax': { position: { 'position': { 'x': 0, 'y': 0, 'z': 0 } }, cameraPosition: { "target": { x: 0, y: 0, z: 0 }, "distance": 728, "yaw": 0, "pitch": 12.85 } },
    'F35': { position: { 'position': { 'x': 0, 'y': 2.3, 'z': 0 } }, cameraPosition: { "target": { x: 0, y: 0, z: 0 }, "distance": 78, "yaw": 0, "pitch": 12.85 } },
    'AOPS_CCG': { position: { 'position': { 'x': 0, 'y': 0, 'z': 0 } }, cameraPosition: { "target": { x: 0, y: 0, z: 0 }, "distance": 400, "yaw": 0, "pitch": 12.85 } },
    'Ice_Breaker': { position: { 'position': { 'x': 0, 'y': 0, 'z': 0 } }, cameraPosition: { "target": { x: 0, y: 0, z: 0 }, "distance": 400, "yaw": 0, "pitch": 12.85 } },
  }

  selectedObjectModel() {
    console.log("selectedObjectModel", this.selectedModel, this.allElements)
    let ship = this.datauxview.getElementId(this.selectedModel);
    if (this.allElements.length > 0) {
      this.allElements.forEach(e => {
        if (e !== 'land') {
          if (e == this.selectedModel) {
            console.log("selectedObjectModel", this.selectedModel, e)
            let elem = this.datauxview.getElementId(e);
            if (elem) {
              var geopos = this.modelsPosition[e]['position'];
              // var cameraTarget = this.modelsPosition[e]['cameraPosition'];
              this.datauxview.getDatascape().modify(elem, { geometry: geopos });
              // this.dfx.moveCamera(cameraTarget);
              if (this.selectedModel == 'F35') {
                this.initF35Anim();
              }
              if (this.selectedModel == 'AOPS_CCG'||this.selectedModel == 'Ice_Breaker') {
                this.dfx.setElementScale(elem, [1, 1, -1]);
              }
              if (this.selectedModel) {
                var cameraTarget = this.modelsPosition[this.selectedModel]['cameraPosition'];
                this.dfx.moveCamera(cameraTarget)
              }
              let mesh = this.ctrlServ.getMesh(this.selectedModel);
              mesh.isVisible = true;
            }
          } else {
            const found = this.shipComps.includes(e);
            let mesh = this.ctrlServ.getMesh(e);
            if (!found) {
              if (mesh) {
                mesh.setEnabled(false);
                mesh.isVisible = false;
              }
            }
          }
        }
      });
    }
  }

  f35AnimList: any = [];
  startF35AnimModel(obj, state) {
    this.scene.stopAllAnimations();
    let ag = this.scene.animationGroups;
    ag.forEach((g) => {
      g.reset();
    })
    if (!state) {
      if (this.f35AnimList.length > 0) {
        let idx = this.f35AnimList.findIndex(e => e == obj);
        if (idx > -1) {
          this.f35AnimList.splice(idx, 1)
        }
      }
    } else {
      if (this.f35AnimList.length > 0) {
        let idx = this.f35AnimList.findIndex(e => e == obj);
        if (idx == -1) {
          this.f35AnimList.push(obj)
        }
      } else {
        this.f35AnimList.push(obj)
      }
    }
    if (this.f35AnimList.length > 0) {
      this.f35AnimList.forEach(e => {
        if (e == obj) {
          this.scene.animationGroups.forEach((anim) => {
            console.log("anim", anim);
            if (anim.name.includes(e)) {
              anim.play(state);
              if (!state) {
                anim.reset();
              }
            }
          })
        } else {
          this.scene.animationGroups.forEach((anim) => {
            console.log("anim1", anim);
            if (anim.name.includes(e)) {
              anim.play(true);
            }
          })
        }

      })
    }
  }

  f35AnimStatus: any = false;
  startF35Anim() {
    this.f35AnimStatus = true;
    this.scene.animationGroups.forEach((anim) => {
      console.log("anim", anim);
      anim.play(true);
    })
  }

  stopF35Anim() {
    this.f35AnimStatus = false;
    let scene = this.scene;
    scene.stopAllAnimations();
    let ag = scene.animationGroups;
    ag.forEach((g) => {
      g.reset();
    })
  }

  debug_ag: any = [];
  initF35Anim() {
    let engine = this.ctrlServ.getMesh('F35');
    let scene = this.scene;
    scene.stopAllAnimations();
    let ag = scene.animationGroups;
    ag.forEach((g) => {
      let norm = false;
      g.targetedAnimations.forEach((a) => {
        let n = a.target.name
        let nt;
        if (a.target.getClassName() === 'TransformNode') {
          nt = this.getChildTransEx('F35', n);
        }
        a.target = nt;
      })
      if (norm) {
        this.debug_ag.push(g);
      }
      g.reset();
    })
    this.excludeRHIBLighting(engine);
  }

  getChildTransEx(id, cid) {
    let el = this.datauxview.getElementId(id);
    let mesh = this.dfx.getElementMesh(el);
    let meshes = mesh.getChildTransformNodes();
    let idx = meshes.findIndex(e => this.getMeshName(e.name) === cid);
    if (idx > -1) {
      return meshes[idx];
    }
    return null;
  }

  getMeshName(n) {
    if (!n) {
      return 'unknown'
    }
    let arr = n.split(".");
    if (arr.length === 1) {
      return arr[0]
    }
    if (arr.length === 0) {
      return "unknown"
    }
    if (!isNaN(arr[arr.length - 1])) {
      return arr[arr.length - 2] + "." + arr[arr.length - 1]
    }
    return arr[arr.length - 1];
  }

  ngAfterViewInit(): void {
    this.vesselLoading = true;
    this.configService.loadFile('../assets/license.info').then((info) => {
      this.configService.getProjectConfig().then((project_config: any) => {
        project_config.licence = info;
        this.project_config = project_config
        this.datauxview.setProjectSettins(project_config);
        this.datauxview.loadCanvas('settings-models', 'json', (data, settingstatus) => {
          console.log("settingstatus", settingstatus);
          var setting_status = settingstatus;
          if (!setting_status) {
          } else {
            this.initObjects(() => {
              this.allElements = this.datauxview.getElementsid();
              this.selectedObjectModel();
              this.initCanvas();
              this.vesselLoading = false;
            })
          }
        }, (err) => { console.log(err) });
      }, (err) => { alert("License not found!") })
    })
    this.cdr.detectChanges();
  }

  /***
   * Add Pickleft event start
   */
  pickElement: any = false;
  tagObjectList: any;
  tagStatus: any = false;
  pickedElement: any;
  pickEvent: boolean = false;
  hitPosition: any;
  pickleftAttach() {
    // this.pickleftAttachStatus= true;
    // let  allObject = this.datauxview.allObjects();
    // let allObjKeys = Object.keys(allObject);
    // this.tagObjectList = allObjKeys;
    var shipCombainSensor = this.shipComps.concat(this.sensors);
    var shipCombainSensorTarget = shipCombainSensor.concat(this.cameras)
    shipCombainSensorTarget.forEach((element, i) => {
      let el = this.datauxview.getElementId(element);
      let info = this.getCtrlData(element);
      let isSub = info && info.isSub;
      let isSensor = this.sensors.includes(element) || this.cameras.includes(element)
      if (isSub || isSensor) {
        this.datauxview.getDatascape().attach(el, {
          actions: {
            pickLeft: [(evt, elem) => {
              if (evt.button === 0) {
                this.pickedElement = elem;
                this.pickEvent = true;
              }
            }]
            // hoverOn: [(evt, elem) => {this.onHoverElem=true; this.onHover(elem); }],
            // hoverOff: [(evt, elem) => { this.onHoverElem=false; this.offHover(elem); }],
          }
        });
      }
    });
    this.shipComps.forEach((element, i) => {
      let el = this.datauxview.getElementId(element);
      let info = this.getCtrlData(element);
      let isSub = info && info.isSub;
      if (isSub) {
        this.datauxview.getDatascape().attach(el, {
          actions: {
            pickLeft: [(evt, elem) => {
              if (evt.button === 0) {
                let props = this.datauxview.getDatascape().props(elem);
                let comp = props['id'];
                if (this.mesh_explode) {
                  let info = this.getCtrlData(comp);
                }
              }
            }],
            hoverOn: [(evt, elem) => {
              if (this.mousestate == 'down') {
                if (this.onHoverElem) {
                  //this.offHover(this.onHoverElem);
                }
                return
              }
              this.onHoverElem = elem;
              let dfx = this.datauxview.getDatascape();
              let hp = dfx.getHitPosition()

              this.hitPosition = this.compDefPos[this.datauxview.getDatascape().props(elem).id].bound[0];
              var id = this.datauxview.getDatascape().props(elem).id;
              console.log(this.datauxview.getDatascape().props(elem).id, this.compDefPos[this.datauxview.getDatascape().props(elem).id].bound[0], "onHover");

              if (this.onHoverElem) {
                let props1 = this.datauxview.getDatascape().props(elem);
                let props2 = this.datauxview.getDatascape().props(this.onHoverElem);
                if (props1.id === props2.id) {
                  this.onHover(elem);
                }
              }
            }],
            hoverOff: [(evt, elem) => {
              this.onHoverElem = elem; //this.offHover(elem);
            }],
          }
        });
      }
    });
    // this.generate();
  }

  onHoverElem: any = false;
  checked: any = true;
  onHover(elem) {
    this.onHoverElem = elem;
    console.log("hoveron", elem);
    let ele = this.datauxview.getDatascape().props(elem);
    console.log("hoveron", elem, ele);

    let info = this.getCtrlData(ele.id);
    let isSub = info && info.isSub;
    //uncheck
    this.checked = true;
    let cat = Object.keys(this.shipCompsCntls);
    cat.forEach((catElement) => {
      this.shipCompsCntls[catElement].forEach((sensorEle, i) => {
        if (this.shipCompsCntls[catElement][i].isSub) {
          let idx = this.shipCompsCntls[catElement].findIndex(e => e.id == ele.id);
          if (idx > -1) {
            if (!this.shipCompsCntls[catElement][idx].selected) {
              this.checked = false;
            }
          }
        }
      });
    });
    if (this.checked && !this.shipCompFocusState && this.viewMode == 'solid' && isSub) {
      this.addObserver(elem);
      //this.observableTag(ele);
      this.tagElement = ele;
      this.pickElement = true;
      if (this.mesh_explode) {
        this.focus_title = info.name;
      } else {
        this.createLabelObject('cube', this.hitPosition, ele.id);
      }
    }
  }
  /***
   * shipcomponent status check
   */
  shipCompFocusState: any = false;
  shipCompFocusElem: any = '';
  shipCompFocusStatus() {
    this.shipCompFocusState = false;
    this.shipCompFocusElem = '';
    let cat = Object.keys(this.shipCompsCntls);
    cat.forEach(element => {
      let idx = this.shipCompsCntls[element].findIndex(e => e.focus == true);
      if (idx > -1) {
        this.shipCompFocusState = true;
        console.log("shipCompFocusStatus", this.shipCompFocusState);
        this.shipCompFocusElem = this.shipCompsCntls[element][idx];
      }
    })
    console.log("shipCompFocusStatus", this.shipCompFocusState)
  }
  /**
* add an Observer attach to 3d object
*/
  addObserver(elem) {
    if (!elem) {
      return;
    }
    this.datauxview.getDatascape().attach(elem, {
      observer: {
        callback: this.assignTagProperties.bind(this),
        options: { no2D: false }
      }
    });
  }
  /***
   * tag observer properties
   */
  assignTagProperties(options) {
    const { id, position, position2D, uuid, tag, visibility } = Object.assign({}, options);

    if (this.selectedLabel === id) {
      var pop = document.getElementById(this.selectedLabel);
    }

    else {
      let elem = this.datauxview.getElementId(id);
      let props = this.datauxview.getDatascape().props(elem);
      var pop = document.getElementById(id);
    }
  }
  /****
   * observable Tag
   */
  tagAfterTagIdArr: any = [];
  objObserver(pop, position, element) {
    if (element != "") {
      console.log(element.id, "position :", position, this.hitPosition);
      const x = position[0];
      const y = position[1];
      let info = this.getCtrlData(element.id);
      const dpr = window.devicePixelRatio;
      if (this.pickElement) {

        let mpop = pop.getElementsByClassName("tooltip_popup")[0]

        var width = -mpop.offsetWidth / 2;//1555 - window.innerWidth;
        var height = 35;// 815 - window.innerHeight;
        pop.style.left = (Number(x) + width) + 'px';
        pop.style.bottom = (Number(y) + height) + 'px';

        var material = info && info.profile ? info.profile.split('_')[1] : "#fefefe";
        let color = 'black'
        if (this.viewObjectMode === 'texture') {
          material = 'lightgrey';
        }
        pop.style.display = 'block';
        pop.style.position = 'absolute';
      } else {
        if (pop != null) {
          pop.style.display = 'none';
        }
      }
    }
  }
  createLabelObject(shape, pos, uid) {
    var id = 'tooltip_' + uid;
    var elem = this.datauxview.getElementId(id);
    if (!elem) {
      var size = 0.0001;
      let profiles = { transparent: { material: 'transparent' }, regular: { material: 'hidden_obj' } };
      let settings = { 'geometry': { 'orientation': { x: 0, y: 0, z: 0 }, position: { x: pos.x, y: pos.y, z: pos.z }, size: size } };
      this.datauxview.addElement(shape, id, profiles, settings);
      let buildModel = this.datauxview.getElementId(id);
      let base = this.datauxview.getElementId(uid);
      let pmesh = this.dfx.getElementMesh(base);
      let cmesh = this.dfx.getElementMesh(buildModel);
      cmesh.setParent(pmesh);
      //this.dfx.modify(buildModel, { profile:'transparent' });
    }
    if (this.selectedLabel != id) {
      // if(this.selectedLabel){
      //   // this.labelObjectShow(id,false);
      //   // let buildModel = this.datauxview.getElementId(id);
      //   // this.dfx.modify(buildModel, { profile:'hidden' });
      // }else{
      let buildModel = this.datauxview.getElementId(id);
      //this.dfx.modify(buildModel, { profile:'transparent' });
      if (this.pickElement) {
        this.labelObjectShow(id, true)
      }
      // }
    }
  }
  selectedLabel: any;
  labelObjectShow(element, flag) {
    let el = this.datauxview.getElementId(element);
    if (el) {
      if (flag) {
        this.selectedLabel = element;
        this.addObserver(el);
      } else {
        let dom = document.getElementById(element);
        if (dom) {
          dom.style.display = "none";
          delete this.selectedLabel;
          this.removeObserver(el);
        }
      }
    }
  }
  /**
   * Remove an Observer detach to 3d object
   */
  removeObserver(elem) {
    if (!elem) {
      return;
    }
    this.datauxview.getDatascape().detach(elem, {
      observer: {
        callback: this.assignTagProperties.bind(this),
        options: { no2D: false }
      }
    });
  }

  /***
   * tagElement added
   */
  tagElement: any = "";
  selectEle: any = '';
  observableTag(props) {
    var event = 'locate';
    if (props != '') {
      if (this.tagElement) {
        document.getElementById('tooltip_' + this.tagElement.id).style.display = "none";
      }
      this.tagElement = props;
      if (this.selectEle) {
        if (this.selectEle == this.tagElement.id) {
          // this.pickElement = !this.pickElement;
          this.pickElement = true;
          if (this.pickElement) {
            // this.onControlChange({event: 'locate',name:this.tagElement.id,state:true });
          }
        } else {
          this.selectEle = this.tagElement.id;
          this.pickElement = true;
          // this.onControlChange({event: 'locate',name:this.tagElement.id,state:true });
        }
      } else {
        this.selectEle = this.tagElement.id;
        this.pickElement = true;
        // this.onControlChange({event: 'locate',name:this.tagElement.id,state:true });
      }
    }
  }


  /**
* initCanvas
*/
  selectedHitPosition: any;
  isDrag = false;
  rightclick = false;
  initCanvas() {
    var canvas = document;
    canvas.addEventListener("pointerdown", (event) => {
      this.isDrag = false;
      if (event.which == 1) {
        this.isDrag = true;
        this.rightclick = true;
        this.selectedHitPosition = event;
      }
    })
    canvas.addEventListener("pointerup", (event) => {
      if (event.which == 1) {
        if (this.selectedHitPosition.clientX == event.clientX && this.selectedHitPosition.clientY == event.clientY) {
          this.isDrag = false;
          delete this.selectedHitPosition;
        }

      }
    })
  }

  enableDfxDrag() {
    let obj = {};
    let dfx = this.datauxview.getDatascape();
    this.khaConfig.dfxDefPosition = {};
    if (this.allElements.length > 0) {
      this.allElements.forEach(e => {
        const found = this.shipComps.includes(e);
        if (!found && e == this.selectedModel) {
          let mesh = this.ctrlServ.getMesh(e);

          mesh.rotationQuaternion = null;
          if (e != 'land') {
            this.khaConfig.enableSystemDrag(mesh, this.filterComps);
          }
        }
      })

    }
  }
  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.CommunicationServ.getInstance().next(data);
  }

  broadcastIframeInfo(data: any) {
    this.CommunicationServ.createIframeInstance(data);
  }

  buttonclick(row, index) {
    this.arrControlBtnProps[index].state = !this.arrControlBtnProps[index].state;
    this.controlToolEvent(row);
  }

  controlToolEvent(control) {
    if (control.name === 'home') {
      if (this.selectedModel) {
        var cameraTarget = this.modelsPosition[this.selectedModel]['cameraPosition'];
        this.dfx.moveCamera(cameraTarget);
      } else {
        this.onChangeCameraPosition(control.name);
      }
    } else if (control.name === '2D') {
      let dimension = control.state ? '2D' : '3D';
      this.onChangeCameraMode(dimension);
    } else if (control.name === 'zoomin' || control.name === 'zoomout') {
      let zoomPosition = control.name === 'zoomin' ? ']' : '[';
      this.Zoom(zoomPosition);
    } else if (control.name === 'drag') {
      // this.arrControlBtnProps[4].state = !this.arrControlBtnProps[4].state;
      if (control.state) {
        let draggedstate = this.IsDragged();
        if (!draggedstate) {
          this.resetChildren();
        }
        this.khaConfig.dragMode = true;
        this.enableDfxDrag();
      } else {
        this.khaConfig.dragMode = false;
        this.khaConfig.removeDragAll();
      }
    } else if (control.name === 'reset') {
      if (!this.excludedrag) {
        this.khaConfig.removeDragAll();
        let draggedstate = this.IsDragged();
        this.resetChildren();
      }
      if (this.arrControlBtnProps[4].state) {
        this.enableDfxDrag();
      }
    }
    else if (control.name === 'animation') {
      if (this.arrControlBtnProps[6].state) {
        this.startF35Anim();
      } else {
        this.stopF35Anim();
      }
    }
    else if (control.name == 'explodeparts') {
      if (this.arrControlBtnProps[7].state) {
        // this.arrControlBtnProps[7].state = !this.arrControlBtnProps[7].state;
        this.mesh_explode = false;
        this.resetMeshExplode();
        this.explodeMesh(-1);
      } else {
        // this.arrControlBtnProps[7].state = !this.arrControlBtnProps[7].state;
        this.explode_val = 1;
        this.mesh_explode = true;
        this.selectedLabel = null;
        //this.toggleOffOcean(false);
        this.explodeMesh(this.explode_val);
        this.showHideLand(false);
      }
    }
  }

  explodeMesh(i = 1) {
    let camera = this.dfx.getCamera();
    if (i >= 0) {
      this.explosion.explode(i);

      let p = this.explosion.getMeshes()[0].getAbsolutePosition();
      camera.targetX.value = p.x;
      camera.targetY.value = p.y;
      camera.targetZ.value = p.z
    }
    if (i < 0) {
      // model pos
      var geopos = this.modelsPosition[this.selectedModel]['position'];
      let elem = this.datauxview.getElementId(this.selectedModel);
      if (this.selectedModel == "CSC" ||
        this.selectedModel == "AOPS" ||
        this.selectedModel == "Asterix") {
        if (this.selectedModel == "AOPS") {
          geopos.position.x += 300;
        }
        if (this.selectedModel == "CSC") {
          geopos.position.x += 200;
        }
        if (this.selectedModel == "Asterix") {
          geopos.position.x += 100;
        }
      }
      this.datauxview.getDatascape().modify(elem, { geometry: geopos });
      if (this.selectedModel) {
        var cameraTarget = this.modelsPosition[this.selectedModel]['cameraPosition'];
        this.dfx.moveCamera(cameraTarget)
      }
      let mesh = this.ctrlServ.getMesh(this.selectedModel);
      mesh.isVisible = true;
      let mm = mesh.getAbsolutePosition();
      camera.targetX.value = mm.x;
      camera.targetY.value = mm.y;
      camera.targetZ.value = mm.z

    }
  }

  /* * * * *
  * barge static animation
  * * * * * */
  bargeStaticInterval
  bargeStaticAnimation() {

    let position = { x: 0, y: 0, z: 0 };
    this.posX = position.x;
    this.posZ = position.z;
    let animation = (timer) => {
      this.posY = this.floatMesh(position, this.ocean);
      this.bargeWaveAnimation();
      this.bargeStaticInterval = window.requestAnimationFrame(animation);
    }
    this.bargeStaticInterval = window.requestAnimationFrame(animation);
  }
  /* * * * *
  * barge static animation
  * * * * * */
  bargeWaveAnimation() {
    var data = { "time": "1", "op": "moveTo", "name": this.selectedModel, "duration": 1.720, "place": { "pos": { "x": this.posX, "y": this.posY, "z": this.posZ }, "rot": { "x": 0, "y": 0, "z": 0 } } }
    this.datauxview.modifyElement(data);
  }
  /* * * * *
  * stop static animation
  * * * * * */
  stopStaticAnimation() {
    cancelAnimationFrame(this.bargeStaticInterval);
  }
  /* * * * *
  * get float value
  * * * * * */
  floatMesh(position, waterMaterial) { //waterMaterial is ocean instance
    if (!waterMaterial) return;
    let time = waterMaterial._lastTime / 100000;
    let x = position.x;
    let z = position.z;
    return 0.5 + Math.abs((Math.sin(((x / 0.05) + time * waterMaterial.waveSpeed)) * waterMaterial.waveHeight * waterMaterial.windDirection.x * 5.0) + (Math.cos(((z / 0.05) + time * waterMaterial.waveSpeed)) * waterMaterial.waveHeight * waterMaterial.windDirection.y * 5.0));
  }

  bargeAnimationState: any = false;
  resetMeshExplode() {
    this.showHideLand(!false);
    this.explosion.explode(0);
    this.resetChildren();
    //this.toggleOffOcean(this.toggleOceanState);
    //this.onChangeCameraPosition('home');
  }
  showHideLand(boo) {
    let dfx = this.datauxview.getDatascape();
    let m = this.datauxview.getElementId("land");
    let mesh = dfx.getElementMesh(m);
    mesh.isVisible = boo;
  }
  // IsDragged checks if any components has been dragged
  IsDragged() {
    let keys = Object.keys(this.dbobj);
    let dragged = false;
    keys.forEach((key) => {
      let info = this.getCtrlData(key);
      let isSub = info.isSub;
      if (isSub) {
        let obj = this.dbobj[key]
        if (obj) {
          let p = this.children[key].pos;
          let mesh = obj[1];
          if (mesh.position.x != p.x ||
            mesh.position.y != p.y ||
            mesh.position.z != p.z) {
            dragged = true;
          }
        }
      }
    })
    return dragged;
  }
  resetChildren() {
    let keys = Object.keys(this.dbobj);
    keys.forEach((key) => {
      let info = this.getCtrlData(key);
      let isSub = info.isSub;
      if (isSub) {
        this.resetChild(key);
        if (key == "decks") {

        }
      }
    })
  }
  resetChild(m) {
    let obj = this.dbobj[m]
    if (obj) {
      let p = this.children[m].pos;
      let mesh = obj[1];
      mesh.position.x = p.x;
      mesh.position.y = p.y;
      mesh.position.z = p.z;
    }
  }
  ground
  sky = null;
  ocean = null;
  //oceanmode = true;
  toggleOceanState = false;
  toggleOffOcean(hide = true) {
    // hide = !hide;   
    if (!this.ground) {
      this.ground = this.ctrlServ.getMesh('land');
      // this.ground.material = this.getGroundMaterial();
    }
    //this.oceanmode = !hide;
    if (!hide) {
      if (this.sky != null) {
        this.sky.setEnabled(false);
      }
      if (this.ocean) {
        if (this.ocean._mesh != null) {
          this.ocean._mesh.setEnabled(false);
        }
      }
      if (this.ground != null) {
        this.ground.setEnabled(true);
      }
    }
    else {
      if (this.sky != null) {
        this.sky.setEnabled(true);
      }
      if (this.ocean) {
        if (this.ocean._mesh != null) {
          this.ocean._mesh.setEnabled(true);
        }
      } else {
        this.addOcean();
      }
      if (this.ground != null) {
        this.ground.setEnabled(false);
      }
    }

    if (this.bargeAnimationState) return;
    if (this.toggleOceanState) {
      this.ctrlServ.modifyElement(this.selectedModel, "geometry", { 'orientation': { x: 0, y: 0, z: 0 }, position: { x: 0, y: 0.8, z: 0 } });
      //this.changeCamView('home');
      this.bargeStaticAnimation();
    } else {
      this.ctrlServ.modifyElement(this.selectedModel, "geometry", { 'orientation': { x: 0, y: 0, z: 0 }, position: { x: 0, y: -0.0, z: 0 } });
      //this.changeCamView('home');
      this.stopStaticAnimation();
    }
  }
  addOcean() {
    let rl = [this.sky];
    this.ocean = this.dfx.addOcean("ocean", { renderList: rl, waveOpts: { col: [80 / 255, 160 / 255, 200 / 255] }, meshOpts: { subdiv: 32 * 8 }, texOpts: { fresnelSeparate: true, bumpSuperimpose: true, bumpAffectsReflection: true, bump: 0.25, bumptex: "./assets/smls/assets/env/waterbump.png" } });
    this.ocean.alpha = 0.9;
    this.ocean.colorBlendFactor2 = 0.5;
  }
  testMode: any = "";
  changeCamView(v, vobj = false) {
    let felem = this.getFocusElem();
    let target = { "x": 0.3, "y": 0.86, "z": -0.3 }
    let distance = 125;
    if (felem) {
      let el = this.datauxview.getElementId(felem)
      target = this.getElemCenter(el)
      distance = undefined;
    }
    let view;
    if (this.testMode === "quiz") {
      let camviews = this.camviews['quiz'];
      view = vobj ? v : camviews[v];
    } else {
      let camviews = this.camviews[this.activeModel];
      view = vobj ? v : camviews[v];
    }
    // view.target = target;
    // view.distance = distance;
    this.moveCameraTo(null, view)
  }
  getFocusElem() {
    let foc_elem = null;
    for (let m in this.shipCompsCntls) {
      let catg = this.shipCompsCntls[m];
      catg.forEach((c) => {
        if (c.focus) {
          foc_elem = c.id;
        }
      })
    }
    return foc_elem;
  }
  explode_val = 0
  sliderChange(event) {
    this.explode_val = event.value;

    this.explodeMesh(this.explode_val);
    this.showHideLand(false);
  }

  /**
  * onChange camera mode
  * * */
  onChangeCameraMode(camView) {
    this.cam_mode = camView;
    if (this.cam_mode == '2D') {
      this.onChangeCameraPosition('top');
    }

    this.datauxview.CameraMode(camView);
    if (this.cam_mode == '3D') {
      setTimeout(() => {
        if (this.selectedModel) {
          var cameraTarget = this.modelsPosition[this.selectedModel]['cameraPosition'];
          this.dfx.moveCamera(cameraTarget);
        } else {
          this.onChangeCameraPosition('home');
        }
      }, 1300);
    }
  }

  setVenueLightIntensity(i = 4) {
    this.lightSettings = [{ direction: { x: -0.85, y: -0.40, z: 0.35 }, diffuse: { r: 0.65, g: 0.65, b: 0.65 }, specular: { r: 0, g: 0, b: 0 } }, { direction: { x: 0.75, y: -0.40, z: -0.45 }, diffuse: { r: 1.0, g: 1.0, b: 1.0 }, specular: { r: 0, g: 0, b: 0 } }, { direction: { x: 0.15, y: -0.40, z: 0.50 }, diffuse: { r: 1.0, g: 1.0, b: 1.0 }, specular: { r: 0, g: 0, b: 0 } }];
    this.dfx = this.datauxview.getDatascape();
    this.scene = this.dfx.getCamera().getScene();
    let lights = this.scene.lights;
    lights.forEach((light) => {
      light.intensity = i;
    })
  }

  processModel() {
    let app = this;
    this.scene.activeCamera._getViewMatrix = function () {
      var bp = 0;
      this.CalcPosition();
      this._computeViewMatrix(this.position, this.GetTargetPos(), this.upVector);
      return this._viewMatrix;
    };
    let hdrTexture = this.scene.environmentTexture;
    app.scene.environmentBRDFTexture = null;
    for (let i = 0; i < app.scene.materials.length; i++) {
      let mtl = app.scene.materials[i];
      let mtl_clname = mtl.getClassName();
      if (mtl_clname === "PBRMaterial") {
        let pbr = mtl;
        pbr.albedoColor.r *= 1.28;
        pbr.albedoColor.g *= 1.28;
        pbr.albedoColor.b *= 1.28;

        if (pbr.metallic == 0) {
          pbr.roughness = 1; //model s/b fixed <---------------------------------------
        }
        //this is always 0 in Blender, but becomes 1 here
        pbr.specularIntensity = 0;
        // console.log("  specIntensity:" + pbr.specularIntensity + " forced to zero");
        //the master light switch
        // console.log("  envIntensity:" + pbr.environmentIntensity);
        //Babylon's clearcoat seems overly bright and washed out
        //so we turn it down half way
        if (pbr.clearCoat.isEnabled) {
          let orig = pbr.clearCoat.intensity;
          pbr.clearCoat.intensity *= 0.5;
          // console.log("  cc_Intensity:" + orig + " changed to " + pbr.clearCoat.intensity);
          // console.log("  cc_roughness:" + pbr.clearCoat.roughness);
        }
      }
      else {
        // console.log("Mtl:" + mtl.name + " NOT PBR.");
      }
    }
  }

  excludeRHIBLighting(mesh) {
    let lights = this.scene.lights;
    lights.forEach((light) => {
      //light.excludedMeshes.push(mesh);
      this.applyExlude(light, mesh);
    })
  }

  applyExlude(light, mesh) {
    light.excludedMeshes.push(mesh);
    mesh.getChildMeshes().forEach((m) => {
      light.excludedMeshes.push(m);
    }
    );
  }
  /**
  * onChange camera position
  * * */
  onChangeCameraPosition(camView) {
    if (camView === 'home') {
      this.onChangeCamView(camView);
    }
    if (this.cam_mode == '2D') {
      camView = 'ortho' + camView
    }
    this.datauxview.Camera(camView);
  }

  /**
  * onChange camera view
  */
  onChangeCamView(v, vobj = false) {
    let currentTab = this.ctrlServ.matCurrentTab;
    if (currentTab !== 'section') {
      this.moveCameraTo(null, v)
    }
  }

  /**
  * move camera
  */
  moveCameraTo(el, view = null) {
    let dfx = this.datauxview.getDatascape();
    let _view = view;
    dfx.moveCamera(_view);
  }

  /**
  * Zoom event to zoom in / out the venue
  */
  Zoom(key) {
    this.isMouseDown = true;
    this.datauxview.startZoom(key);
  }
  stopZoom() {
    this.isMouseDown = !true;
    this.datauxview.stopZoom();
  }
  @HostListener('document:mouseup', ['$event'])
  handleMouseUpEvent(event: MouseEvent) {
    if (this.isMouseDown) {
      this.stopZoom();
    }
    this.mousestate = "up"
  }
  onMouseDownN(d = 1) {
    this.doubleClick += 1;
  }
  onMouseUpN() {
    setTimeout(() => {
      this.doubleClick = 0;
    }, 200);
  }
  rgbToHex(r, g, b) {
    return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
  }
  private componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }
  getObjDef() {
    let scope = this;
    var el = scope.datauxview.getElementId(this.selectedModel);
    var mesh = scope.dfx.getElementMesh(el);
    var meshes = mesh.getChildren();
    let shapes = [];
    let objects = []
    meshes.forEach((m) => {
      //childMesh.getChildren((m) => {
      let mname = scope.getMeshName(m.name);
      let obj = {}
      obj["dfx-" + mname] = { "model": mname, "material": "custom-included", "size": 1 }
      shapes.push(obj);
      let prop = { "type": "static", "parent": "", "name": mname, "model": "dfx-" + mname, "place": { "pos": { "x": 0, "y": 0, "z": 0 }, "rot": { "x": 0, "y": 0, "z": 0 } } }
      objects.push(prop);
      //})
    })
    let controls = [];
    objects.forEach((m) => {
      let o = {
        "name": m.name, "id": m.name, "children": [], "opacity": "solid", "visible": true, "focus": false, "selected": true, "color": "#ffbeb4", "isSub": true, "sensors": [], "animateMedia": false, "hastarget": false
      }
      controls.push(o)
    })
    return { shapes, objects, controls }
  }
  getObjDefJSON() {
    let def = this.getObjDef();
    console.log("------- SHAPES -------");
    console.log(JSON.stringify(def.shapes))
    console.log("------- OBJECTS -------");
    console.log(JSON.stringify(def.objects));
    console.log("------- CONTROLS -------");
    console.log(JSON.stringify(def.controls));
  }

}
