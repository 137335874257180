import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private lang = new Subject<any>();
  languageChange = this.lang.asObservable();
  language:any;
  constructor() { }
  changeLanguage(e){
    console.log('language',e);
    this.language= e;
    this.lang.next(this.language);
  }
}
