import { Component, OnInit, Output, EventEmitter, Input, HostListener, ViewChild, ViewChildren, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { NgModel } from '@angular/forms';
import { CommunicationService } from "../services/communication.service";
import { ActivityVisualizationService } from '../services/activity-visualization.service';
import { TagService } from '../services/tag.service';
import { ControllerService } from '../services/controller.service';
// import { MatTabGroup } from '@angular/material';
import moment from 'moment';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../services/language.service';
import * as uuid from 'uuid';
import { MatAccordion } from '@angular/material/expansion';
import { ConfigService } from '../services/config.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Console } from 'console';
import { AcroFormListBox } from 'jspdf';
import { ChitService } from '../services/chit.service';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from '@angular/platform-browser';

import { RadhazVisService } from "../services/radhaz.service";
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { DrawService } from '../services/draw.service';

@Component({
  selector: 'app-sidepanel',
  templateUrl: './sidepanel.component.html',
  styleUrls: ['./sidepanel.component.scss'],
  providers: [DatePipe]
})

export class SidepanelComponent implements OnInit, AfterViewChecked {

  @ViewChild(MatAccordion) accordion: MatAccordion;
  // @ViewChildren("allTabs") allTabs: MatTabGroup;
  startDate: any = new Date();
  endDate: any = new Date();
  shipDetail: any = '';
  serviceList: any = {};
  statusList: any = [];
  mbsStatusList: any = [];
  showStatusPanelOpen:any=true;
  searchStatus:any="";
  filterStatusKey: any = ['name'];
  sortStatusItem: any = 'name';
  shipStatusHandleToggleOpen:any=false;
  @Input() handleShipDetailToggleOpen: boolean = false;
  shipGroupHandleToggleOpen: boolean = false;
  groupWindow: boolean = false;
  groupState = 'new';
  groupStatusColor: string;
  groupData: string;
  groupName: any;
  sidepanelGroupData: any = []
  activeGroupIndex: any;
  editGroupData: any;
  groupList: any = [];
  sidepanelGroupObject: any =[];
  searchGroup: string = '';
  sidepanelStatusData: any =[];
  sidepanelStatusObject: any =[];
  StatusColorValue: any;
  activeLayoutIndex: any;
  collectionGroupItem: any = ['layoutName', 'group','status'];
  filterLayoutKey: any = ['groupName', 'layoutName', 'statusName'];
  sortObjectKeys: any = ['name', 'groupName', 'layoutName', 'statusName'];
  preTagPoint: any =[];
  pretag: any;
  activeCard: any;
  tagData: any;
  currentShipListInfo: any;
  activeTag: any = [];
  @Input() set shipDetailPanelData(v: any) {
    this.shipDetail = '';
    if (v != '') {
      this.shipDetail = v;
      this.shipDetail = Object.assign(this.serviceList, this.shipDetail)
      this.handleShipDetailToggleOpen = true;
    } else {
      this.handleShipDetailToggleOpen = false;
    }
  }
  @Input() loadedModel:any;
  @Input() unknownLocationState: any = false;
  @Input() locationErrorState: any = false;
  @Input() toggleOceanState: any = false;
  @Input() showGroundState: any = true;
  @Input() showBuildingState: any = true;
  @Input() loadedPort: any = 'cfbhalifax';
  @Input() statusColorCode: any;
  @Input() shipLoadingStatus: boolean = false;
  @Input() selectedConeElem: any = null;
  @Input() toggleCautionState: boolean = false;
  @Input() hazardShowState: boolean = true;
  @Input() set activityPanelState(v: any) {
    // if (v) {
    this.shipHandleToggleOpen = v;
    // }
    // this.WFMHandleToggleOpen =false;
    // this.FMFHandleToggleOpen = false;
  }
  @Input() set reportPanelState(v: any) {
    // if (v) {
    this.reportHandleToggleOpen = v;
    // this.WFMHandleToggleOpen = false;
    // this.FMFHandleToggleOpen = false;
    // this.shipHandleToggleOpen = false;
    // }
  }
  bridgeViewState: any = true;
  project_config: any;
  filterGroupKey: any = ['name'];
  @Input() set clearShipSelection(v: any) {
    if (v == false) {
      this.clearSelectionList();
    }
  }
  @Input() set langChange(v: any) {
    if (v) {
      console.log(v)
      var lang = v == 'fr' ? 'English' : 'French';
      this.onChangeLanguage(lang)
    }
  }
  @Input() set controlPanelState(v: any) {
    this.handleDControlToggleOpen = v;
  }
  @Input() set rawData(v: any) {
    if (v != undefined) {
      this.rawdataPanelOpen = true;
      this.rawDataList = v;
      this.handleDemoToggleOpen = false;
      this.rawDataPrint(v);
    }
  }

  @Input() set fmfPanelState(v: any) {
    // if (v) {
    //   this.FMFHandleToggleOpen = v;
    //   // this.toggleFMFMode({checked:v})
    //   this.toggleFMFState = v;
    //   // this.WFMHandleToggleOpen = false;
    //   // this.reportHandleToggleOpen = false;
    //   // this.shipHandleToggleOpen = false;
    // }else{
    //   this.FMFHandleToggleOpen = v;
    //   // this.toggleFMFMode({checked:v})
    //   this.toggleFMFState = v;
    // }
    this.FMFHandleToggleOpen = v;
    this.activeDetailSection = 'hazard';
    // this.toggleFMFMode({checked:v})
    this.toggleFMFState = v;
  }
  @Input() set wfmPanelState(v: any) {
    // if (v) {
    this.WFMHandleToggleOpen = false;
    // this.FMFHandleToggleOpen = false;
    // this.toggleFMFState = false;
    // this.shipHandleToggleOpen = false;
    // this.reportHandleToggleOpen = false;
    // }
    this.activeDetailSection = 'hazard';
  }
  @Input() set hazardPanelState(v: any) {
    if (v) {
      this.activeDetailSection = 'hazard';
      this.WFMTabChange({ "index": 1, "tab": { "textLabel": "Detail" } });
    }
  }
  @Input() set firstAidPanelState(v: any) {
    if (v) {
      this.activeDetailSection = 'aed';
      this.WFMTabChange({ "index": 1, "tab": { "textLabel": "Detail" } });
    }
  }
  @Input() set kioskPanelState(v: any) {
    if (v) {
      this.activeDetailSection = 'kiosk';
      this.WFMTabChange({ "index": 1, "tab": { "textLabel": "Detail" } });
    }
  }
  @Input() set laydownPanelState(v: any) {
    if (v) {
      this.activeDetailSection = 'laydown';
      this.WFMTabChange({ "index": 1, "tab": { "textLabel": "Detail" } });
    }
  }
  // @Input() set fistAidJson(v: any) {
  //   if (v != undefined) {
  //     this.fistAidJsonData = v;
  //     this.fistAidJsonDataList = v;
  //   }
  // }

  _fistAidJson: any = [];
  @Input() set fistAidJson(v: any) {
    if (v.length > 0) {
      this._fistAidJson = v;
      this.statusUpdate(v);

      this.firstAidUpdateData = this._fistAidJson
      this.filterFirstAidObjectKeys = Object.keys(this.firstAidUpdateData[0])
      this.getFirstAidFilterKey(this._fistAidJson[0]);
    }
  }
  coneList: any = []
  @Input() set _coneList(v: any) {
    if (v.length > 0) {
      this.coneList = v;
      // console.log("this.coneList", this.coneList);
      this.coneList.forEach(element => {
        let item = this.hazardTypeList.find(el => el.key === element.activity);
          if (item) {
            element['name'] = item['name'];
          } else {
            element['name'] = '-';
          }
          let col = ''
          if (element.color) {
            this.coneColors.forEach(elem => {
              if (element.color === elem.material) {
                col = elem.code;
              }
            });
            element['lineColor'] = col;
          } else {
            element['lineColor'] = '';
          }
      });
      this.coneUpdatedData = this.coneList;
    }
  };
  profileInfo: any = {};
  @Input() set _profileInfo(obj: any) {
    if (Object.keys(obj).length > 0) {
      this.profileInfo = obj;
      // console.log("this.profileInfo", this.profileInfo.email)
      this.updateMenuAccess();
    }
  };
  @Input() set adminStateBoardView(obj: any) {
    // if (Object.keys(obj).length > 0) {
      this.stateBoardView = obj;
      // console.log("this.profileInfo", this.profileInfo.email)
      // this.updateMenuAccess();
    // }
  };
  @Input() hideUnselectedState: any = false;
  @Input() planType: any;
  @Input() rhdata: any;
  @Input() rhdata_2: any;
  @Input() aloftdata: any;
  @Input() aloftdata_2: any;
  rdhasState:any=false;
  @Input() set rhmode(v:any){
    this.rdhasState = v;
// if(v){
  this.showPanelOpen=v
// }esle{

// }
  }
  @Input() toggleFMFState: boolean = false;
  @Input() radhaz: any = {}
  @Input() aloft_version;
  // @Input() fistAidJson: any = [];
  @Input() fistAidJsonres: any = [];
  @Input() toggleConeState: boolean = false;

  @Output() toggleLaydownToggle: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggleFMFCone: EventEmitter<any> = new EventEmitter<any>();
  @Output() hideUnselectedToggle: EventEmitter<any> = new EventEmitter<any>();
  @Output() settingPanelState: EventEmitter<any> = new EventEmitter<any>();
  @Output() rawDataState: EventEmitter<any> = new EventEmitter<any>();
  @Output() showFilterships: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedShip: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedLaydown: EventEmitter<any> = new EventEmitter<any>();
  @Output() show_All_Activity: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggle_activity: EventEmitter<any> = new EventEmitter<any>();
  // @Output() toggleJetty: EventEmitter<any> = new EventEmitter<any>();
  // @Output() loadHomeScreen: EventEmitter<any> = new EventEmitter<any>();
  @Output() showJettyLabels: EventEmitter<any> = new EventEmitter<any>();
  @Output() loadMessageScreen: EventEmitter<any> = new EventEmitter<any>();
  @Output() rawDataClose: EventEmitter<any> = new EventEmitter<any>();
  @Output() locationErrorToggle: EventEmitter<any> = new EventEmitter<any>();
  @Output() hazardShowToggle: EventEmitter<any> = new EventEmitter<any>();
  @Output() showBuildingToggle: EventEmitter<any> = new EventEmitter<any>();
  @Output() showGroundToggle: EventEmitter<any> = new EventEmitter<any>();
  @Output() showUnknownLocPopup: EventEmitter<any> = new EventEmitter<any>();
  @Output() unknownLocationToggle: EventEmitter<any> = new EventEmitter<any>();
  @Output() timeProgressToggle: EventEmitter<any> = new EventEmitter<any>();
  @Output() hideCraneToggle: EventEmitter<any> = new EventEmitter<any>();
  @Output() hideLightToggle: EventEmitter<any> = new EventEmitter<any>();
  @Output() bridgeViewToggle: EventEmitter<any> = new EventEmitter<any>();
  @Output() openPublishDialog: EventEmitter<any> = new EventEmitter<any>();
  @Output() activeConeElem: EventEmitter<any> = new EventEmitter<any>();

  process_txt = "Processing...";
  coneSearch: any = "";
  laydownSearch: any = "";
  coneUpdatedData: any = [];
  chitWindowState: any = false;
  sidepanelHide: any = false;
  // toggleFMFState: any = false;
  toggleKioskState: any = false;
  chitFormState: any = false;
  toggleKioskColorState: any = false;
  timeProgress: boolean = false;
  shipHandleToggleOpen: boolean = false;
  laydownHandleToggleOpen: boolean = false;
  coneHandleToggleOpen: boolean = false;
  servicesHandleToggleOpen: boolean = false;
  jettyLabelState: any = false;
  toggleLaydownState: any = false;
  // showBuilding: any = true;
  controlPosition: any = 'Right';
  controlDPosition: any = 'Left';
  search: any = ""
  defRangeMetaData: any = [];
  shipMetaData: any = [];
  shipUpdatedData: any = [];
  shipParentMetaList: any = [];
  shipClassList: any = [];
  showPanelOpen: any = false;
  showLaydownPanelOpen: any = false;
  showWFMPanelOpen: any = false;
  showFMFPanelOpen: any = false;
  showConePanelOpen: any = false;
  showLibPanelOpen: any = false;
  showInstructionsPanelOpen: any = false;
  showGroupPanelOpen: any = true;
  showServicePanelOpen: any = true;
  broadcastSubs: any;
  parentCatListIdx: any;
  parentLaydownListIdx: any;
  filterObjectKeys: any = [];
  filterLaydownObjectKeys: any = [];
  filterFirstAidObjectKeys: any = [];
  filterLaydownKeys: any = [];
  filterFirstAidnKeys: any = [];
  filterKey: any = [];
  shipData: any = "";
  showReportPanelOpen: any = false;
  reportHandleToggleOpen: any = false;
  showToolsPanelOpen: any = true;
  showLayoutPanelOpen: any = true;
  showControlPanelOpen: any = false;
  handleControlToggleOpen: any = false;
  showDControlPanelOpen: any = true;
  showDemoPanelOpen: any = true;
  handleDControlToggleOpen: any = false;
  handleDemoToggleOpen: any = false;
  handleToolsToggleOpen: any = false;
  handleLayoutToggleOpen: any = false;
  filterPanelOpen: boolean = true;
  filterHandleToggleOpen: boolean = false;
  serviceHandleToggleOpen: boolean = false;
  WFMHandleToggleOpen: any = false;
  FMFHandleToggleOpen: any = false;
  libHandleToggleOpen: any = false;
  insHandleToggleOpenHandleToggleOpen: any = false;
  selectedIndex: any = 0;
  WFMTabSelected: any = 'Service';
  activeDetailSection: any = 'hazard';
  firstAidSearch: any = "";
  firstAidSearchFilter: any = "";
  firstAidDataSearch: any = [];
  firstAidDataRes = this.fistAidJson;
  fistAidJsonData: any = [];
  chitDataList: any = [];
  firstAidUpdateData: any = [];
  activeReportSection: any;
  groupItems: any = ['ship', 'date', 'activity'];
  selectedGroupItem: any = 'date'
  wfmHALServiceList: any = [
    { "key": "hazard", "name": "Hazard Areas", "status": "active", "level": 1 },
    { "key": "aed", "name": "AED", "status": "active", "level": 1 },
    { "key": "first-aid", "name": "First aid stations", "status": "inactive", "level": 1 },
    { "key": "spill-kits", "name": "Spill kits", "status": "inactive", "level": 1 },
    { "key": "evacuation-posters", "name": "Evacuation posters", "status": "inactive", "level": 1 },
    // { "key": "shore-cables", "name": "Shore cables", "status": "inactive" },
    { "key": "kiosk", "name": "Kiosks", "status": "inactive", "level": 2 },
    { "key": "laydown", "name": "Laydown areas", "status": "active", "level": 2 },
    { "key": "contractors", "name": "Contractors", "status": "inactive", "level": 2 },
    { "key": "portable-generators", "name": "Portable generators", "status": "inactive", "level": 2 },
    { "key": "fork-lifts", "name": "Forklifts", "status": "inactive", "level": 2 },
    { "key": "cranes", "name": "Cranes", "status": "inactive", "level": 2 }];
  fmfHALServiceList: any = [
    { "key": "hazard", "name": "Hazard Areas", "status": "active", "level": 1 },
    { "key": "aed", "name": "AED", "status": "active", "level": 1 },
    { "key": "first-aid", "name": "First aid stations", "status": "inactive", "level": 1 },
    { "key": "spill-kits", "name": "Spill kits", "status": "inactive", "level": 1 },
    { "key": "evacuation-posters", "name": "Evacuation posters", "status": "inactive", "level": 1 },
    // { "key": "shore-cables", "name": "Shore cables", "status": "inactive" },
    { "key": "kiosk", "name": "Kiosks", "status": "inactive", "level": 2 },
    { "key": "laydown", "name": "Laydown areas", "status": "active", "level": 2 },
    { "key": "contractors", "name": "Contractors", "status": "inactive", "level": 2 },
    { "key": "portable-generators", "name": "Portable generators", "status": "inactive", "level": 2 },
    { "key": "fork-lifts", "name": "Forklifts", "status": "inactive", "level": 2 },
    { "key": "cranes", "name": "Cranes", "status": "inactive", "level": 2 }];
  //  [{ "key": "hazard", "name": "Hazard Areas", "status": "active" },
  // { "key": "aed", "name": "AED", "status": "active" },
  // { "key": "first-aid", "name": "First aid stations", "status": "active" },
  // { "key": "spill", "name": "Spill kits", "status": "inactive" }];
  wfmESQServiceList: any = [
    { "key": "hazard", "name": "Hazard Areas", "status": "active", "level": 1 },
    { "key": "aed", "name": "AED", "status": "active", "level": 1 },
    { "key": "first-aid", "name": "First aid stations", "status": "inactive", "level": 1 },
    { "key": "spill-kits", "name": "Spill kits", "status": "inactive", "level": 1 },
    { "key": "evacuation-posters", "name": "Evacuation posters", "status": "inactive", "level": 1 },
    // { "key": "shore-cables", "name": "Shore cables", "status": "inactive" },
    { "key": "kiosk", "name": "Kiosks", "status": "active", "level": 2 },
    { "key": "laydown", "name": "Laydown areas", "status": "active", "level": 2 },
    { "key": "contractors", "name": "Contractors", "status": "active", "level": 2 },
    { "key": "portable-generators", "name": "Portable generators", "status": "inactive", "level": 2 },
    { "key": "fork-lifts", "name": "Forklifts", "status": "inactive", "level": 2 },
    { "key": "cranes", "name": "Cranes", "status": "inactive", "level": 2 }];
  fmfESQServiceList: any = [
    { "key": "hazard", "name": "Hazard Areas", "status": "active", "level": 1 },
    { "key": "aed", "name": "AED", "status": "active", "level": 1 },
    { "key": "first-aid", "name": "First aid stations", "status": "inactive", "level": 1 },
    { "key": "spill-kits", "name": "Spill kits", "status": "inactive", "level": 1 },
    { "key": "evacuation-posters", "name": "Evacuation posters", "status": "inactive", "level": 1 },
    // { "key": "shore-cables", "name": "Shore cables", "status": "inactive" },
    { "key": "kiosk", "name": "Kiosks", "status": "active", "level": 2 },
    { "key": "laydown", "name": "Laydown areas", "status": "active", "level": 2 },
    { "key": "contractors", "name": "Contractors", "status": "active", "level": 2 },
    { "key": "portable-generators", "name": "Portable generators", "status": "inactive", "level": 2 },
    { "key": "fork-lifts", "name": "Forklifts", "status": "inactive", "level": 2 },
    { "key": "cranes", "name": "Cranes", "status": "inactive", "level": 2 }];
  //  [{ "key": "hazard", "name": "Hazard Areas", "status": "active" },
  // { "key": "aed", "name": "AED", "status": "active" },
  // { "key": "first-aid", "name": "First aid stations", "status": "active" },
  // { "key": "spill", "name": "Spill kits", "status": "inactive" }];
  reportList: any = [
    { "key": "dvt", "name": "DVT activity and jetty plan", "status": "active" },
    { "key": "hazard", "name": "Hazard Areas", "status": "active" },
    { "key": "aed", "name": "AED", "status": "active" },
    // { "key": "first-aid", "name": "First aid stations", "status": "active" },
    { "key": "kiosk", "name": "Kiosks", "status": "inactive" },
    { "key": "laydown", "name": "Laydown areas", "status": "inactive" },
    { "key": "manual-dvt", "name": "Manual DVT report", "status": "inactive" }];
  coneColors: any = [
    { "name": "orange", "code": "#df5900", 'material': 'cone_orange' },
    { "name": "red", "code": "#ff0000", 'material': 'cone_red' },
    // { "name": "green", "code": "#008000", 'material': 'cone_green' }
  ]
  hazardTypeList: any = [
    { "key": "emergency", "name": "Emergency", "label": "red", "filter": true },
    { "key": "spill", "name": "Spill", "label": "red", "filter": true },
    { "key": "notice", "name": "Notice", "label": "orange", "filter": true },
    { "key": "maintenance", "name": "Maintenance", "label": "orange", "filter": true },
    { "key": "disabledequipment", "name": "Disabled Equipment", "label": "orange", "filter": true },
    { "key": "pavementrepair", "name": "Pavement repair", "label": "orange", "filter": true },
    { "key": "temporarystructure", "name": "Temporary structure", "label": "orange", "filter": true },
    { "key": "other", "name": "Other", "label": "orange", "filter": true }
  ];
  hazardFilterKey = ['activity', 'startdate', 'enddate', 'color', 'notes']
  showShipDetailPanelOpen: any = true;
  filterLaydownHandleToggleOpen: boolean = false;
  filterHazardHandleToggleOpen: boolean = false;
  filterKioskHandleToggleOpen: boolean = false;
  filterFirstAidHandleToggleOpen: boolean = false;
  showFirstAidPanelOpen: boolean = true;
  firstAidHandleToggleOpen: boolean = false;
  shipAnimateList: any = [/* {'pid':'1','id':'1','animtype':"overview",'title':'Play Views','animateStatus':'stop','data':'scan'},
                           */{ 'pid': 'shipanim', 'id': '2', 'animtype': "synchrolift", 'title': 'Synchrolift', 'animateStatus': 'stop', 'data': 'scan' },
  ];
  ssoAnimateList: any = [{ 'pid': 'craneanim', 'id': '1', 'animtype': "craneanim", 'title': 'Animate Cranes', 'animateStatus': 'stop', 'data': '' },
  ];
  f35AnimateStatus:any='pause';
  f35AnimateList:any=[{ 'pid': 'canopy', 'id': '2', 'animtype': "canopy", 'title': 'Canopy', 'animateStatus': 'stop', 'data': '' },
  { 'pid': 'gear', 'id': '2', 'animtype': "gear", 'title': 'Landing gear', 'animateStatus': 'stop', 'data': '' },
  { 'pid': 'WeaponDoors', 'id': '2', 'animtype': "WeaponDoors", 'title': 'Weapons bay doors', 'animateStatus': 'stop', 'data': '' },
  { 'pid': 'RefuelSystem', 'id': '2', 'animtype': "RefuelSystem", 'title': 'Refuelling system', 'animateStatus': 'stop', 'data': '' },
  { 'pid': 'FCS', 'id': '2', 'animtype': "FCS", 'title': 'Flight control surfaces', 'animateStatus': 'stop', 'data': '' },
  { 'pid': 'TFLIR', 'id': '2', 'animtype': "TFLIR", 'title': 'Electro-optical tracking', 'animateStatus': 'stop', 'data': '' },
  { 'pid': 'EngineNozzle', 'id': '2', 'animtype': "EngineNozzle", 'title': 'Engine nozzle', 'animateStatus': 'stop', 'data': '' },]
  rawdataPanelOpen: boolean = false;
  rawDataList: any;
  showActivity: boolean = true;
  activityList: any = {};
  currentActivityList: any = [];
  currentActivityListState: any = {};
  sortItem: any = 'startdate';
  sortItemFirstaid: any = '_id'
  sortServiceItem: any;
  shipStatus: any = ['inprogress', 'scheduled', 'completed'];
  shipStatusList: any = ['inprogress', 'scheduled', 'completed'];
  activeShipList: any;
  isFilterAll: boolean = true;
  activityAll: any = true;
  statusAll: boolean = true;
  panelBG = "#333333";
  cardBG = "#4a4a4a";
  fontColor = "#ffffff";
  lineColor = "#9e9e9e"
  defaultRangeDate: any;
  hideCraneState: boolean = false;
  hideLightState: boolean = false;
  hidesynchrolift: boolean = false;
  currentPort: any = 'cfbhalifax';
  activitySelectedList: any = [];
  defaultDateRange: any = 27;
  selectedDateRange: any = 27;
  screenWidth: any = window.innerWidth;
  filterMode: boolean = false;
  global_pause: any;
  formData = new FormData();
  selectedFile: any = 'No file chosen...';
  showBlockLoading: boolean = false;
  kioskJson: any = {};
  kioskData: any;
  defaultActivityList = [{ "name": "Radiating", "key": "radiating" },
  { "name": "Engine Exhuasting", "key": "engineexhuasting" },
  { "name": "Man-Aloft", "key": "man-aloft" },
  { "name": "Diving Operations", "key": "divingoperations" },
  { "name": "Crane Work", "key": "cranework" },
  { "name": "Cold Move", "key": "coldmove" },
  { "name": "Antenna Rotating", "key": "antennarotating" },
  { "name": "Stack Emissions", "key": "stackemissions" },
  { "name": "Fueling Operations", "key": "fuelingoperations" },
  { "name": "Ammunitioning", "key": "ammunitioning" },
  { "name": "Jetty Service", "key": "jettyservice" },
  { "name": "Other Activities", "key": "otheractivities" },
  { "name": "Manaloft/Rotating", "key": "manaloft/rotating" },
  { "name": "Manaloft/Radiate", "key": "manaloft/radiate" },
  { "name": "Truck Fuelling", "key": "truckfuelling" },
  { "name": "Ceremonial", "key": "ceremonial" },
  { "name": "Bollardpulls", "key": "bollardpulls" },
  { "name": "Radiate", "key": "radiate" },
  { "name": "UW Tx", "key": "uxtx" },
  { "name": "Diving Ops", "key": "divingops" },
  { "name": "Crane Ops", "key": "craneops" },
  { "name": "Antenna Rotate", "key": "antennarotate" },
  { "name": "Run Engines", "key": "runengines" },
  { "name": "Black-out", "key": "blackout" },
  { "name": "Basin Trial", "key": "basintrial" },
  { "name": "Fast Cruise", "key": "fastcruise" },
  { "name": "Fuelling", "key": "fuelling" },
  { "name": "Lifting Cert", "key": "liftingcert" },
  { "name": "Ceremonial", "key": "ceremonial" },
  { "name": "Incline Exp", "key": "inclineexp" },
  { "name": "Trimming", "key": "trimming" }]
  languageSelected: any = 'English';
  loginUserShip: any;
  commandingShip: any;
  commandingShipDate = new Date();
  activityDate: any;
  activityVessel: any;
  // kisokElement: any = {
  //   "ajetty": [
  //     {
  //       "guid": 1,
  //       "location1": "Ship Repair Zone",
  //       "id": "Kiosk: SM 8",
  //       "servicetype": "Power Services",
  //       "service": "Electrical Power"
  //     },
  //     {
  //       "guid": 2,
  //       "location1": "Ship Repair Zone",
  //       "id": "Kiosk: SM 8",
  //       "servicetype": "Power Services",
  //       "service": "Electrical Power"
  //     },
  //     {
  //       "guid": 3,
  //       "location1": "Ship Repair Zone",
  //       "id": "Kiosk: SM 8",
  //       "servicetype": "Power Services",
  //       "service": "Electrical Power"
  //     },
  //     {
  //       "guid": 4,
  //       "location1": "Ship Repair Zone",
  //       "id": "Kiosk: SM 8",
  //       "servicetype": "Power Services",
  //       "service": "Load bank"
  //     },
  //     {
  //       "guid": 5,
  //       "location1": "Ship Repair Zone",
  //       "id": "Kiosk: SM 8",
  //       "servicetype": "Mechanical Services",
  //       "service": "Air"
  //     },
  //     {
  //       "guid": 6,
  //       "location1": "Ship Repair Zone",
  //       "id": "Kiosk: SM 8",
  //       "servicetype": "Mechanical Services",
  //       "service": "Steam"
  //     },
  //     {
  //       "guid": 7,
  //       "location1": "Ship Repair Zone",
  //       "id": "Kiosk: SM 8",
  //       "servicetype": "Mechanical Services",
  //       "service": "De-Ionized Water"
  //     },
  //     {
  //       "guid": 8,
  //       "location1": "Ship Repair Zone",
  //       "id": "Kiosk: SM 8",
  //       "servicetype": "Mechanical Services",
  //       "service": "Sewage Connection"
  //     },
  //     {
  //       "guid": 9,
  //       "location1": "Ship Repair Zone",
  //       "id": "Kiosk: SM 8",
  //       "servicetype": "Mechanical Services",
  //       "service": "Oily Waste Connectio"
  //     },
  //     {
  //       "guid": 10,
  //       "location1": "Ship Repair Zone",
  //       "id": "Kiosk: SM 8",
  //       "servicetype": "Mechanical Services",
  //       "service": "Industrial Waste Connection"
  //     },
  //     {
  //       "guid": 11,
  //       "location1": "Ship Repair Zone",
  //       "id": "Kiosk: SM 8",
  //       "servicetype": "Mechanical Services",
  //       "service": "Fire Connection"
  //     },
  //     {
  //       "guid": 12,
  //       "location1": "Ship Repair Zone",
  //       "id": "C4-001",
  //       "servicetype": "Space Mgt",
  //       "service": "Material Mgt Zone"
  //     },
  //     {
  //       "guid": 13,
  //       "location1": "SRZ",
  //       "id": "PED 1352",
  //       "servicetype": "Support Equipment",
  //       "service": "Cable Reel Management / cable cradles"
  //     }
  //   ]
  // }
  laydownData: any = [];
  laydownDataRes: any;
  laydownDataSearch: any = [];
  laydownDataTag: any = [
    // {
    //   "C1-001": {
    //     "pickedMesh": {
    //       "id": "element-cfb_halifax.model-cfb_halifax.laydown.model-cfb_halifax.laydown.S-8.model-cfb_halifax.laydown.S-8.S-8"
    //     },
    //     "pickedPoint": {
    //       "e": {
    //         "x": "494.9470952834181",
    //         "y": "4.901127846366762",
    //         "z": "-29.91511073865877"
    //       }
    //     }
    //   }
    // }
    {
      "_id": 'C1-001',
      "pickedMesh": {
        "id": "element-cfb_halifax.model-cfb_halifax.laydown.model-cfb_halifax.laydown.S-8.model-cfb_halifax.laydown.S-8.S-8"
      },
      "pickedPoint": {
        "x": 498.52468133722573,
        "y": 4.901128972068818,
        "z": -37.52900106213042
      }
    },
    {
      "_id": 'C1-002',
      "pickedMesh": {
        "id": "element-cfb_halifax.model-cfb_halifax.laydown.model-cfb_halifax.laydown.S-3.model-cfb_halifax.laydown.S-3.S-3"
      },
      "pickedPoint": {
        "x": 539.8720728810595,
        "y": 4.90149913773007,
        "z": -254.12572248494075
      }
    },
    {
      "_id": 'C1-004',
      "pickedMesh": {
        "id": "element-cfb_halifax.model-cfb_halifax.laydown.model-cfb_halifax.laydown.S-17.model-cfb_halifax.laydown.S-17.S-17"
      },
      "pickedPoint": {
        "x": 344.32224694967783,
        "y": 4.900558068268765,
        "z": 282.37844614417463
      }
    },
    {
      "_id": 'C2-012',
      "pickedMesh": {
        "id": "element-cfb_halifax.model-cfb_halifax.laydown.model-cfb_halifax.laydown.S-18.model-cfb_halifax.laydown.S-18.S-18"
      },
      "pickedPoint": {
        "x": 231.3757445785035,
        "y": 4.900438222535968,
        "z": 315.31566854214
      }
    },
    {
      "_id": 'C2-013',
      "pickedMesh": {
        "id": "element-cfb_halifax.model-cfb_halifax.laydown.model-cfb_halifax.laydown.S-21.model-cfb_halifax.laydown.S-21.S-21"
      },
      "pickedPoint": {
        "x": 153.74046819353396,
        "y": 4.900162900144323,
        "z": 478.1283891365016
      }
    },
    {
      "_id": 'G2_407',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.G2_407.model-cfb_esquimalt.FMF_CB._laydowns.G2_407.G2_407"
      },
      "pickedPoint": {
        "x": -307.66480836045776,
        "y": 2.999999957464297,
        "z": -101.9086017990146
      }
    },
    {
      "_id": 'G2_406',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.G2_406.model-cfb_esquimalt.FMF_CB._laydowns.G2_406.G2_406"
      },
      "pickedPoint": {
        "x": -318.79173115586,
        "y": 3.000000525517791,
        "z": -105.10894101447971
      }
    },
    {
      "_id": 'G4_405',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.G4_405.model-cfb_esquimalt.FMF_CB._laydowns.G4_405.G4_405"
      },
      "pickedPoint": {
        "x": -339.4798397184991,
        "y": 3.0000000761646106,
        "z": -100.70267843458714
      }
    },
    {
      "_id": 'G4_403',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.G4_403.model-cfb_esquimalt.FMF_CB._laydowns.G4_403.G4_403"
      },
      "pickedPoint": {
        "x": -352.70292366811066,
        "y": 3.0000001297789822,
        "z": -100.89626746154005
      }
    },
    {
      "_id": 'G4_402',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.G4_402.model-cfb_esquimalt.FMF_CB._laydowns.G4_402.G4_402"
      },
      "pickedPoint": {
        "x": -370.7503323840221,
        "y": 2.9999994142711444,
        "z": -98.63766948406666
      }
    },
    {
      "_id": 'G2_401',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.G2_401.model-cfb_esquimalt.FMF_CB._laydowns.G2_401.G2_401"
      },
      "pickedPoint": {
        "x": -363.9092146035864,
        "y": 3.000001102295755,
        "z": -80.64852893994865
      }
    },
    {
      "_id": 'G5_312',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.G5_312.model-cfb_esquimalt.FMF_CB._laydowns.G5_312.G5_312"
      },
      "pickedPoint": {
        "x": -398.15908239535725,
        "y": 2.999999739429427,
        "z": -99.2607427268139
      }
    },
    {
      "_id": 'G7310',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.G7310.model-cfb_esquimalt.FMF_CB._laydowns.G7310.G7310"
      },
      "pickedPoint": {
        "x": -418.1393045276057,
        "y": 3.000000408735076,
        "z": -102.33424264069942
      }
    },
    {
      "_id": 'G7_309',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.G7_309.model-cfb_esquimalt.FMF_CB._laydowns.G7_309.G7_309"
      },
      "pickedPoint": {
        "x": -438.16459629793127,
        "y": 2.9999998794554585,
        "z": -96.48407223312823
      }
    },
    {
      "_id": 'G7_307',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.G7_307.model-cfb_esquimalt.FMF_CB._laydowns.G7_307.G7_307"
      },
      "pickedPoint": {
        "x": -447.31352667042677,
        "y": 2.999999602060001,
        "z": -85.8242454832409
      }
    },
    {
      "_id": 'G7_306',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.G7_306.model-cfb_esquimalt.FMF_CB._laydowns.G7_306.G7_306"
      },
      "pickedPoint": {
        "x": -451.7371757957094,
        "y": 3.000001192295656,
        "z": -77.08388761762586
      }
    },
    {
      "_id": 'G7_303',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.G7_303.model-cfb_esquimalt.FMF_CB._laydowns.G7_303.G7_303"
      },
      "pickedPoint": {
        "x": -458.55954663648714,
        "y": 2.9999998553666956,
        "z": -65.06730712228097
      }
    },
    {
      "_id": 'G7_311',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.G7_311.model-cfb_esquimalt.FMF_CB._laydowns.G7_311.G7_311"
      },
      "pickedPoint": {
        "x": -416.1203508369977,
        "y": 2.959876303826661,
        "z": -85.45640843938477
      }
    },
    {
      "_id": 'G2_401',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.G2_401.model-cfb_esquimalt.FMF_CB._laydowns.G2_401.G2_401"
      },
      "pickedPoint": {
        "x": -364.4561963550638,
        "y": 3.0000009485811177,
        "z": -79.69650358479737
      }
    },
    {
      "_id": 'G7_306B',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.G7_306B.model-cfb_esquimalt.FMF_CB._laydowns.G7_306B.G7_306B"
      },
      "pickedPoint": {
        "x": -416.26238681986797,
        "y": 2.9999999918553044,
        "z": -79.16809551071543
      }
    },
    {
      "_id": 'G7_306A',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.G7_306A.model-cfb_esquimalt.FMF_CB._laydowns.G7_306A.G7_306A"
      },
      "pickedPoint": {
        "x": -429.0978580180832,
        "y": 3.0000003407861016,
        "z": -80.8393387243585
      }
    },
    {
      "_id": 'G7_308',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.G7_308.model-cfb_esquimalt.FMF_CB._laydowns.G7_308.G7_308"
      },
      "pickedPoint": {
        "x": -433.5357095153777,
        "y": 2.98497833177953,
        "z": -85.9040872066348
      }
    },
    {
      "_id": 'G7_304B',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.G7_304B.model-cfb_esquimalt.FMF_CB._laydowns.G7_304B.G7_304B"
      },
      "pickedPoint": {
        "x": -417.759373223088,
        "y": 2.99999981833912,
        "z": -69.61373164446917
      }
    },
    {
      "_id": 'G7_304A',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.G7_304A.model-cfb_esquimalt.FMF_CB._laydowns.G7_304A.G7_304A"
      },
      "pickedPoint": {
        "x": -430.4834336976043,
        "y": 2.9999997882505056,
        "z": -69.35273340992308
      }
    },
    {
      "_id": 'G7_301',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.G7_301.model-cfb_esquimalt.FMF_CB._laydowns.G7_301.G7_301"
      },
      "pickedPoint": {
        "x": -417.5529575865809,
        "y": 3.0000010465877835,
        "z": -60.03600872712616
      }
    },
    {
      "_id": 'G7_302',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.G7_302.model-cfb_esquimalt.FMF_CB._laydowns.G7_302.G7_302"
      },
      "pickedPoint": {
        "x": -434.07773658531437,
        "y": 3.0000004402986065,
        "z": -59.648333192120276
      }
    },
    {
      "_id": 'DIsposal_108',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.DIsposal_108.model-cfb_esquimalt.FMF_CB._laydowns.DIsposal_108.DIsposal_108"
      },
      "pickedPoint": {
        "x": -369.47361527335926,
        "y": 2.999928653240147,
        "z": -31.804590193658868
      }
    },
    {
      "_id": 'G7_205',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.G7_205.model-cfb_esquimalt.FMF_CB._laydowns.G7_205.G7_205"
      },
      "pickedPoint": {
        "x": -399.58573856632984,
        "y": 3.0000001987666565,
        "z": -29.449976668334386
      }
    },
    {
      "_id": 'G7_206',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.G7_206.model-cfb_esquimalt.FMF_CB._laydowns.G7_206.G7_206"
      },
      "pickedPoint": {
        "x": -422.23129789205586,
        "y": 3.0000000981754624,
        "z": -28.285193648400764
      }
    },
    {
      "_id": 'G4_201',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.G4_201.model-cfb_esquimalt.FMF_CB._laydowns.G4_201.G4_201"
      },
      "pickedPoint": {
        "x": -395.19689582163403,
        "y": 2.9999997619544843,
        "z": -20.745103044757627
      }
    },
    {
      "_id": 'G4_202',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.G4_202.model-cfb_esquimalt.FMF_CB._laydowns.G4_202.G4_202"
      },
      "pickedPoint": {
        "x": -409.58621750139014,
        "y": 2.999999602527737,
        "z": -19.7883639020244
      }
    },
    {
      "_id": 'G4_204',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.G4_204.model-cfb_esquimalt.FMF_CB._laydowns.G4_204.G4_204"
      },
      "pickedPoint": {
        "x": -418.7656883504851,
        "y": 2.999999584172599,
        "z": -19.74777238804097
      }
    },
    {
      "_id": 'G4_203',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.G4_203.model-cfb_esquimalt.FMF_CB._laydowns.G4_203.G4_203"
      },
      "pickedPoint": {
        "x": -423.43851968163926,
        "y": 3.500067761987907,
        "z": -10.299664190011924
      }
    },
    {
      "_id": 'G2_105',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.G2_105.model-cfb_esquimalt.FMF_CB._laydowns.G2_105.G2_105"
      },
      "pickedPoint": {
        "x": -293.21067096822253,
        "y": 2.9999999403953552,
        "z": 8.956866378095924
      }
    },
    {
      "_id": 'G7_106',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.G7_106.model-cfb_esquimalt.FMF_CB._laydowns.G7_106.G7_106"
      },
      "pickedPoint": {
        "x": -310.9278331760487,
        "y": 3.0000000596044742,
        "z": -11.927146669878766
      }
    },
    {
      "_id": 'G7_107',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.G7_107.model-cfb_esquimalt.FMF_CB._laydowns.G7_107.G7_107"
      },
      "pickedPoint": {
        "x": -322.0159731463849,
        "y": 3.0000601419523036,
        "z": -14.684850617262839
      }
    },
    {
      "_id": 'G4_DY250_S001',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.G4_DY250_S001.model-cfb_esquimalt.FMF_CB._laydowns.G4_DY250_S001.G4_DY250_S001"
      },
      "pickedPoint": {
        "x": -452.9607410327933,
        "y": 3.5000566351418456,
        "z": 58.70259716783627
      }
    },
    {
      "_id": 'G5_DY250_S002',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.G5_DY250_S002.model-cfb_esquimalt.FMF_CB._laydowns.G5_DY250_S002.G5_DY250_S002"
      },
      "pickedPoint": {
        "x": -444.49130762437653,
        "y": 3.5000540595613074,
        "z": 74.38358304748205
      }
    },
    {
      "_id": 'G1_DY250_S004',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.G1_DY250_S004.model-cfb_esquimalt.FMF_CB._laydowns.G1_DY250_S004.G1_DY250_S004"
      },
      "pickedPoint": {
        "x": -440.6123282658207,
        "y": 3.50005231820478,
        "z": 84.93257622143446
      }
    },
    {
      "_id": 'TB_221',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.TB_221.model-cfb_esquimalt.FMF_CB._laydowns.TB_221.TB_221"
      },
      "pickedPoint": {
        "x": -349.7563714861344,
        "y": 3.5000334870035203,
        "z": 197.9926771603885
      }
    },
    {
      "_id": 'TB_222',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.TB_222.model-cfb_esquimalt.FMF_CB._laydowns.TB_222.TB_222"
      },
      "pickedPoint": {
        "x": -358.8548489095343,
        "y": 3.500034315878736,
        "z": 193.63502311115565
      }
    },
    {
      "_id": 'OPS_02',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.OPS_02.model-cfb_esquimalt.FMF_CB._laydowns.OPS_02.OPS_02"
      },
      "pickedPoint": {
        "x": -389.77123160339397,
        "y": 3.500035407867017,
        "z": 187.43868486719361
      }
    },
    {
      "_id": 'OPS_01',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.OPS_01.model-cfb_esquimalt.FMF_CB._laydowns.OPS_01.OPS_01"
      },
      "pickedPoint": {
        "x": -324.3819135111041,
        "y": 3.5000367155486174,
        "z": 178.75619206781062
      }
    },
    {
      "_id": 'GM2_11B',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.GM2_11B.model-cfb_esquimalt.FMF_CB._laydowns.GM2_11B.GM2_11B"
      },
      "pickedPoint": {
        "x": -243.46764677352098,
        "y": 3.000026226043758,
        "z": 134.76171028502046
      }
    },
    {
      "_id": 'GM2_10B',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.GM2_10B.model-cfb_esquimalt.FMF_CB._laydowns.GM2_10B.GM2_10B"
      },
      "pickedPoint": {
        "x": -251.03737548580003,
        "y": 3.0000262260436728,
        "z": 134.30378738500983
      }
    },
    {
      "_id": 'GM2_9B',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.GM2_9B.model-cfb_esquimalt.FMF_CB._laydowns.GM2_9B.GM2_9B"
      },
      "pickedPoint": {
        "x": -258.42944399529256,
        "y": 3.000026226043701,
        "z": 134.3747292174322
      }
    },
    {
      "_id": 'OPS_11A',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.OPS_11A.model-cfb_esquimalt.FMF_CB._laydowns.OPS_11A.OPS_11A"
      },
      "pickedPoint": {
        "x": -243.52471950132457,
        "y": 3.000026226043701,
        "z": 127.2440115705444
      }
    },
    {
      "_id": 'Park_10A',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.Park_10A.model-cfb_esquimalt.FMF_CB._laydowns.Park_10A.Park_10A"
      },
      "pickedPoint": {
        "x": -251.83201831669672,
        "y": 3.000026226043701,
        "z": 127.31388812677825
      }
    },
    {
      "_id": 'GM3_9A',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.GM3_9A.model-cfb_esquimalt.FMF_CB._laydowns.GM3_9A.GM3_9A"
      },
      "pickedPoint": {
        "x": -258.6205306316692,
        "y": 3.000026226043815,
        "z": 126.95409341947266
      }
    },
    {
      "_id": 'GM2_8B',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.GM2_8B.model-cfb_esquimalt.FMF_CB._laydowns.GM2_8B.GM2_8B"
      },
      "pickedPoint": {
        "x": -279.6478828487667,
        "y": 3.0000262260435875,
        "z": 135.65576596414328
      }
    },
    {
      "_id": 'GM2_7B',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.GM2_7B.model-cfb_esquimalt.FMF_CB._laydowns.GM2_7B.GM2_7B"
      },
      "pickedPoint": {
        "x": -288.80362527104415,
        "y": 3.000026226043701,
        "z": 134.51687067623138
      }
    },
    {
      "_id": 'GM2_6B',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.GM2_6B.model-cfb_esquimalt.FMF_CB._laydowns.GM2_6B.GM2_6B"
      },
      "pickedPoint": {
        "x": -298.86389916114337,
        "y": 3.000026226043701,
        "z": 134.44070979706686
      }
    },
    {
      "_id": 'GM7_8A',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.GM7_8A.model-cfb_esquimalt.FMF_CB._laydowns.GM7_8A.GM7_8A"
      },
      "pickedPoint": {
        "x": -278.29453573426935,
        "y": 3.000026226043474,
        "z": 126.71241225959194
      }
    },
    {
      "_id": 'GM6_7A',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.GM6_7A.model-cfb_esquimalt.FMF_CB._laydowns.GM6_7A.GM6_7A"
      },
      "pickedPoint": {
        "x": -287.61392887698173,
        "y": 3.000026226043701,
        "z": 126.9420732685145
      }
    },
    {
      "_id": 'GM6_6A',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.GM6_6A.model-cfb_esquimalt.FMF_CB._laydowns.GM6_6A.GM6_6A"
      },
      "pickedPoint": {
        "x": -300.40686163468484,
        "y": 2.975026208907366,
        "z": 126.9705885563865
      }
    },
    {
      "_id": 'GM2_5B',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.GM2_5B.model-cfb_esquimalt.FMF_CB._laydowns.GM2_5B.GM2_5B"
      },
      "pickedPoint": {
        "x": -313.6507084185962,
        "y": 3.0000262260435875,
        "z": 134.68642235709902
      }
    },
    {
      "_id": 'GM2_4B',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.GM2_4B.model-cfb_esquimalt.FMF_CB._laydowns.GM2_4B.GM2_4B"
      },
      "pickedPoint": {
        "x": -322.26048771332046,
        "y": 3.000026226043701,
        "z": 134.93418713805102
      }
    },
    {
      "_id": 'GM2_3B',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.GM2_3B.model-cfb_esquimalt.FMF_CB._laydowns.GM2_3B.GM2_3B"
      },
      "pickedPoint": {
        "x": -329.42552308775436,
        "y": 3.000026226043701,
        "z": 134.15117749674192
      }
    },
    {
      "_id": 'GM2_2B',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.GM2_2B.model-cfb_esquimalt.FMF_CB._laydowns.GM2_2B.GM2_2B"
      },
      "pickedPoint": {
        "x": -336.8856549381197,
        "y": 3.000026226043701,
        "z": 134.68483061065618
      }
    },
    {
      "_id": 'GM2_1B',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.GM2_1B.model-cfb_esquimalt.FMF_CB._laydowns.GM2_1B.GM2_1B"
      },
      "pickedPoint": {
        "x": -344.4993533655613,
        "y": 3.000026226043701,
        "z": 134.82676857642267
      }
    },
    {
      "_id": 'GM6_5A',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.GM6_5A.model-cfb_esquimalt.FMF_CB._laydowns.GM6_5A.GM6_5A"
      },
      "pickedPoint": {
        "x": -314.33107255589096,
        "y": 2.975026208907252,
        "z": 126.59558392831312
      }
    },
    {
      "_id": 'GM6_4A',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.GM6_4A.model-cfb_esquimalt.FMF_CB._laydowns.GM6_4A.GM6_4A"
      },
      "pickedPoint": {
        "x": -321.22010977401055,
        "y": 3.0000262260435875,
        "z": 126.44718671241553
      }
    },
    {
      "_id": 'GM6_3A',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.GM6_3A.model-cfb_esquimalt.FMF_CB._laydowns.GM6_3A.GM6_3A"
      },
      "pickedPoint": {
        "x": -329.2531813020236,
        "y": 3.0000262260435875,
        "z": 127.03997714294928
      }
    },
    {
      "_id": 'GM5_2A',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.GM5_2A.model-cfb_esquimalt.FMF_CB._laydowns.GM5_2A.GM5_2A"
      },
      "pickedPoint": {
        "x": -336.3126848539796,
        "y": 3.000026226043701,
        "z": 127.14721653582566
      }
    },
    {
      "_id": 'GM7_1A',
      "pickedMesh": {
        "id": "cfb_esquimalt.FMF_CB._laydowns.model-cfb_esquimalt.FMF_CB._laydowns.GM7_1A.model-cfb_esquimalt.FMF_CB._laydowns.GM7_1A.GM7_1A"
      },
      "pickedPoint": {
        "x": -345.25393517665947,
        "y": 3.000026226043474,
        "z": 126.01290489795807
      }
    }
  ];
  chitFormData: any = [
    {
      "index": '1',
      "id": '60ed81d8b3ebe8003559a4ce',
      'number': "FFH 341"
    },
    {
      "index": '2',
      "id": '60ed81d8b3ebe8003559a4ce',
      'number': "FFH 341"
    }
    ,
    {
      "index": '3',
      "id": '60edba85b3ebe8003559a4d6',
      'number': "FFH 341"
    }
    ,
    {
      "index": '4',
      "id": '60edb85eb3ebe8003559a4d5',
      'number': "FFH 341"
    }
  ];
  laydownSearchData = '';
  firstAidSearchData = '';
  kisokElementArray: any = [];
  kisokServiceData = '';
  kioskStatusList: any;
  kisokServiceDatas: boolean = false;
  showCommentPanel: boolean = false;
  showCommentStatus: boolean = false;
  commentDatas: any;
  commentPanelOpenidx: any;
  commentPanelOpenid: any;
  infoIconPower = "Power Services"
  infoIconMech = "Mechanical Services"
  commentReadonly = true;
  showCommentEditIcon = true;

  isCheckedService;
  isCheckedServiceName;
  serviceFileterData = ['jetty', 'location1', 'kiosk', 'servicetype'];

  locationconeColors: any = [
    { "name": "orange", "code": "#df5900", 'material': 'location_orange' },
    { "name": "red", "code": "#ff0000", 'material': 'location_red' },
    { "name": "green", "code": "#008000", 'material': 'location_green' }
  ]
  aedExpRange: any = 'all';
  statusUpdated: any;
  avtivityDays: any;
  avtivityStartDate: any;
  avtivityEndDate: any;

  shipLocations: any = [
    {
      "key": "A1",
      "value": "A1"
    },
    {
      "key": "A1,2",
      "value": "A1,2"
    },
    {
      "key": "A2",
      "value": "A2"
    },
    {
      "key": "A2,2",
      "value": "A2,2"
    },
    {
      "key": "B1",
      "value": "B1"
    },
    {
      "key": "B1,2",
      "value": "B1,2"
    },
    {
      "key": "B2",
      "value": "B2"
    },
    {
      "key": "B2,2",
      "value": "B2,2"
    },
    {
      "key": "C1",
      "value": "C1"
    },
    {
      "key": "C1,2",
      "value": "C1,2"
    },
    {
      "key": "C2",
      "value": "C2"
    },
    {
      "key": "C2,2",
      "value": "C2,2"
    },
    {
      "key": "C3",
      "value": "C3"
    },
    {
      "key": "C3,2",
      "value": "C3,2"
    },
    {
      "key": "C4",
      "value": "C4"
    },
    {
      "key": "C4,2",
      "value": "C4,2"
    },
    {
      "key": "C4,3",
      "value": "C4,3"
    },
    {
      "key": "C4,4",
      "value": "C4,4"
    },
    {
      "key": "C5",
      "value": "C5"
    },
    {
      "key": "C5,2",
      "value": "C5,2"
    },
    {
      "key": "YN",
      "value": "YN"
    },
    {
      "key": "YN,2",
      "value": "YN,2"
    },
    {
      "key": "Y1",
      "value": "Y1"
    },
    {
      "key": "Y1,2",
      "value": "Y1,2"
    },
    {
      "key": "YS",
      "value": "YS"
    },
    {
      "key": "YS,2",
      "value": "YS,2"
    },
    {
      "key": "Y2",
      "value": "Y2"
    },
    {
      "key": "Y2,2",
      "value": "Y2,2"
    },
    {
      "key": "Y3",
      "value": "Y3"
    },
    {
      "key": "Y3,2",
      "value": "Y3,2"
    },
    {
      "key": "FMFDryDock",
      "value": "FMFDryDock"
    },
    {
      "key": "SA1",
      "value": "SA1"
    },
    {
      "key": "SA2",
      "value": "SA2"
    },
    {
      "key": "SA3",
      "value": "SA3"
    },
    {
      "key": "SA4",
      "value": "SA4"
    },
    {
      "key": "SA5",
      "value": "SA5"
    },
    {
      "key": "SA6",
      "value": "SA6"
    },
    {
      "key": "SB1",
      "value": "SB1"
    },
    {
      "key": "SB2",
      "value": "SB2"
    },
    {
      "key": "SB3",
      "value": "SB3"
    },
    {
      "key": "SB4",
      "value": "SB4"
    },
    {
      "key": "SB5",
      "value": "SB5"
    },
    {
      "key": "SB6",
      "value": "SB6"
    },
    {
      "key": "SB7",
      "value": "SB7"
    },
    {
      "key": "SB8",
      "value": "SB8"
    },
    {
      "key": "SC1",
      "value": "SC1"
    },
    {
      "key": "SC2",
      "value": "SC2"
    },
    {
      "key": "SC3",
      "value": "SC3"
    },
    {
      "key": "SC5",
      "value": "SC5"
    },
    {
      "key": "SC6",
      "value": "SC6"
    },
    {
      "key": "SC7",
      "value": "SC7"
    },
    {
      "key": "SD1",
      "value": "SD1"
    },
    {
      "key": "SD2",
      "value": "SD2"
    },
    {
      "key": "SD3",
      "value": "SD3"
    },
    {
      "key": "SD4",
      "value": "SD4"
    },
    {
      "key": "SD5",
      "value": "SD5"
    }
  ];
  shipConfig: any = {
    "HAL": {
      "model": "Halifax",
      "class": "Halifax",
      "name": "HMCS HALIFAX",
      "number": "FFH 330"
    },
    "VAN": {
      "model": "Halifax",
      "class": "Halifax",
      "name": "HMCS Vancouver",
      "number": "FFH 331"
    },
    "VDQ": {
      "model": "Halifax",
      "class": "Halifax",
      "name": "HMCS VILLE DE QUEBEC",
      "number": "FFH 332"
    },
    "TOR": {
      "model": "Halifax",
      "class": "Halifax",
      "name": "HMCS TORONTO",
      "number": "FFH 333"
    },
    "REG": {
      "model": "Halifax",
      "class": "Halifax",
      "name": "HMCS Regina",
      "number": "FFH 334"
    },
    "CAL": {
      "model": "Halifax",
      "class": "Halifax",
      "name": "HMCS Calgary",
      "number": "FFH 335"
    },
    "MON": {
      "model": "Halifax",
      "class": "Halifax",
      "name": "HMCS MONTREAL",
      "number": "FFH 336"
    },
    "FRE": {
      "model": "Halifax",
      "class": "Halifax",
      "name": "HMCS FREDERICTON",
      "number": "FFH 337"
    },
    "WIN": {
      "model": "Halifax",
      "class": "Halifax",
      "name": "HMCS Winnipeg",
      "number": "FFH 338"
    },
    "CHA": {
      "model": "Halifax",
      "class": "Halifax",
      "name": "HMCS CHARLOTTETOWN",
      "number": "FFH 339"
    },
    "STJ": {
      "model": "Halifax",
      "class": "Halifax",
      "name": "HMCS ST JOHN’S",
      "number": "FFH 340"
    },
    "OTT": {
      "model": "Halifax",
      "class": "Halifax",
      "name": "HMCS Ottawa",
      "number": "FFH 341"
    },
    "VSR": {
      "model": "Halifax",
      "class": "Halifax",
      "name": "HMCS VSR",
      "number": ""
    },
    "VIS": {
      "model": "vis-model",
      "class": "Halifax",
      "name": "HMNZS Te Mana",
      "number": "F111"
    },
    "ORI": {
      "model": "orca-model",
      "class": "Orca",
      "name": "HMCS ORIOLE",
      "number": "YAC 3"
    },
    "AST": {
      "model": "asterix-model",
      "class": "Asterix",
      "name": "MV ASTERIX",
      "number": "AST"
    },
    "HDW": {
      "model": "aops-model",
      "class": "DeWolf",
      "name": "HMCS HARRY DEWOLF",
      "number": "AOPV 430"
    },
    "MAR": {
      "model": "aops-model",
      "class": "DeWolf",
      "name": "HMCS MARGARET BROOKE",
      "number": "AOPV 431"
    },
    "VIC": {
      "model": "vcsub-model",
      "class": "Victoria",
      "name": "HMCS VICTORIA",
      "number": "SSK 876"
    },
    "WSR": {
      "model": "vcsub-model",
      "class": "Victoria",
      "name": "HMCS WINDSOR",
      "number": "SSK 877"
    },
    "COR": {
      "model": "vcsub-model",
      "class": "Victoria",
      "name": "HMCS Corner Brook",
      "number": "SSK 878"
    },
    "CHI": {
      "model": "vcsub-model",
      "class": "Victoria",
      "name": "HMCS Chicoutimi",
      "number": "SSK 879"
    },
    "KIN": {
      "model": "kingston-model",
      "class": "kingston",
      "name": "HMCS KINGSTON",
      "number": " MM 700"
    },
    "GLA": {
      "model": "kingston-model",
      "class": "kingston",
      "name": "HMCS GLACE BAY",
      "number": "MM 701"
    },
    "NAN": {
      "model": "kingston-model",
      "class": "kingston",
      "name": "HMCS NANAIMO",
      "number": "MM 702"
    },
    "EDM": {
      "model": "kingston-model",
      "class": "kingston",
      "name": "HMCS Edmonton",
      "number": "MM 703"
    },
    "SHA": {
      "model": "kingston-model",
      "class": "kingston",
      "name": "HMCS SHAWINIGAN",
      "number": "MM 704"
    },
    "WHI": {
      "model": "kingston-model",
      "class": "kingston",
      "name": "HMCS WHITEHORSE",
      "number": "MM 705"
    },
    "YEL": {
      "model": "kingston-model",
      "class": "kingston",
      "name": "HMCS Yellowknife",
      "number": "MM 706"
    },
    "GBY": {
      "model": "kingston-model",
      "class": "kingston",
      "name": "HMCS GOOSE BAY",
      "number": "MM 707"
    },
    "MCT": {
      "model": "kingston-model",
      "class": "kingston",
      "name": "HMCS MONCTON",
      "number": "MM 708"
    },
    "SAS": {
      "model": "kingston-model",
      "class": "kingston",
      "name": "HMCS SASKATOON",
      "number": "MM 709"
    },
    "BRN": {
      "model": "kingston-model",
      "class": "kingston",
      "name": "HMCS Brandon",
      "number": "MM 710"
    },
    "SUM": {
      "model": "kingston-model",
      "class": "kingston",
      "name": "HMCS SUMMERSIDE",
      "number": "MM 711"
    },
    "SAC": {
      "model": "sackville-model",
      "class": "Boat",
      "name": "Sackville",
      "number": ""
    },
    "TUG": {
      "model": "tug-model",
      "class": "Boat",
      "name": "Tug",
      "number": ""
    },
    "ORC": {
      "model": "orca-boat-model",
      "class": "Boat",
      "name": "ORCA",
      "number": ""
    },
    "RHP": {
      "model": "rhib-p-model",
      "class": "Boat",
      "name": "RHIB P",
      "number": ""
    },
    "RHB": {
      "model": "rhib-model",
      "class": "Boat",
      "name": "RHIB_S",
      "number": ""
    }
  };
  loginInfo: any = {};
  cfbport: string = '';
  userRole:any;
  ship_2_name="HMCS VANCOUVER";
  constructor(private radhazVisServ: RadhazVisService,private datepipe: DatePipe, private configService: ConfigService, private cdRef: ChangeDetectorRef, private translate: TranslateService, private languageChange: LanguageService, private datePipe: DatePipe, private tagServ: TagService, private activityServ: ActivityVisualizationService, private communicationServ: CommunicationService, private ctrlServe: ControllerService,private drawService : DrawService, private chitService: ChitService, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.activityList = this.activityServ.activityConfig;
    this.serviceList = this.ctrlServe.service;
    this.statusList = this.ctrlServe.statusList;
    this.mbsStatusList = this.ctrlServe.mbsStatusList;
    this.translate.addLangs(['en', 'fr']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
    // this.jettyLabel({checked:false})
    matIconRegistry.addSvgIcon(
      '2d-icon',
      domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/2d-icon.svg")
    );
    matIconRegistry.addSvgIcon(
      '3d-icon',
      domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/3d-icon.svg")
    );
    matIconRegistry.addSvgIcon(
      'roof-icon',
      domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/roof.svg")
    );
    matIconRegistry.addSvgIcon(
      'roof-slash-icon',
      domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/roof-slash.svg")
    );

  }




  fmfSidePanelState = false;
  wfmSidePanelState = false;
  updateMenuAccess() {
    console.log("updateMenuAccess ", this.profileInfo);
    this.fmfSidePanelState = this.profileInfo['FMF'] == 'Y' ? false : (this.profileInfo['FMF'] == 'N' ? true : false);
    this.wfmSidePanelState = this.profileInfo['WFM'] == 'Y' ? false : (this.profileInfo['WFM'] == 'N' ? true : false);

    this.wfmHALServiceList.forEach(service => {
      let item = Object.keys(this.profileInfo).find(el => el.toLocaleLowerCase() == service['key'].toLocaleLowerCase())
      if (item) {
        service['status'] = this.profileInfo[service['key']] == 'Y' ? 'active' : 'inactive';
      }
    });

    this.fmfHALServiceList.forEach(service => {
      let item = Object.keys(this.profileInfo).find(el => el.toLocaleLowerCase() == service['key'].toLocaleLowerCase())
      if (item) {
        service['status'] = this.profileInfo[service['key']] == 'Y' ? 'active' : 'inactive';
      }
    });

    this.wfmESQServiceList.forEach(service => {
      let item = Object.keys(this.profileInfo).find(el => el.toLocaleLowerCase() == service['key'].toLocaleLowerCase())
      if (item) {
        service['status'] = this.profileInfo[service['key']] == 'Y' ? 'active' : 'inactive';
      }
    });

    this.fmfESQServiceList.forEach(service => {
      let item = Object.keys(this.profileInfo).find(el => el.toLocaleLowerCase() == service['key'].toLocaleLowerCase())
      if (item) {
        service['status'] = this.profileInfo[service['key']] == 'Y' ? 'active' : 'inactive';
      }
    });
  }

  /***************
   * highest status update ship
   */
  updateShipHighStatus(ship) {
    console.log("updateShipHighStatus", ship);
    var state = '';
    ship['hazardList'].forEach((element, i) => {
      // if(!state){
      if (element['area'] == "Buoyancy" || element['area'] == "Fire Safety" || element['area'] == "EER") {
        if (element['activity'] == 'Not Started') {
          if (state == 'Critical issues preventing completion' || state == 'Major Issues preventing completion' || state == 'Significant Issues successful delivery at high risk' || state == 'On Track minor issues' || state == 'No Issues' || state == 'Completed' || state == '') {
            state = element['activity'];
          }
        }
        if (element['activity'] == 'Critical issues preventing completion') {
          if (state == 'Major Issues preventing completion' || state == 'Significant Issues successful delivery at high risk' || state == 'On Track minor issues' || state == 'No Issues' || state == 'Completed' || state == '') {
            state = element['activity'];
          }
        }
        if (element['activity'] == 'Major Issues preventing completion') {
          if (state == 'Significant Issues successful delivery at high risk' || state == 'On Track minor issues' || state == 'No Issues' || state == 'Completed' || state == '') {
            state = element['activity'];
          }
        }
        if (element['activity'] == 'Significant Issues successful delivery at high risk') {
          if (state == 'On Track minor issues' || state == 'No Issues' || state == 'Completed' || state == '') {
            state = element['activity'];
          }
        }
        if (element['activity'] == 'On Track minor issues') {
          if (state == 'No Issues' || state == 'Completed' || state == '') {
            state = element['activity'];
          }
        }
        if (element['activity'] == 'No Issues') {
          if (state == 'Completed' || state == '') {
            state = element['activity'];
          }
        }
        if (element['activity'] == 'Completed') {
          if (state == '') {
            state = element['activity'];
          }
        }
      }
      // }
    });
    ship['_state'] = state;
    return ship;
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  onChangeServiceFileter(e: { target: { name: any; }; }) {
    this.isCheckedService = !this.isCheckedService;
    this.isCheckedServiceName = e.target.name;
    console.log('selected data', this.isCheckedServiceName);
  }
  showCommentPanelOpenHanddle(idx, id) {
    if (idx === idx) {
      this.commentReadonly = true;
      this.showCommentPanel = !this.showCommentPanel;
      this.commentPanelOpenidx = idx;
      this.commentPanelOpenid = id;
      console.log("Service id : ", idx)
      this.showCommentEditIcon = true;
    } else {
      this.showCommentPanel = false;
    }

  }

  commentData(data, commentData, i) {
    var kioskData = { 'data': data, 'comment': commentData, 'index': i }
    this.broadcastInfo({ src: 'sidepanel', event: 'commentData', data: kioskData, key: "" });
    // console.log("dfsa####", kioskData);
    this.commentDatas = ''
    console.log("Comment Data : ", this.commentDatas);
    console.log("Comment Data passs : ", this.broadcastInfo);

    this.showCommentPanel = false;
    this.showCommentStatus = true;
    setInterval(() => {
      this.showCommentStatus = false;
    }, 1000);
  }
  commentDataCancel() {
    this.commentDatas = ''
    this.showCommentPanel = false;
  }
  commentDataEdit() {
    this.showCommentEditIcon = false;
    this.commentReadonly = false;
  }
  filterResults() {
    this.searchKiosk();
    console.log(this.kisokElementArray)
    if (!this.kisokServiceData) {
      this.accordion.closeAll();
      this.kisokServiceDatas = false;
    } else {
      this.accordion.openAll();
      this.kisokServiceDatas = true;
    }
  }

  /*****
   * kiosk Search
   */
  kioskUpdatedData: any;
  kioskFilterKey: any = ['kiosk', 'location1', 'location2', 'location3', 'service', 'servicetype']
  kioskFormatData: any;
  searchKiosk() {
    var filterData = [];
    if (!this.kisokServiceData) {
      filterData = null;
      this.kioskUpdatedData = this.kioskData;
    } else {
      if (this.kisokServiceData.length > 3) {
        if (this.kioskData.length > 0) {
          for (let i = 0; i < this.kioskData.length; i++) {
            if (this.kioskFilterKey.length > 0) {
              this.kioskFilterKey.forEach((key) => {
                if (typeof this.kioskData[i][key] === 'string' && typeof this.kisokServiceData === 'string') {
                  if (this.kioskData[i][key].toLowerCase().indexOf(this.kisokServiceData.toLowerCase()) > -1) {
                    const found = filterData.some(el => el.uid === this.kioskData[i].uid);
                    if (!found) {
                      filterData.push(this.kioskData[i]);
                    }
                  }
                }
              });
            }
          }
        }
      }

      console.log('kioskUpdatedData', filterData)
      this.kioskUpdatedData = filterData;
      this.kioskTagOpen();
    }
  }

  kioskTagOpen() {

    this.kioskData.forEach(element => {
      let idx = this.kioskUpdatedData.findIndex(e => e.uid == element.uid)
      if (idx > -1) {
        this.broadcastInfo({ src: 'main', event: 'showKioskId', data: element.uid, state: null, sub: null });
        // this.tagServ.searchKioskTag(element)
      } else {
        this.tagServ.showKioskTag(element.uid, false);
      }
    });
  }

  kioskOrderList(data) {
    var arrData = [];
    var arrList = Object.keys(data);
    arrList.forEach(element => {
      if (arrData.length > 0) {

        data[element].forEach(ele => {
          arrData.push(ele)
        });

      } else {
        arrData = data[element]
      }


    });
    console.log(arrData);
    this.kioskData = arrData;
  }
  onChangeLanguage(langName) {
    // let langKey;
    if (langName == 'English') {
      this.languageChange.changeLanguage('fr');
      this.translate.use('fr');
      this.languageSelected = 'French';
      // this.nxtLanguage = 'French';
    }
    if (langName == 'French') {
      this.languageChange.changeLanguage('en');
      this.translate.use('en');
      // this.nxtLanguage = 'English';
      this.languageSelected = 'English';
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = event.target.innerWidth;
  }
  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }

  // orderKeys = ['location1', 'id', 'servicetype', 'service'];
  // newKisokElements = {};
  // checkVal = '';
  // kisokOrderMapping() {
  //   let kisokAllElements = this.kisokElement['ajetty'];
  //   let key1 = this.orderKeys[0];
  //   let key2 = this.orderKeys[1];
  //   let key3 = this.orderKeys[2];
  //   let key4 = this.orderKeys[3];
  //   this.orderKeys.forEach((orderkey, index) => {
  //     if (index === 0) {
  //       this.newKisokElements[orderkey] = [];
  //     }
  //     else if (index === 1) {
  //       this.newKisokElements[key1].forEach(el => el[orderkey] = []);
  //     }
  //     else if (index === 2) {
  //       this.newKisokElements[key1].forEach(value1 => {
  //         value1[key2].forEach(value2 => {
  //           value2[orderkey] = [];
  //         })
  //       });
  //     }
  //     else if (index === 3) {
  //       this.newKisokElements[key1].forEach(value1 => {
  //         value1[key2].forEach(value2 => {
  //           value2[key3].forEach(value3 => {
  //             value3[orderkey] = [];
  //           })
  //         })
  //       });
  //     }
  //     kisokAllElements.forEach(data => {
  //       let checkVal = data[orderkey].split(" ").join("");
  //       if (this.checkVal !== checkVal) {
  //         this.checkVal = checkVal;
  //         let filterObjects = kisokAllElements.filter(el => el[orderkey].split(" ").join("") === this.checkVal);
  //         let item = {};
  //         filterObjects.forEach(elem => {
  //           item[orderkey] = elem[orderkey];
  //           if (index === 0) {
  //             const found = this.newKisokElements[orderkey].some(el => el[orderkey] === elem[orderkey]);
  //             if (!found) {
  //               item['uid'] = uuid.v4();
  //               this.newKisokElements[orderkey].push(item);
  //             }
  //           }
  //           else if (index === 1) {
  //             this.newKisokElements[key1].forEach(value1 => {
  //               if (value1[key1] === elem[key1]) {
  //                 item['uid'] = value1['uid'];
  //                 const found = value1[orderkey].some(el => el[orderkey] === elem[orderkey]);
  //                 if (!found) {
  //                   value1[orderkey].push(item);
  //                 }
  //               }
  //             })
  //           }
  //           else if (index === 2) {
  //             this.newKisokElements[key1].forEach(value1 => {
  //               value1[key2].forEach(value2 => {
  //                 if (value2[key2] === elem[key2]) {
  //                   item['uid'] = value2['uid'];
  //                   const found = value2[orderkey].some(el => el[orderkey] === elem[orderkey]);
  //                   if (!found) {
  //                     value2[orderkey].push(item);
  //                   }
  //                 }
  //               });
  //             })
  //           }
  //           else if (index === 3) {
  //             this.newKisokElements[key1].forEach(value1 => {
  //               value1[key2].forEach(value2 => {
  //                 value2[key3].forEach(value3 => {
  //                   if (value3[key3] === elem[key3]) {
  //                     item['uid'] = value3['uid'];
  //                     const found = value3[orderkey].some(el => el[orderkey] === elem[orderkey]);
  //                     if (!found) {
  //                       value3[orderkey].push(item);
  //                     }
  //                   }
  //                 })
  //               });
  //             })
  //           }
  //         })
  //       }
  //     });
  //   });
  //   // console.log("----------------");
  //   // console.log("newKisokElements ", this.newKisokElements)
  //   // this.kisokElementArray = this.newKisokElements;
  //   // console.log("----------------");
  // }

  ngAfterViewInit() {
    setTimeout(() => {
      this.getLaydownListData();
      this.getUserActivityInfo();
      this.getChitActivityData();
      this.getLayoutData();
      this.getStatusData();
      this.getGroupData();
    }, 1500)
  }
  /* * * * *
  * Init service data load
  * * * * * */
  ngOnInit() {
    // this.kisokOrderMapping();
    // this.coneUpdatedData = this.coneList;
    // this.fistAidJsonDataList = this.fistAidJsonData;
    // console.log('datafile', this.fistAidJsonDataList)
    this.currentTime = 12;
    // this.getLaydownListData();
    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src === "main") {
          if (data.event === 'updateshipdata') {
            var sortData = this.alphabetOrder(data['data']['currentShipList'], 'activity');
            // sortData = sortData.map(v=>({v,expand:false}))
            // console.log("sortData", sortData)
            sortData.forEach(element => {

              if (element.activity) {
                var activity = element.activity.split(" ").join("").toLowerCase();
                let activityConfig = this.activityServ.activityConfig;
                if (activityConfig[activity]) {
                  element['lineColor'] = activityConfig[activity].color
                } else {
                  element['lineColor'] = ''
                }
              } else {
                element['lineColor'] = ''
              }
              var startCDate: any = new Date(element.startdate)
              var endCDate: any = new Date(element.enddate)
              var delta = Math.abs(endCDate - startCDate) / 1000;
              var days = Math.floor(delta / 86400);
              delta -= days * 86400;
              var hours = Math.floor(delta / 3600) % 24;
              delta -= hours * 3600;
              element['days'] = days.toString() + "days" + " " + hours.toString() + "hours";
            });
            this.defRangeMetaData = sortData;
            this.shipMetaData = sortData;
            this.shipUpdatedData = sortData;
            this.shipParentMetaList = sortData;
            console.log("shipUpdatedData", this.shipUpdatedData);
            this.currentShipListInfo = this.shipUpdatedData
            this.filterDateRange(this.selectedDateRange);
            this.statusShiplist(this.shipMetaData);
            this.activityListSelect(this.shipMetaData);
            this.sortByShiplist(this.sortItem);
            // var ship = this.shipUpdatedData.filter(t => t.ship == 'YEL');
            // console.log("vessel name", ship)
            // this.shipUpdatedData = ship;


            if (this.shipData === "") {
              if (data['data']['shipData'].length > 0) {
                this.shipData = data['data']['shipData'][0];
              };
              this.getFilterKey(this.shipData);
            }
            this.mappingCategory();
            this.getActivityList();

          }
          if (data.src === "main") {
            if (data.event == "deleteGroupData") {
              this.deleteGroupData();
            }
            if (data.event == "deleteLayoutData") {
              this.deleteLayoutData();
              this.broadcastInfo({ src: 'sidepanel', event: 'layoutActive', data:  [], key: true });
            }
            if (data.event == "deleteNotesData") {
              this.deleteNotesData();
            }
            if (data.event == "deleteStatusData") {
              this.deleteStatusData();
            }
            if (data.event == 'navyUserInfo') {
              this.loginInfo = data.data;
              this.userRole = this.loginInfo['role']
            }
          }
          if (data.event === 'stop') {
            this.ssoAnimateList[0].animateStatus = 'stop';
          }
          if (data.event === 'showclickedship') {
            let index = this.shipUpdatedData.findIndex(el => el.name === data.key);
            if (index > -1) {
              let ship = this.shipUpdatedData[index];
              this.activeShip(index, ship, true);
            } else {
              let index = this.shipParentMetaList.findIndex(el => el.name === data.key);
              if (index > -1) {
                let ship = this.shipParentMetaList[index];
                this.activeShip(index, ship, true);
              }
            }
          }
          if (data.event === 'loadcurrentportdata') {
            if (data.key) {
              this.currentPort = data.key;
              this.loadCurrentPortShipList();
            }
          }
          if (data.event === 'kisokdata') {
            if (data['data']) {
              var _this = this;
              let kioskdata = JSON.parse(JSON.stringify(data['data']));
              _this.kioskFormatData = kioskdata;
              const kioskJson = JSON.parse(JSON.stringify(data['data']));
              // _this.cloneKioskArr = JSON.parse(JSON.stringify(data['data'].formatKioskJson));
              _this.kioskOrderList(kioskJson.kioskJson);

              // console.log("data['data']", kioskJson.formatKioskJson)
              // kioskJson.formatKioskJson.jettyList.forEach(jetty => {
              //   jetty.location1.forEach(loc => {
              //     loc.kiosk.forEach(loc1 => {
              //       loc1.servicetype.forEach(kiosk => {
              //           if (kiosk.service) {
              //             kiosk.service.forEach(element => {
              //               if (element.status) {
              //                 var colorData = this.statusColorCode.find(element => element.status == element.status);
              //                 element['status'] = colorData.color;
              //               }
              //             });
              //           }
              //       });
              //     });
              //   });
              // });
              _this.kisokElementArray = kioskJson.formatKioskJson;
              this.searchKioskData('jetty')
            }
          }

          // console.log("---------dfdsf-------------", data.event);
          if (data.event === 'detailPanel') {
            this.handleShipDetailToggleOpen = data.key
          }
          if (data.event === 'updateDateRange') {
            if (data['key']) {
              this.defaultDateRange = this.ctrlServe.selectedDateRange;
            } else {
              // this.defaultDateRange = this.ctrlServe.defaultDateRange;
              this.selectedDateRange = this.ctrlServe.defaultDateRange;
              this.todayFromDate = '';
              this.filterDateRange(this.defaultDateRange);
            }
          }
          if (data.event === 'activeShipDate') {
            this.activeShip(data.key, data['data']);
          }
          if (data.event === 'chitFormDetails') {
            this.shipConfig = data['data'];
            this.shipLocations = data['key'];
          }
        }
        if (data.src === "navyApp") {
          // if(data.event == "reconstruct"){
          //    this.broadcastInfo({ src: 'sidepanel', event: 'reconstruct', layout: this.reconstrucuLayoutData,notes:this.sidepanelGroupTagpoint });
          // }
          if(data.event == 'layoutData'){
            this.sidepanelLayoutData = data.data;
            this.tagData = data.createNotes;
            // this.reConstructTagJson();
          }
          if(data.event == 'updateStatus'){
            // this.sidepanelLayoutData = data.data
            this.sidepanelStatusData = data.data;
            this.sidepanelStatusObject = data.data;
            // this.reConstructTagJson()
          }
          if(data.event == 'updateGroup'){
            // this.sidepanelLayoutData = data.data
            this.sidepanelGroupData = data.data;
            this.sidepanelGroupObject = data.data;
            // this.reConstructTagJson()
          }
          if(data.event == 'layoutTagClose'){
            data.data['isChecked'] = false
            delete this.activeCard
            delete this.activeTag
            this.drawService.clearCutOuts();
            this.drawService.clearSections();
          }
        }
        if (data.src === "slider") {
          if (data.event === 'hidetag') {
            this.parentCatListIdx = null;
            delete this.activeShipList;
            this.handleShipDetailToggleOpen = false;
          }
          if (data.event === 'updatelist') {
            var sortData = this.alphabetOrder(data['data'], 'activity')
            // sortData = sortData.map(v=>({v,expand:false}))
            sortData.forEach(element => {
              if (element.activity) {
                var activity = element.activity.split(" ").join("").toLowerCase();
                let activityConfig = this.activityServ.activityConfig;
                if (activityConfig[activity]) {
                  element['lineColor'] = activityConfig[activity].color
                } else {
                  element['lineColor'] = ''
                }
              } else {
                element['lineColor'] = ''
              }
              var startCDate: any = new Date(element.startdate)
              var endCDate: any = new Date(element.enddate)
              var delta = Math.abs(endCDate - startCDate) / 1000;
              var days = Math.floor(delta / 86400);
              delta -= days * 86400;
              var hours = Math.floor(delta / 3600) % 24;
              delta -= hours * 3600;
              element['days'] = days.toString() + "days" + " " + hours.toString() + "hours";
            });
            this.shipUpdatedData = sortData;
            this.shipParentMetaList = sortData;
            this.onChangeUnknownLocation(this.unknownLocationState, true);
            this.statusShiplist(this.shipUpdatedData);
            this.activityListSelect(this.shipMetaData);
            this.sortByShiplist(this.sortItem);
            // var ship = this.shipUpdatedData.filter(t => t.ship == this.loginUserShip);
            // console.log("vessel name", ship)
            // this.shipUpdatedData = ship;
            if (this.search != '') {
              this.searchData();
            }
          }
          if (data.event === 'update_date_range') {
            this.defaultRangeDate = data['data'];
            if (this.defaultRangeDate) {
              this.startDate = this.defaultRangeDate['startDate'];
              this.endDate = this.defaultRangeDate['endDate'];
              var startCDate: any = new Date(this.startDate)
              var endCDate: any = new Date(this.endDate)
              var delta = Math.abs(endCDate - startCDate) / 1000;
              var days = Math.floor(delta / 86400);
              delta -= days * 86400;
              this.avtivityDays = (days + 1) + " day(s)";
              var startDate;
              var endDate;
              startDate = this.datePipe.transform(this.startDate, 'MMMM d, y')
              endDate = this.datePipe.transform(this.endDate, 'MMMM d, y')
              this.avtivityStartDate = startDate;
              this.avtivityEndDate = endDate;
              // this.getDateGroup(this.startDate, this.endDate);
            }
          }
          if (data.event === 'commandingShip') {
            this.commandingShip = data['data'];
            this.commandingShipDate = data['date'];
            this.getChitActivityData();
            // this.defaultRangeDate = data['data'];
          }
          if (data.event === 'changeDayRange') {
            if (data['key']) {
              // this.defaultDateRange = this.ctrlServe.selectedDateRange;
              // this.selectedDateRange = this.ctrlServe.defaultDateRange;
              this.todayFromDate = '';
              this.filterDateRange(this.selectedDateRange)
            }
          }
        }
        if (data.src === "tag") {
          if (data.event === 'activeLaydown') {
            if (data['data']) {
              const activeLaydown = data['data'];
              this.parentLaydownListIdx = activeLaydown;
            }
          }
          if (data.event === 'unSelectedFirstAid') {
            this.activeFirstAidIndex = null;
          }
          // if (data.event === 'updateDetailPanel') {
          //   this.handleShipDetailToggleOpen = data['data'];
          // }
          if (data.event == 'fmfRoofState') {
            this.toggleFMFState = true;
            data = data.data;
            // this.activeFirstAidIndex=data.key;
            if (this.activeFirstAidIndex !== data.key) {
              this.activeFirstAidIndex = data.key;
              this.broadcastInfo({ src: 'sidepanel', event: 'selectedFirstAid', data: data.data, key: "" });
            } else {
              this.activeFirstAidIndex = null;
              this.broadcastInfo({ src: 'sidepanel', event: 'selectedFirstAid', data: data.data, key: "" });
            }

          }
        }
        if (data.src === "sidepanel") {
          if (data.event === 'stepper') {
            this.chitWindowState = data.data;
          }
          if (data.event === 'schedule') {
            this.scheduleWindowState = data.data;
          }
           if (data.event === 'createCollectionTypeForm') {
            let e = data['data'];
            let item = data['key'];
            this.typeAction(e, item);
          }
          if (data.event === 'viewCollectionTypeForm') {
            let e = data['data'];
            let id = data['key'];
            let item = data['key1'];
            this.viewDataTypeDetails(e, id, item)
          }
        }
        if (data.src === "tag") {
          if (data.event === 'updateLaydown') {
            if (data['data']) {
              const updateLaydownTag = data['data'];
              console.log("data success", updateLaydownTag)
              if (updateLaydownTag === 'success') {
                setTimeout(() => this.getLaydownListData(), 1500);
              }
              // this.parentLaydownListIdx = activeLaydown;
            }
          }
          if (data.event === 'defaultColor') {
            if (data['data']) {
              this.selectedTagItem = data.key;
            }
          }
        }
        if (data.src === "chitStepper") {
          if (data.event === 'stepperActive') {
            this.parentCatListIdx = null;
          }
          if (data.event === 'chitDeleteshipStatus') {
            this.chitFormState = false;
          }
          if (data.event === 'updateChitData') {
            let obj = data['data'];
            if (obj == 'reload') {
              var data = data['key'];
              this.commandingShipDate = data.date // new Date(data.date);
              this.commandingShip = data.vessel;
              this.getChitActivityData();
            }
          }
          if (data.event === 'updateChitDataList') {
            let obj = data['data'];
            if (obj == 'reload') {
              // this.commandingShipDate = this.ctrlServe.animationDate;
              // this.commandingShip = data['key'];
              this.getChitActivityData();
            }
          }
          if (data.event === 'chitApprove') {
            let obj = data['data'];
            if (obj == 'reload') {
              this.getChitActivityData();
            }
          }
        }
        if(data.src=="material"){
          if (data.event == "layoutTagGroup") {
            let tagList = data.data;
            if (tagList) {
              let idx = this.sidepanelLayoutData.findIndex(e => e['_id'] == tagList['_id']);
              if (idx > -1) {
                this.sidepanelLayoutData[idx]['tagPoints'] = tagList['tagPoints'];
                // this.reConstructTagJson();
                this.sortByLayoutlist(this.sortlayoutItem);
                if (this.searchLayout) {
                  this.searchData();
                }
              }
            }
          }
          if (data.event === 'updateLayoutData') {
            let layoutData = data['data'];
            if (layoutData.length > 0) {
              this.sidepanelLayoutObject = layoutData;
              this.sidepanelLayoutData = layoutData;
            //  this.reConstructTagJson()
              // this.sortByLayoutlist(this.sortlayoutItem);
              if (this.searchLayout) {
                this.searchData();
              }
              // this.getFilterKey(listData);
            }
          } if(data.event == 'updateStatusData'){
             //this.reConstructTagJson()
          }
        }if(data.src == "dynamic-layout"){
          if(data.event=="deleteLayout"){
            let layout = data['data'];
            this.OnDeleteLayout(layout['index'],layout['id']);
          }
          if(data.event == "deleteNotesLayout"){
            this.deleteNotesConfirm(data['data']);
          }
          if(data.event == "statusChange"){
            let layout = data['data'];
            this.statusChange(layout.comments,layout.layoutId, layout.tagpoint,layout.statusName)
          }
          if(data.event=="activeSingleLayout"){
            let layout = data['data'];
            let idx =this.layoutJson.findIndex(e=> e['layoutId']==layout.layoutId);
            this.activeSingleLayout(layout.event, layout.notesIdx, layout.layoutId,idx,layout.tagPoint,layout.layout)
          }
          if(data.event=="activeSingleLayoutSocket"){
            this.tagServ.createMatTagObjectPoint(data.tagItem,this.sidepanelGroupTagpoint[this.activeCard])
          }
          if(data.event=="createCollectionFormActive"){
            let layout = data['data'];
            this.createCollectionFormActive(layout.type,layout.layout);
          }
          if(data.event=="layoutTag"){
            this.layoutTag(data['data']);
          }
          if(data.event=="typeAction"){
            let layout = data['data'];
            this.typeAction(layout.type,layout.layout);
          }
          if(data.event == "viewDataTypeDetails"){
            let layout = data['data'];
            this.viewDataTypeDetails(layout.type,layout.typeCollectionId,layout.layout);
          }
          if(data.event=="activeLayout"){
            let layout = data['data'];
            let idx =this.layoutJson.findIndex(e=> e['layoutId']==layout.layoutId);
            if(idx>-1){
              this.activeLayout(idx, layout.layoutId, layout.layout);
            }
          }
        }

      })
    var datas = this.fistAidJson
    // console.log(".....", datas);
    this.getDateGroup(this.startDate, this.endDate);
  }
  searchLayout:any = '';
  sidepanelLayoutObject:any=[];
  sidepanelLayoutData:any=[];
  sidepanelGroupTagpoint:any=[];
  layoutJson:any=[];
  groupJson:any=[];
  statusFilter:any=[];
  groupJsonData:any;
  layoutJsonData:any;
  filterLayoutJson:any=[];
  reconstrucuLayoutData:any=[];
  reconstrucuLayoutDataList:any=[];
  sortlayoutItem:any="layoutName";
  stateBoardView:boolean=false;
  reConstructTagJson(){
    if (this.sidepanelLayoutData.length != 0) {
      this.sidepanelGroupTagpoint = [];
      this.layoutJson = [];
      this.groupJson = [];
      this.statusFilter = [];
      this.sidepanelLayoutData.forEach((element, i) => {
        this.layoutJson.push({ 'layoutName': element.layoutName, 'layoutId': element._id, 'groupCollection': element.groupCollection, 'statusCollection': element.statusCollection, 'type': element.type, isChecked: true })
        element.tagPoints.forEach((tagpoint, idx) => {
          var tagpointIdx = element.Notes.findIndex(e => e.tagShapeId == tagpoint.tagShapeId)
          if(tagpointIdx > -1){
          element.Notes[tagpointIdx].startDate = this.datepipe.transform(element.Notes[tagpointIdx].startDate, "MMM d, y, h:mm a")
            tagpoint["tagComments"] =  element.Notes[tagpointIdx]
            tagpoint["layoutId"] = element._id;
            tagpoint["group"] = element.Notes[tagpointIdx].group;
            tagpoint["status"] = element.Notes[tagpointIdx].status;
            tagpoint["layoutName"] = element.layoutName;
          }

        this.groupJson.push({ "groupName":element.Notes[tagpointIdx].group.name, "groupColor": element.Notes[tagpointIdx].group.color, isChecked: true, 'layoutId': element.layoutId })
        this.statusFilter.push({ "statusName": element.Notes[tagpointIdx].status.name, "statusColor": element.Notes[tagpointIdx].status.value, isChecked: true, 'layoutId': element._id })
          tagpoint["tagComments"]['groupName'] = tagpoint['tagComments']['group']['name'];
          tagpoint["tagComments"]['layoutName'] = element.layoutName;
          tagpoint["tagComments"]['statusName'] = tagpoint['tagComments']['status']['name'];
        this.groupJson.push({ "groupName": tagpoint.groupName, "groupColor": tagpoint.groupColor, isChecked: true, 'layoutId': element.layoutId })
        this.statusFilter.push({ "statusName": tagpoint['tagComments']['status']['statusName'], "statusColor": tagpoint['tagComments']['status']['statusColor'], isChecked: true, 'layoutId': element._id })
        this.sidepanelGroupTagpoint.push(tagpoint);
        })
      });
      console.log("this.sidepanelGroupTagpoint", this.sidepanelGroupTagpoint)
      this.reconstrucuLayoutDataList = this.sidepanelGroupTagpoint;
      this.reconstrucuLayoutData = this.sidepanelGroupTagpoint;
      this.filterLayoutJson = this.layoutJson;
      this.layoutJsonData = this.layoutJson;
      if(this.activeLayoutIndex> -1){
        var selectedLayout =  this.sidepanelLayoutData[this.activeLayoutIndex]?this.sidepanelLayoutData[this.activeLayoutIndex]:[];
      } else {
        selectedLayout =[]
      }
      console.log("reConstructTagJson-",this.sidepanelLayoutData,this.sidepanelGroupTagpoint)
      this.broadcastInfo({ src: 'sidepanel', event: 'layoutActive', data:  selectedLayout, tagShow : this.sidepanelGroupTagpoint, key: true });
      this.broadcastInfo({ src: 'sidepanel', event: 'reconstruct', layout: this.layoutJson,notes:this.sidepanelGroupTagpoint });
      const ids = this.groupJson.map(o => o.groupName);
      console.log("this.groupJson", this.groupJson)
      this.groupJson = this.groupJson.filter(({ groupName }, index) => !ids.includes(groupName, index + 1));
      this.groupJsonData = this.groupJson
      const statusids = this.statusFilter.map(o => o.statusName);
      this.statusFilter = this.statusFilter.filter(({ statusName }, index) => !statusids.includes(statusName, index + 1));
      if(this.tagData){
        if(this.tagServ.showHideTagPointer.length != 0){
          this.tagServ.showHideTagPointer.forEach(ele => {
            this.sidepanelLayoutData[this.activeLayoutIndex].tagPoints.forEach(element => {
              if( ele == "layout_" + element.tagShapeId){
                element['isChecked'] = false
                this.tagServ.showHideTag(element,false)
              }
            });
          })
        }
        this.tagData['isChecked'] = true;
        this.tagServ.createMatTagObjectPoint(this.tagData)
        this.tagServ.matTagClear()
        this.activeCard = this.sidepanelGroupTagpoint.findIndex(tag => tag.tagShapeId == this.tagData.tagShapeId)
        this.activeTag =this.tagData.tagShapeId
      }
    } else {
      this.sidepanelGroupTagpoint = [];
      this.layoutJson = [];
      this.groupJson = [];
    }

  }
  getDate(date) {
    return this.datepipe.transform(date, "MMM d, y, h:mm a")
  }
  sortByLayoutlist(e) {
    this.sortlayoutItem = e;
    let direction = 1;
    this.sidepanelGroupTagpoint.sort((a, b) => {
      if (a[e] < b[e]) {
        return -1 * direction;
      }
      else if (a[e] > b[e]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  }

  statusChange(data, layOut, tagpoint, status) {
    var statusId = this.sidepanelStatusData.filter(obj => obj.name == status)
    var statusIdData = statusId[0]._id;
    // this.broadcastInfo({ src: 'sidepanel', event: 'update-Status', data: statusId, });
    var layoutIDx = this.sidepanelLayoutData.findIndex(obj => obj._id == layOut)
    layOut = this.sidepanelLayoutData[layoutIDx]
    console.log('layout', layOut)
    var statusIDX = this.sidepanelStatusData.findIndex(obj => obj.name == status)
    var selectTag = layOut.tagPoints.filter(obj => obj.id == tagpoint.id)
    data.status = this.sidepanelStatusData[statusIDX]
    data.statusId = statusIdData;
    this.broadcastInfo({ src: 'comment', event: 'update-comment', data: data, "layOut": layOut, "selectTag": selectTag[0] });
  }
  getUserActivityInfo() {
    this.configService.getProjectConfig().then((project_config: any) => {
    this.chitService.getOodData().subscribe(response => {
      // console.log('data getChitActivityData', response);
      // console.log("userActivityInfo", response);
      var shipLocationMatch = response.filter(t => t.ood_email == this.profileInfo.email);
      // this.userActivityInfo = response;
      // this.loginUserShip = shipLocationMatch[0].vessel;
      // this.loginUserShip = shipLocationMatch[0].vessel;
      // setTimeout(() => {
      //   var ship = this.shipUpdatedData.filter(t => t.ship == this.loginUserShip);
      //   console.log("vessel name", ship)
      // }, 1500);
    })
  })
  }

  onTabChange(e) {
    if (e['index'] === 1) {
      if (!this.activeShipList) return;
      this.commandingShip = this.activeShipList['ship'];
      this.commandingShipDate = this.ctrlServe.animationDate;
      this.getChitActivityData();
    }
  }

  getChitActivityData() {
    this.configService.getProjectConfig().then((project_config: any) => {
    this.chitService.getChitActivityDataList().subscribe(response => {
      // console.log('data getChitActivityData---', response);
      var shipDateMatch = response.filter(t => new Date(t.Date).getTime() === new Date(this.commandingShipDate).getTime());
      var shipStatus = [];
      // for (let index = 0; index < shipDateMatch.length; index++) {
      for (const key in shipDateMatch) {
        const element = shipDateMatch[key];
        for (const [key, value] of Object.entries(element)) {
          // console.log("shipStatus-- key ", key, typeof(element[key]));
          if (key == "notification" && element[key] !== null && element[key] !== 'null') {
            var dataparse = JSON.parse(element[key]);
            element[key] = dataparse;
            var inprogress = 0;
            var accept = 0;
            var denied = 0;
            for (let index = 0; index < dataparse.length; index++) {
              if (dataparse[index].status == 'inprogress') {
                inprogress++;
              }
              if (dataparse[index].status == 'yes') {
                accept++;
              }
              if (dataparse[index].status == 'no') {
                denied++;
              }
            }
            if (inprogress > 0) {
              element['inprogress'] = true
            } else if (denied > 0 && accept <= 0) {
              element['denied'] = true
            } else {
              element['accept'] = true
            }
            // console.log("Data-----", inprogress, ' - ', accept , ' - ',denied)
          }
        }
        shipStatus.push(element);
      }
      // }

      console.log("shipStatus--", shipStatus)
      // var shipData = shipDateMatch.filter(t => t.Vessel == this.loginUserShip);
      if (this.commandingShip === 'no-ship') {
        this.chitDataList = []
      } else if (this.commandingShip) {
        var shipData = shipStatus.filter(t => t.Vessel == this.commandingShip);
        shipData.forEach(element => {
          var startDate = this.datePipe.transform(element.Date, 'dd-MMM-yy') + ' ,' + element.Start;
          var endDate = this.datePipe.transform(element.Date, 'dd-MMM-yy') + ' ,' + element.End;
          var startCDate: any = new Date(startDate)
          var endCDate: any = new Date(endDate)
          var delta = Math.abs(endCDate - startCDate) / 1000;
          var days = Math.floor(delta / 86400);
          delta -= days * 86400;
          var hours = Math.floor(delta / 3600) % 24;
          delta -= hours * 3600;
          element['Date'] = this.datePipe.transform(element.Date, 'dd-MMM-yy')
          element['days'] = days.toString() + "days" + " " + hours.toString() + "hours";
          // responseData.push()
        });
        this.chitDataList = shipData;
        console.log("Chit data details", this.chitDataList)
      } else {
        shipStatus.forEach(element => {
          var startDate = this.datePipe.transform(element.Date, 'dd-MMM-yy') + ' ,' + element.Start;
          var endDate = this.datePipe.transform(element.Date, 'dd-MMM-yy') + ' ,' + element.End;
          var startCDate: any = new Date(startDate)
          var endCDate: any = new Date(endDate)
          var delta = Math.abs(endCDate - startCDate) / 1000;
          var days = Math.floor(delta / 86400);
          delta -= days * 86400;
          var hours = Math.floor(delta / 3600) % 24;
          delta -= hours * 3600;
          element['Date'] = this.datePipe.transform(element.Date, 'dd-MMM-yy')
          element['days'] = days.toString() + "days" + " " + hours.toString() + "hours";
          // responseData.push()
        });
        this.chitDataList = shipStatus;
        console.log("Chit data details", this.chitDataList)
      }
    })
  })
  }

  kioskOpen(props) {
    this.broadcastInfo({ src: 'main', event: 'showKioskId', data: props.uid, state: null, sub: null });
    // this.tagServ.showKioskTag(props.uid, true)
  }
  getColor(status) {
    if (status) {
      var colorData = this.statusColorCode.find(element => element.status == status);
      return colorData.color;
    }
  }
  /* * * * *
  * method for current activity list
  * * * * * */
  getActivityList() {
    this.currentActivityList = [];
    this.currentActivityListState = {};
    if (this.shipMetaData.length > 0) {
      var data = {};
      this.shipMetaData.forEach(ship => {
        if (ship['ship_status'] === 'show') {
          let activity = ship['activity'].split(" ").join("").toLowerCase();
          const found = this.currentActivityList.some(el => el === activity);
          if (!found) {
            if (activity != '') {
              // console.log(activity)
              this.currentActivityList.push(activity);
              data[activity] = true;
            }
          }
        }
      });
      this.activitySelectedList = this.currentActivityList;
      this.currentActivityListState = data;
      // console.log(this.currentActivityList)
    }
  }

  /* * * * *
  * method for if activity show or disabled
  * * * * * */
  isDisabledActivity(activity) {
    const found = this.currentActivityList.some(el => el === activity);
    // const found = this.currentActivityList[activity];
    return !found;
  }

  /* * * * *
  * filterkeys
  * * * * * */
  getFilterKey(shipData) {
    this.filterObjectKeys = [];
    this.filterObjectKeys = Object.keys(shipData);
    this.filterKey = this.filterObjectKeys;
  }

  /* * * * *
  * Class mapping ship meta data
  * * * * * */
  mappingCategory() {
    this.shipClassList = [];
    if (this.shipUpdatedData.length > 0) {
      this.shipUpdatedData.forEach(element => {
        if (this.shipClassList.indexOf(element.class) == -1) {
          this.shipClassList.push(element.class);
        }
      });
    }
  }

  shipExtentIdx: any;
  shipExtent(i) {
    if (this.shipExtentIdx == undefined) {
      this.shipExtentIdx = i;
    } else {
      if (this.shipExtentIdx == i) {
        delete this.shipExtentIdx
      } else {
        this.shipExtentIdx = i;
      }
    }
  }

  coneExtentIdx: any;
  coneExtent(i) {
    if (this.coneExtentIdx == undefined) {
      this.coneExtentIdx = i;
    } else {
      if (this.coneExtentIdx == i) {
        delete this.coneExtentIdx
      } else {
        this.coneExtentIdx = i;
      }
    }
  }
  ToggleToolOpenHanddle() {
    this.handleToolsToggleOpen = !this.handleToolsToggleOpen;
  }
  ToggleLayoutOpenHanddle() {
    this.handleLayoutToggleOpen = !this.handleLayoutToggleOpen;
  }

  ToggleOpenHanddle() {
    this.shipHandleToggleOpen = !this.shipHandleToggleOpen;
    if (!this.shipHandleToggleOpen) {
      if (window.innerWidth > 541) {
        this.filterHandleToggleOpen = false;
        this.serviceHandleToggleOpen = false;
        this.filterLaydownHandleToggleOpen = false;
      }
    }
    if (!this.shipHandleToggleOpen) {
      var elem = document.getElementsByClassName('side_panel');
      elem[0].classList.remove('open');
    } else {
      setTimeout(() => {
        this.changeFontColor({ 'target': { 'value': this.fontColor } })
      }, 300);
    }

  }
  ToggleLaydownOpenHanddle() {
    this.laydownHandleToggleOpen = !this.laydownHandleToggleOpen;
    if (!this.laydownHandleToggleOpen) {
      if (window.innerWidth > 541) {
        this.filterHandleToggleOpen = false;
        this.serviceHandleToggleOpen = false;
        this.filterLaydownHandleToggleOpen = false;
      }
    }
    if (!this.laydownHandleToggleOpen) {
      var elem = document.getElementsByClassName('side_panel');
      elem[0].classList.remove('open');
    } else {
      setTimeout(() => {
        this.changeFontColor({ 'target': { 'value': this.fontColor } })
      }, 300);
    }


  }
  FirstAidOpenHanddle() {
    this.firstAidHandleToggleOpen = !this.firstAidHandleToggleOpen;
    if (!this.firstAidHandleToggleOpen) {
      if (window.innerWidth > 541) {
        this.filterHandleToggleOpen = false;
        this.serviceHandleToggleOpen = false;
      }
    }
    if (!this.firstAidHandleToggleOpen) {
      var elem = document.getElementsByClassName('side_panel');
      elem[0].classList.remove('open');
    } else {
      setTimeout(() => {
        this.changeFontColor({ 'target': { 'value': this.fontColor } })
      }, 300);
    }
  }

  ToggleWFMOpenHanddle(e) {
    this.selectedIndex = 0;
    if (e == 'FMF') {
      this.WFMHandleToggleOpen = false;
      this.FMFHandleToggleOpen = !this.FMFHandleToggleOpen;
      this.activeDetailSection = 'hazard';
    }
    if (e == 'WFM') {
      this.FMFHandleToggleOpen = false;
      this.WFMHandleToggleOpen = !this.WFMHandleToggleOpen;
      this.activeDetailSection = 'hazard';
    }


  }
  ToggleCommsOpenHanddle() {
    this.broadcastInfo({ src: 'sidepanel', event: 'comms', data: null, key: "" });
  }
  getServicelist(key) {
    if (this.loadedPort == 'cfbhalifax') {
      var data = this.WFMHandleToggleOpen ? this.wfmHALServiceList : this.FMFHandleToggleOpen ? this.fmfHALServiceList : '';
    }
    if (this.loadedPort == 'cfbesquimalt') {
      var data = this.WFMHandleToggleOpen ? this.wfmESQServiceList : this.FMFHandleToggleOpen ? this.fmfESQServiceList : '';
    }

    if (data != '') {
      let idx = data.findIndex(e => e.key == key)
      if (idx > -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false
    }
  }
  ToggleServiceOpenHanddle() {
    this.servicesHandleToggleOpen = !this.servicesHandleToggleOpen;
    if (!this.servicesHandleToggleOpen) {
      if (window.innerWidth > 541) {
        this.filterHandleToggleOpen = false;
        this.serviceHandleToggleOpen = false;
        this.filterLaydownHandleToggleOpen = false;
      }
    }
    if (!this.servicesHandleToggleOpen) {
      var elem = document.getElementsByClassName('side_panel');
      elem[0].classList.remove('open');
    } else {
      setTimeout(() => {
        this.changeFontColor({ 'target': { 'value': this.fontColor } })
      }, 300);
    }
  }
  ToggleConeOpenHanddle() {
    this.coneHandleToggleOpen = !this.coneHandleToggleOpen;
    if (!this.coneHandleToggleOpen) {
      if (window.innerWidth > 541) {
        this.filterHandleToggleOpen = false;
        this.serviceHandleToggleOpen = false;
        this.filterLaydownHandleToggleOpen = false;
      }
    }
    if (!this.coneHandleToggleOpen) {
      var elem = document.getElementsByClassName('side_panel');
      elem[0].classList.remove('open');
    } else {
      setTimeout(() => {
        this.changeFontColor({ 'target': { 'value': this.fontColor } })
      }, 300);
    }
  }
  ToggleReportOpenHanddle() {
    this.reportHandleToggleOpen = !this.reportHandleToggleOpen;
  }
  ToggleLibOpenHanddle() {
    this.libHandleToggleOpen = !this.libHandleToggleOpen;
  }
  ToggleInsOpenHanddle() {
    this.insHandleToggleOpenHandleToggleOpen = !this.insHandleToggleOpenHandleToggleOpen;
  }
  ToggleInsOpenGroupHanddle() {
    this.shipGroupHandleToggleOpen = !this.shipGroupHandleToggleOpen;
  }
  ToggleInsOpenStatusHanddle() {
    this.shipStatusHandleToggleOpen = !this.shipStatusHandleToggleOpen;
  }

  rhHandleToggleOpen;
  rh2HandleToggleOpen;
  ToggleRHOpenHanddle() {
    this.rhHandleToggleOpen = !this.rhHandleToggleOpen;
  }
  ToggleRH2OpenHanddle() {
    this.rh2HandleToggleOpen = !this.rh2HandleToggleOpen;
  }

  /* * * * *
  * Search Filter data
  * * * * * */
  searchData() {
    // var filterData = [];
    // if (this.search === '') {
    //   filterData = null;
    //   this.shipUpdatedData = this.shipMetaData;
    //   this.statusShiplist(this.shipMetaData);
    //   this.activityListSelect(this.shipMetaData);
    // } else {
    //   if (this.shipMetaData.length > 0) {
    //     for (let i = 0; i < this.shipMetaData.length; i++) {
    //       if (this.filterKey.length > 0) {
    //         this.filterKey.forEach((key) => {
    //           if (typeof this.shipMetaData[i][key] === 'string' && typeof this.search === 'string') {
    //             if (this.shipMetaData[i][key].toLowerCase().indexOf(this.search.toLowerCase()) > -1) {
    //               const found = filterData.some(el => el.name === this.shipMetaData[i].name);
    //               if (!found) {
    //                 filterData.push(this.shipMetaData[i]);
    //               }
    //             }
    //           }
    //         });
    //       } else {
    //         if (this.shipMetaData[i].ship.toLowerCase().indexOf(this.search.toLowerCase()) > -1) {
    //           filterData.push(this.shipMetaData[i]);
    //         }
    //       }
    //     }
    //   }
    //   this.shipUpdatedData = filterData;
    //   this.statusShiplist(filterData);
    //   this.activityListSelect(filterData);
    // }
    // this.getDateGroup(this.startDate, this.endDate);
    // this.mappingCategory();
    // this.showFilterships.emit(filterData);
    // this.sortByShiplist(this.sortItem);
      var layoutJson = [];
      var filterData = [];
      if (this.searchLayout === '') {
        filterData = this.reconstrucuLayoutData;
        this.sidepanelGroupTagpoint = this.reconstrucuLayoutData;
        this.searchLayoutClear();
      }
      else {
        if (this.reconstrucuLayoutData.length > 0) {
          for (let i = 0; i < this.reconstrucuLayoutData.length; i++) {
            if (this.filterLayoutKey.length > 0) {
              this.filterLayoutKey.forEach((key) => {
                if (typeof this.reconstrucuLayoutData[i]["tagComments"][key] === 'string' && typeof this.searchLayout === 'string') {
                  if (this.reconstrucuLayoutData[i]["tagComments"][key].toLowerCase().indexOf(this.searchLayout.toLowerCase()) > -1) {
                    const found = filterData.some(el => el['_id'] === this.reconstrucuLayoutData[i]['_id']);
                    if (!found) {
                      filterData.push(this.reconstrucuLayoutData[i]);
                    }
                  }
                }
              });
            } else {
              if (this.reconstrucuLayoutData[i].layoutName.toLowerCase().indexOf(this.searchLayout.toLowerCase()) > -1) {
              filterData.push(this.reconstrucuLayoutData[i]);
              }
            }
          }
        }
        layoutJson = []
        filterData.forEach(ele => {
          layoutJson.push({ 'layoutName': ele["tagComments"]['layoutName'], 'layoutId': ele.layoutId, isChecked: true });
        });
        var data = []
        layoutJson.forEach(el => {
          this.layoutJsonData.forEach(element => {
            if (el.layoutName == element.layoutName) {
              data.push(el);
            }
          });
        });
        // this.filterdataComponentapply(filterData)
        data = data.reduce((a, b) => {
          if (!a.find(t => t.layoutId === b.layoutId)) {
            a.push(b);
          }
          return a;
        }, []);
        console.log("filterData", filterData)
        this.layoutJson = data
        // console.log("layoutJsondd", layoutJson, this.layoutJson, "test",data)
        // this.reConstructTagJson()
        // this.mappingCategory();
        this.sortByLayoutlist(this.sortlayoutItem);

      }

      // console.log("layoutJsondd", this.layoutJson)

      this.sidepanelGroupTagpoint = filterData;


  }

  /* * * * *
  * clear search
  * * * * * */
  searchClear() {
    this.search = "";
    this.shipUpdatedData = this.shipMetaData;
    this.statusShiplist(this.shipMetaData);
    this.activityListSelect(this.shipMetaData);
    this.sortByShiplist(this.sortItem);
    let filterdData = null;
    this.mappingCategory();
    this.showFilterships.emit(filterdData);
  }

  /* * * * *
  * method for disable dates
  * * * * * */
  myDateFilter = (d: Date | null): boolean => {
    if (this.defaultRangeDate) {
      const day = (d || new Date());
      const startDate = this.defaultRangeDate['startDate'];
      const endDate = this.defaultRangeDate['endDate'];
      return (startDate <= day && endDate >= day);
    }
  }

  /* * * * *
  * load ship current port
  * * * * * */
  loadCurrentPortShipList() {
    if (this.currentPort === 'cfbhalifax') {
      this.shipUpdatedData = this.defRangeMetaData;
      this.shipMetaData = this.shipUpdatedData;
      this.activityListSelect(this.shipUpdatedData);
      this.sortByShiplist(this.sortItem);
      this.broadcastInfo({ src: 'sidepanel', event: 'filtered_data', data: null, key: "clear_filter" });
    }
    else if (this.currentPort === 'cfad') {
      this.shipUpdatedData = this.defRangeMetaData.filter(ship => ship.location.replace(" ", "").replace("/", "_") === 'NN');
      this.shipMetaData = this.shipUpdatedData;
      this.activityListSelect(this.shipUpdatedData);
      this.sortByShiplist(this.sortItem);
      this.broadcastInfo({ src: 'sidepanel', event: 'filtered_data', data: { data: this.shipUpdatedData }, key: "" });
    }
    else if (this.currentPort === 'shearwater') {
      this.shipUpdatedData = this.defRangeMetaData.filter(ship => ship.location.replace(" ", "").replace("/", "_") === 'NA');
      this.shipMetaData = this.shipUpdatedData;
      this.activityListSelect(this.shipUpdatedData);
      this.sortByShiplist(this.sortItem);
      this.broadcastInfo({ src: 'sidepanel', event: 'filtered_data', data: { data: this.shipUpdatedData }, key: "" });
    }
    this.broadcastInfo({ src: 'main', event: 'update_time_view', data: '', key: "" });
    if (this.search != '') {
      this.searchData();
    }
  }
  dateGroupList: any = [];
  getDateGroup(startDate, endDate) {
    if (startDate && endDate) {
      var res = [];
      if (this.selectedGroupItem === 'date') {
        res = this.getDateRange(startDate, endDate);
        this.addObjData(res);
      } else {
        res = this.getGroupItems(this.selectedGroupItem);
        this.addObjData(res);
      }
    }
  }
  getGroupItems(key) {
    let objGroup = [];
    objGroup = this.shipUpdatedData.map(el => el[key]);
    objGroup = objGroup.filter((value, i, arr) => arr.indexOf(value) === i);
    return objGroup;
  }
  getDateRange(startDate, endDate) {
    if (startDate && endDate) {
      var startCDate = new Date(startDate)
      var endCDate = new Date(endDate);
      for (var a: any = startCDate; startCDate <= endCDate; startCDate.setDate(startCDate.getDate() + 1)) {
        a = a + ',' + startCDate;
      }
      a = a.split(',');
      a.splice(0, 1);
    }
    return a;
  }
  addObjData(a) {
    this.dateGroupList = [];
    this.dateGroupList = a.map(val => ({ groupName: this.selectedGroupItem, groupValue: this.datePipe.transform(val, 'MMMM d, y'), viewStatus: false, expand: true }));
    // this.datePipe.transform(val, 'MMMM d, y')
  }
  checkDateGroup(act, groupItem, i) {
    var startDate = new Date(act.startdate);
    var endDate = new Date(act.enddate);
    var status = false;
    var dateValue = new Date(act.startdate);

    if (groupItem['groupName'] === 'date') {
      dateValue = new Date(groupItem.groupValue);
    } else {
      dateValue = endDate;
    }

    // if (endDate >= dateValue && startDate <= dateValue) {
    //   this.dateGroupList[i].viewStatus = true;
    //   status = date.expand ? true : false;
    // }

    if (this.ctrlServe.currentActivityDate) {
      if (new Date(this.ctrlServe.currentActivityDate).getTime() <= new Date(dateValue).getTime()) {
        if (groupItem['groupName'] === 'date') {
          if (endDate >= dateValue && startDate <= dateValue) {
            this.dateGroupList[i].viewStatus = true;
            status = groupItem.expand ? true : false;
          }
        } else {
          if (act[groupItem['groupName']].split(" ").join("") === groupItem['groupValue'].split(" ").join("")) {
            if (endDate >= dateValue && startDate <= dateValue) {
              this.dateGroupList[i].viewStatus = true;
              status = groupItem.expand ? true : false;
            }
          }
        }
      } else {
        this.dateGroupList[i].viewStatus = false;
      }
    }
    return status;
  }

  onChangeGroupItem(event, key) {
    this.selectedGroupItem = key;
    this.getDateGroup(this.startDate, this.endDate);
  }
  onChangeCollectionGroupItem(event, key) {
    this.selectedGroupItem = key;
    var res = [];
    res = this.getGroupDataList(this.selectedGroupItem);
    this.addObjDatas(res);
  }
  addObjDatas(a) {
    this.layoutJson = [];
    this.layoutJson = a.map(val => ({ collectionItem: this.selectedGroupItem, layoutName: val, viewStatus: true, expand: true }));
    console.log("this.dateGroupList", this.layoutJson)
    setTimeout(() => {
      delete this.activeLayoutId;
    }, 500);
  }
  getGroupDataList(key) {
    let objGroup = [];
    let objGroupData = [];
    objGroup = this.sidepanelGroupTagpoint.map(el => el[key]);
    if (key != 'layoutName') {
      objGroup.forEach((element, index) => {
        objGroupData.push(element.name)
      });
    }else{
      objGroupData = objGroup;
    }
    objGroup = objGroupData.filter((value, i, arr) => arr.indexOf(value) === i);
    return objGroup;
  }
  checkLayoutGroup(act, groupItem, i) {
    var startDate = new Date(act.startdate);
    var endDate = new Date(act.enddate);
    var status = false;
    var dateValue = new Date(act.startdate);

    // if (groupItem['groupName'] === 'date') {
    //   dateValue = new Date(groupItem.groupValue);
    // } else {
    //   dateValue = endDate;
    // }
    // if (this.currentActivityDate) {
      // if (new Date(this.currentActivityDate).getTime() <= new Date(dateValue).getTime()) {
        // if (groupItem['groupName'] === 'date') {
        //   if (endDate >= dateValue && startDate <= dateValue) {
        //     this.dateGroupList[i].viewStatus = true;
        //     status = groupItem.expand ? true : false;
        //   }
        // } else {

        // }
        if (act[groupItem['groupName']].split(" ").join("") === groupItem['groupValue'].split(" ").join("")) {
          if (endDate >= dateValue && startDate <= dateValue) {
            this.dateGroupList[i].viewStatus = true;
            status = groupItem.expand ? true : false;
          }
        }
      // } else {
      //   this.dateGroupList[i].viewStatus = true;
      // }
    // }

    return status;
  }

  sortByCollectionlist(e) {
    this.sortItem = e;
    let direction = 1;
    this.sidepanelLayoutData.sort((a, b) => {
      if (e == 'startdate' || e == 'enddate') {
        let aDate = new Date(a[e]);
        let bDate = new Date(b[e])
        if (aDate < bDate) {
          return -1 * direction;
        }
        else if (aDate > bDate) {
          return 1 * direction;
        }
        else {
          return 0;
        }
      } else {
        if (a[e] < b[e]) {
          return -1 * direction;
        }
        else if (a[e] > b[e]) {
          return 1 * direction;
        }
        else {
          return 0;
        }
      }
    });

    if (this.activeShipList) {
      let idx = this.sidepanelLayoutData.findIndex(e => e.id == this.activeShipList.id)
      if (idx > -1) {
        this.parentCatListIdx = idx;
      }
    }
  }

  /* * * * *
  * clear date range
  * * * * * */
  clearDateRange() {
    if (this.defaultRangeDate) {
      this.selectedDateRange = 27;
      this.ctrlServe.selectedDateRange = 27;
      this.startDate = this.defaultRangeDate['startDate'];
      this.endDate = this.defaultRangeDate['endDate'];
      this.getDateGroup(this.startDate, this.endDate);
      this.shipUpdatedData = this.defRangeMetaData;
      this.shipMetaData = this.shipUpdatedData;
      this.activityListSelect(this.shipUpdatedData);
      this.sortByShiplist(this.sortItem);
      this.broadcastInfo({ src: 'sidepanel', event: 'filtered_data', data: null, key: "clear_filter" });
      if (this.search != '') {
        this.searchData();
      }
    }
  }

  /* * * * *
  * get date for calender
  * * * * * */
  setRangeDate(range) {
    let date = new Date(this.startDate);
    date.setDate(this.startDate.getDate() + range);
    this.endDate = date;
    this.getDateGroup(this.startDate, this.endDate);
  }

  /* * * * *
  * change date range
  * * * * * */
  onChangeDateRange(event, range) {
    if (!event.checked) {
      event.source._checked = true;
    } else {
      this.filterDateRange(range);
    }
  }

  filterDateRange(range) {
    range = Number(range);
    if (this.defaultDateRange >= range) {
      this.processDateRange(range);
    } else {
      console.log(" out of range !!");
      this.selectedDateRange = range;
      this.ctrlServe.selectedDateRange = range;
      this.defaultDateRange = range
      this.ctrlServe.defaultDateRange = range;
      this.broadcastInfo({ src: 'main', event: 'changeDateRange', data: range, key: "" });
      this.processDateRange(range);
      // this.openPublishDialog.emit('daterange-confirm-box');
    }
  }

  /* * * * *
  * process date range
  * * * * * */
  processDateRange(range) {
    this.selectedDateRange = range;
    range = Number(range);
    let filterData = [];
    let startDate = this.startDate;
    range = Number(range);//((0 < range) ? range - 1 : range);
    this.setRangeDate(range);

    for (let i = 0; i < range + 1; i++) {
      let shipDate = moment(startDate).add(i, 'days');
      this.defRangeMetaData.forEach(ship => {
        if (ship['ship_status'] === 'show') {
          if (shipDate['_d'] >= moment(ship.startdate)['_d'] && shipDate['_d'] <= moment(ship.enddate)['_d']) {
            const found = filterData.some(el => el.name === ship.name);
            if (!found) {
              filterData.push(ship);
            }
          }
        }
      });
    }
    this.shipUpdatedData = filterData;
    this.shipMetaData = filterData;
    this.activityListSelect(filterData);
    this.sortByShiplist(this.sortItem);
    let obj = { "startDate": startDate, data: filterData };
    this.broadcastInfo({ src: 'sidepanel', event: 'filtered_data', data: obj, key: "" });
    if (this.search != '') {
      this.searchData();
    }
    this.getDateGroup(this.startDate, this.endDate);
  }
  todayFromDate: any;
  onChangeDateToday(e) {
    console.log("onChangeDateToday", this.todayFromDate);
    // this.todayFromDate = e.target.value.replace(/[^0-9\.]+/g, '');
    if (this.todayFromDate != null) {
      let range = Number(this.todayFromDate);
      if (this.ctrlServe.defaultDateRange >= range) {
        this.selectedDateRange = range;
        this.ctrlServe.selectedDateRange = range;
        this.broadcastInfo({ src: 'sidepanel', event: 'updateDateRange', data: range, key: "" });
        this.todayFromDate = null;
      } else {
        this.todayFromDate = null;
        // this.processDateRange(this.ctrlServe.defaultDateRange);
        // this.selectedDateRange = this.ctrlServe.defaultDateRange;
        // this.ctrlServe.selectedDateRange = this.ctrlServe.defaultDateRange;
        // this.broadcastInfo({ src: 'sidepanel', event: 'updateDateRange', data: this.ctrlServe.defaultDateRange, key: "" });
        this.openPublishDialog.emit('Given range is out of the date range, Please give within the date range !!');
      }
    }
  }
  /* * * * *
  * filter data based on date range
  * * * * * */
  // dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
  dateRangeChange(dateRangeStart: any, dateRangeEnd: any) {
    delete this.todayFromDate
    let filterData = [];
    if (dateRangeEnd.value) {
      let startDate = dateRangeStart.value;
      let endDate = dateRangeEnd.value;
      var diffInMs = new Date(endDate).getTime() - new Date(startDate).getTime();
      let range = diffInMs / (1000 * 3600 * 24);
      this.setRangeDate(range);
      this.selectedDateRange = null;

      for (let i = 0; i < range + 1; i++) {
        let shipDate = moment(startDate).add(i, 'days');
        this.defRangeMetaData.forEach(ship => {
          if (ship['ship_status'] === 'show') {
            if (shipDate['_d'] >= moment(ship.startdate)['_d'] && shipDate['_d'] <= moment(ship.enddate)['_d']) {
              const found = filterData.some(el => el.name === ship.name);
              if (!found) {
                filterData.push(ship);
              }
            }
          }
        });
      }
      this.shipUpdatedData = filterData;
      this.shipMetaData = filterData;
      this.activityListSelect(filterData);
      this.sortByShiplist(this.sortItem);
      let obj = { "startDate": startDate, data: filterData };
      this.broadcastInfo({ src: 'sidepanel', event: 'filtered_data', data: obj, key: "" });
      if (this.search != '') {
        this.searchData();
      }
      this.getDateGroup(this.startDate, this.endDate);
    }
  }

  /* * * * *
  * clear selected list
  * * * * * */
  clearSelectionList() {
    this.parentCatListIdx = null;
    delete this.activeShipList;
    this.handleShipDetailToggleOpen = false;
    // this.broadcastInfo({ src: 'sidepanel', event: 'selectedship', data: null, key: "" });
  }

  /* * * * *
  * Active ship
  * * * * * */
  checkActiveShip(event, i, ship) {
    if (event.target.classList.contains('info_icon') || event.target.classList.contains('chit_icon') || event.target.classList.contains('toc_icon')) {
      return
    }
    if (window.innerWidth < 541) {
      this.ToggleOpenHanddle()
      this.ToggleServiceOpenHanddle()
      this.ToggleLaydownOpenHanddle()
    }
    this.activeShip(i, ship);
  }

  activeShip(i, ship, moveTimer = false) {
    let willLoadExternalJetty = false;
    if (this.parentCatListIdx === i) {
      this.parentCatListIdx = null;
      delete this.activeShipList;
      this.handleShipDetailToggleOpen = false;
      this.selectedShip.emit(null);
      this.broadcastInfo({ src: 'sidepanel', event: 'selectedship', data: null, key: "" });
    } else {
      let allow;
      // checking if ship current jetty means allowing
      let location = (ship.location ? ship.location.replace(" ", "").replace("/", "_") : "");
      if (this.tagServ.cfb_externalport) {
        if (this.tagServ.cfb_externalport === 'cfad') {
          if (location === 'NN') {
            allow = true;
          }
        } if (this.tagServ.cfb_externalport === 'shearwater') {
          if (location === 'NA') {
            allow = true;
          }
        }
      } else {
        // if selected ship is external jetty means
        const found = this.tagServ.cfbexternal_location.some(el => el === location);
        if (found) {
          if (location === 'NN') {
            willLoadExternalJetty = true;
            this.broadcastInfo({ src: 'animcontrol', event: 'external_jetty', data: { type: 'cfad' }, key: "" });
          }
          if (location === 'NA') {
            willLoadExternalJetty = true;
            this.broadcastInfo({ src: 'animcontrol', event: 'external_jetty', data: { type: 'shearwater' }, key: "" });
          }
          allow = found;
        } else {
          allow = true;
        }
      }

      if (allow) {
        this.parentCatListIdx = i;
        this.activeShipList = ship;
        if (ship['ship_status'] === 'hide') {
          this.showUnknownLocPopup.emit(ship);
        } else {
          this.parentCatListIdx = i;
          this.activeShipList = ship;

          // if selected ship is external jetty means
          if (willLoadExternalJetty) {
            setTimeout(() => { this.selectedShip.emit(ship); }, 1200);
          } else {
            this.selectedShip.emit(ship);
          }
          if (!moveTimer) {
            this.broadcastInfo({ src: 'sidepanel', event: 'selectedship', data: ship, key: "" });
          } else {
            this.broadcastInfo({ src: 'sidepanel', event: 'selectedship', data: null, key: "" });
          }
        }
      }
    }
  }


  /* * *
  * method for multiple active list maintain
  * * */
  getFilterActiveStatus(key) {
    let found = this.filterKey.some(el => el === key);
    return found;
  }

  getFilterKeyValue(shipData) {
    this.filterObjectKeys = [];
    this.filterObjectKeys = Object.keys(shipData);

  }

  /* * * * *
  * select all
  * * * * * */
  filterSelectAll(checked) {
    this.isFilterAll = checked;
    if (checked) {
      this.filterKey = this.filterObjectKeys;
    } else {
      this.filterKey = [];
    }
    if (!this.unknownLocationState) {
      this.searchClear();
    }
  }

  /* * * * *
  * on change filter
  * * * * * */
  onFilterChange(event, key) {
    if (event.checked) {
      this.filterKey.push(key);
      if (this.filterObjectKeys.length === this.filterKey.length) {
        this.isFilterAll = true;
      }
    } else {
      let idx = this.filterKey.findIndex(item => item == key);
      if (idx > -1) {
        this.filterKey.splice(idx, 1);
        this.isFilterAll = false;
      }
      this.getFilterKeyValue(this.shipData);
    }
    this.searchClear();
  }

  ToggleDControlOpenHanddle() {
    this.handleDControlToggleOpen = !this.handleDControlToggleOpen;
  }

  ToggleDemoOpenHanddle() {
    this.handleDemoToggleOpen = !this.handleDemoToggleOpen;
  }

  onDemoCtl(p, idx, action = "") {
    let list = p === 'craneanim' ? this.ssoAnimateList : this.shipAnimateList
    list[idx].animateStatus = action;
    let d = { src: 'animcontrol', event: action, data: { id: p, type: list[idx].animtype } };
    this.communicationServ.getInstance().next(d)
  }
  onDemoF35Ctl(idx, action = "") {
    if(idx=='all'){
      this.f35AnimateStatus = action;
      let f35List=[];
      let state;
      this.f35AnimateList.forEach((list,i)=>{
        this.f35AnimateList[i].animateStatus = action;
        state = action=='play'?true:false;
        // let d = { animId: list,state: state};
        f35List.push(list.pid);
      });
      f35List = state?f35List:[];
      this.broadcastInfo({ src: 'sidepanel', event: 'f35AnimAll', data: f35List, key: state });

    }else{
      // if(this.f35AnimateStatus=='play'){
       
      // }
      let list =this.f35AnimateList[idx];
      list.animateStatus = action;
      this.checkF35Anim();
      let state = action=='play'?true:false;
       let d = { animId: list.pid,state: state};
      this.broadcastInfo({ src: 'sidepanel', event: 'f35Anim', data: d, key: "" });
    }
  }

  checkF35Anim(){
    let status =false;
    this.f35AnimateList.forEach((list,i)=>{
    if(list['animateStatus']=='pause'){
      status = true;
     
    }
    })
    if(status){
      this.f35AnimateStatus = 'pause';
    }else{
      this.f35AnimateStatus = 'play';
    }
  }
  liveEnv = false;
  currentTime = 12;
  getCurrentTime() {
    let date = new Date();
    let t = date.toLocaleString('en-US', { hour: 'numeric', hour12: false });
    let m = date.getMinutes() / 60;
    return Number(t) + Number(m.toFixed(2));
  }

  setliveEnv(ev) {
    let checked = ev.checked;
    this.currentTime = checked ? this.getCurrentTime() : 12;
    this.liveEnv = checked;
    let d = { src: 'animcontrol', event: 'live_env', data: checked };
    this.communicationServ.getInstance().next(d)
  }

  onTime(ev) {
    let val = ev.value;
    let d = { src: 'animcontrol', event: 'live_env_time', data: val };
    this.communicationServ.getInstance().next(d)
  }

  /* * * * *
  * format label
  * * * * * */
  formatLabel(value: number) {
    let t = value;
    let h = Math.floor(t);
    let m = Math.round((t - h) * 60);
    let hh = h < 10 ? '0' + h : h;
    let mm = m < 10 ? '0' + m : m;
    return hh + ":" + mm;
  }

  regEx(location) {
    return location;
    // return location.replace(/_/g, " /").replace(/[^0-9](?=[0-9])/g, '$& ');
  }

  regExReverse(location) {
    return location.replace(/\//g, "_").replace(/\s/g, '');
  }

  rawDataPanel(e) {
    if (!e.checked) {
      this.rawdataPanelOpen = false;
    }
    if (e.checked && this.rawDataList != undefined) {
      this.rawdataPanelOpen = true;
    }
    this.handleDemoToggleOpen = false;
    this.rawDataState.emit(e.checked);
  }

  rawdataClose() {
    this.rawdataPanelOpen = !this.rawdataPanelOpen;
    this.rawDataClose.emit(this.rawdataPanelOpen);
    let slide: any = document.querySelector(".ctrlTgl mat-slide-toggle input");
    slide.click();
  }

  rawDataPrint(data) {
    let elem = document.getElementById('rawdata');
    let rawDataKeys = Object.keys(data)
    if (elem) {
      var elemList = "<div>";
      rawDataKeys.forEach((key) => {
        if (key == 'place') {
          elemList += "<p><span>Position:</span><span>x:" + data['place']['pos'].x + "<br>y:" + data['place']['pos'].y + "<br>z:" + data['place']['pos'].z + "</span>";
          elemList += "<p><span>Rotation:</span><span>x:" + data['place']['rot'].x + "<br>y:" + data['place']['rot'].y + "<br>z:" + data['place']['rot'].z + "</span>";
        } else {
          if (data[key] == '') {
            elemList += "<p><span>" + key + ":</span><span>-</span>";
          } else {
            elemList += "<p><span>" + key + ":</span><span>" + data[key] + "</span>";
          }
        }
        // console.log(key,data);
      });
      elemList += "</div>";
      elem.innerHTML = elemList;
    }
  }

  settingPanelClose() {
    this.handleDControlToggleOpen = false;
    this.settingPanelState.emit(false);
  }

  getActivityColor(activity) {
    if (activity) {
      activity = activity.split(" ").join("").toLowerCase();
      let activityConfig = this.activityServ.activityConfig;
      if (activityConfig[activity]) {
        return activityConfig[activity].color
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  /**
   * get cone color
   */
  getConeColor(color) {
    let col = ''
    if (color) {
      this.coneColors.forEach(elem => {
        if (color === elem.material) {
          col = elem.code;
        }
      });
      return col;
    } else {
      return '';
    }
  }

  getLocationConeColor(color) {
    let col = ''
    if (color) {
      this.locationconeColors.forEach(elem => {
        if (color === elem.material) {
          col = elem.code;
        }
      });
      return col;
    } else {
      return '';
    }
  }

  /**
   * method for show all activity show/hide
   */
  showAllActivity(e) {
    this.showActivity = e.checked;
    var activityList = Object.keys(this.currentActivityListState)
    activityList.forEach(element => {
      this.currentActivityListState[element] = e.checked
    });
    this.show_All_Activity.emit(this.showActivity);
  }

  /* * *
  * method for activity show/hide
  * * */
  toggleActivity(e, key) {
    this.currentActivityListState[key] = e.checked;
    this.activityListState();
    let obj = { show: e.checked, activity: key };
    this.toggle_activity.emit(obj);
  }

  /* * *
  * method for activity checked all
  * * */
  activityListState() {
    var activityList = Object.keys(this.currentActivityListState);
    var data = true;
    activityList.forEach(element => {
      if (!this.currentActivityListState[element]) {
        data = this.currentActivityListState[element]
      }
    });
    this.showActivity = data;
  }

  // showJetty(e) {
  //   this.toggleJetty.emit(e.checked);
  // }

  // loadPlayScreen(e) {
  //   this.loadHomeScreen.emit('PTP');
  // }

  openMessageScreen(e) {
    this.loadMessageScreen.emit(true);
  }

  /*   @HostListener('document:mouseover', ['$event'])
    mouseover(event) {
      if (event.target.matches('#shipPanel')) {
        setTimeout(() => {
          this.shipHandleToggleOpen = true;
        }, 100);
      }
      if (event.target.matches('#demoPanel')) {
        setTimeout(() => {
          this.handleDemoToggleOpen = true;
        }, 100);
      }
    } */

  getPanelwidth(e) {
    if (e == 'filterPanel') {
      let rawElem = document.getElementById('shipPanel');
      width = rawElem.offsetWidth;
      return width + 'px';
    } else {
      let elem = document.getElementById(e);
      var width = elem.offsetWidth;
      if (this.filterHandleToggleOpen) {
        let rawElem = document.getElementById('filterPanel');
        width += rawElem.offsetWidth;
      }
      if (this.serviceHandleToggleOpen) {
        let rawElem = document.getElementById('filterPanel');
        width += rawElem.offsetWidth;
      }
      if (e == 'layoutPanels' || e == 'toolPanel' || e == 'shipPanel_2' || e == 'servicesPanel' || e == 'cautionPanel') {
        let rawElem = document.getElementById(e);
        width = rawElem.offsetWidth;
        return width + 'px';
      }
      if (e == 'WFMPanel' || e == 'libPanel') {
        let rawElem = document.getElementById(e);
        width = rawElem.offsetWidth;
      } if(e == 'statusPanel'){
        let rawElem = document.getElementById(e);
      width = rawElem.offsetWidth;
      return width + 'px';
      }

      return 'translateX(' + width + 'px)';
    }
  }

  /* * *
  * method for reset all the filters
  * * */
  resetFilter() {
    this.sortItem = 'startdate';
    this.searchClear();
    this.clearDateRange();
    this.activitySelectAll(true);
    this.filterSelectAll(true);
    this.filterLaydownSelectAll(true)
    this.filterFirstAidSelectAll(true)
    this.getDateGroup(this.startDate, this.endDate);
    this.coneUpdatedData = this.coneList;
    delete this.hazardStartDate;
    delete this.hazardEndDate;
    this.hazardTypeList = this.hazardTypeList.map(v => ({ ...v, filter: true }));
    this.jettySearch = '';
    this.kioskSearch = '';
    this.locationSearch = '';
    var cloneKiosk = JSON.parse(JSON.stringify(this.kioskFormatData.formatKioskJson))
    this.kisokElementArray = cloneKiosk;
    this.expandDateList = [];
  }

  /* * *
  * method for activity is activity selected/not
  * * */
  getActivityStatus(activity) {
    const found = this.activitySelectedList.some(el => el === activity);
    return found;
    // const found = this.currentActivityListState[activity];
    // return !found;
  }

  /* * *
  * method for get all activities
  * * */
  getAcivityListKey() {
    this.currentActivityList = [];
    if (this.defRangeMetaData.length > 0) {
      this.defRangeMetaData.map(ship => {
        if (ship['ship_status'] === 'show') {
          let activity = ship['activity'].split(" ").join("").toLowerCase();
          const found = this.currentActivityList.some(el => el === activity);
          if (!found) {
            if (activity != '') {
              this.currentActivityList.push(activity);
            }
          }
        }
      });
    }
  }

  /* * *
  * select all activity
  * * */
  activitySelectAll(checked) {
    this.getDateGroup(this.startDate, this.endDate);
    if (checked) {
      this.activitySelectedList = this.currentActivityList;
    } else {
      this.activitySelectedList = [];
    }
    this.activityListSelect(this.shipMetaData, true);
  }

  /* * *
  * on change activity
  * * */
  onChangeActivity(event, activity) {
    this.getDateGroup(this.startDate, this.endDate);
    if (event.checked) {
      this.activitySelectedList.push(activity);
    } else {
      let idx = this.activitySelectedList.findIndex(el => el === activity);
      if (idx > -1) {
        this.activitySelectedList.splice(idx, 1);
        this.activityAll = false;
      }
    }
    this.getAcivityListKey();
    this.activityListSelect(this.shipMetaData, true);
  }

  /* * *
  * method for Activity filter
  * * */
  activityListSelect(shipData, def = false) {
    if (this.activitySelectedList.length != this.currentActivityList.length) {
      this.activityAll = false;
    } else {
      this.activityAll = true;
    }

    this.shipUpdatedData = [];
    if (!this.activityAll) {
      if (this.activitySelectedList.length == 0) {
        this.shipUpdatedData = [];
      }

      else {
        shipData.forEach(element => {
          let idx = this.activitySelectedList.findIndex(e => e == element.activity.replace(/\s/g, ''));
          if (idx > -1) {
            this.shipUpdatedData.push(element)
          }
        });

        // if search item have data means filter happends
        if (def) {
          if (this.search != '') {
            this.searchData();
          }
        }
      }

    } else {
      this.shipUpdatedData = shipData;
      // if search item have data means filter happends
      if (def) {
        if (this.search != '') {
          this.searchData();
        }
      }
    }
  }

  selectStatusAll(event) {
    this.shipStatus = [];
    if (event.checked) {
      this.shipStatus = this.shipStatusList;
    }
    this.statusShiplist(this.shipMetaData);
  }

  statusShiplist(e) {
    if (this.shipStatus.length != 3) {
      this.statusAll = false;
    } else {
      this.statusAll = true;
    }
    this.shipUpdatedData = [];
    let idx = this.shipStatus.findIndex(e => e == 'all');
    if (idx == -1) {
      e.forEach(element => {
        let idx = this.shipStatus.findIndex(e => e == element.status);
        if (idx > -1) {
          this.shipUpdatedData.push(element)
        }
      });
    } else {
      this.shipUpdatedData = e;
    }
    this.setActiveShipIdx();
    this.sortByShiplist(this.sortItem);
  }

  /* * *
  * sortitem on change
  * * * */
  onChangeSortItem(event, key) {
    // if (!event.checked) {
    //   event.source._checked = true;
    // }
    this.sortByShiplist(key);
  }

  onChangeServiceSortItem(event: any, e: any) {
    console.log("hello")
    this.sortByServicelist(e);
  }

  kioskSortItem: any = 'jetty';
  filterKioskKeys = [{ "key": "jetty", "name": "Jetty" }, { "key": "location1", "name": "Zone" }, { "key": "kiosk", "name": "Kiosk" }, { "key": "service", "name": "Kiosk Status" }, { "key": "Mechanical Services", "name": "Mechanical Status" }, { "key": "Power Services", "name": "Power Status" }];
  sortByServicelist(e) {
    let sortItem = e;
    this.kioskSortItem = e;
    console.log("Stored Item ", sortItem);
    let kisokElementArray = this.kisokElementArray.jettyList;
    let loc = "location1"
    let kiosk = "kiosk"
    let servicetype = "servicetype"
    if (sortItem === 'jetty') {
      kisokElementArray.sort(function (a, b) {
        if (sortItem && sortItem) {
          return a.jetty.localeCompare(b.jetty, undefined, { numeric: true });
        }
      })
    }
    if (sortItem === 'location1') {
      kisokElementArray.forEach(item => {
        if (item && item[sortItem]) {
          item[sortItem].sort(function (a, b) {
            return a[sortItem].localeCompare(b[sortItem], undefined, { numeric: true });
          });
        }
      })
    }
    if (sortItem === 'kiosk') {

      kisokElementArray.forEach(item => {
        if (item && item[loc]) {
          item[loc].forEach(item2 => {
            if (item2 && item2[sortItem]) {
              item2[sortItem].sort(function (a, b) {
                return a[sortItem].localeCompare(b[sortItem], undefined, { numeric: true })
              })
            }
          })
        }
      })
    }

    if (sortItem === 'servicetype') {
      kisokElementArray.forEach(item => {
        if (item && item[loc]) item[loc].forEach(item1 => {
          if (item1 && item1[kiosk]) item1[kiosk].forEach(item2 => {
            if (item2 && item2[sortItem]) item2[sortItem].sort(function (a, b) {
              return a[sortItem].localeCompare(b[sortItem], undefined, { numeric: true })
            })
          })
        })
      })
    }
    if (sortItem === 'service') {
      kisokElementArray.forEach(item => {
        if (item && item[loc]) item[loc].forEach(item1 => {
          if (item1 && item1[kiosk]) item1[kiosk].forEach(item2 => {
            if (item2 && item2[servicetype]) item2[servicetype].forEach(item3 => {
              if (item3 && item3[sortItem].length > 0) {
                let dataHandle = false;
                item3[sortItem].forEach(element => {
                  if (element['status']) {
                    dataHandle = true;
                  }
                });
                if (dataHandle) {
                  item3[sortItem].sort(function (a, b) {
                    return a['status'].localeCompare(b['status'], undefined, { numeric: true })
                  })
                }
              }
            })
          })
        })
      })
    }
    if (sortItem === 'Power Services') {
      kisokElementArray.forEach(item => {
        if (item && item[loc]) item[loc].forEach(item1 => {
          if (item1 && item1[kiosk]) item1[kiosk].forEach(item2 => {
            if (item2 && item2[servicetype]) item2[servicetype].forEach(item3 => {
              if (item3.servicetype == sortItem) {
                sortItem = 'service';
                if (item3 && item3[sortItem].length > 0) {
                  let dataHandle = false;
                  item3[sortItem].forEach(element => {
                    if (element['status']) {
                      dataHandle = true;
                    }
                  });
                  if (dataHandle) {
                    item3[sortItem].sort(function (a, b) {
                      return a['status'].localeCompare(b['status'], undefined, { numeric: true })
                    })
                  }
                }
              }
            })
          })
        })
      })
    }
    if (sortItem === 'Mechanical Services') {
      kisokElementArray.forEach(item => {
        if (item && item[loc]) item[loc].forEach(item1 => {
          if (item1 && item1[kiosk]) item1[kiosk].forEach(item2 => {
            if (item2 && item2[servicetype]) item2[servicetype].forEach(item3 => {
              if (item3.servicetype == sortItem) {
                sortItem = 'service';
                if (item3 && item3[sortItem].length > 0) {
                  let dataHandle = false;
                  item3[sortItem].forEach(element => {
                    if (element['status']) {
                      dataHandle = true;
                    }
                  });
                  if (dataHandle) {
                    item3[sortItem].sort(function (a, b) {
                      return a['status'].localeCompare(b['status'], undefined, { numeric: true })
                    })
                  }
                }
              }
            })
          })
        })
      })
    }

    console.log('console.log(kisokElementArray)', sortItem, kisokElementArray)
  }


  jettySearch: any = '';
  kioskSearch: any = '';
  locationSearch: any = '';
  sortByShiplist(e) {
    this.sortItem = e;
    // var listItem=this.shipUpdatedData;
    // this.shipUpdatedData.forEach(element => {
    //   var data={};
    //   data[e]= element[e];
    //   listItem.push(data)
    // });
    // console.log(this.shipUpdatedData, e)
    // console.log(this.shipUpdatedData.sort((a, b) => b.e - a.e))
    // this.isDesc = !this.isDesc; //change the direction
    // this.column = property;
    // this.sortList.sort((a, b) => a.localeCompare(b, this.languages[langId].code))

    let direction = 1;
    this.shipUpdatedData.sort((a, b) => {
      // console.log(a[e] < b[e], a[e] > b[e], a[e], b[e])
      if (e == 'startdate' || e == 'enddate') {
        let aDate = new Date(a[e]);
        let bDate = new Date(b[e])
        if (aDate < bDate) {
          return -1 * direction;
        }
        else if (aDate > bDate) {
          return 1 * direction;
        }
        else {
          return 0;
        }
      } else {
        if (a[e] < b[e]) {
          return -1 * direction;
        }
        else if (a[e] > b[e]) {
          return 1 * direction;
        }
        else {
          return 0;
        }
      }
    });

    if (this.activeShipList) {
      let idx = this.shipUpdatedData.findIndex(e => e.name == this.activeShipList.name)
      if (idx > -1) {
        this.parentCatListIdx = idx;
      }
    }
  }

  expandKiosk(jidx, locidx, kioskidx) {
    this.kisokElementArray.jettyList[jidx].location1[locidx].kiosk[kioskidx].expand = !this.kisokElementArray.jettyList[jidx].location1[locidx].kiosk[kioskidx].expand;
  }
  setActiveShipIdx() {
    if (this.activeShipList) {
      let idx = this.shipUpdatedData.findIndex(e => e.name == this.activeShipList.name)
      if (idx > -1) {
        if (this.parentCatListIdx == null) {
          // this.selectedShip.emit(this.activeShipList);
        }
        this.parentCatListIdx = idx;
      } else {
        this.parentCatListIdx = null;
        // this.selectedShip.emit(null);
      }
    }
  }

  getContrastCol(hexcolor) {
    hexcolor = hexcolor.replace("#", "");
    if (hexcolor.length === 3) {
      hexcolor = hexcolor + hexcolor;
    }
    var r = parseInt(hexcolor.substr(0, 2) || "00", 16);
    var g = parseInt(hexcolor.substr(2, 2) || "00", 16);
    var b = parseInt(hexcolor.substr(4, 2) || "00", 16);
    var col = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (col >= 128) ? 'black' : 'white';
  }

  changePanelBG(event) {
    let panel: any = document.getElementsByClassName("side_panel")[0];
    let filter: any = document.getElementsByClassName("filter_side_panel")[0];
    // let rawdata: any = document.getElementsByClassName("rawdata_side_panel")[0];
    // let control: any = document.getElementsByClassName("control_side_panel")[0];
    this.panelBG = event.target.value
    if (!panel) {
      return
    }
    if (!filter) {
      return
    }
    // if (!rawdata) {
    //   return
    // }
    // if (!control) {
    //   return
    // }

    panel.style.backgroundColor = this.panelBG;
    filter.style.backgroundColor = this.panelBG;
    // rawdata.style.backgroundColor = this.panelBG;
    // control.style.backgroundColor = this.panelBG;


  }

  invertState: any = true;
  changeCardBG(event) {
    this.cardBG = event.target.value;
    let panels: any = document.querySelectorAll(".shipParentList .catList");
    panels.forEach((panel) => {
      panel.style.backgroundColor = this.cardBG;
      // panel.style.color = this.getContrastCol(this.cardBG);
    })
    let color = this.getContrastCol(this.cardBG);
    if (color == 'black') {
      this.invertState = false;
    } else {
      this.invertState = true;
    }
  }
  changeFontColor(event) {
    this.fontColor = event.target.value;
    let panels: any = document.querySelectorAll(".shipParentList .catList");
    let label: any = document.querySelectorAll(".side_panel .result_search .mat-form-field-label");
    let surffixIcon: any = document.querySelectorAll(".side_panel .result_search .mat-button-base");
    let value: any = document.querySelectorAll(".side_panel .result_search .mat-input-element");
    let flabel: any = document.querySelectorAll(".filter_side_panel .dateRange .mat-form-field-label");
    let fsurffixIcon: any = document.querySelectorAll(".filter_side_panel .dateRange .mat-button-base");
    // let icon: any = document.querySelectorAll(".side_panel .result_search .mat-datepicker-toggle-default-icon");
    let icon: any = document.querySelectorAll(".filter_side_panel .dateRange .mat-datepicker-toggle-default-icon");
    let selectArrow: any = document.querySelectorAll(".side_panel .result_search .mat-select-arrow");
    let selectValue: any = document.querySelectorAll(".side_panel .result_search .mat-select-value");
    let checkbox: any = document.querySelectorAll(".filter_side_panel .dateLabel");
    let filterLabel: any = document.querySelectorAll(".filter_side_panel .filter_label");
    let filterInput: any = document.querySelectorAll(".filter_side_panel .filterInput .mat-checkbox .mat-checkbox-label");
    let btnFont: any = document.querySelectorAll(".inputFields .mat-button-wrapper");
    let labelFont: any = document.querySelectorAll(".inputFields .dateRangeLabel");
    panels.forEach((panel) => {
      panel.style.color = this.fontColor;
    })
    label.forEach((panel) => {
      panel.children[0].style.color = this.fontColor;
    });
    labelFont.forEach((panel) => {
      panel.children[0].style.color = this.fontColor;
    })
    surffixIcon.forEach((panel) => {
      panel.style.color = this.fontColor;
    })
    flabel.forEach((panel) => {
      panel.children[0].style.color = this.fontColor;
    })
    fsurffixIcon.forEach((panel) => {
      panel.style.color = this.fontColor;
    })
    value.forEach((panel) => {
      panel.style.color = this.fontColor;
    })
    icon.forEach((panel) => {
      panel.style.color = this.fontColor;
    })
    selectArrow.forEach((panel) => {
      panel.style.color = this.fontColor;
    })
    selectValue.forEach((panel) => {
      panel.style.color = this.fontColor;
    })
    checkbox.forEach((panel) => {
      panel.style.color = this.fontColor;
    })
    filterLabel.forEach((panel) => {
      panel.style.color = this.fontColor;
    })
    filterInput.forEach((panel) => {
      panel.style.color = this.fontColor;
    })
    btnFont.forEach((panel) => {
      panel.style.color = this.fontColor;
    })
  }
  changeLineColor(event) {
    this.lineColor = event.target.value;
    let panels: any = document.querySelectorAll(".side_panel .result_search .mat-form-field-flex");
    panels.forEach((panel) => {
      panel.style.borderBottom = "1px solid" + this.lineColor;

    })
  }
  onPortSelect(val) {
    // if (val != this.loadedPortLocation) {
    if (val != this.loadedPort) {
      this.loadedPort = val;
      this.broadcastInfo({ src: 'sidepanel', event: 'port_change', data: val, key: "" });
    }
  }
  /***********
   * *******jetty label show/Hide
   * ********/
  jettyLabel(e) {
    this.jettyLabelState = !e.checked;
    if (this.jettyLabelState && this.toggleKioskState) {
      this.toggleKioskName({ checked: !false })
    }
    this.showJettyLabels.emit(e.checked)
  }

  /***********
  * *******jetty label show/Hide
  * ********/
  onChangeUnknownLocation(checked, emitData = false) {
    this.unknownLocationState = checked;
    if (!emitData) {
      this.unknownLocationToggle.emit(checked);
    }
    var data = [];
    if (checked) {
      this.shipParentMetaList.forEach(element => {
        if (element['location_status'] == "unknown") {
          data.push(element);
        }
      });
      this.shipUpdatedData = data;
      this.shipMetaData = data;
      // this.showFilterships.emit(data);
    } else {
      this.shipUpdatedData = this.shipParentMetaList;
      this.shipMetaData = this.shipParentMetaList;
      // this.showFilterships.emit(null);
    }
  }
  /***********
  * *******Hazard show/Hide
  * ********/
  onChangeShowhideHazard(e) {
    this.hazardShowState = e.checked;
    this.hazardShowToggle.emit(e.checked)
  }
  /***********
  * *******location error flag show/Hide
  * ********/
  onChangeLocationErrorState(e) {
    this.locationErrorState = e.checked;
    this.locationErrorToggle.emit(e.checked)
  }
  /***********
  * *******Building show/Hide
  * ********/
  showBuilding(e) {
    this.showBuildingState = e.checked;
    this.showBuildingToggle.emit(e.checked)
  }
  /***********
  * ******* Ground show/Hide
  * ********/
  showGround(e) {
    this.showGroundState = e.checked;
    this.showGroundToggle.emit(e.checked)
  }
  /***********
  * ******* Time progress show/Hide
  * ********/
  timeProgressState(e) {
    this.timeProgress = e.checked;
    this.timeProgressToggle.emit(e.checked)
  }
  /***********
   * ******* Laydown show/Hide
   * ********/
  togglLaydown(e) {
    this.toggleLaydownState = !e.checked;
    this.toggleLaydownToggle.emit(!e.checked)
  }
  toggleCone(e) {
    this.toggleConeState = !e.checked;
    this.toggleFMFCone.emit(!e.checked)
  }
  /**
   * toggle Ocean Effect
   */
  toggleOcean(e) {
    this.toggleOceanState = e.checked;
    this.broadcastInfo({ src: 'controlpanel', event: 'toggleocean', data: e.checked, key: "" });
  }

  dateFormat(date) {
    if (date) {
      return this.datePipe.transform(date, 'MMMM d, y')
    }
  }

  getDayLength(startDate, endDate) {
    var startCDate: any = new Date(startDate)
    var endCDate: any = new Date(endDate)
    var delta = Math.abs(endCDate - startCDate) / 1000;
    var days = Math.floor(delta / 86400);
    delta -= days * 86400;
    return (days + 1) + " day(s)";
  }

  getDayHoursLength(startDate, endDate) {
    var startCDate: any = new Date(startDate)
    var endCDate: any = new Date(endDate)
    var delta = Math.abs(endCDate - startCDate) / 1000;
    // calculate (and subtract) whole days
    var days = Math.floor(delta / 86400);
    delta -= days * 86400;
    // console.log( "day",delta)
    // calculate (and subtract) whole hours
    var hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;
    return days + "days" + " " + hours + "hours";
    // console.log( "hours",delta)
  }

  formateDate(date) {
    if (date) {
      return this.datePipe.transform(date, 'dd-MMM-yy');
    } else {
      return '-'
    }

  }

  getDayHoursLength2(startDate, startTime, endDate, endTime) {
    startDate = this.datePipe.transform(startDate, 'dd-MMM-yy') + ' ,' + startTime;
    endDate = this.datePipe.transform(endDate, 'dd-MMM-yy') + ' ,' + endTime;
    var startCDate: any = new Date(startDate)
    var endCDate: any = new Date(endDate)
    var delta = Math.abs(endCDate - startCDate) / 1000;
    // calculate (and subtract) whole days
    var days = Math.floor(delta / 86400);
    delta -= days * 86400;
    // console.log( "day",delta)
    // calculate (and subtract) whole hours
    var hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;
    return days + "days" + " " + hours + "hours";
    // console.log( "hours",delta)
  }

  hideUnselected(e) {
    this.hideUnselectedToggle.emit(e.checked);
  }
  stateBoardViewToggle(e){
    this.stateBoardView = !this.stateBoardView;
    this.handleToolsToggleOpen=false;
    this.broadcastInfo({ src: 'admin-SB', event: 'adminView', data: this.stateBoardView, key: "" });
  }
  hideCrane(e) {
    this.hideCraneState = e.checked;
    if (e.checked) {
      this.ssoAnimateList.forEach((element, i) => {
        if (element.animateStatus == 'play') {
          this.onDemoCtl(element.pid, i, 'stop')
        }
      });

    }
    this.hideCraneToggle.emit(!e.checked);
  }

  hideLight(e) {
    this.hideLightToggle.emit(!e.checked);
  }
  toggleBridge(e) {
    this.bridgeViewState = e.checked;
    this.bridgeViewToggle.emit(this.bridgeViewState);
  }
  filterToggle() {
    this.filterHandleToggleOpen = !this.filterHandleToggleOpen;
    if (window.innerWidth < 541) {
      this.ToggleOpenHanddle()
      this.ToggleServiceOpenHanddle()
      this.ToggleLaydownOpenHanddle()
    }
  }
  ServiceShort() {
    this.serviceHandleToggleOpen = !this.serviceHandleToggleOpen;
    if (window.innerWidth < 541) {
      this.ToggleOpenHanddle()
      this.ToggleServiceOpenHanddle()
      this.ToggleLaydownOpenHanddle()
    }
  }
  availableList(activity) {
    var state = true;
    this.currentActivityList.forEach(element => {
      if (element == activity) {
        state = false;
      }
    });
    return state;
  }
  alphabetOrder(data, key) {
    data.sort((a: any, b: any) => {
      if (a[key] < b[key]) {
        return -1;
      } else if (a[key] > b[key]) {
        return 1;
      } else {
        return 0;
      }
    });
    return data;
  }

  chitMaping = [
    { id: 1, chitId: '60edba85b3ebe8003559a4d6' },
    { id: 2, chitId: '60ee9265b3ebe8003559a4e4' },
    { id: 3, chitId: '60ee8855b3ebe8003559a4d8' },
    { id: 4, chitId: '60ee88f4b3ebe8003559a4d9' },
    { id: 5, chitId: '60ee89aeb3ebe8003559a4da' },
    { id: 6, chitId: '60ee8c82b3ebe8003559a4db' },
    { id: 7, chitId: '60ee8da5b3ebe8003559a4dc' },
    { id: 8, chitId: '60ee8eafb3ebe8003559a4dd' },
    { id: 9, chitId: '60ee8eafb3ebe8003559a4dd' },
    { id: 10, chitId: '60ee9052b3ebe8003559a4df' },
    { id: 11, chitId: '60ee913bb3ebe8003559a4e1' },
    { id: 12, chitId: '60ee913bb3ebe8003559a4e1' },
    { id: 13, chitId: '60ee91a7b3ebe8003559a4e2' },
    { id: 14, chitId: '60ee9201b3ebe8003559a4e3' },
    { id: 15, chitId: '60ee9265b3ebe8003559a4e4' },
    { id: 16, chitId: '60edba85b3ebe8003559a4d6' },
    { id: 17, chitId: '60ee9265b3ebe8003559a4e4' },
    { id: 18, chitId: '60ee8855b3ebe8003559a4d8' },
    { id: 19, chitId: '60ee88f4b3ebe8003559a4d9' },
    { id: 20, chitId: '60ee89aeb3ebe8003559a4da' },
    { id: 21, chitId: '60ee8c82b3ebe8003559a4db' },
    { id: 22, chitId: '60ee8da5b3ebe8003559a4dc' },
    { id: 23, chitId: '60ee8eafb3ebe8003559a4dd' },
    { id: 24, chitId: '60ee8eafb3ebe8003559a4dd' },
    { id: 25, chitId: '60ee9052b3ebe8003559a4df' },
    { id: 26, chitId: '60ee913bb3ebe8003559a4e1' },
    { id: 27, chitId: '60ee913bb3ebe8003559a4e1' },
    { id: 28, chitId: '60ee91a7b3ebe8003559a4e2' },
    { id: 29, chitId: '60ee9201b3ebe8003559a4e3' },
    { id: 30, chitId: '60ee9265b3ebe8003559a4e4' },
  ]

  chitWindow(chitId, date) {
    console.log("chitId", chitId);
    console.log("Ship Details", date.groupValue);
    this.chitWindowState = true;

    // var activityDate = this.datePipe.transform(date.groupValue, 'dd-MM-yyy') ;
    var activityDate = this.datePipe.transform(date.groupValue, 'MM-dd-yyy');
    console.log("Ship Details", activityDate);
    // this.activeShip('', item, i)
    // let chitId: any = ''
    // let chit = this.chitMaping.find(el => el.id === i);
    // if (chit) {
    //   chitId = chit['chitId']
    // }
    this.broadcastInfo({ src: 'sidepanel', event: 'stepper', data: this.chitWindowState, key: "" });
    // this.broadcastInfo({ src: 'sidepanel', event: 'deleteChit', data: chitId, key: "" });
    setTimeout(() => {
      this.broadcastInfo({ src: 'sidepanel', event: 'loadStepper', data: chitId, key: chitId.activity, date: activityDate });
    }, 500)
  }

  chitView(chit) {
    // console.log("chitId", chit);
    this.chitWindowState = true;
    var shipMatch = {};
    var shipName = this.shipUpdatedData.filter(t => t.ship == chit.Vessel && t.location === chit.Location);
    if (shipName.length > 0) {
      shipMatch = shipName[0];
    }

    this.broadcastInfo({ src: 'sidepanel', event: 'stepper', data: this.chitWindowState, key: "" });
    setTimeout(() => {
      this.broadcastInfo({ src: 'sidepanel', event: 'updateSelectedShip', data: shipMatch, key: '' });
      this.broadcastInfo({ src: 'sidepanel', event: 'chitViewData', data: chit, key: '' });
      this.broadcastInfo({ src: 'sidepanel', event: 'shipDetails', data: shipMatch, key: "" });
    }, 500)
  }

  /* * * * *
  * onchange upload key
  * * * * * */
  selectedUploadkey = 'first_aid';
  onChangeUploadKey(key) {
    this.selectedUploadkey = key;
  }

  /* * * * *
 * choose excel file
 * * * * * */
  chooseExcelFile(e) {
    // console.log("event", e);
    this.project_config = this.configService.project_config;
    if (this.selectedUploadkey) {
      if (this.loadedPort) {
        var re = /(?:\.([^.]+))?$/;
        var ext = re.exec(e.target.files[0].name)[1];
        if (ext == 'xlsx' || ext == 'xls' || ext == 'xlsm' || ext == 'csv') {
          this.selectedFile = e.target;
          let port = this.loadedPort.split(" ").join("").toLowerCase();
          this.formData.append('file', e.target.files[0]);
          this.formData.append('port', port);
          this.selectedFile = e.target.files[0].name;
          let url;
          if (this.selectedUploadkey.toLocaleLowerCase() === 'kiosk') {
            url = this.project_config.kioskUpload;
          }
          if (this.selectedUploadkey.toLocaleLowerCase() === 'first_aid') {
            url = this.project_config.uploadFirstAid;
          }
          this.showBlockLoading = true;
          this.configService.uploadExcelData(url, this.formData, this.excelUploadSuccess);
        } else {
          this.openPublishDialog.emit("Invalid File");
          return;
        }
      } else {
        this.openPublishDialog.emit("Please Select Kha option !!");
        return;
      }
    } else {
      this.openPublishDialog.emit("Please Select upload option !!");
      return;
    }
  }
  /* * * * *
  * check Json or Not
  * * * * * */
  hasJsonStructure(str) {
    if (typeof str !== 'string') return false;
    try {
      const result = JSON.parse(str);
      const type = Object.prototype.toString.call(result);
      return type === '[object Object]'
        || type === '[object Array]';
    } catch (err) {
      return false;
    }
  }

  /* * *
  * reset both file
  * * */
  resetFile() {
    this.formData = new FormData();
    this.selectedFile = 'No file chosen...';
    let elem: any = document.getElementById("fileupload");
    elem.value = "";
  }

  /* * * * *
  * excel upload sucess
  * * * * * */
  excelUploadSuccess = ((kioskData: string) => {
    this.showBlockLoading = false;
    let isJson = this.hasJsonStructure(kioskData);
    if (isJson) {
      kioskData = JSON.parse(kioskData);
      if (kioskData['result'] == 'success') {
        if (this.selectedUploadkey.toLocaleLowerCase() === 'kiosk') {
          this.kioskJson = kioskData['data'];
        }
        // console.log("----------------------");
        // console.log("networkData ", this.kioskJson);
        // console.log("----------------------");
        this.resetFile();
        this.openPublishDialog.emit('File Uploaded Successfully !!!');
      } else {
        this.resetFile();
        this.openPublishDialog.emit('Unknown File Format !!!');
      }
    } else {
      this.resetFile();
      this.openPublishDialog.emit('Unknown File Format !!!');
    }
  })
  deleteChit(e) {
    this.chitFormState = e.checked;
    this.broadcastInfo({ src: 'sidepanel', event: 'chitDeleteStatus', data: this.chitFormState });
  }
  toggleKioskName(e) {
    this.toggleKioskState = e.checked;
    this.broadcastInfo({ src: 'sidepanel', event: 'kioskName', data: this.toggleKioskState });
  }
  toggleFMFMode(e) {
    this.toggleFMFState = e.checked;
    this.broadcastInfo({ src: 'sidepanel', event: 'FMF_mode', data: this.toggleFMFState });
  }
  toggleKioskStatus(data, i) {
    var kioskData = { 'data': data, 'index': i }
    this.broadcastInfo({ src: 'sidepanel', event: 'kioskStatus', data: kioskData, key: "" });
  }
  toggleCautionMode(e) {
    this.toggleCautionState = !this.toggleCautionState;
    this.broadcastInfo({ src: 'sidepanel', event: 'cautionarea_mode', data: this.toggleCautionState });
  }
  toggleKioskColor(e) {
    this.toggleKioskColorState = e.checked;
    this.broadcastInfo({ src: 'sidepanel', event: 'kioskColor', data: this.toggleKioskColorState, key: "" });
  }
  showHideRHIndi($event, id, p = "") {
    let rhdata = this["rhdata" + p];
    let state = $event.checked;
    if (!state && rhdata[id].wfstate) {
      rhdata[id].wfstate = false;
    }
    if(this.ship_2_name==='AOPS'&&p=='_2'){
      p='_3'
    }
    this.broadcastInfo({ src: 'sidepanel', event: 'rhIndi', data: { state, id }, key: p });
  }
  showHideRHAll($event, p = '') {
    let state = $event.checked;
    if(this.ship_2_name==='AOPS'&&p=='_2'){
      p='_3'
    }
    this.broadcastInfo({ src: 'sidepanel', event: 'rhshowall', data: { state }, key: p });
  }
  showHideRHIndi_wf($event, id, p = "") {
    let rhdata = this["rhdata" + p];
    rhdata[id].wfstate = !rhdata[id].wfstate;
    let state = rhdata[id].wfstate;
    if(this.ship_2_name==='AOPS'&&p=='_2'){
      p='_3'
    }
    this.broadcastInfo({ src: 'sidepanel', event: 'rhIndi_wf', data: { state, id }, key: p });
  }
  showHideRHComp($event, id, p = "") {
    let rhdata = this["rhdata" + p];
    let state = !rhdata[id].state;
    if(this.ship_2_name==='AOPS'&&p=='_2'){
      p='_3'
    }
    this.broadcastInfo({ src: 'sidepanel', event: 'rhComp', data: { state, id }, key: p });
  }
  /**man-aloft */
  showHideMAIndi($event, id, p = "") {
    let rhdata = this["aloftdata" + p];
    let state = $event.checked;
    if (!state && rhdata[id].wfstate) {
      rhdata[id].wfstate = false;
    }
    if(this.ship_2_name==='AOPS'&&p=='_2'){
      p='_3'
    }
    this.broadcastInfo({ src: 'sidepanel', event: 'maIndi', data: { state, id }, key: p });
  }
  showHideMAAll($event, p = '') {
    let state = $event.checked;
    this.broadcastInfo({ src: 'sidepanel', event: 'mashowall', data: { state }, key: p });
  }
  showHideMAComp($event, id, p = "") {
    let rhdata = this["aloftdata" + p];
    let state = !rhdata[id].state;
    this.broadcastInfo({ src: 'sidepanel', event: 'maComp', data: { state, id }, key: p });
  }
  activeCone(event, obj) {
    if (event.target.classList.contains('info_icon') || event.target.classList.contains('delete_icon') || event.target.classList.contains('edit_icon')) {
      return
    } else {
      this.activeConeElem.emit(obj);
    }
  }
  getActiveCone(pid) {
    if (this.selectedConeElem) {
      return (this.selectedConeElem._id === pid ? true : false);
    } else {
      return false;
    }
  }
  getHazardName(activity) {
    let item = this.hazardTypeList.find(el => el.key === activity);
    if (item) {
      return item['name'];
    } else {
      return '-';
    }
  }
  /* * * * *
  * Search Filter data
  * * * * * */
  searchHazardData() {
    var filterData = [];
    if (this.coneSearch === '') {
      filterData = null;
      this.coneUpdatedData = this.coneList;
    } else {
      if (this.coneUpdatedData.length > 0) {
        for (let i = 0; i < this.coneUpdatedData.length; i++) {
          if (this.hazardFilterKey.length > 0) {
            this.hazardFilterKey.forEach((key) => {
              if (typeof this.coneUpdatedData[i][key] === 'string' && typeof this.coneSearch === 'string') {
                if (this.coneUpdatedData[i][key].toLowerCase().indexOf(this.coneSearch.toLowerCase()) > -1) {
                  const found = filterData.some(el => el._id === this.coneUpdatedData[i]._id);
                  if (!found) {
                    filterData.push(this.coneUpdatedData[i]);
                  }
                }
              }
            });
          } else {
            if (this.coneUpdatedData[i].ship.toLowerCase().indexOf(this.coneSearch.toLowerCase()) > -1) {
              filterData.push(this.coneUpdatedData[i]);
            }
          }
        }
      }
      this.coneUpdatedData = filterData;
    }
  }
  /**
  * clear search
  **/
  searchConeClear() {
    this.coneSearch = "";
    this.coneUpdatedData = this.coneList;
  }
  /**
  * clear search
  **/
  searchKioskClear(e) {
    var clear = false;
    if (e == 'jetty') {
      this.jettySearch = '';
      if (this.kioskSearch != '') {
        clear = true;
        this.searchKioskData('kiosk');
      }
      if (this.locationSearch != '') {
        clear = true;
        this.searchKioskData('location1');
      }
    } else if (e == 'location1') {

      this.locationSearch = '';
      if (this.kioskSearch != '') {
        clear = true;
        this.searchKioskData('kiosk');
      }
      if (this.jettySearch != '') {
        clear = true;
        this.searchKioskData('jetty');
      }
    } else if (e == 'kiosk') {
      this.kioskSearch = '';
      if (this.locationSearch != '') {
        clear = true;
        this.searchKioskData('location1');
      }
      if (this.jettySearch != '') {
        clear = true;
        this.searchKioskData('jetty');
      }
    }
    if (!clear) {
      var cloneKiosk = JSON.parse(JSON.stringify(this.kioskFormatData.formatKioskJson))
      this.kisokElementArray = cloneKiosk;
      // this.kisokElementArray.jettyList = this.kioskFormatData.formatKioskJson.jettyList;
    }
  }

  searchKioskData(e) {
    var filterArr;
    var sourceData = JSON.parse(JSON.stringify(this.kioskFormatData.formatKioskJson.jettyList));
    if (this.kioskFormatData.formatKioskJson.jettyList.length > 0) {
      if (this.jettySearch === '' && this.locationSearch === '' && this.kioskSearch === '') {
        this.kisokElementArray.jettyList = sourceData;
      } else {
        if (this.jettySearch !== '') {
          filterArr = this.kioskJettySearch('jetty', sourceData);
          if (this.locationSearch !== '') {
            filterArr = this.kioskLocationSearch('location1', filterArr);
            if (this.kioskSearch != '') {
              filterArr = this.kioskNameSearch('kiosk', filterArr);
            }
          } else {
            if (this.kioskSearch != '') {
              filterArr = this.kioskNameSearch('kiosk', filterArr);
            }
          }
        } else {
          if (this.locationSearch !== '') {
            filterArr = this.kioskLocationSearch('location1', sourceData);
            if (this.kioskSearch != '') {
              filterArr = this.kioskNameSearch('kiosk', filterArr);
            }
          } else {
            if (this.kioskSearch != '') {
              filterArr = this.kioskNameSearch('kiosk', sourceData);
            }
          }
        }
        this.kisokElementArray.jettyList = filterArr;
      }

    }

  }
  kioskJettySearch(e, source) {
    var filterData = [];
    if (this.jettySearch === '') {
      filterData = null;
    } else {
      source.forEach(element => {
        if (typeof element[e] === 'string' && typeof this.jettySearch === 'string') {
          if (element[e].toLowerCase().indexOf(this.jettySearch.toLowerCase()) > -1) {
            const found = filterData.some(el => el[e] === element[e]);
            if (!found) {
              filterData.push(element);
            }
          }
        }
      });
      return filterData;
    }
  }
  kioskLocationSearch(e, source) {
    var filterData = [];
    if (this.locationSearch === '') {
      filterData = null;
    } else {
      source.forEach(element => {
        if (element && element[e]) {
          element[e].forEach(item => {
            if (typeof item[e] === 'string' && typeof this.locationSearch === 'string') {
              if (item[e].toLowerCase().indexOf(this.locationSearch.toLowerCase()) > -1) {
                const found = filterData.some(el => el[e] === element[e]);
                if (!found) {
                  element[e] = []
                  filterData.push(element);
                }
                if (filterData.length > 0) {
                  filterData.forEach((ele, i) => {
                    if (ele['jetty'] === element['jetty']) {
                      const found = ele[e].some(el => el[e] === item[e]);
                      if (!found) {
                        filterData[i][e].push(item);
                      }
                    }
                  })
                }
              }
            }
          })
        }
      });
      return filterData;
    }
  }
  kioskNameSearch(e, source) {
    var filterData = [];
    // var sourceData = this.kioskFormatData.jettyList;
    if (this.kioskSearch === '') {
      filterData = null;
    } else {
      source.forEach((element, jIdx) => {
        if (element && element['location1']) {
          element['location1'].forEach((item, locIdx) => {
            if (item && item[e]) {
              item[e].forEach((item1, kioskIdx) => {
                const found = filterData.some(el => el['jetty'] === element['jetty']);
                if (!found) {
                  if (element['location1']) {
                    element['location1'].forEach((el, i) => {
                      if (el['kiosk']) {
                        element['location1'][i]['kiosk'] = [];
                      }
                    });
                  }
                  filterData.push(element);
                }
                if (typeof item1[e] === 'string' && typeof this.kioskSearch === 'string') {
                  if (item1[e].toLowerCase().indexOf(this.kioskSearch.toLowerCase()) > -1) {
                    if (filterData.length > 0) {
                      filterData.forEach((ele, i) => {
                        if (ele['jetty'] === element['jetty']) {
                          if (ele['location1'].length > 0) {
                            ele['location1'].forEach((elem, idx) => {
                              if (ele['location1'] === element['location1']) {
                                const found = elem[e].some(el => el[e] === item1[e]);
                                if (!found) {
                                  filterData[i]['location1'][idx]['kiosk'].push(item1);
                                }
                              }
                            });
                          }

                        }
                      })
                    }
                  }
                }
              })
            }
          })
        }
      });
      // this.kisokElementArray.jettyList = filterData;
      return filterData;
    }
  }
  // Laydown

  getActiveLaydown(id) {
    if (this.parentLaydownListIdx) {
      return (this.parentLaydownListIdx._id === id ? true : false);
    } else {
      return false;
    }
  }
  setLaydownIndex(event, index: number) {
    if (event.target.classList.contains('info_icon')) {
      return
    }
    this.parentLaydownListIdx = index;
  }

  getLaydownColor(color) {
    let col = ''
    if (color) {
      // this.coneColors.forEach(elem => {
      //   if (color === elem.material) {
      //     col = elem.code;
      //   }
      // });
      return col;
    } else {
      return '';
    }
  }

  getLaydownListData() {
    this.configService.getLaydownData()
      .subscribe(
        (response) => {
          console.log('response received')
          var repos = response;
          var laydownremove = ['C1-001', 'C1-002', 'C1-004', 'C2-012', 'G2_402', 'G2_403', 'G2_405', 'C2-013', 'G7_401']
          laydownremove.forEach(element => {
            let id = repos.findIndex(e => e._id.trim() == element.trim())
            if (id > -1) {
              repos.splice(id, 1);
            }
          });
          console.log("datat2222 : ", repos);
          repos = repos.map(v => ({ ...v, expand: false }))
          this.laydownDataRes = repos;
          this.laydownData = this.laydownDataRes;
          var object = Object.keys(repos[0])
          // this.getLaydownFilterKey(this.laydownData)

          var objKeys = ['C-JettyArea']
          // objKey.forEach(element => {
          //   let keys = repos.findIndex(e => e.keys == element)
          //   if (keys > -1) {
          //     delete repos[0].Object.firstname(keys, 1);
          //     console.log("key remove :", repos[0])
          //   }
          // });

          // removeItem(id){
          delete repos[0].TimeModified
          delete repos[0].DateModified

          this.filterLaydownObjectKeys = Object.keys(repos[0])
          console.log("key", object);
          this.getLaydownFilterKey(this.laydownData[0]);
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
  }

  laydownFilterToggle() {
    this.filterLaydownHandleToggleOpen = !this.filterLaydownHandleToggleOpen;
    // if (window.innerWidth < 541) {
    //   this.ToggleOpenHanddle()
    //   this.ToggleServiceOpenHanddle()
    //   this.ToggleLaydownOpenHanddle()
    // }
  }
  hazardFilterToggle() {
    this.filterHazardHandleToggleOpen = !this.filterHazardHandleToggleOpen
  }
  kioskFilterToggle() {
    this.filterKioskHandleToggleOpen = !this.filterKioskHandleToggleOpen
  }
  // filter option
  availableHazardList(key) {
    var state = true;
    this.coneList.forEach(element => {
      if (element.activity == key.name.toLowerCase()) {
        state = false;
      }
    });
    return state;
  }
  filterHazard() {
    var hazard = [];
    this.coneUpdatedData = [];
    this.coneList.forEach(element => {
      let idx = this.hazardTypeList.findIndex(e => e.name.toLowerCase() == element.activity);
      if (idx > -1) {
        if (this.hazardTypeList[idx].filter) {
          hazard.push(element);
        }
      }
    });
    this.coneUpdatedData = hazard;
  }
  /* * *
  * on change hazard
  * * */
  onChangeHazard(event, key, i) {
    this.hazardTypeList[i].filter = event.checked;
    this.filterHazard();
  }
  hazardStartDate: any;
  hazardEndDate: any;
  dateHazardRangeChange() {
    var hazard = [];

    var startDate = new Date(this.hazardStartDate);
    var endDate = new Date(this.hazardEndDate);
    if (this.hazardStartDate && this.hazardEndDate) {
      this.coneUpdatedData = [];
      this.coneList.forEach(element => {
        // this.getDateRange(element.startDate,element.endDate).then((res) =>{
        var res = this.getDateRange(element.startdate, element.enddate);
        if (res.length > 0) {
          res.forEach(ele => {
            ele = new Date(ele);
            if (endDate >= ele && startDate <= ele) {
              if (hazard.length > 0) {
                let idx = hazard.findIndex(e => e._id == element._id);
                if (idx == -1) {
                  hazard.push(element);
                }
              } else {
                hazard.push(element);
              }
            }
          });

        }
        // })
      });
      this.coneUpdatedData = hazard;
    }

  }

  //  select all

  filterLaydownSelectAll(checked) {
    this.isFilterAll = checked;
    if (checked) {
      this.filterLaydownKeys = this.filterLaydownObjectKeys;
    } else {
      this.filterLaydownKeys = [];
    }
    if (!this.unknownLocationState) {
      this.searchClear();
    }
  }
  // get Laydown Filter Active Statu

  getLaydownFilterActiveStatus(key) {
    let found = this.filterLaydownKeys.some(el => el === key);
    return found;
  }


  //  on change filter

  onLaydownFilterChange(event, key) {

    if (event.checked) {
      this.filterLaydownKeys.push(key);
      if (this.filterLaydownObjectKeys.length === this.filterLaydownKeys.length) {
        this.isFilterAll = true;
      }
    } else {
      let idx = this.filterLaydownKeys.findIndex(item => item == key);
      if (idx > -1) {
        this.filterLaydownKeys.splice(idx, 1);
        this.isFilterAll = false;
      }
      this.getLaydownFilterKeyValue(this.laydownData[0]);
    }
    this.searchClear();
  }

  getLaydownFilterKeyValue(laydownData) {
    this.filterLaydownObjectKeys = [];
    this.filterLaydownObjectKeys = Object.keys(laydownData);
  }

  getLaydownFilterKey(laydownData) {
    this.filterLaydownObjectKeys = [];
    this.filterLaydownObjectKeys = Object.keys(laydownData);
    this.filterLaydownKeys = this.filterLaydownObjectKeys;
  }

  // Sort by option
  sortItemLaydown: any = '';
  onChangeLaydownSortItem(event, key) {
    this.sortItemLaydown = key;
    // if (!event.checked) {
    //   event.source._checked = true;
    // }
    this.sortByShiplistdata(key);
  }


  sortByShiplistdata(e) {
    this.sortItem = e;

    let direction = 1;
    this.laydownData.sort((a, b) => {
      console.log("this.laydownData", this.laydownData)
      // console.log(a[e] < b[e], a[e] > b[e], a[e], b[e])
      if (e == 'startdate' || e == 'enddate') {
        let aDate = new Date(a[e]);
        let bDate = new Date(b[e])
        if (aDate < bDate) {
          return -1 * direction;
        }
        else if (aDate > bDate) {
          return 1 * direction;
        }
        else {
          return 0;
        }
      } else {
        if (a[e] < b[e]) {
          return -1 * direction;
        }
        else if (a[e] > b[e]) {
          return 1 * direction;
        }
        else {
          return 0;
        }
      }
    });

    // if (this.activeShipList) {
    //   let idx = this.shipUpdatedData.findIndex(e => e.name == this.activeShipList.name)
    //   if (idx > -1) {
    //     this.parentCatListIdx = idx;
    //   }
    // }
  }

  searchLaydownData() {
    var filterData = [];
    var filterKey = ['_id']
    this.laydownDataSearch = this.laydownDataRes;
    if (this.laydownSearch === '') {
      filterData = null;
      this.laydownDataSearch = this.laydownDataRes;
      this.laydownData = this.laydownDataSearch;
    } else {
      if (this.laydownDataSearch.length > 0) {
        for (let i = 0; i < this.laydownDataSearch.length; i++) {
          if (this.filterLaydownKeys.length > 0) {
            this.filterLaydownKeys.forEach((key) => {
              if (typeof this.laydownDataSearch[i][key] === 'string' && typeof this.laydownSearch === 'string') {
                if (this.laydownDataSearch[i][key].toLowerCase().indexOf(this.laydownSearch.toLowerCase()) > -1) {
                  const found = filterData.some(el => el._id === this.laydownDataSearch[i]._id);
                  if (!found) {
                    filterData.push(this.laydownDataSearch[i]);
                  }
                }
              }
            });
          } else {
            if (this.laydownDataSearch[i].ship.toLowerCase().indexOf(this.laydownSearch.toLowerCase()) > -1) {
              filterData.push(this.laydownDataSearch[i]);
            }
          }
        }
      }
      this.laydownData = filterData;
    }
  }
  //  clear search
  searchLaydownClear() {
    this.laydownSearch = "";
    this.laydownData = this.laydownDataRes;
  }
  laydownExtentIdx: any;
  laydownExtent(i) {
    if (this.laydownExtentIdx == undefined) {
      this.laydownExtentIdx = i;
    } else {
      if (this.laydownExtentIdx == i) {
        delete this.laydownExtentIdx
      } else {
        this.laydownExtentIdx = i;
      }
    }
  }
  checkActiveLaydown(event, i, laydown) {
    if (event.target.classList.contains('info_icon')) {
      return
    }
    console.log("datest : ", laydown._id)
    var laydownDataPointer = this.laydownDataTag.filter(laydownData => laydownData._id === laydown._id)
    console.log("laydownDataPointer Data", laydownDataPointer[0])
    this.broadcastInfo({ src: 'sidepanel', event: 'selectedLaydown', data: laydownDataPointer[0], key: "" });
  }

  serviceDetailToggle(e) {
    this.activeDetailSection = e;
    // console.log(document.getElementById('ToggleEvent'+e));
    this.selectedIndex = 1;

    // var data = this.selectedIndex;
    // data =   this.filterFirstAidHandleToggleOpen = !this.filterFirstAidHandleToggleOpen;
    // this.filterLaydownHandleToggleOpen = false;
    // setTimeout(() => this.selectedIndex =  this.selectedIndex.length-1, -1)
  }
  serviceToggle(e, key) {
    // this.activeDetailSection = e;
    // console.log(document.getElementById('ToggleEvent'+key));
  }

  WFMTabChange(e) {
    console.log("1111111", e);
    this.selectedIndex = e.index;
    this.WFMTabSelected = e.tab.textLabel;
    this.filterFirstAidHandleToggleOpen = false;
    this.filterLaydownHandleToggleOpen = false;
    this.filterHazardHandleToggleOpen = false;
    this.filterKioskHandleToggleOpen = false;
    // setTimeout(() => this.selectedIndex =  this.selectedIndex.length+1, 1)
  }
  editHazard(data) {
    this.broadcastInfo({ src: 'sidepanel', event: 'hazardEdit', data: data, key: "" });
  }
  deleteHazard(data) {
    this.broadcastInfo({ src: 'sidepanel', event: 'hazardDelete', data: data, key: "" });
  }
  activeFirstAidIndex: any
  activeFirstAid(event, i, item) {
    console.log("data-- ", item)
    if (event.target.classList.contains('info_icon') || event.target.classList.contains('edit_icon')) {
      return
    }
    if (this.toggleFMFState) {
      if (this.activeFirstAidIndex !== i) {
        this.activeFirstAidIndex = i;
        this.broadcastInfo({ src: 'sidepanel', event: 'selectedFirstAid', data: item, key: "" });
      } else {
        this.activeFirstAidIndex = null;
        this.broadcastInfo({ src: 'sidepanel', event: 'selectedFirstAid', data: item, key: "" });
      }
    } else {
      this.broadcastInfo({ src: 'sidepanel', event: 'roofConfirm', data: item, key: i });
    }
  }

  // firstAidExtentIdx: any;
  firstAidExtent(i) {
    this._fistAidJson[i].expand = !this._fistAidJson[i].expand;
    // if (this.firstAidExtentIdx == undefined) {
    //   this.firstAidExtentIdx = i;
    // } else {
    //   if (this.firstAidExtentIdx == i) {
    //     delete this.firstAidExtentIdx
    //   } else {
    //     this.firstAidExtentIdx = i;
    //   }
    // }
  }

  searchFirstAidData() {
    var filterData = [];
    var filterKey = ['serial']
    this.firstAidDataSearch = this.firstAidUpdateData;
    if (this.firstAidSearch === '') {
      filterData = null;
      this.firstAidDataSearch = this.firstAidUpdateData;
      this._fistAidJson = this.firstAidDataSearch;
    } else {
      if (this.firstAidDataSearch.length > 0) {
        for (let i = 0; i < this.firstAidDataSearch.length; i++) {
          if (this.filterFirstAidnKeys.length > 0) {
            this.filterFirstAidnKeys.forEach((key) => {
              if (typeof this.firstAidDataSearch[i][key] === 'string' && typeof this.firstAidSearch === 'string') {
                if (this.firstAidDataSearch[i][key].toLowerCase().indexOf(this.firstAidSearch.toLowerCase()) > -1) {
                  const found = filterData.some(el => el._id === this.firstAidDataSearch[i]._id);
                  if (!found) {
                    filterData.push(this.firstAidDataSearch[i]);
                  }
                }
              }
            });
          } else {
            if (this.firstAidDataSearch[i].ship.toLowerCase().indexOf(this.firstAidSearch.toLowerCase()) > -1) {
              filterData.push(this.firstAidDataSearch[i]);
            }
          }
        }
      }
      return filterData;
    }
  }


  statusUpdate(v) {
    // console.log(v);
    if (v.length > 0) {
      v.forEach((element, i) => {
        // console.log(element)
        var date = new Date();
        // var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var month = new Date(date.setDate(date.getDate() + 30));
        var battery_exp = new Date(element.battery_exp);
        var spare_exp = new Date(element.spare_exp);
        var pad_exp = new Date(element.pad_exp);
        var redStatus = false;
        var yellowStatus = false;

        if (battery_exp < date || spare_exp < date || pad_exp < date) {
          redStatus = true;
          this._fistAidJson[i].color = 'red';
        }

        if ((month >= battery_exp && battery_exp >= date) || (month >= spare_exp && spare_exp >= date) || (month >= pad_exp && pad_exp >= date)) {
          yellowStatus = true;
          this._fistAidJson[i].color = 'yellow';
        }
        if (!redStatus && !yellowStatus) {
          this._fistAidJson[i].color = 'green';
        }

        // console.log(element);
        // console.log("battery_exp", battery_exp, "spare_exp", spare_exp, "pad_exp", pad_exp, "date", date, "month", month)
        // console.log(this._fistAidJson[i].color, "red", redStatus, "yellow", yellowStatus)
      });
    }
  }
  aedStartDate: any;
  aedEndDate: any;
  aedDateRange() {
    this.aedExpRange = '';
    var filterData = [];
    if (this.aedStartDate && this.aedEndDate) {
      var startDate = new Date(this.aedStartDate);
      var endDate = new Date(this.aedEndDate);
      this.firstAidUpdateData.forEach(element => {
        var battery_exp = new Date(element.battery_exp);
        var spare_exp = new Date(element.spare_exp);
        var pad_exp = new Date(element.pad_exp);
        if ((endDate >= battery_exp && battery_exp >= startDate) || (endDate >= spare_exp && spare_exp >= startDate) || (endDate >= pad_exp && pad_exp >= startDate)) {
          filterData.push(element);
        }
      })
      this._fistAidJson = filterData;
    }
  }
  aedExpRangeCheck(e, status) {
    this.aedExpRange = status;
    if (e.checked == true) {
      if (status == 'all') {
        this.firstAidDataSearch = this.firstAidUpdateData;
        this._fistAidJson = this.firstAidDataSearch;
      } else {
        var filterData = [];
        if (status == 'expired') {
          this._fistAidJson.forEach(element => {
            if (element.color == 'red') {
              filterData.push(element);
            }
          });
        } else if (status == '3') {
          var date = new Date();
          var month = new Date(date.setDate(date.getDate() + 90));
          this._fistAidJson.forEach(element => {
            var battery_exp = new Date(element.battery_exp);
            var spare_exp = new Date(element.spare_exp);
            var pad_exp = new Date(element.pad_exp);
            if ((month >= battery_exp && battery_exp >= date) || (month >= spare_exp && spare_exp >= date) || (month >= pad_exp && pad_exp >= date)) {
              filterData.push(element);
            }
          })
        }
        this._fistAidJson = filterData;
      }
    } else {
      this.aedExpRange = 'all';
      this.firstAidDataSearch = this.firstAidUpdateData;
      this._fistAidJson = this.firstAidDataSearch;
    }


  }
  searchAedKeyList = [];
  firstAidGroupSearch: any = '';
  firstAidLocationSearch: any = '';
  firstAidBarcodeSearch: any = '';
  firstAidNotesSearch: any = '';
  // AED Search start
  // searchFirstAidDatas(e){

  // }
  //AED END
  //
  searchFirstAidClearItem(e) {

    if (e == 'group') {
      this.firstAidGroupSearch = '';
    } else if (e == 'location') {
      this.firstAidLocationSearch = '';
    } else if (e == 'barcode') {
      this.firstAidBarcodeSearch = '';
    } else if (e == 'notes') {
      this.firstAidNotesSearch = '';
    }
    this.searchFirstAidDatas(e);
  }
  searchKeyUpdate(key) {
    if (this.searchAedKeyList.length > 0) {
      let idx = this.searchAedKeyList.findIndex(e => e == key);
      if (idx == -1) {
        this.searchAedKeyList.push(key);
      }
    } else {
      this.searchAedKeyList.push(key)
    }
  }


  searchFirstAidDatas(filter) {
    if (this.firstAidGroupSearch == '' && this.firstAidLocationSearch == '' && this.firstAidBarcodeSearch == '' && this.firstAidNotesSearch == '') {
      this.firstAidDataSearch = this.firstAidUpdateData;
      this._fistAidJson = this.firstAidDataSearch;
    } else {
      if (this.firstAidGroupSearch == '') {
        let idx = this.searchAedKeyList.findIndex(e => e == 'group');
        if (idx > -1) {
          this.searchAedKeyList.splice(idx, 1);
        }
      } else {
        if (filter == 'group') {
          this.searchKeyUpdate(filter)
        }
      }
      if (this.firstAidLocationSearch == '') {
        let idx = this.searchAedKeyList.findIndex(e => e == 'location');
        if (idx > -1) {
          this.searchAedKeyList.splice(idx, 1);
        }
      } else {
        if (filter == 'location') {
          this.searchKeyUpdate(filter)
        }
      }
      if (this.firstAidBarcodeSearch == '') {
        let idx = this.searchAedKeyList.findIndex(e => e == 'barcode');
        if (idx > -1) {
          this.searchAedKeyList.splice(idx, 1);
        }
      } else {
        if (filter == 'barcode') {
          this.searchKeyUpdate(filter)
        }
      }
      if (this.firstAidNotesSearch == '') {
        let idx = this.searchAedKeyList.findIndex(e => e == 'notes');
        if (idx > -1) {
          this.searchAedKeyList.splice(idx, 1);
        }
      } else {
        if (filter == 'notes') {
          this.searchKeyUpdate(filter)
        }
      }
      var filterData;
      this.searchAedKeyList.forEach((element, i) => {
        if (i == 0) {
          let source = this.firstAidUpdateData;
          filterData = this.searchAedSearch(element, source);
        } else {
          filterData = this.searchAedSearch(element, filterData);
        }
      });
      this._fistAidJson = filterData;
    }
  }
  searchAedSearch(key, source) {
    var filterData = [];
    if (source.length > 0) {
      // if (this.searchAedKeyList.length > 0) {
      // this.searchAedKeyList.forEach((key) => {
      for (let i = 0; i < source.length; i++) {

        let searchData;
        searchData = key == 'group' ? this.firstAidGroupSearch : key == 'location' ? this.firstAidLocationSearch : key == 'barcode' ? this.firstAidBarcodeSearch : key == 'notes' ? this.firstAidNotesSearch : '';
        if (searchData != '') {
          if (typeof source[i][key] === 'string' && typeof searchData === 'string') {
            if (source[i][key].toLowerCase().indexOf(searchData.toLowerCase()) > -1) {
              const found = filterData.some(el => el._id === source[i]._id);
              if (!found) {
                filterData.push(source[i]);
              }
            }
          }
        }
      }
      // });
      return filterData;
    }
    // }

  }
  editFirstAid(e) {
    this.broadcastInfo({ src: 'main', event: 'first_Aid_data', data: e, key: "" });
    this.broadcastInfo({ src: 'sidepanel', event: 'aedEdit', data: true });
  }
  //  clear search
  searchFirstAidClear() {
    this.searchAedKeyList = [];
    this.firstAidGroupSearch = '';
    this.firstAidLocationSearch = '';
    this.firstAidBarcodeSearch = '';
    this.firstAidNotesSearch = '';
    delete this.aedEndDate;
    delete this.aedStartDate;
    this.aedExpRange = 'all';
    this._fistAidJson = this.firstAidUpdateData;

  }
  //  select all


  FirstAidFilterToggle() {
    this.filterFirstAidHandleToggleOpen = !this.filterFirstAidHandleToggleOpen;
  }

  //  select all

  filterFirstAidSelectAll(checked) {
    this.isFilterAll = checked;
    if (checked) {
      this.filterFirstAidnKeys = this.filterFirstAidObjectKeys;
    } else {
      this.filterFirstAidnKeys = [];
    }
    if (!this.unknownLocationState) {
      this.searchClear();
    }
  }
  // get Laydown Filter Active Statu
  getFirstAidFilterActiveStatus(key) {
    let found = this.filterFirstAidnKeys.some(el => el === key);
    return found;
  }


  //  on change filter

  onFirstAidFilterChange(event, key) {
    if (event.checked) {
      this.filterFirstAidnKeys.push(key);
      if (this.filterFirstAidObjectKeys.length === this.filterFirstAidnKeys.length) {
        this.isFilterAll = true;
      }
    } else {
      let idx = this.filterFirstAidnKeys.findIndex(item => item == key);
      if (idx > -1) {
        this.filterFirstAidnKeys.splice(idx, 1);
        this.isFilterAll = false;
      }
      this.getFirstAidFilterKeyValue(this._fistAidJson[0]);
    }
    this.searchClear();
  }

  getFirstAidFilterKeyValue(_fistAidJson) {
    this.filterFirstAidObjectKeys = [];
    this.filterFirstAidObjectKeys = Object.keys(_fistAidJson);
  }

  getFirstAidFilterKey(_fistAidJson) {
    this.filterFirstAidObjectKeys = [];
    this.filterFirstAidObjectKeys = Object.keys(_fistAidJson);
    this.filterFirstAidnKeys = this.filterFirstAidObjectKeys;
  }


  // Sort by option
  onChangeFirstAidSortItem(event, key) {
    // if (!event.checked) {
    //   event.source._checked = true;
    // }
    this.sortByFirstAidlistdata(key);
  }


  sortByFirstAidlistdata(e) {
    this.sortItemFirstaid = e;

    let direction = 1;
    this._fistAidJson.sort((a, b) => {
      // console.log(a[e] < b[e], a[e] > b[e], a[e], b[e])
      if (e == 'startdate' || e == 'enddate') {
        let aDate = new Date(a[e]);
        let bDate = new Date(b[e])
        if (aDate < bDate) {
          return -1 * direction;
        }
        else if (aDate > bDate) {
          return 1 * direction;
        }
        else {
          return 0;
        }
      } else {
        if (a[e] < b[e]) {
          return -1 * direction;
        }
        else if (a[e] > b[e]) {
          return 1 * direction;
        }
        else {
          return 0;
        }
      }
    });

  }


  reportWfm(e) {
    this.WFMHandleToggleOpen = false;
    this.FMFHandleToggleOpen = false;
    this.filterLaydownHandleToggleOpen = false;
    this.filterHazardHandleToggleOpen = false;
    this.filterKioskHandleToggleOpen = false;
    this.filterFirstAidHandleToggleOpen = false;
    var data = e == 'aed' ? this._fistAidJson : e == 'hazard' ? this.coneList : '';
    if (data != '') {
      this.broadcastInfo({ src: 'sidepanel', event: 'reportWfm', data: data, key: "wfmReport_" + e });
    }
  }
  reportDetailToggle(e) {
    this.reportHandleToggleOpen = false;
    this.activeReportSection = e;
    if (e == 'dvt') {
      e = 'preview';
      this.broadcastInfo({ src: 'sidepanel', event: 'reportWfm', data: '', key: "wfmReport_" + e });
    } else {
      this.reportWfm(e)
    }

  }
  getBGLabel(state) {
    let idx = this.statusList.findIndex(e => e.status == state);
    if (idx > -1) {
      return this.statusList[idx].color;
    } else {
      return "#f3f3f3";
    }
  }

  getMbsBGLabel(state) {
    let idx = this.mbsStatusList.findIndex(e => e.status == state);
    if (idx > -1) {
      return this.mbsStatusList[idx].color;
    }
  }
  shipDetailPanelClose() {
    this.handleShipDetailToggleOpen = !this.handleShipDetailToggleOpen;
    this.broadcastInfo({ src: 'sidepanel', event: 'updateDetailPanel', data: '', key: this.handleShipDetailToggleOpen });
  }
  openDoc(link, route = null, comp = null) {
    if (route) {
      let url = '../../assets/doc/' + comp + '_' + link.key + '_' + route + '.xlsx';
      window.open(url);
    } else {
      if (link != '') {
        if (link == 'ERN') {
          // this.broadcastInfo({ src: 'sidepanel', event: 'init_ern', data:'ern', key: "" });
          // setTimeout(() => {
          //   this.nscHandleToggleOpen=false;
          //   this.ernHandleToggleOpen=true;
          // }, 300);
          this.broadcastInfo({ src: 'sidepanel', event: 'scsViewModelWindow', data: 'ern', key: 'true', sub: 'ern' });
        } else {
          window.open(link);
        }
      }

    }
  }
  detailPanel(ship) {
    this.handleShipDetailToggleOpen = !this.handleShipDetailToggleOpen;
    this.broadcastInfo({ src: 'tag', event: 'updateDetailPanel', data: ship, key: this.handleShipDetailToggleOpen });
  }
  getPortListHazard(data, port) {
    let idx = data.findIndex(e => e.port == port);
    if (idx > -1) {
      return false
    } else {
      return true
    }
  }
  /**
   * checkForRadHaz
   */
  checkForRadHaz() {
    this.broadcastInfo({ src: 'sidepanel', event: 'checkforradhaz', data: '', key: '' });
  }
  riskMangState: any = false;
  riskMangToggle() {
    this.riskMangState = true;
    this.broadcastInfo({ src: 'sidepanel', event: 'riskMangState', data: this.riskMangState, key: "" });
  }
  clearRadHaz() {
    this.broadcastInfo({ src: 'sidepanel', event: 'clearradhaz', data: '', key: '' });
  }
  toggleMAVersion(e) {
    this.broadcastInfo({ src: 'sidepanel', event: 'togglemav', data: { state: e.checked }, key: '' });
  }
  loadShipComponent(key, nsc) {
    // console.log("---", key)
    // console.log("---", nsc)
    const scsViewModel = true
    this.broadcastInfo({ src: 'sidepanel', event: 'scsViewModelWindow', data: key, key: 'true', sub: nsc });
  }
  chitDeleteForm(chitId = '', item = { activity: '' }) {
    // console.log("chitId", chitId);
    // console.log("Ship Details", item);
    let data = {
      chitId: chitId,
      port: this.loadedPort,
      chit_id: chitId
    }
    this.broadcastInfo({ src: 'sidepanel', event: 'chitDeleteshipList', data: item });
    this.chitFormState = false;
  }

  expandDateList: any = [];
  expandGroupCard(i, item, date) {
    // this.shipUpdatedData[i].expand=!this.shipUpdatedData[i].expand;
    if (this.sortItem == 'startdate') {
      if (this.expandDateList.length > 0) {
        var idx = this.expandDateList.findIndex(e => e['date'].groupValue == date.groupValue && e['data'].name == item.name);
        if (idx == -1) {
          this.expandDateList.push({ 'date': date, 'data': item });
        } else {
          if (!this.shipUpdatedData[i].expand) {
            this.expandDateList.splice(idx, 1);
          }
        }
      } else {
        this.expandDateList.push({ 'date': date, 'data': item });
      }
    }
    console.log("this.expandDateList", this.expandDateList)
  }

  expandCheckDate(item, date) {
    if (this.sortItem == 'startdate') {
      if (this.expandDateList.length > 0) {
        var idx = this.expandDateList.findIndex(e => e['date'].groupValue == date.groupValue && e['data'].name == item.name);
        if (idx == -1) {
          return false;
        } else {
          return true;
        }
        // let idx = this.expandDateList.findIndex(e=>e['date'].groupValue==date.groupValue);
        // if(idx==-1){
        //   return false;
        // }else{
        //   var indx = this.expandDateList.findIndex(e=> e['data'].name==item.name);
        //   if(indx==-1){
        //   return false;
        // }else{
        //   return true
        // }
        // }
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  // createGroupPopUp(){
  //   this.groupWindow = true;
  //   this.groupStatusColor = this.getRandomColor()
  // }

  /**
   * Group code start
   */

  onChangeColor(item) {
    this.groupStatusColor = item.color;
  }
  onChangeColorClick(event) {
    console.log(event)
  }
  createGroupPopup() {
    this.broadcastInfo({ src: "sidepanel", event: "createGroup", data: "" })
  }
  editGroup(e) {
    this.broadcastInfo({ src: "sidepanel", event: "updateGroup", data: e })
  }
  groupWindowClose() {
    this.groupWindow = false;
    delete this.editGroupData;
    delete this.groupName, this.groupState, this.groupData;
  }
  selectedGroupDeleteData = {};
  OnDeleteGroup(i, group) {
    this.selectedGroupDeleteData = { i, group };
    this.broadcastInfo({ src: 'material', event: 'openDialogConfirmGroupDelete', data: "", key: true });
  }
  deleteGroupData() {
    if (Object.keys(this.selectedGroupDeleteData).length === 0) return;
    let idx = this.selectedGroupDeleteData['i'];
    let groupID = this.selectedGroupDeleteData['group']['_id'] || ''
    if (groupID) {
      if (groupID === this.activeGroupIndex) {
        delete this.activeGroupIndex;
        // this.broadcastInfo({ src: 'controlpanel', event: 'clear-group-window', data: "", key: true });
      }
      this.sidepanelGroupData.splice(idx, 1);
      this.broadcastInfo({ src: 'sidepanel', event: 'groupDelete', data: groupID, key: '' });
      this.selectedGroupDeleteData = {};
    }
  }
  onChange(item, event) {
    console.log(item)
    console.log()
    item.color = event;
    this.configService.updateGroup(item._id, item).then(reuslt => {
      // this.reConstructTagJson()
    })
    //this.khaConfig.hideHighlightSelection()
    this.broadcastInfo({ src: "shipDetail", event: "groupColorUpdated", data: item })
  }
  sortByGrouplist(e) {
    this.sortItem = e;
    let direction = 1;
    this.sidepanelGroupData.sort((a, b) => {
      if (e == 'startdate' || e == 'enddate') {
        let aDate = new Date(a[e]);
        let bDate = new Date(b[e])
        if (aDate < bDate) {
          return -1 * direction;
        }
        else if (aDate > bDate) {
          return 1 * direction;
        }
        else {
          return 0;
        }
      } else {
        if (a[e] < b[e]) {
          return -1 * direction;
        }
        else if (a[e] > b[e]) {
          return 1 * direction;
        }
        else {
          return 0;
        }
      }
    });

    if (this.activeShipList) {
      let idx = this.sidepanelGroupData.findIndex(e => e.id == this.activeShipList.id)
      if (idx > -1) {
        this.parentCatListIdx = idx;
      }
    }
  }
  // sortitem on change
  onChangeGroupSortItem(event, key) {
    this.sortByGrouplist(key);
  }
  // search fuction
  searchGroupData() {
    var filterData = [];
    if (this.searchGroup === '') {
      filterData = null;
      this.sidepanelGroupData = this.sidepanelGroupObject;
    } else {
      if (this.sidepanelGroupObject.length > 0) {
        for (let i = 0; i < this.sidepanelGroupObject.length; i++) {
          if (this.filterGroupKey.length > 0) {
            this.filterGroupKey.forEach((key) => {
              if (typeof this.sidepanelGroupObject[i][key] === 'string' && typeof this.searchGroup === 'string') {
                if (this.sidepanelGroupObject[i][key].toString().toLowerCase().indexOf(this.searchGroup.toLowerCase()) > -1) {
                  const found = filterData.some(el => el.group_id === this.sidepanelGroupObject[i]['group_id']);
                  if (!found) {
                    filterData.push(this.sidepanelGroupObject[i]);
                  }
                }
              }
            });
          }
          // else {
          //   if (this.sidepanelGroupObject[i].groupName.toLowerCase().indexOf(this.searchGroup.toLowerCase()) > -1) {
          //     filterData.push(this.sidepanelGroupObject[i]);
          //   }
          // }
        }
      }
      this.sidepanelGroupData = filterData;
    }
    // this.mappingCategory();

    this.sortByGrouplist(this.sortItem);
  }
  searchGroupClear() {
    this.searchGroup = "";
    this.sidepanelGroupData = this.sidepanelGroupObject;
    this.sortByGrouplist(this.sortItem);
    let filterdData = null;
    // this.mappingCategory();
  }


  /**
   * Status code start
   */

  createStatus() {
    this.broadcastInfo({ src: "sidepanel", event: "createStatus", data: "" })
  }
  // search fuction
  searchStatusData() {
    var filterData = [];
    if (this.searchStatus === '') {
      filterData = null;
      this.sidepanelStatusData = this.sidepanelStatusObject;
    } else {
      if (this.sidepanelStatusObject.length > 0) {
        for (let i = 0; i < this.sidepanelStatusObject.length; i++) {
          if (this.filterStatusKey.length > 0) {
            this.filterStatusKey.forEach((key) => {
              if (typeof this.sidepanelStatusObject[i][key] === 'string' && typeof this.searchStatus === 'string') {
                if (this.sidepanelStatusObject[i][key].toString().toLowerCase().indexOf(this.searchStatus.toLowerCase()) > -1) {
                  const found = filterData.some(el => el.group_id === this.sidepanelStatusObject[i]['group_id']);
                  if (!found) {
                    filterData.push(this.sidepanelStatusObject[i]);
                  }
                }
              }
            });
          }
        }
      }
      this.sidepanelStatusData = filterData;
    }
    this.sortByGrouplist(this.sortItem);
  }
  //  clear search

  searchStatusClear() {
    this.searchStatus = "";
    this.sidepanelStatusData = this.sidepanelStatusObject;
    this.sortByGrouplist(this.sortItem);
    let filterdData = null;
    // this.mappingCategory();
  }

  // sortitem on change
  onChangeStatusSortItem(event, key) {
    this.sortByStatuslist(key);
  }
  sortByStatuslist(e) {
    this.sortStatusItem = e;
    let direction = 1;
    this.sidepanelStatusData.sort((a, b) => {
      if (a[e] < b[e]) {
        return -1 * direction;
      }
      else if (a[e] > b[e]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  }
  editStatus(e) {
    this.broadcastInfo({ src: "sidepanel", event: "updateStatus", data: e })
  }
  selectedStatusDeleteData = {};
  OnDeleteStatus(i, item) {
    this.selectedStatusDeleteData = { i, item };
    this.broadcastInfo({ src: 'material', event: 'openDialogConfirmStatusDelete', data: "", key: true });
  }
  deleteStatusData() {
    this.broadcastInfo({ src: 'sidepanel', event: 'statusDelete', data: this.selectedStatusDeleteData, key: true });
  }
  onChangeStatusColor(item, event) {
    console.log(item)
    item.value = event;
    this.configService.updateStatus(item._id, item).then(data => {
      if (data) {
        console.log("Status updated ", data);
        this.broadcastInfo({ src: "sidepanel", event: "updateStatusColor", data: "statusReload" })
        this.broadcastInfo({ src: 'kys', event: 'updateStatusData', data: "reConstructTagJson" });
        this.broadcastInfo({ src: 'sidepanel', event: 'updateStatusColorData', data: "updateStatusColor" });
      }
    }).catch((error) => {
      console.log("error ", error);
    });
  }
  onChangeStatusColorClick(item) {
    this.StatusColorValue = item.value;
  }
  activeLayoutId:any;
  // selectedLayoutList:any;
  activeLayout( i, layout,item) {
    console.log(this.tagServ.showHideTagPointer)
    if(this.tagServ.showHideTagPointer.length != 0){
      this.tagServ.showHideTagPointer.forEach(ele => {
        this.sidepanelLayoutData[this.activeLayoutIndex].tagPoints.forEach(element => {
          if( ele == "layout_" + element.tagShapeId){
            element['isChecked'] = false
            this.tagServ.showHideTag(element,false)
          }
        });
      })

    }
    this.activeLayoutIndex=i;
    this.activeLayoutId = layout
    // if(this.sidepanelGroupTagpoint[this.activeCard]){

    //   delete this.activeCard
    //   delete this.activeLayoutId
    // }
    this.broadcastInfo({ src: 'sidepanel', event: 'updateCollectionData', data: item });
    this.broadcastInfo({ src: 'sidepanel', event: 'layoutActive', data:  this.sidepanelLayoutData[this.activeLayoutIndex],tagShow: this.sidepanelGroupTagpoint, key: true });
  }

  activeSingleLayout(event, idxCard, layout , layoutIdx ,tag = undefined,item){
    // console.log("data", idxCard, layout , layoutIdx ,tag = undefined,item)
    if(tag && !tag['isChecked']){
      tag["isChecked"] = true;
    } else {
      tag["isChecked"] = false;
    }
    this.activeLayoutId = layout;
    // if(idxCard != this.activeCard){
      this.tagServ.createMatTagObjectPoint(tag,undefined)
      this.activeCard = idxCard;
      this.activeTag =tag.tagShapeId
    // } else {
    //   this.tagServ.showHideTag(this.sidepanelGroupTagpoint[this.activeCard], true)
    //   delete this.activeCard
    //   this.activeTag =[]
    // }
    this.drawService.clearCutOuts();
    this.drawService.clearSections();
    // this.drawService.clearPointer();

    this.activeLayoutIndex = layoutIdx
    if(this.sidepanelLayoutData[layoutIdx].Marker.length != 0 && idxCard == this.activeCard){
       var marker = this.sidepanelLayoutData[layoutIdx].Marker.filter(marker => marker.markerId == tag.markerId);
       this.drawService.applyCutMarkersFromData(marker)
    }
    this.sidepanelStatusData = item.statusCollection
    this.broadcastInfo({ src: 'sidepanel', event: 'updateCollectionData', data: item });
    this.broadcastInfo({ src: 'sidepanel', event: 'layoutActive', data: this.sidepanelLayoutData[layoutIdx], tagShow: this.sidepanelGroupTagpoint, key: true, activeTag:this.activeTag });
  }
  layoutTagState: any = false;
  layoutTag(i) {
    this.layoutTagState = !this.layoutTagState;
    if(this.layoutTagState){
      if (this.sidepanelLayoutData.length > 0 && this.sidepanelLayoutData[i]) {
        if (this.sidepanelLayoutData[i]['tagPoints'].length > 0) {
          this.sidepanelLayoutData[i]['tagPoints'].forEach(element => {
            this.preTagPoint.push(element)
            element.tagShowState = this.layoutTagState;
            this.tagServ.createMatObjectPoint(element);
          });
        }
      }
    } else {
      this.preTagPoint = []
      if (this.sidepanelLayoutData[i]['tagPoints'].length > 0) {
        this.sidepanelLayoutData[i]['tagPoints'].forEach(element => {
          element.tagShowState = this.layoutTagState;
          this.tagServ.showLayoutTag(element, false);
        });
      }
    }
  }



  searchLayoutClear() {
    this.searchLayout = "";
    this.sidepanelGroupTagpoint = this.reconstrucuLayoutData;
    this.layoutJson = this.layoutJsonData;
    // this.sortByLayoutlist(this.sortlayoutItem);
    // console.log("layoutJson", this.layoutJson)
    // this.mappingCategory();
  }
    // search fuction

    searchDataLayout() {
      var layoutJson = [];
      // console.log("reconstrucuLayoutData", this.reconstrucuLayoutData)
      var filterData = [];
      if (this.searchLayout === '') {
        filterData = this.reconstrucuLayoutData;
        this.sidepanelGroupTagpoint = this.reconstrucuLayoutData;
      } else {
        if (this.reconstrucuLayoutData.length > 0) {
          for (let i = 0; i < this.reconstrucuLayoutData.length; i++) {
            if (this.filterLayoutKey.length > 0) {
              this.filterLayoutKey.forEach((key) => {
                if (typeof this.reconstrucuLayoutData[i][key] === 'string' && typeof this.searchLayout === 'string') {
                  if (this.reconstrucuLayoutData[i][key].toLowerCase().indexOf(this.searchLayout.toLowerCase()) > -1) {
                    const found = filterData.some(el => el['id'] === this.reconstrucuLayoutData[i]['id']);
                    if (!found) {
                      filterData.push(this.reconstrucuLayoutData[i]);
                    }
                  }
                }
              });
            } else {
              if (this.reconstrucuLayoutData[i].layoutName.toLowerCase().indexOf(this.searchLayout.toLowerCase()) > -1) {
                filterData.push(this.reconstrucuLayoutData[i]);
              }
            }
          }
        }
        // console.log("layoutJsondd", this.layoutJson)
        this.sidepanelGroupTagpoint = filterData;
      }
      layoutJson = []
      filterData.forEach(ele => {
        layoutJson.push({ 'layoutName': ele.layoutName, 'layoutId': ele.layoutId, isChecked: true });
      });
      var data = []
      layoutJson.forEach(el => {
        this.layoutJsonData.forEach(element => {
          if (el.layoutName == element.layoutName) {
            data.push(el);
          }
        });
      });
      //this.filterdataComponentapply(filterData)
      data = data.reduce((a, b) => {
        if (!a.find(t => t.layoutId === b.layoutId)) {
          a.push(b);
        }
        return a;
      }, []);

      console.log("filterData", filterData)
      this.layoutJson = data
      // console.log("layoutJsondd", layoutJson, this.layoutJson, "test",data)

      // this.reConstructTagJson()
      // this.mappingCategory();
      this.sortByLayoutlist(this.sortlayoutItem);
    }

    OnEditLayout(layout){
      this.broadcastInfo({ src: 'sidepanel', event: 'openDialogLayoutEdit', data: layout, key: true });
    }

    selectedLayoutDeleteData = {}
    OnDeleteLayout(i, layout) {
      var layoutIDx = this.sidepanelLayoutData.findIndex(obj => obj._id == layout)
      layout = this.sidepanelLayoutData[layoutIDx]
      this.selectedLayoutDeleteData = { i, layout };
      this.broadcastInfo({ src: 'material', event: 'openDialogConfirmLayoutDelete', data: "", key: true });
    }
    deleteLayoutData() {
      // console.log(this.selectedLayoutDeleteData)
      if (Object.keys(this.selectedLayoutDeleteData).length === 0) return;
      let idx = this.selectedLayoutDeleteData['i'] || -1;
      let layoutID = this.selectedLayoutDeleteData['layout']['_id'] || ''
      if (layoutID) {
        if (idx === this.activeLayoutIndex) {
          delete this.activeLayoutIndex;
          this.broadcastInfo({ src: 'sidepanel', event: 'clear-layout-window', data: "", key: true });
        }
        this.sidepanelLayoutData.splice(idx, 1);
        if(this.activeCard){
          this.tagServ.showHideTag(this.sidepanelGroupTagpoint[this.activeCard], false);
          this.tagServ.matTagClear();
        }
        // this.broadcastInfo({ src: 'controlpanel', event: 'delete-layout-data', data: this.selectedLayoutDeleteData['layout'], key: '' });
        this.deleteLayout(this.selectedLayoutDeleteData['layout']._id, this.selectedLayoutDeleteData['layout']);
        this.selectedLayoutDeleteData = {};
      }
    }
    tagNotesDelete: any;
    deleteNotesConfirm(item){
      console.log(item)
      this.tagNotesDelete = item;
      this.broadcastInfo({ src: 'material', event: 'openDialogConfirmNotesDelete', data: "", key: true });
    }
    deleteNotesData(){
      // this.broadcastInfo({ src: 'sidepanel', event: 'delete-notes-data', data: this.tagNotesDelete });
      if(this.tagNotesDelete){
        if (this.tagNotesDelete.tagComments&&this.tagNotesDelete.tagComments.typeCollection == 'Chits') {
          this.chitService.deleteChitActivity(this.tagNotesDelete.tagComments.typeCollectionId);
        }
        if (this.tagNotesDelete.tagComments&&this.tagNotesDelete.tagComments.typeCollection == 'Rounds') {
          this.chitService.deleteRounds(this.tagNotesDelete.tagComments.typeCollectionId);
        }
        if (this.tagNotesDelete.tagComments&&this.tagNotesDelete.tagComments._id) {
          this.configService.deleteNotes(this.tagNotesDelete.tagComments._id);
        }
        if (this.tagNotesDelete.tagShapeId) {
          this.configService.deleteTagShape(this.tagNotesDelete.tagShapeId);
        }
        if (this.tagNotesDelete.markerId) {
          this.configService.deleteMarker(this.tagNotesDelete.markerId);
        }
      }


      this.tagServ.showHideTag(this.tagNotesDelete, false);
      this.tagServ.matTagClear()
      delete this.activeTag
      if(this.activeLayoutIndex){
        this.broadcastInfo({ src: 'sidepanel', event: 'layoutActive', data:  this.sidepanelLayoutData[this.activeLayoutIndex], tagShow: this.sidepanelGroupTagpoint, key:true, activeTag:this.activeTag });
      }
     this.getLayoutData();
    }
    createLayout(){
      this.broadcastInfo({ src: 'sidepanel', event: 'createLayout', });
    }
    // typeAction(e, item,tagPoint = undefined){
    //   if(tagPoint){
    //    var idx =this.sidepanelLayoutData.findIndex(obj => obj._id == tagPoint.layoutId);
    //   }
    //   this.chitService.typeCollectionForm = '';
    //   this.broadcastInfo({ src: 'sidepanel', event: 'createCollectionTypeForm', data: e, key: item ,layout : this.sidepanelLayoutData[idx],tagPoint : tagPoint});
    // }
    // viewDataTypeDetails(e, id, item){
    //   this.chitService.typeCollectionForm = '';
    //   this.broadcastInfo({ src: 'sidepanel', event: 'viewCollectionTypeForm', data: e, key: id, key1: item });
    // }
    createCollectionFormActive(e, item){
      this.chitService.typeCollectionForm = 'createCollectionFormActive';
      this.broadcastInfo({ src: 'sidepanel', event: 'createCollectionTypeForm', data: e, key: item });
    }

    showCollection(event){
      this.showLayoutPanelOpen = event['checked'];
      this.broadcastInfo({ src: 'sidepanel', event: 'collectionToggle', data: event['checked']});
    }
    showGroup(event){
      this.showGroupPanelOpen = event['checked'];
      this.broadcastInfo({ src: 'sidepanel', event: 'groupToggle', data: event['checked']});
    }
    showStatus(event){
      this.showStatusPanelOpen = event['checked'];
      this.broadcastInfo({ src: 'sidepanel', event: 'statusToggle', data: event['checked']});
    }
    showStateboard(event){
      this.stateBoardView= event['checked']
      this.broadcastInfo({ src: 'sidepanel', event: 'stateBoardEnable', data: event['checked']});
    }

    shipDetailCodeUpdate(){
      if(this.layoutData.length>0){
        this.layoutData.forEach((col,colIDX)=>{
          console.log("layoutData",col)
          if(col['tagPoints'].length>0){
            col['tagPoints'].forEach((tag,tagIDX)=>{
              let str = tag.parentMeshId;
              var data= str.split('.');
              if(data.length>2){
                let code = data[data.length-1];
                this.layoutData[colIDX]['tagPoints'][tagIDX]['code']=code;
              }

            })
          }
        })
      }
    }

    // Api Collection
    layoutData: any = [];
    getLayoutData(data = undefined){
      this.configService.getLayoutData().then((response: any) => {
        this.layoutData = response.filter(e => e.port == this.loadedPort);
        console.log("getLayoutData-port ", this.layoutData);
        if(this.loadedPort=='slms'){
          this.shipDetailCodeUpdate();
        }
            this.sidepanelLayoutData = this.layoutData;
            this.tagData = data;
            // this.reConstructTagJson();

        this.broadcastInfo({ src: 'navyApp', event: 'layoutData', data: this.layoutData , createNotes : data });
        this.broadcastInfo({ src: 'navyApp', event: 'layoutPort', data: this.loadedPort });
      }).catch((error) => {
        console.log("error ", error)
      });
    }
    deleteLayout(_id, layout) {
      this.configService.deleteLayoutData(_id, {_id:layout['_id']}).then(data => {
        if (data) {
          console.log("Layout deleted ", data);
          this.getLayoutData();
        }
      }).catch((error) => {
        console.log("error ", error);
      });
    }
    getStatusData() {
      this.configService.getStatusData().then((response: any) => {
        console.log("getStatusData ", response);
        this.sidepanelStatusData = response;
        this.sidepanelStatusObject = response;
        this.broadcastInfo({ src: 'navyApp', event: 'updateStatus', data: response });
      }).catch((error) => {
        console.log("error ", error);
      });
    }
      getGroupData() {
      this.configService.getGroupListData().then((response: any) => {
        this.sidepanelGroupData = response;
        this.sidepanelGroupObject = response;
        this.broadcastInfo({ src: 'navyApp', event: 'updateGroup', data: response });
      }).catch((error) => {
        console.log("error ", error);
      });
    }
    // chitWindowState: any = false;
    scheduleWindowState: any = false;
    selectedTagItem: any;
    typeAction(e, item,tagPoint = undefined) {
      if (item != null) {
        this.selectedTagItem = item;
        // this.broadcastInfo({ src: 'sidepanel', event: 'selectedTagItem', data: item });
      }
      if (item == null) {
        delete this.selectedTagItem;
        this.broadcastInfo({ src: 'sidepanel', event: 'createTagoutDialog', data: "" });
      }
      if (e == 'Chits') {
        this.chitWindowState = true;
        setTimeout(() => {
          this.broadcastInfo({ src: 'sidepanel', event: 'dashboardChit', data: this.scheduleWindowState, key: "dashboard", value: e });
        }, 100)
      }
      if (e == 'Rounds') {
        this.scheduleWindowState = true;
        setTimeout(() => {
          this.broadcastInfo({ src: 'sidepanel', event: 'schedule', data: this.scheduleWindowState, key: "dashboard", value: e });
        }, 100);
      }
      if (e == 'Lockout/Tagout') {
        // this.tagoutWindowState = true;
        // setTimeout(() => {
        //   this.broadcastInfo({ src: 'dashboard', event: 'createTagoutDialog', data: true, key: "" });
        // }, 100);
      }
      if (e == 'Aid') {
        // var aidCreate = true;
        // this.broadcastInfo({ src: 'main', event: 'aidAreas', data: aidCreate });
      }
      if (e == 'HazardAreas') {
        // var harzardAreaCreate = true;
        // this.broadcastInfo({ src: 'main', event: 'hazardAreas', data: harzardAreaCreate });
        // console.log("Comming Soon")
      }
      if (e == 'Kiosks') {
        console.log("Comming Soon")
      }
      if (e == 'LaydownAreas') {
        console.log("Comming Soon")
      }
    }
    viewDataTypeDetails(e, id, item){
      if (item != null) {
        this.selectedTagItem = item;
      }
      if (item == null) {
        delete this.selectedTagItem;
      }
      if (e == 'Chits') {
        this.chitWindowState = true;
        setTimeout(() => {
          this.broadcastInfo({ src: 'sidepanel', event: 'chitViewForm', data: id, key: "dashboard", });
        }, 50)
      }
      if (e == 'Rounds') {
        this.scheduleWindowState = true;
        this.broadcastInfo({ src: 'sidepanel', event: 'schedule', data: this.scheduleWindowState, key: "dashboard" });
      setTimeout(() => {
        this.broadcastInfo({ src: 'sidepanel', event: 'scheduleDashboardDataView', data: id, key: "", });
      }, 200)
      }
    }
    toggleDemoShips(event){
      this.ship_2_name=event.checked?"AOPS":"HMCS VANCOUVER";
      this.broadcastInfo({ src: 'sidepanel', event: 'toggle_demo_ships', data: this.ship_2_name, key: "" });
    }
}

