import { Component, OnInit,Input } from '@angular/core';
import { CommunicationService } from "../services/communication.service";
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-layout-card',
  templateUrl: './layout-card.component.html',
  styleUrls: ['./layout-card.component.scss'],
  providers: [DatePipe]
})

export class LayoutCardComponent implements OnInit {

  constructor(private datepipe: DatePipe,private communicationServ: CommunicationService) {}
  orderList:any=[1,2,3,4,5,6,7,8];
  broadcastSubs:any;
  layoutData:any=[];
  sidepanelLayoutData:any=[];
  sidepanelNotesData:any=[];
  sidepanelStatusObject:any=[];
  sidepanelStatusData:any=[];
  adminLayoutOrderList:any=[];
  activeTag:any;
  @Input() activeLayoutId:any;
  layoutAxis:any = [
    {left:"3",top:"2",right:"",bottom:""},
    {left:"3",top:"35",right:"",bottom:""},
    {left:"3",top:"",right:"",bottom:"3"},
    {left:"27",top:"",right:"",bottom:"3"},
    {left:"",top:"",right:"27",bottom:"3"},
    {left:"",top:"",right:"4",bottom:"3"},
    {left:"",top:"35",right:"4",bottom:"3"},
    {left:"",top:"2",right:"4",bottom:""}]// left = 0-100%, top = 0-100%, right = 0-100%, bottom = 0-100%

    selectedStateBoardData:any;
    orderListMode:any='view';
    userRoleFilter:any;
    stateboardList=[];
    sidepanelVirtualData = [];
  ngOnInit() {
    this.broadcastSubs = this.communicationServ.getInstance()
    .subscribe((data: any) => {
      if(data.src=="admin-SB"){
        if(data.event=="stateBoardList"){
          this.userRoleFilter = data['userRoleFilter'];
          this.stateboardList = data['data'];
        }
        
        if(data.event=="orderLayout"){
          this.orderListMode=data['mode'];
          this.cardLoader=false;
          this.adminLayoutOrderList=[];
          let adminSB=data['data'];
          console.log("adminLayoutOrderList", adminSB)
          if(adminSB){
            this.selectedStateBoardData = adminSB;
            if(adminSB['layoutList'].length>0){
              adminSB['layoutList'].forEach(element => {
                let idx = this.sidepanelLayoutData.findIndex(e=> e['layoutId']==element['_id']);
                
                if(idx>-1){
                  this.adminLayoutOrderList.push(this.sidepanelLayoutData[idx]);
                  this.adminLayoutOrderList[this.adminLayoutOrderList.length-1]['objectState']=true;
                  this.adminLayoutOrderList[this.adminLayoutOrderList.length-1]['order']=element['order'];
                  this.adminLayoutOrderList[this.adminLayoutOrderList.length-1]['layoutName'] = element['name'];
                  if(this.layoutData.length>0){
                    let layoutIdx = this.layoutData.findIndex(e=> e['_id']==element['_id']);
                    if(layoutIdx>-1){
                      this.adminLayoutOrderList[this.adminLayoutOrderList.length-1]['notesLength']=this.layoutData[layoutIdx]['Notes']?this.layoutData[layoutIdx]['Notes'].length:0;
                    }
                  }
                }
              });
              // let result = this.sidepanelLayoutData.filter(o1 => adminSB['layoutList'].some(o2 => o1['layoutId'] === o2['_id']));
              console.log("adminLayoutOrderList",this.adminLayoutOrderList);
            }

            // this.adminLayoutOrderList=result;
            this.arrangeOrder();
          }

        }
      }
      if(data.src=="sidepanel"){
        if(data.event == "reconstruct"){
            this.sidepanelLayoutData = data['layout'].length > 0 ? data['layout'].filter((arr, index, self) =>
            index === self.findIndex((t) => (t.layoutId == arr.layoutId))) : [];  
            // this.orderList.forEach((ele,i) => {
            //   this.sidepanelLayoutData[i]['order']=ele;
            // });
            // this.arrangeOrder();
          console.log("reconstruct-layoutcard", this.sidepanelLayoutData, this.sidepanelVirtualData );
          this.sidepanelNotesData = data['notes'] ? data['notes'] : [];
          this.sidepanelVirtualData= data['virtualList'] ? data['virtualList'] : [];
        }
        if(data.event == "layoutActive"){
          console.log("layoutActive", data );
          this.activeTag = data['activeTag'];
          this.activeLayoutId = data['data']['_id'];
        }
      }  
      if(data.src=="navyApp")   {
        if (data.event === 'updateStatus') {
          this.sidepanelStatusData = data.data;
          this.sidepanelStatusObject = data.data;
          console.log("updateStatus", data.data);
        }
        // if(data.event == "deleteLayoutUpdate"){
        //   let id= data['data'];
        //   let idx = this.adminLayoutOrderList.findIndex(e=>)
        // }
        if (data.event === 'layoutData') {
          console.log("layout-card-data",data['data']);
          this.layoutData = data['data'];
        }
      }
    })
  }
  activeOrderData:any;
  activeOrder(item){
    console.log("activeOrder",item);
    this.activeOrderData = item
  }

  cardLoader:boolean=false;
  selectLayout(item){
    this.cardLoader=true;
    if(item=='none'){
      console.log("selectLayout",item);
      let data = {layout:"none",order:this.activeOrderData['order']};
      this.broadcastInfo({ src: 'dynamic-layout', event: 'addLayout', data:data, key: true });
    }else{
      console.log("selectLayout",item);
      let data = {layout:{id:item['_id'],layoutName:item['layoutName']},order:this.activeOrderData['order']};
      this.broadcastInfo({ src: 'dynamic-layout', event: 'addLayout', data:data, key: true });
    }

  }

  layoutStateboard(e) {
    this.selectedStateBoardData = e;
    this.broadcastInfo({ src: 'dynamic-layout', event: 'changeView', data: e, key: true });

  }

  adminEmptyOrderList:any=[];
arrangeOrder(){
  this.adminEmptyOrderList=[];
  // this.adminLayoutOrderList.forEach((element,i) => {
  //   if(element['order']){
  //     this.adminLayoutOrderList[i]['axis']=this.layoutAxis[element['order']-1];
  //   }else{

  //   }
  // });
  this.orderList.forEach((element,i) => {
    let idx =this.adminLayoutOrderList.findIndex(e=> e['order'] == element);
    if(idx>-1){
      this.adminLayoutOrderList[idx]['axis']=this.layoutAxis[element-1];
    }else{
      let emptyList = {order:element,axis:this.layoutAxis[element-1]};
      this.adminEmptyOrderList.push(emptyList);
    }
  })
}

checkLayoutList(layout){
  let layoutList= this.adminLayoutOrderList;
  var status =false;
  layoutList.forEach(e=>{
    if(e){
      if(e.layoutId==layout['_id']){
        status= true;
      }
    }
   
  })
  return status;
}


closeDynamicStateboard(){
  this.broadcastInfo({ src: 'dynamic-layout', event: 'closeStateboard', data:"", key: true });
  this.broadcastInfo({ src: 'dynamic-layout', event: 'closeStateboardViewIcon', data:"", key: true });
}
formStateBoard(data){
  this.broadcastInfo({ src: 'dynamic-layout', event: 'formStateboardView', data:data, key: true });
}
stateboardNameEdit(data){
  this.broadcastInfo({ src: 'dynamic-layout', event: 'editStateboardName', data:data, key: true });
}
/* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
    broadcastInfo(data: any) {
      this.communicationServ.getInstance().next(data);
    }
  
    filteredNotesList:any=[];
    selectedLayout:any;
    notesPanel:any=false;
      activeLayout(idx, layoutId, layout){
        // this.selectedLayout = layout;
        // this.notesPanel = true;
        // console.log(layout,this.sidepanelNotesData );
        // this.filteredNotesList = this.sidepanelNotesData.filter(e=>e.layoutId==layout.layoutId)
        // console.log("layout",this.filteredNotesList );
        // var data = {idx:idx,layoutId:layoutId,layout:layout}
        // console.log("typeAction",data);
        // this.broadcastInfo({ src: 'dynamic-layout', event: 'activeLayout', data:data, key: true });
      }
      getDate(date) {
        return this.datepipe.transform(date, "MMM d, y, h:mm a")
      }

      OnDeleteLayout(idx,id){
        var data ={index:idx,id:id}
        console.log("OnDeleteLayout",data);
        
        this.broadcastInfo({ src: 'dynamic-layout', event: 'deleteLayout', data:data, key: true });
      }
      deleteNotesConfirm(data){
        console.log("deleteNotesConfirm",data);
        this.broadcastInfo({ src: 'dynamic-layout', event: 'deleteNotesLayout', data:data, key: true });
      }
      statusChange(comm,layoutId, tagpoint,statusName){
        var data = {comments:comm,layoutId:layoutId,tagpoint:tagpoint,statusName:statusName}
        console.log("statusChange",data);
        this.broadcastInfo({ src: 'dynamic-layout', event: 'statusChange', data:data, key: true });
      }
      activeSingleLayout(event, notesIdx, layoutId,layoutIdx,tagPoint,layout){
        if (event.srcElement && (event.srcElement.classList.contains('chit_icon') || event.srcElement.id == "deleteNotes" || event.srcElement.id == "statusNotes" || event.srcElement.id == "viewTypeForm" || event.srcElement.id == "turnoverStatus")) {
          return;
        }
        var data = {event:event, notesIdx:notesIdx, layoutId:layoutId,layoutIdx:layoutIdx,tagPoint:tagPoint,layout:layout}
        console.log("activeSingleLayout",event,data);
        this.broadcastInfo({ src: 'dynamic-layout', event: 'activeSingleLayout', data:data, key: true });
      }
      createCollectionFormActive(type, layout){
        var data = {type:type,layout:layout}
        console.log("createCollectionFormActive",data);
        this.broadcastInfo({ src: 'dynamic-layout', event: 'createCollectionFormActive', data:data, key: true });
      }
      OnEditLayout(layout){
        console.log("OnEditLayout",layout);
        this.broadcastInfo({ src: 'dynamic-layout', event: 'OnEditLayout', data:layout, key: true });
      }
      layoutTag(idx){
        this.broadcastInfo({ src: 'dynamic-layout', event: 'layoutTag', data:idx, key: true });
      }
      typeAction(type, layout){
        var data = {type:type,layout:layout}
        console.log("typeAction",data);
        this.broadcastInfo({ src: 'dynamic-layout', event: 'typeAction', data:data, key: true });
      }
      viewDataTypeDetails(type,typeCollectionId,layout,tag){
        var data = {type:type,typeCollectionId:typeCollectionId,layout:layout,tagpoints:tag}
        console.log("viewDataTypeDetails",data);
        this.broadcastInfo({ src: 'dynamic-layout', event: 'viewDataTypeDetails', data:data, key: true });
      }
      viewDataTagoutDetails(type,typeCollectionId, layout){
        var data = {type:type,typeCollectionId:typeCollectionId,layout:layout}
        console.log("viewDataTagoutDetails",data);
        this.broadcastInfo({ src: 'dynamic-layout', event: 'viewTagoutDataTypeDetails', data:data, key: true });
      }
      stateboardChange(e){
        console.log("stateboardChange",e);
        this.broadcastInfo({ src: 'dynamic-layout', event: 'changeView', data:e, key: true });
      }

      getVSHeight(item) {
        let ht = '0px';
        // if (item.expand > -1) {
        let list = item.notesLength ? item.notesLength : [];
        if(list>0){
          let c = Math.min(list, 2);
            var screenDivide = (window.innerHeight/100)+1
          var itemScreen = window.innerHeight/screenDivide
            // ht = (c * itemScreen) + 'px';//62 is height of each item block in list
          var cardHeight = document.getElementById("layoutCard-h")?document.getElementById("layoutCard-h").offsetHeight-40:(c * itemScreen);
          // var screenDivide = (window.innerHeight/100)+1
          // var itemScreen = window.innerHeight/screenDivide
            ht = cardHeight + 'px';//62 is height of each item block in list
        }
        return ht;
      }

  showhideObject(coll,i){
    this.adminLayoutOrderList[i]['objectState']=!this.adminLayoutOrderList[i]['objectState'];
    console.log("showhideObject",coll,this.adminLayoutOrderList);
    this.broadcastInfo({ src: 'dynamic-layout', event: 'showhideObject', data:coll, key: this.adminLayoutOrderList[i]['objectState'] });
  }
  layoutElemId: any;
  viewNotesElemId: any;
  viewEyeElemId:any;
  viewAddElemId:any;
  viewEditElemId:any;
  hoverActionEnter(item,panel) {
    let elem;
    // if(this.activeActionElemId){
    //   // elem = document.getElementById(this.activeActionElemId).style.display='none';
    // }else{
    // this.activeActionElemId = 'actionNotes_' + item._id;
    if(panel=="layout"){
      this.viewEyeElemId = 'viewEye_' + item.layoutId;
      this.viewAddElemId = 'viewAdd_' + item.layoutId;
      this.viewEditElemId = 'viewEdit_' + item.layoutId;
      elem = document.getElementById(this.viewEyeElemId) ? document.getElementById(this.viewEyeElemId).style.visibility = 'visible' : '';
      elem = document.getElementById(this.viewAddElemId) ? document.getElementById(this.viewAddElemId).style.visibility = 'visible' : '';
      elem = document.getElementById(this.viewEditElemId) ? document.getElementById(this.viewEditElemId).style.visibility = 'visible' : '';
    }
    if(panel=="notes"){
      this.viewNotesElemId = 'viewNotes_' + item._id;
      elem = document.getElementById(this.viewNotesElemId) ? document.getElementById(this.viewNotesElemId).style.visibility = 'visible' : '';
    }
    // }

  }
  hoverActionLeave() {
    if (this.viewEyeElemId && document.getElementById(this.viewEyeElemId)) {
      document.getElementById(this.viewEyeElemId) ? document.getElementById(this.viewEyeElemId).style.visibility = 'hidden' : '';
      delete this.viewEyeElemId;
    }
    if (this.viewAddElemId && document.getElementById(this.viewAddElemId)) {
      document.getElementById(this.viewAddElemId) ? document.getElementById(this.viewAddElemId).style.visibility = 'hidden' : '';
      delete this.viewAddElemId;
    }
    if (this.viewNotesElemId && document.getElementById(this.viewNotesElemId)) {
      document.getElementById(this.viewNotesElemId) ? document.getElementById(this.viewNotesElemId).style.visibility = 'hidden' : '';
      delete this.viewNotesElemId;
    }
    if (this.viewEditElemId && document.getElementById(this.viewEditElemId)) {
      document.getElementById(this.viewEditElemId) ? document.getElementById(this.viewEditElemId).style.visibility = 'hidden' : '';
      delete this.viewEditElemId;
    }
  }
}
