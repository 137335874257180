import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommunicationService } from "../services/communication.service";
import { DatePipe } from '@angular/common';
import { ChitService } from '../services/chit.service';
import { ConfigService } from '../services/config.service';
import * as io from 'socket.io-client';
import { Router } from '@angular/router';

@Component({
  selector: 'app-chitapp',
  templateUrl: './chitapp.component.html',
  styleUrls: ['./chitapp.component.scss'],
  providers: [DatePipe]
})
export class ChitappComponent implements OnInit {
  @Input() shipVesselName: any = []
  // VesselName: any = []
  chitDataList: any = [];
  createChitForm: boolean = false;
  chitListView: boolean = false;
  chitView: boolean = false;
  createChitForm2: boolean = false;
  collision: boolean = false;
  approveError: boolean = false;
  chitForm: FormGroup;
  approveFormGroup!: FormGroup;
  comments: any;
  verified: '';
  verifiedData: any;
  socket: any;
  VesselName: any = [
    { 'id': '001', 'vessel': 'Ammunitioning', 'location': 'A1', 'onsiteName': 'john DoMateo', 'onsiteUnit': 'OC-001', 'shipStaff': 'Thomas', 'shop': 'department 1' },
    { 'id': '002', 'vessel': 'Bollard Pulls', 'location': 'A1,2', 'onsiteName': 'Hudson', 'onsiteUnit': 'OC-002', 'shipStaff': 'Mateo', 'shop': 'department 2' },
    { 'id': '003', 'vessel': 'Man-Aloft', 'location': 'A2', 'onsiteName': 'Gabriel', 'onsiteUnit': 'OC-003', 'shipStaff': 'Gabriel ', 'shop': 'department 3' },
    { 'id': '004', 'vessel': 'Antenna Rotating', 'location': 'A2,2', 'onsiteName': 'Mason', 'onsiteUnit': 'OC-004', 'shipStaff': 'Owen ', 'shop': 'department 4' },
    { 'id': '005', 'vessel': 'Ceremonial', 'location': 'B1', 'onsiteName': 'Luca', 'onsiteUnit': 'OC-005', 'shipStaff': 'Kayden', 'shop': 'department 5' },
    { 'id': '006', 'vessel': 'Cold Move', 'location': 'B1,2', 'onsiteName': 'Jayden', 'onsiteUnit': 'OC-006', 'shipStaff': 'Alexander ', 'shop': 'department 6' },
    { 'id': '007', 'vessel': 'Crane Work', 'location': 'B2', 'onsiteName': 'Jackson', 'onsiteUnit': 'OC-006', 'shipStaff': 'Jayden ', 'shop': 'department 6' },
    { 'id': '008', 'vessel': 'Diving Operations', 'location': 'B2,2', 'onsiteName': 'Liam', 'onsiteUnit': 'OC-006', 'shipStaff': 'Nathan', 'shop': 'department 6' },
    { 'id': '009', 'vessel': 'Engine Exhuasting', 'location': 'C1', 'onsiteName': 'Benjamin', 'onsiteUnit': 'OC-006', 'shipStaff': 'Connor', 'shop': 'department 6' },
    { 'id': '010', 'vessel': 'Fueling Operations', 'location': 'C1,2', 'onsiteName': 'Oliver', 'onsiteUnit': 'OC-006', 'shipStaff': 'Theodore', 'shop': 'department 6' },
    { 'id': '011', 'vessel': 'Jetty Service', 'location': 'C2', 'onsiteName': 'Ethan', 'onsiteUnit': 'OC-006', 'shipStaff': 'Isaac', 'shop': 'department 6' },
    { 'id': '012', 'vessel': 'Radiating', 'location': 'C2,2', 'onsiteName': 'Jacob', 'onsiteUnit': 'OC-006', 'shipStaff': 'Henry', 'shop': 'department 6' },
    { 'id': '013', 'vessel': 'Antenna Radiating', 'location': 'C3', 'onsiteName': 'Lucas', 'onsiteUnit': 'OC-007', 'shipStaff': 'Myles', 'shop': 'department 7' }
  ];
  broadcastSubs;
  chitDetails:any=[];
  userDetails:any=[];
  userIdDetais: any;
  public href: string = "";
  constructor(private configService: ConfigService, private chitService: ChitService,private datePipe: DatePipe,private _formBuilder: FormBuilder,private communicationServ: CommunicationService, private router: Router) { }
  
  ngAfterViewInit() {
    this.configService.loadFile('../assets/license.info').then((info) => {
      this.configService.getProjectConfig().then((project_config: any) => {
        project_config.licence = info;
        this.projectConfig = project_config;
        this.init_socket();
        // this.datauxview.setProjectSettins(project_config);
        // this.datauxview.loadCanvas('settings', 'json', (data, settingstatus) => {
        //   var setting_status = settingstatus;
        //   if (!setting_status) {
        //     // RAJ: commenting out this part since it is not required for this project at this time
        //     /*
        //     setting_status = this.attachDynamicObject(data, (settingstatus) => {
        //       if (settingstatus) {
        //         this.initObjects();
        //       }
        //     });
        //     */
        //   } else {
        //     this.init_data();
        //   }
        // });
      }, (err) => { console.log(err) });
    }, (err) => { alert("License not found!") })

    // setTimeout(() => {
      // this.getChitActivityData();
      
    // }, 1500)
  }
  ngOnInit(): void {
    // this.VesselName = Object.keys(this.shipVesselName);
    // this.chitForm = new FormGroup({
    //   'presentDate': new FormControl((new Date()).toISOString().substring(0,10)),
    // })
    // this.getChitActivityData();
    this.href = this.router.url;
    console.log("Url Live", this.router.url);
    const urlDelimitators = new RegExp(/[?//,;&:#$+=]/);
    console.log("Url Live", this.router.url.split("?")[0]); 
    let currentUrlPath = this.router.url.slice(1).split(urlDelimitators).pop();
      console.log('URL_PATH: ', currentUrlPath);
    this.chitForm = this._formBuilder.group({
      'date': new FormControl((new Date()).toISOString().substring(0, 10)),
      vessel: ['', Validators.required],
      location: ['', Validators.required],
      onsite: ['', Validators.required],
      shipstaff: ['', Validators.required],
      start: ['', Validators.required],
      end: ['', Validators.required],
    });
    this.approveFormGroup = this._formBuilder.group({
      approved: ['', Validators.required],
    });
    this.broadcastSubs = this.communicationServ.getInstance()
    .subscribe((data: any) => {
      if (data.src === "app-main") {
        if (data.event === 'getChitDetail') {
          console.log("data:::",data.data);
          this.chitDetails = data.data['chit'];
          var userDetails = data.data['user'];
          this.userIdDetais = userDetails ? userDetails._id : currentUrlPath;
          console.log("user id", this.userIdDetais);
          // this.comments = this.chitDetails.comments != undefined;
          this.comments = (this.chitDetails.comments != undefined ? this.chitDetails.comments : '');
          this.verified = (this.chitDetails.verified ? this.chitDetails.verified: ' ');
          // this.verifiedData = (this.chitDetails.verified ? this.chitDetails.verified: '');
          // console.log("Notification details", this.chitDetails.notification)
          console.log("Notification details", JSON.parse(this.chitDetails.notification))

          // JSON.stringify(this.userList);
          // var datas = [
          //   { 'id': '610d3eefd6b4251d4cc9b23b', 'status': 'true', 'supRank': '001', 'supTelephone': '6139952534' },
          //   { 'id': '610d3eefd6b4251d4cc9b231', 'status': 'false', 'supRank': '002', 'supTelephone': '6139952534' },
          //   { 'id': '610d3eefd6b4251d4cc9b233', 'status': 'false', 'supRank': '003', 'supTelephone': '6139952534' },
          //   { 'id': '610d3eefd6b4251d4cc9b234', 'status': 'false', 'supRank': '004', 'supTelephone': '6139952534' },
          //   { 'id': '610d3eefd6b4251d4cc9b235', 'status': 'false', 'supRank': '005', 'supTelephone': '6139952534' },
          //   { 'id': '610d3eefd6b4251d4cc9b236', 'status': 'false', 'supRank': '006', 'supTelephone': '6139952534' },
          //   { 'id': '610d3eefd6b4251d4cc9b237', 'status': 'false', 'supRank': '007', 'supTelephone': '6139952534' }
          // ];
          var datas = JSON.parse(this.chitDetails.notification);
          datas.find(item => item._id == this.userIdDetais)
          var verified = datas.filter(t => t._id === this.userIdDetais);
          this.verifiedData = (verified[0].status ? verified[0].status: ''); 
          console.log("data update--", verified[0].status);

          this.userDetails = data.data['user'] ? data.data['user'] : "";
          this.approveFormGroup.setValue({
            approved: (this.chitDetails.verified ? this.chitDetails.verified: ''),
          });
          if(data.key=='chitView'){
            this.chitView=true;
            this.chitListView=false;
          }
          if(data.key=='chitListView'){
            this.chitView=false;
            this.chitListView=true;
          }
        }
      }
    })
  }
  userId: any;
  userName: any;
  projectConfig: any;
  init_socket() {
    console.log('init_socket');
    var _this = this;
    let socketPath = this.projectConfig.socketport || 'http://localhost:3000';
    console.log("soket url", socketPath);
    if (!_this.userId) {
      let socketport = `${socketPath}?userId=${_this.userId}&username=${_this.userName}`;
      _this.socket = io.connect(socketport);
      _this.socket.on('connect', onConnect);
      _this.socket.on('disconnect', onDisconnect);
      _this.socket.on('connect_error', onError);
      _this.socket.on('reconnect_error', onError);

      function onConnect(evt) {
        console.log("socket connected!!");
        writeToScreen("CONNECTED");
      }
      function onDisconnect(evt) {
        writeToScreen("DISCONNECTED");
        console.log("socket disconnected!!");
      }

      function onError(message) {
        writeToScreen('<span style="color: red;">ERROR:</span> ' + message);
      }

      function doSend(message) {
        writeToScreen("SENT: " + message);
        _this.socket.emit('sendMsg', message);
      }

      function writeToScreen(message) {
        var pre = document.createElement("p");
        pre.style.wordWrap = "break-word";
        pre.innerHTML = message;
        //output.appendChild(pre);
      }

    }
  }
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }

  visselInfoDetails(value) {
    let OnsiteInfo = this.VesselName.find(el => el.vessel === value);
    if (OnsiteInfo) {
      // shipStaffId =  OnsiteInfo;
      var VesselInfoData = OnsiteInfo;
      // console.log("sndkajsd", this.shipStaffInfo)
      this.chitForm.value.location = VesselInfoData.location;
      this.chitForm.value.onsite = VesselInfoData.onsiteName;
      this.chitForm.value.shipstaff = VesselInfoData.shipStaff;
    }
  }
  checkCheckBoxvalue(event){
    // console.log(event.checked)
    this.verified = event.checked;
    this.approveError = false;
  }
  viewChitData(data) {
    this.chitDetails = data;
    this.comments = (this.chitDetails.comments ? this.chitDetails.comments: ' '); 
    this.verified = this.chitDetails.verified;
    this.verifiedData = this.chitDetails.verified;
    this.createChitForm = false;
    this.chitListView = false;
    this.chitView = true;
    this.createChitForm2 = false;
    this.approveFormGroup.setValue({
      approved: (this.chitDetails.verified ? this.chitDetails.verified: ''),
    });
  }
  chitDataApproved() {
    this.createChitForm = false;
    this.chitListView = true;
    this.chitView = false;
    this.createChitForm2 = false;
  }
  chitCancel() {
    this.createChitForm = false;
    this.chitListView = true;
    this.chitView = false;
    this.createChitForm2 = false;
  }
  chitWindow() {
    this.createChitForm = true;
    this.chitListView = false;
    this.chitView = false;
    this.createChitForm2 = false;
  }
  backChit() {
    this.createChitForm = true;
    this.chitListView = false;
    this.chitView = false;
    this.createChitForm2 = false;
  }
  chitRadiate() {
    this.createChitForm = false;
    this.chitListView = false;
    this.chitView = false;
    this.createChitForm2 = true;
  }
  chitCreateForm() {
    this.createChitForm = false;
    this.chitListView = true;
    this.chitView = false;
    this.createChitForm2 = false;
  }

  dateFormat(date) {
    if (date) {
      return this.datePipe.transform(date, 'd MMM, y')
    }
  }
  chitStatusUpdate(e){
    if(e!='cancel'){
      if (this.approveFormGroup.invalid) {
        this.approveError = true;
        return;
      }
      this.approveError = false;
      var status =  e=='reject'?'no':'yes';
      // var data = [
      //   { 'id': '610d3eefd6b4251d4cc9b23b', 'status': 'false', 'supRank': '001', 'supTelephone': '6139952534' },
      //   { 'id': '610d3eefd6b4251d4cc9b231', 'status': 'false', 'supRank': '002', 'supTelephone': '6139952534' },
      //   { 'id': '610d3eefd6b4251d4cc9b233', 'status': 'false', 'supRank': '003', 'supTelephone': '6139952534' },
      //   { 'id': '610d3eefd6b4251d4cc9b234', 'status': 'false', 'supRank': '004', 'supTelephone': '6139952534' },
      //   { 'id': '610d3eefd6b4251d4cc9b235', 'status': 'false', 'supRank': '005', 'supTelephone': '6139952534' },
      //   { 'id': '610d3eefd6b4251d4cc9b236', 'status': 'false', 'supRank': '006', 'supTelephone': '6139952534' },
      //   { 'id': '610d3eefd6b4251d4cc9b237', 'status': 'false', 'supRank': '007', 'supTelephone': '6139952534' }
      // ];
      var data = JSON.parse(this.chitDetails.notification);
      data.find(item => item._id == this.userIdDetais).status = status;
      console.log("data update", data);
      this.chitService.approveData(this.chitDetails['_id'], {notification:JSON.stringify(data), status:status, comments:this.comments, verified:this.verified})
      .subscribe((res) => {
        // console.log("approve",res)
        var data = res.value
        if (data.status == "yes" || data.status == "no") {
          this.chitCancel();
          this.getChitActivityData();
        }
        let obj = {
          message: "success",
        }
        this.sendApproved(obj);
        // this.broadcastInfo({ src: 'chitApprove', event: 'chitData', data: obj });
        
      }, error => {
        console.error('There was an error!');
      })
    }else{
      this.chitCancel();
      this.getChitActivityData();
    }

  }

  sendApproved(obj) {
    if (this.socket !== undefined) {
      // obj['userId'] = this.userId;
      // console.log("sendApproved", obj)
      this.socket.emit('sendApproved', obj);
    }
  }

  // dateFormat(date) {
  //   if (date) {
  //     return this.datePipe.transform(date, 'MMMM d, y')
  //   }
  // }

  getDayLength(startDate, endDate) {
    var startCDate: any = new Date(startDate)
    var endCDate: any = new Date(endDate)
    var delta = Math.abs(endCDate - startCDate) / 1000;
    var days = Math.floor(delta / 86400);
    delta -= days * 86400;
    return (days + 1) + " day(s)";
  }

  getDayHoursLength(startDate, endDate) {
    var startCDate: any = new Date(startDate)
    var endCDate: any = new Date(endDate)
    var delta = Math.abs(endCDate - startCDate) / 1000;
    // calculate (and subtract) whole days
    var days = Math.floor(delta / 86400);
    delta -= days * 86400;
    // console.log( "day",delta)
    // calculate (and subtract) whole hours
    var hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;
    return days + "days" + " " + hours + "hours";
    // console.log( "hours",delta)
  }
  getChitActivityData() {
    var data = this.dateFormat(this.chitDetails.Date);
    // this.datePipe(new Date(), 'yyyy-MM-dd hh:mm:ssZZZZZ', 'en_US')
    var chitDate = this.datePipe.transform(data, 'yyy-MM-dd');
    // console.log("dateatta", chitDate);

    this.configService.getProjectConfig().then((project_config: any) => {
      this.projectConfig = project_config;
    this.chitService.getChitActivityDataList().subscribe(response => {
      // console.log('data getChitActivityData', response);
      // var shipDateMatch = response.filter(t => t.Date == ((new Date()).toISOString().substring(0, 10)));
      // var shipDateMatch = response.filter(t => this.dateFormat(new Date(t.date).getTime()) == this.dateFormat(new Date(data).getTime()));
      var shipDateMatch = response.filter(t => new Date(t.Date).getTime() === new Date(data).getTime());
      // this.chitDataList = shipDateMatch;
      // console.log('data getChitActivityData1', shipDateMatch)
      if (shipDateMatch) {
        var shipData = shipDateMatch.filter(t => t.Vessel == this.chitDetails.Vessel);
        // this.chitDataList = shipData;
      }
      if (shipData) {
        var shipDataList = shipData.filter(t => t.Vessel == this.chitDetails.OnsiteName || this.chitDetails.SupName);
        this.chitDataList = shipDataList;
      } else {   
        this.chitDataList = shipDateMatch;
      }
    })
  })
  }
  formateDate(date){
    if(date){
      return this.datePipe.transform(date, 'dd-MMM-yy');
    } else{
      return '-'
    }
  }
  getDayHoursLength2(startDate,startTime, endDate, endTime) {
    startDate = this.datePipe.transform(startDate, 'dd-MMM-yy') +' ,'+ startTime;
    endDate = this.datePipe.transform(endDate, 'dd-MMM-yy') +' ,'+ endTime;
    var startCDate: any = new Date(startDate)
    var endCDate: any = new Date(endDate)
    var delta = Math.abs(endCDate - startCDate) / 1000;
    // calculate (and subtract) whole days
    var days = Math.floor(delta / 86400);
    delta -= days * 86400;
    // console.log( "day",delta)
    // calculate (and subtract) whole hours
    var hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;
    return days + "days" + " " + hours + "hours";
    // console.log( "hours",delta)
  }
  // getChitActivityDatas() {
  //   this.chitService.getChitActivityDataList().subscribe(response => {
  //     console.log('data getChitActivityData---', response);
  //     var shipDateMatch = response.filter(t => new Date(t.Date).getTime() === new Date(this.commandingShipDate).getTime());
  //     if (this.commandingShip === 'no-ship') {
  //       this.chitDataList = []
  //     }else if(this.commandingShip){
  //       var shipData = shipDateMatch.filter(t => t.Vessel == this.commandingShip);
  //       this.chitDataList = shipData;
  //     } else {
  //       this.chitDataList = shipDateMatch;
  //     }

  //   })
  // }
}
