import { Injectable } from '@angular/core';
import { Http, RequestOptions, Headers } from '@angular/http';

@Injectable({
  providedIn: 'root'
})

export class JsonService {
  constructor(private _http: Http) { }

  getCanvasData(prjName, jsonFileName) {
    return new Promise((resolve, reject) => {
      this.getData(prjName, jsonFileName).then(
        res => {
          resolve(res);
        });
    });   
  }

  getAssetsData(prjName, jsonFileName) {
    return new Promise((resolve, reject) => {
      this.getData(prjName, jsonFileName).then(
        res => {
          resolve(res);
        });
    });
  }

  getObjectData(prjName, jsonFileName) {
    return new Promise((resolve, reject) => {
      this.getData(prjName, jsonFileName).then(
        res => {
          resolve(res);
        });
    });
  }

  getObjectProperties(prjName, jsonFileName) {
    return new Promise((resolve, reject) => {
      this.getData(prjName, jsonFileName).then(
        res => {
          resolve(res);
        });
    });
  }

  getAnimationData(prjName, jsonFileName) {
    return new Promise((resolve, reject) => {
      this.getData(prjName, jsonFileName).then(
        res => {
          resolve(res);
        });
    });
  }

  getData(prjName, jsonFileName) {
    return new Promise((resolve, reject) => {
      const url = prjName + '/data/' + jsonFileName + '.json';
      return this._http.get(url).subscribe(data => {
        resolve(data.json());
      }, (err) => {
        console.log('error:get-config:', err);
        reject(null);
      });
    });
  }
}
