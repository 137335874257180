import { Injectable } from '@angular/core';
import { CommunicationService } from "../services/communication.service";
import { TagService } from '../services/tag.service';

@Injectable({
  providedIn: 'root'
})
export class ActivityVisualizationService {
  setRGID: boolean = false;
  dfx: any;
  datauxview: any;
  allElements: any = {};
  activityState: boolean = true;
  broadcastSubs: any;
  currentShipList: any = [];
  jettyShipList: any = [];
  buildingsList: any = [];
  jettyShipID: any = [];
  profiles: any = {
    "red_rh": { material: 'dfx_#bb0000_transparent' },
    "red_rh_wr": { material: 'dfx_#bb0000', wireframe: true },
    "green_rh": { material: 'dfx_#00ff00_transparent' },
    "green_rh_wr": { material: 'dfx_#00ff00', wireframe: true },
    "yellow_rh": { material: 'dfx_#ff0000_transparent' },
    "yellow_rh_wr": { material: 'dfx_#ff0000', wireframe: true },
    "red": { material: 'envelope_red' },
    "blue": { material: 'envelope_blue' },
    "yellow": { material: 'envelope_yellow' },
    "pink": { material: 'envelope_pink' },
    "violet": { material: 'envelope_violet' },
    "purple": { material: 'envelope_purple' },
    "skyblue": { material: 'envelope_skyblue' },
    "green": { material: 'envelope_green' },
    "orange": { material: 'envelope_orange' },
    "brown": { material: 'envelope_brown' },
    "gray": { material: 'envelope_gray' },
    "maroon": { material: 'envelope_maroon' },
    "teal": { material: 'envelope_teal' },
    "navy": { material: 'envelope_navy' },
    "olive": { material: 'envelope_olive' },
    "lightSalmon": { material: 'envelope_lightSalmon' },
    "crimson": { material: 'envelope_crimson' },
    "hidden": { material: 'hidden_obj', visible: false,'selectable': false },
    "transparent": { material: 'transparent' },
    "kiosk_green": { material: 'cube_green' },
    "kiosk_yellow": { material: 'cube_yellow' },
  }
  activityConfig: any = {
    "ammunitioning": {
      id: "ammunitioning",
      color: "teal",
      position: {
        default: { x: 0, y: 0, z: 0 },
      }
    },
    "bollardpulls": {
      id: "bollard_pulls",
      color: "blue",
      position: {
        default: { x: 0, y: 0, z: 0 },
      }
    },
    "man-aloft": {
      color: "yellow",
      position: {
        default: { x: 0, y: 0, z: 0 },
      }
    },
    "ceremonial": {
      id: "ceremonial",
      color: "pink",
      position: {
        default: { x: 0, y: 0, z: 0 },
      }
    },
    "coldmove": {
      id: "cold_move",
      color: "violet",
      position: {
        default: { x: 0, y: 0, z: 0 },
      }
    },
    "cranework": {
      id: "crane_work",
      color: "purple",
      position: {
        default: { x: 0, y: 0, z: 0 },
      }
    },
    "fuelingoperations": {
      id: "fueling_operations",
      color: "skyblue",
      position: {
        default: { x: 0, y: 0, z: 0 },
      }
    },
    "antennarotating": {
      id: "antenna_rotating",
      color: "green",
      position: {
        default: { x: 0, y: 0, z: 0 },
      }
    },
    "radiating": {
      id: "radiating",
      color: "red",
      position: {
        default: { x: 0, y: 0, z: 0 },
      }
    },
    "stackemissions": {
      id: "stack_emissions",
      color: "brown",
      position: {
        default: { x: 0, y: 0, z: 0 },
      }
    },
    "truckfuelling": {
      id: "truck_fuelling",
      color: "gray",
      position: {
        default: { x: 0, y: 0, z: 0 },
      }
    },
    "manaloft/radiate": {
      id: "man-aloft/radiate",
      color: "maroon",
      position: {
        default: { x: 0, y: 0, z: 0 },
      }
    },
    "manaloft/rotating": {
      id: "man-aloft/rotating",
      color: "orange",
      position: {
        default: { x: 0, y: 0, z: 0 },
      }
    },
    "otheractivities": {
      id: "other_activities",
      color: "navy",
      position: {
        default: { x: 0, y: 0, z: 0 },
      }
    },
    "divingoperations": {
      id: "diving_operations",
      color: "olive",
      position: {
        default: { x: 0, y: 0, z: 0 },
      }
    },
    "engineexhuasting": {
      id: "engine_exhuasting",
      color: "lightSalmon",
      position: {
        default: { x: 0, y: 0, z: 0 },
      }
    },
    "jettyservice": {
      id: "jetty_service",
      color: "crimson",
      position: {
        default: { x: 0, y: 0, z: 0 },
      }
    },
    "": {
      id: "",
      color: "hidden",
      position: {
        default: { x: 0, y: 0, z: 0 },
      }
    },
  }
  emitters_activity=['radiating','antenna rotating','antenna radiating']
  constructor(private tagServ: TagService, private communicationServ: CommunicationService,) {
    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src === "main") {
          if (data.event === 'updateshipdata') {
            this.currentShipList = data['data']['currentShipList'];
          }
        }
      });
  }
  isRHEmitter(act){
    return this.emitters_activity.includes(act)
  }
  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }

  /* * * *
  * init dataux instance
  * * * */
  init(_datauxview) {
    this.datauxview = _datauxview;
    this.dfx = _datauxview.getDatascape();
  }

  /* * * *
  * maintain activity state
  * * * */
  setActivityState(flag) {
    this.activityState = flag;
  }

  /* * * *
  * method for activity show/hide
  * * * */
  toggleTransparentActivity(ship) {
    if (!this.activityState) {
      this.hideActivity(ship);
    }
  }

  /* * * *
  * method for show all activity show/hide
  * * * */
  showAllActivity(ship) {
    let shipid = ship['name'];
    if (this.activityState) {
      if (this.tagServ.selTagElement) {
        if (this.tagServ.selTagElement === shipid) {
          this.showActivity(ship);
        } else {
          this.transparentActivity(ship);
        }
      } else {
        this.showActivity(ship);
      }
    } else {
      this.hideActivity(ship);
    }
  }

  /* * * *
  * check if object is visible or not
  * * * */
  isShipVisible(c) {
    let comp = this.datauxview.getElementId(c);
    if (comp) {
      let props = this.dfx.props(comp);
      let vis = props.profile.visible;
      return vis;
    } else {
      return null;
    }
  }

  /* * * *
  * set ship activity visualiztion
  * * * */
  setShipActivity(ship) {
    if (ship) {
      let shipid = ship['name'];
      let show = this.isShipVisible(shipid);
      if (show) {
        this.processShipActivity(ship);
      }
    }
  }

  /* * * *
  * start process ship activity visualiztion
  * * * */
  processShipActivity(ship) {
    this.createCylinderModel(ship);
    this.tagServ.attachFlagBaseToShip(ship.name, ship['activity'], ship['model'], true);
    // let activity = ship['activity'].split(" ").join("").toLowerCase();
    // let shipid = ship['name'];
    // if (activity === 'man-aloft') {
    //   if (ship['class'] === 'Halifax') {
    //     let elem = this.datauxview.getElementId(shipid)
    //     this.allElements[shipid] = elem;
    //     if (this.activityState) {
    //       this.toggleManAloftEnvelope(shipid);
    //     } else {
    //       this.toggleManAloftEnvelope(shipid, false);
    //     }
    //   } else {
    //     this.createCylinderModel(ship);
    //   }
    // } else {
    //   this.createCylinderModel(ship);
    // }
  }

  /* * * *
  * ship object activity toggleManAloftEnvelope
  * * * */
  toggleManAloftEnvelope(shipid, yesno = true) {
    let scene = this.dfx.getCamera().getScene();
    let mat_name = yesno ? 'envelope_yellow' : 'envelop_mat';
    let mat = scene.getMaterialByID(mat_name)
    let ship_el = this.datauxview.getElementId(shipid);
    let ship = this.dfx.getElementMesh(ship_el);
    let meshes = ship.getChildMeshes();
    meshes.forEach((mesh) => {
      if (mesh.name.includes('envelope')) {
        mesh.material = mat;
      }
    })
  }

  /* * * *
  * transparent object make it visible with respected meterial
  * * * */
  showActivity(ship) {
    let activity = ship['activity'].split(" ").join("").toLowerCase();
    let posArray = this.activityConfig[activity]['position'];
    let shipid = ship.name;
    let show = this.isShipVisible(shipid);
    if (show) {
      // if (activity === 'man-aloft' && ship['class'] === 'Halifax') {
      //   this.toggleManAloftEnvelope(shipid);
      // } else {
      Object.keys(posArray).forEach((key) => {
        let id = 'envelope-' + key + '-' + shipid;
        if (this.allElements[id]) {
          let element = this.allElements[id];
          this.dfx.modify(element, { profile: 'regular' });
          this.dfx.modify(element, { profile: this.activityConfig[activity]['color'] });
        }
      })
      // }
    }
  }

  /* * * *
  * transparent the given ship activity
  * * * */
  transparentActivity(ship) {
    let activity = ship['activity'].split(" ").join("").toLowerCase();
    let posArray = this.activityConfig[activity]['position'];
    let shipid = ship.name;
    let show = this.isShipVisible(shipid);
    if (show) {
      // if (activity === 'man-aloft' && ship['class'] === 'Halifax') {
      //   // this.toggleManAloftEnvelope(shipid, false);
      // } else {
      Object.keys(posArray).forEach((key) => {
        let id = 'envelope-' + key + '-' + shipid;
        if (this.allElements[id]) {
          let element = this.allElements[id];
          this.dfx.modify(element, { profile: 'regular' });
          this.dfx.modify(element, { profile: 'transparent' });
        }
      })
      //}
    }
  }

  /* * * *
  * hide the given ship activity
  * * * */
  hideActivity(ship) {
    let activity = ship['activity'].split(" ").join("").toLowerCase();
    let posArray = this.activityConfig[activity]['position'];
    let shipid = ship.name;
    let show = this.isShipVisible(shipid);
    if (show) {
      // if (activity === 'man-aloft' && ship['class'] === 'Halifax') {
      //   this.toggleManAloftEnvelope(shipid, false);
      // } else {
      Object.keys(posArray).forEach((key) => {
        let id = 'envelope-' + key + '-' + shipid;
        if (this.allElements[id]) {
          let element = this.allElements[id];
          this.dfx.modify(element, { profile: 'regular' });
          this.dfx.modify(element, { profile: 'hidden' });
        }
      })
      // }
    }
  }

  /* * * *
  * change activity for given ship
  * * * */
  changeActivity(ship, activity) {
    let shipid = ship.name;
    let show = this.isShipVisible(shipid);
    if (show) {
      if (ship['activity'].split(" ").join("").toLowerCase() !== activity.split(" ").join("").toLowerCase()) {
        if (ship['activity'].split(" ").join("").toLowerCase() === 'man-aloft') {
          this.toggleManAloftEnvelope(shipid, false);
        }
        ship['activity'] = activity;
        this.processShipActivity(ship);
      }
    }
  }
  getDynamicCubeConfig(shape, id) {
    let dfx = this.dfx;
    let opt = {
      shape: shape,
      id: id,
      size: 1,
      profiles: this.profiles,
    }
    return dfx.model(opt);
  }
  shape: 0;
  kioskElement = {};
  createShapeCube(data, port) {
    let shape = 'cube';
    let id = data.uid;
    var pos
    if (data.servicetype == "Power Services") {
      pos = { "x": 143.756, "y": 5, "z": -15.067 };
    } else {
      pos = { "x": 303.549, "y": 5, "z": -161.26 };
    }
    var size = 3;
    let profiles = this.profiles;
    let settings = { 'geometry': { 'orientation': { x: 0, y: 0, z: 0 }, position: { x: pos.x, y: pos.y, z: pos.z }, size: size } };
    this.datauxview.addElement(shape, id, profiles, settings);
    let buildModel = this.datauxview.getElementId(id);
    this.dfx.modify(buildModel, { profile: data.port == port ? data.servicetype == "Power Services" ? 'kiosk_yellow' : 'kiosk_green' : 'invisible' });
    this.attchCubeObjEvent(buildModel);
    // this.dfx.setAsParent(port_el, [buildModel]);
    // this.attchStaticObjEvent(buildModel);
    // if(this.shape<3){
    // var profile=data.port==port?data.servicetype=="Power Services"?'kiosk_yellow':'kiosk_green':'invisible';
    // // var modelConfig = data.servicetype=="Power Services"?'kiosk-model1':'kiosk-model';
    // var modelConfig = this.getDynamicCubeConfig('cube',data.uid);

    // var size=3;
    // let cube = this.dfx.add({
    //       id: data.uid,
    //       model: modelConfig,
    //       profile: profile,
    //       geometry: { position: { x: pos.x, y: pos.y, z: pos.z }, size }
    //     });
    //     console.log("created cube",cube,data.uid)

    //     this.shape++
    //     this.kioskElement[data.uid]=cube;
    //     this.tagServ.kioskElement=this.kioskElement
    // }

  }
  attchCubeObjEvent(el) {
    this.datauxview.getDatascape().attach(el, {
      actions: {
        pickLeft: [(evt, elem) => {
          if (evt.button === 0) {
            let props = this.dfx.props(el);
            // let shipid = props.id.replace("envelope-default-","");
            this.broadcastInfo({ src: 'main', event: 'showKioskId', data: props.id, state: null, sub: null });
            // this.tagServ.showKioskTag(props.id, true)
            console.log("pickLeft", props.id)
          }
        }]
      }
    });
  }
  createShapePointer(shape, e) {
    var elem = this.datauxview.getElementId('laydownPointer');
    var pos = e.pickedPoint;
    if (!elem) {
      var size = 0.5;
      let profiles = this.profiles;
      let settings = { 'geometry': { 'orientation': { x: 0, y: 0, z: 0 }, position: { x: pos.x, y: pos.y, z: pos.z }, size: size } };
      this.datauxview.addElement(shape, 'laydownPointer', profiles, settings);
      let buildModel = this.datauxview.getElementId('laydownPointer');
      this.dfx.modify(buildModel, { profile: 'transparent' });
    } else {
      this.dfx.modify(elem, { 'geometry': { 'orientation': { x: 0, y: 0, z: 0 }, position: { x: pos.x, y: pos.y, z: pos.z }, size: size } });
    }
    // setTimeout(() => {
    this.broadcastInfo({ src: 'main', event: 'showLaydownPointer', data: 'laydownPointer', state: true, sub: e.pickedMesh.id });
    // }, 1000);
    // this.attchCubeObjEvent(buildModel);
  }
  /* * * *
  * get dynamic cylinder model
  * * * */
  getDynamicCylinderConfig(shape, id) {
    let dfx = this.dfx;
    let opt = {
      shape: shape,
      id: id,
      size: 1,
      profiles: this.profiles,
    }
    return dfx.model(opt);
  }
  /* * * *
   * get dynamic sphere model
   * * * */
  getDynamicSphereConfig(shape, id) {
    let dfx = this.dfx;
    let opt = {
      shape: shape,
      id: id,
      size: 1,
      profiles: this.profiles,
    }
    return dfx.model(opt);
  }
  /* * * *
  * create dynamic cylinder objects
  * * * */
  createCylinderModel(ship) {
    let activity = ship['activity'].split(" ").join("").toLowerCase();
    let posArray = this.activityConfig[activity]['position'];
    let shipid = ship.name;

    Object.keys(posArray).forEach((key, idx) => {
      let pos = posArray[key];
      let size = 1;
      let id = 'envelope-' + key + '-' + shipid;
      let modelConfig = this.getDynamicCylinderConfig('cylinder', id);
      let isemitter=this.isRHEmitter(activity)
      let s = activity === 'man-aloft' ? 15 : 25;
      s = isemitter ? 50 : s;
      let sh = isemitter ? 50 : 5;
      let sy = isemitter ? 0 : 25;
      sy = (ship.model === 'vcsub-model' ? 12 : sy);
      let profile = (this.activityState ? this.activityConfig[activity]['color'] : 'hidden');
      let cylinder = this.dfx.add({
        id: id,
        model: modelConfig,
        profile: profile,
        geometry: { position: { x: pos.x, y: sy, z: pos.z }, size }
      });

      this.dfx.setElementScale(cylinder, [s, sh, s]);
      this.allElements[id] = cylinder;
      let ship_el = this.datauxview.getElementId(shipid);
      this.dfx.setAsParent(ship_el, [cylinder]);
      let cylinder_m = this.datauxview.getElementMesh(cylinder);
      if (this.setRGID) {
        cylinder_m.renderingGroupId = 1;
      }
      this.attchCyliderObjEvent(cylinder)
    });
  }

  /* * * * *
  * attch event for Cylinder created ship objects
  * * * * * */
  attchCyliderObjEvent(el) {
    this.datauxview.getDatascape().attach(el, {
      actions: {
        pickLeft: [(evt, elem) => {
          if (evt.button === 0) {
            let props = this.dfx.props(el);
            let shipid = props.id.replace("envelope-default-", "");
            this.broadcastInfo({ src: 'main', event: 'showclickedship', key: shipid, state: null, sub: null });
          }
        }]
      }
    });
  }

  /**
  * method for set building list
  */
  setBuildingList(obj) {
    this.buildingsList = obj;
  }

  /**
   * method for get all the breaths in jetty
   */
  getShipsInJetty(jetty) {
    let ships = [];
    this.jettyShipID = [];
    jetty = jetty.toLowerCase();
    this.currentShipList.forEach(ship => {
      let location = ship['location'].substring(0, 2);
      let shipid = ship['name'];
      if (jetty === location.toLowerCase()) {
        let show = this.isShipVisible(shipid);
        if (show) {
          let element = this.datauxview.getElementId(shipid)
          this.jettyShipID.push(element);
          ships.push(ship);
        }
      }
    });
    return ships;
  }
  /**
   *
   * get current ship element array
   */
  getShipsInView(except) {
    let ships = [];
    except = except.toLowerCase();
    this.currentShipList.forEach(ship => {
      let shipid = ship['name'];
      if (except !== shipid) {
        let show = this.isShipVisible(shipid);
        if (show) {
          let element = this.datauxview.getElementId(shipid)
          ships.push(element);
        }
      }
    });
    return ships;

  }
  /**
  * method for get a cylinder element
  */
  getCylinderID(activity) {
    let cylinderID = '';
    activity = activity.split(" ").join("").toLowerCase();
    let posArray = this.activityConfig[activity]['position'];
    this.jettyShipList.forEach(ship => {
      if (activity === ship['activity'].split(" ").join("").toLowerCase()) {
        let shipid = ship.name;
        if (activity === 'man-aloft' && ship['class'] === 'Halifax') {
          if (this.allElements[shipid]) {
            cylinderID = this.allElements[shipid];
          }
        } else {
          Object.keys(posArray).forEach((key) => {
            let id = 'envelope-' + key + '-' + shipid;
            if (this.allElements[id]) {
              cylinderID = this.allElements[id];
            }
          })
        }
      }
    });
    return cylinderID;
  }
  /**
   *
   * get radhaz cylinder for given ship
   */
  getCylinderIDForShip(activity, ship) {
    let cylinderID = '';
    activity = activity.split(" ").join("").toLowerCase();
    let posArray = this.activityConfig[activity]['position'];
    let shipid = ship.name;
    if (activity === ship['activity'].split(" ").join("").toLowerCase()) {
      let id = 'envelope-default' + '-' + shipid;
      if (this.allElements[id]) {
        cylinderID = this.allElements[id];
      }
    }

    return cylinderID;
  }

  /**
  * method for get a target element
  */
  getTargetElements() {
    let buildingElem = [];
    this.buildingsList.forEach(bld => {
      let elem = this.datauxview.getElementId(bld);
      buildingElem.push(elem);
    });
    let targetElem = buildingElem.concat(this.jettyShipID);
    return targetElem;
  }
  /**
    * get all buildings in the port
    */
  getTargetBldgElements() {
    let buildingElem = [];
    this.buildingsList.forEach(bld => {
      let elem = this.datauxview.getElementId(bld);
      buildingElem.push(elem);
    });
    return buildingElem;
  }
  /**
   * method for get activity intersecting objects
   */
  // showActivityIntersect(activity,ship) {
  //   let boo = false;
  //   if (this.currentShipList.length > 0) {
  //     let cylinderElem = this.getCylinderIDForShip(activity,ship);
  //     let ships = this.getShipsInView(ship.Vessel);
  //     let bldgs=this.getTargetBldgElements();
  //     let targetElem=[].concat(ships,bldgs);
  //     let obj = this.dfx.checkForCollision({ source: cylinderElem, targets: targetElem });
  //     if(obj){
  //       let shipId = ship.UserID || '';
  //       this.getCollisionShipData(obj,shipId);
  //       this.getFlagObjects(obj,-25,'radhaz');
  //       boo=!!obj.length;
  //     }
  //   }
  //   return boo;
  // }
  showActivityIntersect(activity, ship,addInplace=false) {
    let boo = false;
    let cy_dyn=false;
    if (this.currentShipList.length > 0) {
      let cylinderElem = this.getCylinderIDForShip(activity, ship);
      if(!cylinderElem&&addInplace){
        this.setShipActivity(ship);
        /**
         * TODO-add await
         */
        while(!cylinderElem){
        cylinderElem = this.getCylinderIDForShip(activity, ship);
        }
        cy_dyn=true;
      }

      let ships = this.getShipsInView(ship.name);
      let bldgs = this.getTargetBldgElements();
      let targetElem = [].concat(ships, bldgs);
      let obj = this.dfx.checkForCollision({ source: cylinderElem, targets: targetElem });
      let shipId = ship['name'] || '';
      if (obj) {

        this.getCollisionShipData(obj, shipId);
        this.getFlagObjects(obj, -25, 'radhaz');
        boo = !!obj.length;
      }
      if(addInplace&&cy_dyn){
        this.dfx.remove(cylinderElem);
        let id = 'envelope-default' + '-' + shipId;
        this.allElements[id]=null;
        delete this.allElements[id];
      }
    }
    return boo;
  }
  getActivityIntersect(activity, ship, cship) {
    let boo = false;
    let res = { result: false, ships: [] };
    if (this.currentShipList.length > 0) {
      let cylinderElem = this.getCylinderIDForShip(activity, ship);
      //let ships = this.getShipsInView(ship.name);
      //let bldgs=this.getTargetBldgElements();
      let el_cship = this.datauxview.getElementId(cship.name);
      if (el_cship) {

        let targetElem = [el_cship];//.concat(ships);
        let obj = this.dfx.checkForCollision({ source: cylinderElem, targets: targetElem });
        if (obj) {
          obj.forEach(elem => {
            let props = this.dfx.props(elem);
            res.ships.push(props.id);
          });
          let shipId = ship['name'] || '';
          this.getCollisionShipData(obj, shipId);
          this.getFlagObjects(obj, -25, 'radhaz');
          boo = !!obj.length;
          res.result = boo;
        }
      }
    }
    return res;
  }
  checkActivityCollisionByOtherShips(activity, cship) {
    let list = []
    if (this.currentShipList.length > 0) {
      this.currentShipList.some(ship => {
        let activity = ship['activity'].split(" ").join("").toLowerCase();
        let shipid = ship['name'];
        if (shipid !== cship.name && this.isRHEmitter(activity) && this.isShipVisible(shipid)) {
          let col = this.getActivityIntersect(activity, ship, cship);
          if (col.result && col.ships.includes(cship.name)) {
            list.push(shipid)
          }
        }
      });
    }
    return list;
  }

  selectedCollisionships = [];
  getCollisionShipData(obj, parentChitId) {
    this.selectedCollisionships = [];
    obj.forEach(elem => {
      let props = this.dfx.props(elem);
      let shipId = props.id;
      let ship = this.currentShipList.find(el => el.name === shipId);
      if (ship) {
        ship['parentChitId'] = parentChitId;
        this.selectedCollisionships.push(ship);
      }
    });
  }

  /**
   * method for get flag objects to create instance
   */
  flag_store_radhaz = [];
  getFlagObjects(obj, y = 0, store = "") {
    let flagObj = [];
    obj.forEach(elem => {
      let props = this.dfx.props(elem);
      let objConfig = {
        "type": "static",
        "parent": props.id,
        "name": 'flag_' + props.id,
        "model": "flag-model",
        "profile": "regular",
        "place": {
          "pos": { x: 0, y, z: 0 }, //props.geometry['position'],
          "rot": { x: 0, y: 0, z: 180 }
        }
      };
      flagObj.push(objConfig);
    });
    this.createFlagObject(flagObj, store)
  }

  /**
   * creating flag object instance
   */
  createFlagObject(obj, store = null) {
    this.datauxview.addElements(obj, (objects) => {
      // console.log("flagObjects ", objects);
      if (store) {
        this['flag_store_' + store] = objects;
      }
    }, (id, pointer) => { });
  }
  clearFlagObjects(store) {
    let arr = this['flag_store_' + store];
    arr.forEach((s) => {
      let el = this.datauxview.getElementId(s.name);
      this.dfx.remove(el);
    })
    this['flag_store_' + store] = [];
  }

  /**
   * RadHaz Demo code
   */
  radhaz_cylinders = {};
  ant_radhaz_cylinders={};
  ant_rot_cylinders={};
  /* * * *
  * create dynamic cylinder objects
  * * * */
  getShipRADHAZBase(ship) {
    /**
     * get base level for different ship models
     */
    return 11.3
  }
  createCylinderModel_RADHAZ(shipdata, id,ant="") {
    let posArray = shipdata;
    let shipid = id;
    let baseLevel = this.getShipRADHAZBase(shipdata);
    Object.keys(posArray).forEach((key, idx) => {
      let pos = posArray[key].position;
      if (key.includes("CIWS")) {
        pos = { x: 3.86, y: 13.63, z: 33.51 }
      }
      let size = 1;
      let id = 'envelope-' + key + '-' + shipid;
      if(ant){
        id=id+"-"+ant;
      }
      let modelConfig = this.getDynamicCylinderConfig('cylinder', id);
      //modelConfig = this.getDynamicSphereConfig('sphere',id);
      let s;
      let dy = (pos.y - baseLevel);
      s = ant==="ROT"?(posArray[key].rot_radius||3):posArray[key].radius;
      let sh = (s / 2) + dy;
      let sy = (sh / 2) + baseLevel;

      if (sh > s) {
        sh = s;
        sy = pos.y;
      }

      let profile = "hidden";
      let cylinder = this.dfx.add({
        id: id,
        model: modelConfig,
        profile: profile,
        geometry: { position: { x: pos.x, y: sy, z: pos.z }, size }
      });

      this.dfx.setElementScale(cylinder, [s, sh, s]);
      let cyl =this.radhaz_cylinders
      cyl=ant=="ROT"? this["ant_rot_cylinders"]:cyl;
      cyl=ant=="ARH"? this["ant_radhaz_cylinders"]:cyl;
      if (!cyl[shipid]) {
        cyl[shipid] = {}
      }
      cyl[shipid][id] = cylinder;
      let ship_el = this.datauxview.getElementId(shipid);
      this.dfx.setAsParent(ship_el, [cylinder]);
      let cm = this.dfx.getElementMesh(cylinder);
      cm.isPickable = false;
  
      if (this.setRGID) {
        cm.renderingGroupId = 1;
      }
    });
  }
  showHideRHCylinder(key, boo, shipid,prof=null) {
    let cy = this.radhaz_cylinders[shipid]['envelope-' + key]
    let cym = this.dfx.getElementMesh(cy);
    let profile = boo ? prof||"yellow_rh" : "hidden";
 
    if (profile == 'yellow_rh' && cym.oncollision) {
      profile = "red_rh"
    }
    this.dfx.modify(cy, { profile });
    let cm = this.dfx.getElementMesh(cy);
    cm.isPickable = false;
    // if(profile=='hidden'){
    //   cm.isPickable = false;
    // }else{
    //   cm.isPickable = true;
    // }
  }
  showHideRHCylinderMesh(key, boo, shipid,prof=null) {
    let cym = key
    let profile = boo ? prof||"dfx_#bb0000_transparent" : "hidden_obj";
    if (profile == 'dfx_#bb0000_transparent' && cym.oncollision) {
      profile = 'dfx_#ff0000_transparent'
    }
    let scene = this.dfx.getCamera().getScene();
    let mat = scene.getMaterialByID(profile);
    cym.material=mat;
    cym.isVisible=boo;
    if(boo&&!cym.isEnabled()){
      cym.setEnabled(boo);
    }
    cym.isPickable = false;
  }
  showHideARHCylinder(key, boo, shipid,prof=null) {
    let cy = this.ant_radhaz_cylinders[shipid]['envelope-' + key+"-ARH"]
    let cym = this.dfx.getElementMesh(cy);
    let profile = boo ? prof||"yellow" : "hidden";
    /* if (profile == 'yellow_rh' && cym.oncollision) {
      profile = "red_rh"
    } */
    this.dfx.modify(cy, { profile });
    let cm = this.dfx.getElementMesh(cy);
    cm.isPickable = false;
    // if(profile=='hidden'){
    //   cm.isPickable = false;
    // }else{
    //   cm.isPickable = true;
    // }
  }
  showHideROTCylinder(key, boo, shipid,prof=null) {
    let cy = this.ant_rot_cylinders[shipid]['envelope-' + key+"-ROT"]
    let cym = this.dfx.getElementMesh(cy);
    let profile = boo ? prof||"skyblue" : "hidden";
    /* if (profile == 'green_rh' && cym.oncollision) {
      profile = "red_rh"
    } */
    this.dfx.modify(cy, { profile });
    let cm = this.dfx.getElementMesh(cy);
    cm.isPickable = false;
    // if(profile=='hidden'){
    //   cm.isPickable = false;
    // }else{
    //   cm.isPickable = true;
    // }
  }
  showHideRHCylinder_wf(key, boo, shipid) {
    if(typeof key !=='string'){
      key.material.wireframe=boo;
      return
    }
    let cy = this.radhaz_cylinders[shipid]['envelope-' + key]
    let cym = this.dfx.getElementMesh(cy);

    let profile = boo ? "_rh_wr" : "_rh";
    if (cym.oncollision) {
      profile = 'red' + profile
    } else {
      profile = 'yellow' + profile
    }
    this.dfx.modify(cy, { profile });
  }
}
