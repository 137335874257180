import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateHighlightService {

  arrayOfDays:any=[new Date()];
  datesString=this.arrayOfDays.map(x=>x.getFullYear()+('00'+x.getMonth()).slice(-2)+('00'+x.getDate()).slice(-2))
  constructor() {}
  displayMonth(year: number, view: string) {
    setTimeout(() => {
      let elements = document.querySelectorAll(".mat-calendar-content");
      let x = elements[0].querySelectorAll(".mat-calendar-body-cell-content");
      if (view == "multi-year") {
        const years = this.arrayOfDays.map(x => x.getFullYear());
        x.forEach((y: any) => {
          if (years.indexOf(+y.innerHTML.trim()) >= 0) {
            {
              y.style["border-color"] = "red";
            }
          }
        });
      }
      if (view=="year"){
        const monthsIni = [
          "JAN","FEB","MAR","APR","MAY","JUN","JUL","AUG","SEP","OCT","NOV","DEC"
        ];
        const months = this.arrayOfDays
          .filter(x => x.getFullYear() == year)
          .map(x => monthsIni[x.getMonth()]);
        x.forEach((y: any) => {
          if (months.indexOf(y.innerHTML.trim()) >= 0) {
            {
              y.style["border-color"] = "red";
            }
          }
        });
      }
    });
  }
  getClass(d:any)
  {
    d =d._d;
    const search=d.getFullYear()+('00'+d.getMonth()).slice(-2)+('00'+d.getDate()).slice(-2)
    return this.datesString.indexOf(search)>=0?'enchanced':null
  }
  setHighlightDate(date){
    this.arrayOfDays  = date;
    this.datesString=this.arrayOfDays.map(x=>x.getFullYear()+('00'+x.getMonth()).slice(-2)+('00'+x.getDate()).slice(-2))
  }
}
