import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommunicationService } from "../services/communication.service";
import { ChitService } from '../services/chit.service';
import { ConfigService } from '../services/config.service';
import { ControllerService } from '../services/controller.service';

@Component({
  selector: 'app-teams-panel',
  templateUrl: './teams-panel.component.html',
  styleUrls: ['./teams-panel.component.scss']
})
export class TeamsPanelComponent implements OnInit {
  broadcastSubs: any;
  teamsDashboard: boolean = false;
  createDashboardTeamsFormGroup!: FormGroup;
  dashboardRoundHeading: any = "Create Teams";
  teamsId: any;
  isReadOnlyDashboard: boolean = false;
  createResponseDashboard: boolean = true;
  location:any = "";
  editResponseDashboardIcon: boolean = false;
  closeResponseDashboardIcon: boolean = false;
  updateResponseDashboard: boolean = false;
  teamStatus: any;
  teamsNotes: any;
  usersDetails: any;
  supervisorListData: any = [];
  startTime = '08:00';
  endTime = '16:00';
  filterDate = "2021-03-01"
  shipVesselName: any = {
    "HAL": {
      "model": "Halifax",
      "class": "Halifax",
      "name": "HMCS HALIFAX",
      "number": "FFH 330"
    },
    "VAN": {
      "model": "Halifax",
      "class": "Halifax",
      "name": "HMCS Vancouver",
      "number": "FFH 331"
    },
    "VDQ": {
      "model": "Halifax",
      "class": "Halifax",
      "name": "HMCS VILLE DE QUEBEC",
      "number": "FFH 332"
    },
    "TOR": {
      "model": "Halifax",
      "class": "Halifax",
      "name": "HMCS TORONTO",
      "number": "FFH 333"
    },
    "REG": {
      "model": "Halifax",
      "class": "Halifax",
      "name": "HMCS Regina",
      "number": "FFH 334"
    },
    "CAL": {
      "model": "Halifax",
      "class": "Halifax",
      "name": "HMCS Calgary",
      "number": "FFH 335"
    },
    "MON": {
      "model": "Halifax",
      "class": "Halifax",
      "name": "HMCS MONTREAL",
      "number": "FFH 336"
    },
    "FRE": {
      "model": "Halifax",
      "class": "Halifax",
      "name": "HMCS FREDERICTON",
      "number": "FFH 337"
    },
    "WIN": {
      "model": "Halifax",
      "class": "Halifax",
      "name": "HMCS Winnipeg",
      "number": "FFH 338"
    },
    "CHA": {
      "model": "Halifax",
      "class": "Halifax",
      "name": "HMCS CHARLOTTETOWN",
      "number": "FFH 339"
    },
    "STJ": {
      "model": "Halifax",
      "class": "Halifax",
      "name": "HMCS ST JOHN’S",
      "number": "FFH 340"
    },
    "OTT": {
      "model": "Halifax",
      "class": "Halifax",
      "name": "HMCS Ottawa",
      "number": "FFH 341"
    },
    "VSR": {
      "model": "Halifax",
      "class": "Halifax",
      "name": "HMCS VSR",
      "number": ""
    },
    "VIS": {
      "model": "vis-model",
      "class": "Halifax",
      "name": "HMNZS Te Mana",
      "number": "F111"
    },
    "ORI": {
      "model": "orca-model",
      "class": "Orca",
      "name": "HMCS ORIOLE",
      "number": "YAC 3"
    },
    "AST": {
      "model": "asterix-model",
      "class": "Asterix",
      "name": "MV ASTERIX",
      "number": "AST"
    },
    "HDW": {
      "model": "aops-model",
      "class": "DeWolf",
      "name": "HMCS HARRY DEWOLF",
      "number": "AOPV 430"
    },
    "MAR": {
      "model": "aops-model",
      "class": "DeWolf",
      "name": "HMCS MARGARET BROOKE",
      "number": "AOPV 431"
    },
    "VIC": {
      "model": "vcsub-model",
      "class": "Victoria",
      "name": "HMCS VICTORIA",
      "number": "SSK 876"
    },
    "WSR": {
      "model": "vcsub-model",
      "class": "Victoria",
      "name": "HMCS WINDSOR",
      "number": "SSK 877"
    },
    "COR": {
      "model": "vcsub-model",
      "class": "Victoria",
      "name": "HMCS Corner Brook",
      "number": "SSK 878"
    },
    "CHI": {
      "model": "vcsub-model",
      "class": "Victoria",
      "name": "HMCS Chicoutimi",
      "number": "SSK 879"
    },
    "KIN": {
      "model": "kingston-model",
      "class": "kingston",
      "name": "HMCS KINGSTON",
      "number": " MM 700"
    },
    "GLA": {
      "model": "kingston-model",
      "class": "kingston",
      "name": "HMCS GLACE BAY",
      "number": "MM 701"
    },
    "NAN": {
      "model": "kingston-model",
      "class": "kingston",
      "name": "HMCS NANAIMO",
      "number": "MM 702"
    },
    "EDM": {
      "model": "kingston-model",
      "class": "kingston",
      "name": "HMCS Edmonton",
      "number": "MM 703"
    },
    "SHA": {
      "model": "kingston-model",
      "class": "kingston",
      "name": "HMCS SHAWINIGAN",
      "number": "MM 704"
    },
    "WHI": {
      "model": "kingston-model",
      "class": "kingston",
      "name": "HMCS WHITEHORSE",
      "number": "MM 705"
    },
    "YEL": {
      "model": "kingston-model",
      "class": "kingston",
      "name": "HMCS Yellowknife",
      "number": "MM 706"
    },
    "GBY": {
      "model": "kingston-model",
      "class": "kingston",
      "name": "HMCS GOOSE BAY",
      "number": "MM 707"
    },
    "MCT": {
      "model": "kingston-model",
      "class": "kingston",
      "name": "HMCS MONCTON",
      "number": "MM 708"
    },
    "SAS": {
      "model": "kingston-model",
      "class": "kingston",
      "name": "HMCS SASKATOON",
      "number": "MM 709"
    },
    "BRN": {
      "model": "kingston-model",
      "class": "kingston",
      "name": "HMCS Brandon",
      "number": "MM 710"
    },
    "SUM": {
      "model": "kingston-model",
      "class": "kingston",
      "name": "HMCS SUMMERSIDE",
      "number": "MM 711"
    },
    "SAC": {
      "model": "sackville-model",
      "class": "Boat",
      "name": "Sackville",
      "number": ""
    },
    "TUG": {
      "model": "tug-model",
      "class": "Boat",
      "name": "Tug",
      "number": ""
    },
    "ORC": {
      "model": "orca-boat-model",
      "class": "Boat",
      "name": "ORCA",
      "number": ""
    },
    "RHP": {
      "model": "rhib-p-model",
      "class": "Boat",
      "name": "RHIB P",
      "number": ""
    },
    "RHB": {
      "model": "rhib-model",
      "class": "Boat",
      "name": "RHIB_S",
      "number": ""
    }
  };
  vesselName: any;
  selectedTagNotesItem: any;
  selectedLayoutList: any;
  selectedTagItem: any;
  viewResponseDashboard: boolean = false;
  
  constructor(private ctrlServ: ControllerService, private configService: ConfigService, private datePipe: DatePipe, private _formBuilder: FormBuilder, private communicationServ: CommunicationService, private chitService: ChitService) {
    this.createDashboardTeamsFormGroup = this._formBuilder.group({
      teams:[''],
      starttime: [' ', Validators.required],
      endtime: [' ', Validators.required],
      date: [' ', Validators.required],
      email: [' ', Validators.required],
      vessel: [' ', Validators.required],
      details: [''],
    });
  }


  ngOnInit(): void {
    this.getUserData();
    this.supervisorList();
    this.vesselName = Object.keys(this.shipVesselName);
    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src == "sidepanel") {
          if (data.event === 'teams') {
            var keyElem = data.key
            if (data.selectedLayoutList) {
              this.selectedLayoutList = data.selectedLayoutList
              this.selectedTagItem = data.selectedTag
            }
            if (keyElem == "dashboard") {
              this.teamsDashboard = true;
              var vessel;
              if (this.configService['loginInfo'] != undefined) {
                vessel = this.configService['loginInfo']['vessel']
              }
              var formData = data.value;
              var filterDate = data.filterDate != undefined ? data.filterDate : '';
              this.filterDate = filterDate
              this.createDashboardTeamsFormGroup.setValue({
                teams: (" "),
                starttime: (""),
                endtime: (""),
                date: (new Date(filterDate) ? new Date(this.ctrlServ.formatUTCTZ(filterDate, "DD-MMM-YYYY")) : ""),
                email: (""),
                vessel: (vessel ? vessel : ""),
                details: (""),
              });
              if (formData) {
                this.createDashboardTeamsFormGroup.patchValue({
                  teams: (formData.teams ? formData.teams : " "),
                  starttime: (formData.starttime ? formData.starttime : ""),
                  endtime: (formData.endtime ? formData.endtime : ""),
                  date: (new Date(filterDate) ? new Date(this.ctrlServ.formatUTCTZ(filterDate, "DD-MMM-YYYY")) : ""),
                  email: (formData.email ? formData.email : ""),
                  vessel: (formData.vessel ? formData.vessel : vessel ? vessel : ""),
                  details: (formData.details ? formData.details : ""),
                });
              }

            }
          }

          if (data.event === 'scheduleDashboardData') {
            var data = data['data']
            this.teamsId = data._id
            this.isReadOnlyDashboard = true;
            this.dashboardRoundHeading = "View teams";
            this.editResponseDashboardIcon = true;
            this.closeResponseDashboardIcon = false;
            this.viewResponseDashboard = true;
            this.createResponseDashboard = false;
            this.updateResponseDashboard = false;

            this.teamStatus = data.status
            this.teamsNotes = data.notes
            this.createDashboardTeamsFormGroup.disable();
            this.createDashboardTeamsFormGroup.setValue({
              teams: (data.teams ? data.teams : ""),
              starttime: (data.starttime ? data.starttime : ""),
              endtime: (data.endtime ? data.endtime : ""),
              date: (new Date(data.date) ? new Date(this.ctrlServ.formatUTCTZ(data.date, "DD-MMM-YYYY")) : ""),
              email: (data.email ? data.email : ""),
              vessel: (data.vessel ? data.vessel : ""),
              details: (data.details ? data.details : ""),
            });
          }

          if (data.event === 'teamsDashboardDataView') {
            this.teamsDashboard = true;
            var notes = data.notes;
            this.location = notes != undefined && typeof notes.props.tagLocation === "object" ? JSON.stringify(notes.props.tagLocation) : "";
            var data = data['data']
            this.teamsId = data
            this.isReadOnlyDashboard = true;
            this.dashboardRoundHeading = "View Teams";
            this.editResponseDashboardIcon = true;
            this.viewResponseDashboard = true;
            this.closeResponseDashboardIcon = false;
            this.createResponseDashboard = false;
            this.updateResponseDashboard = false;

            this.teamStatus = data.status
            this.teamsNotes = data.notes
            this.createDashboardTeamsFormGroup.disable();
            this.getTeamsActivityDataView(this.teamsId);
          }

          if (data.event === 'teamsSelectedData') {
            this.selectedTagNotesItem = data.data;
            console.log("this.teamsSelectedData---", this.selectedTagNotesItem)
          }
        }

        if (data.src == "layoutPannel") {
          if (data.event == "teams_sendLocation") {
            this.teamsDashboard = true;
            this.location = data.location;
          }
        }
      })
  }

  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }

  getUserData() {
    this.chitService.getUserData().subscribe(
      (response) => {
        this.usersDetails = response;
      },
      error => {
      })
  }

  supervisorList() {
    this.configService.getProjectConfig().then((project_config: any) => {
      this.chitService.getScheduleData()
        .subscribe(
          (response) => {
            var repos = response;
            this.supervisorListData = repos;
            console.log('this.supervi', this.supervisorListData)
            var filterData = this.supervisorListData.filter(obj => obj.userId == this.configService.accountEmail)
            if (filterData.length != 0) {
              this.TeamsFormBinded(filterData[0])
            }
          },
          error => {
            console.error('Request failed with error')
            console.log(error)
          })
    });
  }

  TeamsFormBinded(chitFormData) {
    this.createDashboardTeamsFormGroup.patchValue({
      starttime: (chitFormData.start_time ? chitFormData.start_time : "08:00"),
      endtime: (chitFormData.end_time ? chitFormData.end_time : "20:00"),
      vessel: (chitFormData.vessel ? chitFormData.vessel : ""),
    });
  }


  getTeamsActivityDataView(teamsId) {
    this.chitService.getTeamsData(teamsId).subscribe(
      (response) => {
        var repos = response;
        if (repos.length !== 0) {
          var data = repos[0];
          this.teamStatus = data.status
          this.teamsNotes = data.notes
          this.createDashboardTeamsFormGroup.setValue({
            teams: (data.teams ? data.teams : " "),
            starttime: (data.starttime ? data.starttime : ""),
            endtime: (data.endtime ? data.endtime : ""),
            date: (new Date(data.date) ? new Date(data.date) : ""),
            email: (data.email ? data.email : ""),
            vessel: (data.vessel ? data.vessel : ""),
            details: (data.details ? data.details : ""),
          });
        }
      },
      error => {
        console.error('Request failed with error')
      })
  }

  closeEditRoundsDashboard() {
    this.isReadOnlyDashboard = true;
    this.dashboardRoundHeading = "View Teams";
    this.editResponseDashboardIcon = true;
    this.closeResponseDashboardIcon = false;
    this.createResponseDashboard = false;
    this.viewResponseDashboard = true;
    this.updateResponseDashboard = false;
    this.createDashboardTeamsFormGroup.disable();
  }

  closeRoundsDashboard() {
    let teamsWindowState = false
    this.broadcastInfo({ src: 'sidepanel', event: 'Teams', data: teamsWindowState, key: "" });
  }

  onselectUser(user) {
    let name = '';
    if (user) {
      name = user['First Name'] + ' - ' + user.Role;
      this.createDashboardTeamsFormGroup.patchValue({
        teams: name,
      });
    }
  }

  editRoundsDashboard() {
    this.isReadOnlyDashboard = false;
    this.dashboardRoundHeading = "Edit teams";
    this.editResponseDashboardIcon = false;
    this.closeResponseDashboardIcon = true;
    this.viewResponseDashboard = false;
    this.createResponseDashboard = false;
    this.updateResponseDashboard = true;
    this.createDashboardTeamsFormGroup.enable();
  }
  officerNameDetails: any;
  createTeamsDashboard() {
    if (this.createDashboardTeamsFormGroup.valid) {
      var data = {
        "officerid": '',
        "details": this.createDashboardTeamsFormGroup.value.details,
        "starttime": this.createDashboardTeamsFormGroup.value.starttime,
        "teams": this.createDashboardTeamsFormGroup.value.teams,
        "notes": '',
        "status": 'false',
        "email": this.createDashboardTeamsFormGroup.value.email,
        "date": this.datePipe.transform(this.createDashboardTeamsFormGroup.value.date, 'd-MMMM-y'),
        "role": '',
        "vessel": this.createDashboardTeamsFormGroup.value.vessel,
        "number": '',
        "endtime": this.createDashboardTeamsFormGroup.value.endtime
      }
      var userData = this.usersDetails.filter(t => t.email.toLowerCase() == this.createDashboardTeamsFormGroup.value.email.toLowerCase());
      this.officerNameDetails = userData[0]['First Name'] + ' ' + userData[0]['Role'] || "",
      console.log("data ", data)
      this.chitService.addTeams(data)
        .subscribe((res) => {
          if (res['status'] === '200') {
          }
          var teamsId = res.insertedId;
          let tagLocation, locationShape;
          if (this.location != "") {
            tagLocation = JSON.parse(this.location);
            locationShape = "teams";
          } else {
            let locationKey = "teams_" + this.createDashboardTeamsFormGroup.value.teams.split(" ").join('')
            let tagLocationIdx = this.chitService.locationList.findIndex(item => item.locationKey == locationKey);
            if (tagLocationIdx != -1) {
              tagLocation = this.chitService.locationList[tagLocationIdx]['location'];
              locationShape = this.chitService.locationList[tagLocationIdx]['locationShape'] || "teams";
            }
          }
          var chitData = [{ 'typeCollectionId': teamsId, 'typeCollection': 'Teams', "purpose": this.createDashboardTeamsFormGroup.value.details, "tagLocation": tagLocation, "locationShape": locationShape }]
          var logBookData = { 'typeCollection': 'Teams', "tagName": this.createDashboardTeamsFormGroup.value.teams, "description": this.createDashboardTeamsFormGroup.value.details, "startDate": this.datePipe.transform(this.createDashboardTeamsFormGroup.value.date, 'yyyy-MM-dd'), "startTime": this.createDashboardTeamsFormGroup.value.starttime, "endTime": this.createDashboardTeamsFormGroup.value.endtime };
          var chit = {
            "Location": this.createDashboardTeamsFormGroup.value.location,
            "Vessel": this.createDashboardTeamsFormGroup.value.vessel,
            "activity": this.createDashboardTeamsFormGroup.value.teams,
            "emitterLocation": this.createDashboardTeamsFormGroup.value.teams,
            "startTime": this.createDashboardTeamsFormGroup.value.starttime,
            "endTime": this.createDashboardTeamsFormGroup.value.endtime,
            "officerName":this.officerNameDetails
          }
          this.chitService.createLogBook(logBookData);
          this.broadcastInfo({ src: 'chit', event: 'TeamsCreate', data: chitData, chit: chit, seletedCollection: this.selectedTagItem, formdate: this.datePipe.transform(this.createDashboardTeamsFormGroup.value.date, 'd-MMMM-y') });
          this.broadcastInfo({ src: 'sidepanel', event: 'scheduleDataInsert', data: 'true' });
          this.broadcastInfo({ src: 'sidepanel', event: 'Teams', data: false, key: "" });
        }, error => {
          console.error('There was an error!');
        })
    }
  }

  updateTeamsDashboard() {
    if (this.createDashboardTeamsFormGroup.valid) {
      var data = {
        "officerid": '',
        "teams": this.createDashboardTeamsFormGroup.value.teams,
        "details": this.createDashboardTeamsFormGroup.value.details,
        "starttime": this.createDashboardTeamsFormGroup.value.starttime,
        "email": this.createDashboardTeamsFormGroup.value.email,
        "date": this.datePipe.transform(this.createDashboardTeamsFormGroup.value.date, 'd-MMMM-y'),
        "role": '',
        "vessel": this.createDashboardTeamsFormGroup.value.vessel,
        "number": '',
        "endtime": this.createDashboardTeamsFormGroup.value.endtime,
        "notes": (this.teamsNotes ? this.teamsNotes : ""),
        "status": (this.teamStatus ? this.teamStatus : ""),
      }
      var userData = this.usersDetails.filter(t => t.email.toLowerCase() == this.createDashboardTeamsFormGroup.value.email.toLowerCase());
      this.officerNameDetails = userData[0]['First Name'] + ' ' + userData[0]['Role'] || "",
      this.chitService.updateTeams(this.teamsId, data)
        .subscribe((res) => {
          if (res['status'] === '200') {
          }
          let tagLocation, locationShape;
          if(this.location != ""){
            tagLocation = JSON.parse(this.location);
            locationShape =  "teams";
          }else{
            let locationKey = "teams_"+this.createDashboardTeamsFormGroup.value.teams.split(" ").join('')
            let tagLocationIdx = this.chitService.locationList.findIndex(item=> item.locationKey == locationKey);
            if(tagLocationIdx != -1){
              tagLocation = this.chitService.locationList[tagLocationIdx]['location'];
              locationShape = this.chitService.locationList[tagLocationIdx]['locationShape'] || "teams";
            }
          }
          var chitData =[{'typeCollectionId': this.teamsId, 'typeCollection': 'Teams',"purpose": this.createDashboardTeamsFormGroup.value.details,"tagLocation": tagLocation, "locationShape":locationShape,"tagShapeId":this.selectedTagNotesItem.tagShapeId }]
          var logBookData = {'typeCollection': 'Teams',"tagName":this.createDashboardTeamsFormGroup.value.teams, "description": this.createDashboardTeamsFormGroup.value.details,"startDate":this.datePipe.transform(this.createDashboardTeamsFormGroup.value.date, 'yyyy-MM-dd'), "startTime": this.createDashboardTeamsFormGroup.value.starttime,"endTime": this.createDashboardTeamsFormGroup.value.endtime };
          var chit ={
            "Location": this.createDashboardTeamsFormGroup.value.location,
            "Vessel": this.createDashboardTeamsFormGroup.value.vessel,
            "activity": this.createDashboardTeamsFormGroup.value.teams,
            "emitterLocation": this.createDashboardTeamsFormGroup.value.teams,
            "startTime": this.createDashboardTeamsFormGroup.value.starttime,
            "endTime": this.createDashboardTeamsFormGroup.value.endtime,
            "officerName":this.officerNameDetails
          }
          console.log("this.selectedTagNotesItem", this.selectedTagNotesItem)
          this.broadcastInfo({ src: 'chit', event: 'TeamsCreate', data: chitData, chit : chit, seletedCollection : this.selectedTagNotesItem, formdate: this.datePipe.transform(this.createDashboardTeamsFormGroup.value.date, 'd-MMMM-y')});
          this.chitService.createLogBook(logBookData);
          this.broadcastInfo({ src: 'sidepanel', event: 'scheduleDataInsert', data: 'true' });
          this.broadcastInfo({ src: 'sidepanel', event: 'Teams', data: false, key: "" });
        }, error => {
          console.error('There was an error!');
        })
    }
  }

  pickLocation() {
    this.teamsDashboard = false;
    this.ctrlServ.isAddLocationState = true;
    this.ctrlServ.isActiveLayout = 'teams';
  }
}
