import { Component, ViewChild, Output, EventEmitter } from '@angular/core';
// import { MatSidenav } from '@angular/material';
// import {MatSidenavModule} from '@angular/material/sidenav';
import * as  menuList from "../../assets/appData/menuList.json";
import { LanguageService } from '../services/language.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent {
  // @ViewChild(MatSidenav, { static: false }) sidenav: MatSidenav;
  @Output() selectedMenu = new EventEmitter<object>();
  AppUrlList: any = menuList['default']['menulist'];
  selectedMenuName: any = '';
  selectedMenuKey: any = menuList['defaultMenu']; // RAJ08DEC: this default value has to be read from json
  isMenuOpened: boolean = false;
  isChildMenuOpend: boolean = false;
  childMenu: any = [];
  childRequestMenu: any = [];
  search: any = '';

  constructor(private translate: TranslateService, private language: LanguageService) {
    this.language.languageChange.subscribe(props => {
      console.log("language", props);
      this.translate.use(props);
    })
  }

  /* * * *
  * open Menu options
  * * * * */
  openMenu(e) {
    this.isMenuOpened = !this.isMenuOpened;
  }

  /* * * *
   * menu active
   * * * * */
  menuActive(e) {
    this.selectedMenuName = e.name;
    this.selectedMenuKey = e.key;
    this.selectedMenu.next(e);
    this.isMenuOpened = false;
  }

  /* * * *
   * menu options
   * * * * */
  menuHover(e) {
    this.selectedMenuName = e.name;
    this.selectedMenuKey = e.key;
    this.isChildMenuOpend = true;
    this.childMenu = e.childUrl;
    this.childRequestMenu = e.childUrl;
  }

  /* * * *
  * childe menu close
  * * * * */
  closeMenu(e) {
    this.isMenuOpened = false;
  }

  /* * * *02-12-2020
   * search object
   * * * * */
  searchValue(e) {
    console.log(e.target.value);
    this.search = e.target.value;
  }

  /* * * *02-12-2020
   * search clear
   * * * * */
  searchClear(e) {
    this.search = '';
  }
  // RAJ08DEC: remove all unused codes
}