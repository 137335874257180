import { Component, AfterViewInit, ViewChild, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { CommunicationService } from "../services/communication.service";
import { LanguageService } from '../services/language.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})

export class TableComponent implements AfterViewInit {
  displayedColumns: string[] = ['StartDate', 'Ship', 'EndDate', 'Location', 'Activity', 'StartTime', 'EndTime', 'Notes'];
  dataSource: any;
  screenWidth: any = window.innerWidth;
  @Input() set shipMetaData(v: any) {
    if (v != undefined) {
      this.getReportData(v);
    }
  }
  tblToggleOpen: any = false;
  @Input() tblSeePlanOpen:any =false;
  @Input() planType:any;
  broadcastSubs: any;
  @Output() tblToggleState: EventEmitter<any> = new EventEmitter<any>();
  @Output() seeplanclose: EventEmitter<any> = new EventEmitter<any>();
  legendsList: any = [{ "iconName": "antenna rotating", "name": "Antenna Rotating" }, { "iconName": "ammunitioning", "name": "Ammunitioning" },
  { "iconName": "man-aloft", "name": "Man Aloft" }, { "iconName": "cold move", "name": "Cold Move" }, { "iconName": "crane work", "name": "Crane Work" },
  { "iconName": "diver", "name": "Diver" }, { "iconName": "basinTrial", "name": "Basin Trial" },
  { "iconName": "emmissions", "name": "Emmissions" }, { "iconName": "radiating", "name": "Radiate" }, { "iconName": "fueling", "name": "Fueling" }]

  // @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  // @ViewChild(MatSidenav, { static: false }) sidenav: MatSidenav;

  ngAfterViewInit() {
  }

  constructor(private communicationServ: CommunicationService, private translate: TranslateService, private language: LanguageService) {
    this.language.languageChange.subscribe(props => {
      console.log("language", props);
      this.translate.use(props);
    })
   }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    console.log(event.target.innerWidth, ",", event.target.devicePixelRatio);
    this.screenWidth = event.target.innerWidth;
  }
  /* * * * *
  * Init service data load
  * * * * * */
  ngOnInit() {
    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src === "main") {
          if (data.event === 'showtable') {
            this.tblToggleHanddle();
          }
        }
      })
  }

  getReportData(data) {
    this.dataSource = [];
    data.forEach(ship => {
      if (ship.activity) {
        this.dataSource.push(ship);
      }
    });
  }

  tblToggleHanddle() {
    this.tblToggleOpen = !this.tblToggleOpen;
    this.tblToggleState.emit(this.tblToggleOpen);
  }
  tblSeePlanClose(){
    this.tblSeePlanOpen = false;
    this.seeplanclose.emit(false);
  }

  /***
   * location code change
   */
  getLocation(location) {
    if (location) {
      let loc = location.substring(0, 2);
      let code = location.substring(2, location.length);
      let str = loc + ' ' + code.replace("_", "/");
      return str;
    } else {
      return location;
    }
  }


}