import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from "@angular/forms";
import { FileValidators } from "ngx-file-drag-drop";
import {io} from 'socket.io-client';
import { ConfigService } from '../services/config.service';
import { LanguageService } from '../services/language.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-message-screen',
  templateUrl: './message-screen.component.html',
  styleUrls: ['./message-screen.component.scss']
})
export class MessageScreenComponent implements OnInit {
  @Output() msgTagView: EventEmitter<any> = new EventEmitter<any>();

  socket: any;
  message: any = '';
  displayedColumns: string[] = ['position', 'Message', 'Date', 'Time'];
  dataSource: any = [];

  constructor(private configServ: ConfigService, private translate: TranslateService, private language: LanguageService) {
    this.language.languageChange.subscribe(props => {
      console.log("language", props);
      this.translate.use(props);
    })
   }

  ngOnInit(): void {
    this.fileControl.valueChanges.subscribe((files: File[]) =>
      console.log(this.fileControl.value, this.fileControl.valid)
    );
  }

  ngAfterViewInit(): void {
    this.init_socket();
  }

  fileControl = new FormControl(
    [],
    [FileValidators.required,
    FileValidators.maxFileCount(2)]
  );

  onValueChange(file: File[]) {
    console.log("File changed!");
  }

  drop(e) {
    console.log(e);
  }

  broadCast() {
    if (this.message) {
      this.doSend(this.message);
      this.msgTagView.emit();
    }
  }

  closePanel() {
    this.msgTagView.emit();
  }

  doSend(message) {
    this.socket.emit('sendMsg', message);
    this.socket.emit('pullBroadcastMsg');
  }

  /* * * * *
  * init socket service
  * * * * * */
  init_socket() {
    var _this = this;
    let socketport = this.configServ.project_config.socketport || 'http://localhost:8070/';
    _this.socket = io(socketport);
    _this.socket.on('connect', onConnect);
    _this.socket.on('getBroadcastMsg', onBroadcastMessage);
    _this.socket.on('disconnect', onDisconnect);
    _this.socket.on('connect_error', onError);
    _this.socket.on('reconnect_error', onError);

    function onConnect(evt) {
      writeToScreen("CONNECTED");
      console.log("socket connected!!");
      _this.socket.emit('pullBroadcastMsg');
      // doSend("Allo?");
    }

    function onDisconnect(evt) {
      writeToScreen("DISCONNECTED");
      console.log("socket disconnected!!");
    }

    function onBroadcastMessage(data) {
      writeToScreen('<span style="color: blue;">RESPONSE: ' + data + '</span>');
      _this.dataSource = data;
    }

    function onError(message) {
      writeToScreen('<span style="color: red;">ERROR:</span> ' + message);
    }

    function doSend(message) {
      writeToScreen("SENT: " + message);
      _this.socket.emit('sendMsg', message);
    }

    function writeToScreen(message) {
      var pre = document.createElement("p");
      pre.style.wordWrap = "break-word";
      pre.innerHTML = message;
      //output.appendChild(pre);
    }
  }

}
