import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit } from '@angular/core';
import { CommunicationService } from '../services/communication.service';
import { MatTableDataSource } from '@angular/material/table';
import { ConfigService } from '../services/config.service';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-ship-comments',
  templateUrl: './ship-comments.component.html',
  styleUrls: ['./ship-comments.component.scss']
})
export class ShipCommentsComponent implements OnInit {
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  // @Input() shipCompartment: any
  @Input() compComments: any
  // @Input() selectedKhaSystems: any
  // @Input() selectedGroupData: any
  @Input() selectedLayoutList: any
  @Input() LayoutMode: any;
  @Input() selectedTagItem: any;
  @Input() layoutHeading: any = "";
  isCommentTyped = false;
  addUserCard: boolean = false;
  addCommentCard: boolean = true;
  displayedColumns: string[];
  dataSource: any;
  usersDetails: any = [];
  userList: any = [];
  selection = new SelectionModel<any>(false, []);
  shipCompartmentDetails: any = [];
  isActivityTyped = false;
  compActivity: any = [];
  activityMessage: any;
  activityDetail: any = [];
  loginUserdata: { fName: any; lName: any; email: any; };
  activityMessageIdx: any;
  broadcastIframeSubs: any;
  deleteIdx: any;
  notificationNo: any;
  surveyNo: any;
  materialNo: any;
  status: any = {};
  statusId: any;
  adminEmailId: any;
  // public allColors: any[] = [
  //   { value: '#FF0000', name: 'BLOCKED' },
  //   { value: '#FFBF00', name: 'INPROGRESS' },
  //   { value: '#00FF00', name: 'COMPLETED' },

  // ];
  allColors: any;
  colorchanges: any;
  StatusName: any;
  startDate: any;
  broadcastSubs: any;
  constructor(public communicationServ: CommunicationService, private configService: ConfigService) {

  }
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }
  ngAfterViewInit(): void {
    this.getUserData();
    console.log("tagListComment", this.LayoutMode, this.selectedLayoutList);
    if (this.LayoutMode) {
      // this.getCompartmentDetails();
      console.log("tagListComment", this.selectedLayoutList);
      // if (this.selectedTagItem) {
      //   this.getLayoutTag()
      // }

    } else {
      // this.getCompartmentDetails();
    }

  }
  getUserData() {
    this.configService.getUserData().subscribe(
      (response) => {
        this.usersDetails = response;
        console.log(this.usersDetails)
        var userData = this.usersDetails.filter(obj => obj.email == this.configService.accountEmail)
        if (userData.length > 0) {
          this.loginUserdata = {
            "fName": userData[0]['First Name'],
            "lName": userData[0]['Last Name'],
            "email": userData[0].email
          }
          this.userList.push(this.loginUserdata);
        }
      },
      error => {
        console.error('Request failed with error');
      })
  }
  ngOnInit(): void {
    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src === "navyApp") {
          if (data.event === 'statusData') {
            this.allColors = data.data;
            console.log("this.allColors", this.allColors)
            this.getLayoutTag()
          }
        }
        // if (data.src === "sidepanel") {
        //   if(data.event=="layoutActive"){
        //     this.selectedLayoutList = data['data'];
        //     this.getLayoutTag()
        //   }
        // }
      })
    this.broadcastIframeSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src === "commentScreen") {
          if (data.event === 'deleteComment') {
            this.saveData()
          }
        }
      })
  }
  onCancel(data) {
    if (data == 'Comments') {
      this.isCommentTyped = false
    } else {
      this.isActivityTyped = false
    }
  }
  onComment(e) {
    if (e.target.value.trim() != '') {
      this.isCommentTyped = true;
    } else {
      this.isCommentTyped = false;
    }
  }
  onClose() {
    this.cancel.emit(false)
  }
  onSave(data) {
    if (data == 'Comments') {
      this.isCommentTyped = false;

      this.saveData();
    } else {
      this.isActivityTyped = false;
      this.activityDetail.unshift({
        "userName": this.loginUserdata.fName + ' ' + this.loginUserdata.lName,
        "activityMessage": this.activityMessage,
        "loginEmail": this.loginUserdata.email,
        "dataTime": this.getDateTime()
      })
      this.activityMessage = ""
      this.saveData();
    }
  }
  onActivity(e) {
    if (e.target.value.trim() != '') {
      this.isActivityTyped = true;
      this.activityMessage = e.target.value
    } else {
      this.isActivityTyped = false;
    }
  }
  saveData() {
    if (this.LayoutMode) {
      if (this.selectedTagItem && this.selectedTagItem.tagComments) {
        var CommentScreendata = this.getCommentScreen();
        this.broadcastInfo({ src: 'ship-comment', event: 'update-comment-data', data: CommentScreendata });
      } else {
        var CommentScreendata = this.getCommentScreen();
        CommentScreendata = Object.assign({},this.selectedTagItem,CommentScreendata)
        this.broadcastInfo({ src: 'ship-comment', event: 'create-comment', data: CommentScreendata });
      }
    } else {
        var CommentScreendata = this.getCommentScreen();
        this.broadcastInfo({ src: 'ship-comment', event: 'commentsData', data: CommentScreendata });
    }
    if (this.layoutHeading == '') {
      var CommentScreendata = this.getCommentScreen()
      this.broadcastInfo({ src: 'material', event: 'openDialogConfirmLayoutName', data: CommentScreendata });
    } 
    // else {
    //   var CommentScreendata = this.getCommentScreen();
    //   this.broadcastInfo({ src: 'comment', event: 'update-comment', data: CommentScreendata });
    // }
  }
  getLayoutTag() {
    if (this.selectedLayoutList) {
      if (this.selectedLayoutList['tagPoints']) {
        if (this.selectedLayoutList['tagPoints'].length > 0) {
          this.selectedLayoutList['tagPoints'].forEach(element => {
            if(this.selectedTagItem.tagComments){
              if (element.tagComments['_id'] == this.selectedTagItem.tagComments._id) {
                if (element.tagComments) {
                  console.log("comments", element, element.tagComments)
                  this.compComments = element.tagComments.description ? element.tagComments.description : '';
                  this.activityDetail = element.tagComments.activityMessage ? element.tagComments.activityMessage : '';
                  this.userList = element.tagComments.userList ? element.tagComments.userList : [];
                  // this.notificationNo = element.tagComments.notificationNo ? element.tagComments.notificationNo : [];
                  // this.surveyNo = element.tagComments.surveyNo ? element.tagComments.surveyNo : '';
                  // this.materialNo = element.tagComments.materialNo ? element.tagComments.materialNo : '';
                  this.status = element.tagComments.status ? element.tagComments.status : {};
                  this.startDate = element.tagComments.startDate ? element.tagComments.startDate : new Date();
                  // this.statusId = element.tagComments.statusId ? element.tagComments.statusId : '';
                  if (this.status) {
                    this.colorchanges = this.status.value ? this.status.value : '';
                    this.StatusName = this.status.name ? this.status.name : '';
                  }
                }
              }
            }

          });
        }
      }
    }
  }


  UserChecked() {
    if (this.shipCompartmentDetails.length != 0) {
      this.usersDetails.forEach(element => {
        this.userList.forEach(ele => {
          if (ele.email == element.email) {
            element['isChecked'] = true;
          }
        })
      });
    }
  }

  addUserSelected(selectData, $event: any) {
    $event.stopPropagation();
    var userData: any = [];
    userData = selectData;
    var data = {
      "fName": userData['First Name'],
      "lName": userData['Last Name'],
      "email": userData.email
    }
    if (this.userList.length != 0) {
      var userDataIdx = this.userList.findIndex(obj => obj.email == selectData.email)
      if (userDataIdx == -1) {
        this.userList.push(data)
      } else {
        this.userList.splice(userDataIdx, 1)
      }
    } else {
      this.userList.push(data);
    }
    if (this.shipCompartmentDetails.length != 0) {
      var urldata = this.configService.project_config.commentsApi + this.shipCompartmentDetails[0]._id;
      var CommentScreendata = this.getCommentScreen();
      this.configService.putData(urldata, CommentScreendata).subscribe(result => {
      })
    } else {
      var CommentScreendata = this.getCommentScreen()
      this.configService.postData(this.configService.project_config.commentsApi, CommentScreendata).subscribe(result => {
        // this.getCompartmentDetails()
      })
    }
  }
  getCommentScreen() {
    var commentScreenData = {
      "description": this.compComments,
      "status": this.status,
      'startDate': this.startDate ? this.startDate : new Date(),
      // "statusId": this.statusId,
    }
    console.log("this.shipCompartmentDetails[0]", commentScreenData)
    return commentScreenData
  }
  editActivity(i) {
    this.activityMessageIdx = i;
    this.deleteIdx = null;
  }
  saveActivity(i) {
    this.activityMessageIdx = null;
    this.activityDetail[i].dataTime = this.getDateTime()
    this.saveData();
  }
  deleteActivity(i) {
    this.broadcastInfo({ src: 'material', event: 'commentDelate-popup', data: "", sub: null });
    this.deleteIdx = i
  }
  CancelActivity() {
    this.activityMessageIdx = null
  }
  getDateTime() {
    var date = new Date();
    var dateTime = date.toString()
    var data = dateTime.split(' ')
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes > 10 ? 0 + minutes : minutes
    var FullDateTime = data[1] + " " + data[2] + "," + data[3] + " " + hours + ":" + minutes + " " + ampm
    return FullDateTime
  }

  statusChange(e) {
    var colr = e.split(' ')
    var selectData = this.allColors.filter(t => t._id == e);
    this.colorchanges = selectData[0].value;
    this.StatusName = selectData[0].name;
    this.statusId = selectData[0]._id;
    this.status = {
      'value': this.colorchanges,
      "name": this.StatusName,
      'statusId': this.statusId
    }
    this.isCommentTyped = true;
  }

  notificationNoValue(e) {
    if (e.target.value.trim() != '') {
      this.isCommentTyped = true;
    } else if (this.notificationNo && this.surveyNo && this.materialNo) {
      this.isCommentTyped = false;
    }
  }
  surveyNoValue(e) {
    if (e.target.value.trim() != '') {
      this.isCommentTyped = true;
    } else if (this.notificationNo && this.surveyNo && this.materialNo) {
      this.isCommentTyped = false;
    }
  }
  materialNoValue(e) {
    if (e.target.value.trim() != '') {
      this.isCommentTyped = true;
    } else if (this.notificationNo && this.surveyNo && this.materialNo) {
      this.isCommentTyped = false;
    }
  }
  // createStatus() {
  //   this.broadcastInfo({ src: "sidepanel", event: "createStatus", data: "" })
  // }
}