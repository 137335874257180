import { Injectable } from '@angular/core';
import { TagService } from '../services/tag.service';
import { ActivityVisualizationService } from '../services/activity-visualization.service';
import { CommunicationService } from "../services/communication.service";
var moment = require('moment-timezone');

@Injectable({
  providedIn: 'root'
})
export class ControllerService {
  datauxview: any;
  dfx: any;
  distance: any = 1800;
  activeShipList: any = '';
  currentActivityDate: any;
  defaultDateRange: Number = 27;
  selectedDateRange: Number = 27;
  animationDate = new Date();
  isDayShipsLoaded: boolean = false;
  commandingOfficer: any = '';
  selectedSysComp: any = [];
  showSysComp: any = {};
  selectedKha: any = 'Buoyancy';
  matCurrentTab: any = "system";
  shipComp: any = {};
  isMaterialAppLoaded: boolean = false;
  stateBoardView: boolean = false;
  isAddLocationState: boolean = false;
  statusList: any = [
    { 'id': 'completed', 'status': 'Completed', 'color': '#99ccff' },
    { 'id': 'noissues', 'status': 'No Issues', 'color': '#00b050' },
    { 'id': 'ontrackminorissues', 'status': 'On Track minor issues', 'color': '#99cc00' },
    { 'id': 'significantissuessuccessfuldeliveryathighrisk', 'status': 'Significant Issues successful delivery at high risk', 'color': '#ffc000' },
    { 'id': 'majorissuespreventingcompletion', 'status': 'Major Issues preventing completion', 'color': '#ff6600' },
    { 'id': 'criticalissuespreventingcompletion', 'status': 'Critical issues preventing completion', 'color': '#ff0000' },
    { 'id': 'notstarted', 'status': 'Not Started', 'color': '#ffffff' }
  ];
  mbsStatusList: any = [
    { 'id': 'acceptable', 'status': 'Acceptable', 'color': '#00b050' },
    { 'id': 'acceptablewithreview', 'status': 'Acceptable with review', 'color': '#00b0f0' },
    { 'id': 'undesirable', 'status': 'Undesirable', 'color': '#ffff00' },
    { 'id': 'intolerable', 'status': 'Intolerable', 'color': '#ff0000' }
  ];
  // service: any = {
  //   "nsc_certificate": "03 March 2021", "key": "Key Hazard Area Certificates",
  //   "hazardList":
  //     [
  //       { "activity": "Completed", 'order': "II", "area": "Structure", "nscStatus": "inactive", "mbsStatus": "Acceptable", "shipStatus": "inactive", "date": "03 Mar 2021", "scc": "inactive", "mbslink": "" },
  //       { "activity": "Critical issues preventing completion", 'order': "III", "area": "Buoyancy", "nscStatus": "active", "mbsStatus": "Acceptable with review", "shipStatus": "active", "date": "03 Mar 2021", "scc": "inactive", "mbslink": "" },
  //       { "activity": "On Track minor issues", 'order': "IV", "area": "Engineering", "nscStatus": "inactive", "mbsStatus": "Undesirable", "shipStatus": "inactive", "date": "03 Mar 2021", "scc": "inactive", "mbslink": "" },
  //       { "activity": "On Track minor issues", 'order': "V", "area": "Seamanship", "nscStatus": "inactive", "mbsStatus": "Intolerable", "shipStatus": "inactive", "date": "03 Mar 2021", "scc": "inactive", "mbslink": "" },
  //       { "activity": "Significant Issues successful delivery at high risk", 'order': "VI", "area": "Fire Safety", "nscStatus": "active", "mbsStatus": "Undesirable", "shipStatus": "active", "date": "03 Mar 2021", "scc": "inactive", "mbslink": "../../assets/doc/fire_safety_MBS_systems.xlsx#MBS" },
  //       { "activity": "Significant Issues successful delivery at high risk", 'order': "VII", "area": "EER", "nscStatus": "active", "mbsStatus": "Intolerable", "shipStatus": "active", "date": "03 Mar 2021", "scc": "inactive", "mbslink": "" },
  //       { "activity": "Critical issues preventing completion", 'order': "VIII", "area": "Comms", "nscStatus": "inactive", "mbsStatus": "Undesirable", "shipStatus": "inactive", "date": "03 Mar 2021", "scc": "inactive", "mbslink": "" },
  //       { "activity": "Completed", 'order': "IX", "area": "Navigation", "nscStatus": "inactive", "mbsStatus": "Intolerable", "shipStatus": "inactive", "date": "03 Mar 2021", "scc": "inactive", "mbslink": "" },
  //       { "activity": "Completed", 'order': "X", "area": "Dangerous Goods", "nscStatus": "inactive", "mbsStatus": "Acceptable", "shipStatus": "inactive", "date": "03 Mar 2021", "scc": "inactive", "mbslink": "" }
  //     ],
  //   "certificate_strategy": [
  //     { 'status': 'Completed', 'cert': 'ConOpS', 'link': '../../assets/doc/HALIFAX-Class_Naval_Ship_Code_Concept_of_Operations_Statement_-_Ship_(NSC_CONOPS-S).PDF - Copy.pdf' },
  //     { 'status': 'Completed', 'cert': 'ERN Family', 'link': 'ERN' },
  //     { 'status': 'Completed', 'cert': 'Standards Plan', 'link': '' },
  //     { 'status': 'Not Started', 'cert': 'Certification Plan (build)', 'link': '' },
  //     { 'status': 'Completed', 'cert': 'Certification Plan (in-Svc)', 'link': '' }
  //   ],
  //   "assessed_class": "<name>", "initial_lr_class": "03 Mar 2021", "design_auth": "<name>", "regulatory_auth": "NMRA", "scheduled_refit": "June 2005", "last_refit": "June 2005", "certificate": "03 Mar 2021"
  // }
  service: any = {
    "nsc_certificate": "03 March 2021", "key": "Key Hazard Area Certificates",
    "hazardList":
      [
        { "activity": "Not Started", 'order': "II", "area": "Structure", "key": "structure", "nscStatus": "inactive", "mbsStatus": "Acceptable", "shipStatus": "inactive", "date": "03 Mar 2021", "scc": "inactive", "mbslink": "" },
        { "activity": "Completed", 'order': "III", "area": "Buoyancy", "key": "buoyancy", "nscStatus": "active", "mbsStatus": "Acceptable with review", "shipStatus": "active", "date": "03 Mar 2021", "scc": "inactive", "mbslink": "" },
        { "activity": "Not Started", 'order': "IV", "area": "Engineering", "key": "engineering", "nscStatus": "inactive", "mbsStatus": "Undesirable", "shipStatus": "inactive", "date": "03 Mar 2021", "scc": "inactive", "mbslink": "" },
        // { "activity": "Not Started", 'order': "V", "area": "Seamanship", "key": "seamanship", "nscStatus": "inactive", "mbsStatus": "Intolerable", "shipStatus": "inactive", "date": "03 Mar 2021", "scc": "inactive", "mbslink": "" },
        { "activity": "Significant Issues successful delivery at high risk", 'order': "VI", "area": "Fire Safety", "key": "firesafety", "nscStatus": "active", "mbsStatus": "Undesirable", "shipStatus": "active", "date": "03 Mar 2021", "scc": "inactive", "mbslink": "../../assets/doc/fire_safety_MBS_systems.xlsx#MBS" },
        { "activity": "Major Issues preventing completion", 'order': "VII", "area": "EER", "key": "eer", "nscStatus": "active", "mbsStatus": "Intolerable", "shipStatus": "active", "date": "03 Mar 2021", "scc": "inactive", "mbslink": "" },
        { "activity": "Not Started", 'order': "VIII", "area": "Comms", "key": "comms", "nscStatus": "inactive", "mbsStatus": "Undesirable", "shipStatus": "inactive", "date": "03 Mar 2021", "scc": "inactive", "mbslink": "" },
        { "activity": "Not Started", 'order': "IX", "area": "Navigation", "key": "navigation", "nscStatus": "inactive", "mbsStatus": "Intolerable", "shipStatus": "inactive", "date": "03 Mar 2021", "scc": "inactive", "mbslink": "" },
        { "activity": "Not Started", 'order': "X", "area": "Dangerous Goods", "key": "dangerousgoods", "nscStatus": "inactive", "mbsStatus": "Acceptable", "shipStatus": "inactive", "date": "03 Mar 2021", "scc": "inactive", "mbslink": "" }
      ],
    "safetyArea":
      [
        { "activity": "Acceptable", 'order': "II", "area": "Structure", "key": "structure", "nscStatus": "inactive", "mbsStatus": "Acceptable", "shipStatus": "inactive", "date": "03 Mar 2021", "summary": "inactive", "detail": "inactive", "mbslink": "" },
        { "activity": "Acceptable with review", 'order': "IV", "area": "Engineering Systems", "key": "engineering", "nscStatus": "inactive", "mbsStatus": "Undesirable", "shipStatus": "inactive", "date": "03 Mar 2021", "summary": "inactive", "detail": "inactive", "mbslink": "" },
        { "activity": "Undesirable", 'order': "VI", "area": "Fire Safety", "key": "firesafety", "nscStatus": "inactive", "mbsStatus": "Undesirable", "shipStatus": "inactive", "date": "03 Mar 2021", "summary": "inactive", "detail": "inactive", "mbslink": "../../assets/doc/fire_safety_MBS_systems.xlsx#MBS" },
        { "activity": "Acceptable with review", 'order': "VII", "area": "EER", "key": "eer", "nscStatus": "inactive", "mbsStatus": "Intolerable", "shipStatus": "inactive", "date": "03 Mar 2021", "summary": "inactive", "detail": "inactive", "mbslink": "" },
        { "activity": "Undesirable", 'order': "VIII", "area": "Radio Communications", "key": "comms", "nscStatus": "inactive", "mbsStatus": "Undesirable", "shipStatus": "inactive", "date": "03 Mar 2021", "summary": "inactive", "detail": "inactive", "mbslink": "" },
        // { "activity": "Intolerable", 'order': "IX", "area": "Navigation & Seamanship", "key": "navigation", "nscStatus": "inactive", "mbsStatus": "Intolerable", "shipStatus": "inactive", "date": "03 Mar 2021","summary": "inactive", "detail": "inactive", "mbslink": "" },
        { "activity": "Acceptable with review", 'order': "X", "area": "Dangerous Goods", "key": "dangerousgoods", "nscStatus": "inactive", "mbsStatus": "Acceptable", "shipStatus": "inactive", "date": "03 Mar 2021", "summary": "inactive", "detail": "inactive", "mbslink": "" }
      ],
    "certificate_strategy": [
      { 'status': 'Completed', 'cert': 'ConOpS', 'link': '../../assets/doc/HALIFAX-Class_Naval_Ship_Code_Concept_of_Operations_Statement_-_Ship_(NSC_CONOPS-S).PDF - Copy.pdf' },
      { 'status': 'Completed', 'cert': 'ERN Family', 'link': 'ERN' },
      { 'status': 'Completed', 'cert': 'Standards Plan', 'link': '' },
      { 'status': 'Not Started', 'cert': 'Certification Plan (build)', 'link': '' },
      { 'status': 'Completed', 'cert': 'Certification Plan (in-Svc)', 'link': '' }
    ],
    "assessed_class": "<name>", "initial_lr_class": "03 Mar 2021", "design_auth": "<name>", "regulatory_auth": "NMRA", "scheduled_refit": "June 2005", "last_refit": "June 2005", "certificate": "03 Mar 2021"
  }
  comps = [
    {
      "component": "KHA", "compList": [
        {
          "system": "Buoyancy", "key": "buoyancy",
          "systemList": [
            { "name": "Eductors", "color": "#FFF", "status": "active", "model": ["Eductors"], 'targetStatus': false, 'visible': false }
          ],
          "status": "active"
        },
        { "system": "Comms", "key": "comms", "systemList": [], "status": "inactive" },
        { "system": "Dangerous Goods", "key": "dangerousgoods", "systemList": [], "status": "inactive" },
        {
          "system": "EER", "key": "eer", "systemList": [
            { "name": "EEBDs", "color": "#FFF", "status": "active", "model": ["EEBDs"], 'targetStatus': false, 'visible': false },
            { "name": "Life Rafts", "color": "#FFF", "status": "active", "model": ["Life Rafts"], 'targetStatus': false, 'visible': false },
            { "name": "Life Buoys", "color": "#FFF", "status": "active", "model": ["Life Buoys"], 'targetStatus': false, 'visible': false }
          ], "status": "active"
        },
        { "system": "Engineering", "key": "engineering", "systemList": [], "status": "inactive" },
        // { "system": "Seamanship", "key": "seamanship", "systemList": [], "status": "inactive" },
        {
          "system": "Fire Safety", "key": "firesafety",
          "systemList": [{ "name": "HALON System", "color": "#FFF", "status": "active", "model": ["HALON System"], 'targetStatus': false, 'visible': false },
          { "name": "Automatic Water Spray", "color": "#FFF", "status": "active", "model": ["Automatic Water Spray"], 'targetStatus': false, 'visible': false },
          { "name": "AFFF System", "color": "#FFF", "status": "active", "model": ["AFFF System"], 'targetStatus': false, 'visible': false },
          { "name": "Twin Agent (TAU)", "color": "#FFF", "status": "active", "model": ["Twin Agent (TAU)"], 'targetStatus': false, 'visible': false },
          { "name": "Fine Water Spray", "color": "#FFF", "status": "active", "model": ["Fine Water Spray"], 'targetStatus': false, 'visible': false },
          { "name": "Galley FSS (KIDDE)", "color": "#FFF", "status": "active", "model": ["Galley FSS (KIDDE)"], 'targetStatus': false, 'visible': false },
          { "name": "MDFP", "color": "#FFF", "status": "active", "model": ["MDFP"], 'targetStatus': false, 'visible': false },
          { "name": "DDFP", "color": "#FFF", "status": "active", "model": ["DDFP"], 'targetStatus': false, 'visible': false },
          { "name": "Firemain", "color": "#FFF", "status": "active", "model": ["Firemain"], 'targetStatus': false, 'visible': false },
          { "name": "Hoses, Nozzles & Fittings", "color": "#FFF", "status": "active", "model": ["Hoses, Nozzles & Fittings"], 'targetStatus': false, 'visible': false },
          { "name": "Dry Chemical Extinguishers", "color": "#FFF", "status": "active", "model": ["Dry Chemical Extinguishers"], 'targetStatus': false, 'visible': false },
          { "name": "CO2 Extinguishers", "color": "#FFF", "status": "active", "model": ["CO2 Extinguishers"], 'targetStatus': false, 'visible': false },
          { "name": "Water Extinguishers", "color": "#FFF", "status": "active", "model": ["Water Extinguishers"], 'targetStatus': false, 'visible': false },
          { "name": "SCBA/Charging System/PDC", "color": "#FFF", "status": "active", "model": ["SCBA/Charging System/PDC"], 'targetStatus': false, 'visible': false },
          { "name": "SCBA/Charging System/HPFA", "color": "#FFF", "status": "active", "model": ["SCBA/Charging System/HPFA"], 'targetStatus': false, 'visible': false },
          { "name": "Locker (Damage Control)", "color": "#FFF", "status": "active", "model": ["Locker (Damage Control)"], 'targetStatus': false, 'visible': false }],
          "status": "active"
        },
        { "system": "Navigation", "key": "navigation", "systemList": [], "status": "inactive" },
        { "system": "Structure", "key": "structure", "systemList": [], "status": "inactive" }
      ]
    },
    { "component": "Compartments", "compList": [] },
    {
      "component": "Presets", "compList": [
        { "name": "Shafts", "code": ["Shafting"], "selected": false, "status": "active" },
        { "name": "Ladders", "code": ["Ladders-Vertical_and_Sloping"], "selected": false, "status": "active" },
        { "name": "Portable Firefighting", "code": ["Portable_Firefighting"], "selected": false, "status": "active" },
        { "name": "Seawater", "code": ["Aux_Sea_Water_and_Sea_Water_Cooling", 'Aux_Sea_Water_and_Sea_Water_Cooling_Insulation'], "selected": false, "status": "active" },
        { "name": "First-Aid Kits", "code": [], "selected": false, "status": "inactive" },
        { "name": "Life Rafts", "code": [], "selected": false, "status": "inactive" },
        { "name": "Stretchers", "code": [], "selected": false, "status": "inactive" }
      ]
    },

  ]
  userSchedule = null;
  isActiveLayout = "";
  location: string = "";
  broadcastSubs: any;
  selectedCollection = "";

  constructor(private communicationServ: CommunicationService, private tagServ: TagService, private activityServ: ActivityVisualizationService) {
    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data) => {
        if (data.src == "ship-detail") {
          if (data.event == "set-" + this.isActiveLayout + "-picked-location") {
            let obj = data['data'];
            if (obj) {
              this.addPickedLocation(obj);
            }
          }
        }
      })
  }

  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }

  /* * * *
  * init dataux
  * * * */
  init(_datauxview) {
    this.datauxview = _datauxview;
    this.dfx = _datauxview.getDatascape();
  }

  /* * * *
  * maintain selected ship
  * * * */
  setSelectedElem(elem) {
    this.activeShipList = elem;
  }

  /* * * *
  * show the object
  * * * */
  showShip(ship, report = false) {
    if (ship) {
      let shipid = ship['name'];
      let element = this.datauxview.getElementId(shipid);
      if (element) {
        this.dfx.modify(element, { profile: 'regular' });
        this.activityServ.showAllActivity(ship);
        this.tagServ.showShipLabel(report, shipid);
        this.tagServ.hideFlag(shipid);
        let mesh = this.getMesh(shipid)
        // if (mesh.isVisible) {
        //   console.log("showShip",element.isVisible,element.isPickable)
          mesh.isPickable = true;
        // }
        let childMesh = mesh.getChildMeshes();
        if(childMesh.length>0){
          childMesh.forEach(element => {
            if(element.material.name == 'hidden_obj'){
              // console.log(element.isPickable,element.material.name)
              element.isPickable = false;
              // console.log("showShip-child",element.isVisible,element.isPickable)
            }
          });
        }
      }

    }
  }

  /* * * *
  * hide the object
  * * * */
  hideShip(ship) {
    if (ship) {
      let shipid = ship['name'];
      let show = this.isObjVisible(shipid);
      if (show) {
        let element = this.datauxview.getElementId(shipid);
        this.dfx.modify(element, { profile: 'invisible' });
        this.tagServ.showShipLabel(false, shipid);
        let mesh = this.getMesh(shipid)
        if (mesh) {
          mesh.isPickable = false;
        }
      }
    }
  }


  /* * * *
  * getMesh the object
  * * * */

  getMesh(id) {
    let el = this.datauxview.getElementId(id);
    let mesh = this.dfx.getElementMesh(el);
    return mesh;
  }

  /* * * *
  * hide the object
  * * * */
  transparentShip(ship) {
    if (ship) {
      let shipid = ship['name'];
      let element = this.datauxview.getElementId(shipid);
      if (element) {
        this.dfx.modify(element, { profile: 'transparent' });
        this.activityServ.toggleTransparentActivity(ship);
        this.tagServ.showShipLabel(false, shipid);
      }
    }
  }

  /* * * * *
  * on selected ship to show focused mode
  * * * * * */
  // RAJ-COMMENT: 1800 to be declared in constant and used
  focusElement(ship, key = false) {
    if (ship) {
      let shipid = ship['name'];
      let element = this.datauxview.getElementId(shipid);
      if (element) {
        if (!key) {
          this.showShip(ship);
        }

        let dfx = this.datauxview.getDatascape();
        // let def = { "target": dfx.getAbsElemCenter(element), "distance": this.distance };
        let def = { "target": dfx.getAbsElemCenter(element) };
        dfx.moveCamera(def);
      }
    }
  }

  /* * * * *
  * on selected ship to show focused mode
  * * * * * */
  // RAJ-COMMENT: 1800 to be declared in constant and used
  focusStaticElement(element) {
    if (element) {
      let el = this.datauxview.getElementId(element);
      if (el) {
        let dfx = this.datauxview.getDatascape();
        // let def = { "target": dfx.getAbsElemCenter(el), "distance": this.distance };
        let def = { "target": dfx.getAbsElemCenter(el) };
        dfx.moveCamera(def);
      }
    }
  }

  /* * * *
  * move the object position
  * * * */
  move(element, posObj) {
    if (element) {
      let props = this.dfx.props(element);
      var data = { "time": "1", "op": "moveTo", "name": props.id, "duration": 1.720, "place": { "pos": posObj } }
      this.datauxview.modifyElement(data);
    }
  }

  /* * * *
  * rotate the object
  * * * */
  rotate(element, ang) {
    if (element) {
      let props = this.dfx.props(element);
      var data = { "time": "1", "op": "moveTo", "name": props.id, "duration": 1.720, "place": { "rot": ang } }
      this.datauxview.modifyElement(data);
    }
  }

  /* * * *
  * check if object is visible or not
  * * * */
  isObjVisible(c) {
    let comp = this.datauxview.getElementId(c);
    if (comp) {
      let props = this.dfx.props(comp);
      let vis = props.profile.visible;
      return vis;
    } else {
      return null;
    }
  }

  /* * * * *
  * show particluar element
  * * * * * */
  showElement(name) {
    let element = this.datauxview.getElementId(name);
    if (element) {
      var _obj = { "profile": 'regular' };
      this.dfx.modify(element, _obj);
    }
  }

  /* * * * *
  * hide particluar element
  * * * * * */
  hideElement(name) {
    let show = this.isObjVisible(name);
    if (show) {
      let element = this.datauxview.getElementId(name);
      var _obj = { "profile": 'invisible' };
      this.dfx.modify(element, _obj);
      // let mesh = this.getMesh(name)
      // if(mesh){
      //   mesh.isPickable = false;
      // }
    }
  }
  /* * * * *
  * modify element
  * * * * * */
  modifyElement(name, prop, val, obj = null) {
    let exist = this.datauxview.getElementId(name);;
    if (exist) {

      var _obj = {};
      if (obj) {
        _obj = obj
      } else {
        _obj[prop] = val;
      }

      this.dfx.modify(exist, _obj);
    }
  }
  /* * * * *
  * method for get object configuration
  * * * * * */
  getObjectConfig() {
    return {
      "type": "static",
      "parent": "",
      "name": '',
      "model": "",
      "profile": "regular",
      "place": {
        "pos": { "x": 0, "y": 0, "z": 0 },
        "rot": { "x": 0, "y": 0, "z": 0 }
      }
    };
  }

  /* * * * *
  * method for get popup configuration
  * * * * * */
  getPopupConfig() {
    return {
      "number": "FFH 330",
      "expand": false,
      "laidDown": "19 March 1987",
      "Launched": "30 April 1988",
      "Commissioned": "29 June 1992",
      "Homeport": "CFBHalifax",
      "register": "http://google.com/",
      "status": "ACTIVE"
    };
  }

  /* * * *
  * method for open dialog box
  * * * */
  openDialogBox(message) {
    this.broadcastInfo({ src: 'ctrlserv', event: 'opendialogbox', data: message, key: '' });
  }

  /* * * *
* get mesh name
* * * */
  getMeshName(n) {
    if (!n) {
      return 'unknown'
    }
    let arr = n.split(".");
    if (arr.length === 1) {
      return arr[0]
    }
    if (arr.length === 0) {
      return "unknown"
    }
    if (arr[arr.length - 1].indexOf('00') === 0) {
      return arr[arr.length - 2] + "." + arr[arr.length - 1]
    }
    return arr[arr.length - 1];
  }
  updateSysComps(_comps, boo) {
    let showAll;
    this.showSysComp[this.selectedKha] = [];
    const idx = this.comps.findIndex(el => el.component.toLowerCase() === 'kha');
    this.comps[idx]["compList"].forEach(item => {
      if (item['key'] === this.selectedKha.split(" ").join("").toLowerCase()) {
        item['systemList'].forEach(sys => {
          if (sys['model']) {
            sys['model'].forEach(comp => {
              let found = _comps.some(el => comp.endsWith(el))
              if (found) {
                sys['status'] = boo ? "active" : 'inactive';
              }
              if (sys['status'] === 'active') {
                this.showSysComp[this.selectedKha].push(comp);
              }
            });
          }
        })
        showAll = item['systemList'].some(sys => sys['status'] == 'inactive');
      }
    });
    let _boo = showAll ? false : true;
    this.broadcastInfo({ src: 'ctrlServ', event: 'update-show-all', data: _boo, key: "" });
  }
  /* * *
  * update system comps
  * * */
  updateComps(_comps) {
    const idx = this.comps.findIndex(el => el.component.toLowerCase() === 'kha');
    this.comps[idx]["compList"].forEach(item => {
      if (item['key'] === this.selectedKha.split(" ").join("").toLowerCase()) {
        item['systemList'].forEach(sys => {
          if (sys['model']) {
            sys['model'].forEach(comp => {
              let found = _comps.some(el => comp.endsWith(el))
              if (found) {
                sys['status'] = 'active';
              } else {
                sys['status'] = 'inactive';
              }
            });
          }
        })
      }
    })
  }
  getChildMeshEx(id, cid) {
    let el = this.datauxview.getElementId(id);
    let mesh = this.dfx.getElementMesh(el);
    let meshes = mesh.getChildMeshes();
    let idx = meshes.findIndex(e => this.getMeshName(e.name) === cid);
    if (idx > -1) {
      return meshes[idx];
    }
    return null;
  }

  pickLocation() {
    this.isAddLocationState = true;
  }

  addPickedLocation(obj) {
    this.isAddLocationState = false;
    let mname = obj['mname'] || '';
    this.location = typeof obj['position'] === "object" ? JSON.stringify(obj['position']) : JSON.stringify({ x: 0, y: 0, z: 0 });
    this.broadcastInfo({ src: 'layoutPannel', event: this.isActiveLayout + '_sendLocation', location: this.location });
  }

  /* * *
  * convert local time zone
  */
  convertUTCTZ(date, tzString = null) {
    if (!tzString) {
      tzString = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    date = date ? new Date(date) : new Date();
    let utcDate = Date.UTC(date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );
    let convertdDate = new Date(utcDate);
    let d = moment.tz(convertdDate, tzString);
    let fd = d.utc().format();
    return fd;
  }

  /* * *
  * convert UTC Date Format
  */
  formatUTCTZ(date, format = null) {
    let tzString = Intl.DateTimeFormat().resolvedOptions().timeZone;
    format = format ? format : "YYYY-MM-DD";
    date = date ? new Date(date) : new Date();
    let utcDate = Date.UTC(date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );
    let convertdDate = new Date(utcDate);
    let d = moment.tz(convertdDate, tzString);
    let fd = d.utc().format(format);
    return fd;
  }

}
